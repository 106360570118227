import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Divider,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Check, Close, Visibility, VisibilityOff } from "@mui/icons-material";

import { typography, colors } from "../../utils/styles";
import { useSearchParams } from "react-router-dom";
import AuthApi from "../../api/auth/AuthApi";
import ResetPasswordSuccess from "../../components/ResetPassword/ResetPasswordSuccess";
import { STATUS, Status } from "../../constants/statusConstants";
import useToast from "../../hooks/useToast";

const ResetPassword: React.FC = () => {
  const { ToastMessage, handleOpenToast } = useToast();
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get("resetToken");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<{
    password?: string;
    confirmPassword?: string;
  }>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [isPasswordChanged, setIsPasswordChanged] = useState<Status>(
    STATUS.IDLE
  );

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const validatePassword = (password: string) => {
    const regex = /^(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const error = regex.test(password)
      ? ""
      : "Password must follow the validation rules";
    return error;
  };

  const passwordChecklist = [
    { label: "8-15 characters long", regex: /.{8,15}/ },
    { label: "At least 1 uppercase letter", regex: /[A-Z]/ },
    { label: "At least 1 digit", regex: /\d/ },
    { label: "At least 1 special character (@$!%*?&)", regex: /[@$!%*?&]/ },
  ];

  const checkPassword = (confirmPassword: string) => {
    const error = password === confirmPassword ? "" : "Passwords do not match";
    return error;
  };

  const handleSubmit = async () => {
    setIsPasswordChanged(STATUS.LOADING);
    try {
      await AuthApi.resetPassword({ resetToken, newPassword: password });
      setIsPasswordChanged(STATUS.SUCCESS);
    } catch (error: any) {
      if (error?.response) {
        handleOpenToast(
          error.response?.data?.errors?.[0].message ?? "Something went wrong.",
          "error"
        );
      } else handleOpenToast("Something went wrong.");
      setIsPasswordChanged(STATUS.ERROR);
    }
  };

  const handleReset = () => {
    setFormSubmitted(true);

    const passwordError = validatePassword(password);
    const confirmPasswordError = checkPassword(confirmPassword);

    if (passwordError || confirmPasswordError) {
      setErrors({
        password: passwordError,
        confirmPassword: confirmPasswordError,
      });

      const toastMessage = `${
        passwordError ? "Password: " + passwordError : ""
      } ${
        confirmPasswordError ? "Confirm Password: " + confirmPasswordError : ""
      }`;

      handleOpenToast(toastMessage, "error");
      return;
    }

    handleSubmit();
  };

  useEffect(() => {
    if (formSubmitted) {
      setErrors({
        password: validatePassword(password),
        confirmPassword: checkPassword(confirmPassword),
      });
    }
  }, [password, confirmPassword, formSubmitted]);

  return isPasswordChanged === STATUS.SUCCESS ? (
    <ResetPasswordSuccess />
  ) : (
    <Box mx="16px" my="24px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap="16px"
      >
        <ToastMessage />
        <img
          src="/Icons/yaara_logo.svg"
          style={{ marginTop: "40px" }}
          alt="logo"
        />
        <Typography
          fontSize={typography.font_size["2xl"]}
          fontWeight={typography.font_weight["bold"]}
        >
          Reset Password
        </Typography>

        <Typography
          fontSize={typography.font_size["base"]}
          fontWeight={typography.font_weight["regular"]}
          color={colors.black["200"]}
          textAlign="center"
        >
          Please set something that you will remember later.
        </Typography>
      </Box>
      <Box>
        <InputLabel sx={{ paddingBottom: "4px", paddingTop: "30px" }}>
          New Password
        </InputLabel>

        <TextField
          type={showPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={() => setPasswordFocused(false)}
          sx={{ marginBottom: "15px" }}
          error={!!errors.password}
          helperText={errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            style: { height: "48px" },
          }}
          onFocus={() => setPasswordFocused(true)}
        />
        {passwordFocused && (
          <Box sx={{ marginBottom: "15px" }}>
            <Typography
              sx={{
                fontSize: typography.font_size.xs,
                color: colors.gray["75"],
              }}
            >
              Password Checklist
            </Typography>
            <List
              dense
              sx={{
                fontSize: typography.font_size.xs,
                padding: 0,
                marginBottom: "15px",
              }}
            >
              {passwordChecklist.map((item, index) => (
                <ListItem key={index} sx={{ padding: "2px 0" }}>
                  <ListItemIcon sx={{ minWidth: "24px" }}>
                    {item.regex.test(password) ? (
                      <Check style={{ color: "green", fontSize: "16px" }} />
                    ) : (
                      <Close style={{ color: "red", fontSize: "16px" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{
                      fontSize: typography.font_size.xs,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        <InputLabel sx={{ py: "4px" }}>Confirm New Password</InputLabel>

        <TextField
          type={showConfirmPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          placeholder="Repeat Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{ marginBottom: "20px" }}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowConfirmPassword} edge="end">
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            style: { height: "48px" },
          }}
        />

        <Button onClick={handleReset} variant="primary" fullWidth>
          Reset Password
        </Button>

        <Divider
          style={{
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          OR
        </Divider>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          gap="40px"
        >
          <Box
            display="flex"
            flexDirection="row"
            fontSize={typography.font_size["sm"]}
          >
            <Typography color={colors.gray["75"]}>Have an account? </Typography>
            <a href="/login">Sign In.</a>
          </Box>

          <Typography
            sx={{
              fontSize: typography.font_size["xxs"],
              color: colors.gray["75"],
            }}
          >
            By signing in, you are agreeing to our{" "}
            <a href="/terms-and-conditions">T&C</a> and{" "}
            <a href="/privacy-policy">Privacy Policy</a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
