import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, Button } from '@mui/material';
import styled from '@emotion/styled';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import creatorsLeaderboardApi from '../../api/leaderboard/creatorsLeaderboardApi';
import usersLeaderboardApi from '../../api/leaderboard/usersLeaderboardApi';
import { setTopEarningCreators, setTopFollowedCreators } from '../../redux/leaderboardSlice';
import { TopEarningCreatorType, TopFollowedCreatorType } from '../../types';
import { typography, colors } from '../../utils/styles';
const LeaderboardContainer = styled(Box)`
  background-image: url('/Images/topLeaderboard.png');
  background-size: 102% 112%;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 18px 0 0 0;
  overflow-y: auto;
  overflow-x: hidden;
`;
const Header = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 24px;
  position: sticky;
  top: 16px;
  z-index: 12;
`;
const TabButton = styled(Button)<{ active: boolean }>`
  color: ${({ active }) => (active ? colors.violet[100] : colors.gray[100])}; 
  background-color: ${({ active }) => (active ? colors.gray[100] : 'transparent')};
  border-radius: 20px;
  font-weight: ${typography.font_weight.bold};
  padding: 6px 12px;
  &:hover {
    background-color: ${({ active }) => (active ? colors.gray[100] : 'transparent')}; 
  }
`;
const PodiumContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0 24px;
  width: 100%;
  align-items: space-between;
  position: sticky;
  top: 66px;
  right: 0;
  left: 0;
`;
const ItemsContainer = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
`;
const ImageContainerTwo = styled(Box)`
  position: relative;
  top: 34vw;
  left: 3.2vw;
  width: 35%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const ImageContainerOne = styled(Box)`
  position: relative;
  top: 22vw;
  left: 0;
  width: 35%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;
const ImageContainerThree = styled(Box)`
  position: relative;
  top: 34vw;
  right: 3.2vw;
  width: 35%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;
const ImageWithBorder = styled(Avatar)`
  color: #FFF;
  border: 3px solid #FFF;
  border-radius: 50%;
  width: 54%;
  height: 16vw;
`;
const BottomContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 12px;
  align-items: flex-start;
  justify-content: center;
  position: sticky;
  top: 50%;
  left: 0;
`;
const BottomContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 18px 8px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  background: ${colors.sky[200]};
  border-radius: 8px;
  position: absolute;
  bottom: 35dvh;
`;
const ContentItem = styled(Box)`
  height: 81px;
  align-self: stretch;
  border-radius: 12px;
  background: #FFF;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 12px;
`;
const CountContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CountText = styled(Typography)`
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  color: ${colors.gray[90]};
  text-align: center;
  font-family: "Poppins";
  font-size: ${typography.font_size.xs}px; 
  font-style: normal;
  font-weight: ${typography.font_weight.regular}; 
  line-height: normal;
  border: 2px solid ${colors.gray[200]}; 
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MainContentItem = styled(Box)`
  display: flex;
  gap: 12px;
  align-items: center;
`;
const ProfilePicContainer = styled(Box)``;
const DisplayNameText = styled(Typography)`
  color: ${colors.black[200]}; 
  font-size: 18px;
  font-style: normal;
  font-weight: ${typography.font_weight.semiBold}; 
  line-height: ${typography.line_height.regular};
`;
const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
`;
const Subtext = styled(Typography)`
  color: ${colors.gray[80]};
  font-family: "Poppins";
  font-size: ${typography.font_size.sm}px; 
  font-style: normal;
  font-weight: ${typography.font_weight.regular}; 
  line-height: ${typography.line_height.default}; 
  letter-spacing: ${typography.letter_spacing.default};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;
const DisplayName = styled(Typography)`
  color: ${colors.white}; 
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  font-family: "Poppins";
  font-size: ${typography.font_size.xs}px; 
  font-style: normal;
  font-weight: ${typography.font_weight.bold}; 
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const FollowersCount = styled(Typography)`c
  display: flex;
  width: 18vw;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.72);
  color: ${colors.brand_primary}; // #7262C2
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  font-family: "Poppins";
  font-size: ${typography.font_size.xs}px; 
  font-style: normal;
  font-weight: ${typography.font_weight.bold}; 
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const CoinsCount = styled(Typography)`
  display: flex;
  width: 20vw;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.72);
  color: ${colors.orange[500]}; 
  text-align: center;
  font-family: "Poppins";
  font-size: ${typography.font_size.xs}px; 
  font-style: normal;
  font-weight: ${typography.font_weight.bold}; 
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const PodiumImageContainer = styled(Box)`
  position: absolute;
  top: 50%;
`;
const PodiumImage = styled('img')`
  max-width: 100%;
`;
const InfoItemsContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  position: relative;
`;
const leaderBoardTabs={
  FOLLOWERS: "followers",
  EARNER: "earner"
}
const LeaderBoard = () => {
  const location=useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const dispatch= useDispatch<AppDispatch>();
  const [activeTab, setActiveTab]= useState(leaderBoardTabs.FOLLOWERS)
  const {topFollowedCreators, topEarningCreators} = useSelector((state: RootState)=> state.leaderboard);
  const handleSetActiveTabToFollowers = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setActiveTab(leaderBoardTabs.FOLLOWERS);
  }
  const handleSetActiveTabToEarner=(event: React.SyntheticEvent)=>{
    event.preventDefault();
    setActiveTab(leaderBoardTabs.EARNER);
  }
  const getTopFollowedCreators=async()=>{
    try{
      let response= userRole==="creator" ? await creatorsLeaderboardApi.getTopFollowedCreators(): await usersLeaderboardApi.getTopFollowedCreators();
      dispatch(setTopFollowedCreators(response.data.data));
    }
    catch(err){
      console.error("err")
    }
  }
  const getTopEarningCreators=async()=>{
    try{
      let response= userRole==="creator" ? await creatorsLeaderboardApi.getTopEarningCreators() : await usersLeaderboardApi.getTopEarningCreators();
      dispatch(setTopEarningCreators(response.data.data));
    }
    catch(err){
      console.error("err")
    }
  }
  useEffect(()=>{
    if(topFollowedCreators.length===0){
      getTopFollowedCreators();
    }
    if(topEarningCreators.length===0){
      getTopEarningCreators();
    }
  }, [dispatch])

  return (
    <LeaderboardContainer>
      <Header>
        <TabButton active={activeTab===leaderBoardTabs.FOLLOWERS ? true: false} variant={activeTab===leaderBoardTabs.FOLLOWERS ? "contained" : "text"} onClick={handleSetActiveTabToFollowers}>
          Most Followed
        </TabButton>
        <TabButton active={activeTab===leaderBoardTabs.EARNER ? true: false} variant={activeTab===leaderBoardTabs.EARNER ? "contained" : "text"} onClick={handleSetActiveTabToEarner}>
          Highest Earner
        </TabButton>
      </Header>
      {
      activeTab===leaderBoardTabs.FOLLOWERS ? (
        <FollwersBoard topFollowedCreatorsList={topFollowedCreators}/>
      ): (
        <EarnersBoard topEarningCreatorsList={topEarningCreators}/>
      )
      }
    
    </LeaderboardContainer>
  );
};

export default LeaderBoard;

interface FollwersBoardProps{
  topFollowedCreatorsList: TopFollowedCreatorType[];
}
const FollwersBoard:React.FC<FollwersBoardProps>=({topFollowedCreatorsList})=>{
  return (
    <>
      <PodiumContainer>
        <ItemsContainer>
          <InfoItemsContainer>
          <ImageContainerTwo>
                <ImageWithBorder src={topFollowedCreatorsList[1]?.profilePhotoUrl}/>
                <InfoContainer>
                  <DisplayName>
                      {topFollowedCreatorsList[1]?.displayName}
                  </DisplayName>
                  <FollowersCount>
                    {topFollowedCreatorsList[1]?.followersCount}
                  </FollowersCount>
                </InfoContainer>
            </ImageContainerTwo>
            <ImageContainerOne>
            <ImageWithBorder src={topFollowedCreatorsList[0]?.profilePhotoUrl}/>
            <InfoContainer>
                  <DisplayName>
                      {topFollowedCreatorsList[0]?.displayName}
                  </DisplayName>
                  <FollowersCount>
                    {topFollowedCreatorsList[0]?.followersCount}
                  </FollowersCount>
                </InfoContainer>
            </ImageContainerOne>
            <ImageContainerThree>
            <ImageWithBorder src={topFollowedCreatorsList[2]?.profilePhotoUrl}/>
            <InfoContainer>
                  <DisplayName>
                      {topFollowedCreatorsList[2]?.displayName}
                  </DisplayName>
                  <FollowersCount>
                    {topFollowedCreatorsList[2]?.followersCount}
                  </FollowersCount>
                </InfoContainer>
            </ImageContainerThree>
          </InfoItemsContainer>
          <PodiumImageContainer>
            <PodiumImage src="/Images/podiumWithLight.png" alt="podium"/>
          </PodiumImageContainer>
        </ItemsContainer>
      </PodiumContainer>
      <BottomContainer>
        <BottomContent>
          {topFollowedCreatorsList.slice(3, ).map((item, index)=>(
          <ContentItem>
            <CountContainer>
              <CountText>{4 + index}</CountText>
            </CountContainer>
            <MainContentItem>
              <ProfilePicContainer>
                <Avatar src={item?.profilePhotoUrl} sx={{width: "56px", height: "56px"}}/>
              </ProfilePicContainer>
              <TextContainer>
                <DisplayNameText>
                  {item?.displayName}
                </DisplayNameText>
                <Subtext>
                  {item?.followersCount} Followers
                </Subtext>
              </TextContainer>
            </MainContentItem>
          </ContentItem>
          ))}
        </BottomContent>
      </BottomContainer>
    
    </>
  )
}
interface EarnersBoardProps{
  topEarningCreatorsList: TopEarningCreatorType[];
}
const EarnersBoard:React.FC<EarnersBoardProps>=({topEarningCreatorsList})=>{
      return (
        <>
          <PodiumContainer>
          <ItemsContainer>
          <InfoItemsContainer>
          <ImageContainerTwo>
                <ImageWithBorder src={topEarningCreatorsList[1]?.profilePhotoUrl}/>
                <InfoContainer>
                      <DisplayName>
                      {topEarningCreatorsList[1]?.displayName}
                      </DisplayName>
                      <CoinsCount>
                        {topEarningCreatorsList[1]?.earning} <img src="/Images/newCloutCoin.png" alt="coin"/>
                      </CoinsCount>
                    </InfoContainer>
            </ImageContainerTwo>
            <ImageContainerOne>
            <ImageWithBorder src={topEarningCreatorsList[0]?.profilePhotoUrl}/>
            <InfoContainer>
                      <DisplayName>
                      {topEarningCreatorsList[0]?.displayName}
                      </DisplayName>
                      <CoinsCount>
                        {topEarningCreatorsList[0]?.earning} <img src="/Images/newCloutCoin.png" alt="coin"/>
                      </CoinsCount>
                    </InfoContainer>
            </ImageContainerOne>
            <ImageContainerThree>
            <ImageWithBorder src={topEarningCreatorsList[2]?.profilePhotoUrl}/>
            <InfoContainer>
                      <DisplayName>
                          {topEarningCreatorsList[2]?.displayName}
                      </DisplayName>
                      <CoinsCount>
                        {topEarningCreatorsList[2]?.earning} <img src="/Images/newCloutCoin.png" alt="coin"/>
                      </CoinsCount>
                    </InfoContainer>
            </ImageContainerThree>
          </InfoItemsContainer>
          <PodiumImageContainer>
            <PodiumImage src="/Images/podiumWithLight.png" alt="podium"/>
          </PodiumImageContainer>
        </ItemsContainer>
      </PodiumContainer>
          <BottomContainer>
            <BottomContent>
              {topEarningCreatorsList.slice(3, ).map((item, index)=>(
              <ContentItem>
                <CountContainer>
                  <CountText>{4 + index}</CountText>
                </CountContainer>
                <MainContentItem>
                  <ProfilePicContainer>
                    <Avatar src={item?.profilePhotoUrl} sx={{width: "56px", height: "56px"}}/>
                  </ProfilePicContainer>
                  <TextContainer>
                    <DisplayNameText>
                      {item?.displayName}
                    </DisplayNameText>
                    <Subtext sx={{gap: "6px"}}>
                      {item?.earning} <img src="/Images/newCloutCoin.png" alt="coin"/>
                    </Subtext>
                  </TextContainer>
                </MainContentItem>
              </ContentItem>
              ))}
            </BottomContent>
          </BottomContainer>
        
        </>
      )
}