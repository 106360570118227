import React from 'react'
import CreateOnDemandRates from '../../components/Creator/OnDemandRate/CreateOnDemandRates'

const CreatorOnDemandRate = () => {
  return (
        <CreateOnDemandRates
        headerTitleText="Set On Demand 1-1  Rates"
        title="Set your on demand  1- 1 rates"
        subtitle="Set your per minute charges for 1 or more of your offerings below"
        tooltipText="set your per minute charges for each offering , so that user can directly interact with you."
        cancelTextTitle="Discard On Demand 1-1?"
        cancelTextSubtitle="Are you sure you want to discard this on demand 1-1 rate?"
        />
  )
}

export default CreatorOnDemandRate