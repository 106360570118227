import React from "react";
import {
  Box,
  Button,
  Typography,
  styled,
} from "@mui/material";
import { typography } from "../../../utils/styles";
import { FilterOptionIcon } from "../../../assets/SvgIcons";


const FilterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  z-index: 2;
  position: absolute;
  top: 48px;
  right: 17px;
`;

const IconContainer = styled(Box)`
  padding: 16px 0px 16px 16px;
`;

const TextContainerDiv = styled(Box)`
  display: flex;
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  padding: 16px 16px 16px 0;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;

const FilterText = styled(Typography)`
  color: #212121;
  font-size: 16px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: 0.24px;
`;

const Buttons = styled(Button)`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  min-width: 0
  justify-content: flex-start;
  align-items: center;
  padding: 0
  
`;

interface filterData {
  id: number;
  text: string;
}

interface FilterModalProps {
  handleOnClickFilterIcon: () => void;
  data: filterData[];
}

const UsersFollowingFilters : React.FC<FilterModalProps> = ({
  handleOnClickFilterIcon,
  data,
}) => {
  return (
    <FilterContainer>
      <Buttons onClick={handleOnClickFilterIcon}>
        <IconContainer>
          <FilterOptionIcon />
        </IconContainer>
        <TextContainerDiv>
          <FilterText>Username</FilterText>
        </TextContainerDiv>
      </Buttons>

      <Buttons onClick={handleOnClickFilterIcon}>
        <IconContainer>
          <FilterOptionIcon />
        </IconContainer>
        <TextContainerDiv>
          <FilterText>Started following</FilterText>
        </TextContainerDiv>
      </Buttons>

      <Buttons onClick={handleOnClickFilterIcon}>
        <IconContainer>
          <FilterOptionIcon />
        </IconContainer>
        <TextContainerDiv>
          <FilterText>User</FilterText>
        </TextContainerDiv>
      </Buttons>

      <Buttons onClick={handleOnClickFilterIcon}>
        <IconContainer>
          <FilterOptionIcon />
        </IconContainer>
        <TextContainerDiv sx={{ border: "none" }}>
          <FilterText>Creator</FilterText>
        </TextContainerDiv>
      </Buttons>
    </FilterContainer>
  );
};


export default UsersFollowingFilters;