export const colors = {
  status: {
    SUCCESSFUL: "#43A45C",
    FAILED: "#E88A8A",
    IN_PROGRESS: "#EFB515",
    PENDING: "#E88A8A",
    CANCELLED: "#E88A8A",
  },
  gray: {
    10: "#FFF3E0",
    20: "#21212133",
    50: "#2121211f",
    75: "#00000066",
    80: "#616161",
    90: "#9E9E9E",
    95: "#e9e9e9",
    100: "#f3f3f3",
    105: "#f5f5f5",
    110: "#E9E9E9",
    120: "#E6E6E6",
    200: "#2121213d",
    300: "#d5d5d599",
    325: "#21212166",
    400: "#b5b5b5",
    500: "#21212140",
    600: "#808080",
    700: "#212121b3",
  },
  black: {
    10: "#CCC",
    50: "#343546",
    100: "#6E6E6E",
    200: "#212121",
  },
  violet: {
    20: "#5c4fa6",
    50: "#4F30AB",
    100: "#7262C2",
  },
  orange: {
    50: "#fff7ed",
    100: "#FB542B",
    200: "#544796",
    300: "#c15700",
    400: "#FFF3E0",
    500: "#FBB01C",
  },
  pink: {
    50: "#fdf2f8",
    100: "#fce7f3",
  },
  purple: {
    50: "#DCD7F5",
    100: "#A3278F",
  },
  green: {
    50: "#E8F5E9",
    60: "#E8F5E9",
    100: "#2E7D32",
    200: "#2E7D32",
    300: "#1976d2",
  },
  red:{
    50: "#FFEBEE",
    200:"#D81B60",
     100: "#B71C1C",
    300: "#B71C1C",
  },
  sky: {
    50: "#E3F2FD",
    200: "#E0DBFF",
    300: "#1976D2"
  },
  default: "#2121211A",
  brand_primary: "#544796",
  brand_secondary: "#41acaf",
  white: "#FFFFFF",
  disabled: "#B1B4B7",
  title: "#5C6178",
  background_primary: "#e5ecfd",
  background_secondary: "",
  background_tertiary: "",
  background_inactive: "",
  offwhite: "#f4f8fb",
  border_positive: "#2E7D32",
  background_positive_subtle: "#E8F5E9",
};

export const typography = {
  font_size: {
    "3xs": 8,
    xxs: 10,
    xs: 12,
    sm: 14,
    base: 16,
    lg: 18,
    xl: 20,
    "2xl": 24,
  },
  font_weight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    bolder: 800,
  },
  line_height: {
    default: "135%",
    regular: "125%"
  },
  letter_spacing: {
    regular: "0.15px",
    normal: "0.18px",
    standard: "0.21px",
    default: "0.24px",
  },
};