import { Box, Button, Typography, styled } from "@mui/material";
import React from "react";
import { RequestCloseModal, RequestOpenModal } from "../types";

interface ItemStateType {
  id: number;
  img: string;
  name: string;
  handle: string;
  type: string;
}
interface DiscardModalProps {
  requestOpenModal: RequestOpenModal;
  requestCloseModal: RequestCloseModal;
  title: string;
  subtitle: string;
  unfollowTheCreator?: (item: ItemStateType) => void;
  item?: ItemStateType;
  handleConfirmDiscard?:()=>void;
}

const UnfollowContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Content = styled(Box)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
`;

const BottomButtonsContainer = styled(Box)`
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const Title = styled(Typography)`
  color: var(--Text-Primary, #212121);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  letter-spacing: 0.3px;
`;

const Subtitle = styled(Typography)`
  color: var(--Text-Secodary, #616161);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.21px;
`;

const Buttons = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  color: var(--Text-Secodary, #616161);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  letter-spacing: 0.24px;
`;

const HorizontalDivider = styled(Box)`
  display: flex;
  height: 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 0.8px solid rgba(33, 33, 33, 0.05);
`;

const VerticalDivider = styled(Box)`
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 0.5px solid rgba(33, 33, 33, 0.1);
`;

const UnfollowContent = styled(Box)`
  width: 280px;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 24px 80px 0px rgba(0, 0, 0, 0.3);
`;

const DiscardModal: React.FC<DiscardModalProps> = ({
  requestOpenModal,
  requestCloseModal,
  title,
  subtitle,
  unfollowTheCreator,
  item,
  handleConfirmDiscard
}) => {
  const handleCancel = () => {
    requestCloseModal();
  };

  const handleConfirmedUnfollow = () => {
    // unfollowTheCreator(item);
    if(typeof handleConfirmDiscard==="function"){
      handleConfirmDiscard();
      return
    }
    
    requestCloseModal();
  };

  return (
    <UnfollowContainer>
      <UnfollowContent>
        <Content>
          <Title>{title}</Title>

          <Subtitle>{subtitle}</Subtitle>
        </Content>
        <HorizontalDivider />
        <BottomButtonsContainer>
          <Buttons sx={{ color: "#616161" }} onClick={handleCancel}>
            Cancel
          </Buttons>
          <VerticalDivider />
          <Buttons sx={{ color: "#7262C2" }} onClick={handleConfirmedUnfollow}>
            Yes
          </Buttons>
        </BottomButtonsContainer>
      </UnfollowContent>
    </UnfollowContainer>
  );
};

export default DiscardModal;
