import { Box, Button, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { DollarCaseIcon } from "../../../assets/SvgIcons";
import { typography } from "../../../utils/styles";

interface Item {
  id: number;
  img: string;
  name: string;
  handle: string;
  title: string;
  description: string;
  likes: number;
  comments: boolean;
  totalComments: number;
  user_type: string;
  video: string;
  tips: string;
  userImg: string;
  liked: boolean;
  images: string[];
  postTypeId: number;
}

interface ImagePostProps {
  item: Item;
}

const Container = styled(Box)`
  position: relative;
`;

const CarouselContainer = styled(Box)({
  width: "100%",
  maxWidth: "600px",
  margin: "0 auto",
  height: "auto",
});

const Image = styled("img")({
  width: "100%",
  borderRadius: "2px",
});

const LockedContentScreenContainer = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(84, 71, 150, 1) 0%,
    rgba(84, 71, 150, 0.38) 100%
  );
  z-index: 9;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const LockedContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const LockedButtonContainer = styled(Box)``;
const LockedButton = styled(Button)`
  display: flex;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: 8px;
  border: 1px solid #7262c2;
  background: #7262c2;

  color: #fff;

  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: 0.18px;
`;

const Text = styled(Typography)`
  color: #212121;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: 0.18px;
`;

const ImagePost: React.FC<ImagePostProps> = ({ item }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    touchMove: true,
    swipeToSlide: true,
    afterChange: (current: number) => setCurrentIndex(current),
  };

  return (
    <Container className="image_container">
      <CarouselContainer sx={{ opacity: item.postTypeId === 1 ? "0" : "1" }}>
        <Slider {...settings}>
          {item.images.map((image, index) => (
            <div key={index}>
              <Image src={image} alt={`Carousel image ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </CarouselContainer>

      <Typography
        variant="body1"
        sx={{ fontWeight: typography.font_weight.semiBold }}
        className="image_counter"
      >
        {currentIndex + 1}
        <Box className="slash_divider"></Box>
        {item.images.length}
      </Typography>

      {item?.postTypeId === 1 ? (
        <LockedContentScreenContainer>
          <LockedContent>
            <LockedButtonContainer>
              <LockedButton>
                <DollarCaseIcon />
                Unlock
              </LockedButton>
            </LockedButtonContainer>

            <Text>1 Image (0.33) for Rs.XX</Text>
          </LockedContent>
        </LockedContentScreenContainer>
      ) : null}
    </Container>
  );
};

export default ImagePost;
