import { Box, Typography } from "@mui/material";
import ConstructionIcon from "@mui/icons-material/Construction";
import Settings from "../../components/Settings/Settings";

const SettingsPage = () => {
  return (
    <Box
    >
    <Settings/>
    </Box>
  );
};
export { SettingsPage };
