import { ApiService } from '../ApiClient';
import {creatorAxiosInstance} from '../axiosInstance';
import '../interceptors/creatorAuthInterceptors';

class CreatorFeedsServiceApi extends ApiService {
   
    constructor() {
      super(creatorAxiosInstance)
    }
      fetchGallery(){
          return this.axios.get('content/gallery?page=1&pageSize=10&view=GRID')
        }
      
      fetchGalleryListView(){
      return this.axios.get('content/gallery?page=1&pageSize=10&view=LIST')
      }

  }
  export default new CreatorFeedsServiceApi();
  