import styled from "@emotion/styled";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { typography, colors } from "../../utils/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { STATUS, Status } from "../../constants/statusConstants";
import { useLocation } from "react-router-dom";
import { RequestCloseModal, RequestOpenModal } from "../../types";
import creatorPaymentServiceApi from "../../api/creator/paymentServiceApi";
import userPaymentServiceApi from "../../api/user/paymentServiceApi";
import CashfreeService from "../../services/CashfreeService";
import { PageTagModalComponent } from "../../components/Common/PageTagModalComponent";

const YCValue = styled(Typography)({
  fontSize: "12px",
});
const Discount = styled(Typography)`
  position: absolute;
  bottom: -6px;
  font-size: ${typography.font_size["3xs"]}px;
  font-weight: ${typography.font_weight.medium};
  background: ${colors.orange[100]};
  border-radius: 12px;
  padding-inline: 4px;
  color: ${colors.white};
`;

const BillingDetailsContent = styled(Stack)`
  display: flex;
  width: 100%;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 0.25px solid ${colors.violet[100]};
  background: ${colors.white};
`;

const Summary: any = ({ amountPayable }: { amountPayable: number }) => {
  return (
    <Stack direction="column">
      <Typography
        fontWeight={typography.font_weight.bold}
        fontSize={typography.font_size.lg}
      >
        &#8377; {amountPayable}
      </Typography>
      <Typography>Grand Total</Typography>
    </Stack>
  );
};

const BillingDetails: any = ({ data }: { data: any }) => {
  return (
    <BillingDetailsContent>
      <Typography
        fontWeight={typography.font_weight.bold}
        fontSize={typography.font_size.sm}
      >
        Billing Details
      </Typography>
      <Stack sx={{ width: "100%" }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontSize={typography.font_size.xs}>
            No. of Clout Coins you get{" "}
          </Typography>
          <Typography fontSize={typography.font_size.xs}>
            CC {data.amountInYc}
          </Typography>
        </Stack>
        <Stack
          sx={{
            width: "100%",
          }}
          direction="column"
          my="8px"
          py="10px"
          gap="8px"
          borderBottom="1px solid rgba(213, 213, 213, 0.35)"
          borderTop="1px solid rgba(213, 213, 213, 0.35)"
        >
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Typography fontSize={typography.font_size.xxs}>
                Item Cost
              </Typography>
              <Typography fontSize={typography.font_size.xxs}>
                (1₹ per 10 Yaara Coins)
              </Typography>
            </Box>
            <Typography fontSize={typography.font_size.xxs}>
              &#8377; {data.amountInRs}
            </Typography>
          </Stack>
          {data.discountPercentage !== 0 && (
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize={typography.font_size.xxs}>
                Discount
              </Typography>
              <Typography fontSize={typography.font_size.xxs}>
                {data.discountPercentage}%
              </Typography>
            </Stack>
          )}
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize={typography.font_size.xxs}>
              GST applicable(18%)
            </Typography>
            <Typography fontSize={typography.font_size.xxs}>
              &#8377; {data.gstAmount}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight={typography.font_size.sm}>
            Amount Payable
          </Typography>
          <Typography fontWeight={typography.font_size.sm}>
            &#8377; {data.totalAmount}
          </Typography>
        </Stack>
      </Stack>
    </BillingDetailsContent>
  );
};

const Container = styled(Box)`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 320px;
  align-items: flex-start;
  height: 609px;
  border-radius: 8px;
  background: ${colors.white};
  position: relative;
`;
const PayButton = styled(Button)`
  color: ${colors.white};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  border-radius: 8px;
  display: flex;
  width: 129px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  &:hover {
    background: current-color;
  }
`;
const BillingDetailsContainer = styled(Box)`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
`;
interface AddCoinsProps {
  requestOpenModal: RequestOpenModal;
  requestCloseModal: RequestCloseModal;
  availableYC: string | number;
}

const AddCoins: React.FC<AddCoinsProps> = ({
  requestOpenModal,
  requestCloseModal,
  availableYC,
}) => {
  let cashfree: any;
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const [availableWalletCC, setAvailableWalletCC] = useState("");
  const [coins, setCoins] = useState<number>(0);
  const [isFetched, setIsFetched] = useState<Status>(STATUS.IDLE);
  const [addCoinsData, setAddCoinsData] = useState([]);
  const [billingDetails, setBillingDetails] = useState({});
  const [amountPayable, setAmountPayable] = useState(0);
  const [orderDetails, setOrderDetails] = useState({});
  const [sessionId, setSessionId] = useState("");
  const [isCCAdded, setIsCCAdded] = useState<Status>(STATUS.IDLE);
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("myorder");
  const handleCoins = (e: any) => {
    const isValid = isNaN(e.target.value);
    if (isValid) return;
    setCoins(e.target.value);
  };
  const getCoinsData = async () => {
    setIsFetched(STATUS.LOADING);
    try {
      if (userRole === "user") {
        const response = await userPaymentServiceApi.get_CC_Topup_Options();
        console.log("response", response);
        setAddCoinsData(response.data.data);
      } else if (userRole === "creator") {
        const response = await creatorPaymentServiceApi.get_CC_Topup_Options();
        console.log("response", response);
        setAddCoinsData(response.data.data);
      }
    } catch (error) {
      setIsFetched(STATUS.ERROR);
      console.log(error);
    }
  };
  const getBillingDetails = async (option: any) => {
    setCoins(option.amountInYc);
    try {
      if (userRole === "user") {
        const response = await userPaymentServiceApi.get_CC_Billing_Detail(
          option.id
        );
        console.log(response);
        setBillingDetails(response.data.data);
        setAmountPayable(response.data.data.totalAmount);
        const {
          amountInRs,
          gstAmount,
          amountInYc,
          discountPercentage,
          totalAmount,
        } = option;
        setOrderDetails({
          amountInRs,
          gstAmount,
          amountInYc,
          discountPercentage,
          discountInRs: (totalAmount * discountPercentage) / 100,
          totalAmount,
        });
      } else if (userRole === "creator") {
        const response = await creatorPaymentServiceApi.get_CC_Billing_Detail(
          option.id
        );
        console.log(response);
        setBillingDetails(response.data.data);
        setAmountPayable(response.data.data.totalAmount);
        const {
          amountInRs,
          gstAmount,
          amountInYc,
          discountPercentage,
          totalAmount,
        } = option;
        setOrderDetails({
          amountInRs,
          gstAmount,
          amountInYc,
          discountPercentage,
          discountInRs: (totalAmount * discountPercentage) / 100,
          totalAmount,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const createOrderAndGenerateSessionId = async () => {
    try {
      setIsCCAdded(STATUS.LOADING);
      if (userRole === "user") {
        const response = await userPaymentServiceApi.create_Order_And_SessionId(
          orderDetails
        );
        setSessionId(response.data.data.sessionId);
        let checkoutOptions = {
          paymentSessionId: response.data.data.sessionId,
          redirectTarget: "_self",
          returnUrl: `${process.env.REACT_APP_CASHFREE_BASE_RETURN_URL}/${userRole}/home`,
          // wallet?myorder={order_id}
        };
        await CashfreeService.initializeSDK("sandbox");
        const cashfree_response = await CashfreeService.checkout(
          checkoutOptions
        );
        console.log("cashfree", cashfree_response);
        if (cashfree_response) {
          setIsCCAdded(STATUS.SUCCESS);
        }
      } else if (userRole === "creator") {
        const response =
          await creatorPaymentServiceApi.create_Order_And_SessionId(
            orderDetails
          );
        setSessionId(response.data.data.sessionId);
        let checkoutOptions = {
          paymentSessionId: response.data.data.sessionId,
          redirectTarget: "_self",
          returnUrl: `${process.env.REACT_APP_CASHFREE_BASE_RETURN_URL}/${userRole}/home`,
          // wallet?myorder={order_id}
        };
        await CashfreeService.initializeSDK("sandbox");
        const cashfree_response = await CashfreeService.checkout(
          checkoutOptions
        );
        console.log("cashfree", cashfree_response);
        if (cashfree_response) {
          setIsCCAdded(STATUS.SUCCESS);
        }
      }
    } catch (error) {
      console.log(error);
      setIsCCAdded(STATUS.ERROR);
    }
  };

  useEffect(() => {
    getCoinsData();
  }, []);
  useEffect(() => {
    if (orderId) {
      if (userRole === "user") {
        userPaymentServiceApi
          .verify_Payment_Status(orderId)
          .then((d) => console.log(d));
      } else if (userRole === "creator") {
        creatorPaymentServiceApi
          .verify_Payment_Status(orderId)
          .then((d) => console.log(d));
      }
    }
  }, []);

  return (
    <Container>
      <Content>
        <PageTagModalComponent
          style={{ borderRadius: "8px 8px 0 0" }}
          close={requestCloseModal}
        >
          Add Coins
        </PageTagModalComponent>
        <Stack px="16px" direction="column" gap="16px">
          <Box textAlign="center">
            <Typography
              component={"span"}
              fontWeight={typography.font_weight.semiBold}
            >
              Current Balance : {availableYC}
            </Typography>
            &nbsp;
            <Typography component="span">CC</Typography>
          </Box>
          <Box>
            <InputLabel>Add Clout Coins</InputLabel>
            <TextField
              fullWidth
              value={coins}
              aria-readonly
              // onChange={handleCoins}
              helperText={
                coins < 50 ? "Minimum CC should be more than 50 CC" : ""
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/Icons/coin_icon.svg" />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputBase-root": {
                  height: "48px",
                },
              }}
            />
          </Box>
          <Stack
            direction="row"
            flexWrap="wrap"
            gap="22px"
            columnGap="8px"
            rowGap="16px"
            justifyContent="space-between"
          >
            {addCoinsData.map((option: any, idx: number) => {
              return (
                <Stack
                  key={idx}
                  position="relative"
                  onClick={() => getBillingDetails(option)}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="8px"
                  width="85px"
                  height="48px"
                  sx={{
                    background: option.amountInYc === coins ? "#E8F5E9" : null,
                    border:
                      option.amountInYc === coins
                        ? "1px solid #2E7D32"
                        : "1px solid rgba(33, 33, 33, 0.20)",
                  }}
                  textAlign="center"
                >
                  <YCValue>CC {option.amountInYc}</YCValue>
                  {option.discountPercentage !== 0 && (
                    <Discount>{option.discountPercentage}% OFF</Discount>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </Stack>
        {coins >= 50 && (
          <BillingDetailsContainer>
            <BillingDetails data={billingDetails} />
          </BillingDetailsContainer>
        )}
        <Box
          maxWidth="sm"
          display="flex"
          flexDirection={"row"}
          justifyContent="space-between"
          sx={{
            position: "absolute",
            bottom: "0",
            left: "0",
            boxShadow: "0px -4px 8px 0px rgba(0, 0, 0, 0.08)",
            width: "100%",
            padding: "8px 8px 8px 16px",
          }}
        >
          <Summary amountPayable={amountPayable} />
          <PayButton
            endIcon={
              isCCAdded === STATUS.LOADING && <CircularProgress size={20} />
            }
            variant={
              coins <= 50 || isCCAdded === STATUS.LOADING
                ? "disabled"
                : "contained"
            }
            disabled={isCCAdded === STATUS.LOADING || coins <= 50}
            onClick={createOrderAndGenerateSessionId}
          >
            Pay
          </PayButton>
        </Box>
      </Content>
    </Container>
  );
};
export { AddCoins };
