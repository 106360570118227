import { Tooltip, SxProps, Theme, Grow, Slide, SlideProps } from '@mui/material';
import React, { ReactNode } from 'react';

interface CustomTooltipProps {
  arrow?: boolean;
  title: string;
  placement: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
  animated?: boolean;
  sx?: SxProps<Theme>;
  children: ReactNode;
}


const normalStyles={
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: "#212121",
    color: "#F3F3F3",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "135%",
    letterSpacing: "0.21px",
    width: "344px",
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ arrow, title, placement, animated, sx, children }) => {
  return (
    <Tooltip
    id="customTooltip"
      arrow={arrow}
      title={title}
      placement={placement}
      TransitionComponent={animated ? CustomSlide : Grow}
    PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip':  normalStyles ,
          '& .MuiTooltip-arrow':{
            color: "#212121",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "135%",
            letterSpacing: "0.21px"

          }
        },
      }}
    >
      <div>{children}</div>
    </Tooltip>
  );
}

export default CustomTooltip;


const CustomSlide: React.FC<SlideProps> = (props) => <Slide {...props} direction="up" />;