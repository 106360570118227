import ApiClient, { ApiService } from "../ApiClient";
import {userAxiosInstance} from "../axiosInstance";

class AuthApi extends ApiService {
    constructor() {
        super(userAxiosInstance);
        this.axios = userAxiosInstance;
      }
    
    async login(credentials: { email: string, password: string }) {
        try {
            const response = await this.axios.post('user/auth/signin', credentials);
            return response.data;
        } catch (error) {
            console.error('Failed to login:', error);
            throw error;
        }
    }

    async signup(credentials: { email: string, password: string, referralCode?: string }) {
        try {
            const response = await this.axios.post('user/auth/signup', credentials);
            return response.data;
        } catch (error) {
            console.error('Failed to signup:', error);
            throw error;
        }
    }

    public async verifyemail(token:string){
        try{
            userAxiosInstance.interceptors.request.use((config) => {
                config.headers['Authorization'] = `Bearer ${token}`;
                return config;
              }, (error) => {
                return Promise.reject(error);
              });
            const response = await this.axios.patch('user/auth/verify');
            return response.data
        }catch(error){
            throw error;
        }
    }

    forgotPassword(email:string){
        return this.axios.post('user/auth/forgot-password',{email});
    }

    resetPassword(resetParams:object){
        return this.axios.post('user/auth/create-password',resetParams)
    }

    resendVerificationMail(userId:string){
        return this.axios.post(`user/auth/resend-verification-email/${userId}`)
    }

}

export default new AuthApi();
