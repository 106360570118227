import styled from "@emotion/styled";
import { FormHelperText, TextField } from "@mui/material";
import { ChangeEventHandler, KeyboardEventHandler } from "react";

const StyledInput = styled(TextField)(({ theme }) => ({
'& .MuiInputBase-root': {
        height:'48px',
        // padding :'4px 8px',
        letterSpacing: '0.24px',
        color:'#616161',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
        borderColor: 'gray',
        },
        '&:hover fieldset': {
        borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
        borderColor: 'black',
        borderWidth: 2,
        },
        },
    },
}));

interface TextInputProps {
  value: any;
  onChange: ChangeEventHandler;
  onKeyDown?:KeyboardEventHandler;
  placeholder: string;
  name: string;
  disabled?: boolean;
  helperText?: string;
  isAvailable?:any;
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  placeholder,
  value,
  onChange,
  onKeyDown,
  disabled = false,
  helperText = '',
  isAvailable = null
}) => {
  let helperTextContent;

  if (helperText) {
    if (isAvailable === true) {
      helperTextContent = <FormHelperText sx={{ color: '#2E7D32',marginLeft:0 }}>Available</FormHelperText>;
    } else if (isAvailable === false) {
      helperTextContent = <FormHelperText sx={{ color: '#B71C1C',marginLeft:0 }}>Handle name unavailable</FormHelperText>;
    } else {
      helperTextContent = '';
    }
  }
  return (
    <StyledInput
      fullWidth
      name={name}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      disabled={disabled}
      helperText={helperTextContent}
      // FormHelperTextProps={{
      //   children: helperText
      //     ? isAvailable
      //       ? <FormHelperText sx={{ color: 'green' }}>Available</FormHelperText>
      //       : <FormHelperText sx={{ color: 'red' }}>Handle name unavailable</FormHelperText>
      //     : ''
      // }}
    />
  );
};

export { TextInput };
