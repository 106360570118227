import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import creatorWalletServiceApi from "../../../api/creator/creatorWalletServiceApi";
import useToast from "../../../hooks/useToast";
import moment from "moment";

type ServiceType = "TIPS" | "PAYMENT" | "SUBSCRIPTION" | "DMS" | "POST";

const serviceTypeIcon: Record<ServiceType, string> = {
  TIPS: "/Icons/serviceType/tips.svg",
  PAYMENT: "/Icons/serviceType/payment.svg",
  SUBSCRIPTION: "/Icons/serviceType/subscription.svg",
  DMS: "/Icons/serviceType/dm.svg",
  POST: "/Icons/serviceType/post.svg",
};

const TransactionsDetails = ({data}:{data:any}) =>{
    const makeDescription = () =>{
        switch(data.serviceType.toUpperCase()){
            case "TIPS":
                return `To - ${data?.toUser ?? "-"}`
            case "PAYMENT":
                return `Paid - INR ${data.amountInRs}`
            case "POST":
                return data.transactionType === "DEBIT" ? `Post brought from - @${data.toUser}` :`Purchased post - @${data.fromUser}` 
             case "SUBSCRIPTION":
                return `From - @${data?.fromUser ?? "-"}`
            default :
                return "--"
        }
    }
    const getSign = () =>{
        if(data.transactionType === "DEBIT"){
            return "-"
        }
        else{
            return "+"
        }
    }
    const iconSrc = serviceTypeIcon[data?.serviceType.toUpperCase() as ServiceType];
    return (
        <Stack direction='row' gap='18px' pt='16px' >
           <img src ={iconSrc} style={{alignSelf:'flex-start'}} width="24px" height="24px"/>
            <Stack direction='row' pb='16px' justifyContent='space-between' flexGrow={1} borderBottom='1px solid #616161' >
                <Box>
                    <Typography fontSize='12px' fontWeight={700}>
                    {data?.message ?? ""}
                    </Typography>
                    <Typography fontWeight={400} fontSize='10px'>
                    {makeDescription()}
                    </Typography>
                    <Typography fontSize='8px' color="#9E9E9E">
                    {moment.utc(data.createdAt).local().format('D MMMM YY, h:mma')}
                    </Typography>
                </Box>
            <Typography fontSize='12px' color='#616161' pr='16px'>{getSign()}CC {data.amountInYc ?? ""}</Typography>
            </Stack>
        </Stack>
    )
}

const PendingTxn = () => {
  const { ToastMessage, handleOpenToast } = useToast();
  const [pendingTransactionData, setPendingTransactionData] = useState([]);
  const fetchPendingTransactionHistory = async () => {
    try {
      const response = await creatorWalletServiceApi.getPendingHistory();
      setPendingTransactionData(response.data.data.data);
    } catch (error) {
      handleOpenToast("Something went wrong.", "error");
    }
  };
  useEffect(() => {
    fetchPendingTransactionHistory();
  }, []);

  if (pendingTransactionData.length === 0) {
    return (
      <Typography mt="3rem" textAlign="center">
        No Pending History
      </Typography>
    );
  }

  return (
    <Box>
      <ToastMessage />
      {pendingTransactionData &&
        pendingTransactionData?.map((txn: any) => {
          return <TransactionsDetails data={txn} />;
        })}
    </Box>
  );
};
export default PendingTxn;
