import { Box, Button, Typography } from "@mui/material";
import { colors, typography } from "../../utils/styles";

const NoPurchasedContent = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="20px"
      mt="180px"
    >
      <img src="/Images/submission.svg" width="200px" height="150px" />
      <Typography
        fontSize={typography.font_size["xl"]}
        fontWeight={typography.font_weight["bold"]}
      >
        No Content Purchased
      </Typography>
      <Typography
        fontSize={typography.font_size["sm"]}
        color={colors.gray["80"]}
        textAlign="center"
      >
        Explore Creator & purchase to <br />
        access exclusive content
      </Typography>

      <Button type="submit" variant="primary">
        Go to Home
      </Button>
    </Box>
  );
};

export { NoPurchasedContent };