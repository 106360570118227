import {creatorAxiosInstance} from "../axiosInstance";

creatorAxiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('creatorAccessToken'); 
  if (token) {
    let parsedToken = JSON.parse(token);
    config.headers['Authorization'] = `Bearer ${parsedToken}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
