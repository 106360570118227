import React from 'react'
import AppModals from './AppModals';
import { useModal } from '../context/ModalContext';



const InAppModal = () => {

const { activeAppModals,  requestCloseModal } = useModal();

  return (
    <>
  {activeAppModals.length>=1 &&  
      activeAppModals.map((modal, index) => (
        modal.component && (
        <AppModals
          key={index}
          name={modal.name}
          component={modal.component}
          close={requestCloseModal}
        >
          {modal.component}
        </AppModals>
        )
      ))} 
      </>
  )
}

export default InAppModal;