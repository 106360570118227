import { Box, Button, styled, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { peer } from "../../services/peer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import callSocketService from "../../services/callSocketService";
import { colors } from "../../utils/styles";
import { MuteIcon, SpeakerIcon, EndIcon } from "../../assets/SvgIcons";
import Stream from "../../components/CallService/Stream";
import { calculateRemainingDuration, formatTime } from "../../utils/formatTime";
import creatorCallServiceApi from "../../api/call/creatorCallService";
import userCallServiceApi from "../../api/call/userCallService";

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  width: "100vw",
  backgroundColor: colors.violet[100],
  position: "relative",
  overflow: "hidden",
});

const RemoteNameContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  padding: "16px 24px",
  top: "22%",
  zIndex: 1,
  minWidth: "170px",
  minHeight: "80px",
});

const ProfilePicContainer = styled(Box)({
  position: "relative",
  width: "110px",
  height: "110px",
  borderRadius: "50%",
  backgroundColor: colors.white,
  padding: "4px",
  marginBottom: "10px",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
});

const ProfileImage = styled("img")({
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  objectFit: "cover",
});

const ActionButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  padding: "4px",
  bottom: "158px",
  zIndex: 1,
  minWidth: "314px",
  minHeight: "72px",
  gap: "40px",
});

const ActionButton = styled(Button)(({ enabled }: { enabled: boolean }) => ({
  width: "64px",
  height: "64px",
  borderRadius: "50%",
  backgroundColor: enabled ? "#ffffff" : "transparent",
  boxShadow: enabled ? "0px 0px 10px rgba(0, 0, 0, 0.3)" : "none",
  "&:hover": {
    backgroundColor: enabled ? "#f2f2f2" : "transparent",
  },
}));

const TimeStrip = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "24px",
  backgroundColor: colors.sky[50],
  zIndex: 1,
});

const AudioCallJoin = () => {
  const { roomId } = useParams();
  const [searchParams] = useSearchParams();
  const userName = searchParams.get("userName");
  const userRole = searchParams.get("userRole");
  const profilePhotoUrl = searchParams.get("profilePhotoUrl");
  const endTime = searchParams.get("endTime");
  const [callEstablished, setCallEstablished] = useState(false);
  const navigate = useNavigate();

  const [remoteSocketId, setRemoteSocketId] = useState<string | null>(null);
  const [remoteName, setRemoteName] = useState<string | null>(null);
  const [remoteProfilePhotoUrl, setRemoteProfilePhotoUrl] = useState<
    string | null
  >(null);
  const [myStream, setMyStream] = useState<MediaStream | null>(null);
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
  const [readyToSendStream, setReadyToSendStream] = useState<boolean>(false);
  const [timer, setTimer] = useState(0);
  const [remainingDuration, setRemainingDuration] = useState<number>(0);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [isMuted, setIsMuted] = useState(false);
  const [isSpeakerOff, setIsSpeakerOff] = useState(false);

  const sendStreams = () => {
    if (myStream && peer?.peer) {
      myStream.getTracks().forEach((track) => {
        peer.peer?.addTrack(track, myStream);
      });
    }
  };

  const handleUserJoined = ({
    socketId,
    userName,
    profilePhotoUrl,
  }: {
    userName: string;
    socketId: string;
    profilePhotoUrl: string;
  }) => {
    setRemoteSocketId(socketId);
    setRemoteName(userName);
    setRemoteProfilePhotoUrl(profilePhotoUrl);
  };

  const handleCallUser = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setMyStream(stream);
      const offer = await peer.getOffer();
      if (remoteSocketId) {
        callSocketService.emitMessage("audio:call:user:call", {
          to: remoteSocketId,
          offer,
          userName,
          profilePhotoUrl,
        });
        setCallEstablished(true);
      }
    } catch (error) {
      console.error("Error initiating call:", error);
    }
  };

  const handleIncomingCall = async ({
    from,
    offer,
    userName,
    profilePhotoUrl,
  }: {
    from: string;
    offer: RTCSessionDescriptionInit;
    userName: string;
    profilePhotoUrl: string;
  }) => {
    try {
      setRemoteSocketId(from);
      setRemoteName(userName);
      setRemoteProfilePhotoUrl(profilePhotoUrl);

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setMyStream(stream);
      const answer = await peer.getAnswer(offer);
      callSocketService.emitMessage("audio:call:call:accepted", {
        to: from,
        answer,
      });
      setReadyToSendStream(true);
      setCallEstablished(true);
    } catch (error) {
      console.error("Error handling incoming call:", error);
    }
  };

  const handleCallAccepted = ({
    from,
    answer,
  }: {
    from: string;
    answer: RTCSessionDescriptionInit;
  }) => {
    try {
      peer.setLocalDescription(answer);
      setReadyToSendStream(true);
    } catch (err) {
      console.error("Error accepting call:", err);
    }
  };

  const handleNegotiationNeeded = async () => {
    try {
      const offer = await peer.getOffer();
      if (remoteSocketId) {
        callSocketService.emitMessage("audio:call:peer:nego:needed", {
          to: remoteSocketId,
          offer,
        });
      }
    } catch (error) {
      console.error("Error in negotiation:", error);
    }
  };

  const handleNegoNeedIncoming = async ({
    from,
    offer,
  }: {
    from: string;
    offer: RTCSessionDescriptionInit;
  }) => {
    try {
      const ans = await peer.getAnswer(offer);
      callSocketService.emitMessage("audio:call:peer:nego:done", {
        to: from,
        ans,
      });
    } catch (error) {
      console.error("Error handling negotiation:", error);
    }
  };

  const handleNegoNeedFinal = async ({
    answer,
  }: {
    answer: RTCSessionDescriptionInit;
  }) => {
    try {
      await peer.setLocalDescription(answer);
    } catch (error) {
      console.error("Error setting local description:", error);
    }
  };

  const handleCallEnded = () => {
    const reason = "Call-ended";
    console.log("Call ended by remote peer:", reason);
    cleanupAndNavigate(reason);
  };

  const cleanupAndNavigate = async (reason: string) => {
    console.log("Call ended:", reason);
    console.log("Call duration:", formatTime(timer));

    if (userRole === "creator") {
      await creatorCallServiceApi.endCall({
        roomId,
        callDuration: timer,
      });
    } else {
      await userCallServiceApi.endCall({
        roomId,
        callDuration: timer,
      });
    }

    if (myStream) {
      myStream.getTracks().forEach((track) => {
        track.stop();
        track.enabled = false;
      });
      setMyStream(null);
    }

    if (remoteStream) {
      remoteStream.getTracks().forEach((track) => {
        track.stop();
        track.enabled = false;
      });
      setRemoteStream(null);
    }

    if (peer?.peer) {
      peer.peer.close();
      peer.peer = null;
    }

    setCallEstablished(false);
    setRemoteSocketId(null);

    if (remoteSocketId) {
      callSocketService.emitMessage("audio:call:ended", {
        to: remoteSocketId,
        reason: reason,
      });
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop();
          track.enabled = false;
        });
      })
      .catch((err) => console.error("Error releasing media devices:", err));

    callSocketService.disconnect();
    navigate(-1);
  };

  const handleEndCall = (reason?: string) => {
    cleanupAndNavigate(reason || "user_ended");
  };

  const handleMuteToggle = () => {
    if (myStream) {
      const audioTrack = myStream.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        setIsMuted(!audioTrack.enabled);
      }
    }
  };

  const handleSpeakerToggle = () => {
    if (remoteStream && audioRef.current) {
      const audioTrack = remoteStream.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        setIsSpeakerOff(!audioTrack.enabled);

        if (!audioTrack.enabled) {
          audioRef.current.pause();
        } else if (audioRef.current.readyState >= 3) {
          audioRef.current.play().catch((error) => {
            console.error("Error playing audio:", error);
          });
        }
      }
    }
  };

  useEffect(() => {
    if (roomId && userName) {
      callSocketService.connect(userName, roomId);
      callSocketService.emitMessage("audio:call:room:join", {
        userName,
        roomId,
        profilePhotoUrl,
      });

      callSocketService.onMessage("audio:call:user:joined", handleUserJoined);
      callSocketService.onMessage(
        "audio:call:incomming:call",
        handleIncomingCall
      );
      callSocketService.onMessage(
        "audio:call:call:accepted",
        handleCallAccepted
      );
      callSocketService.onMessage(
        "audio:call:peer:nego:needed",
        handleNegoNeedIncoming
      );
      callSocketService.onMessage(
        "audio:call:peer:nego:final",
        handleNegoNeedFinal
      );
      callSocketService.onMessage("audio:call:ended", handleCallEnded);

      return () => {
        callSocketService.offMessage("audio:call:user:joined");
        callSocketService.offMessage("audio:call:incomming:call");
        callSocketService.offMessage("audio:call:call:accepted");
        callSocketService.offMessage("audio:call:peer:nego:needed");
        callSocketService.offMessage("audio:call:peer:nego:final");
        callSocketService.offMessage("audio:call:ended");
        callSocketService.disconnect();
      };
    }
  }, [roomId, userName]);

  useEffect(() => {
    if (myStream && readyToSendStream) {
      sendStreams();
    }
  }, [readyToSendStream, myStream]);

  useEffect(() => {
    if (remoteSocketId && !callEstablished) {
      handleCallUser();
    }
  }, [remoteSocketId, callEstablished]);

  useEffect(() => {
    if (peer?.peer) {
      const handleTrack = async (ev: RTCTrackEvent) => {
        setRemoteStream(ev.streams[0]);
      };

      peer.peer.addEventListener("track", handleTrack);
      peer.peer.addEventListener("negotiationneeded", handleNegotiationNeeded);

      return () => {
        peer.peer?.removeEventListener("track", handleTrack);
        peer.peer?.removeEventListener(
          "negotiationneeded",
          handleNegotiationNeeded
        );
      };
    }
  }, [peer?.peer]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (callEstablished) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [callEstablished]);

  useEffect(() => {
    if (endTime) {
      const initialRemaining = calculateRemainingDuration(endTime);
      setRemainingDuration(initialRemaining);

      const interval = setInterval(() => {
        const remaining = calculateRemainingDuration(endTime);
        setRemainingDuration((prev) => {
          if (remaining <= 0 && prev > 0) {
            handleEndCall("time_up");
          }
          return remaining;
        });
      }, 60000);

      if (initialRemaining <= 0) {
        handleEndCall("time_up");
      }

      return () => clearInterval(interval);
    }
  }, [endTime]);

  return (
    <Container>
      {remoteStream && (
        <>
          <audio ref={audioRef} autoPlay={true} />
          <TimeStrip>
            <Typography
              sx={{
                color: colors.red[100],
                fontSize: "12px",
                fontWeight: 400,
                letterSpacing: "1.5%",
                fontFamily: "Poppins",
                width: "100%",
                textAlign: "center",
                zIndex: 1,
              }}
            >
              {remainingDuration <= 0
                ? "Call ending..."
                : `${remainingDuration} min left${
                    remainingDuration <= 5 ? " - Call ending soon" : ""
                  }`}
            </Typography>
          </TimeStrip>
        </>
      )}

      {remoteStream ? (
        <>
          <RemoteNameContainer>
            <ProfilePicContainer>
              <ProfileImage src={remoteProfilePhotoUrl!} alt="User" />
            </ProfilePicContainer>
            <Typography
              sx={{
                color: colors.white,
                fontSize: "20px",
                fontWeight: "bold",
                fontFamily: "Poppins",
                zIndex: 1,
              }}
            >
              {remoteName}
            </Typography>
            <Typography
              sx={{
                color: colors.white,
                fontSize: "18px",
                fontWeight: 400,
                zIndex: 1,
              }}
            >
              {formatTime(timer)}
            </Typography>
          </RemoteNameContainer>

          <Stream
            stream={remoteStream}
            muted={isSpeakerOff}
            isOwner={false}
            fullScreen
          />
        </>
      ) : (
        <Typography color="white">Waiting for other user...</Typography>
      )}
      {remoteStream && (
        <ActionButtonContainer>
          <ActionButton enabled={isSpeakerOff} onClick={handleSpeakerToggle}>
            <SpeakerIcon
              color={isSpeakerOff ? colors.violet[100] : "#F3F3F3"}
            />
          </ActionButton>

          <ActionButton
            enabled={true}
            onClick={() => handleEndCall("Call ended")}
            sx={{
              backgroundColor: colors.red[100],
              "&:hover": { backgroundColor: "#FF0000" },
            }}
          >
            <EndIcon />
          </ActionButton>

          <ActionButton enabled={isMuted} onClick={handleMuteToggle}>
            <MuteIcon color={isMuted ? colors.violet[100] : "#F3F3F3"} />
          </ActionButton>
        </ActionButtonContainer>
      )}
    </Container>
  );
};

export default AudioCallJoin;
