import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Typography, styled } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { colors, typography } from "../../utils/styles";
import { convertNumberToProfileCount } from "../../utils/ProfileUtilFunctions";
import userApi from "../../api/user/userApi";
import { setUser } from "../../redux/appUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  height: 100vh;
  background-color: ${colors.background_primary};
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-bottom: 5rem;
`;

const ImageContainer = styled(Box)`
  position: relative;
`;

const Image = styled("img")`
  width: 100%;
  height: 181px;
  object-fit: cover;
`;

const BackgroundContainer = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #212121;
  opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileDescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ProfileDescHeader = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  bottom: 4px;
`;

const LeftContainer = styled(Box)`
  display: flex;
  padding-right: 1rem;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
`;

const ProfileAchievenmentsContainer = styled(Box)`
  display: flex;
  border-top: 1px solid ${colors.gray[20]};
  border-bottom: 1px solid ${colors.gray[20]};
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const ProfileAchievementsContent = styled(Box)`
  display: flex;
  padding: 6px 12px;
  justify-content: space-evenly;
  align-items: center;
  align-self: stretch;
`;

const ProfileAchievementsItem = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  text-decoration: none;
`;

const ActivityContainer = styled(Box)`
  background: green;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.5rem;
  position: absolute;
  left: 83%;

  bottom: 5px;
  border: 1px solid white;
`;

const ItemContent = styled(Typography)`
  color: ${colors.black[50]};
  text-align: center;
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: 0.15px;
`;

const ShowcasingContentBox = styled(Box)`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  width: 100%;
`;

const ShowCasingItem = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  width: 89px;
  color: ${colors.black[200]};
  font-size: 10px;
  font-weight: 400;
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  background: #fff;
  height: 24px;
`;

const ShowcasingButtonsContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(89px, max-content));
  gap: 8px;
  width: 100%;
  justify-content: space-between;
`;

const ProfileDescription = styled(Typography)`
  overflow: hidden;
  color: #616161;
  text-align: justify;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.18px;
  display: inline;
`;

const UserProfile: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [viewText, setViewText] = useState<boolean>(false);

  const userInfo = useSelector((state: RootState) => state.user);

  const handleViewText = () => {
    setViewText((prev) => !prev);
  };
  const handleOnClickEditButton = () => {
    navigate("/user/edit/profile/", { state: { userInfo } });
  };

  const getUserProfile = async () => {
    try {
      const response = await userApi.getUserProfile();
      const userDetais = response.data?.user;
      dispatch(setUser(userDetais));
    } catch (error) {}
  };

  useEffect(() => {
    if (!userInfo.id) {
      getUserProfile();
    }
  }, []);

  return (
    <Container>
      {!userInfo.coverPhotoUrl ? (
        <ImageContainer>
          <Image src="/Images/no_cover.png" />
          <BackgroundContainer></BackgroundContainer>
        </ImageContainer>
      ) : (
        <Image src={userInfo.coverPhotoUrl} />
      )}

      <ProfileDescriptionContainer>
        <ProfileDescHeader>
          <LeftContainer>
            <Box sx={{ position: "relative" }}>
              <Avatar
                sx={{ width: "3.5rem", height: "3.5rem" }}
                alt="user"
                src={userInfo?.profilePhotoUrl ?? ""}
              />
              <ActivityContainer />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: typography.font_weight.semiBold,
                  color: colors.black[200],
                  letterSpacing: ".02rem",
                }}
              >
                {userInfo.displayName}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: typography.font_weight.regular,
                  color: colors.gray[700],
                  letterSpacing: ".02rem",
                }}
              >
                {userInfo?.handleName ? `@${userInfo?.handleName}` : ""}
              </Typography>
            </Box>
          </LeftContainer>

          <Button
            onClick={handleOnClickEditButton}
            sx={{
              cursor: "pointer",
              color: "black",
              letterSpacing: ".02rem",
              display: "flex",
              minWidth: "24px",
              height: "24px",
              padding: "4.5px 0px 5.5px 0px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Vector"
                d="M11.2299 0.820243C11.4894 0.560762 11.7974 0.354923 12.1364 0.214479C12.4754 0.0740348 12.8387 0.00173553 13.2056 0.00170899C13.5726 0.00168245 13.9359 0.0739292 14.2749 0.214325C14.6139 0.35472 14.922 0.560514 15.1815 0.819957C15.4409 1.0794 15.6468 1.38741 15.7872 1.72641C15.9277 2.0654 16 2.42873 16 2.79567C16 3.1626 15.9278 3.52595 15.7874 3.86496C15.647 4.20398 15.4412 4.51202 15.1818 4.7715L14.6663 5.28641L10.715 1.33515L11.2299 0.820243ZM9.90693 2.14381L1.34721 10.7047C1.05901 10.9927 0.860159 11.3578 0.774581 11.7562L0.0127901 15.3109C-0.00729948 15.4042 -0.00367597 15.5011 0.0233296 15.5927C0.0503351 15.6843 0.0998617 15.7677 0.167385 15.8352C0.234907 15.9027 0.318274 15.9522 0.409867 15.9792C0.501461 16.0062 0.598361 16.0099 0.691716 15.9898L4.24636 15.228C4.64472 15.1424 5.00988 14.9436 5.2979 14.6554L13.8588 6.09449L9.90693 2.14381Z"
                fill="#9E9E9E"
              />
            </svg>
          </Button>
        </ProfileDescHeader>

        <Box sx={{ margin: ".5rem 0", padding: "0 1rem" }}>
          <Typography
            sx={{
              width: "100%",
              display: "block",
            }}
          >
            {userInfo?.description && userInfo?.description.length > 130 ? (
              <>
                <ProfileDescription>
                  {viewText
                    ? userInfo?.description
                    : `${userInfo?.description.substring(0, 131)}...`}
                </ProfileDescription>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    color: colors.brand_primary,
                    cursor: "pointer",
                    display: "inline",
                    marginLeft: "5px",
                  }}
                  onClick={handleViewText}
                >
                  {viewText ? "See less" : "See more"}
                </Typography>
              </>
            ) : (
              <ProfileDescription>{userInfo?.description}</ProfileDescription>
            )}
          </Typography>
        </Box>

        <ProfileAchievenmentsContainer>
          <ProfileAchievementsContent>
            <ProfileAchievementsItem to={`/following/${userInfo?.id}`}>
              <ItemContent variant="body1">
                {convertNumberToProfileCount(userInfo?.followingCount)}
              </ItemContent>
              <ItemContent variant="body1">Followings</ItemContent>
            </ProfileAchievementsItem>

            <ProfileAchievementsItem to="/user/users-subscription">
              <ItemContent variant="body1">
                {convertNumberToProfileCount(userInfo?.followingCount)}
              </ItemContent>
              <ItemContent variant="body1">Subscription</ItemContent>
            </ProfileAchievementsItem>
          </ProfileAchievementsContent>
        </ProfileAchievenmentsContainer>

        <ShowcasingContentBox>
          <Typography
            variant="body1"
            sx={{
              fontSize: typography.font_size.sm,
              fontWeight: typography.font_weight.bold,
              color: colors.black[200],
              paddingBottom: "0.5rem",
              flexShrink: "0",
              width: "100%",
            }}
          >
            Content preferences:
          </Typography>

          <ShowcasingButtonsContainer>
            {userInfo?.preferredContentCategory.map(
              (profileDataItem, index) => (
                <ShowCasingItem key={index}>{profileDataItem}</ShowCasingItem>
              )
            )}
          </ShowcasingButtonsContainer>
        </ShowcasingContentBox>
      </ProfileDescriptionContainer>
    </Container>
  );
};

export default UserProfile;
