
import { ApiService } from '../ApiClient';
import {creatorAxiosInstance} from '../axiosInstance';
import '../interceptors/creatorAuthInterceptors';

class WalletService extends ApiService {
  constructor() {
    super(creatorAxiosInstance);
  }
  get_Wallet_Details(){
    return this.axios.get(`/payment/wallet`);
  }
  get_CC_Topup_Options() {
    return this.axios.get(`/payment/add-money-config`);
  }
  get_CC_Billing_Detail(ccid:string){
    return this.axios.get(`/payment/add-money-config/${ccid}`)
  }

  getHistory(){
    return this.axios.get('payment/transaction-history/creator?page=1&pageSize=10&type=history')
  }
  getPendingHistory(){
    return this.axios.get('payment/transaction-history/creator?page=1&pageSize=10&type=pending')
  }
}

export default new WalletService();
