import axios, { AxiosInstance } from 'axios';
let baseURL = 'https://api.dev.yarra.social/api/v1/'

const userAxiosInstance:AxiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
const creatorAxiosInstance:AxiosInstance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export {creatorAxiosInstance,userAxiosInstance};
