// src/services/socketService.ts
import { io, Socket } from "socket.io-client";

const SOCKET_URL = "https://api.dev.yarra.social";
// "https://api.dev.yarra.social"; // Replace with your server URL

class SocketService {
  private socket: Socket | null = null;

  // Initialize socket connection
  connect(payload: { userId: string }) {
    this.socket = io(SOCKET_URL, {
      transports: ['websocket'], 
    });

    this.socket.on("connect", () => {
      console.log("Connected to the socket server");
      if (this.socket) {
        this.socket.emit("join", payload);
      }
    });

    this.socket.on("disconnect", () => {
      console.log("Disconnected from the socket server");
    });
  }

  // Emit a message
  emitMessage(event: any, data: any) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }
  disconnectWithPayload(payload: { userId: string }) {
    if (this.socket) {
      this.socket.emit("leaving", payload);
      this.socket.disconnect();
    }
  }

  // Listen for an event
  onMessage(event: string, callback: (data: any) => void) {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }

  // Remove an event listener
  offMessage(event: string) {
    if (this.socket) {
      this.socket.off(event);
    }
  }

  // Disconnect the socket
  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}

export default new SocketService();
