import React, { useEffect, useState } from "react";
import { Box, Typography, Tab, Tabs, styled } from "@mui/material";
import { CartIcon, LockIcon, UnLockIcon } from "../../../../assets/SvgIcons";
import GridView from "./GridView";
import { colors } from "../../../../utils/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { UserFeedType } from "../../../../types";
import NoSubscription from "../../../Subscription/NoSubscription";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  position: relative;
`;

const TitleBar = styled(Box)`
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

const Title = styled(Typography)`
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  flex: 1 0 0;
`;

const TabContainer = styled(Box)`
  border-bottom: 2px solid rgba(33, 33, 33, 0.1);
`;

const TabsItems = styled(Tabs)({
  "& .MuiTabs-indicator": {
    height: "8px",
    borderRadius: "12px",
    bottom: "-5px",
  },
});

const TabItem = styled(Tab)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
`;
const LabelContainer = styled(Box)``;

const LabelText = styled(Typography)`
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.15px;
`;
const TabMainContainer = styled(Box)`
  display: flex;
  padding: 0px 8px;
  align-items: flex-start;
  align-self: stretch;
`;
const ContentContainer = styled(Box)`
  // grid-template-columns: repeat(auto-fit, minmax(122px, 1fr));
  display: grid;
  gap: 3px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
`;

const ContainerHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: sticky;
  top: 0;
  background: white;
  padding-bottom: 2px;
  z-index: 12;
`;

const NoSubscriptionContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoPostFoundScreen = styled(Box)`
  width: 100%;
`;

const NoPostContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NoPostText = styled(Typography)`
  color: var(--Text-Accent-2, #616161);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  letter-spacing: 0.24px;
`;

const contentStatus = {
  LOCKED: "Locked",
  PURCHASED: "Purchased",
  FREE: "Free",
};

interface CreatorContentProps {}
const CreatorContent: React.FC<CreatorContentProps> = ({}) => {
  const { lockedPostList, freePostList, purchasedPostList } = useSelector(
    (state: RootState) => state.anotherCreator
  );
  const [value, setValue] = React.useState<string>();
  const [activeTab, setActiveTab] = useState<string>("");
  const [post, setPost] = useState<UserFeedType[]>();
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    setValue(newValue);
    setTimeout(() => {
      setActiveTab(newValue);
    }, 200);
  };

  useEffect(() => {
    setValue(contentStatus.LOCKED);
    setActiveTab(contentStatus.LOCKED);
  }, []);

  useEffect(() => {
    if (activeTab === contentStatus.LOCKED) {
      setPost(lockedPostList);
    } else if (activeTab === contentStatus.PURCHASED) {
      setPost(purchasedPostList);
    } else {
      setPost(freePostList);
    }
  }, [activeTab, lockedPostList, purchasedPostList, freePostList]);

  const LockedLabel = () => {
    return (
      <LabelContainer>
        <LockIcon
          color={value === contentStatus.LOCKED ? colors.violet[100] : ""}
        />
        <LabelText
          variant="body2"
          color="textSecondary"
          sx={{
            color: value === contentStatus.LOCKED ? colors.violet[100] : "",
          }}
        >
          Locked
        </LabelText>
      </LabelContainer>
    );
  };

  const PurchasedLabel = () => {
    return (
      <LabelContainer>
        <CartIcon
          color={value === contentStatus.PURCHASED ? colors.violet[100] : ""}
        />
        <LabelText
          variant="body2"
          color="textSecondary"
          sx={{
            color: value === contentStatus.PURCHASED ? colors.violet[100] : "",
          }}
        >
          Purchased
        </LabelText>
      </LabelContainer>
    );
  };

  const FreeLabel = () => {
    return (
      <LabelContainer>
        <UnLockIcon
          color={value === contentStatus.FREE ? colors.violet[100] : ""}
        />
        <LabelText
          variant="body2"
          color="textSecondary"
          sx={{ color: value === contentStatus.FREE ? colors.violet[100] : "" }}
        >
          Free
        </LabelText>
      </LabelContainer>
    );
  };

  const NoPostsScreen: React.FC<{ text: string }> = ({ text }) => {
    return (
      <NoPostContainer>
        <NoPostText>No {text} posts available.</NoPostText>
      </NoPostContainer>
    );
  };

  return (
    <Container>
      <ContainerHeader>
        <TitleBar>
          <Title>Creator's Content</Title>
        </TitleBar>
        <TabMainContainer>
          <TabContainer sx={{ width: "100%" }}>
            <TabsItems
              value={value}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
            >
              <TabItem value={contentStatus.LOCKED} label={<LockedLabel />} />
              <TabItem
                value={contentStatus.PURCHASED}
                label={<PurchasedLabel />}
              />
              <TabItem value={contentStatus.FREE} label={<FreeLabel />} />
            </TabsItems>
          </TabContainer>
        </TabMainContainer>
      </ContainerHeader>
      <ContentContainer>
        {activeTab &&
          post?.map((item, index) => (
            <GridView item={item} key={index} activeTab={activeTab} />
          ))}
      </ContentContainer>

      <NoPostFoundScreen>
        {activeTab === contentStatus.PURCHASED && post?.length === 0 ? (
          <NoSubscriptionContainer>
            <NoSubscription />
          </NoSubscriptionContainer>
        ) : activeTab === contentStatus.LOCKED && post?.length === 0 ? (
          <NoPostsScreen text="Locked" />
        ) : activeTab === contentStatus.FREE && post?.length === 0 ? (
          <NoPostsScreen text="Free" />
        ) : null}
      </NoPostFoundScreen>
    </Container>
  );
};

export default CreatorContent;
