import {
  Avatar,
  Box,
  Button,
  Typography,
  styled,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { colors, typography } from "../../../utils/styles";
import { convertNumberToProfileCount } from "../../../utils/ProfileUtilFunctions";
import { CalendarIcon } from "../../../assets/SvgIcons";
import CreatorContent from "./CreatorContent/ContentView";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import CreatorsServices from "./CreatorsServices";
import usersCreatorProfileServiceApi from "../../../api/user/creatorServiceApi";
import creatorsCreatorProfileServiceApi from "../../../api/creator/creatorProfileServiceApi";
import {
  setAddFollowing,
  setRemoveFollowing,
} from "../../../redux/creatorServiceSlice";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  min-height: 100vh;
  height: auto;
  background-color: ${colors.background_primary};
  display: flex;
  flex-direction: column;
  background-color: white;
`;
const ImageContainer = styled(Box)`
  position: relative;
`;

const Image = styled("img")`
  width: 100%;
  height: 181px;
  object-fit: cover;
`;

const BackgroundContainer = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.black[200]};
  opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileDescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ProfileDescHeader = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  bottom: 4px;
`;

const LeftContainer = styled(Box)`
  display: flex;
  padding-right: 1rem;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
`;

const ProfileAchievenmentsContainer = styled(Box)`
  display: flex;
  border-top: 1px solid ${colors.gray[20]};
  border-bottom: 1px solid ${colors.gray[20]};
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const ProfileAchievementsContent = styled(Box)`
  display: flex;
  padding: 6px 2px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ProfileAchievementsItem = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  text-decoration: none;
`;

const ActivityContainer = styled(Box)`
  background: green;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.5rem;
  position: absolute;
  left: 83%;
  bottom: 5px;
  border: 1px solid white;
`;

const ItemContent = styled(Typography)`
  color: ${colors.black[50]};
  text-align: center;
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: 0.15px;
`;

const ShowcasingContentBox = styled(Box)`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  width: 100%;
`;

const ShowCasingItem = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  width: 89px;
  color: ${colors.black[200]};
  font-size: 10px;
  font-weight: 400;
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  background: #fff;
  height: 24px;
`;

const ShowcasingButtonsContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(89px, max-content));
  gap: 8px;
  width: 100%;
  justify-content: space-between;
`;

const BottomContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  gap: 16px;
  overflow: visible;
  // min-height: 100vh;
`;

const UnfollowButton = styled(Button)`
  color: var(--Text-Tertiary, #9e9e9e);
  text-align: center;
  /* Heading/H6-B */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 16.2px */
  letter-spacing: 0.18px;
`;

const UnclickableAchievmentsDivider = styled(Box)`
  opacity: 0.6;
  background: var(--Border-Primary, #7262c2);
  width: 1px;
  height: 29px;
`;

const CreatorOfferingsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
`;
const CreatorOfferingsTitleBar = styled(Box)`
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;
`;

const CreatorOfferingsTitle = styled(Typography)`
  color: var(--Text-Primary, #212121);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
`;

const AvailabilityConatiner = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AvailabilityText = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Brand-Primary, #7262c2);
  text-overflow: ellipsis;

  /* Body/M-B */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 16.2px */
`;

const CreatorOfferingsContentContainer = styled(Box)`
  display: flex;
  padding: 8px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const CreatorOfferingsContent = styled(Box)`
  display: flex;
  align-items: center;
  gap: 53px;
  align-self: stretch;
  justify-content: space-between;
`;
const CreatorOfferingsContentText = styled(Box)`
  color: var(--Background-brand-primary, #7262c2);

  /* Heading/H6-B */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 16.2px */
  letter-spacing: 0.18px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const SubscriptionText = styled(Typography)`
  color: var(--Background-brand-primary, #7262c2);

  /* Heading/H6-B */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 16.2px */
  letter-spacing: 0.18px;
`;

const SubscriptionValidity = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Secodary, #616161);
  text-overflow: ellipsis;

  /* Body/S-R */
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 13.5px */
  letter-spacing: 0.15px;
`;
const CreatorOfferingsContentButton = styled(Button)`
  display: flex;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Background-brand-primary, #7262c2);
  background: var(--Background-brand-primary, #7262c2);
  color: #fff;
  text-align: center;
  /* Heading/H6-B */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 16.2px */
  letter-spacing: 0.18px;
  max-width: 106px;
  &:hover {
    background: var(--Background-brand-primary, #7262c2);
  }
`;

const ProfileDescription = styled(Typography)`
  overflow: hidden;
  color: #616161;
  text-align: justify;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.18px;
  display: inline;
`;

const FollowButton = styled(Button)`
  display: flex;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Background-brand-primary, #7262c2);
  background: var(--Background-brand-primary, #7262c2);
  color: #fff;
  text-align: center;

  /* Heading/H6-B */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 16.2px */
  letter-spacing: 0.18px;
  &:hover {
    background: var(--Background-brand-primary, #7262c2);
  }
`;

const CreatorProfileForUsers: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { profileData, subscriptions, onDemandRate } = useSelector(
    (state: RootState) => state.anotherCreator
  );
  const [viewText, setViewText] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const [likesCount, setLikesCount] = useState<number>(0);
  const [postsCount, setPostsCount] = useState<number>(0);

  const handleViewText = () => {
    setViewText((prev) => !prev);
  };
  const { id } = useParams();
  const handleCheckSubscription = () => {
    if (subscriptions.isPurchasedByMe) {
      navigate(`/${userRole}/check-creators-subscription-plan/${id}`);
    } else {
      navigate(`/${userRole}/buy-subscription`);
    }
  };
  const handleCheckOnDemandRate = () => {
    navigate(`/${userRole}/check-creators-on-demand-plan/${id}`);
  };

  const handleOnClickFollowButton = async () => {
    try {
      if (userRole === "user") {
        if (profileData.userId) {
          dispatch(setAddFollowing());
          const response = await usersCreatorProfileServiceApi.addFollower(
            profileData?.userId
          );
        }
      } else if (userRole === "creator") {
        if (profileData.userId) {
          dispatch(setAddFollowing());

          const response = await creatorsCreatorProfileServiceApi.addFollower(
            profileData?.userId
          );
        }
      }
    } catch (error) {
      console.log("Error in adding follower", error);
    }
  };

  const handleOnClickUnFollowButton = async () => {
    try {
      if (userRole === "user") {
        if (profileData.userId) {
          dispatch(setRemoveFollowing());
          const response = await usersCreatorProfileServiceApi.removeFollower(
            profileData?.userId
          );
        }
      } else if (userRole === "creator") {
        if (profileData.userId) {
          dispatch(setRemoveFollowing());
          const response =
            await creatorsCreatorProfileServiceApi.removeFollower(
              profileData?.userId
            );
        }
      }
    } catch (error) {
      console.log("Error in removing follower", error);
    }
  };

  return (
    <Container>
      {id ? (
        <CreatorsServices
          creatorId={id}
          setLikesCount={setLikesCount}
          setPostsCount={setPostsCount}
        />
      ) : null}

      {profileData ? (
        <>
          {!profileData?.coverPhotoUrl ? (
            <ImageContainer>
              <Image src="/Images/no_cover.png" />
              <BackgroundContainer></BackgroundContainer>
            </ImageContainer>
          ) : (
            <Image src={profileData?.coverPhotoUrl} />
          )}
          <ProfileDescriptionContainer>
            <ProfileDescHeader>
              <LeftContainer>
                <Box sx={{ position: "relative" }}>
                  <Avatar
                    sx={{ width: "3.5rem", height: "3.5rem" }}
                    alt="user"
                    src={profileData?.profilePhotoUrl}
                  />

                  <ActivityContainer />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: typography.font_weight.semiBold,
                      color: colors.black[200],
                      letterSpacing: ".02rem",
                    }}
                  >
                    {profileData?.displayName}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: typography.font_weight.regular,
                      color: colors.gray[700],
                      letterSpacing: ".02rem",
                    }}
                  >
                    {profileData?.handleName
                      ? `@${profileData?.handleName}`
                      : ""}
                  </Typography>
                </Box>
              </LeftContainer>

              {profileData?.isFollowing ? (
                <UnfollowButton
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={handleOnClickUnFollowButton}
                >
                  Unfollow
                </UnfollowButton>
              ) : (
                <FollowButton onClick={handleOnClickFollowButton}>
                  Follow
                </FollowButton>
              )}
            </ProfileDescHeader>
            <ProfileAchievenmentsContainer>
              <ProfileAchievementsContent>
                <ProfileAchievementsItem to="#">
                  <ItemContent variant="body1">
                    {convertNumberToProfileCount(likesCount)}
                  </ItemContent>
                  <ItemContent variant="body1">Likes</ItemContent>
                </ProfileAchievementsItem>

                <ProfileAchievementsItem to="#">
                  <ItemContent variant="body1">
                    {convertNumberToProfileCount(postsCount)}
                  </ItemContent>
                  <ItemContent variant="body1">Posts</ItemContent>
                </ProfileAchievementsItem>

                <UnclickableAchievmentsDivider />

                <ProfileAchievementsItem
                  to={
                    userRole === "user"
                      ? "#"
                      : `/following/${profileData.userId}`
                  }
                >
                  <ItemContent variant="body1">
                    {convertNumberToProfileCount(
                      profileData?.followingCount ?? 0
                    )}
                  </ItemContent>
                  <ItemContent variant="body1">Following</ItemContent>
                </ProfileAchievementsItem>
                <ProfileAchievementsItem
                  to={
                    userRole === "user"
                      ? "#"
                      : `/followers/${profileData.userId}`
                  }
                >
                  <ItemContent variant="body1">
                    {convertNumberToProfileCount(
                      profileData?.followersCount ?? 0
                    )}
                  </ItemContent>
                  <ItemContent variant="body1">Followers</ItemContent>
                </ProfileAchievementsItem>
                <ProfileAchievementsItem
                  // to={userRole === "user" ? "#" : `/creators-subscribers`}
                  to="#"
                >
                  <ItemContent variant="body1">
                    {convertNumberToProfileCount(
                      profileData?.subscriberCount ?? 0
                    )}
                  </ItemContent>
                  <ItemContent variant="body1">Subscribers</ItemContent>
                </ProfileAchievementsItem>
              </ProfileAchievementsContent>
            </ProfileAchievenmentsContainer>

            <Box sx={{ margin: ".5rem 0", padding: "0 1rem" }}>
              <Typography
                sx={{
                  width: "100%",
                  display: "block",
                }}
              >
                {profileData?.description &&
                profileData?.description.length > 130 ? (
                  <>
                    <ProfileDescription>
                      {viewText
                        ? profileData?.description
                        : `${profileData?.description.substring(0, 131)}...`}
                    </ProfileDescription>
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        color: colors.brand_primary,
                        cursor: "pointer",
                        display: "inline",
                        marginLeft: "5px",
                      }}
                      onClick={handleViewText}
                    >
                      {viewText ? "See less" : "See more"}
                    </Typography>
                  </>
                ) : (
                  <ProfileDescription>
                    {profileData?.description}
                  </ProfileDescription>
                )}
              </Typography>
            </Box>

            <ShowcasingContentBox>
              <Typography
                variant="body1"
                sx={{
                  fontSize: typography.font_size.sm,
                  fontWeight: typography.font_weight.regular,
                  lineHeight: typography.line_height.default,
                  letterSpacing: typography.letter_spacing.standard,
                  color: colors.black[200],
                  paddingBottom: "0.5rem",
                  flexShrink: "0",
                  width: "100%",
                }}
              >
                Showcasing Content:
              </Typography>

              <ShowcasingButtonsContainer>
                {profileData?.contentTarget?.map(
                  (profileDataItem: any, index: any) => (
                    <ShowCasingItem key={index}>
                      {profileDataItem}
                    </ShowCasingItem>
                  )
                )}
              </ShowcasingButtonsContainer>
            </ShowcasingContentBox>

            {subscriptions?.id || onDemandRate?.id ? (
              <CreatorOfferingsContainer>
                <CreatorOfferingsTitleBar>
                  <CreatorOfferingsTitle>
                    Creator's Offerings
                  </CreatorOfferingsTitle>
                </CreatorOfferingsTitleBar>
                <CreatorOfferingsContentContainer>
                  {onDemandRate?.id ? (
                    <CreatorOfferingsContent>
                      <CreatorOfferingsContentText>
                        On Demand 1-1 Services
                      </CreatorOfferingsContentText>
                      <CreatorOfferingsContentButton
                        onClick={handleCheckOnDemandRate}
                      >
                        Check Now
                      </CreatorOfferingsContentButton>
                    </CreatorOfferingsContent>
                  ) : null}
                  {subscriptions?.id ? (
                    <CreatorOfferingsContent>
                      <CreatorOfferingsContentText>
                        <SubscriptionText>Subscription Plan</SubscriptionText>
                        <SubscriptionValidity>
                          {subscriptions?.isPurchasedByMe
                            ? null
                            : "You are not subscribed"}
                        </SubscriptionValidity>
                      </CreatorOfferingsContentText>
                      <CreatorOfferingsContentButton
                        onClick={handleCheckSubscription}
                      >
                        Check Now
                      </CreatorOfferingsContentButton>
                    </CreatorOfferingsContent>
                  ) : null}
                </CreatorOfferingsContentContainer>

                <Divider sx={{ width: "100%", marginTop: "8px" }} />
              </CreatorOfferingsContainer>
            ) : null}

            <BottomContainer>
              <CreatorContent />
            </BottomContainer>
          </ProfileDescriptionContainer>
        </>
      ) : null}
    </Container>
  );
};

export default CreatorProfileForUsers;
