import { Box, Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { setFiles } from "../../../redux/creatorContentSlice";

const RenderImage = (props: any) => {
  return (
    <Box>
      <Checkbox
        sx={{
          position: "absolute",
          ml: "90px",
          color: "#fff",
          "&.Mui-checked": {
            color: "#fff",
          },
        }}
        checked={props?.file?.isCoverPhoto}
        onChange={(e: any) => {
          props.onClick(e.target.checked);
        }}
      />
      <img src={props.url} width={125} height={125} />
    </Box>
  );
};

const CoverPhotos = (props: any) => {
  const dispatch = useDispatch();

  const files = useSelector((state: any) => state.postContent.files);

  const handleRenderImage = (flag: any, index: any) => {
    let filesCopy = cloneDeep(files);

    filesCopy = filesCopy.map((i: any) => {
      return { ...i, isCoverPhoto: false };
    });
    filesCopy[index].isCoverPhoto = flag;
    dispatch(setFiles(filesCopy));
  };
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap="4px"
        ml="4px"
      >
        {files
          .filter((file: any) => file.selectedFree)
          .map((file: any, index: any) => {
            return (
              <Box>
                <RenderImage
                  key={index}
                  url={file.croppedUrl ? file.croppedUrl : file.url}
                  file={file}
                  onClick={(flag: any) => {
                    handleRenderImage(flag, index);
                  }}
                />{" "}
              </Box>
            );
          })}
      </Box>
    </>
  );
};

export default CoverPhotos;
