import { ApiService } from "../ApiClient";
import { creatorAxiosInstance } from "../axiosInstance";
import "../interceptors/creatorAuthInterceptors"

class CreatorBookingsApi extends ApiService{
  constructor() {
    super(creatorAxiosInstance)
  }
  getWeeklyAvailability() {
      return this.axios.get("creator/slots");
  }  
  addWeeklyAvailability(payload: any){
    return this.axios.post("creator/slots", JSON.stringify(payload));
  }
  updateWeeklyAvailability(payload: any){
    return this.axios.post("content/post", JSON.stringify(payload));
  }
  getConfirmedBookings(page:string, pageSize: string) {
    return this.axios.get(`creator/bookings?status=Confirmed&page=${page}&pageSize=${pageSize}`);
  }
  getPendingBookings(page:string, pageSize: string) {
    return this.axios.get(`creator/bookings?status=Pending&page=${page}&pageSize=${pageSize}`);
  }

  getHistoryBookings(page:string, pageSize: string) {
    return this.axios.get(`creator/bookings?status=History&page=${page}&pageSize=${pageSize}`);
  }

  cancelBooking(bookingId:string){
    return this.axios.get(`creator/bookings?status=History&page`);
  }

  checkCanRescheduleBooking(bookingId:string){
    let body={id:bookingId};
    return this.axios.post(`creator/slots/reschedule/available`, JSON.stringify(body));
  }

  updateBookingShowRequestStatus(bookingId:string, payload:any){
    return this.axios.patch(`creator/bookings/${bookingId}`, JSON.stringify(payload));
  }
}


export default new CreatorBookingsApi();
