import React, { useState, useEffect } from "react";
import {
  AudioCallIcon,
  CalendarIcon,
  ClockSvgIcon,
  CloutCoinIcon,
  TextMessageIcon,
  VideoCallCameraIcon,
  WarningSvgIcon,
  GreenCheckMarkIcon,
  CancelRescheduleIcon,
} from "../../../../assets/SvgIcons";
import {
  ConfirmedBookingsItemsContainer,
  Container,
  MonthContainer,
  MonthTitle,
  ImageContainer,
  ContentMainSection,
  TitleAndHandleName,
  Title,
  HandleName,
  IncomingOutgoingMessage,
  SecondLineContainer,
  TypeContainer,
  TypeText,
  DurationContainer,
  DurationText,
  PriceContainer,
  PriceIcon,
  PriceText,
  ThirdLineContainer,
  DateContainer,
  DateText,
  TimeContainer,
  TimeText,
  FourthLineContainer,
  ViewDetailsText,
  ConfirmedText,
  BookingDetailsContainer,
  BookingDetailsTitle,
  ChargesTextContainer,
  ChargesText,
  ChargesValue,
  ServiceTypeContainer,
  ServiceTypeText,
  ServiceTypeValue,
  RescheduleButtonContainer,
  RescheduleButton,
  BookingInfoContainer,
  Avatar,
  ActionRequiredContainer,
  ActionRequiredTextContainer,
  ActionRequiredSubText,
  PendingContentContainer,
  AcceptButtonContainer,
  AcceptButton,
  WarningContainer,
  AcceptIconAndText,
  CancelIconAndText,
  CancelText,
  styled,
  Box
} from "./CheckBookingsStyles";
import { colors } from "../../../../utils/styles";
import { groupByMonth } from "../../../../utils/groupByMonth";
import { formatLongDate, formatRegularDate } from "../../../../utils/formatDate";
import { GroupedBookings } from "../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { useLocation, useNavigate } from "react-router-dom";
import creatorBookingServiceApi from "../../../../api/creator/bookingServiceApi";
import userBookingServiceApi from "../../../../api/user/bookingServiceApi";
import {
  acceptRescheduling,
  rejectRescheduling,
  setPendingBookings,
} from "../../../../redux/bookingSlice";
import moment from "moment";
import NoBookings from "../NoBookings";
import { useSnackbar } from "../../../../context/SnackbarContext";
const PendingBookingDetailsContainer = styled(Box)<{ open: boolean }>`
  grid-column: 2 / 4;
  display: flex;
  padding: ${({ open }) => (open ? "8px 0px 8px 0px" : "0 16px 0 0")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
  flex-shrink: 0;
  height: ${({ open }) => (open ? "208px" : "0px")};
  overflow: hidden;
  transition: height 0.5s ease-in-out;
`;
interface PendingBookingsProps {}
const PendingBookings: React.FC<PendingBookingsProps> = ({}) => {
  const { pendingBookings } = useSelector((state: RootState) => state.bookings);
  const groupedBookings: GroupedBookings = groupByMonth(pendingBookings);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const userRole = location.pathname.includes("creator") ? "creator" : "user";
  const fetchPendingBookings = async () => {
    try {
      let response =
        userRole === "creator"
          ? await creatorBookingServiceApi.getPendingBookings("1", "20")
          : await userBookingServiceApi.getPendingBookings("1", "20");
      let data = response.data.data.data;
      dispatch(setPendingBookings(data));
    } catch (err) {
      console.error("Error in getting pending bookings", err);
    }
  };
  useEffect(() => {
    fetchPendingBookings();
  }, [dispatch]);
  return (
    <ConfirmedBookingsItemsContainer>
      {pendingBookings?.length ? (
        <>
          {Object.keys(groupedBookings).map((monthYear) => (
            <div key={monthYear}>
              <MonthContainer>
                <MonthTitle>{monthYear}</MonthTitle>
              </MonthContainer>
              {groupedBookings[monthYear].map((booking, index) => (
                <PendingBookingsItem key={index} item={booking} />
              ))}
            </div>
          ))}
        </>
      ) : (
        <NoBookings
          title="No Pending Bookings Yet"
          subtitle="Start rescheduling your booking."
        />
      )}
    </ConfirmedBookingsItemsContainer>
  );
};
interface PendingBookingsItemProps {
  item: any;
}
const PendingBookingsItem: React.FC<PendingBookingsItemProps> = ({ item }) => {
  const [openDetails, setOpenDetails] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const userRole = location.pathname.includes("creator") ? "creator" : "user";
  const {openSnackBar} = useSnackbar();
  const handleOnClickViewDetails = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setOpenDetails((prev) => !prev);
  };
  const handleOnClickAcceptRescheduleRequest = async (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    try {
      e.preventDefault();
      const payload = {
        status: "accept",
      };
      dispatch(acceptRescheduling(item?.bookingId));
      openSnackBar("Booking accepted successfully", "success");
      const response =
        userRole === "creator"
          ? await creatorBookingServiceApi.updateBookingShowRequestStatus(
              item?.bookingId,
              payload
            )
          : await userBookingServiceApi.updateBookingShowRequestStatus(
              item?.bookingId,
              payload
            );
    } catch (err) {
      console.error("Error in accepting rescheduling request ", err);
      openSnackBar("Error accepting a booking", "error");
    }
  };
  const handleOnClickRejectRescheduleRequest = async (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    try {
      e.preventDefault();
      const payload = {
        status: "rejected",
      };
      dispatch(rejectRescheduling(item?.bookingId));
      openSnackBar("Booking rejected successfully", "success");
      const response =
        userRole === "creator"
          ? await creatorBookingServiceApi.updateBookingShowRequestStatus(
              item?.bookingId,
              payload
            )
          : await userBookingServiceApi.updateBookingShowRequestStatus(
              item?.bookingId,
              payload
            );
    } catch (err) {
      console.error("Error in rejecting rescheduling request", err);
      openSnackBar("Error in rejecting a booking", "error");
    }
  };
  const handleOnClickRescheduleButton = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    try {
      const response =
        userRole === "creator"
          ? await creatorBookingServiceApi.checkCanRescheduleBooking(
              item?.bookingId
            )
          : await userBookingServiceApi.checkCanRescheduleBooking(
              item?.bookingId
            );
      if (response.data.message === "Reschedule allowed") {
        let creatorName = item?.displayName;
        let creatorId = item?.userId ?? item?.creatorId;
        let serviceType =
          item.type === "Subscription Plan" ? "subscription" : "onDemand";
        let service =
          item?.serviceType === "AUDIOCALL"
            ? "Audio Call"
            : item?.serviceType === "VIDEOCALL"
            ? "Video Call"
            : "Direct Message";
        let minuteSlot = parseInt(item?.slotLength);
        let previousEndTime = item?.endTime;
        let previousStartTime = item?.startTime;
        let previousDate = item?.date;
        let bookedSlotId = item?.bookingId;
        navigate(
          `/${userRole}/reschedule-booking?bookingId=${item?.bookingId}&serviceType=${serviceType}&service=${service}&minuteSlot=${minuteSlot}&creatorId=${creatorId}&creatorName=${creatorName}&previousStartTime=${previousStartTime}&previousEndTime=${previousEndTime}&previousDate=${previousDate}&bookedSlotId=${bookedSlotId}`
        );
      }
    } catch (err) {
      openSnackBar("Reschedule not allowed more than once", "warning");
      console.error("Error in can reschedule booking", err);
    }
  };
  
  const visitProfile=(e:any)=>{
    e.preventDefault();
    if(!item?.profileId) return;
    navigate(`/${userRole}/creator-profile-view/${item?.profileId}`);
  }
  return (
    <Container>
      {item?.actionRequired && (
        <ActionRequiredContainer>
          <WarningContainer>
            <WarningSvgIcon />
          </WarningContainer>
          <ActionRequiredTextContainer>
            <ActionRequiredSubText>
              <b>Action Required </b>: Creator suggested a new slot for this
              booking. Kindly accept the request.
            </ActionRequiredSubText>
          </ActionRequiredTextContainer>
        </ActionRequiredContainer>
      )}
      <PendingContentContainer textSetup={item?.actionRequired}>
        <ImageContainer>
          <Avatar
          onClick={visitProfile}
            sx={{ width: "24px", height: "24px" }}
            src={item.profilePic}
          />
        </ImageContainer>
        <ContentMainSection>
          <TitleAndHandleName onClick={visitProfile}>
            <Title>{item?.displayName} - </Title>
            <HandleName>
              {" "}
              {item.handleName ? `@${item.handleName}` : ""}
            </HandleName>
            {item?.label === "outgoing" ? (
              <IncomingOutgoingMessage>Outgoing</IncomingOutgoingMessage>
            ) : null}
          </TitleAndHandleName>
          <SecondLineContainer>
            <TypeContainer>
              {item.serviceType === "AUDIOCALL" ? (
                <AudioCallIcon />
              ) : item.serviceType === "VIDEOCALL" ? (
                <VideoCallCameraIcon />
              ) : (
                <TextMessageIcon />
              )}
              <TypeText>
                {item?.serviceType === "AUDIOCALL"
                  ? "Audio call"
                  : item?.serviceType === "VIDEOCALL"
                  ? "Video call"
                  : "Text message"}
              </TypeText>
            </TypeContainer>
            <DurationContainer>
              <ClockSvgIcon />
              <DurationText>{parseInt(item?.slotLength)} min</DurationText>
            </DurationContainer>
            {item.type !== "Subscription Plan" ? (
              <PriceContainer>
                <PriceIcon>
                  <CloutCoinIcon />
                </PriceIcon>
                <PriceText>
                  {item?.label === "outgoing" ? null : "+"}
                  {item?.amountInYc}
                </PriceText>
              </PriceContainer>
            ) : null}
          </SecondLineContainer>
          <ThirdLineContainer>
            <DateContainer>
              <CalendarIcon />
              <DateText>{formatLongDate(item.date)}</DateText>
            </DateContainer>
            <TimeContainer>
              <TimeText>
                {" |   "}
                {item?.startTime} - {item?.endTime}
              </TimeText>
            </TimeContainer>
          </ThirdLineContainer>
          <FourthLineContainer>
            <ViewDetailsText onClick={handleOnClickViewDetails}>
              {openDetails ? "Less Details" : "View Details"}
            </ViewDetailsText>
            <ConfirmedText sx={{ color: colors.black[200] }}>
              {item?.showRequest ? (
                <CancelIconAndText
                  onClick={handleOnClickRejectRescheduleRequest}
                >
                  <CancelRescheduleIcon />
                  <CancelText>Reject</CancelText>
                </CancelIconAndText>
              ) : (
                "Awaiting Reschedule Response"
              )}
            </ConfirmedText>
          </FourthLineContainer>
        </ContentMainSection>
        <AcceptButtonContainer>
          {item?.showRequest && (
            <AcceptIconAndText onClick={handleOnClickAcceptRescheduleRequest}>
              <GreenCheckMarkIcon />
              <AcceptButton>Accept</AcceptButton>
            </AcceptIconAndText>
          )}
        </AcceptButtonContainer>
        <PendingBookingDetailsContainer open={openDetails}>
          <BookingInfoContainer sx={{paddingRight: "16px"}}>
            <BookingDetailsTitle>Current Booking Details</BookingDetailsTitle>
            {item.amountInYc ? (
              <ChargesTextContainer>
                <ChargesText>{item?.serviceType === "AUDIOCALL"
                    ? "Audio call"
                    : item?.serviceType === "VIDEOCALL"
                    ? "Video call"
                    : "Message"} Charges (per min)</ChargesText>
                <ChargesValue>
                  {item.amountInYc
                    ? item?.amountInYc / parseInt(item?.slotLength)
                    : ""}{" "}
                  CC
                </ChargesValue>
              </ChargesTextContainer>
            ) : (
              <ChargesTextContainer>
                <ChargesText>Plan expiry date</ChargesText>
                <ChargesValue>
                  {moment
                    .utc(item?.planExpiryDate)
                    .local()
                    .format("DD MMM YYYY h:mm a")}
                </ChargesValue>
              </ChargesTextContainer>
            )}
            <ServiceTypeContainer>
              <ServiceTypeText>Type of Service</ServiceTypeText>
              <ServiceTypeValue>{item?.type}</ServiceTypeValue>
            </ServiceTypeContainer>
          </BookingInfoContainer>
          {item?.label === "outgoing" && (
            <RescheduleButtonContainer
            sx={{ alignItems: "center", justifyContent: "center"}}
            >
              <RescheduleButton onClick={handleOnClickRescheduleButton}>
                Reschedule booking
              </RescheduleButton>
            </RescheduleButtonContainer>
          )}
          <BookingInfoContainer
          sx={{paddingRight: "16px", paddingTop: "8px", borderTop: "1px solid rgba(33, 33, 33, 0.1)"}}
          >
            <BookingDetailsTitle sx={{color: colors.gray[80]}}>Previous Booking Details</BookingDetailsTitle>
              <ChargesTextContainer>
                <ChargesText sx={{color: colors.gray[80]}}>Booking date</ChargesText>
                <ChargesValue sx={{color: colors.gray[80]}}>
                  {formatRegularDate(item?.prevDate)}
                </ChargesValue>
              </ChargesTextContainer>
              <ChargesTextContainer>
                <ChargesText sx={{color: colors.gray[80]}}>No. of minutes</ChargesText>
                <ChargesValue sx={{color: colors.gray[80]}}>
                 {parseInt(item?.slotLength)} Minutes
                </ChargesValue>
              </ChargesTextContainer>
            <ServiceTypeContainer>
              <ServiceTypeText sx={{color: colors.gray[80]}}>Slot timing</ServiceTypeText>
              <ServiceTypeValue sx={{color: colors.gray[80]}}>{item?.prevStartTime}{" - "}{item?.prevEndTime}</ServiceTypeValue>
            </ServiceTypeContainer>
          </BookingInfoContainer>
        </PendingBookingDetailsContainer>
      </PendingContentContainer>
    </Container>
  );
};

export default PendingBookings;
