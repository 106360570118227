import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../store/store";
import userApi from "../api/user/userApi";
import profileServiceApi from "../api/user/profileServiceApi";

const UsersProtectedRoute = () =>{
  let accessToken = '';
  const strAccessToken = localStorage.getItem('accessToken');
  const userId = useSelector((state: RootState) => state.user.id);
  if(strAccessToken) accessToken = JSON.parse(strAccessToken);
  const updateUserProfile = () =>{
    if(!userId){
      profileServiceApi.getProfile().then(res=>console.log(res)).catch(err=>{});
    }
    else{
      console.log(userId)
    }
  }
  useEffect(()=>{
    updateUserProfile()
  },[])
  if(!accessToken) return <Navigate to="/"/>
  return <Outlet/>
}
export {UsersProtectedRoute}