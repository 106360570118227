import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";

interface InputFieldWithTextCountProps {
  name: string;
  characterLimit: number;
  minHeight?: number;
  maxHeight?: number;
  placeholder: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const InputFieldWithTextCount: React.FC<InputFieldWithTextCountProps> = ({
  characterLimit,
  minHeight,
  maxHeight,
  placeholder,
  name,
  value = "",
  onChange,
}) => {
  const [currentValue, setCurrentValue] = useState<string>(value);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue.length <= characterLimit) {
      setCurrentValue(newValue);
      if (onChange) {
        onChange(event);
      }
    }
  };

  return (
    <StyledTextField
      multiline
      name={name}
      placeholder={placeholder}
      value={currentValue}
      onChange={handleChange}
      helperText={`${currentValue.length}/${characterLimit}`}
      variant="outlined"
      fullWidth
      inputProps={{ maxLength: characterLimit }} // enforce maxLength
      minHeight={minHeight}
      maxHeight={maxHeight}
    />
  );
};

const StyledTextField = styled(TextField)<{ minHeight?: number; maxHeight?: number }>(
  ({ minHeight, maxHeight }) => ({
    '& .MuiInputBase-root': {
      minHeight: minHeight || 'auto',
      maxHeight: maxHeight || 'auto',
      // overflow: 'auto',
      // boxSizing:'border-box',
      padding :'4px 12px',
      letterSpacing: '0.24px',
      color:'#616161',
      // fontWeight:'700',
      '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'gray',
          },
          '&:hover fieldset': {
            borderColor: 'black',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'black',
            borderWidth: 2,
          },
        },
    },
  })
);

export { InputFieldWithTextCount };
