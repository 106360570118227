import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { colors, typography } from "../../utils/styles";
import { setCreator, updateCreator } from "../../redux/appUserSlice";
import userApi from "../../api/user/userApi";
import { setLoading } from "../../redux/creatorRegistrationSlice";
import AadharVerification from "./AadhaarVerification/AadhaarVerification";
import PanVerification from "./PANVerification/PANVerification";
import GstVerification from "./GSTVerification/GSTVerification";

const RegistrationDetails = (props: any) => {
  const { verificationType, setVerificationType } = props;

  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const [individualName, setIndividualName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessContact, setBusinessContact] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [individualNameError, setIndividualNameError] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [businessContactError, setBusinessContactError] = useState("");
  const [businessAddressError, setBusinessAddressError] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleIndividualSubmit = async () => {
    if (!individualName) {
      setIndividualNameError("Individual name is required");
      return;
    }
    setIndividualNameError("");

    try {
      dispatch(setLoading(true));

      const payload = {
        creatorType: value,
        individualName: individualName,
      };
      const response = await userApi.onboardingStepTwoAsIndividualType(payload);

      if (response.status === 200) {
        dispatch(setCreator(response?.data?.creator));
        return props.getNextStage();
      }
    } catch (error) {
      console.error("Error during step two:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleBusinessSubmit = async () => {
    if (!businessName) {
      setBusinessNameError("Business name is required");
      return;
    } else {
      setBusinessNameError("");
    }
    if (!businessContact) {
      setBusinessContactError("Business contact is required");
      return;
    } else {
      setBusinessContactError("");
    }
    if (!businessAddress) {
      setBusinessAddressError("Business address is required");
      return;
    } else {
      setBusinessAddressError("");
    }

    try {
      dispatch(setLoading(true));

      const payload = {
        creatorType: value,
        businessName: businessName,
        businessAddress,
        businessContact,
      };
      const response = await userApi.onboardingStepTwoAsBusinessType(payload);

      dispatch(updateCreator(response.data.creator));

      return props.getNextStage();
    } catch (error) {
      console.error("Error during step two:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Box>
      {!verificationType && (
        <Stack>
          <Stack mx="16px" my="16px" direction="row" gap="8px">
            <Typography
              fontSize={typography.font_size["sm"]}
              color={colors.gray["80"]}
            >
              Register as
            </Typography>
            <Tooltip
              title="For individual - Aadhar & Pan details are required. For business - Aadhar, Pan & GST  details are required."
              arrow
            >
              <img
                src="/Icons/tooltip_info.svg"
                width="16px"
                height="18px"
                style={{ marginTop: "2px" }}
                alt="info"
              />
            </Tooltip>
          </Stack>
          <Stack mx="16px">
            <RadioGroup
              row
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              <FormControlLabel
                value="INDIVIDUAL"
                control={<Radio />}
                label="Individual"
              />
              <FormControlLabel
                value="BUSINESS"
                control={<Radio />}
                label="Business"
              />
            </RadioGroup>
          </Stack>

          {value === "INDIVIDUAL" && (
            <Box>
              <Box mx="16px">
                <InputLabel sx={{ paddingBottom: "4px", paddingTop: "15px" }}>
                  Enter Individual Name
                </InputLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={individualName}
                  onChange={(e) => setIndividualName(e.target.value)}
                  InputProps={{ style: { height: "48px" } }}
                />
                {individualNameError && (
                  <Typography color="error" sx={{ mt: "8px" }}>
                    {individualNameError}
                  </Typography>
                )}
              </Box>

              <Box position="absolute" bottom="0" width="100%">
                <Button
                  sx={{ borderRadius: "0px" }}
                  type="submit"
                  onClick={handleIndividualSubmit}
                  variant="primary"
                  fullWidth
                >
                  Submit
                </Button>
              </Box>
            </Box>
          )}
          {value === "BUSINESS" && (
            <Box>
              <Box mx="16px">
                <InputLabel sx={{ paddingBottom: "4px", paddingTop: "15px" }}>
                  Enter Business Name(Mandatory)
                </InputLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  InputProps={{ style: { height: "48px" } }}
                />
                {businessNameError && (
                  <Typography color="error" sx={{ mt: "8px" }}>
                    {businessNameError}
                  </Typography>
                )}
              </Box>

              <Box mx="16px">
                <InputLabel sx={{ paddingBottom: "4px", paddingTop: "15px" }}>
                  Enter Business Contact
                </InputLabel>
                <TextField
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={businessContact}
                  onChange={(e) => setBusinessContact(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          backgroundColor: colors.gray["90"],
                          width: 40,
                          height: 30,
                          paddingLeft: 2,
                          border: "2px",
                        }}
                      >
                        <IconButton edge="start">
                          <Typography>+91</Typography>
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => {}} edge="end">
                          <CallOutlinedIcon
                            sx={{ color: colors.violet["100"] }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { height: "48px" },
                  }}
                />
                {businessContactError && (
                  <Typography color="error" sx={{ mt: "8px" }}>
                    {businessContactError}
                  </Typography>
                )}
              </Box>

              <Box mx="16px">
                <InputLabel sx={{ paddingBottom: "4px", paddingTop: "15px" }}>
                  Enter Business Address
                </InputLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={businessAddress}
                  onChange={(e) => setBusinessAddress(e.target.value)}
                  InputProps={{ style: { height: "72px" } }}
                />
                {businessAddressError && (
                  <Typography color="error" sx={{ mt: "8px" }}>
                    {businessAddressError}
                  </Typography>
                )}
              </Box>
              <Box position="absolute" bottom="0" width="100%">
                <Button
                  type="submit"
                  onClick={handleBusinessSubmit}
                  variant="primary"
                  sx={{ borderRadius: "0px" }}
                  fullWidth
                >
                  Submit
                </Button>
              </Box>
            </Box>
          )}
        </Stack>
      )}

      {verificationType === "aadhar" && (
        <AadharVerification next={() => props.getNextStage()} />
      )}

      {verificationType === "pan" && (
        <PanVerification next={() => props.getNextStage()} />
      )}

      {verificationType === "gst" && (
        <GstVerification next={() => props.getNextStage()} />
      )}
    </Box>
  );
};

export { RegistrationDetails };
