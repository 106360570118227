import { Box, styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SubscriptionHeader from "../ProfileView/Creator/CreatorSubscriptions/SubscriptionHeader";
import RescheduleSlotDetails from "../../components/RescheduleSlot/RescheduleSlotDetails";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setBookedSlotId,
  setCreatorDetails,
  setMinuteSlot,
  setService,
  setServiceType,
} from "../../redux/slotBookingSlice";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

const RescheduleSlot = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const serviceType = queryParams.get("serviceType");
  const service = queryParams.get("service");
  const minuteSlot = queryParams.get("minuteSlot");
  const creatorId = queryParams.get("creatorId");
  const creatorName = queryParams.get("creatorName");
  const previousStartTime = queryParams.get("previousStartTime");
  const previousEndTime = queryParams.get("previousEndTime");
  const previousDate = queryParams.get("previousDate");
  const bookedSlotId = queryParams.get("bookedSlotId");
  const handleOnClickGoBackButton = () => {
    navigate(-1);
  };
  const data = {
    previousDate,
    previousStartTime,
    previousEndTime,
  };
  useEffect(() => {
    if (
      serviceType &&
      service &&
      minuteSlot &&
      creatorId &&
      creatorName &&
      bookedSlotId
    ) {
      dispatch(setService(service));
      dispatch(setServiceType(serviceType));
      dispatch(setMinuteSlot(minuteSlot));
      dispatch(setCreatorDetails({ creatorId, creatorName }));
      dispatch(setBookedSlotId(bookedSlotId));
    }
  }, [
    bookedSlotId,
    creatorId,
    creatorName,
    dispatch,
    minuteSlot,
    service,
    serviceType,
  ]);
  return (
    <Container>
      <SubscriptionHeader
        title={`Reschedule - ${service}`}
        handleOnClickGoBackButton={handleOnClickGoBackButton}
      />
      <RescheduleSlotDetails
        data={
          data.previousDate && data.previousEndTime && data.previousStartTime
            ? data
            : undefined
        }
      />
    </Container>
  );
};

export default RescheduleSlot;
