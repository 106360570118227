import { io, Socket } from "socket.io-client";

const SOCKET_URL = "https://api.dev.yarra.social";

class CallSocketService {
  private socket: Socket | null = null;

  connect(userName: string, roomId: string) {
    this.socket = io(SOCKET_URL, {
      transports: ["websocket"],
    });

    this.socket.on("connect", () => {
      console.log("Connected to the socket server");
    });

    this.socket.on("disconnect", () => {
      console.log("Disconnected from the socket server");
    });
  }

  emitMessage(event: string, data: any) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }

  disconnectWithPayload(payload: { userId: string }) {
    if (this.socket) {
      this.socket.emit("leaving", payload);
      this.socket.disconnect();
    }
  }

  onMessage(event: string, callback: (data: any) => void) {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }

  offMessage(event: string) {
    if (this.socket) {
      this.socket.off(event);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}

export default new CallSocketService();
