import { createSlice } from '@reduxjs/toolkit';
import { EarningsData } from '../types';


interface PostEarningsState{
    totalPurchased: number;
    totalTips: number;
    tipsData:  EarningsData[];
    purchasedPostData:  EarningsData[];
}

const initialState: PostEarningsState = {
  totalPurchased: 0,
  totalTips: 0,
  tipsData: [] as EarningsData[],
  purchasedPostData: [] as EarningsData[],
};

const postEarningsSlice = createSlice({
  name: 'postEarnings',
  initialState: initialState,
  reducers: {
    setTipsData(state, action) {
      state.tipsData = action.payload.data;
      state.totalTips = action.payload.totalTips;
      state.totalPurchased = action.payload.totalPurchased;
    },
    setPurchasedPostData(state, action) {
      state.purchasedPostData = action.payload.data;
      state.totalTips = action.payload.totalTips;
      state.totalPurchased = action.payload.totalPurchased;
    },
 
    addTipsData(state, action) {
      state.tipsData.push(action.payload);
      state.totalTips += action.payload.amount;
    },
    addPostData(state, action) {
      state.purchasedPostData.push(action.payload);
      state.totalPurchased += action.payload.amount;
    },
  }
});

export const {
  setTipsData,
  setPurchasedPostData,
  addTipsData,
  addPostData,
} = postEarningsSlice.actions;

export const postEarningsReducer = postEarningsSlice.reducer;