import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Divider,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { typography, colors } from "../../utils/styles";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff, Check, Close } from "@mui/icons-material";
import AuthApi from "../../api/auth/AuthApi";
import { STATUS, Status } from "../../constants/statusConstants";
import { REGEX } from "../../utils/regex";
import useToast from "../../hooks/useToast";
import { useParams } from "react-router-dom";

const SignUp: React.FC = () => {
  const { referralCodeStr } = useParams();
  const navigate = useNavigate();
  const { ToastMessage, handleOpenToast } = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<{
    email?: string;
    password?: string;
    confirmPassword?: string;
  }>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isRegistered, setIsRegistered] = useState<Status>(STATUS.IDLE);

  const [showPasswordHint, setShowPasswordHint] = useState(false);
  const [showEmailHint, setShowEmailHint] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [openReferralInput, setOpenReferralInput] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const validateCredentials = () => {
    const mailError = REGEX.EMAIL.test(email)
      ? ""
      : "Please enter a valid email address.";
    const passwordError = REGEX.PASSWORD.test(password)
      ? ""
      : "Please enter a valid password.";
    const confirmPasswordError =
      password === confirmPassword ? "" : "Passwords do not match";
    setErrors({
      email: mailError,
      password: passwordError,
      confirmPassword: confirmPasswordError,
    });
    return !(mailError || passwordError || confirmPasswordError);
  };

  const handleSignup = async (
    email: string,
    password: string,
    referralCode?: string
  ) => {
    try {
      if (referralCode) {
        const response = await AuthApi.signup({
          email,
          password,
          referralCode,
        });
        return response;
      } else {
        const response = await AuthApi.signup({ email, password });
        return response;
      }
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const isValid = validateCredentials();
    if (!isValid) return;
    setIsRegistered(STATUS.LOADING);
    try {
      const response = await handleSignup(email, password, referralCode);
      const emailVerificationRoute = response.data.verificationLink;
      console.log(emailVerificationRoute);
      const userEmail = response?.data?.user?.email;
      const userId = response?.data?.user?.id;
      setIsRegistered(STATUS.SUCCESS);

      navigate(`/emailVerification`, {
        replace: true,
        state: { email: userEmail, id: userId, password },
      });
    } catch (err: any) {
      setIsRegistered(STATUS.ERROR);
      if (err?.response) {
        handleOpenToast(err.response?.data?.errors?.[0].message, "error");
      }
    }
  };

  const handleOnClickReferral = () => {
    setOpenReferralInput(true);
  };

  useEffect(() => {
    if (referralCodeStr) {
      setReferralCode(referralCodeStr);
      setOpenReferralInput(true);
    }
  }, [referralCodeStr]);

  const passwordChecklist = [
    { label: "8-15 characters long", regex: /.{8,15}/ },
    { label: "At least 1 uppercase letter", regex: /[A-Z]/ },
    { label: "At least 1 digit", regex: /\d/ },
    { label: "At least 1 special character (@$!%*?&)", regex: /[@$!%*?&]/ },
  ];

  return (
    <Box mx="16px" my="24px">
      <ToastMessage />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap="10px"
      >
        <img
          src="/Icons/yaara_logo.svg"
          style={{ marginTop: "30px" }}
          alt="logo"
        />
        <Typography
          fontSize={typography.font_size["2xl"]}
          fontWeight={typography.font_weight["bold"]}
        >
          Create Your Account
        </Typography>

        <Typography
          fontSize={typography.font_size["base"]}
          fontWeight={typography.font_weight["regular"]}
          color={colors.black["200"]}
        >
          Become a member!!
        </Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <InputLabel sx={{ paddingBottom: "4px", paddingTop: "15px" }}>
          Email
        </InputLabel>
        <TextField
          variant="outlined"
          fullWidth
          sx={{ marginBottom: "20px" }}
          placeholder="Enter your email"
          value={email}
          onChange={(e) => {
            setErrors((prev) => ({ ...prev, email: "" }));
            setEmail(e.target.value);
          }}
          onBlur={() => setShowEmailHint(false)}
          onFocus={() => {
            setShowEmailHint(true);
            setErrors((prev) => ({ ...prev, email: "" }));
          }}
          error={!!errors.email}
          helperText={
            showEmailHint ? "abc@domain.com" : errors.email ? errors.email : ""
          }
          InputProps={{ style: { height: "48px" } }}
        />
        <InputLabel sx={{ py: "4px" }}>Password</InputLabel>

        <TextField
          type={showPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password}
          helperText={errors.password}
          onBlur={() => setShowPasswordHint(false)}
          onFocus={() => {
            setShowPasswordHint(true);
            setErrors((prev) => ({ ...prev, password: "" }));
          }}
          sx={{ marginBottom: "10px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            style: { height: "48px" },
          }}
        />
        {showPasswordHint && (
          <List dense sx={{ fontSize: typography.font_size.xs, padding: 0 }}>
            {passwordChecklist.map((item, index) => (
              <ListItem key={index} sx={{ padding: "2px 0" }}>
                <ListItemIcon sx={{ minWidth: "24px" }}>
                  {item.regex.test(password) ? (
                    <Check style={{ color: "green", fontSize: "16px" }} />
                  ) : (
                    <Close style={{ color: "red", fontSize: "16px" }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{ fontSize: typography.font_size.xs }}
                />
              </ListItem>
            ))}
          </List>
        )}
        <InputLabel sx={{ py: "4px" }}>Confirm Password</InputLabel>

        <TextField
          type={showConfirmPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          placeholder="Confirm your password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onFocus={() => {
            setErrors((prev) => ({ ...prev, confirmPassword: "" }));
          }}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          sx={{ marginBottom: "20px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowConfirmPassword} edge="end">
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            style: { height: "48px" },
          }}
        />

        {openReferralInput && (
          <>
            <InputLabel sx={{ py: "4px" }}>Referral Code</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              sx={{ marginBottom: "24px" }}
              placeholder="Enter your referral code"
              value={referralCode}
              onChange={(e) => {
                setReferralCode(e.target.value);
              }}
              InputProps={{ style: { height: "48px" } }}
            />
          </>
        )}
        <Button
          startIcon={
            isRegistered === STATUS.LOADING && <CircularProgress size={20} />
          }
          type="submit"
          variant="primary"
          fullWidth
        >
          Register
        </Button>

        <Divider
          style={{
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          OR
        </Divider>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          gap="10px"
        >
          <Box
            display="flex"
            flexDirection="row"
            fontSize={typography.font_size["sm"]}
          >
            <Typography color={colors.gray["75"]}>
              Already have an account? &nbsp;
            </Typography>
            <a href="/login">Sign In.</a>
          </Box>
          <Link
            style={{ fontSize: typography.font_size["sm"] }}
            to={{}}
            onClick={handleOnClickReferral}
          >
            Have referral code?
          </Link>
          <Typography
            sx={{
              fontSize: typography.font_size["xxs"],
              color: colors.gray["75"],
            }}
          >
            By signing in, you are agreeing to our{" "}
            <a href="/terms-and-conditions">T&C</a> and{" "}
            <a href="/privacy-policy">Privacy Policy</a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SignUp;
