import { Box, Button, styled, Typography } from "@mui/material";
import { colors } from "../../utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  resetBooking,
  setAssignedSlot,
  setSelectedSlot,
  setTimerEndTime,
} from "../../redux/slotBookingSlice";
import { resetTimer } from "../../redux/timerSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SubscriptionHeader from "../ProfileView/Creator/CreatorSubscriptions/SubscriptionHeader";
import { Timer } from "../../components/BookSlot/Timer";
import { useSnackbar } from "../../context/SnackbarContext";
import { formatLongDate } from "../../utils/formatDate";
import userSlotBookingService from "../../api/booking/user/userSlotBookingService";
import creatorSlotBookingService from "../../api/booking/creator/creatorSlotBookingService";
import userSlotReschedulingService from "../../api/booking/user/userSlotReschedulingService";
import creatorSlotReschedulingService from "../../api/booking/creator/creatorSlotReschedulingService";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

const DetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 94%;
  border: 1px solid ${colors.violet[100]};
  border-radius: 8px;
  padding: 16px 8px;
`;

const DetailItem = styled(Box)`
  display: flex;
  gap: 4px;
  justify-content: space-between;
  width: 100%;
`;

const DetailItemTitle = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

const DetailItemValue = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.violet[100]};
`;

const RescheduleDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const {
    serviceType,
    service,
    minuteSlot,
    daySlot,
    selectedSlot,
    availableSlotTime,
    creatorDetails,
    lockedSlotId,
    timerEndTime,
    bookedSlotId,
  } = useSelector((state: RootState) => state.slotBooking);

  const handleOnClickGoBackButton = async () => {
    try {
      if (timerEndTime !== "") {
        userRole === "user"
          ? await userSlotBookingService.unlockSlot(lockedSlotId)
          : await creatorSlotBookingService.unlockSlot(lockedSlotId);
        dispatch(setTimerEndTime(""));
        dispatch(resetTimer());
        dispatch(setAssignedSlot({}));
        dispatch(setSelectedSlot({ startTime: "", endTime: "" }));
      }
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const timeLeft = useSelector((state: RootState) => state.timer.timeLeft);
  const previousStartTime = queryParams.get("previousStartTime");
  const previousEndTime = queryParams.get("previousEndTime");
  const previousDate = queryParams.get("previousDate");
  const { openSnackBar } = useSnackbar();

  const hasPreviousData = previousStartTime && previousEndTime && previousDate;

  const hasRescheduleData =
    service &&
    minuteSlot &&
    daySlot &&
    selectedSlot &&
    creatorDetails.creatorName;  const handleConfirmation = async () => {
    try {
      const payload = {
        lockedSlotId,
        startTime: availableSlotTime.startTime,
        endTime: availableSlotTime.endTime,
        date: daySlot.date,
        bookedSlotId,
      };
      const { data: responseData } =
        userRole === "user"
          ? await userSlotReschedulingService.getRescheduleSlot(payload)
          : await creatorSlotReschedulingService.getRescheduleSlot(payload);
      if (responseData) {
        dispatch(resetBooking());
        openSnackBar("Booking rescheduled successfully", "success");
        navigate(`/${userRole}/bookings`);
      }
    } catch (error) {
      console.log(error);
      openSnackBar("Error in booking slot", "error");
    }
  };

  useEffect(() => {
    if (timeLeft === "00:00") {
      dispatch(resetTimer());
      navigate(`/${userRole}/time-expired`);
    }
  }, [timeLeft, dispatch, navigate, userRole]);

  return (
    <Container>
      <SubscriptionHeader
        title={`Reschedule - ${service}`}
        handleOnClickGoBackButton={handleOnClickGoBackButton}
      />
      {timerEndTime && <Timer ttl={timerEndTime} />}

      {hasPreviousData && (
        <DetailsContainer>
          <DetailItem>
            <DetailItemTitle sx={{ fontWeight: "bold" }}>
              Previous Booking Details
            </DetailItemTitle>
          </DetailItem>
          <DetailItem>
            <DetailItemTitle>Booking Date</DetailItemTitle>
            <DetailItemValue>{previousDate}</DetailItemValue>
          </DetailItem>
          <DetailItem>
            <DetailItemTitle>No. of minutes</DetailItemTitle>
            <DetailItemValue>{Number(minuteSlot)} Minutes</DetailItemValue>
          </DetailItem>
          <DetailItem>
            <DetailItemTitle>Slot Timing</DetailItemTitle>
            <DetailItemValue>
              {previousStartTime} {previousStartTime && "-"} {previousEndTime}
            </DetailItemValue>
          </DetailItem>
        </DetailsContainer>
      )}
      {hasRescheduleData && (
        <DetailsContainer>
          <DetailItem>
            <DetailItemTitle
              sx={{ fontWeight: "bold", color: colors.violet[100] }}
            >
              Reschedule Booking Details
            </DetailItemTitle>
          </DetailItem>
          <DetailItem>
            <DetailItemTitle>Handle Name</DetailItemTitle>
            <DetailItemValue>{creatorDetails.creatorName}</DetailItemValue>
          </DetailItem>
          <DetailItem>
            <DetailItemTitle>Service name</DetailItemTitle>
            <DetailItemValue>{service}</DetailItemValue>
          </DetailItem>
          <DetailItem>
            <DetailItemTitle>Booking Date</DetailItemTitle>
            <DetailItemValue>{formatLongDate(daySlot?.date)}</DetailItemValue>
          </DetailItem>
          <DetailItem>
            <DetailItemTitle>Type of Booking</DetailItemTitle>
            <DetailItemValue>{serviceType}</DetailItemValue>
          </DetailItem>
          <DetailItem>
            <DetailItemTitle>No. of minutes</DetailItemTitle>
            <DetailItemValue>{Number(minuteSlot)} Minutes</DetailItemValue>
          </DetailItem>
          <DetailItem>
            <DetailItemTitle>Slot Timing</DetailItemTitle>
            <DetailItemValue>
              {availableSlotTime.startTime} {availableSlotTime.startTime && "-"}{" "}
              {availableSlotTime.endTime}
            </DetailItemValue>
          </DetailItem>
        </DetailsContainer>
      )}
      {timeLeft !== "00:00" && (
        <Button
          variant="contained"
          sx={{
            backgroundColor: colors.violet[100],
            color: colors.white,
            fontSize: "16px",
            fontWeight: 700,
            position: "fixed",
            bottom: "0",
            borderRadius: "0",
            "&:hover": {
              backgroundColor: colors.violet[20],
            },
            width: "100%",
            height: "56px",
          }}
          onClick={handleConfirmation}
          disabled={!hasRescheduleData}
        >
          Confirm
        </Button>
      )}
    </Container>
  );
};

export default RescheduleDetails;
