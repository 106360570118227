import styled from "@emotion/styled";
import {
  Box,
  CircularProgress,
  InputLabel,
  Stack,
  Typography,
  Button,
  InputAdornment,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { colors, typography } from "../../utils/styles";
import { InputFieldWithTextCount } from "../../components/Common/CharacterCountInput";
import { GenderSelectRadioInput } from "../../components/Common/GenderSelectRadioInput";
import { TextInput } from "../../components/Common/TextInput";
import { OptionalPhoneNumberInput } from "../../components/Common/OptionalPhoneNumberInput";
import { ChooseContent } from "../../components/Common/ChooseContent";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { STATUS, Status } from "../../constants/statusConstants";
import { useDispatch } from "react-redux";
// import { updateCreatorProfile } from "../../redux/appCreatorSlice";
import { AppDispatch } from "../../store/store";
import { AboutMe } from "../../components/Common/AboutMe";
import { CoverPicture } from "../../components/Common/CoverPicture";
import useHandleNameAvailability from "../../hooks/useHandleNameAvailability";
import creatorApi from "../../api/creator/creatorApi";
import { updateCreator } from "../../redux/appCreatorSlice";
import creatorProfileServiceApi from "../../api/creator/creatorProfileServiceApi";

const EditProfilePageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const PageTag = styled("div")`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  background: ${colors.purple[50]};
`;
const InputLabelComponent = (props:any) => {
  return (
    <InputLabel
      sx={{
        color: colors.black[200],
        fontSize: typography.font_size.sm,
        mb: "8px",
      }}
    >
      {props.children}
      {props.isMandatory && (
        <span style={{ color: "red", marginLeft: "4px" }}>*</span>)}
    </InputLabel>
  );
};
const options = [
  "Educational",
  "Fashion",
  "Fitness",
  "Wellness",
  "Food",
  "Cooking",
  "Gaming",
  "Beauty",
  "Travel",
  "Parenting",
  "Lifestyle",
  "Health",
  "Technology",
  "Gadget",
  "DIY/Craft",
  "Pet",
  "Environment",
  "Sustainability",
];

type EditProfileState = {
  description: string;
  displayName: string;
  handleName: string;
  gender: string;
  phoneNumber?: string;
  preferredContentCategory: string[];
  profilePhotoUrl: string;
  coverPhotoUrl: string;
  contentTarget: string[];
};
const EditCreatorProfile = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = location?.state?.profile;
  const {
    displayName,
    gender,
    phoneNumber,
    preferredContentCategory,
    email,
    handleName,
    description,
    profilePhotoUrl,
    coverPhotoUrl,
    contentTarget,
  } = userInfo;

  const initialState = {
    description: description ?? "",
    displayName: displayName ?? "",
    gender: gender ?? "Male",
    preferredContentCategory: preferredContentCategory ?? [],
    phoneNumber: phoneNumber ?? "",
    handleName: handleName ?? "",
    profilePhotoUrl: profilePhotoUrl ?? "",
    coverPhotoUrl: coverPhotoUrl ?? "",
    contentTarget: contentTarget ?? [],
  };

  const [userProfileDetails, setUserProfileDetails] =
    useState<EditProfileState>(initialState);
  const [isProfileUpdated, setIsProfileUpdated] = useState<Status>(STATUS.IDLE);
  const [imageLoadingStatus, setImageLoadingStatus] = useState(false);
  const navigate = useNavigate();

  const { isAvailable, handleNameChange } = useHandleNameAvailability();

  const handleInputChange = (event: any) => {
    let { name, value } = event?.target;
    if (name === "handleName") {
      handleNameChange(value);
    }

    setUserProfileDetails((prev) => ({ ...prev, [name]: value }));
  };

  const updateCoverPicture = (uri: string) => {
    setUserProfileDetails(prev => ({ ...prev, 'coverPhotoUrl': uri }))
  }
  const updateProfilePicture = (uri: string) => {
    setUserProfileDetails(prev => ({ ...prev, 'profilePhotoUrl': uri }))
  }

  const isSaveBtnActive =
    !!userProfileDetails.description &&
    !!userProfileDetails.displayName &&
    !!(userProfileDetails.preferredContentCategory.length >= 3) &&
    !!(
      !userProfileDetails.phoneNumber ||
      userProfileDetails.phoneNumber.length === 10
    );

  const handleSaveProfileDetails = async () => {
    setIsProfileUpdated(STATUS.LOADING);
    try {
      const filteredUserData = Object.fromEntries(
        Object.entries(userProfileDetails).filter(
          ([key, value]) =>
            value !== "" &&
            value !== null &&
            (!Array.isArray(value) || value.length > 0) &&
            value !== initialState[key as keyof typeof initialState]
        )
      );
      const response = await creatorProfileServiceApi.updateCreatorProfile (
        filteredUserData
      );

      dispatch(updateCreator(response.data.creator));
      setIsProfileUpdated(STATUS.SUCCESS);
      navigate(-1);
    } catch (error) {
      setIsProfileUpdated(STATUS.ERROR);
    }
  };

  return (
    <Box maxWidth="sm" m="auto">
      <EditProfilePageContainer>
        <PageTag>
          <Typography
            fontSize={typography.font_size.base}
            fontWeight={typography.font_weight.bold}
            color={colors.black[200]}
          >
            Edit Profile
          </Typography>
          <CloseIcon sx={{ position: "absolute", right: 10 }} onClick={()=>navigate(-1)}/>
        </PageTag>

        <CoverPicture 
          pictureUrl={userInfo?.coverPhotoUrl ?? ""}
          updateCoverPicture={updateCoverPicture} 
          setImageLoadingStatus={setImageLoadingStatus}
          />
        <AboutMe 
          profilePic={userInfo?.profilePhotoUrl ?? ""} 
          displayName={displayName} 
          handleName={handleName} 
          updateProfilePicture={updateProfilePicture}
          setImageLoadingStatus={setImageLoadingStatus}
          />
        <Stack direction="column" gap="32px" px="16px" pb={6}>
          <Box>
            <InputLabelComponent isMandatory={true}>Description</InputLabelComponent>
            <InputFieldWithTextCount
              name="description"
              placeholder="Write Description"
              characterLimit={200}
              minHeight={72}
              value={userProfileDetails.description}
              onChange={handleInputChange}
            />
          </Box>
          <Box>
            <InputLabelComponent isMandatory={true}>Enter Display Name</InputLabelComponent>
            <InputFieldWithTextCount
              name="displayName"
              placeholder="Enter display name"
              characterLimit={25}
              minHeight={48}
              maxHeight={48}
              value={userProfileDetails.displayName}
              onChange={handleInputChange}
            />
          </Box>

          <Box>
            <InputLabelComponent isMandatory={true}>Handle Name</InputLabelComponent>
            <TextInput
              name="handleName"
              placeholder="Enter your handle name"
              value={userProfileDetails.handleName}
              onChange={handleInputChange}
              helperText={
                userProfileDetails.handleName
                  ? isAvailable
                    ? "Available"
                    : "Handle name not available"
                  : ""
              }
            />
          </Box>

          <Box>
            <InputLabelComponent isMandatory={true}>
              How do you identify yourself?
            </InputLabelComponent>
            <GenderSelectRadioInput
              value={userProfileDetails.gender}
              onChange={handleInputChange}
            />
          </Box>

          <Box>
            <InputLabelComponent>Email</InputLabelComponent>
            <TextInput
              name="email"
              placeholder="Enter your mail"
              value={email}
              disabled
              onChange={() => {}}
            />
          </Box>
          <Box>
            <InputLabelComponent>Phone Number (optional)</InputLabelComponent>
            <OptionalPhoneNumberInput
              name="phoneNumber"
              onChange={handleInputChange}
              value={userProfileDetails.phoneNumber}
            />
          </Box>

          <Box>
            <InputLabelComponent isMandatory={true}>
              What type of content you would like to showcase ?
            </InputLabelComponent>
            <ChooseContent
              name="contentTarget"
              options={options}
              maxSelection={3}
              setUserDetails={setUserProfileDetails}
              initialValues={userInfo?.contentTarget ?? []}
            />
          </Box>

          <Box>
            <InputLabelComponent isMandatory={true}>
              What type of content you would like to see?
            </InputLabelComponent>
            <ChooseContent
              name="preferredContentCategory"
              options={options}
              selectAll={true}
              setUserDetails={setUserProfileDetails}
              initialValues={preferredContentCategory}
            />
          </Box>

          <Box>
            <InputLabelComponent>Instagram handle name</InputLabelComponent>
            <TextField
              disabled
              fullWidth
              placeholder="Instagram Id here"
              sx={{
                "& .MuiInputBase-input": {
                  height: "48px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Button
                      disabled
                      sx={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                      Connect
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>

        {isProfileUpdated !== STATUS.LOADING ? (
          <Button
            fullWidth
            variant={(isSaveBtnActive && !imageLoadingStatus) ? 'primary' : 'disabled'}
            sx={{ borderRadius: 0, position: "sticky", bottom: 0 }}
            onClick={handleSaveProfileDetails}
          >
            Save
          </Button>
        ) : (
          <Button
            fullWidth
            sx={{ borderRadius: 0, position: "sticky", bottom: 0 }}
            variant="primary"
            startIcon={<CircularProgress size={26} />}
          />
        )}
      </EditProfilePageContainer>
    </Box>
  );
};
export { EditCreatorProfile };
