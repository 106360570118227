import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  duration,
  Popover,
  Typography,
} from "@mui/material";
import { BottomNavRoute } from "../routes/bottomNavRoutes";
import { selectCurrentPage, setPage } from "../redux/activePageSlice";
import { typography } from "../colors";
import {
  setMode,
  setFiles,
  setCurrentContentStep,
} from "../redux/creatorContentSlice";
import { videoDuration } from "../services/videoDuration";
import { colors } from "../utils/styles";
import { PHOTO_STEPS, VIDEO_STEPS } from "../utils/creatorContentTypes";
import creatorContentApi from "../api/creator/creatorContentApi";
import { clear_Image_Post_State, set_Compressed_Images, set_No_Of_Images, set_Original_Selected_Images, set_Reference_Images, setView } from "../redux/imageUploadSlice";
import { compressImage } from "./PostImageFeature/imageProcessingUtil";
import { clear_Video_Post_State, set_Video_Post_View, set_Videos } from "../redux/videoUploadSlice";
import { getVideoDimensions } from "./PostVideoFeature/videoUtils";

const BottomNav = ({ userRole }: { userRole: string }) => {
  const [acitve, setActive] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bottomNav = useSelector(selectCurrentPage);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const imageRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    // dispatch(setBottomNav(newValue));
    // if (acitve !== newValue) {
    let path = userRole === "USER" ? "user" : "creator";

    if (newValue === "post") {
      dispatch(clear_Image_Post_State());
      return handleClick(event);
    }

    dispatch(setPage(newValue));
    navigate(`/${path}/${newValue}`, { replace: false });
    // }
  };

  const uploadImageFileHandler = async (event: any) => {
    const totalImages = event.target.files.length;
    dispatch(set_No_Of_Images(totalImages));
    if (event.target.files && totalImages > 0) {
      const fileArray = Array.from(event.target.files).map((file:any) => {
        return compressImage(file);
      });

      Promise.all(fileArray)
        .then((results:any) => {
          const originalImages = results.map((im:any)=>im.original)
          const compressedImages = results.map((im:any)=>im.compressed)

          dispatch(setView('CAROUSEL_VIEW'))
          dispatch(set_Original_Selected_Images(originalImages))
          dispatch(set_Compressed_Images(compressedImages))
          dispatch(set_Reference_Images(compressedImages));
          navigate("/test",{replace:true});
         
        })
        .catch(error => console.error('Error reading files: ', error));
    }
    // dispatch(setFiles(files));
    // dispatch(setCurrentContentStep(PHOTO_STEPS.PHOTO_UPLOADING));
   
  };
  const getMediaSizeFromBlobUrl = async(blobUrl: string): Promise<{ width: number; height: number }> => {
    return await new Promise((resolve, reject) => {
      const video = document.createElement('video');
  
      video.src = blobUrl;
      video.onloadedmetadata = () => {
        const width = video.videoWidth;
        const height = video.videoHeight;
        resolve({ width, height });
      };
  
      video.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadVideoFileHandler = async (event: any) => {
    const input = event.target;

    if (input.files.length > 3) {
      alert("You can only upload a maximum of 3 videos.");
      input.value = "";
      return;
    }

    for (const file of input.files) {
      if (file.type.startsWith("video/") && file.size > 100 * 1024 * 1024) {
        alert(
          `Video file ${file.name} is larger than 100MB and will not be uploaded.`
        );
        input.value = ""; // End the flow if a file exceeds the size limit
        return;
      }
    }

    // const files = await Promise.all(
    //   Object.values(input.files)?.map(async (file: any) => {
    //     const url = await URL.createObjectURL(file);
    //     return {
    //       name: file.name,
    //       url,
    //       actualUrl: url,
    //       selectedFree: false,
    //       maxLimit: await videoDuration(file),
    //       freeSeconds: 0,
    //     };
    //   })
    // );
    if (event.target.files) {
      const fileArray = Array.from(event.target.files);

    // Map through files to get an array of video details (url + dimensions)
    const videoDetailsPromises = fileArray.map(async (file: any) => {
      const url = URL.createObjectURL(file);
      const dimensions = await getVideoDimensions(file);
      
      return {
        url,
        width: dimensions.width,
        height: dimensions.height,
        duration: dimensions.duration,
        maxFreeSeconds:Math.round(dimensions.duration * 0.3)
      };
    });

    // Wait for all promises to resolve while maintaining order
    const videosDetails = await Promise.all(videoDetailsPromises);
    
    // Dispatch the array to Redux store or use as needed
    dispatch(clear_Video_Post_State());
    dispatch(set_Videos(videosDetails));
    dispatch(set_Video_Post_View('CAROUSEL_VIEW'));
      // setVideoUrls(urls);
  }

    // dispatch(setFiles(files));
    // dispatch(setCurrentContentStep(VIDEO_STEPS.VIDEO_UPLOADING));
    navigate("/video");
  };

  const handleVideoButtonClick = () => {
    videoRef.current?.click?.();
    dispatch(setMode("video"));
  };
  const handleImageButtonClick = () => {
    imageRef.current?.click?.();
    dispatch(setMode("photo"));
  };

  useEffect(() => {
    // creatorContentApi.setToken();
  }, []);

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box padding="10px" display="flex" flexDirection="column" gap="16px">
          <input
            ref={imageRef}
            hidden
            multiple
            id="accept-photo"
            accept=".jpg, .jpeg, .png , .gif, .svg"
            type="file"
            onChange={(e) => uploadImageFileHandler(e)}
          />

          <input
            ref={videoRef}
            hidden
            multiple
            id="accept-video"
            accept=".mp4, .avi, .mkv , .gif"
            type="file"
            onChange={(e) => uploadVideoFileHandler(e)}
          />

          <Box
            display="flex"
            flexDirection="row"
            gap="10px"
            sx={{ cursor: "pointer" }}
            onClick={handleImageButtonClick}
          >
            <img
              src="/Icons/sidebarIcons/gallery.svg"
              width="24px"
              height="24px"
            />
            <Typography
              fontSize={typography.font_size["base"]}
              marginRight="30px"
            >
              Add Image
            </Typography>
          </Box>
          <Box sx={{ borderBottom: `1px solid ${colors.gray["95"]}` }}> </Box>

          <Box
            display="flex"
            flexDirection="row"
            gap="10px"
            sx={{ cursor: "pointer" }}
            onClick={() => handleVideoButtonClick()}
          >
            <img
              src="/Icons/sidebarIcons/video.svg"
              width="24px"
              height="24px"
            />
            <Typography fontSize={typography.font_size["base"]}>
              Add Video
            </Typography>
          </Box>
        </Box>
      </Popover>

      <BottomNavigation
        value={acitve}
        onChange={handleChange}
        sx={{
          marginTop: "auto",
          boxShadow: "0px -4px 8px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        {userRole === "CREATOR"
          ? BottomNavRoute.map((item) => (
              <BottomNavigationAction
                key={item.path}
                value={item.text}
                icon={
                  <item.displayIcon
                    style={{
                      color:
                        bottomNav.toLowerCase() === item.text.toLowerCase()
                          ? "#7272C2"
                          : "#9E9E9E",
                    }}
                  />
                }
              />
            ))
          : BottomNavRoute.filter((item) => item.text !== "post").map(
              (item) => (
                <BottomNavigationAction
                  key={item.path}
                  value={item.text}
                  icon={
                    <item.displayIcon
                      style={{
                        color:
                          bottomNav.toLowerCase() === item.text.toLowerCase()
                            ? "#7272C2"
                            : "#9E9E9E",
                      }}
                    />
                  }
                />
              )
            )}
      </BottomNavigation>
    </>
  );
};

export { BottomNav };
