import React, { useEffect, useState } from 'react';
import CheckCreatorsAvailability from '../../components/Creator/CreatorBookings/CheckCreatorsAvailability';


const CheckAvailability = () => {
  return (
        <CheckCreatorsAvailability/>
  )
}

export default CheckAvailability;