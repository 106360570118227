import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
const ToogleSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: 'rgba(46, 125, 50, 1)',
    '&:hover': {
      backgroundColor: alpha("rgba(46, 125, 50, 1)", theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: 'rgba(33, 33, 33, 0.05)',
  },
}));
const label = { inputProps: { 'aria-label': 'Color switch demo' } };
interface ToogleButtonProps{
    handleOnChange:(event: React.ChangeEvent<HTMLInputElement>)=>void;
    checked: boolean;
}

const  ToogleButton:React.FC<ToogleButtonProps>=({handleOnChange, checked})=> {
  return (
    <div>
      <ToogleSwitch {...label} defaultChecked size="small"
        checked={checked}
        onChange={handleOnChange}
      />
    </div>
  );
}


export default ToogleButton;