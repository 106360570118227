import { ApiService } from "../ApiClient";
import { userAxiosInstance } from "../axiosInstance";
import "../interceptors/userAuthInterceptors";

class UserCallApi extends ApiService {
  constructor() {
    super(userAxiosInstance);
  }

  startCall(payload: any) {
    return this.axios
      .put("creator-services/call/start/user", payload)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  endCall(payload: any) {
    return this.axios
      .put("creator-services/call/end/user", payload)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}

export default new UserCallApi();
