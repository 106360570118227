import React from "react";
import { Box, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SubscriptionPlan from "../../../../components/Creator/SubscriptionPlan/BuySubscriptionCard";
import SubscriptionHeader from "../CreatorSubscriptions/SubscriptionHeader";
import { BuySubscription } from "../../../BuySubscription/BuySubscription";


const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items:center;
`;

const CreatorBuySubscription = () => {
    const navigate = useNavigate();
    const handleOnClickGoBackButton=()=>{
      navigate(-1);
    }

  return (
    <Container>
    <BuySubscription/>
  </Container>
  )
}

export default CreatorBuySubscription;