import { Box, Button, Stack, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors, typography } from "../../utils/styles";
import CollectionsIcon from "@mui/icons-material/Collections";
import { DetailedPurchasedContentView } from "./DetailedPurchasedContent";
import userApi from "../../api/user/userApi";
import { useLocation, useNavigate } from "react-router-dom";
import userGalleryService from "../../api/gallery/userGalleryService";
import creatorGalleryService from "../../api/gallery/creatorGalleryService";


const ContentContainer=styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  justify-content: space-between;
`;
const PurchasedContent = () => {
  const location = useLocation();
  let userRole = location.pathname.includes("user") ? "user" : "creator";
  const [postId, setPostId] = useState("");
  const [postData, setPostData] = useState([]);

  const handleImageClicked = (postDetails: any) => {
    setPostId(postDetails.id);
    setPostData(postDetails);
  };

  const getGalleryData = async () => {
    try {
      const response =
        userRole === "user"
          ? await userGalleryService.fetchGallery()
          : await creatorGalleryService.fetchGallery();
      setPostData(response.data.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getGalleryData();
  }, []);

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap">
      {postData.length === 0 ? 
        <NoContent /> :
        <Content data={postData} />
       }
    </Box>
  );
};

export { PurchasedContent };

const NoContent = () => {
  const navigate = useNavigate();
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      height="80dvh"
      px="5rem"
    >
      <img
        width="194px"
        height="145.5px"
        src="/Images/illustration_reading.png"
        alt="no subscription"
      />
      <Typography
        gutterBottom
        fontSize={typography.font_size.base}
        fontWeight={700}
        color={colors.gray[80]}
      >
        No Content Purchased
      </Typography>
      <Typography
        gutterBottom
        fontSize={typography.font_size.sm}
        fontWeight={400}
        color={colors.gray[80]}
      >
        Explore Creator & purchase to access exclusive content
      </Typography>
      <Button variant="contained" onClick={() => navigate("/user/home")}>
        Go to Home
      </Button>
    </Stack>
  );
};

const Content = ({ data }: { data: any }) => {
  return (
    <ContentContainer
     >
      {data.map((post: any) => (
        <Tile data={post} />
      ))}
    </ContentContainer>
  );
};

const Tile = ({ data }: { data: any }) => {
  const location = useLocation();
  const userRole = location.pathname.includes("/user") ? "user" : "creator";
  const navigate = useNavigate();
  const onClickTile = () => {
    navigate(`/${userRole}/gallery/explore`);
  };
  return (
    <Box
      p="2px"
      // width="33%"
      // minWidth="124px"
      height="124px"
      sx={{ position: "relative" }}
      onClick={onClickTile}
    >
      <img src={data.coverPhotoUrl} width="100%" height="100%" />
      <Mark type={data.contentType} count={data.contentCount} />
    </Box>
  );
};
const Mark = ({ type, count }: { type: string; count: number }) => {
  const tag = () => {
    if (count > 1) {
      return type === "PHOTO" ? (
        <img src="/Icons/postType/multiplePhotos.svg" width={16} height={16} />
      ) : (
        <img src="/Icons/postType/multipleVideos.svg" width={16} height={16} />
      );
    } else {
      return null;
    }
  };
  return (
    <Box sx={{ position: "absolute", right: "5px", top: "2px" }}>{tag()}</Box>
  );
};
