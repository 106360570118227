import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface BaseUser {
  id: string;
  email: string;
  displayName: string;
  handleName: string;
  description:string;
  profilePhotoUrl: string | null;
  coverPhotoUrl: string | null;
  gender: string;
  preferredContentCategory: string[];
  coins: number;
  followingCount: number;
  isEmailVerified: boolean;
  isOnboardingDone: boolean;
  createdAt: string;
  updatedAt: string;
  isCreator: boolean;
  referralCode?:string | null;
}

interface UserState extends BaseUser {
  phoneNumber: string | null;
  isActive: boolean;
  isRemoved: boolean;
  isReported: boolean;
  isBlocked: boolean;
}

interface CreatorState extends BaseUser {
  creatorId: string | null;
}

const initialUserState: UserState = {
  id: '',
  email: '',
  phoneNumber: null,
  displayName: '',
  handleName: '',
  description:'',
  profilePhotoUrl: null,
  coverPhotoUrl: null,
  gender: '',
  preferredContentCategory: [],
  coins: 0,
  followingCount: 0,
  isCreator:false,
  isEmailVerified: false,
  isOnboardingDone: false,
  isActive: false,
  isRemoved: false,
  isReported: false,
  isBlocked: false,
  createdAt: '',
  updatedAt: '',
  referralCode: null,
};

const initialCreatorState: CreatorState = {
  id: '',
  email: '',
  displayName: '',
  handleName: '',
  description:'',
  profilePhotoUrl: null,
  coverPhotoUrl: null,
  gender: '',
  preferredContentCategory: [],
  coins: 0,
  followingCount: 0,
  isEmailVerified: false,
  isOnboardingDone: false,
  createdAt: '',
  updatedAt: '',
  creatorId: null,
  isCreator: false,
  referralCode: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser(state, action: PayloadAction<UserState>) {
      return { ...state, ...action.payload };
    },
    updateUser(state, action: PayloadAction<Partial<UserState>>) {
      return { ...state, ...action.payload };
    },
    clearUser(state) {
      return initialUserState;
    },
  },
});

const creatorSlice = createSlice({
  name: 'creator',
  initialState: initialCreatorState,
  reducers: {
    setCreator(state, action: PayloadAction<CreatorState>) {
      return { ...state, ...action.payload };
    },
    updateCreator(state, action: PayloadAction<Partial<CreatorState>>) {
      return { ...state, ...action.payload };
    },
    clearCreator(state) {
      return initialCreatorState;
    },
  },
});

export const { setUser, updateUser, clearUser } = userSlice.actions;
export const { setCreator, updateCreator, clearCreator } = creatorSlice.actions;

export const userReducer = userSlice.reducer;
export const creatorReducer = creatorSlice.reducer;
