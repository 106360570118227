import { Box } from "@mui/material";
import { useState } from "react";
import { NoPurchasedContent } from "../../components/Gallery/NoPurchasedContent";
import { PurchasedContent } from "../../components/Gallery/PurchasedContent";

const Gallery = () => {
  const [contentPurchased, setContentPurchased] = useState(true);
  return (
    <Box>
      {contentPurchased ? <PurchasedContent /> : <NoPurchasedContent />}
    </Box>
  );
};
export { Gallery };