import React, { useRef, useState } from "react";
import { Avatar, Badge, Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { uploadimage } from "../services/uploadToS3";
import { colors } from "../utils/styles";

const AddIcon = styled(Avatar)(({ theme }) => ({
  width: 19.83,
  height: 19.83,
}));

const UserDisplayPicture: React.FC<{ setProfilePicture: (url: string) => void }> = ({
  setProfilePicture,
}) => {
  const [selectedImage, setSelectedImage] = useState<string>("");
  const imageInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpdate = () => {
    imageInputRef.current?.click();
  };

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      const imgUrl = await uploadimage("users/profilepicture", file)
        .then((d) => {
          setProfilePicture(d);
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <Box mb="42px" sx={{ textAlign: "center" }}>
      <Badge
        sx={{ cursor: "pointer" }}
        onClick={handleImageUpdate}
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={<AddIcon src="/Icons/plus_icon_circle.svg" />}
      >
        <Avatar
          sx={{ width: "72px", height: "72px" }}
          alt="display_picture"
          src={selectedImage}
        />
      </Badge>
      <Typography sx={{ fontSize: "11.5px", mt: "8px", color: colors.black[100] }}>
        Upload picture
      </Typography>
      <input
        ref={imageInputRef}
        type="file"
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleImageChange}
      />
    </Box>
  );
};

export { UserDisplayPicture };
