import React, { createContext, useContext, useState, ReactNode } from 'react';
import { RequestCloseModal, RequestOpenModal } from '../types';

interface ModalObj { 
  name: string;
  component: React.ReactNode;
}


interface ModalContextProps {
  requestOpenModal: RequestOpenModal;
  requestCloseModal: RequestCloseModal;
  activeAppModals: ModalObj[];
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [activeAppModals, setActiveAppModals] = useState<ModalObj[]>([]);

  const requestOpenModal: RequestOpenModal = (obj) => {
    setActiveAppModals((prev) => [...prev, obj]);
  };

  const requestCloseModal: RequestCloseModal = () => {
    setActiveAppModals((prev) => prev.slice(0, -1));
  };

  return (
    <ModalContext.Provider value={{ requestOpenModal, requestCloseModal, activeAppModals }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextProps => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
