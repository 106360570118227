// src/VideoContext.tsx
import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';

interface VideoContextProps {
  currentVideo: HTMLVideoElement | null;
  playVideo: (videoRef: HTMLVideoElement) => void;
  mute: boolean;
  toggleMute: () => void;
}

const VideoContext = createContext<VideoContextProps | undefined>(undefined);

interface VideoProviderProps {
  children: ReactNode;
}

export const VideoProvider: React.FC<VideoProviderProps> = ({ children }) => {
  const [currentVideo, setCurrentVideo] = useState<HTMLVideoElement | null>(null);
  const [mute, setMute] = useState(false);

  const playVideo = useCallback((videoRef: HTMLVideoElement) => {
    if (currentVideo && currentVideo !== videoRef) {
      currentVideo.pause();
    }
    setCurrentVideo(videoRef);
  }, [currentVideo]);

  const toggleMute = useCallback(() => {
    setMute((prevMute) => !prevMute);
  }, []);

  return (
    <VideoContext.Provider value={{ currentVideo, playVideo, mute, toggleMute }}>
      {children}
    </VideoContext.Provider>
  );
};

export const useVideo = (): VideoContextProps => {
  const context = useContext(VideoContext);
  if (context === undefined) {
    throw new Error('useVideo must be used within a VideoProvider');
  }
  return context;
};
