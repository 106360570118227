import { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { PageTagComponent } from "../../../components/Common/PageTagComponent"
import TransactionStatusTabs from "../../../components/Wallet/Transactions/TransactionStatusTab"
import { TabContext, TabPanel } from "@mui/lab"
import TxnHistory from "../../../components/Wallet/Transactions/History"
import PendingTxn from "../../../components/Wallet/Transactions/Pending"

const UserTransactionPage = () => {
    const [value, setValue] = useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Box>
            <PageTagComponent>Transactions</PageTagComponent>
                <Box pl="16px">
                    <TxnHistory userRole="user"/>
                </Box>
        </Box>
    )


}
export default UserTransactionPage