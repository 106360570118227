import { ApiService } from '../ApiClient';
import {userAxiosInstance} from '../axiosInstance';
import "../interceptors/userAuthInterceptors";

class CreatorProfileService extends ApiService {
  constructor() {
    super(userAxiosInstance);
  }

  getAnotherCreatorsProfile(creatorId:string){
    return this.axios.get(`creator/profile/${creatorId}`);
  }

  getFreePostList(creatorId:string, page:string, pageSize: string){
    return this.axios.get(`content/post?creatorId=${creatorId}&postAccessType=FREE&page=${page}&pageSize=${pageSize}`);
  }

  getLockedPostList(creatorId:string, page:string, pageSize: string){
    return this.axios.get(`content/post?creatorId=${creatorId}&postAccessType=PAID&page=${page}&pageSize=${pageSize}`);
  }

  getPurchasedPostList(creatorId:string, page:string, pageSize: string){
    return this.axios.get(`content/gallery?page=${page}&pageSize=${pageSize}&creatorId=${creatorId}&view=LIST`);
  }

  getCreatorsSubscription(creatorId:string){
    return this.axios.get(`subscription?creatorId=${creatorId}`);
  }
  getCreatorsOnDemandRates(creatorId:string){
    return this.axios.get(`subscription/on-demand-rate?creatorId=${creatorId}`);
  }

  addFollower(userId:string){
    return this.axios.post(`creator/following/${userId}`);
  }

  removeFollower(userId:string){
    return this.axios.delete(`creator/following/${userId}`);
  }

  getAllPostsOfACreator(creatorId:string, page:string, pageSize: string){
    return this.axios.get(`content/post?creatorId=${creatorId}&postAccessType=ALL&page=${page}&pageSize=${pageSize}`);
  }

  getCreatorsFollowersList(page:string, pageSize: string){
    return this.axios.get(`creator/following/followers?page=${page}&pageSize=${pageSize}`);
  }

  getCreatorsFollowingList(page:string, pageSize: string){
    return this.axios.get(`creator/following/followings?page=${page}&pageSize=${pageSize}`);
  }

  getCreatorsPostStats(creatorId:string){
    return this.axios.get(`content/post/stats?creatorId=${creatorId}`);
  }
 
 
}

export default new CreatorProfileService();