import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs, styled, CircularProgress } from "@mui/material";
import ActiveSubscription from "./ActiveSubscription";
import SubscriptionHistory from "./SubscriptionHistory";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setActivePagination, setActiveSubscriptions, setHistoryPagination, setHistorySubscriptions, setLoading } from "../../redux/subscriptionSlice";
import { useLocation } from "react-router-dom";
import creatorSubscriptionServiceApi from "../../api/creator/subscriptionServiceApi";
import userSubscriptionServiceApi from "../../api/user/subscriptionServiceApi";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  position: relative;
`;
const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 70vh;
  width: 100%;
  gap: 16px;
`;
const TabMainContainer = styled(Box)`
  display: flex;
  padding: 0px 8px;
  align-items: flex-start;
  align-self: stretch;
`;
const TabContainer = styled(Box)`
  border-bottom: 2px solid rgba(33, 33, 33, 0.1);
`;
const TabsItems = styled(Tabs)({
  "& .MuiTabs-indicator": {
    height: "8px",
    borderRadius: "12px",
    bottom: "-5px",
  },
});

const TabItem = styled(Tab)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
`;

const currentActiveTab = {
  ACTIVE: "Active",
  HISTORY: "History",
};

const Subscription = () => {
  const location = useLocation()
  const userRole = location.pathname.includes('user')? 'user':'creator'
  const [value, setValue] = useState(currentActiveTab.ACTIVE);
  const [activeTab, setActiveTab] = useState(currentActiveTab.ACTIVE);
  const dispatch = useDispatch<AppDispatch>();
  const { activeSubscriptions, historySubscriptions, loading } = useSelector((state: RootState) => state.subscriptions);
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    setValue(newValue);
    setTimeout(() => {
      setActiveTab(newValue);
    }, 200);
  };
  const fetchSubscriptions = async () => {
    dispatch(setLoading(true));
    try {
      const response = userRole === 'creator' ? 
      await creatorSubscriptionServiceApi.getAllSubscription("1", "10")
     : 
     await userSubscriptionServiceApi.getAllSubscription("1", "10")
      let activeSubscriptionData = response.data.data.activeSubscriptions;
      let activePaginationData = activeSubscriptionData.pagination;
      let historySubscriptionData = response.data.data.historySubscriptions;
      let historyPaginationData = historySubscriptionData.pagination;
      dispatch(setActiveSubscriptions(activeSubscriptionData.subscriptions));
      dispatch(setHistorySubscriptions(historySubscriptionData.subscriptions));
      dispatch(setActivePagination(activePaginationData));
      dispatch(setHistoryPagination(historyPaginationData));
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return (
    <Container>
      <TabMainContainer>
        <TabContainer sx={{ width: "100%" }}>
          <TabsItems
            value={value}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
          >
            <TabItem
              value={currentActiveTab.ACTIVE}
              label={`${currentActiveTab.ACTIVE}(${activeSubscriptions?.subscriptions?.length})`}
            />
            <TabItem
              value={currentActiveTab.HISTORY}
              label={`${currentActiveTab.HISTORY}(${historySubscriptions?.subscriptions?.length})`}
            />
          </TabsItems>
        </TabContainer>
      </TabMainContainer>

      <ContentContainer>
        {loading ? (
          <CircularProgress />
        ) : activeTab === currentActiveTab.ACTIVE ? (
          <ActiveSubscription activeSubscriptions={activeSubscriptions} userRole={userRole}/>
        ) : activeTab === currentActiveTab.HISTORY ? (
          <SubscriptionHistory historySubscriptions={historySubscriptions} userRole={userRole}/>
        ) : null}
      </ContentContainer>
    </Container>
  );
};

export { Subscription };
