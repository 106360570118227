export const PHOTO_STEPS = {
  PHOTO_UPLOADING: "photo_uploading",
  PHOTO_CAROUSEL: "photo_carousel",
  FREE_PHOTOS: "free_photos",
  COVER_PHOTOS: "cover_photos",
  POST_DETAILS: "post_details",
  POST_AMOUNT: "post_amount",
};

export const VIDEO_STEPS = {
  VIDEO_UPLOADING: "video_uploading",
  VIDEO_CAROUSEL: "video_carousel",
  PREVIEW_VIDEOS: "preview_videos",
  POST_DETAILS: "post_details",
  POST_AMOUNT: "post_amount",
};
