import React, { useState } from "react";
import { Box, Button, InputLabel, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentStep,
  setCurrentStep,
  setLoading,
} from "../../../redux/creatorRegistrationSlice";
import userApi from "../../../api/user/userApi";

export default function GstVerification(props: { next: () => void }) {
  const dispatch = useDispatch();
  const currentStep = useSelector(getCurrentStep);

  const [gstNumber, setGstNumber] = useState("");
  const [errors, setErrors] = useState<{
    gstNumber?: string;
  }>({});

  const checkGstNum = (e: any) => {
    const num = e.target.value;
    if (num.length <= 12) {
      setGstNumber(num);
    }
  };

  const handleSubmit = async () => {
    try {
      dispatch(setLoading(true));

      const payload = {
        gst_number: gstNumber.trim(),
      };
      await userApi.verifyGstNum(payload);
      props.next();
    } catch (error: any) {
      console.error("Error during gst verification:", error);
      setErrors({ gstNumber: error.message });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Box>
      <Box mx="16px">
        <InputLabel sx={{ paddingBottom: "4px", paddingTop: "15px" }}>
          Enter GST number (Mandatory)
        </InputLabel>
        <TextField
          variant="outlined"
          placeholder="000000000000"
          fullWidth
          value={gstNumber}
          onChange={checkGstNum}
          error={!!errors.gstNumber}
          helperText={errors.gstNumber}
          InputProps={{
            style: { height: "48px" },
          }}
          inputProps={{ maxLength: 12 }}
        />
      </Box>

      <Box position="absolute" bottom="0" width="100%">
        <Button
          type="submit"
          onClick={handleSubmit}
          variant={gstNumber.length < 11 ? "disabled" : "primary"}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}
