import React from "react"
import CreatorProfile from "./CreatorProfile";
import UserProfile from "./UserProfile";
import { useLocation } from "react-router-dom";

const Profile:  React.FC = () =>{
    const location = useLocation();
    const userRole = location.pathname.includes('user') ? "user" :"creator"
    const component = {
        user:<UserProfile />,
        creator:<CreatorProfile />
    }

    return component[userRole]
}
export {Profile} 