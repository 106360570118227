import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setUserFeeds, addMoreFeedsToUserFeeds } from "../../redux/userFeedSlice";
import { UserFeedType } from "../../types";
import feedsServices from "../../api/feeds/feedsServices";
import userFeedsService from "../../api/feeds/userFeedsService";
import UserFeed from "../../components/Feeds/UserFeed";
import { styled, Typography, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import PostSkeleton from "../../components/Common/PostSkeleton";

const NoPostScreenContainer = styled(Box)`
  width: 100vw;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoPostScreen = styled(Typography)`
  color: var(--Text-Accent-2, #616161);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  letter-spacing: 0.24px;
`;

const Home: React.FC = () => {
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const [posts, setPosts] = useState<UserFeedType[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { userFeeds } = useSelector((state: RootState) => state.userFeeds);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { ref, inView } = useInView({
    threshold: 0.85,
    triggerOnce: false,
  });

  const fetchUserFeeds =async (page: number) => {
    setLoading(true);
    try {
      const response = userRole === "creator"
        ? await feedsServices.getHomeFeeds(page.toString(), "3")
        : await userFeedsService.getHomeFeeds(page.toString(), "3");

      const newPosts = response.data.data.data;
      if (newPosts.length > 0) {
        if(page>1){
          dispatch(addMoreFeedsToUserFeeds(newPosts));
        }

        else{
          dispatch(setUserFeeds(newPosts));
        }
        
      }
      const fetchedTotalPages = response.data.data.pagination.totalPages;
      setTotalPages(fetchedTotalPages);
    } catch (error) {
      console.error("Error fetching feeds:", error);
    } finally {
      handleLoading();
    }
  }


  const handleLoading=()=>{
    setTimeout(()=>{
      setLoading(false);
    }, 2000);
  }

  useEffect(() => {
    fetchUserFeeds(page);
  }, [page]);

  useEffect(() => {
      if (inView && !loading && totalPages > page) {
          setPage((prevPage) => prevPage + 1);
      } 
  }, [inView]);

  return (
    <Box width="100%" ref={ref}>
      <Box
        width="100%"
        sx={{
          // visibility: !loading ? "visible" : "hidden",
          "& .MuiTabPanel-root": {
            padding: "0px",
          },
        }}
      >
            {userFeeds?.map((post, index) => {
              if (userFeeds?.length === index + 1) {
                return (
                  <div ref={ref} key={post?.id}>
                    <UserFeed post={post} handleLoading={handleLoading}/>
                  </div>
                );
              } else {
                return <UserFeed key={post?.id} post={post} />;
              }
            })}

        {!loading && userFeeds?.length ===0 && (
          <NoPostScreenContainer>
            <NoPostScreen>No more posts found</NoPostScreen>
          </NoPostScreenContainer>
        )}
      </Box>

      {loading && (
        <Box>
          {Array.from({ length: 2 }, (_, index) => index).map((index) => (
            <PostSkeleton key={index} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export { Home };


