import { InputAdornment, TextField } from "@mui/material";
import { ChangeEventHandler } from "react";

interface PhoneNoInput {
  value?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  name?: string;
}

const OptionalPhoneNumberInput: React.FC<PhoneNoInput> = ({ value, onChange, name }) => {
  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    if (!/^\d*$/.test(value) || value.length > 10) {
      return;
    }
    onChange(event);
  };

  return (
    <TextField
      fullWidth
      placeholder="XXXXX XXXXX"
      name={name ?? ""}
      value={value}
      onChange={handleOnChange}
      inputProps={{ maxLength: 10 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <p
              style={{
                background: "#E0E0E0",
                padding: "5px 8px",
                borderRadius: "4px",
              }}
            >
              +91
            </p>
          </InputAdornment>
        ),
      }}
      sx={{
        '& .MuiInputBase-input': {
          height: "48px",
          letterSpacing: ".5px",
        },
      }}
    />
  );
};

export { OptionalPhoneNumberInput };
