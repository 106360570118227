import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { colors, typography } from "../../../utils/styles";
import { OTPInput } from "./OTPInput";
import userApi from "../../../api/user/userApi";
import { setLoading } from "../../../redux/creatorRegistrationSlice";
import { useDispatch } from "react-redux";

export default function AadharVerification(props: any) {
  const dispatch = useDispatch();

  const [aadharNumber, setAadharNumber] = useState("");
  const [requestId, setRequestId] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpVerified, setOtpVerified] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [errors, setErrors] = useState<{
    aadhar?: string;
    otp?: string;
  }>({});

  const isOtpFilled = otp.every((num) => Boolean(num));

  useEffect(() => {
    if (isOtpFilled) handleNext();
  }, [isOtpFilled]);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [seconds]);

  const formatTime = (sec: number) => {
    const minutes = Math.floor(sec / 60);
    const remainingSeconds = sec % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const handleSubmit = async () => {
    try {
      dispatch(setLoading(true));
      const payload = {
        aadhar_number: aadharNumber.trim(),
      };
      const response = await userApi.sendAadharOtp(payload);

      setRequestId(response.data.requestId);
      setOtpSent(true);
      setSeconds(10);
    } catch (error: any) {
      setErrors({ ...errors, aadhar: error.message });
      console.error("Error during step two:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleNext = async () => {
    try {
      dispatch(setLoading(true));

      const payload = {
        request_id: requestId,
        otp: otp.join(""),
        aadhar_number: aadharNumber.trim(),
      };
      await userApi.verifyAadharOtp(payload);
      setOtpVerified(true);
    } catch (e) {
      setErrors({ ...errors, otp: "Error" });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleAadharNumberChange = (e: any) => {
    const num = e.target.value;
    if (num.length <= 12) {
      setAadharNumber(num);
    }
  };

  return (
    <Box>
      <Box mx="16px">
        <InputLabel sx={{ paddingBottom: "4px", paddingTop: "15px" }}>
          Enter Aadhar Card
        </InputLabel>
        <TextField
          type="number"
          variant="outlined"
          placeholder="000000000000"
          fullWidth
          value={aadharNumber}
          onChange={handleAadharNumberChange}
          error={!!errors.aadhar}
          helperText={errors.aadhar}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton edge="start">
                  <img src="/Images/aadhar.svg" />
                </IconButton>
              </InputAdornment>
            ),
            style: { height: "48px" },
          }}
          inputProps={{ maxLength: 12 }}
        />

        {otpSent && (
          <Stack>
            <Typography
              mt="32px"
              fontSize={typography.font_size["sm"]}
              color={colors.gray["80"]}
            >
              Please Enter OTP sent on aadhar card <br /> registered mobile
              number
            </Typography>

            {errors.otp && (
              <Typography
                mt="32px"
                fontSize={typography.font_size["sm"]}
                color={colors.red["100"]}
              >
                Entered OTP sent on aadhar card registered <br /> mobile number
                is wrong
              </Typography>
            )}

            <OTPInput
              otp={otp}
              setOtp={setOtp}
              handleOTPVerification={{}}
              sx={{ marginTop: "16px" }}
            />

            {otpVerified ? (
              <Typography color={colors.green["200"]} mt="6px">
                OTP Verified Successfully!
              </Typography>
            ) : (
              <Box mt="6px">
                {seconds === 0 && (
                  <Box
                    color={colors.violet["100"]}
                    fontWeight={typography.font_weight["bold"]}
                    onClick={handleSubmit}
                    sx={{ cursor: "pointer" }}
                  >
                    Resend
                  </Box>
                )}
                {seconds !== 0 && (
                  <Typography>
                    <b>{formatTime(seconds)}</b> sec remaining
                  </Typography>
                )}
              </Box>
            )}
          </Stack>
        )}
      </Box>

      <Box position="absolute" bottom="0" width="100%">
        {otpSent ? (
          <Button
            type="submit"
            onClick={props.next}
            variant={otpVerified ? "primary" : "disabled"}
            fullWidth
          >
            Next
          </Button>
        ) : (
          <Button
            type="submit"
            onClick={handleSubmit}
            variant={aadharNumber.length < 11 ? "disabled" : "primary"}
            fullWidth
          >
            Send OTP
          </Button>
        )}
      </Box>
    </Box>
  );
}
