import styled from "@emotion/styled";
import { colors, typography } from "../../utils/styles";
import { Typography } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";

const PageTag = styled("div")`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  background: ${colors.purple[50]};
  margin-bottom:8px;
  width: 100%;
`

export const PageTagComponent = (props:any) =>{
    const navigate = useNavigate();
    const handleOnClickBackButton=()=>{
      if(typeof props.handleBack==="function"){
        props.handleBack()
      }
      else{
        navigate(props?.to ?? -1,{replace:true})
      }
    }

    return(
        <PageTag>
            <Typography
            fontSize={typography.font_size.base}
            fontWeight={typography.font_weight.bold}
            color={colors.black[200]}
          >
            {props?.children}
          </Typography>
          <ArrowBackIosIcon sx={{ position: "absolute", left: 16 }} onClick={handleOnClickBackButton}/>
        </PageTag>
    )
}