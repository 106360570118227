import React, { useCallback, useEffect, useState } from "react";
import CreatorFollowing from "../../../components/Creator/Following/Following";
import followingsApi from "../../../api/creator/socialConnectionsApi";
import { useParams } from "react-router-dom";

interface FollowingType {
  id: number;
  img: string;
  name: string;
  handle: string;
  type: string;
}

interface CreatorFollowingProps {
  isOwn: boolean;
}

const FollowingPage: React.FC<CreatorFollowingProps> = ({ isOwn }) => {
  const [followingData, setFollowingData] = useState<FollowingType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { id } = useParams();

  // Function to fetch followings
  const fetchOwnFollowings = useCallback(() => {
    if (loading || !hasMore) return;

    setLoading(true);

    followingsApi
      .getOwnFollowings(page)
      .then(({ data }) => {
        const filteredData = data.data.map((item: any) => ({
          id: item.user.id,
          img: item.user.profilePhotoUrl,
          name: item.user.displayName,
          handle: item.user.handleName,
          type: item.user.creator ? "creator" : "user",
        }));

        if (filteredData.length === 0) {
          setHasMore(false);
        } else {
          setFollowingData((prevData) => {
            const newData = [...prevData, ...filteredData];
            setHasMore(data.pagination.totalItems > newData.length);
            return newData;
          });

          setPage((prevPage) => prevPage + 1);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setHasMore(false);
      });
  }, [page, loading, hasMore]);

  const fetchFollowings = useCallback(() => {
    if (loading || !hasMore) return;

    setLoading(true);

    followingsApi
      .getCreatorFollowings(id!, page)
      .then(({ data }) => {
        const filteredData = data.data.map((item: any) => ({
          id: item.user.id,
          img: item.user.profilePhotoUrl,
          name: item.user.displayName,
          handle: item.user.handleName,
          type: "creator",
        }));

        if (filteredData.length === 0) {
          setHasMore(false);
        } else {
          setFollowingData((prevData) => {
            const newData = [...prevData, ...filteredData];
            setHasMore(data.pagination.totalItems > newData.length);
            return newData;
          });

          setPage((prevPage) => prevPage + 1);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setHasMore(false);
      });
  }, [page, loading, hasMore]);

  useEffect(() => {
    isOwn ? fetchOwnFollowings() : fetchFollowings();
  }, []);

  return (
    <CreatorFollowing
      text="Followings"
      data={followingData}
      fetchMoredata={isOwn ? fetchOwnFollowings : fetchFollowings}
      hasMore={hasMore}
      loading={loading}
    />
  );
};

export default FollowingPage;
