import { useState, useRef, useCallback } from "react";
import userApi from "../api/user/userApi";

const useHandleNameAvailability = () => {
  const [isAvailable, setIsAvailable] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const checkAvailability = useCallback(async (input: string) => {
    setLoading(true);
    try {
      const response = await userApi.checkHandleAvailability(input);
      setIsAvailable(response?.data?.isAvailable);
    } catch (error) {
      console.error("Error checking handle name availability:", error);
      setIsAvailable(null);
    }
    setLoading(false);
  }, []);

  const handleNameChange = useCallback(
    (value: string) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        if (value) {
          checkAvailability(value);
        } else {
          setIsAvailable(null);
        }
      }, 1000);
    },
    [checkAvailability]
  );

  return { isAvailable, loading, handleNameChange };
};

export default useHandleNameAvailability;
