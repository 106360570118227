import  { ApiService } from "../ApiClient";
import { creatorAxiosInstance } from "../axiosInstance";
import "../interceptors/creatorAuthInterceptors"
class CreatorContentApi extends ApiService{
  constructor() {
    super(creatorAxiosInstance)
  }
  uploadPost(payload: any) {
      return this.axios.post("content/post", payload);
  }  
}

export default new CreatorContentApi();
