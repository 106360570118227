import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { PageTagComponent } from "../../components/Common/PageTagComponent";
import { typography, colors } from "../../utils/styles";

const PolicyContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Header = styled(Box)`
  position: sticky;
  top: 0;
`;
const Section = styled(Box)``;
const SectionTitle = styled(Typography)`
  font-size: ${typography.font_size.lg}px;
  font-weight: ${typography.font_weight.semiBold};
  color: ${colors.violet[100]}; /* Assuming var(--Text-Brand-Primary, #7262c2) maps to violet[100] */
`;
const SectionContent = styled(Typography)`
  font-size: ${typography.font_size.sm}px;
  color: ${colors.black[200]};
  line-height: ${typography.line_height.default}; /* 1.6 mapped to '135%' */
`;
const Content = styled(Box)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;
const Items = styled("ul")``;
const Item = styled("li")`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
  margin-left: 1.5rem;
`;
const ItemTextBold = styled("b")`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const PrivacyPolicyPage = () => {
  return (
    <PolicyContainer>
      <Header>
        <PageTagComponent>Privacy Policy</PageTagComponent>
      </Header>
      <Content>
        <Section>
          <SectionTitle>Our Privacy Policies</SectionTitle>
          <SectionContent>
            Effective as of October 01, 2024. <br />
            <br />
            Clout is a social network which enables users to share their
            content; other users to enjoy content and for some to do both. We
            refer to those users who share content as “Creators” and those users
            who pay to view Creators’ content as “Fans”. This Privacy Policy
            describes the privacy practices of Clout, Inc. (“Clout” “we”, “us”
            or “our”) and how we handle your personal data when you visit our
            site www.tryclout.com (the “Website”), any related sites, pages,
            services and goods or any other website operated by Clout that links
            to this Privacy Policy (the “Services”). The purpose of this Privacy
            Policy is to provide you with a clear explanation of what personal
            data we collect, when, why and how we collect, use and share your
            personal data and it explains your statutory rights.
            <br />
            <br />
            We strongly urge you to read this Privacy Policy and make sure you
            fully understand our practices in relation to personal data before
            you use our Services.
          </SectionContent>
        </Section>
        <br />
        <Section>
          <SectionTitle>Index</SectionTitle>
          <SectionContent>
            <Items>
              <Item>Personal data we collect </Item>
              <Item>How we use your personal data </Item>
              <Item>How we share your personal data</Item>
              <Item>Your choices</Item>
              <Item>Other sites and services</Item>
              <Item>Security</Item>
              <Item>International data transfer</Item>
              <Item>Children</Item>
              <Item>Changes to this Privacy Policy</Item>
              <Item>How to contact us</Item>
            </Items>
          </SectionContent>
        </Section>
        <br />
        <br />
        <Section>
          <SectionTitle>Personal data we collect </SectionTitle>
          <br />
          <SectionContent>
            Information you provide to us. Personal data is data that can be
            used to contact or identify you, such as your name, email address,
            phone number, etc. Personal data you may provide to us through our
            Services, or otherwise, includes:
            <br />
            <br />
            <Items>
              <Item>
                <ItemTextBold>Contact data,</ItemTextBold> such as your first
                and last name, alias, email address, billing and mailing
                addresses, and phone number.{" "}
              </Item>
              <Item>
                <ItemTextBold>Profile data,</ItemTextBold> such as the username
                and password that you may set to establish an online account
                with us, photograph, biographical information, links to your
                profiles on social networks, non-fungible token (“NFT”)
                holdings, digital wallet addresses, and any other information
                that you add to or is associated with your account profile.{" "}
              </Item>
              <Item>
                <ItemTextBold>Communications data,</ItemTextBold> such as data
                that we exchange with you, including when you contact us with
                questions or feedback, through our Services, social media, or
                otherwise.
              </Item>
              <Item>
                <ItemTextBold>Payment and transactional data,</ItemTextBold>{" "}
                such as data relating to, or needed to complete, your purchases
                on or through our Services (including payment card information
                and billing information) or to provide you with the funds from a
                sale on the platform, digital wallet information, and your
                purchase history.
              </Item>
              <Item>
                <ItemTextBold>Marketing data,</ItemTextBold> such as your
                preferences for receiving our marketing communications and
                details about your engagement with them. User-generated content,
                such as photos, images, comments, questions, messages, and other
                content or information that you generate, transmit, or otherwise
                make available on our Services, as well as associated metadata.{" "}
              </Item>
              <Item>
                <ItemTextBold>
                  Government-issued identification numbers,
                </ItemTextBold>{" "}
                such as national identification number (e.g. aadhaar card, tax
                identification number, gst number, passport number), state or
                local identification number (e.g., aadhaar card, driver’s
                license or state ID number), and an image of the relevant
                identification card or any other data required for ‘Know Your
                Customer’ checks.{" "}
              </Item>
              <Item>
                <ItemTextBold>
                  Other data not specifically listed here,
                </ItemTextBold>{" "}
                which we will use as described in this Privacy Policy or as
                otherwise disclosed at the time of collection.{" "}
              </Item>
            </Items>
            <br />
            <ItemTextBold>Third-party sources.</ItemTextBold> We may combine
            personal data we receive from third party vendors and/or other
            commercially available sources with the personal data we collected.
            The third-party sources from which we may receive personal data are:{" "}
            <br />
            <Items>
              <Item>
                <ItemTextBold>Public sources,</ItemTextBold> such as social
                media platforms and other publicly available sources.
              </Item>
              <Item>
                <ItemTextBold>Data providers,</ItemTextBold> such as information
                services and data licensors that provide demographic and other
                information.{" "}
              </Item>
              <Item>
                <ItemTextBold>Third party vendors,</ItemTextBold> such as
                service providers providing talent management services, fraud
                warnings, KYC or enrichment service providers to enhance and
                personalize your user experience.
              </Item>
              <Item>
                <ItemTextBold>Marketing partners,</ItemTextBold> such as joint
                marketing partners and event co-sponsors.
              </Item>
            </Items>
            <br />
            If you choose to activate, access, or connect your account on or to
            a third-party platform or network, such as Facebook/Google/Twitter
            we may collect data from that platform or network, the data we
            collect may include your profile name, avatar, etc.
            <br />
            <br />
            <ItemTextBold>Automatic data collection,</ItemTextBold>
            We may automatically collect data about you, your computer, or
            mobile device while you access, browse, view, or otherwise interact
            with our Services. This data may include device information, such as
            IP addresses, geolocation, and other unique identifiers. This device
            data is stored securely in our servers and databases, utilizing
            encryption and access controls to protect your information. We
            retain this data to enhance your experience, optimize our services,
            and for analytical purposes, ensuring that it is handled in
            accordance with our privacy policies. The data we collect may
            include:
            <br />
            <Items>
              <Item>
                <ItemTextBold>Device data,</ItemTextBold>
                such as your computer’s or mobile device’s operating system type
                and version, manufacturer and model, browser type, screen
                resolution, RAM and disk size, CPU usage, device type (e.g.,
                phone, tablet), IP address, unique identifiers (including
                identifiers used for advertising purposes), language settings,
                mobile device carrier, radio/network information (e.g., Wi-Fi,
                LTE, 3G), and general location information such as city, state
                or geographic area.
              </Item>
              <Item>
                <ItemTextBold>
                  Online activity data on our Website ,
                </ItemTextBold>
                such as pages or screens you viewed, how long you spent on a
                page or screen, navigation paths between pages or screens,
                information about your activity on a page or screen, access
                times and duration of access, and whether you have opened our
                marketing emails or clicked on URLs that led you to our site.
              </Item>
            </Items>
            <br />
            <ItemTextBold>Cookies and similar technologies.</ItemTextBold>
            We use cookies and similar technologies (such as web beacons,
            pixels, tags and scripts) to improve and personalize your
            experience, provide our Services, analyze website performance and
            for marketing purposes. To learn more about how we use cookies and
            your control over these cookies, please see our Cookie Policy.
            <br />
            <br />
            <ItemTextBold>Data about others.</ItemTextBold>
            Users of our Services may have the opportunity to refer friends or
            other contacts to us and share their contact data with us. Please do
            not refer someone to us, or share their contact data with us, unless
            you have their permission to do so.
            <br />
            <br />
            </SectionContent>
            </Section>

            <Section>
            <SectionContent>
            <SectionTitle>How we use your personal data.</SectionTitle>
            <br />
            We may use your personal data for the following purposes or as
            otherwise described at the time of collection:
            <br />
            <br />
            <ItemTextBold>Service delivery.</ItemTextBold>
            We may use your personal data to:
            <Items>
              <Item>
                develop, provide, operate and improve our Services and our
                business;
              </Item>
              <Item>
                establish, maintain and service your user profile for our
                Services;
              </Item>
              <Item>
                verify or maintain the quality or safety of the Services;
              </Item>
              <Item>
                conduct Know Your Customer checks and other screens permitted by
                law when you set up an account;
              </Item>
              <Item>
                verify your identity when you place a spent/purchase an app
                currency;
              </Item>
              <Item>
                process your payments and complete transactions with you when
                you spent/purchase an app currency;
              </Item>
              <Item>
                connect to your virtual wallet when you wish to add or withdraw
                funds from your virtual wallet;
              </Item>
              <Item>
                facilitate invitations to friends who you want to invite to join
                our Services;
              </Item>
              <Item>
                enable security features of our Services, such as (for example)
                by sending you security codes via email or SMS, and remembering
                devices from which you have previously logged in;
              </Item>
              <Item>
                communicate with you about our Services, including by sending
                announcements, updates, security alerts, and support and
                administrative messages;
              </Item>
              <Item>enable communication with other Service users; and</Item>
              <Item>
                provide support for our Services, and contact you where
                requested or respond to your requests, questions and feedback.
              </Item>
            </Items>
            <br />
            <ItemTextBold>Research and development.</ItemTextBold>
            We may use your personal data for research and development purposes,
            including to analyze and improve our Services and our business. As
            part of these activities, we may create aggregated, de-identified,
            or anonymous data from the personal data we collect. We make
            personal data into anonymous data by removing information that makes
            it personally identifiable to you. We may use this anonymous data
            and share it with third parties for our lawful business purposes,
            including to analyze and improve our Services and promote our
            business. Clout ensures that all contracts with third-party service
            providers comply with applicable data protection laws, such as
            India's Information Technology Act, 2000, and the Personal Data
            Protection Bill (if applicable). We also ensure that our partners
            adhere to strict data protection and security standards, regularly
            reviewing these contracts to maintain compliance with Indian data
            protection regulations.
            <br />
            <br />
            <Items>
              <ItemTextBold>Marketing and advertising.</ItemTextBold>
              <br />
              We may collect and use your personal data for marketing and
              advertising purposes:
              <Item>
                Direct marketing. We may send you direct marketing
                communications about our products, services or promotions that
                may be of interest to you. Our processing of your personal data
                for marketing purposes is based on our legitimate interest, or
                on your consent, or as otherwise permitted by applicable laws.
                You have the right to prevent direct marketing of any form at
                any time, this right can be exercised by following the opt-out
                link attached to each communication or by sending an email to
                help@clout.com.
              </Item>
              <Item>
                Interest-based advertising. We may engage third-party
                advertising companies and social media companies to display ads
                on other online services. These companies may use cookies
                (Cookies are small text files placed on your device when you
                visit our website. They help us collect various types of data,
                including but not limited to your IP address, browsing habits,
                pages visited, time spent on our site, and preferences regarding
                language and other settings. Users can adjust their cookie
                preferences directly through our website by visiting the Cookie
                Settings page, where you can manage which types of cookies
                (essential, performance, or marketing) you wish to allow or
                block. Additionally, you can disable cookies through your
                browser settings; however, please note that some features of the
                website may not function properly if cookies are disabled.) and
                similar technologies to collect information about your
                interaction (including the data described in the automatic data
                collection section above) over time across our Services, our
                communications and other online services, and use that
                information to serve online ads that they think will interest
                you. This is called interest-based advertising. We may also
                share information about our users with these companies to
                facilitate interest-based advertising to those or similar users
                on other online platforms. You can learn more about your choices
                for limiting interest-based advertising in the Advertising
                choices section below.
              </Item>
            </Items>
            <br />
            <Items>
              <ItemTextBold>Compliance and protection.</ItemTextBold>
              We may use your personal data to: <br />
              <Item>
                comply with applicable laws, lawful requests, and legal process,
                such as to respond to subpoenas or requests from government
                authorities;
              </Item>
              <Item>
                protect our, your or others’ rights, privacy, safety or property
                (including by making and defending legal claims);
              </Item>
              <Item>
                audit our internal processes for compliance with legal and
                contractual requirements or our internal policies;
              </Item>
              <Item>maintain appropriate business records;</Item>
              <Item>
                enforce the terms and conditions that govern our Services;
              </Item>
              <Item>
                and prevent, identify, investigate and deter fraudulent,
                harmful, unauthorized, unethical or illegal activity, including
                cyberattacks and identity theft.
              </Item>
            </Items>
            <br />
            <ItemTextBold>With your consent.</ItemTextBold>
            In some cases, we may specifically ask for your consent to collect,
            use or share your personal data, such as when required by law.
            <br />
            <br />
            <ItemTextBold>
              To create aggregated, de-identified and/or anonymized data.
            </ItemTextBold>
            We may create aggregated, de-identified and/or anonymized data from
            your personal data and other individuals whose personal data we
            collect. We make personal data into de-identified and/or anonymized
            data by removing information that makes the data identifiable to
            you. We may use this aggregated, de-identified and/or anonymized
            data and share it with third parties for our lawful business
            purposes, including to analyze and improve our Services, promote our
            business or marketing purposes.
            <br />
            <br />
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>How we share your personal data </SectionTitle>
          <br />
          <SectionContent>
            We do not sell or rent your personal data to third parties for their
            marketing purposes without your explicit consent.
            <br />
            <br />
            We may share your personal data with third parties as detailed below
            and as otherwise described in this Privacy Policy.
            <Items>
              <Item>
                <ItemTextBold>Affiliates.</ItemTextBold> We may share your
                personal data with our corporate parent, subsidiaries, and
                affiliates, for purposes consistent with this Privacy Policy.
              </Item>
              <Item>
                <ItemTextBold>Service providers</ItemTextBold>. We share
                personal data with those third parties who provide services on
                our behalf or help us operate our Services or our business (such
                as hosting, information technology, customer support, email
                delivery, marketing, and website analytics).
              </Item>
              <Item>
                <ItemTextBold>
                  Payment processors and fraud detection service providers.
                </ItemTextBold>{" "}
                We share personal data with payment processors and fraud
                detection service providers for the purposes of facilitating
                payments done via the Services and securing the Services and
                protecting it against fraud, unauthorized transactions (such as
                money laundering), claims or other liabilities.
              </Item>
              <Item>
                <ItemTextBold>Advertising partners.</ItemTextBold> We may share
                your personal data with third-party advertising companies for
                the interest-based advertising purposes described above. Data
                obtained through the Clout Messaging short code program will not
                be shared with any third-parties for their marketing
                reasons/purposes.
              </Item>
              <Item>
                <ItemTextBold>Creators. As a Creator,</ItemTextBold> we may
                publicly identify you as a Creator with Clout, including in
                connection with our marketing and promotional materials. In
                addition, we may publicly share information related to your use
                and activity of the Services (including earnings, audience and
                viewership metrics, and other similar analytics); if we first
                aggregate that information with information from other Creators.
              </Item>
              <Item>
                <ItemTextBold>
                  Third-party platforms and social media networks.
                </ItemTextBold>{" "}
                Our Services may contain certain social media features, such as
                sharing content from Clout to external social media networks. If
                you have enabled features or functionality that connect our
                Services to a third-party platform or social media network (such
                as by linking your account to a third party’s services), we may
                disclose the personal data that you authorized us to share. We
                do not control the third party’s use of your personal data and
                such third party’s use will be in accordance with such party’s
                privacy policy. Professional advisors.
              </Item>
              <Item>
                <ItemTextBold>Professional advisors,</ItemTextBold> such as
                lawyers, auditors, bankers and insurers, where necessary in the
                course of the professional services that they render to us.
              </Item>
              <Item>
                <ItemTextBold>Government Agencies and regulators.</ItemTextBold>{" "}
                We may share personal data with law enforcement agencies, public
                authorities or other parties: (i) in order to respond to a
                subpoena or court order, judicial process or to regulatory
                authorities, if we believe we are required to do so by law, or
                that doing so is reasonably necessary to comply with legal
                processes; (ii) when we believe it necessary or appropriate to
                disclose personal data to law enforcement authorities, such as
                to investigate actual or suspected fraud or violations of law,
                breaches of security, or breaches of this Privacy Policy; (iii)
                to respond to claims against us; and (iv) to protect the rights,
                property, or personal safety of Clout, our customers or the
                public.
              </Item>
              <Item>
                <ItemTextBold>
                  In the context of a corporate transaction.
                </ItemTextBold>{" "}
                Personal data may be disclosed if we go through a business
                transition such as a merger, sale, transfer of all or a portion
                of Clout’ assets, acquisition, bankruptcy or similar event. In
                the event that we sell any business or assets, we will disclose
                your data to the prospective buyer. If all, or substantially
                all, of our assets are acquired by a third party, data held by
                us about our users will be one of the transferred assets.
              </Item>
              <Item>
                <ItemTextBold>Other users.</ItemTextBold> Your personal data,
                such as your profile information and user-generated content and
                your email address may be visible to other users of our
                Services. For example, other users may have access to your
                profile or content through the course of your use of our
                Services, such as when you spent/purchase an app currency. In
                addition, as a Fan, your email and other personal data that you
                have provided to Clout may be shared with each Creator to whom
                you subscribe. Where your personal data can be seen, collected
                and used by others, including being cached, copied, screen
                captured or stored elsewhere by others (e.g., search engines),
                we are not responsible for any such use of data.
              </Item>
            </Items>
          </SectionContent>
          <br />
          <br />
        </Section>

        <Section>
          <SectionTitle>Your Choices</SectionTitle>
          <br />
          <SectionContent>
            You have the following choices with respect to your personal data.
            <br />
            <br />
            <ItemTextBold>Access or update your information.</ItemTextBold> If you have registered for an
            account with us through our Services, you may review and update
            certain account information by logging into the account.
            <br />
            <br />
            <ItemTextBold>Delete your user data.</ItemTextBold> If you wish to delete your user data, you can
            delete your account by visiting your Account Settings.
            <br />
            <br />
            <ItemTextBold>Opt-out of marketing communications.</ItemTextBold> You may opt-out of
            marketing-related emails by following the opt-out or unsubscribe
            instructions at the bottom of the email, or by contacting us through
            the contact details set out in the ‘How to contact us’ section
            below. Please note that if you choose to opt-out of
            marketing-related emails, you may continue to receive
            service-related and other non-marketing emails.
            <br />
            <br />
            <ItemTextBold>Advertising choices.</ItemTextBold> You can limit use of your information for
            interest-based advertising by:
            <Items>
              <Item>
              <ItemTextBold>Browser settings.</ItemTextBold> Blocking third-party cookies in your browser
                settings.
              </Item>
              <Item>
              <ItemTextBold>Privacy browsers/plug-ins.</ItemTextBold> By using privacy browsers or
                ad-blocking browser plug-ins that let you block tracking
                technologies.
              </Item>
              <Item>
              <ItemTextBold>Platform settings.</ItemTextBold> Google and Facebook offer opt-out features
                that let you opt-out of use of your information for
                interest-based advertising:
                <br />
                Google: https://adssettings.google.com/
                <br />
                Facebook: https://www.facebook.com/about/ads{" "}
              </Item>

              <Item>
              <ItemTextBold>Ad industry tools.</ItemTextBold> Opting out of interest-based ads from
                companies participating in the following industry opt-out
                programs:
                <br />
                Network Advertising Initiative:
                http://www.networkadvertising.org/managing/opt_out.asp
                <br />
                Digital Advertising Alliance: optout.aboutads.info.
                <br />
                AppChoices mobile app, available at
                https://www.youradchoices.com/appchoices, which will allow you
                to opt-out of interest-based ads in mobile apps served by
                participating members of the Digital Advertising Alliance.
                <br />
              </Item>
            </Items>
            <br />
            You will need to apply these opt-out settings on each device from
            which you wish to opt-out.
            <br />
            <br />
            <ItemTextBold>Do Not Track.</ItemTextBold> Some Internet browsers may be configured to send “Do
            Not Track” signals to the online services that you visit, allowing
            users to opt out of tracking by websites and only services. We
            currently do not respond to “Do Not Track” or similar signals. To
            find out more about “Do Not Track,” please visit
            http://www.allaboutdnt.com, or consult our Cookie Policy to
            understand how you can opt out of receiving cookies.
            <br />
            <br />
            <ItemTextBold>Declining to provide information.</ItemTextBold> We need to collect personal data
            to provide certain services. If you do not provide that data we
            identify as required or mandatory, we may not be able to provide
            those services.
            <br />
            <br />
            <ItemTextBold>Third-party platforms.</ItemTextBold> If you choose to connect to our Services
            through your social media account or other third-party platform, you
            may be able to use your settings in your account with that platform
            to limit the information we receive from it. If you revoke our
            ability to access information from a third-party platform, that
            choice will not apply to information that we have already received
            from that third party.
          </SectionContent>
          <br />
          <br />
        </Section>

        <Section>
          <SectionTitle>Other sites and services</SectionTitle>
          <br />
          <SectionContent>
            Our Services may contain links to third party websites, mobile
            applications, and other online services operated by third parties.
            In addition, our content may be integrated into websites or other
            online services that are not associated with Clout. These links and
            integrations are not an endorsement of, or representation that we
            are affiliated with, any third party. We do not control websites,
            mobile applications or online services operated by third parties,
            and we are not responsible for their actions, nor are they subject
            to this Privacy Policy. We encourage you to read the privacy
            policies of each of the other third-party websites, mobile
            applications and online services you visit. Our Services use and
            transfer of information received from Google APIs to any other app
            will adhere to Google API Services User Data Policy, including the
            Limited Use requirements.
          </SectionContent>
          <br />
          <br />
        </Section>

        <Section>
          <SectionTitle>How long we keep your personal data</SectionTitle>
          <br />
          <SectionContent>
            We apply a general rule of keeping personal data only for as long as
            is required to fulfill the purpose for which it was collected.
            However, in some circumstances, we will retain your personal data
            for longer periods. Different categories of data are retained for
            varying durations based on legal requirements in India. For
            instance, financial data may be retained for up to eight years to
            comply with Indian tax regulations, while browsing history and
            non-essential data may only be kept for a shorter period of up to 12
            months. We regularly review our data retention policies to ensure
            that data is not held longer than necessary and that it aligns with
            applicable laws and regulations. We will retain personal data for
            the following purposes: (i) as long as it is necessary and relevant
            for our operations and to provide our Services, e.g. so that we have
            an accurate record of your dealings with us in the event of any
            complaints or challenge; and (ii) in relation to personal data from
            closed accounts to comply with applicable laws, prevent fraud,
            collect any fees owed, resolve disputes, troubleshoot problems,
            assist with any investigation, enforce our Service terms and take
            other actions as permitted by law.
          </SectionContent>
          <br />
          <br />
        </Section>

        <Section>
          <SectionTitle>Security</SectionTitle>
          <br />
          <SectionContent>
            We implement a number of technical and organizational safeguards
            designed to maintain the security of our Services and to prevent
            unauthorized access, loss, misuse, alteration, destruction, or
            damage through industry-standard technologies and internal
            procedures.
            <br />
            However, security risks are inherent in all internet and information
            technologies, and the transmission of personal data via the internet
            is not completely secure. Although we will do our best to protect
            your personal data, we cannot guarantee the security of any personal
            data transmitted through the Service. Users who have registered for
            our Services agree to keep their password in strict confidence and
            not to disclose it to any third party. Users are also responsible
            for maintaining the confidentiality of their passwords and the
            security of their accounts. We recommend choosing strong passwords
            and updating them periodically. If you suspect any unauthorized
            access to your account, please notify us immediately to ensure
            prompt action.
          </SectionContent>
          <br />
        </Section>

        <Section>
          <SectionTitle>
            Cross-border processing of your personal data{" "}
          </SectionTitle>
          <br />
          <SectionContent>
            We are headquartered in the United States. To provide and operate
            our services, it is necessary for us to process your personal
            information in the United States. If we transfer personal
            information across borders such that we are required to apply
            appropriate safeguards to personal information under applicable data
            protection laws, we will do so. Please contact us for further
            information about any such transfers or the specific safeguards
            applied.
          </SectionContent>
          <br />
          <br />
        </Section>
        <Section>
          <SectionTitle>Children</SectionTitle>
          <br />
          <SectionContent>
            Our Services are not intended for children, and we do not collect
            personal information from them. We define “children” as anyone under
            18 years old. If we learn we have collected or received personal
            information from a child without verification of parental consent,
            we will delete the information. If you believe we might have any
            information from or about a child, please contact us via the
            contract information noted below.
            <br />
            Notwithstanding the foregoing, if you are creating an account as a
            Creator and you are between the ages of 15 and 17, you may use the
            Services with Clout prior approval and with a parent or guardian’s
            verified written consent.
          </SectionContent>
          <br />
          <br />
        </Section>
        <Section>
          <SectionTitle>Changes to this Privacy Policy</SectionTitle>
          <br />
          <SectionContent>
            We reserve the right to modify this Privacy Policy at any time. If
            we make material changes to this Privacy Policy, we will notify you
            by updating the date of this Privacy Policy and posting it on our
            Website. If required by law we will also provide notification of
            changes in another way that we believe is reasonably likely to reach
            you, such as via email. Any modifications to this Privacy Policy
            will be effective upon our posting the modified version (or as
            otherwise indicated at the time of posting). We recommend that you
            check this Privacy Policy periodically for any updates or changes.
          </SectionContent>
          <br />
          <br />
        </Section>
        <Section>
          <SectionTitle>How to contact us</SectionTitle>
          <br />
          <SectionContent>
            To exercise any of your rights in connection with your personal data
            or to share any questions (or comments) concerning this policy,
            please contact us at support@clout.ai or 566/11, Row House No 11,
            Ganatra Complex, Market Yard, Gultekdi Pune 411037.
          </SectionContent>
        </Section>
      </Content>
    </PolicyContainer>
  );
};

export default PrivacyPolicyPage;
