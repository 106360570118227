import { Box, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ImageStockIcon,
  VideoIconForPostGrid,
} from "../../../../assets/SvgIcons";
import { Link, useLocation } from "react-router-dom";
import { UserFeedType } from "../../../../types";

const Container = styled(Link)`
  height: 123.162px;
  flex-shrink: 0;
  position: relative;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
`;

const Image = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const IconContainer = styled(Box)`
  display: flex;
  width: 15.898px;
  height: 16px;
  padding: 2.667px 2.65px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  right: 0;
`;

const Placeholder = styled(Box)`
  width: 100%;
  height: 100%;
  background: lightgray;
`;

const contentStatus = {
  LOCKED: "Locked",
  PURCHASED: "Purchased",
  FREE: "Free",
};

interface ContentGridViewProps {
  item: UserFeedType;
  activeTab: string;
}
const ContentGridView: React.FC<ContentGridViewProps> = ({
  item,
  activeTab,
}) => {
  const [postItem, setPostItem] = useState<UserFeedType>();
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";

  useEffect(() => {
    if (item) {
      setPostItem(item);
    }
  }, [item]);

  return (
    <Container
      to={`/${userRole}/creator-post-page/${
        activeTab === contentStatus.LOCKED
          ? 1
          : activeTab === contentStatus.PURCHASED
          ? 2
          : 3
      }`}
      key={item.id}
    >
      {postItem ? (
        <Image src={postItem?.coverPhotoUrl} alt="imagePost" loading="lazy" />
      ) : (
        <Placeholder />
      )}
      <IconContainer>
        {postItem?.contentType === "VIDEO" ? (
          <VideoIconForPostGrid />
        ) : (
          <ImageStockIcon />
        )}
      </IconContainer>
    </Container>
  );
};

export default React.memo(ContentGridView);
