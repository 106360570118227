import { Avatar, Box, Button, Typography, styled } from "@mui/material";
import {
  AudioCallIcon,
  TextMessageIcon,
  VideoCallCameraIcon,
} from "../../../assets/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { typography, colors } from "../../../utils/styles";
import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  resetBooking,
  setSubscriptionDetails,
} from "../../../redux/slotBookingSlice";
import {
  setAnotherCreatorProfile,
  setAnothetCreatorsSubscriptionData,
} from "../../../redux/creatorServiceSlice";
import usersCreatorProfileServiceApi from "../../../api/user/creatorServiceApi";
import creatorsCreatorProfileServiceApi from "../../../api/creator/creatorProfileServiceApi";
import userSlotBookingService from "../../../api/booking/user/userSlotBookingService";
import creatorSlotBookingService from "../../../api/booking/creator/creatorSlotBookingService";
const Container = styled(Box)`
  display: flex;
  width: 340px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid var(--Border-Primary, #7262c2);
  background: var(--Background-default, #fff);
`;
const Header = styled(Box)`
  display: flex;
  width: 100%;
  height: 77px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px 16px 0px 0px;
  border-bottom: 1px solid var(--Border-Primary, #7262c2);
  background: var(--Background-brand-primary-Subtle, #dcd7f5);
`;
const HeaderContent = styled(Box)`
  display: flex;
  padding: 8px 0px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
const HeaderUserName = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Primary, #212121);
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.21px;
`;

const HeaderHandleName = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Secodary, #616161);
  text-overflow: ellipsis;
  /* Body/M-R */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.18px;
`;
const SubscriptionOfferingsContainer = styled(Box)`
  padding: 0px 16px 16px 16px;
`;
const SubscriptionOfferingsContent = styled(Box)`
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 16px 0;
  border-bottom: 1px solid var(--Border-Primary, #7262c2);
  gap: 8px;
`;
const SubscriptionOfferingPricingText = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Primary, #212121);
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  letter-spacing: 0.18px;
`;
const SubscriptionOfferingsText = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Primary, #212121);
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.15px;
`;
const SubscriptionPlanOfferingsContent = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
const PlanText = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Primary, #212121);
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.15px;
`;
const SubscriptionOtherDetailsConatainer = styled(Box)`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const OthetDeatilsTitle = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Primary, #212121);
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  letter-spacing: 0.18px;
`;
const OtherDetailsIems = styled("ul")`
  list-style-type: disc;
  padding-left: 28px;
  margin: 0;
`;
const OtherDetailItem = styled("li")`
  padding-left: 1.5em;
`;
const OtherDetailItemText = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Primary, #212121);
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.15px;
`;
const BuySubscriptionButton = styled(Button)`
  display: flex;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Background-brand-primary, #7262c2);
  background: var(--Background-brand-primary, #7262c2);
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  letter-spacing: 0.18px;
  width: 100%;
`;

const SubscriptionViewCardContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const SubscriptionViewCardContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ViewCardContentLeftContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const ViewCardContentLeftIcon = styled(Box)``;

const ViewCardContentLeftTextContainer = styled(Box)``;

const ViewCardContentLeftSubTitleText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const SubscriptionPlan = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const { profileData, subscriptions } = useSelector(
    (state: RootState) => state.anotherCreator
  );
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const [consumptionData, setConsumptionData] = useState<any>(null);

  const displayItems = [
    {
      title: "Direct Message",
      icon: <TextMessageIcon />,
      minutes: subscriptions?.directMessageMinutes,
      label: subscriptions?.directMessageMinutes
        ? `${subscriptions.directMessageMinutes} min Direct message`
        : "Direct message",
    },
    {
      title: "Audio Call",
      icon: <AudioCallIcon />,
      minutes: subscriptions?.audioCallMinutes,
      label: subscriptions?.audioCallMinutes
        ? `${subscriptions.audioCallMinutes} min Audio call`
        : "Audio call",
    },
    {
      title: "Video Call",
      icon: <VideoCallCameraIcon />,
      minutes: subscriptions?.videoCallMinutes,
      label: subscriptions?.videoCallMinutes
        ? `${subscriptions.videoCallMinutes} min Video call`
        : "Video call",
    },
  ].sort((a, b) => (b.minutes ?? 0) - (a.minutes ?? 0)); // Sort by minutes, descending

  const fetchCeatorsProfileData = async (id: string) => {
    try {
      if (id) {
        if (userRole === "user") {
          const response =
            await usersCreatorProfileServiceApi.getAnotherCreatorsProfile(id);
          if (response.status === 200) {
            dispatch(setAnotherCreatorProfile(response.data.data.creator));
          }
        } else if (userRole === "creator") {
          const response =
            await creatorsCreatorProfileServiceApi.getAnotherCreatorsProfile(
              id
            );
          if (response.status === 200) {
            dispatch(setAnotherCreatorProfile(response.data.data.creator));
          }
        }
      }
    } catch (error) {
      console.error("Error fetching creators profile data", error);
    }
  };

  const fetchCreatorsSubscription = async (id: string) => {
    try {
      if (id) {
        if (userRole === "user") {
          const response =
            await usersCreatorProfileServiceApi.getCreatorsSubscription(id);
          if (response.status === 200) {
            dispatch(
              setAnothetCreatorsSubscriptionData(
                response.data.data.subscription
              )
            );
          }
        } else if (userRole === "creator") {
          const response =
            await creatorsCreatorProfileServiceApi.getCreatorsSubscription(id);
          if (response.status === 200) {
            dispatch(
              setAnothetCreatorsSubscriptionData(
                response.data.data.subscription
              )
            );
          }
        }
      }
    } catch (error) {
      console.error("Error fetching creators subscription", error);
    }
  };
  useEffect(() => {
    const getConsumptionData = async () => {
      try {
        const { data } =
          userRole === "user"
            ? await userSlotBookingService.getSubscriptionConsumption(
                subscriptions.id,
                subscriptions.version!
              )
            : await creatorSlotBookingService.getSubscriptionConsumption(
                subscriptions.id,
                subscriptions.version!
              );
        if (data) {
          setConsumptionData(data);
          dispatch(
            setSubscriptionDetails({
              userSubscriptionId: data.userSubscriptionId, // Ensure it's data from the response
              expiryDate: data.expiryDate,
            })
          );
        }
      } catch (error) {
        console.error("Failed to fetch subscription consumption data:", error);
      }
    };

    if (subscriptions?.id && subscriptions?.version) {
      getConsumptionData();
    }
  }, [subscriptions.id, subscriptions.version]);

  useEffect(() => {
    if (id && !subscriptions?.id) {
      fetchCeatorsProfileData(id);
      fetchCreatorsSubscription(id);
    }
  }, [id, subscriptions]);
  return (
    <Container>
      <Header>
        <HeaderContent>
          <Box sx={{ position: "relative" }}>
            <Avatar
              sx={{ width: "40px", height: "40px" }}
              alt="user"
              src={profileData?.profilePhotoUrl}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <HeaderUserName>{profileData?.displayName}</HeaderUserName>
            <HeaderHandleName>@{profileData?.handleName}</HeaderHandleName>
          </Box>
        </HeaderContent>
      </Header>

      <SubscriptionOfferingsContainer>
        <SubscriptionOfferingsContent>
          <SubscriptionOfferingPricingText>
            CC {subscriptions?.amountInYC}/ Month
          </SubscriptionOfferingPricingText>
          <SubscriptionOfferingsText>
            Subscription Offerings -
          </SubscriptionOfferingsText>

          {/* <SubscriptionPlanOfferingsContent>
            <TickMarkIcon />
            <PlanText>{subscriptions?.directMessageMinutes} min direct Messages</PlanText>
          </SubscriptionPlanOfferingsContent>

          <SubscriptionPlanOfferingsContent>
            <TickMarkIcon />
            <PlanText>{subscriptions?.videoCallMinutes} min video call</PlanText>
          </SubscriptionPlanOfferingsContent>

          <SubscriptionPlanOfferingsContent>
            <CancelRedIcon />
            <PlanText
              sx={{
                textDecoration: "line-through"
              }}
            >
              Audio Call
            </PlanText>
          </SubscriptionPlanOfferingsContent>*/}

          <SubscriptionViewCardContentContainer>
            {displayItems.map((item, index) => (
              <SubscriptionViewCardContent key={index}>
                <ViewCardContentLeftContainer>
                  <ViewCardContentLeftIcon>{item.icon}</ViewCardContentLeftIcon>
                  <ViewCardContentLeftTextContainer>
                    <ViewCardContentLeftSubTitleText
                      sx={{
                        textDecoration: item.minutes ? "none" : "line-through",
                      }}
                    >
                      {item.label}
                    </ViewCardContentLeftSubTitleText>
                  </ViewCardContentLeftTextContainer>
                </ViewCardContentLeftContainer>
              </SubscriptionViewCardContent>
            ))}
          </SubscriptionViewCardContentContainer>
        </SubscriptionOfferingsContent>

        <SubscriptionOtherDetailsConatainer>
          <OthetDeatilsTitle>Others Details:</OthetDeatilsTitle>

          <OtherDetailsIems>
            <OtherDetailItem>
              <OtherDetailItemText>
                10% default discount for subscribed users who want to buy the
                post.
              </OtherDetailItemText>
            </OtherDetailItem>

            <OtherDetailItem>
              <OtherDetailItemText>
                Free post will be provided to whoever buys this monthly
                subscription.
              </OtherDetailItemText>
            </OtherDetailItem>

            <OtherDetailItem>
              <OtherDetailItemText>
                1 month will start from the day user has bought this plan.
              </OtherDetailItemText>
            </OtherDetailItem>
          </OtherDetailsIems>
        </SubscriptionOtherDetailsConatainer>

        <BuySubscriptionButton>
          <Link
            to={`/${userRole}/book-slot?serviceType=subscription&id=${profileData?.userId}&creatorName=${profileData?.displayName}&isAudioCall=${subscriptions?.audioCall}&isVideoCall=${subscriptions?.videoCall}&isDirectMessage=${subscriptions?.directMessage}&audioCallMinutes=${consumptionData?.audioCallMinutesLeft}&videoCallMinutes=${consumptionData?.videoCallMinutesLeft}&directMessageMinutes=${consumptionData?.directMessageMinutesLeft}`}
            style={{ textDecoration: "none", width: "100%", color: "white" }}
            onClick={() => {
              dispatch(resetBooking());
            }}
          >
            Book Your Slot
          </Link>
        </BuySubscriptionButton>
      </SubscriptionOfferingsContainer>
    </Container>
  );
};

export default SubscriptionPlan;
