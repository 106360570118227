import styled from "@emotion/styled";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { PageTagComponent } from "../../components/Common/PageTagComponent";
import CashfreeService from "../../services/CashfreeService";
import { colors, typography } from "../../utils/styles";
import { STATUS, Status } from "../../constants/statusConstants";
import creatorPaymentServiceApi from "../../api/creator/paymentServiceApi";
import creatorSubscriptionServiceApi from "../../api/creator/subscriptionServiceApi";
import userSubscriptionServiceApi from "../../api/user/subscriptionServiceApi";
import userPaymentServiceApi from "../../api/user/paymentServiceApi";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";

const markStyle = {
  width: "12px",
  height: "12px",
};

const BorderedContainer = styled(Box)`
  border: 1px solid rgba(114, 98, 194, 0.43);
  border-radius: 8px;
  padding-inline: 8px;
`;

const SubscriptionOfferings = ({
  audioCall,
  audioCallMinutes,
  videoCall,
  videoCallMinutes,
  directMessage,
  directMessageMinutes,
}: {
  audioCall: boolean;
  audioCallMinutes: number;
  videoCall: boolean;
  videoCallMinutes: number;
  directMessage: boolean;
  directMessageMinutes: number;
}) => {
  return (
    <BorderedContainer>
      <Box py="12px">
        <Typography fontWeight={700} fontSize="12px">
          Text Messages
        </Typography>
        <Stack direction="row" alignItems="center">
          {directMessage ? (
            <DoneIcon sx={markStyle} />
          ) : (
            <ClearIcon sx={markStyle} />
          )}
          <Typography fontSize="10px">
            {directMessage
              ? `${directMessageMinutes} Text Messages Character`
              : ""}
          </Typography>
        </Stack>
      </Box>
      <hr />
      <Box py="12px">
        <Typography fontWeight={700} fontSize="12px">
          Audio Call
        </Typography>
        <Stack direction="row" alignItems="center">
          {directMessage ? (
            <DoneIcon sx={markStyle} />
          ) : (
            <ClearIcon sx={markStyle} />
          )}
          <Typography fontSize="10px">
            {audioCall ? `${audioCallMinutes} min Audio Call` : ""}
          </Typography>
        </Stack>
      </Box>
      <hr />
      <Box py="12px">
        <Typography fontWeight={700} fontSize="12px">
          Video Call
        </Typography>
        <Stack direction="row" alignItems="center">
          {videoCall ? (
            <DoneIcon sx={markStyle} />
          ) : (
            <ClearIcon sx={markStyle} />
          )}
          <Typography fontSize="10px">
            {videoCall ? `${videoCallMinutes} Minute Video Call` : ""}
          </Typography>
        </Stack>
      </Box>
    </BorderedContainer>
  );
};

const SunscriptionExpiration = () => {
  return (
    <BorderedContainer>
      <Stack direction="row" justifyContent="space-between" py="12px">
        <Box>
          <Typography fontWeight={700} fontSize="12px">
            Subscription Expiry Date
          </Typography>
          <Typography fontSize="10px">
            Period - 15th Jul to 14th Aug 2024
          </Typography>
        </Box>
        <Typography fontSize="10px">14th Aug 2023</Typography>
      </Stack>
    </BorderedContainer>
  );
};

const DiscountAndOtherDetails = () => {
  return (
    <BorderedContainer>
      <Box py="12px">
        <Typography mb="6px" fontWeight={700} fontSize="12px">
          Discount and Other Details
        </Typography>
        <Typography my="6px" gutterBottom fontSize="10px">
          10% default discount for subscribed users who wants to buy the post.
        </Typography>
        <hr />
        <Typography my="6px" gutterBottom fontSize="10px">
          Free post will be provided to whoever buys this monthly subscription.
        </Typography>
        <hr />
        <Typography mt="6px" fontSize="10px">
          1 month will start from the day user has bought this plan.
        </Typography>
      </Box>
    </BorderedContainer>
  );
};

const SubscriptionCancellationPolicy = () => {
  return (
    <BorderedContainer>
      <Box py="12px">
        <Typography fontWeight={700} fontSize="12px">
          Cancellation Policy
        </Typography>
        <Typography fontSize="10px">
          You can cancel the subscription within 3 days of purchase (T&C).
        </Typography>
      </Box>
    </BorderedContainer>
  );
};
const BuySubscription = () => {
  const [isInsufficientBalance, setIsInsufficientBalance] = useState(false);
  const [fromUser, setFromUser] = useState("");
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const [currentYc, setCurrentYc] = useState(0);
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState("");
  const [amount, setAmount] = useState(100);
  const [openDialog, setOpenDialog] = useState(false);

  const data = useSelector((state: RootState) => state.anotherCreator);
  const [subscriptions, setSubscriptions] = useState(data.subscriptions);
  const [profileData, setProfileData] = useState(data.profileData);

  const subscriptionCharge = subscriptions?.amountInYC;
  const query = location.pathname.includes("myorder");
  useEffect(() => {
    if (!subscriptions?.id) {
      const a = localStorage.getItem("subscriptionPlanData");
      if (a) {
        setSubscriptions(JSON.parse(a));
      }
    }
    if (!profileData?.id) {
      const b = localStorage.getItem("profileData");
      if (b) {
        setProfileData(JSON.parse(b));
      }
    }
  }, [query]);

  useEffect(() => {
    if (subscriptions.id) {
      localStorage.setItem(
        "subscriptionPlanData",
        JSON.stringify(subscriptions)
      );
      localStorage.setItem("profileData", JSON.stringify(profileData));
    }
  }, []);

  useEffect(() => {
    if (userRole === "user") {
      userPaymentServiceApi.get_Wallet_Details().then((data) => {
        setCurrentYc(data.data.data.totalYc);
        setIsInsufficientBalance(data.data.data.totalYc < subscriptionCharge);
        setFromUser(data.data.data.userId);
      });
    } else if (userRole === "creator") {
      creatorPaymentServiceApi.get_Wallet_Details().then((data) => {
        setCurrentYc(data.data.data.totalYc);
        setIsInsufficientBalance(data.data.data.totalYc < subscriptionCharge);
        setFromUser(data.data.data.userId);
      });
    }
  }, [subscriptions.id]);

  const onPayingForSubscription = async () => {
    setOpenDialog(true);
  };

  return (
    <Box height="100dvh" display="flex" flexDirection="column">
      <SubscriptionConfirmationModal
        open={openDialog}
        setOpenDialog={setOpenDialog}
        amount={subscriptions.amountInYC}
        fromUser={fromUser}
        userRole={userRole}
        planData={subscriptions}
        profileData={profileData}
      />
      <PageTagComponent
        to={`/${userRole}/creator-profile-view/${profileData.id}`}
      >
        Buy Subscription Plan
      </PageTagComponent>
      <Box flexGrow={1} px="16px">
        <Box>Plan Details</Box>
        <Box display="flex" flexDirection="column" gap="13px">
          <SubscriptionOfferings
            audioCall={subscriptions.audioCall}
            audioCallMinutes={subscriptions.audioCallMinutes}
            videoCall={subscriptions.videoCall}
            videoCallMinutes={subscriptions.videoCallMinutes}
            directMessage={subscriptions.directMessage}
            directMessageMinutes={subscriptions.directMessageMinutes}
          />
          <SunscriptionExpiration />
          <DiscountAndOtherDetails />
          <SubscriptionCancellationPolicy />
          {isInsufficientBalance && (
            <Typography
              pl="8px"
              component="span"
              color="#B71C1C"
              fontSize="10px"
            >
              You have Insufficient balance in your wallet. To buy subscription
              plan add Yaara Coins to your wallet.
            </Typography>
          )}
        </Box>
      </Box>
      <Stack
        px="20px"
        py="4px"
        mt="auto"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        boxShadow="0px -4px 8px 0px rgba(0, 0, 0, 0.08)"
      >
        <Box>
          <Typography fontSize="20px" fontWeight={700}>
            {subscriptions.amountInYC} CC
          </Typography>
          <Typography
            fontSize="14px"
            color={isInsufficientBalance ? "#B71C1C" : "#212121"}
          >
            {isInsufficientBalance ? "Insufficient Balance" : "Grand Total"}
          </Typography>
        </Box>
        {isInsufficientBalance ? (
          <Button
            onClick={() =>
              navigate(`/${userRole}/wallet/add-coins`, {
                state: { from: "buy-subscription" },
              })
            }
            sx={{ width: "140px" }}
            variant="contained"
          >
            Add Cc
          </Button>
        ) : (
          <Button
            onClick={onPayingForSubscription}
            sx={{ width: "140px" }}
            variant="contained"
          >
            Pay
          </Button>
        )}
      </Stack>
    </Box>
  );
};
export { BuySubscription };

const SubscriptionConfirmationModal = ({
  open,
  setOpenDialog,
  amount,
  fromUser,
  userRole,
  planData,
  profileData,
}: {
  open: boolean;
  setOpenDialog: any;
  amount: number;
  fromUser: string;
  userRole: string;
  planData: any;
  profileData: any;
}) => {
  const navigate = useNavigate();
  const [isPaid, setIsPaid] = useState<Status>(STATUS.IDLE);
  const handleSubscriptionChargePay = async () => {
    const serviceId = planData.id;
    const serviceVersion = planData.version;
    try {
      setIsPaid(STATUS.LOADING);
      if (userRole === "user") {
        await userSubscriptionServiceApi.payForSubscription({
          toUser: profileData.userId, // creator
          fromUser, // current user
          serviceType: "SUBSCRIPTION",
          amount,
          serviceVersion,
          serviceId,
        });
        setIsPaid(STATUS.SUCCESS);
        navigate("/user/subscription-added", {
          state: {
            planData,
            handleName: profileData.handleName,
            displayName: profileData.displayName,
          },
        });
      } else if (userRole === "creator") {
        await creatorSubscriptionServiceApi.payForSubscription({
          toUser: profileData.userId, // creator
          fromUser, // current user
          serviceType: "SUBSCRIPTION",
          amount,
          serviceVersion,
          serviceId,
        });
        setIsPaid(STATUS.SUCCESS);
        navigate("/user/subscription-added", {
          state: {
            planData,
            handleName: profileData.handleName,
            displayName: profileData.displayName,
          },
        });
      }
      localStorage.removeItem("subscriptionPlanData");
      localStorage.removeItem("profileData");
    } catch (error) {
      setIsPaid(STATUS.ERROR);
      console.log(error);
    } finally {
      setIsPaid(STATUS.IDLE);
    }
  };
  useEffect(() => {
    setOpenDialog(open);
  }, [open]);
  return (
    <Dialog open={open}>
      <Stack sx={{ height: "300px", width: "280px" }}>
        <Box
          sx={{ background: colors.purple[50] }}
          p="1rem"
          pt="1.5rem"
          height="118px"
        >
          <Box textAlign="center">
            <Typography fontWeight={700} fontSize={typography.font_size.xl}>
              Subscription Plan
            </Typography>
            <Typography fontSize={typography.font_size.base}>
              CC {amount} for 1 month
            </Typography>
          </Box>
        </Box>
        <Box py=".5rem" px="2rem" textAlign="center">
          <Typography fontWeight={700} fontSize={typography.font_size.xl}>
            Confirm Payment
          </Typography>
          <Typography
            lineHeight="16px"
            fontSize={typography.font_size.xs}
            color={colors.gray[80]}
          >
            click below to confirm payment to buy subscription plan of @
            {profileData?.handleName ?? ""}{" "}
          </Typography>
        </Box>
        <Stack
          pt="1.25rem"
          direction="row"
          px="2rem"
          justifyContent="space-between"
        >
          <Button
            sx={{ height: "48px", width: "100px" }}
            variant={isPaid === STATUS.LOADING ? "disabled" : "outlined"}
            onClick={() => setOpenDialog(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{ height: "48px", width: "100px" }}
            variant={isPaid === STATUS.LOADING ? "disabled" : "contained"}
            onClick={handleSubscriptionChargePay}
          >
            {isPaid !== STATUS.LOADING ? (
              "Confirm"
            ) : (
              <CircularProgress size={24} sx={{ color: "#FFF" }} />
            )}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
