import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import Slide, { SlideProps } from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../utils/styles';
import MuiAlert, { AlertProps } from "@mui/material/Alert";

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={4} ref={ref} variant="filled" {...props} />;
});

interface CustomSnackbarProps {
  message: string;
  open: boolean;
  onClose: () => void;
  severity?: 'error' | 'success' | 'warning' | 'info';
}

const variantColors = {
  error: '#B71C1C',
  success: '#2E7D32',
  warning: '#C15700',
  info: '#212121', 
};

const StyledSnackbar = styled(Snackbar)`
  .MuiSnackbarContent-root {
    color: var(--Text-Inverted, ${colors.gray[100]});
    font-size: ${typography.font_size.sm}px;
    font-style: normal;
    font-weight: ${typography.font_weight.regular};
    line-height: ${typography.line_height.default}; /* 135% */
    letter-spacing: ${typography.letter_spacing.standard};
    display: flex;
    width: 344px;
    // height: 34px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    text-align: left;
    word-wrap: break-word;
    background: var(--Background-Inverted, ${colors.black[200]});
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    padding: 16px 16px;
  }
  .MuiSnackbarContent-message {
    padding: 0;
  }
  transform: translateY(-80px);
`;
const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ 
  message, open, onClose ,
 severity = "info"
}) => {
  const [transition, setTransition] = React.useState<
    React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >
  >(Fade);

  React.useEffect(() => {
    setTransition(() => SlideTransition);
  }, []);

  return (
    <StyledSnackbar
      open={open}
      onClose={onClose}
      TransitionComponent={transition}
      // message={message}
      key={transition.name}
      autoHideDuration={5000}
    >
         <Alert 
          onClose={onClose} 
          severity={severity} 
          sx={{
            width: '100%',
            py:0,
            backgroundColor: variantColors[severity],
            display: 'flex',  
            alignItems: 'center',  
            justifyContent: 'center',  
            }}
         >
          {message}
        </Alert>
        </StyledSnackbar>
  );
};

export default CustomSnackbar;
