import React from "react";
import Subscribers from "../../../components/Creator/Subscribers/Subscribers";

const SubscribersPage: React.FC = () => {
  return (
    <>
      <Subscribers />
    </>
  );
};

export default SubscribersPage;
