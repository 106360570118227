import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";

import { typography, colors } from "../../utils/styles";

import SuccessLogo from "../Common/SuccessLogo";

const ForgotPasswordVerification: React.FC = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" mx="16px">
      <Box
        marginTop="140px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mx="20px"
      >
        <Box>
          <SuccessLogo style={{ marginTop: "30px" }} />
        </Box>
        <Typography
          fontSize={typography.font_size["base"]}
          fontWeight={typography.font_weight["bold"]}
          paddingY="16px"
          textAlign="center"
        >
          Verification link sent to you email ID
        </Typography>

        <Typography
          fontSize={typography.font_size["sm"]}
          textAlign="center"
          color={colors.gray["80"]}
          marginBottom="10px"
        >
          Please go to your registered mail ID and reset the password
        </Typography>

        <Button variant="disabled" sx={{ height: "32px" }}>
          Go to Home Page
        </Button>

        <Divider
          style={{
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          OR
        </Divider>

        <Box
          display="flex"
          flexDirection="row"
          fontSize={typography.font_size["sm"]}
        >
          <Typography color={colors.gray["75"]}>
            Did not receive email?{" "}
          </Typography>{" "}
          <a href=""> Resend.</a>
        </Box>
      </Box>
    </Box>
  );
};
export default ForgotPasswordVerification;
