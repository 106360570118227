import { createSlice  } from "@reduxjs/toolkit";
import { NewProfileState, OndemandRateType, ProfileState, PurchaseFeedType, SubscriptionType, UserFeedType } from "../types";

interface CreatorProfileState {
  profileData: NewProfileState;
  lockedPostList: UserFeedType[];
  freePostList:  UserFeedType[];
  purchasedPostList: UserFeedType[];
  subscriptions:SubscriptionType;
  onDemandRate:OndemandRateType;
}

const initialAnotherCreatorState: CreatorProfileState = {
  profileData: {
    id: "",
    userId: "",
    email: "",
    phoneNumber: "",
    displayName: "",
    handleName: "",
    description: "",
    contentTarget: [],
    referralCode: "",
    coins: 0,
    creatorType: "",
    businessName: "",
    individualName: "",
    followersCount: 0,
    followingCount: 0,
    subscriptionCount: 0,
    subscriberCount: 0, 
    isInstagramVerified: false,
    isEmailVerified: false,
    isRemoved: false,
    isActive: false,
    isReported: false,
    isBlocked: false,
    profilePhotoUrl: "",
    coverPhotoUrl: "",
    preferredContentCategory: [],
    gender: "",
    isFollowing: false,
  },
  lockedPostList:[],
  freePostList:[],
  purchasedPostList:[],
  subscriptions: {
    id: '',
    amountInYC: 0,
    audioCall: false,
    videoCall: false,
    directMessage: false,
    audioCallMinutes: 0,
    videoCallMinutes: 0,
    directMessageMinutes: 0,
    creatorId: '',
    version: 0,
    discount: null,
    isRecent: false,
    isDeleted: false,
    deletedAt: null,
    createdAt: '',
    updatedAt: '',
    isPurchasedByMe: false,
  },
onDemandRate: {
    id: '',
    creatorId: '',
    audioCallYc: 0,
    videoCallYc: 0,
    directMessageYc: 0,
    createdAt: '',
    updatedAt: '',
      },
};

const anotherCreatorProfileSlice = createSlice({
  name: "anotherCreator",
  initialState: initialAnotherCreatorState,
  reducers: {
    setAnotherCreatorProfile(state, action) {
      state.profileData = action.payload;
    },
    setAnothetCreatorsFreePostsList(state, action){
      state.freePostList=action.payload;
    },
    setAnothetCreatorsLockedPostsList(state, action){
      state.lockedPostList=action.payload;
  },

    setAnothetCreatorsPurchasedPostsList(state, action){
        state.purchasedPostList=action.payload;
    },

    setAnothetCreatorsOnDemandRates(state, action){
        state.onDemandRate=action.payload;
    },

    setAnothetCreatorsSubscriptionData(state, action){
        state.subscriptions=action.payload;
    },
    setAddFollowing(state){
      state.profileData.isFollowing = true;
    },
    setRemoveFollowing(state){
      state.profileData.isFollowing = false;
    },

    setAnothetCreatorsLockedPostsListAfterBuyPost(state, action){
      if (state.lockedPostList) {
        console.log("action.payload?", action.payload);
        const postIndex = state.lockedPostList.findIndex(post => post.id === action.payload?.post?.id);
        
        console.log("postIndex", postIndex);
        if (postIndex !== -1) {
            state.lockedPostList.splice(postIndex, 1, action.payload.post);
        }
      }
    }
}
});

export const {
    setAnotherCreatorProfile,
    setAnothetCreatorsFreePostsList,
    setAnothetCreatorsLockedPostsList,
    setAnothetCreatorsPurchasedPostsList,
    setAnothetCreatorsOnDemandRates,
    setAnothetCreatorsSubscriptionData,
    setAddFollowing,
    setRemoveFollowing,
    setAnothetCreatorsLockedPostsListAfterBuyPost,
} =
  anotherCreatorProfileSlice.actions;
export const anotherCreatorProfileReducer = anotherCreatorProfileSlice.reducer;
