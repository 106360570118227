import React, {useState, useEffect} from 'react';
// import Modal from "@mui/material/Modal";
import { Box, Modal } from '@mui/material';

interface AppModalsProps {
    name: string;
    component: React.ReactNode;
    close: () => void;
    children?: React.ReactNode; 
  }


  const AppModals: React.FC<AppModalsProps> = (props) => {
    const { close, children, component } = props;

    useEffect(() => {
      }, [props]);

  return (
   
    <Modal
        id="appModals"
        open={!!component} 
        onClose={close ?? undefined} 
      >
        <Box
        maxWidth="sm"
        sx={{background: "none", height: "100vh"}}
        >
            {children ? children : null}
        </Box>

  </Modal>
  )
}

export default AppModals;