import { CircularProgress, Backdrop } from "@mui/material";
import { colors } from "../../utils/styles";

const Loader = ({ loading }: { loading: boolean }) => {
  return (
    <Backdrop open={loading} style={{ zIndex: 999 }}>
      <div
        style={{
          color: "#fff",
          display: "flex",
          width: "36px",
          height: "36px",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          borderRadius: "4px",
          background: colors.violet[100],
          flexShrink: 0,
        }}
      >
        <CircularProgress color="inherit" size={20} />
      </div>
    </Backdrop>
  );
};

export default Loader;
