import React, { useState } from "react";

import {
  Box,
  Button,
  Divider,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { typography, colors } from "../../utils/styles";
import AuthApi from "../../api/auth/AuthApi";
import ForgotPasswordVerification from "../../components/ForgotPassword/Verification";
import { STATUS, Status } from "../../constants/statusConstants";
import useToast from "../../hooks/useToast";

const ForgotPassword: React.FC = () => {
  const { handleOpenToast, ToastMessage } = useToast();
  const [email, setEmail] = useState("");
  const [isMailSent, setIsMailSent] = useState<Status>(STATUS.IDLE);
  const [errors, setErrors] = useState<{
    email?: string;
  }>({});

  const validateEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const error = regex.test(email) ? "" : "Incorrect email";
    setErrors({ email: error });
  };

  const onClickResetPassword = async (event: any) => {
    if (!email) {
      handleOpenToast("Please enter your email.", "error");
      return;
    }
    if (errors.email) {
      handleOpenToast(errors.email, "error");
      return;
    }
    setIsMailSent(STATUS.LOADING);
    try {
      const response = await AuthApi.forgotPassword(email);
      setIsMailSent(STATUS.SUCCESS);
    } catch (error: any) {
      if (error?.response) {
        handleOpenToast(
          error.response?.data?.errors?.[0].message ?? "Something went wrong.",
          "error"
        );
      } else handleOpenToast("Something went wrong.", "error");
      setIsMailSent(STATUS.ERROR);
    }
  };

  return isMailSent === STATUS.SUCCESS ? (
    <ForgotPasswordVerification />
  ) : (
    <Box mx="16px" my="24px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap="16px"
      >
        <ToastMessage />
        <img
          src="/Icons/yaara_logo.svg"
          style={{ marginTop: "40px" }}
          alt="logo"
        />
        <Typography
          fontSize={typography.font_size["2xl"]}
          fontWeight={typography.font_weight["bold"]}
        >
          Forgot Password
        </Typography>

        <Typography
          fontSize={typography.font_size["base"]}
          fontWeight={typography.font_weight["regular"]}
          color={colors.black["200"]}
          textAlign="center"
        >
          Enter your email address and we will send you a link to reset your
          password.
        </Typography>
      </Box>
      <Box>
        <InputLabel sx={{ paddingBottom: "4px", paddingTop: "30px" }}>
          Email
        </InputLabel>
        <TextField
          variant="outlined"
          fullWidth
          sx={{ marginBottom: "20px" }}
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={(e) => validateEmail(e.target.value)}
          error={!!errors.email}
          helperText={errors.email && errors.email}
          InputProps={{ style: { height: "48px" } }}
        />

        <Button onClick={onClickResetPassword} variant="primary" fullWidth>
          Reset Password
        </Button>

        <Divider
          style={{
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          OR
        </Divider>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          gap="40px"
        >
          <Box
            display="flex"
            flexDirection="row"
            fontSize={typography.font_size["sm"]}
          >
            <Typography color={colors.gray["75"]}>Have an account? </Typography>
            <a href="/login">Sign In.</a>
          </Box>

          <Typography
            sx={{
              fontSize: typography.font_size["xxs"],
              color: colors.gray["75"],
            }}
          >
            By signing in, you are agreeing to our{" "}
            <a href="/terms-and-conditions">T&C</a> and{" "}
            <a href="/privacy-policy">Privacy Policy</a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
