import React from "react";
import UserSubscription from "../../../components/User/Subscription/Subscription";

const SubscriptionPage: React.FC = () => {
  return (
    <>
      <UserSubscription />
    </>
  );
};

export default SubscriptionPage;
