import React, { useEffect, useState } from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import { RequestCloseModal, RequestOpenModal, severityType, UserFeedType } from "../../types";
import { typography, colors } from "../../utils/styles";
import { useLocation } from "react-router-dom";
import userSubscriptionServiceApi from "../../api/user/subscriptionServiceApi";
import creatorSubscriptionServiceApi from "../../api/creator/subscriptionServiceApi";
import userPaymentServiceApi from "../../api/user/paymentServiceApi";
import creatorPaymentServiceApi from "../../api/creator/paymentServiceApi";
import BuyPostInsufficientbalance from "./BuyPostInsufficientbalance";
import feedsServices from "../../api/feeds/feedsServices";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { setPostAfterBuyPost } from "../../redux/userFeedSlice";
import { getContentInfo } from "../../services/contentInfo";
import userFeedsService from "../../api/feeds/userFeedsService";

const Container = styled(Box)`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Content = styled(Box)`
  display: flex;
  width: 280px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  opacity: 0.9;
  background: ${colors.white};
`;
const TopContentContainer = styled(Box)`
  width: 100%;
  height: 85px;
  border-radius: 8px 8px 0 0;
  background: ${colors.purple[50]};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  justify-content: center;
`;
const MainContent = styled(Box)`
  display: flex;
  padding: 16px 16px 4px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
`;
const UserInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  justify-content: center;
`;
const UserName = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: normal;
`;
const UserHandleName = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const BalanceInfo = styled(Box)`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 2px;
`;
const BalanceText = styled(Typography)`
  color: ${colors.red[200]};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const AddCoinButton = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${colors.violet[100]};
  overflow: hidden;
  color: ${colors.gray[100]};
  text-align: center;
  text-overflow: ellipsis;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  width: 232px;
  &:hover {
    background: ${colors.violet[100]};
  }
`;
const CancelButton = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: ${colors.violet[100]};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};

  &:hover {
    background: inherit;
  }
`;
const ButtonsContainer = styled(Box)`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 8px;
`;
const CelebrationContainer = styled(Box)``;
const Image = styled("img")``;
const AvailText = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-size: ${typography.font_size.xl}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: normal;
`;
interface BuyPostProps {
  requestOpenModal: RequestOpenModal;
  requestCloseModal: RequestCloseModal;
  post: UserFeedType;
  openSnackBar:(message:string, severity: severityType)=> void;
}
const BuyPost: React.FC<BuyPostProps> = ({
  requestOpenModal,
  requestCloseModal,
  post,
  openSnackBar
}) => {
  const [fromUser, setFromUser] = useState("");
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const [availableYC, setAvailableYC] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const getAandUpdateWalletDetails = () => {
    try {
    if (userRole === "user") {
      userPaymentServiceApi.get_Wallet_Details().then((data) => {
        setAvailableYC(data.data.data.totalYc);
        setFromUser(data.data.data.userId);
      });
    } else if (userRole === "creator") {
      creatorPaymentServiceApi.get_Wallet_Details().then((data) => {
        setAvailableYC(data.data.data.totalYc);
        setFromUser(data.data.data.userId);
      });
    } else {
      setAvailableYC("0");
    }
  } catch (error) {
    console.log(error);
  }
  };
  const fetchPostByPostId = async () => {
    try {
      const response = userRole === 'creator' ? 
      await feedsServices.getPostById(post.id)
     : 
     await userFeedsService.getPostById(post.id);
      dispatch(setPostAfterBuyPost({ post: response.data.data}));
    } catch (error) {
      console.log(error);
    }
  };

  const onBuyPostSuccess = (status: number) => {
    if (status === 200) {
      openSnackBar("You have successfully purchased a post.", "success");
      fetchPostByPostId();
      requestCloseModal();
    }
  };

  const onPayingForSubscription = async () => {
    try {
      if (userRole === "user") {
        const resp = await userSubscriptionServiceApi.payForSubscription({
          toUser: post.creator.userId,
          fromUser,
          serviceType: "POST",
          amount: post?.price,
          serviceId: post?.id,
        });
        onBuyPostSuccess(resp.data.status);
      } else if (userRole === "creator") {
        const resp = await creatorSubscriptionServiceApi.payForSubscription({
          toUser: post.creator.userId,
          fromUser,
          serviceType: "POST",
          amount: post.price,
          serviceId: post?.id,
        });
        onBuyPostSuccess(resp.data.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnClickPayButton = () => {
    if (post.price <= parseInt(availableYC)) {
      onPayingForSubscription();
    } else {
      requestCloseModal();
      requestOpenModal({
        name: "Insufficient balance modal",
        component: (
          <BuyPostInsufficientbalance
            requestOpenModal={requestOpenModal}
            requestCloseModal={requestCloseModal}
            post={post}
            availableYC={availableYC}
          />
        ),
      });
    }
  };
  const handleCancel = () => {
    requestCloseModal();
  };
  useEffect(() => {
    getAandUpdateWalletDetails();
  }, []);

  return (
    <Container>
      <Content>
        <TopContentContainer>
          <UserInfo>
            <UserName>{post?.creator?.displayName}</UserName>
            <UserHandleName>
              {post?.creator?.handleName ? `@${post?.creator?.handleName}` : ""}
              </UserHandleName>
          </UserInfo>
        </TopContentContainer>
        <MainContent>
          <CelebrationContainer>
            <Image src="/Images/celebrations.png" alt="celebrations" />
          </CelebrationContainer>
          <BalanceInfo>
            <AvailText>Avail Lifetime Access</AvailText>
            <BalanceText>{getContentInfo(post)}</BalanceText>
          </BalanceInfo>
        </MainContent>
        <ButtonsContainer>
          <AddCoinButton onClick={handleOnClickPayButton}>
            Pay CC {post?.price}
          </AddCoinButton>
          <CancelButton onClick={handleCancel}>Cancel</CancelButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};

export default BuyPost;
