import { Button, Stack, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useEffect, useRef, useState } from "react";
import { typography, colors } from "../../utils/styles";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import creatorProfileServiceApi from "../../api/creator/creatorProfileServiceApi";
import profileServiceApi from "../../api/user/profileServiceApi";
import CustomTooltip from "../../utils/CustomTooltip";
import { InfoSvg } from "../../assets/SvgIcons";
import CustomSnackbar from "../../components/Common/Snackbar";
import { severityType } from "../../types";

const ReferAndEarn: React.FC = () => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [copySuccess, setCopySuccess] = useState<string>("");
  const [isWebShareApiAvailable, setIsWebShareApiAvailable] = useState(true);
  const [referralCode, setReferralCode] = useState<string | null>();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity]= useState<severityType>("info");
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const userReferralCode = useSelector(
    (state: RootState) => state.user.referralCode
  );
  const creatorReferralCode = useSelector(
    (state: RootState) => state.creatorProfile.profile.referralCode
  );
  const [earnedReferralCoins, setEarnedReferralCoins] = useState(0);

  const copyToClipboard = async () => {
    if (!referralCode) return;
    if (navigator.clipboard && referralCode) {
      try {
        await navigator.clipboard.writeText(referralCode);
        setCopySuccess("Copied!");
        openSnackBar("Referral code copied to clipboard", "success");
      } catch (err) {
        fallbackCopyTextToClipboard(referralCode);
      }
    } else {
      fallbackCopyTextToClipboard(referralCode);
    }
  };

  const fallbackCopyTextToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      setCopySuccess("Copied!");
      openSnackBar("Referral code copied to clipboard", "success");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
    document.body.removeChild(textArea);
  };
  const handleShare = () => {
    if (navigator.share) {
      const shareUrl = `https://dev.yarra.social/signup/${referralCode}`;
      const textToCopy = `𝗝𝗼𝗶𝗻 𝗖𝗹𝗼𝘂𝘁 𝗮𝗻𝗱 𝗘𝗮𝗿𝗻 𝗖𝗹𝗼𝘂𝘁 𝗖𝗼𝗶𝗻𝘀!\n\nI’m so into Clout, and I’m sure it will win you over too! Clout takes fun to a whole new level. Join now and grab your referral bonus!💸\n\nSign up using my link and referral code to get a bonus:\n👉 Join Clout: ${shareUrl}\n🆔 Referral Code: ${referralCode}\n\n𝐉𝐨𝐢𝐧 𝐧𝐨𝐰 𝐚𝐧𝐝 𝐥𝐞𝐭’𝐬 𝐦𝐚𝐤𝐞 𝐭𝐡𝐞 𝐦𝐨𝐬𝐭 𝐨𝐟 𝐂𝐥𝐨𝐮𝐭 𝐭𝐨𝐠𝐞𝐭𝐡𝐞𝐫!🚀`;
      navigator
        .share({
          text: textToCopy,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.error("Error sharing", error));
    } else {
      openSnackBar("Referral code copied to clipboard", "warning");
    }
  };

  const getReferralCode = () => {
    let referralCodeFromState =
      userRole === "creator" ? creatorReferralCode : userReferralCode;
    setReferralCode(referralCodeFromState);
  };

  const getTotalEarnedReferralCoins = async () => {
    try {
      let response =
        userRole === "creator"
          ? await creatorProfileServiceApi.getEarnedReferralCoins()
          : await profileServiceApi.getEarnedReferralCoins();
      setEarnedReferralCoins(response.data.data.totalReferralBonus);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOnClickGenerateReferralCode = async () => {
    try {
      let response =
        userRole === "creator"
          ? await creatorProfileServiceApi.generateReferralCode()
          : await profileServiceApi.generateReferralCode();
      setReferralCode(response.data.data.referralCode);
    } catch (err) {
      console.error(err);
    }
  };

  const openSnackBar = (message: string, severity: severityType) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (!navigator.share) {
      setIsWebShareApiAvailable(false);
    }
  }, []);

  useEffect(() => {
    getReferralCode();
    getTotalEarnedReferralCoins();
  }, []);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      sx={{ overflow: "hidden" }}
    >
      <img
        src="/Images/referandearn.png"
        style={{ width: "340px", height: "255px" }}
        alt="Refer and Earn"
      />
      <Typography
        sx={{
          fontSize: typography.font_size.base,
          color: colors.gray["80"],
          fontWeight: typography.font_weight.bolder,
          display: "flex",
          gap: "4px",
        }}
      >
        Invite Friends & Earn Clout Coins
        <CustomTooltip
          arrow={true}
          title="You would be able to earn a maximum of 1000 CC , for your 1st 10 referrals."
          placement="bottom-end"
        >
          <Typography
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InfoSvg />
          </Typography>
        </CustomTooltip>
      </Typography>
      <Typography
        sx={{
          fontSize: typography.font_size.sm,
          color: colors.gray["80"],
        }}
      >
        You Earned: {earnedReferralCoins} coins
      </Typography>
      {referralCode ? (
        <>
          <Stack
            direction="row"
            mt="16px"
            alignItems="center"
            gap="16px"
            justifyContent="center"
          >
            <Typography
              ref={textRef}
              sx={{
                fontSize: typography.font_size.base,
                color: colors.gray["80"],
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "48%",
                overflow: "hidden",
              }}
            >
              Code: {referralCode}
            </Typography>
            <ContentCopyIcon
              onClick={copyToClipboard}
              style={{ cursor: "pointer" }}
            />
          </Stack>
          <Button
            sx={{ mt: "2.5rem" }}
            variant="contained"
            onClick={handleShare}
          >
            Invite
          </Button>
        </>
      ) : (
        <Button
          sx={{ mt: "2.5rem" }}
          variant="contained"
          onClick={handleOnClickGenerateReferralCode}
        >
          Generate referral code
        </Button>
      )}

      <CustomSnackbar
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        severity={snackbarSeverity}
      />
    </Stack>
  );
};

export { ReferAndEarn };
