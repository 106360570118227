import styled from "@emotion/styled";
import { Avatar, Badge, Stack, Typography } from "@mui/material"
import { useRef, useState } from "react";
import { uploadimage } from "../../services/uploadToS3";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
}));

export const AboutMe = ({ displayName = "", handleName = "", profilePic, updateProfilePicture, setImageLoadingStatus }: {
    displayName?: string,
    handleName?: string,
    profilePic?: string,
    updateProfilePicture?: any,
    setImageLoadingStatus: (loading: boolean) => void;
}) => {
    const imageInputRef = useRef<HTMLInputElement>(null);
    const [profileImage, setProfileImage] = useState<string>(profilePic ?? "");
    const handleBoxClick = () => {
        if (imageInputRef.current) {
            imageInputRef.current.click();
        }
    };
    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result) {
                    setProfileImage(reader.result as string);
                }
            };
            reader.readAsDataURL(file);
            setImageLoadingStatus(true);
            await uploadimage("profile_photos", file)
                .then((d) => {
                    setProfileImage(d);
                    updateProfilePicture(d)
                })
                .catch((err) => console.error(err))
                .finally(() => {
                    setImageLoadingStatus(false)
                })
        }
    };
    return <Stack direction='row' gap={1} alignItems="center" mb="32px" px="16px" position="relative" bottom="4px" >
        <Badge
            onClick={handleBoxClick}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
                <SmallAvatar alt="plus" src="/Icons/plus_icon_circle.svg" />
            }
        >
            <Avatar sx={{ height: '60px', width: "60px", }} alt="profile" src={profileImage} />
        </Badge>
        <Stack>
            <Typography fontSize={14} fontWeight="bold" sx={{ letterSpacing: "0.21px" }}>{displayName}</Typography>
            <Typography fontSize={12} >@{handleName}</Typography>
        </Stack>
        <input
            type="file"
            ref={imageInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleImageChange}
        />

    </Stack>
}

