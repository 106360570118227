import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { AppDispatch, RootState } from "../store/store";
import creatorApi from "../api/creator/creatorApi";
import { useEffect } from "react";
import creatorProfileServiceApi from "../api/creator/creatorProfileServiceApi";
import { setCreator } from "../redux/appCreatorSlice";


const CreatorsProtectedRoute = () =>{
  const dispatch=useDispatch<AppDispatch>();
  const strAccessToken = localStorage.getItem('creatorAccessToken');
  let accessToken = '';
  if(strAccessToken) accessToken = JSON.parse(strAccessToken);
  const userId = useSelector((state: RootState) => state.creatorProfile.profile?.id ?? "");
  const updateCreatorProfile = () =>{
    if(!userId){
      creatorProfileServiceApi.getCreatorProfile().then(res=>{
        dispatch(setCreator(res.data.data.creator));
      }).catch(err=>{});
    }
    else{
      console.log(userId)
    }
  }
  useEffect(()=>{
    updateCreatorProfile()
  },[])
  if(!accessToken) return <Navigate to="/"/>

  return <Outlet />
}
export {CreatorsProtectedRoute}