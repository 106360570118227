import { ApiService } from '../ApiClient';
import {creatorAxiosInstance} from '../axiosInstance';
import '../interceptors/creatorAuthInterceptors';

class PostEarningsApi extends ApiService {
   
    constructor() {
      super(creatorAxiosInstance)
    }
     getPurchasedPostData(postId: string, userId: string, page: string, pageSize: string) {
       return this.axios.get(`payment/yc-history/service/${postId}?userId=${userId}&page=${page}&pageSize=${pageSize}&serviceType=POST&paymentType=PURCHASED`);
      }
    getTipsData(postId: string, userId: string, page: string, pageSize: string, ) {
        return this.axios.get(`payment/yc-history/service/${postId}?userId=${userId}&page=${page}&pageSize=${pageSize}&serviceType=POST&paymentType=TIPS`);
      }

  }
  export default new PostEarningsApi();
  