class PeerService {
  public peer: RTCPeerConnection | null = null;

  constructor() {
    if (!this.peer) {
      this.peer = new RTCPeerConnection({
        iceServers: [
          {
            urls: [
              "stun:stun.l.google.com:19302",
              "stun:global.stun.twilio.com:3478",
            ],
          },
        ],
      });

      this.peer.onicecandidate = (event) => {
        if (event.candidate) {
          console.log("New ICE candidate:", event.candidate);
        }
      };
    }
  }

  async getOffer() {
    if (!this.peer) {
      return null;
    }
    const offer = await this.peer.createOffer();
    await this.peer.setLocalDescription(offer);
    return offer;
  }

  async getAnswer(offer: RTCSessionDescriptionInit) {
    if (!this.peer) {
      return null;
    }
    await this.peer.setRemoteDescription(new RTCSessionDescription(offer));

    if (this.peer.signalingState === "have-remote-offer") {
      const answer = await this.peer.createAnswer();
      await this.peer.setLocalDescription(answer);
      return answer;
    } else {
      throw new Error(
        "Peer connection is not in the right state to create an answer"
      );
    }
  }

  async setLocalDescription(answer: RTCSessionDescriptionInit) {
    if (!this.peer) {
      return null;
    }
    await this.peer.setRemoteDescription(new RTCSessionDescription(answer));
  }

  cleanup() {
    if (this.peer) {
      this.peer.close();
      this.peer = null;
    }
  }
}

export const peer = new PeerService();
