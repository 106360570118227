import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateSubscriptionHeader from "./OnDemandHeader";
import {
  AudioCallIcon,
  InfoSvg,
  TextMessageIcon,
  VideoCallIcon,
} from "../../../assets/SvgIcons";
import { Box, Button, styled, Typography } from "@mui/material";
import CustomTooltip from "../../../utils/CustomTooltip";

import OnDemandRateSuccessCard from "./OnDemandRateSuccessCard";

import { colors, typography } from "../../../utils/styles";
import { InitialOnDemandRate } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import creatorApi from "../../../api/creator/creatorApi";
import OnDemandRateInputCards from "./OnDemandRateInputCards";
import {
  resetAudioCallRate,
  resetDirectMessageRate,
  resetVideoCallRate,
  setAudioCallRate,
  setDirectMessageRate,
  setOnDemandRate,
  setVideoCallRate,
} from "../../../redux/creatorOnDemand";
import creatorProfileServiceApi from "../../../api/creator/creatorProfileServiceApi";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
`;

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  // min-height: auto;
  // max-height: 100vh;
  // margin-bottom: 62px;
`;

const TitleContainer = styled(Box)`
  padding: 0 16px;
  position: sticky;
  top: 8vh;
  left: 0;
  z-index: 12;
  background: ${colors.white};
`;

const TitleAndTooltipContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;

const SubtitleContainer = styled(Box)``;

const Subtitle = styled(Typography)`
  color: ${colors.gray[80]};
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
  align-self: stretch;
`;

const BottomButtonsContainer = styled(Box)`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
`;

const DoneButton = styled(Button)`
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  display: flex;
  height: 56px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 0;
  width: 100%;
  cursor: pointer;
`;

const SubscriptionSuccessCardContainer = styled(Box)`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  width: 100%;
  // position: fixed;
  // bottom: 56px;
  // left: 17px;
  padding: 12px 0;
  // z-index: 12;
  background: ${colors.white};
`;

const CreateSubscriptionFormContainer = styled(Box)`
  overflow: visible;
  height: auto;
  // min-height: 55vh;
  // max-height: 145vh;
`;

const CardContainer=styled(Box)`
  padding: 16px;
`

interface CreateOnDemandRatesProps {
  headerTitleText: string;
  title: string;
  subtitle: string;
  tooltipText: string;
  cancelTextTitle: string;
  cancelTextSubtitle: string;
}

type itemsProps = {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  isActive: boolean;
};

const data = [
  {
    title: "Direct Message",
    subtitle: "100CC per 1 minute",
    icon: <TextMessageIcon />,
    isActive: false,
  },

  {
    title: "Audio Call",
    subtitle: "100CC per 1 minute",
    icon: <AudioCallIcon />,
    isActive: false,
  },

  {
    title: "Video Call",
    subtitle: "100CC per 1 minute",
    icon: <VideoCallIcon />,
    isActive: false,
  },
];

const initialOnDemandData = {
  id: "",
  audioCallYc: 0,
  videoCallYc: 0,
  directMessageYc: 0,
};

const CreateOnDemandRates: React.FC<CreateOnDemandRatesProps> = ({
  headerTitleText,
  title,
  subtitle,
  tooltipText,
  cancelTextTitle,
  cancelTextSubtitle,
}) => {
  const navigate = useNavigate();
  const handleOnClickGoBackButton = () => {
    navigate(-1);
  };
  const [items, setItems] = useState<itemsProps[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const [onDemandRateData, setOnDemandRateData] =
    useState<InitialOnDemandRate>(initialOnDemandData);
  const { onDemandRate } = useSelector(
    (state: RootState) => state.creatorOnDemandRate
  );
  const {id}=useSelector((state: RootState)=> state.creatorProfile.profile);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");



  const handleSetSuccessCardDataValues = (
    dataValue: string,
    isDeletingFlag?: boolean,
    itemValue?: number
  ) => {
    const dataCopy = [...data];
    const itemIndex = dataCopy.findIndex((item) => item.title === dataValue);

    if (itemIndex === -1) return;

    const item = { ...dataCopy[itemIndex], isActive: true };

    if (isDeletingFlag) {
      const newItems = items.filter((item) => item.title !== dataValue);
      setItems(newItems);
      return;
    }

    if (itemValue) {
      item.subtitle = `${itemValue}YC per 1 minute`;
    }

    const newItems = items.filter(
      (existingItem) => existingItem.title !== dataValue
    );
    setItems([...newItems, item]);
  };

  const handleOnClickDoneButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleSetInitialPlanCard = () => {
    const newItems = data.map((item) => {
      if (item.title === "Direct Message" && onDemandRate.directMessageYc) {
        return {
          ...item,
          isActive: true,
          subtitle: `${onDemandRate.directMessageYc}YC per 1 minute`,
        };
      }
      if (item.title === "Audio Call" && onDemandRate.audioCallYc) {
        return {
          ...item,
          isActive: true,
          subtitle: `${onDemandRate.audioCallYc}YC per 1 minute`,
        };
      }
      if (item.title === "Video Call" && onDemandRate.videoCallYc) {
        return {
          ...item,
          isActive: true,
          subtitle: `${onDemandRate.videoCallYc}YC per 1 minute`,
        };
      }

      return item;
    });

    setItems(newItems);
  };

  const handleDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    setOnDemandRateData((prev) => {
      return { ...prev, [name]: Number(value) };
    });
  };

  const handleDeleteVideoCall = async () => {
    try{
    handleSetSuccessCardDataValues("Video Call", true, 0);
    setOnDemandRateData((prev) => {
      return { ...prev, videoCallYc: 0 };
    });
    dispatch(resetVideoCallRate());
    if(onDemandRateData.id){
      const response=await creatorProfileServiceApi.deleteOnDemandRate(onDemandRateData?.id, "field", "videoCallYc")
    }
  }
  catch(err){
    console.log("error", err)
  }
  };

  const handleDeleteAudioCall = async () => {
    try{
    handleSetSuccessCardDataValues("Audio Call", true, 0);
    setOnDemandRateData((prev) => {
      return { ...prev, audioCallYc: 0 };
    });
    dispatch(resetAudioCallRate());
    if (onDemandRateData.id) {
      const response=await creatorProfileServiceApi.deleteOnDemandRate(onDemandRateData?.id, "field", "audioCallYc")
    }
  }
    catch(err){
      console.log("error", err)
    }
  };

  const handleDeleteDirectMessage = async () => {
    try{
    handleSetSuccessCardDataValues("Direct Message", true, 0);
    setOnDemandRateData((prev) => {
      return { ...prev, directMessageYc: 0 };
    });

    dispatch(resetDirectMessageRate());
    if (onDemandRateData.id) {
      const response=await creatorProfileServiceApi.deleteOnDemandRate(onDemandRateData?.id, "field", "directMessageYc")
    }
  }
  catch(err){
    console.log("error", err)
  }
  
  };

  const handleAudioCallValueSet = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    try{
      e.preventDefault();
    if (onDemandRateData.audioCallYc) {
      openSnackBar("Audio call rates are set successfully.");
      handleSetSuccessCardDataValues(
        "Audio Call",
        false,
        onDemandRateData?.audioCallYc 
      );
      let rate = onDemandRateData?.audioCallYc 
      const body = {
        audioCallYc: rate,
      };
      dispatch(setAudioCallRate(rate));
      const response=await creatorProfileServiceApi.createUpDateOnDemandRate(body); 
    }
    return;
  }
    catch(err){
      console.log("error", err)
    }

  };

  const handleVideoCallValueSet = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    try{
    e.preventDefault();
    let rate = onDemandRateData?.videoCallYc
    if (onDemandRateData.videoCallYc) {
      openSnackBar("Video call rates are set successfully.");
      handleSetSuccessCardDataValues(
        "Video Call",
        false,
        onDemandRateData?.videoCallYc
      );
      const body = {
        videoCallYc: rate,
      };
      dispatch(setVideoCallRate(rate));
      const response=await creatorProfileServiceApi.createUpDateOnDemandRate(body);
      
    }
    return;

  }
  catch(err){
    console.log("error", err)
  }
  };

  const handleDmValueSet = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try{
      e.preventDefault();
    let rate = onDemandRateData?.directMessageYc
    if (onDemandRateData.directMessageYc) {
      openSnackBar("Direct messages rates are set successfully.");
      handleSetSuccessCardDataValues(
        "Direct Message",
        false,
        onDemandRateData?.directMessageYc
      );

      const body = {
        directMessageYc: rate,
      };
      dispatch(setDirectMessageRate(rate));
      const response=await creatorProfileServiceApi.createUpDateOnDemandRate(body);
      
    }
    return;
}
catch(err){
  console.log("error", err)
}
  };

  const openSnackBar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const checkForData = () => {
    return (
      onDemandRate?.audioCallYc ||
      onDemandRate?.directMessageYc ||
      onDemandRate?.videoCallYc
    );
  };

  const fetchCreatorOnDemandRate = async (creatorId: string) => {
    try {
      const response = await creatorProfileServiceApi.getOnDemandRate(creatorId);
      dispatch(setOnDemandRate(response.data.data.onDemandRate));
    } catch (error) {
      
    } 
  };

  useEffect(() => {
    if (onDemandRate) {
      let data = {
        id: onDemandRate.id ? onDemandRate.id : "",
        audioCallYc: onDemandRate.audioCallYc ? onDemandRate.audioCallYc : 0,
        videoCallYc: onDemandRate.videoCallYc ? onDemandRate.videoCallYc : 0,
        directMessageYc: onDemandRate.directMessageYc
          ? onDemandRate.directMessageYc
          : 0,
      };
      setOnDemandRateData(data);
      handleSetInitialPlanCard();
    }
  }, [dispatch, onDemandRate]);

  useEffect(() => {
    if (id && !onDemandRate?.id) {
      fetchCreatorOnDemandRate(id)
    }
  }, [dispatch, id]);


  return (
    <Container>
      <CreateSubscriptionHeader
        title={headerTitleText}
        cancelTextTitle={cancelTextTitle}
        cancelTextSubtitle={cancelTextSubtitle}
        handleOnClickGoBackButton={handleOnClickGoBackButton}
      />
      <ContentContainer>
        <TitleContainer>
          <TitleAndTooltipContainer>
            <Title>{title}</Title>
            <CustomTooltip
              arrow={true}
              title={tooltipText}
              placement="bottom-start"
            >
              <Typography
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InfoSvg />
              </Typography>
            </CustomTooltip>
          </TitleAndTooltipContainer>
          <SubtitleContainer>
            <Subtitle>{subtitle}</Subtitle>
          </SubtitleContainer>
        </TitleContainer>

        <CreateSubscriptionFormContainer
        >
          <OnDemandRateInputCards
            onDemandRate={onDemandRate}
            onDemandRateData={onDemandRateData}
            handleDataChange={handleDataChange}
            snackbarOpen={snackbarOpen}
            snackbarMessage={snackbarMessage}
            handleAudioCallValueSet={handleAudioCallValueSet}
            handleVideoCallValueSet={handleVideoCallValueSet}
            handleDmValueSet={handleDmValueSet}
            handleDeleteDirectMessage={handleDeleteDirectMessage}
            handleDeleteAudioCall={handleDeleteAudioCall}
            handleDeleteVideoCall={handleDeleteVideoCall}
            handleCloseSnackbar={handleCloseSnackbar}
          />
        </CreateSubscriptionFormContainer>
      </ContentContainer>

      <SubscriptionSuccessCardContainer>
        {checkForData() ? 
        <CardContainer>
          <OnDemandRateSuccessCard items={items} />
        </CardContainer>
        
        : null}
      </SubscriptionSuccessCardContainer>

      <BottomButtonsContainer>
        <DoneButton
          onClick={handleOnClickDoneButton}
          disabled={!items.some((it) => it.isActive)}
          sx={{
            color: items.some((it) => it.isActive)
              ? colors.gray[100]
              : colors.gray[90],
            background: items.some((it) => it.isActive)
              ? colors.violet[100]
              : "rgba(230, 230, 230, 1)",
          }}
        >
          Done
        </DoneButton>
      </BottomButtonsContainer>
    </Container>
  );
};

export default CreateOnDemandRates;
