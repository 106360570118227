import AWS from "aws-sdk";

const aws_region = "ap-south-1";
const bucketName = "yaara-dev";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: aws_region,
  signatureVersion: "v4",
});
const s3 = new AWS.S3();

export const uploadimage = async (path: any, file: any) => {
  const params = {
    Bucket: bucketName,
    Key: `${path + "/" + file.name}`,
    Body: file,
    ContentType: file.type,
  };
  await s3.putObject(params).promise();

  return `https://${bucketName}.s3.${aws_region}.amazonaws.com/${path}/${file.name}`;
};

export const uploadContentFiles = async (key: any, file: any) => {
  const params = {
    Bucket: bucketName,
    Key: key,
    Body: file,
    ContentType: file.type,
  };
  await s3.putObject(params).promise();

  return {
    bucketName,
    key,
    url: `https://${bucketName}.s3.${aws_region}.amazonaws.com/${key}`,
  };
};
