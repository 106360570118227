import { ApiService } from '../ApiClient';
import {creatorAxiosInstance} from '../axiosInstance';
import "../interceptors/creatorAuthInterceptors";


class SubscriptionService extends ApiService {
  constructor() {
    super(creatorAxiosInstance);
  }

  payForSubscription(subscriptionDetails:object){
    return this.axios.post('payment/yc-to-yc/pay-to-creator',subscriptionDetails)
  }

 getAllSubscription(page:string, pageSize:string) {
      return this.axios.get(`subscription/user-subscription?page=${page ?? "1"}&pageSize=${pageSize ?? "10"}`);
  }
getActiveSubscription(page:string, pageSize:string) {

    return this.axios.get(`subscription/user-subscription?page=${page ?? "1"}&pageSize=${pageSize ?? "10"}&status=ACTIVE`);
  
  }
getHistorySubscription(page:string, pageSize:string) {
    return this.axios.get(`subscription/user-subscription?page=${page ?? "1"}&pageSize=${pageSize ?? "10"}&status=HISTORY`);

  }
  getSubscribers(page:string, pageSize:string) {
    return this.axios.get(`subscription/user-subscription/creator?page=${page ?? "1"}&pageSize=${pageSize ?? "10"}&status=HISTORY`);

  }


}

export default new SubscriptionService();
