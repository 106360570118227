import { configureStore } from "@reduxjs/toolkit";
// Import your reducers here
import pageReducer from "../redux/activePageSlice";
import contentReducer from "../redux/creatorContentSlice";
import creatorRegistrationSlice from "../redux/creatorRegistrationSlice";
import { userReducer, creatorReducer } from "../redux/appUserSlice";
import creatorSubscriptionSlice from "../redux/creatorSubscriptionSlice";
import { subscriptionsReducer } from "../redux/subscriptionSlice";
import { creatorProfileReducer } from "../redux/appCreatorSlice";
import { userFeedReducer } from "../redux/userFeedSlice";
import { creatorOnDemandRateReducer } from "../redux/creatorOnDemand";
import { anotherCreatorProfileReducer } from "../redux/creatorServiceSlice";
import { bookingReducer } from "../redux/bookingSlice";
import { postEarningsReducer } from "../redux/postEarningsSlice";
import { imagePostReducer } from "../redux/imageUploadSlice";
import { slotBookingReducer } from "../redux/slotBookingSlice";
import { videoPostReducer } from "../redux/videoUploadSlice";
import { timerReducer } from "../redux/timerSlice";
import { leaderboardReducer } from "../redux/leaderboardSlice";
import { settingsReducer } from "../redux/settingsSlice";

const store = configureStore({
  reducer: {
    // Add your reducers here
    page: pageReducer,
    becomeCreator: creatorRegistrationSlice,
    postContent: contentReducer,
    user: userReducer,
    creator: creatorReducer,
    creatorSubscription: creatorSubscriptionSlice,
    subscriptions: subscriptionsReducer,
    creatorProfile: creatorProfileReducer,
    userFeeds: userFeedReducer,
    creatorOnDemandRate: creatorOnDemandRateReducer,
    anotherCreator: anotherCreatorProfileReducer,
    bookings: bookingReducer,
    postEarnings: postEarningsReducer,
    slotBooking: slotBookingReducer,
    imagePost:imagePostReducer,
    videoPost:videoPostReducer,
    timer: timerReducer,
    leaderboard: leaderboardReducer,
    settings: settingsReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
