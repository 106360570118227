import React from "react";
import { Avatar, Box, Button, Typography, styled } from "@mui/material";
import { RequestCloseModal, RequestOpenModal, UserFeedType } from "../../types";
import { AddCoins } from "../AddCoins/AddCoins";
import { typography, colors } from "../../utils/styles";

const Container = styled(Box)`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Content = styled(Box)`
  display: flex;
  width: 280px;
  flex-direction: column;
  align-items: center;
  height: 362px;
  border-radius: 8px;
  opacity: 0.9;
  background: ${colors.white};
`;
const TopContentContainer = styled(Box)`
  width: 100%;
  border-radius: 8px 8px 0 0;
  background: ${colors.purple[50]};
`;
const TopContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 24px 24px 0 24px;
`;
const Title = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const MainContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;
const UserInfo = styled(Box)`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`;
const UserName = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const UserHandleName = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const BalanceInfo = styled(Box)`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;
const BalanceText = styled(Typography)`
  color: ${colors.red[300]};
  text-align: center;
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const AddCoinButton = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${colors.violet[100]};
  color: ${colors.white};
  text-align: center;
  text-overflow: ellipsis;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  width: 232px;
  &:hover {
    background: ${colors.violet[100]};
  }
`;
const CancelButton = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: ${colors.violet[100]};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  &:hover {
    background: inherit;
  }
`;
const ButtonsContainer = styled(Box)`
  display: flex;
  padding: 16px 24px 8px 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;
interface TipInsufficientbalanceProps {
  requestOpenModal: RequestOpenModal;
  requestCloseModal: RequestCloseModal;
  item: UserFeedType;
  availableYC: string | number;
}
const TipInsufficientbalance: React.FC<TipInsufficientbalanceProps> = ({
  requestOpenModal,
  requestCloseModal,
  item,
  availableYC,
}) => {
  const handleAddYarraCoins = () => {
    requestCloseModal();
    requestOpenModal({
      name: "Add Coins",
      component: (
        <AddCoins
          requestOpenModal={requestOpenModal}
          requestCloseModal={requestCloseModal}
          availableYC={availableYC}
        />
      ),
    });
  };
  const handleCancel = () => {
    requestCloseModal();
  };
  return (
    <Container>
      <Content>
        <TopContentContainer>
          <TopContent>
            <Title>OOPS!! Insufficient Balance </Title>
            <Avatar
              sx={{ width: "56px", height: "56px" }}
              alt="user "
              src={item?.creator?.profilePhotoUrl ?? ""}
            />
          </TopContent>
        </TopContentContainer>
        <MainContent>
          <UserInfo>
            <UserName>{item?.creator?.displayName}</UserName>
            <UserHandleName>
              {item?.creator?.handleName ? `@${item?.creator?.handleName}` : ""}
            </UserHandleName>
          </UserInfo>
          <BalanceInfo>
            <BalanceText>Available Coins are {availableYC}.</BalanceText>
            <BalanceText>Please add coins to Tip Creator.</BalanceText>
          </BalanceInfo>
        </MainContent>
        <ButtonsContainer>
          <AddCoinButton onClick={handleAddYarraCoins}>
            Add Clout Coins
          </AddCoinButton>
          <CancelButton onClick={handleCancel}>Cancel</CancelButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};

export default TipInsufficientbalance;
