import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import store from "./store/store";
import { muiTheme } from "./theme";
import { ModalProvider } from "./context/ModalContext";
import { VideoProvider } from "./context/VideoContext";
import { SnackbarProvider } from "./context/SnackbarContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>
        <ModalProvider>
          <VideoProvider>
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </VideoProvider>
        </ModalProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
