import { Box, Typography } from "@mui/material";

export const SearchFeed = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h5" fontWeight="bold">
        Welcome to the Feed!
      </Typography>
      <Typography variant="body1" fontWeight="18px">
        Content Grid will be shown Soon
      </Typography>
      <Typography variant="h4">Currently in development</Typography>
    </Box>
  );
};
