import { createSlice } from '@reduxjs/toolkit';
import { FAQType, NotificationType } from '../types';
interface CreatorOnDemandRateState {
    notifications: NotificationType,
    faqList: FAQType[],
}
const initialOnDemandState: CreatorOnDemandRateState = {
    notifications:{
      IN_APP_NOTIFICATION: false,
      NEW_POST_NOTIFICATION: false,
      DIRECT_MEASAGE_NOTIFICATION: false,
    },
    faqList: [],
};
const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialOnDemandState,
  reducers: {
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    setfaqList(state, action) {
      state.faqList = action.payload;
    },
    setInAppNotification(state, action) {
      state.notifications.IN_APP_NOTIFICATION = action.payload;        
    },
    setNewPostNotification(state, action) {
      state.notifications.NEW_POST_NOTIFICATION = action.payload;
    },
    setDirectMessagetNotification(state, action) {
      state.notifications.DIRECT_MEASAGE_NOTIFICATION = action.payload;
    },
  },
});
export const {
    setNotifications,
    setfaqList,
    setInAppNotification,
    setNewPostNotification,
    setDirectMessagetNotification,
} = settingsSlice.actions;

export const settingsReducer= settingsSlice.reducer;
