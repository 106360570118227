import React from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import { RequestCloseModal, RequestOpenModal, UserFeedType } from "../../types";
import { AddCoins } from "../AddCoins/AddCoins";
import { colors, typography } from "../../utils/styles";
import { getContentInfo } from "../../services/contentInfo";

const Container = styled(Box)`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Content = styled(Box)`
  display: flex;
  width: 280px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  opacity: 0.9;
  background: ${colors.white};
`;
const TopContentContainer = styled(Box)`
  width: 100%;
  height: 85px;
  border-radius: 8px 8px 0 0;
  background: ${colors.purple[50]};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  justify-content: center;
`;
const MainContent = styled(Box)`
  display: flex;
  padding: 16px 16px 4px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
`;
const UserInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  justify-content: center;
`;
const UserName = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-size: ${typography.font_size.xl}px;
  font-weight: ${typography.font_weight.regular};
  line-height: normal;
`;
const UserHandleName = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-size: ${typography.font_size.sm}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const BalanceInfo = styled(Box)`
  display: flex;
  padding: 0px 16px 3px 16px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 1px;
`;
const BalanceText = styled(Typography)`
  overflow: hidden;
  color: ${colors.red[100]};
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const AddCoinButton = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${colors.violet[100]};
  overflow: hidden;
  color: ${colors.white};
  text-align: center;
  text-overflow: ellipsis;
  font-size: ${typography.font_size.base}px;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  width: 232px;
  &:hover {
    background: ${colors.violet[100]};
  }
`;
const CancelButton = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: ${colors.violet[100]};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  &:hover {
    background: inherit;
  }
`;
const ButtonsContainer = styled(Box)`
  display: flex;
  padding: 0 16px 8px 16px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 8px;
`;
const AvailText = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-size: ${typography.font_size.xl}px;
  font-weight: ${typography.font_weight.regular};
  line-height: normal;
`;
const ContentInfo = styled(Box)``;
const ContentInfoText = styled(Typography)`
  color: ${colors.red[200]};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
interface BuyPostInsufficientbalanceProps {
  requestOpenModal: RequestOpenModal;
  requestCloseModal: RequestCloseModal;
  post: UserFeedType;
  availableYC: string | number;
}
const BuyPostInsufficientbalance: React.FC<BuyPostInsufficientbalanceProps> = ({
  requestOpenModal,
  requestCloseModal,
  post,
  availableYC,
}) => {
  const handleAddYarraCoins = () => {
    requestCloseModal();
    requestOpenModal({
      name: "Add Coins",
      component: (
        <AddCoins
          requestOpenModal={requestOpenModal}
          requestCloseModal={requestCloseModal}
          availableYC={availableYC}
        />
      ),
    });
  };
  const handleCancel = () => {
    requestCloseModal();
  };
  return (
    <Container>
      <Content>
        <TopContentContainer>
          <UserInfo>
            <UserName>{post?.creator?.displayName}</UserName>
            <UserHandleName>
              {post?.creator?.handleName ? `@${post?.creator?.handleName}` : ""}
            </UserHandleName>
          </UserInfo>
        </TopContentContainer>
        <MainContent>
          <ContentInfo>
            <AvailText>Avail Lifetime Access</AvailText>
            <ContentInfoText>{getContentInfo(post)}</ContentInfoText>
          </ContentInfo>
          <BalanceInfo>
            <BalanceText>Available Clout coins {availableYC}.</BalanceText>
            <BalanceText>Add More Clout coins to buy post.</BalanceText>
          </BalanceInfo>
        </MainContent>
        <ButtonsContainer>
          <AddCoinButton onClick={handleAddYarraCoins}>Add CC</AddCoinButton>
          <CancelButton onClick={handleCancel}>Cancel</CancelButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};

export default BuyPostInsufficientbalance;
