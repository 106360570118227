import React, { useEffect, useRef, useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Posts/post.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box, Button, Divider, Typography, styled } from "@mui/material";
import { colors, typography } from "../../utils/styles";
import {
  ArrowForwardIcon,
  BlockIcon,
  CoinIcon,
  CommentIcon,
  DeleteNewIcon,
  EditIcon,
  FilterOptionIcon,
  GiftIcon,
  ReportIcon,
  ShareIcon,
} from "../../assets/SvgIcons";
import { severityType, UserFeedType } from "../../types";
import UserFeedVideoPost from "./UserFeedVideoPost";
import feedsServices from "../../api/feeds/feedsServices";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { deleteFeedById, setAddFollowingInUserFeed, setLikePost, setRemoveFollowingInUserFeed } from "../../redux/userFeedSlice";
import { Comments } from "./Comments/Comments";
import UserFeedImagePost from "./UserFeedImagePost";
import SendTip from "../SendTip/SendTip";
import CustomSnackbar from "../Common/Snackbar";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import usersCreatorProfileServiceApi from "../../api/user/creatorServiceApi";
import creatorsCreatorProfileServiceApi from "../../api/creator/creatorProfileServiceApi";
import userFeedsService from "../../api/feeds/userFeedsService";
import { useModal } from "../../context/ModalContext";
import ReportPost from "../../modals/ReportPost/ReportPost";
import { removePostById } from "../../redux/appCreatorSlice";

import { convertNumberToProfileCount } from "../../utils/ProfileUtilFunctions";
import { setFiles, setMakeItFree, setMode, setPostDescription, setPreferredContent, setPostAmount,  setAddSubscription} from "../../redux/creatorContentSlice";
interface PostComponentProps {
  post: UserFeedType;
  handleLoading?: () => void;
  handleOnClickEarnings?: (post: UserFeedType) => void;
  isMyPost?: boolean;
}
const CarouselContainer = styled(Box)`
  width: 100%;
  maxwidth: 600px;
  margin: 0 auto;
  height: auto;
  position: relative;
`;
const SendTipContainer = styled(Box)``;
const SendTipButton = styled(Button)`
  background: ${colors.violet[50]};
  color: ${colors.white};
  font-weight: ${typography.font_weight.bold};
  font-size: ${typography.font_size.xs};
  display: flex;
  gap: 0.3rem;
  alignitems: center;
  padding: 8px 10px;
  &:hover {
    background: ${colors.violet[50]};
  }
`;
const ContentDescription = styled(Typography)`
  overflow: hidden;
  color: #616161;
  text-align: justify;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.18px;
  display: inline;
  width: 100%;
`;
const UnfollowButton = styled(Button)`
  color: ${colors.gray[90]}; /* Equivalent to var(--Text-Tertiary, #9e9e9e) */
  text-align: center;
  font-family: "Poppins";
  font-size: ${typography.font_size.xs}px; /* 12px font size */
  font-style: normal;
  font-weight: ${typography.font_weight.bold}; /* 700 font weight */
  line-height: ${typography.line_height.default}; /* 135% line-height */
  letter-spacing: ${typography.letter_spacing
    .normal}; /* 0.18px letter-spacing */
`;
const FollowButton = styled(Button)`
  display: flex;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.violet[100]}; /* Equivalent to var(--Background-brand-primary, #7262C2) */
  background: ${colors.violet[100]};
  color: ${colors.white}; /* #FFF */
  text-align: center;
  font-family: "Poppins";
  font-size: ${typography.font_size.xs}px; /* 12px font size */
  font-style: normal;
  font-weight: ${typography.font_weight.bold}; /* 700 font weight */
  line-height: ${typography.line_height.default}; /* 135% line-height */
  letter-spacing: ${typography.letter_spacing
    .normal}; /* 0.18px letter-spacing */
  &:hover {
    background: ${colors.violet[100]};
  }
`;
const EarningTextContainer = styled(Box)`
  display: flex;
  justify-content: end;
  align-items: center;
  flex: 1;
  gap: 2px;
`;
const EarningTitle = styled(Typography)`
  color: ${colors.black[100]}; /* Equivalent to #6E6E6E */
  font-family: "Poppins";
  font-size: ${typography.font_size.xs}px; /* 12px font size */
  font-style: normal;
  font-weight: ${typography.font_weight.bold}; /* 700 font weight */
  line-height: ${typography.line_height.default}; /* 135% line-height */
  display: flex;
  align-items: center;
`;
const EarningText = styled(Typography)`
  color: ${colors.black[100]}; /* Equivalent to #6E6E6E */
  font-family: "Poppins";
  font-size: ${typography.font_size.xs}px; /* 12px font size */
  font-style: normal;
  font-weight: ${typography.font_weight.bold}; /* 700 font weight */
  line-height: ${typography.line_height.default}; /* 135% line-height */
  display: flex;
  align-items: center;
`;
const EarningButton = styled(Button)`
  min-width: 10px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const EarningTextAndButton = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;
const ReportOrBlockModalContainer = styled(Box)`
  display: flex;
  height: 112px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: ${colors.white}; /* Equivalent to var(--Background-card, #FFF) */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: 24px;
  right: 0;
  z-index: 12;
  width: 220px;
`;
const ReporOrBlockContent = styled(Box)`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;
const EditModalContainer = styled(Box)`
  display: flex;
  height: 80px;
  width: 158px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: ${colors.white}; /* Equivalent to var(--Background-card, #FFF) */
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: 24px;
  right: 0;
  z-index: 12;
`;
const EditContent = styled(Box)`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
const EditContentPost=styled(Box)`
display: flex;
padding: 8px;
align-items: center;
gap: 8px;
align-self: stretch;
`;
const EditText = styled(Typography)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  color: ${colors.black[200]}; /* Equivalent to var(--Text-Primary, #212121) */
  font-family: "Poppins";
  font-size: ${typography.font_size.base}px; /* 16px font size */
  font-style: normal;
  font-weight: ${typography.font_weight.regular}; /* 400 font weight */
  line-height: ${typography.line_height.default}; /* 135% line-height */
  letter-spacing: ${typography.letter_spacing
    .default}; /* 0.24px letter-spacing */
  align-self: stretch;
`;
const MenuIconContainer = styled(Box)`
  cursor: pointer;
  color: ${colors.black[200]}; /* Equivalent to black */
  letter-spacing: 0.02rem;
  display: flex;
  min-width: 24px;
  height: 24px;
  padding: 4.5px 0 5.5px 0;
  justify-content: center;
  align-items: center;
`;
const UserFeed: React.FC<PostComponentProps> = ({
  post,
  handleLoading,
  handleOnClickEarnings,
  isMyPost,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [item, setItem] = useState<UserFeedType>();
  const dispatch = useDispatch<AppDispatch>();
  const [viewText, setViewText] = useState<boolean>(false);
  const [openComments, setOpenComments] = useState<boolean>(false);
  const [openTipMe, setOpenTipMe] = useState<boolean>(false);
  const [lockedPost, setLockedPost] = useState<boolean>(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<severityType>("info");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openReportOrBlockModal, setOpenReportOrBlockModal] = useState(false);
  const { requestOpenModal, requestCloseModal } = useModal();
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const modalRef = useRef<HTMLDivElement | null>(null);
  const menuIconRef = useRef<HTMLDivElement | null>(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    touchMove: true,
    swipeToSlide: true,
    afterChange: (current: number) => setCurrentIndex(current),
  };

  const handleOnClickLikeButton = async () => {
    try {
      if (item) {
        const feedLikesCount = item.likesCount;
        const updatedItem = {
          ...item,
          isLikedByMe: !item.isLikedByMe,
          likesCount: item.isLikedByMe
            ? feedLikesCount - 1
            : feedLikesCount + 1,
        };
        setItem(updatedItem);
        if (item.id) {
          const postBody = {
            postId: item.id,
            creatorId: item?.creator?.id,
          };
          const isLiked = !item.isLikedByMe;
          dispatch(setLikePost({ postId: item.id, isLiked }));
          try {
            userRole === "user"
              ? userFeedsService.likePost(postBody)
              : feedsServices.likePost(postBody);
          } catch (apiError) {
            console.error("Failed to like/unlike the post:", apiError);
            setItem({
              ...item,
              isLikedByMe: item.isLikedByMe,
              likesCount: feedLikesCount,
            });
            dispatch(
              setLikePost({ postId: item.id, isLiked: item.isLikedByMe })
            );
          }
        }
      }
    } catch (err) {
      console.error("An unexpected error occurred:", err);
    }
  };

  const handleViewText = () => {
    setViewText((prev) => !prev);
  };
  const handleOnClickShareButton = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check out this video!",
          text: "Watch this amazing video",
          url: window.location.href,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.log("Web Share API is not supported in your browser.");
    }
  };

  const handleOnClickCommentButton = () => {
    setOpenComments(!openComments);
  };
  const handleOnClickTipMe = () => {
    setOpenTipMe(!openTipMe);
  };
  const openSnackBar = (message: string, severity: severityType) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOnClickFollowButton = async () => {
    try {
      if (userRole === "user") {
        if (item?.creator?.userId) {
          dispatch(setAddFollowingInUserFeed({ creatorId: item?.creatorId }));
          const response = await usersCreatorProfileServiceApi.addFollower(
            item?.creator?.userId
          );
        }
      } else if (userRole === "creator") {
        if (item?.creator?.userId) {
          dispatch(setAddFollowingInUserFeed({ creatorId: item?.creatorId }));

          const response = await creatorsCreatorProfileServiceApi.addFollower(
            item?.creator?.userId
          );
        }
      }
    } catch (error) {
      console.log("Error in adding follower", error);
    }
  };

  const handleOnClickUnFollowButton = async () => {
    try {
      if (userRole === "user") {
        if (item?.creator?.userId) {
          dispatch(
            setRemoveFollowingInUserFeed({ creatorId: item?.creatorId })
          );
          const response = await usersCreatorProfileServiceApi.removeFollower(
            item?.creator?.userId
          );
        }
      } else if (userRole === "creator") {
        if (item?.creator?.userId) {
          dispatch(
            setRemoveFollowingInUserFeed({ creatorId: item?.creatorId })
          );
          const response =
            await creatorsCreatorProfileServiceApi.removeFollower(
              item?.creator?.userId
            );
        }
      }
    } catch (error) {
      console.log("Error in removing follower", error);
    }
  };

  const handleOpenCreatorProfile = () => {
    navigate(`/creator/creator-profile-view/${post.creatorId}`);
  };

  const renderTextWithOptionalLink = (text?: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    if (!text) return null;
    const parts = text.split(urlRegex);
    return parts.map((part, index) =>
      urlRegex.test(part) ? (
        <a
          style={{
            overflow: " hidden",
            textAlign: "center",
            textOverflow: " ellipsis",
            whiteSpace: "pre-line",
            fontFamily: "Poppins",
            fontSize: `${typography.font_size.xs}px`,
            fontStyle: "normal",
            lineHeight: typography.line_height.default,
            letterSpacing: typography.letter_spacing.normal,
            width: "100%",
            display: "inline",
            lineBreak: "anywhere",
          }}
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
        >
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  const handleOnClickArrowButton = () => {
    if (item && typeof handleOnClickEarnings === "function") {
      handleOnClickEarnings(item);
    }
  };

  const handleOnClickMenuIcon = () => {
    if (isMyPost) {
      setOpenEditModal(!openEditModal);
    } else {
      setOpenReportOrBlockModal(!openReportOrBlockModal);
    }
  };

  const handleClickOnReportIcon = () => {
    setOpenReportOrBlockModal(false);
    requestOpenModal({
      name: "Report Post Modal",
      component: (
        <ReportPost
          requestOpenModal={requestOpenModal}
          requestCloseModal={requestCloseModal}
          openSnackBar={openSnackBar}
        />
      ),
    });
  };

  const handleClickOnBlockIcon = () => {
    setOpenReportOrBlockModal(false);
  };
  const handleOnClickDeletePost=async(e:React.MouseEvent<HTMLDivElement>)=>{
    e.preventDefault();
    setOpenEditModal(false);
    openSnackBar("Post deleted successfully", "success");
    if(item?.id){
      dispatch(removePostById(item?.id));
      await feedsServices.deletePostById(item?.id)
    }
  }

  const handleOnClickEditPost=(e:React.MouseEvent<HTMLDivElement>)=>{
    e.preventDefault();
    if(item){
      dispatch(setMode(item?.contentType));
      dispatch(setPostDescription(item?.contentDescription))
      dispatch(setPreferredContent(item?.tag));
      dispatch(setMakeItFree(item?.freePost));
      dispatch(setPostAmount(item?.price));
      dispatch(setAddSubscription(item?.subscriptionPost));
      let filesArray:any=[];
      item?.contents?.forEach((element) =>{
          let obj={url: element.processedUrl}
          filesArray.push(obj);
      })
      dispatch(setFiles(filesArray));
      navigate("/test/update",{replace:true, state:{post: item}});

    };
  }
  useEffect(() => {
    if (post) {
      setItem(post);
    }
  }, [post]);
  useEffect(() => {
    if (item) {
      if (item.freePost || item.isPurchasedByMe || item.isPostedByMe) {
        setLockedPost(false);
      }
    }
  }, [item]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        menuIconRef.current &&
        !menuIconRef.current.contains(event.target as Node)
      ) {
        if (isMyPost) {
          setOpenEditModal(false);
        } else {
          setOpenReportOrBlockModal(false);
        }
      }
    }

    if (openEditModal || openReportOrBlockModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openEditModal, openReportOrBlockModal]);

  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
        padding: "0",
        position: "relative",
      }}
      key={item?.id}
    >
      <Box
        className="post_header"
        sx={{
          padding: ".5rem 1rem",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link
          style={{ textDecoration: "none" }}
          to={item?.isPostedByMe ? `/${userRole}/profile` : `/${userRole}/creator-profile-view/${item?.creatorId}`}
        >
          <Box
            sx={{
              display: "flex",
              gap: ".5rem",
              alignItems: "center",
              flex: "1",
            }}
            // onClick={handleOpenCreatorProfile}
          >
            <ListItemAvatar sx={{ margin: "0", minWidth: "auto" }}>
              <Avatar
                sx={{ width: "2.8rem", height: "2.8rem" }}
                alt="user "
                src={item?.creator?.profilePhotoUrl ?? ""}
              />
            </ListItemAvatar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: typography.font_weight.semiBold,
                  color: colors.black[200],
                  letterSpacing: ".02rem",
                }}
              >
                {item?.creator?.displayName}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: typography.font_weight.regular,
                  color: colors.gray[700],
                  letterSpacing: ".02rem",
                }}
              >
                {item?.creator?.handleName
                  ? `@${item?.creator?.handleName}`
                  : ""}
              </Typography>
            </Box>
          </Box>
        </Link>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            justifyContent: "flex-end",
            position: "relative",
          }}
        >
          {!item?.isPostedByMe &&
            (item?.creator?.following ? null : (
              <FollowButton onClick={handleOnClickFollowButton}>
                Follow
              </FollowButton>
            ))}

          <MenuIconContainer ref={menuIconRef} onClick={handleOnClickMenuIcon}>
            <MoreVertIcon />
          </MenuIconContainer>

          {openReportOrBlockModal ? (
            <ReportOrBlockModalContainer ref={modalRef}>
              <ReporOrBlockContent onClick={handleClickOnReportIcon}>
                <ReportIcon />
                <EditText>Report</EditText>
              </ReporOrBlockContent>

              <Divider
                sx={{
                  width: "75%",
                  alignSelf: "self-end",
                }}
              />

              <ReporOrBlockContent onClick={handleClickOnBlockIcon}>
                <BlockIcon />
                <EditText>Block</EditText>
              </ReporOrBlockContent>
            </ReportOrBlockModalContainer>
          ) : null}

          {openEditModal ?
            (
              <EditModalContainer ref={modalRef}>
                <EditContentPost 
                onClick={handleOnClickEditPost}
                >
                    <EditIcon
                      color={"#212121"}
                      width={"24"}
                      height={"24"}
                    />
                    <EditText>Edit</EditText>
                </EditContentPost>

                <Divider sx={{
                    width: "68%",
                    alignSelf: "self-end"
                }}/>


                <EditContent 
                onClick={handleOnClickDeletePost}
                >
                    <DeleteNewIcon
                      color={"#212121"}
                    />
                    <EditText>Delete</EditText>
                </EditContent>
              </EditModalContainer>
            )
            : null
          }
        </Box>
      </Box>

      <Box sx={{ margin: ".5rem 0", padding: "0 1rem" }}>
        <Typography
          sx={{
            width: "100%",
            display: "block",
          }}
        >
          {item?.contentDescription && item?.contentDescription.length > 130 ? (
            <>
              <ContentDescription>
                {viewText
                  ? item?.contentDescription
                  : `${item?.contentDescription.substring(0, 131)}...`}
              </ContentDescription>
              <Typography
                component="span"
                sx={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  color: colors.brand_primary,
                  cursor: "pointer",
                  display: "inline",
                  marginLeft: "5px",
                }}
                onClick={handleViewText}
              >
                {viewText ? "See less" : "See more"}
              </Typography>
            </>
          ) : (
            <ContentDescription>{item?.contentDescription}</ContentDescription>
          )}
        </Typography>
      </Box>
      <CarouselContainer>
        <>
          {item && item.contents?.length > 1 ? (
            <Slider {...settings}>
              {item.contents.map((content) =>
                content.type !== "VIDEO" ? (
                  <UserFeedImagePost
                    key={content.id}
                    item={content}
                    post={item}
                    freePost={item.freePost}
                    price={item.price}
                    subscriptionPost={item.subscriptionPost}
                    lockedPost={lockedPost}
                    openSnackBar={openSnackBar}
                  />
                ) : (
                  <UserFeedVideoPost
                    key={content.id}
                    post={item}
                    contentItem={content}
                    freePost={item.freePost}
                    price={item.price}
                    subscriptionPost={item.subscriptionPost}
                    lockedPost={lockedPost}
                    openSnackBar={openSnackBar}
                  />
                )
              )}
            </Slider>
          ) : (
            item?.contents.map((content) =>
              content.type !== "VIDEO" ? (
                <UserFeedImagePost
                  key={content.id}
                  post={item}
                  item={content}
                  freePost={item.freePost}
                  price={item.price}
                  subscriptionPost={item.subscriptionPost}
                  lockedPost={lockedPost}
                  openSnackBar={openSnackBar}
                />
              ) : (
                <UserFeedVideoPost
                  key={content.id}
                  post={item}
                  contentItem={content}
                  freePost={item.freePost}
                  price={item.price}
                  subscriptionPost={item.subscriptionPost}
                  lockedPost={lockedPost}
                  openSnackBar={openSnackBar}
                />
              )
            )
          )}

          {item && item?.contents?.length > 1 ? (
            <Typography
              variant="body1"
              sx={{ fontWeight: typography.font_weight.semiBold }}
              className="image_counter"
            >
              {currentIndex + 1}
              <Box className="slash_divider"></Box>
              {item?.contents?.length}
            </Typography>
          ) : null}
        </>
      </CarouselContainer>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: "0.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "6px 1rem",
            gap: "12px",
          }}
        >
          <Button
            sx={{
              padding: "0",
              width: "1rem",
              minWidth: "0",
              height: "1rem",
              minHeight: "0",
              cursor: "pointer",
              color: item?.isLikedByMe ? "red" : "black",
            }}
            onClick={handleOnClickLikeButton}
          >
            {item?.isLikedByMe ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </Button>
          <Button
            sx={{
              padding: "0",
              width: "1.3rem",
              minWidth: "0",
              height: "1rem",
              minHeight: "0",
              cursor: "pointer",
              color: "black",
            }}
            onClick={handleOnClickCommentButton}
          >
            <CommentIcon />
          </Button>

          <Button
            sx={{
              padding: "0",
              width: "1.3rem",
              minWidth: "0",
              height: "1rem",
              minHeight: "0",
              cursor: "pointer",
            }}
            onClick={handleOnClickShareButton}
          >
            <ShareIcon />
          </Button>

          {!item?.isPostedByMe && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flex: "1",
                justifyContent: "end",
                gap: "0.5rem",
              }}
            >
              <SendTipButton onClick={handleOnClickTipMe}>
                <GiftIcon />
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: typography.font_weight.bold,
                    color: colors.white,
                    fontSize: typography.font_size.xs,
                    letterSpacing: ".02rem",
                    wordWrap: "break-word",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  Tip Me
                </Typography>
            </SendTipButton>
            </Box>
          )}

          {item?.isPostedByMe && isMyPost && (
            <EarningTextContainer>
              <EarningTitle>Earnings</EarningTitle>

              <EarningTextAndButton>
                <CoinIcon width="10" height="10" />
                <EarningText>{convertNumberToProfileCount(item?.totalEarning ?? 0)}</EarningText>
                <EarningButton onClick={handleOnClickArrowButton}>
                  <ArrowForwardIcon />
                </EarningButton>
              </EarningTextAndButton>
            </EarningTextContainer>
          )}
        </Box>
        <Box>
          {item && openTipMe ? (
            <SendTipContainer>
              <SendTip
                item={item}
                handleOnClickTipMe={handleOnClickTipMe}
                openSnackBar={openSnackBar}
              />
            </SendTipContainer>
          ) : null}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "6px",
            padding: "0 1rem",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: typography.font_weight.bold,
              color: colors.black[200],
              fontSize: typography.font_size.xs + 1,
              letterSpacing: ".02rem",
            }}
          >
            {item?.likesCount} Likes, {item?.commentsCount} Comments
          </Typography>
          {openComments && (
            <Comments
              onClose={handleOnClickCommentButton}
              postId={item?.id ?? ""}
              creatorId={item?.creator?.id ?? ""}
              userRole={userRole}
              openSnackBar={openSnackBar}
            />
          )}
        </Box>
      </Box>

      <CustomSnackbar
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        severity={snackbarSeverity}
      />
    </ListItem>
  );
};

export default React.memo(UserFeed);
