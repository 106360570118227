import React, { useEffect, useState } from "react";
import { PageTagComponent } from "../../Common/PageTagComponent";
import { Box, Button, styled, Typography } from "@mui/material";
import { colors, typography } from "../../../utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import postEarningsApi from "../../../api/creator/postEarningsApi";
import { useLocation } from "react-router-dom";
import {
  setPurchasedPostData,
  setTipsData,
} from "../../../redux/postEarningsSlice";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
const EarningsHeader = styled(Box)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${colors.white};
`;
const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
`;
const TotalEarningsContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 16px;
`;
const Title = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const Text = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const ButtonsContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
const PurchasedButton = styled(Button)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 49%;
`;
const PurchasedTitle = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const PurchasedAmountTitle = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const ContentListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 11px;
  width: 100%;
  overflow-y: auto;
  flex-grow: 1;
  padding: 16px 16px 0 16px;
  position: relative;
  overflow-x: hidden;
  align-items: center;
`;
const ContentListContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 0;
  width: 100%;
  gap: 4px;
  border-bottom: 1px solid ${colors.gray[50]};
`;
const ContentTitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const ContentTitle = styled(Typography)`
  color: ${colors.black[200]};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const ContentTitlePriceText = styled(Typography)`
  color: ${colors.gray[80]};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const Subtitle = styled(Typography)`
  color: ${colors.gray[80]};
  font-family: Poppins;
  font-size: ${typography.font_size.xxs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const EarningsAndButtonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
  width: 100%;
  z-index: 12;
  background-color: ${colors.white};
  padding: 16px 16px 0 16px;
`;

const NoPostScreenContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
  overflow: hidden;
`;

const NoPostScreen = styled(Typography)`
  color: var(--Text-Accent-2, #616161);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.24px;
  position absolute;
`;
const contentStatus = {
  TIPS: "TIPS",
  PURCHASED: "PURCHASED",
};
const Earnings = () => {
  const postInfo = useLocation();
  const { post } = postInfo.state;
  const dispatch = useDispatch<AppDispatch>();
  const { totalPurchased, totalTips, tipsData, purchasedPostData } =
    useSelector((state: RootState) => state.postEarnings);
  const [activeButton, setActiveButton] = useState(contentStatus.PURCHASED);

  const handleOnClickPurchasedButton = () => {
    setActiveButton(contentStatus.PURCHASED);
  };
  const handleOnClickTipsButton = () => {
    setActiveButton(contentStatus.TIPS);
  };
  const fetchPurchasedPostData = async () => {
    try {
      const response = await postEarningsApi.getPurchasedPostData(
        post?.id,
        post?.creator?.userId,
        "1",
        "20"
      );
      dispatch(setPurchasedPostData(response.data.data));
    } catch (error) {
      console.error(error);
    }
  };
  const fetchTipsData = async () => {
    try {
      const response = await postEarningsApi.getTipsData(
        post?.id,
        post?.creator?.userId,
        "1",
        "20"
      );
      dispatch(setTipsData(response.data.data));
    } catch (error) {
      console.error(error);
    }
  };
  const formatIsoDate = (isoDate: string) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };
  useEffect(() => {
    fetchPurchasedPostData();
    fetchTipsData();
  }, []);

  return (
    <Container>
      <EarningsHeader>
        <PageTagComponent>Post Earning</PageTagComponent>
      </EarningsHeader>
      <Content>
        <EarningsAndButtonContainer>
          <TotalEarningsContainer>
            <Title>Total Earnings</Title>
            <Text>{`cc ${totalPurchased + totalTips}`}</Text>
          </TotalEarningsContainer>
          <ButtonsContainer>
            <PurchasedButton
              onClick={handleOnClickPurchasedButton}
              sx={{
                border:
                  activeButton === contentStatus.PURCHASED
                    ? "2px solid #7262C2"
                    : "2px solid rgba(33, 33, 33, 0.10)",
              }}
            >
              <PurchasedTitle>Purchased</PurchasedTitle>
              <PurchasedAmountTitle>{`cc ${totalPurchased}`}</PurchasedAmountTitle>
            </PurchasedButton>
            <PurchasedButton
              onClick={handleOnClickTipsButton}
              sx={{
                border:
                  activeButton === contentStatus.TIPS
                    ? "2px solid #7262C2"
                    : "2px solid rgba(33, 33, 33, 0.10)",
              }}
            >
              <PurchasedTitle>Tips</PurchasedTitle>
              <PurchasedAmountTitle>{`cc ${totalTips}`}</PurchasedAmountTitle>
            </PurchasedButton>
          </ButtonsContainer>
        </EarningsAndButtonContainer>
        <ContentListContainer>
          {activeButton === contentStatus.PURCHASED ? (
            purchasedPostData?.length ? (
              purchasedPostData?.map((item, index) => (
                <ContentListContent key={index}>
                  <ContentTitleContainer>
                    <ContentTitle>
                      {item.fromUserDetails.handleName
                        ? `@${item.fromUserDetails.handleName}`
                        : ""}{" "}
                      - purchased post
                    </ContentTitle>
                    <ContentTitlePriceText>
                      +{item.amount}
                    </ContentTitlePriceText>
                  </ContentTitleContainer>
                  <Subtitle>{formatIsoDate(item.createdAt)}</Subtitle>
                </ContentListContent>
              ))
            ) : (
              <NoPostScreenContainer>
                <NoPostScreen>your posts not purchased yet.</NoPostScreen>
              </NoPostScreenContainer>
            )
          ) : null}
          {activeButton === contentStatus.TIPS ? (
            tipsData?.length ? (
              tipsData?.map((item, index) => (
                <ContentListContent key={index}>
                  <ContentTitleContainer>
                    <ContentTitle>
                      {item.fromUserDetails.handleName
                        ? `@${item.fromUserDetails.handleName}`
                        : ""}{" "}
                      - tip post
                    </ContentTitle>
                    <ContentTitlePriceText>
                      +{item.amount}
                    </ContentTitlePriceText>
                  </ContentTitleContainer>
                  <Subtitle>{formatIsoDate(item.createdAt)}</Subtitle>
                </ContentListContent>
              ))
            ) : (
              <NoPostScreenContainer>
                <NoPostScreen>No tips given yet.</NoPostScreen>
              </NoPostScreenContainer>
            )
          ) : null}
        </ContentListContainer>
      </Content>
    </Container>
  );
};

export default Earnings;
