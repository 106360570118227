import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
  mode:string,
  view:string,
  originalVideos:any,
  noOfVideos:number,
  freeSeconds:any,
  croppedAreaCoordinates : Array<{ width: number, height: number, x: number, y: number }>
}

let initialState:InitialState = {
  mode: "video",
  view:'NONE',
  originalVideos: [],
  noOfVideos:0,
  freeSeconds : [],
  croppedAreaCoordinates:[]
};

export const videoPostSlice = createSlice({
  name: "videoPost",
  initialState: initialState,
  reducers: {
   set_Video_Post_View(state,action){
        state.view = action.payload
   },

   set_No_Of_Videos(state,action){
    state.noOfVideos = action.payload;
   },

   set_Videos(state,action){
    state.originalVideos = action.payload
   },
   
   set_Cropped_Area_Coordinates(state: any, action: any) {
    if (Array.isArray(action.payload)) {
      state.croppedAreaCoordinates = action.payload;
    } else {
      state.croppedAreaCoordinates[action.payload.index] = action.payload.croppedAreaPixels;
    }
  },

  set_Free_Seconds (state:any,action:any){
    if(Array.isArray(action.payload)){
      state.freeSeconds = action.payload
    }
    else{

    }
  },

   clear_Video_Post_State(){
    return initialState
   }
  },
});

export const {
    set_Video_Post_View,
    set_No_Of_Videos,
    set_Videos,
    set_Cropped_Area_Coordinates,
    set_Free_Seconds,
    clear_Video_Post_State
} = videoPostSlice.actions;

// export const selectCurrentMode = (state: any) => state.postContent.mode;
// export const selectCurrentFiles = (state: any) => state.postContent.files;
export const currentView = (state:any) =>state.videoPost.view;

export const videoPostReducer = videoPostSlice.reducer;
