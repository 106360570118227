import React, { useEffect, useState } from "react";
import SubscriptionInputCard from "./OnDemandInputForm";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import Snackbar from "../../Common/Snackbar";
import { InitialOnDemandRate, OndemandRateType } from "../../../types";

const Container = styled(Box)`
  display: flex;
  padding: 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

interface OnDemandRateInputCardsProps {
  onDemandRate: OndemandRateType;
  onDemandRateData: InitialOnDemandRate;
  handleDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  snackbarOpen: boolean;
  snackbarMessage: string;
  handleAudioCallValueSet: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleVideoCallValueSet: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleDmValueSet: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleDeleteDirectMessage: () => void;
  handleDeleteAudioCall: () => void;
  handleDeleteVideoCall: () => void;
  handleCloseSnackbar: () => void;
}

const initialDmMessage = "Set dm rates";
const initialVideoCallMessage = "Set video call rates";
const initialAudioCallMessage = "Set audio call rates";

const audioCallInfoText =
  "Trending time which other creators offering for Audio call :";
const videoCallInfoText =
  "Trending time which other creators offering for Video call :";
const directMessageInfoText =
  "Trending rate which other creators offering for DM:";
const inputInfoText = "Set your per minute charges(CC)";
const inputFieldUnitText = "cc/min";

const minTimeText = "30min";
const maxTimeText = "240min";

const minTimeDmText = "50 cc/min";
const maxTimeDmText = "100 cc/min";

const OnDemandRateInputCards: React.FC<OnDemandRateInputCardsProps> = ({
  onDemandRate,
  onDemandRateData,
  handleDataChange,
  snackbarOpen,
  snackbarMessage,
  handleAudioCallValueSet,
  handleVideoCallValueSet,
  handleDmValueSet,
  handleDeleteDirectMessage,
  handleDeleteAudioCall,
  handleDeleteVideoCall,
  handleCloseSnackbar,
}) => {
  return (
    <Container>
      <SubscriptionInputCard
        title="Direct Message"
        subtitle={initialDmMessage}
        infoText={directMessageInfoText}
        minimumText="Minimum Charges"
        maximumText="Maximum Charges"
        minimumTime={minTimeDmText}
        maximumTime={maxTimeDmText}
        inputInfoText={inputInfoText}
        inputFieldUnitText={inputFieldUnitText}
        name="directMessageYc"
        value={onDemandRateData?.directMessageYc}
        onValueChange={handleDataChange}
        onValueSet={handleDmValueSet}
        onDelete={handleDeleteDirectMessage}
        stateValue={onDemandRate?.directMessageYc}
      />

      <SubscriptionInputCard
        title="Audio call"
        subtitle={initialAudioCallMessage}
        infoText={audioCallInfoText}
        minimumText="Minimum time"
        maximumText="Maximum time"
        minimumTime={minTimeText}
        maximumTime={maxTimeText}
        inputInfoText={inputInfoText}
        inputFieldUnitText={inputFieldUnitText}
        name="audioCallYc"
        value={onDemandRateData?.audioCallYc}
        onValueChange={handleDataChange}
        onValueSet={handleAudioCallValueSet}
        onDelete={handleDeleteAudioCall}
        stateValue={onDemandRate?.audioCallYc}
      />

      <SubscriptionInputCard
        title="Video call"
        subtitle={initialVideoCallMessage}
        infoText={videoCallInfoText}
        minimumText="Minimum time"
        maximumText="Maximum time"
        minimumTime={minTimeText}
        maximumTime={maxTimeText}
        inputInfoText={inputInfoText}
        inputFieldUnitText={inputFieldUnitText}
        name="videoCallYc"
        value={onDemandRateData?.videoCallYc}
        onValueChange={handleDataChange}
        onValueSet={handleVideoCallValueSet}
        onDelete={handleDeleteVideoCall}
        stateValue={onDemandRate?.videoCallYc}
      />

      <Snackbar
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
      />
    </Container>
  );
};

export default OnDemandRateInputCards;
