import { Box, Button, Typography, styled } from "@mui/material";
import React from "react";
import { colors, typography } from "../../../utils/styles";
import { BackButton, CancelIcon, CancelRedIcon } from "../../../assets/SvgIcons";
import { useModal } from "../../../context/ModalContext";
import DiscardSubscriptionModal from "../../../modals/DiscardSubscriptionModal/DiscardSubscriptionModal";

const Header = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  padding: 16px;
  background: ${colors.purple[50]};
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const HeaderContent = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HeaderTitleText = styled(Typography)`
  color: #212121;
  font-size: 16px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: 0.24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  flex: 1 0 0;
`;

interface CreateSubscriptionHeaderProps {
  title: string;
  cancelTextTitle: string;
  cancelTextSubtitle: string;
  handleOnClickGoBackButton: () => void;
}

const CreateSubscriptionHeader: React.FC<CreateSubscriptionHeaderProps> = ({
  title,
  handleOnClickGoBackButton,
}) => {
  const {requestOpenModal, requestCloseModal}=useModal();
  return (
    <Header>
      <HeaderContent>
        <HeaderTitleText>{title}</HeaderTitleText>
        <Button
          onClick={handleOnClickGoBackButton}
          sx={{ minWidth: "0", padding: "0" }}
        >
          <CancelIcon />
        </Button>
      </HeaderContent>
    </Header>
  );
};

export default CreateSubscriptionHeader;
