import React, { useState, useEffect } from "react";
import { Box, Button, Divider, styled, Typography } from "@mui/material";
import { PageTagComponentWithMenu } from "../../Common/PageTagComponentWithMenu";
import {typography, colors } from "../../../utils/styles";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../context/ModalContext";
import EditAvailabilityInfo from "../../../modals/EditAvailabilityInfo/EditAvailabilityInfo";
import { setUnAvailability, setWeeklyAvailability } from "../../../redux/bookingSlice";
import UnAvailabilityInfo from "../../../modals/UnAvailabilityInfo/UnAvailabilityInfo";
import bookingServiceApi from "../../../api/creator/bookingServiceApi";
import { PageTagComponent } from "../../Common/PageTagComponent";
const Container = styled(Box)`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const NoBookingsContainer=styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const NoBookingsContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 265px;
  height: 312px;
  gap: 11px;
`;
const Image = styled("img")``;
const Title = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
`;
const Subtitle = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
  width: 82%;
`;
const SetAvailabiltyButton = styled(Box)`
  display: flex;
  width: 233px;
  height: 56px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: ${colors.violet[100]}; 
  color: ${colors.white}; 
  text-align: center;
  text-overflow: ellipsis;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const SetAvailabilityHeader = styled(Box)`
  position: relative;
`;
const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  overflow: hidden;
`;
const ImageAndTextContainer = styled(Box)`
  display: flex;
  padding-bottom: 16px;
  align-items: center;
  width: 100%;
  padding: 0px 17px;
`;
const Text = styled(Box)`
  color: ${colors.gray[80]};
  width: 265px;
  font-size: ${typography.font_size.sm}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 78vh;
`;
const TimeSlotContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
const ShowTimeSlotsContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 17px;
`;
const BottomContainer = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
const SetAvailabilityButton = styled(Button)`
  width: 100%;
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  background: ${colors.violet[100]};
  color: ${colors.white};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  border-radius: 0px;
  &:hover {
    background: ${colors.violet[100]};
  }
`;
const TimeContainer = styled(Box)`
display: flex;
width: 195px;
height: 33px;
justify-content: center;
align-items: center;
border-radius: 4px;
border: 1px solid var(--Border-Strong, rgba(33, 33, 33, 0.20));
background: #FFF;
`;
const TimeText = styled(Typography)`
color: ${colors.gray[80]};
text-align: center;
font-size: ${typography.font_size.base}px;
font-style: normal;
font-weight: ${typography.font_weight.regular};
line-height: ${typography.line_height.default};
letter-spacing: ${typography.letter_spacing.default};
`;
const SetUnAvailabilityContainer = styled(Box)`
  position: absolute;
  right: 30px;
  top: 40px;
  display: flex;
  border-radius: 12px;
  background: ${colors.white};
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  padding: 16px;
  align-items: center;
`;
const SetUnAvailabilityText = styled(Typography)<{
  isUnAvailableToday: boolean;
}>`
  color: ${(props) =>
    props.isUnAvailableToday ? colors.red[200] : colors.black[200]};
  font-size: ${typography.font_size.sm}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const TimeSlotNameConatiner = styled(Box)`
  align-self: flex-start;
  justify-self: flex-start;
`;
const TimeSlotNameText = styled(Typography)`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.sm}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const TimeSlotInputContainer = styled(Box)`
  display: flex;
  align-items: center;
  align-self: flex-end;
`;
const SlotsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
`;

const NoSlotsText=styled(Typography)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    color: ${colors.gray[80]};
    white-space: nowrap;
    font-style: italic;
    font-size: ${typography.font_size.xs}px;
    font-weight: ${typography.font_weight.regular};
    line-height: ${typography.line_height.default};
    letter-spacing: ${typography.letter_spacing.regular};
`;

interface CheckCreatorsAvailabilityProps {}
const weekDayNumbers: any = {
  0: "MON",
  1: "TUE",
  2: "WED",
  3: "THUR",
  4: "FRI",
  5: "SAT",
  6: "SUN",
};
const TimelineContainer: React.FC<{ startTime: string, endTime:string }> = ({ startTime, endTime }) => {
  return (
    <TimeContainer>
      <TimeText>{startTime}&nbsp;&nbsp;TO&nbsp;&nbsp;{endTime}</TimeText>
    </TimeContainer>
  );
};
const CheckCreatorsAvailability: React.FC<
  CheckCreatorsAvailabilityProps
> = ({}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { weeklyAvailability, unavailability } = useSelector(
    (state: RootState) => state.bookings
  );
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { requestOpenModal, requestCloseModal } = useModal();
  const handleOnConfirmButtonClick = () => {
    navigate("/creator/bookings/set-availability");
  };
  const handleOnClickSetAvailability=()=>{
    navigate("/creator/bookings/set-availability")
}
  const handleOnClickEditAvailabilityButton = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    requestOpenModal({
      name: "Edit Availability Info Modal",
      component: (
        <EditAvailabilityInfo
          requestOpenModal={requestOpenModal}
          requestCloseModal={requestCloseModal}
          handleOnConfirm={handleOnConfirmButtonClick}
        />
      ),
    });
  };
  const handleOnClickMenuIcon = () => {
    setOpenModal(!openModal);
  };
  const handleSetUnAvailabilityForToday = () => {
    dispatch(setUnAvailability(!unavailability));
  };
  const handleOnClickUnAvailability = (e: React.MouseEvent<HTMLDivElement>) => {
    requestOpenModal({
      name: "Unavailability Modal",
      component: (
        <UnAvailabilityInfo
          requestOpenModal={requestOpenModal}
          requestCloseModal={requestCloseModal}
          handleOnConfirm={handleSetUnAvailabilityForToday}
        />
      ),
    });
  };
  const fetchWeeklyAvailability = async () => {
    try{
    const response= await bookingServiceApi.getWeeklyAvailability();
    dispatch(setWeeklyAvailability(response.data.data));
    }
    catch(error){
      console.error("Error fetching weekly availability", error);
    }
  }

  useEffect(() => {
    if(weeklyAvailability?.every(it=>!it.checked)){
      fetchWeeklyAvailability();
    }
  }, [dispatch])
  return (
    <Container>
      <SetAvailabilityHeader>
      {(weeklyAvailability?.length===0 || weeklyAvailability.every(slots=>!slots.checked)) ?(
        <PageTagComponent>
            Availability
        </PageTagComponent>
      ):(
        <>
        <PageTagComponentWithMenu onMenuIconClick={handleOnClickMenuIcon}>
          Availability
        </PageTagComponentWithMenu>
        {openModal && (
          <SetUnAvailabilityContainer onClick={handleOnClickUnAvailability}>
            <SetUnAvailabilityText isUnAvailableToday={unavailability}>
              {unavailability
                ? "Marked unavailable for today"
                : "Set unavailability for today"}
            </SetUnAvailabilityText>
          </SetUnAvailabilityContainer>
          
        )}
        </>
      )}
      </SetAvailabilityHeader>
      <Content>
        <ImageAndTextContainer>
          <Text>
            Your Calendar for weekly availability for slot bookings.
          </Text>
        </ImageAndTextContainer>
        <ContentContainer>
        <TimeSlotContainer>
        {weeklyAvailability?.slice().sort((a, b) => a.day - b.day)?.map((item, index) => {
          return (
            <>
            <ShowTimeSlotsContainer key={index}>
              <TimeSlotNameConatiner>
                <TimeSlotNameText>{weekDayNumbers[item?.day]}</TimeSlotNameText>
              </TimeSlotNameConatiner>

              <SlotsContainer>
              {item.slots?.length===0 ? (
                  <NoSlotsText>Unavailable</NoSlotsText>
              ):
              <>
              {item.slots?.map((slot, slotIndex) => (
              <TimeSlotInputContainer>
                <TimelineContainer startTime={slot?.from} endTime={slot?.to}/>
              </TimeSlotInputContainer>
              )
              )}
              </>
              }
              </SlotsContainer>
            </ShowTimeSlotsContainer>
              <Divider sx={{width: "100%", margin: "8px 0"}}/>
            </>
          )})}
      </TimeSlotContainer>
      </ContentContainer>
      <BottomContainer>
          <SetAvailabilityButton onClick={handleOnClickEditAvailabilityButton}>
            Edit Availability
          </SetAvailabilityButton>
        </BottomContainer>
      </Content>
    </Container>
  );
};

export default CheckCreatorsAvailability;