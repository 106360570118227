import React from "react";
import {
    Box,
    Button,
    styled,
    Typography,
    Avatar,
  } from "@mui/material";
import { typography, colors } from "../../../../utils/styles";

const ConfirmedBookingsItemsContainer = styled(Box)`
  overflow-y: scroll;
  width: 100%;
  position: relative;
`;
const Container = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  border-bottom: 1px solid ${colors.gray[200]};
  background: ${colors.white};
  flex-direction: column;
`;
const MonthContainer = styled(Box)`
  display: flex;
  height: 22px;
  align-items: center;
  width: 100%;
  background-color: ${colors.gray[300]};
  padding-left: 16px;
  padding-top: 6px;
`;
const MonthTitle = styled(Typography)`
  color: ${colors.gray[80]};
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
`;
const ContentContainer = styled(Box)`
  display: grid;
  grid-template-columns: 0.5fr 4fr 1fr;
  grid-template-rows: auto auto;
  column-gap: 3px;
  padding: 16px 0px 0px 16px;
  width: 100%;
`;
const ImageContainer = styled(Box)`
  grid-column: 1 / 2;
`;
const ContentMainSection = styled(Box)`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  padding-bottom: 16px;
`;
const TitleAndHandleName = styled(Box)`
  display: flex;
  align-items: center;
`;
const Title = styled(Typography)`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    width: 43%;
`;
const HandleName = styled(Typography)`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const IncomingOutgoingMessage = styled(Typography)`
  color: ${colors.red[200]};
  font-size: ${typography.font_size.xxs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
  display: flex;
  height: 16px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  background: ${colors.red[50]};
  margin-left: 5px;
`;
const SecondLineContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const TypeContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const TypeText = styled(Typography)`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const DurationContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const DurationText = styled(Typography)`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const PriceContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const PriceIcon = styled(Box)`
  color: ${colors.black[200]};
  text-align: center;
  font-size: ${typography.font_size.sm}px;
  font-weight: ${typography.font_weight.regular};
`;
const PriceText = styled(Typography)`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const ThirdLineContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const DateContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const DateText = styled(Typography)`
  color: ${colors.gray[80]};
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const TimeContainer = styled(Box)`
  display: flex;
  align-items: center;
`;
const TimeText = styled(Typography)`
  color: ${colors.gray[80]};
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const FourthLineContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const ViewDetailsText = styled(Typography)`
  color: ${colors.sky[300]};
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const ConfirmedText = styled(Typography)`
  color: ${colors.green[200]};
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const BookingDetailsContainer = styled(Box)<{ open: boolean }>`
  grid-column: 2 / 4;
  display: flex;
  padding: ${({ open }) => (open ? "8px 16px 8px 0px" : "0 16px 0 0")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
    border-top: 1px solid rgba(33, 33, 33, 0.1);
  flex-shrink: 0;
  height: ${({ open }) => (open ? "118px" : "0px")};
  overflow: hidden;
  transition: height 0.5s ease-in-out;
`;
const BookingDetailsTitle = styled(Typography)`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
`;
const ChargesTextContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ChargesText = styled(Typography)`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const ChargesValue = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const ServiceTypeContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ServiceTypeText = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const ServiceTypeValue = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const RescheduleButtonContainer = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const RescheduleButton = styled(Button)`
overflow: hidden;
color: ${colors.orange[300]};
text-align: center;
text-overflow: ellipsis;
font-size: ${typography.font_size.xs}px;
font-weight: ${typography.font_weight.regular};
line-height: ${typography.line_height.default};
letter-spacing: ${typography.letter_spacing.normal};
text-decoration-line: underline;
padding: 0;
`;
const JoinButtonContainer = styled(Box)`
grid-column: 3 / 4;
position: relative;
`;
const JoinButton = styled(Button)`
display: flex;
height: 32px;
width: 71px;
padding: 4px 16px;
justify-content: center;
align-items: center;
flex: 1 0 0;
color: ${colors.white}; 
text-align: center;
border-radius: 8px;
border: 1px solid ${colors.violet[100]}; 
background: ${colors.violet[100]}; 
font-size: ${typography.font_size.xs}px; 
font-weight: ${typography.font_weight.bold};
line-height: ${typography.line_height.default}; 
letter-spacing: ${typography.letter_spacing.normal};
position: absolute;
right: 8px;
`;
const BookingInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
const ActionRequiredContainer=styled(Box)`
display: flex;
width: 375px;
padding: 8px 4px;
align-items: center;
gap: 4px;
background: ${colors.orange[400]};
width: 100%;
`;
const ActionRequiredTextContainer=styled(Box)`
    display: flex;
    align-items: center;
`;
const ActionRequiredSubText=styled(Typography)`
color: ${colors.orange[300]};
font-size: ${typography.font_size.xs}px; 
font-weight: ${typography.font_weight.regular};
line-height: ${typography.line_height.default}; 
letter-spacing: ${typography.letter_spacing.normal};
font-style: normal;
`;
const PendingContentContainer = styled(Box)<{textSetup:boolean}>`
    display: grid;
    grid-template-columns: ${({ textSetup }) => (!textSetup ? "0.5fr 6fr .9fr" : "0.5fr 5fr 1fr")};
    grid-template-rows: auto auto;        
    column-gap: 8px;                            
    padding: 16px 0px 0px 16px;
    width: 100%;
`;
const AcceptButtonContainer=styled(Box)`
  grid-column: 3 / 4;
  padding-right: 8px;
`;
const AcceptButton = styled(Button)`
display: flex;
height: 32px;
padding: 4px 16px;
justify-content: center;
align-items: center;
flex: 1 0 0;
border-radius: 8px;
color: var(--Text-Positive, #2E7D32);
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 135%; 
letter-spacing: 0.18px;
padding: 0;
min-width: 0px;
`;
const WarningContainer=styled(Box)`
`;
const RowContainer=styled(Box)`
`;
const CancelBookingButton=styled(Button)`
overflow: hidden;
color: ${colors.red[300]};
font-size: ${typography.font_size.xs}px; 
font-weight: ${typography.font_weight.regular};
line-height: ${typography.line_height.default}; 
letter-spacing: ${typography.letter_spacing.normal};
text-align: center;
text-overflow: ellipsis;
font-style: normal;
text-decoration-line: underline;
`
const AcceptIconAndText=styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
const CancelIconAndText=styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-left: 8px;
  cursor:pointer;
`;
const CancelText=styled(Typography)`
color: ${colors.red[300]};
font-size: ${typography.font_size.xs}px; 
font-weight: ${typography.font_weight.bold};
line-height: ${typography.line_height.default}; 
letter-spacing: ${typography.letter_spacing.normal};
text-align: center;
font-style: normal;
`;
export {
  ConfirmedBookingsItemsContainer,
  Container,
  MonthContainer,
  MonthTitle,
  ContentContainer,
  ImageContainer,
  ContentMainSection,
  TitleAndHandleName,
  Title,
  HandleName,
  IncomingOutgoingMessage,
  SecondLineContainer,
  TypeContainer,
  TypeText,
  DurationContainer,
  DurationText,
  PriceContainer,
  PriceIcon,
  PriceText,
  ThirdLineContainer,
  DateContainer,
  DateText,
  TimeContainer,
  TimeText,
  FourthLineContainer,
  ViewDetailsText,
  ConfirmedText,
  BookingDetailsContainer,
  BookingDetailsTitle,
  ChargesTextContainer,
  ChargesText,
  ChargesValue,
  ServiceTypeContainer,
  ServiceTypeText,
  ServiceTypeValue,
  RescheduleButtonContainer,
  RescheduleButton,
  JoinButtonContainer,
  JoinButton,
  BookingInfoContainer,
  Avatar,
  ActionRequiredContainer,
  ActionRequiredTextContainer,
  ActionRequiredSubText,
  PendingContentContainer,
  AcceptButtonContainer,
  AcceptButton,
  WarningContainer,
  RowContainer,
  CancelBookingButton,
  AcceptIconAndText,
  CancelIconAndText,
  CancelText,
  styled, 
  Box
};
