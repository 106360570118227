import React from "react";
import CreatorsFollowers from "../../../components/Creator/Followers/Followers";

interface FollowersPageProps {
  isOwn: boolean;
}

const FollowersPage: React.FC<FollowersPageProps> = ({ isOwn }) => {
  return (
    <>
      <CreatorsFollowers isOwn={isOwn} />
    </>
  );
};

export default FollowersPage;
