import { ApiService } from '../ApiClient';
import {creatorAxiosInstance} from '../axiosInstance';
import '../interceptors/creatorAuthInterceptors';

class UerFeedsServiceApi extends ApiService {
   
    constructor() {
      super(creatorAxiosInstance)
    }
     getHomeFeeds( page:string, pageSize: string) {
       return this.axios.get(`content/post/feed?status=PROCESSED&page=${page}&pageSize=${pageSize}`);
      }
    
    likePost(postBody:any) {
        return this.axios.post(`content/likes`, JSON.stringify(postBody));
      }
    
    getAllComments(postId: string, page: string, pageSize: string) {
        return this.axios.get(`content/comments?postId=${postId}&page=${page}&pageSize=${pageSize}`);
    }
    addComment(body: any) {
        return this.axios.post(`content/comments`, JSON.stringify(body));   
    }
    updateComment(postId: string, body: any) {
       return this.axios.patch(`content/comments/${postId}`, JSON.stringify(body));
    }
    deleteComment(postId: string) {
      return  this.axios.delete(`content/comments/${postId}`);
    }

    getPostById(postId: string) {
     
        return  this.axios.get(`content/post/${postId}`);
    }

    deletePostById(postId: string){
      return  this.axios.delete(`content/post/${postId}`);
    }


    updatePostById(postId: string, data:any){
      return  this.axios.put(`content/post/${postId}`, JSON.stringify(data));
    }
  }
  export default new UerFeedsServiceApi();
  