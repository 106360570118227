import React, { useEffect, useState } from 'react'
import { Avatar, Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
// import SearchTextInput from '../Common/SearchTextInput';
import moment from 'moment';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModal } from '../../context/ModalContext';
import user from '../../api/messaging/usermsgService';
import NoMessages from '../../components/Messages/NoMessages';
import creatormsgService from '../../api/messaging/creatormsgService';
import usermsgService from '../../api/messaging/usermsgService';
// import JoinCall from '../../modals/JoinCall/JoinCall';


const Container = styled(Box)`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
gap: 16px;
flex-shrink: 0;
`;

const ContentContainer = styled(Box)`
width: 100%;
`;

const MessageHeader = styled(Box)`
width: 100%;
`;

const Content = styled(Box)`
width: 100%;
`;

const NoMessageContainer = styled(Box)`
width: 100%;
`;

const SearchBarContainer = styled(Box)`
display: flex;
padding: 8px 16px;
align-items: center;
width: 100%;
`;

const MessageItemContainer = styled(Box)`
display: flex;
padding: 8px 16px;
align-items: center;
gap: 8px;
align-self: stretch;
`;

const NameAndMessageContainer = styled(Box)`
    flex:1;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const NameAndHandleContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 2px;
`;

const NameText = styled(Typography)`
overflow: hidden;
color: var(--Text-Primary, #212121);
text-overflow: ellipsis;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
/* Heading/H6-B */
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 16.2px */
letter-spacing: 0.18px;
width: min-content;

`;
const HandleName = styled(Typography)`
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
overflow: hidden;
color: var(--Text-Secodary, #616161);
text-overflow: ellipsis;

/* Body/S-B */
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 13.5px */
`;
const MessageContainer = styled(Box)`


`;
const MessageText = styled(Typography)`
overflow: hidden;
color: var(--Text-Secodary, #616161);
text-overflow: ellipsis;
white-space: nowrap;

/* Body/S-B */
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 13.5px */
`

const TimeDurationContainer = styled(Box)`
`;

const TimeDuration = styled(Box)`
overflow: hidden;
color: var(--Text-Secodary, #616161);
text-overflow: ellipsis;
white-space: nowrap;

/* Body/XXS */
font-family: Poppins;
font-size: 6px;
font-style: normal;
font-weight: 700;
line-height: 100%; /* 6px */
`;

type MessageType = {
    message: string,
    displayName: string,
    handleName: string,
    time: string,
    creatorId: string;
    profilePicUrl: string;
    latestMessage: string;
    latestTimestamp: string;
    profilePhotoUrl: string;
    toUser: string;
    userId: string;
}

const MessageItem: React.FC<{ Message: MessageType, handleOpenMessage: (message?: MessageType) => void }> = ({
    Message,
    handleOpenMessage
}) => {

    const [message, setMessage] = useState<MessageType>();
    useEffect(() => {
        if (Message) {
            setMessage(Message);
        }
    }, [Message])



    const TimeDurationFromNow = (time?: string) => {
        const utcTime = moment.utc(time);
        const localTime = moment(new Date()).local()
        const timeFromNow = utcTime.local().from(moment(new Date()).local());
        return timeFromNow
    }
    return (
        <MessageItemContainer onClick={() => handleOpenMessage(message)}>
            <Avatar src={message?.profilePhotoUrl} sx={{ width: 40, height: 40 }} />
            <NameAndMessageContainer>
                <NameAndHandleContainer>
                    <NameText>{message?.displayName}</NameText>
                    <HandleName>@{message?.handleName}</HandleName>
                </NameAndHandleContainer>
                <MessageContainer>
                    <MessageText>{message?.latestMessage}</MessageText>
                </MessageContainer>
            </NameAndMessageContainer>
            <TimeDurationContainer>
                <TimeDuration>{TimeDurationFromNow(message?.latestTimestamp)}</TimeDuration>
            </TimeDurationContainer>
        </MessageItemContainer>
    )
}
const Messages = () => {
    const [searchData, setSearchData] = useState<string>("");
    const [chatThreads, setChatThreads] = useState([]);
    const location = useLocation();
    const userRole = location.pathname.includes("creator") ? "creator" : "user";
    const navigate = useNavigate();
    const { requestOpenModal, requestCloseModal } = useModal()
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchData(value);
    };

    const handleOpenMessage = (chat?: MessageType) => {
        if (chat) {
            navigate(`/${userRole}/messages/read-message/${chat.toUser}`,{state:{chatData:chat}});
        }
    }
    useEffect(() => {
       location.pathname.includes('user')?  usermsgService.fetchChat().then(data => {
        console.log(data.data.data.data)
        setChatThreads(data.data.data.data)
    }):
    creatormsgService.fetchChat().then(data => {
      console.log(data.data.data.data)
      setChatThreads(data.data.data.data)
  })
    }, [])
    return (
        <Container>
            <MessageHeader>
                {/* <PageTagComponent>Messages</PageTagComponent> */}
            </MessageHeader>
            <ContentContainer>
                {
                    chatThreads.length > 0 &&
                    (
                        <>
                            <Content>
                                {chatThreads?.map((item: any) => (
                                    <MessageItem
                                        Message={item}
                                        handleOpenMessage={()=>handleOpenMessage(item)}
                                    />
                                ))}

                            </Content>
                        </>
                    )
                }
                {
                    chatThreads.length===0 && (
                        <NoMessageContainer>
                            <NoMessages/>
                        </NoMessageContainer>
                    )
                }
            </ContentContainer>
        </Container>
    )
}

export default Messages