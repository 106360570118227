import React, { useState, useEffect, useRef, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import Hls from "hls.js";
import {
  Box,
  styled,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { colors, typography } from "../../../utils/styles";
import { DollarCaseIcon } from "../../../assets/SvgIcons";
import { useVideo } from "../../../context/VideoContext";

interface Item {
  id: number;
  img: string;
  name: string;
  handle: string;
  title: string;
  description: string;
  likes: number;
  comments: boolean;
  totalComments: number;
  user_type: string;
  video: string;
  tips: string;
  userImg: string;
  liked: boolean;
  images: string[];
  postTypeId: number;
}

interface VideoPostTwoProps {
  item: Item;
}

const Container = styled(Box)`
  position: relative;
`;

const LockedContentScreenContainer = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(84, 71, 150, 1) 0%,
    rgba(84, 71, 150, 0.38) 100%
  );
  z-index: 9;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LockedContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const LockedButtonContainer = styled(Box)``;

const LockedButton = styled(Button)`
  display: flex;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #7262c2;
  background: #7262c2;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: 0.18px;
`;

const Text = styled(Typography)`
  color: #212121;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: 0.18px;
`;

const VideoPost: React.FC<VideoPostTwoProps> = ({ item }) => {
  const { playVideo, mute, toggleMute } = useVideo();
  const [isPlaying, setIsPlaying] = useState(false);
  const [manualPause, setManualPause] = useState(false);
  const [opacity, setOpacity] = useState(false);
  const [loading, setLoading] = useState(true);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const hls = useRef<Hls | null>(null);
  const { ref, inView } = useInView({
    threshold: 0.85,
    triggerOnce: false,
  });

  const handlePlayPause = useCallback(() => {
    if (item.postTypeId === 1) {
      return;
    }
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video
          .play()
          .then(() => {
            setIsPlaying(true);
            setManualPause(false);
          })
          .catch((error) => {
            console.error("Play error:", error);
          });
      } else {
        video.pause();
        setIsPlaying(false);
        setManualPause(true);
      }
      setOpacity(true);
      setTimeout(() => {
        setOpacity(false);
      }, 2000);
    }
  }, [item.postTypeId]);


  const handleMuteIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    toggleMute();
  }

  useEffect(() => {
    const video = videoRef.current;

    if (item && video) {
      if (Hls.isSupported()) {
        hls.current = new Hls();
        hls.current.loadSource(item.video);
        hls.current.attachMedia(video);
        hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
          setLoading(false);
        });
        hls.current.on(Hls.Events.ERROR, (event, data) => {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                hls.current?.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                hls.current?.recoverMediaError();
                break;
              default:
                hls.current?.destroy();
                break;
            }
          }
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = item.video;
        video.addEventListener("loadedmetadata", () => {
          setLoading(false);
        });
      }
    }

    return () => {
      hls.current?.destroy();
    };
  }, [item]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.muted = mute;
      if (inView && !manualPause) {
        setTimeout(() => {
          if (item.postTypeId !== 1) {
            playVideo(video);
            video
              .play()
              .then(() => {
                setIsPlaying(true);
              })
              .catch((error) => console.error("Play error:", error));
          }
        }, 1000);
      } else if (!inView) {
        video.pause();
        setIsPlaying(false);
      }
    }
  }, [inView, playVideo, mute, manualPause, item.postTypeId]);

  return (
    <Container className="video_container" onClick={handlePlayPause} ref={ref}>
      <Box>
        {loading && <CircularProgress />}
        <video
          ref={videoRef}
          muted={mute}
          width="100%"
          height="100%"
          playsInline
          style={{
            display: loading ? "none" : "block",
            opacity: item.postTypeId === 1 ? "0" : "1",
            maxHeight: "72vh",
            objectFit: "contain",
          }}
          preload="load"
        />
      </Box>
      <button
        className="play_pause_button"
        style={{ opacity: opacity ? "1" : "0" }}
      >
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </button>
      <button onClick={handleMuteIconClick}
        className="speaker_icon_button"
        style={{ background: colors.gray[500] }}
      >
        {mute ? (
          <VolumeOffIcon sx={{ width: ".8em", height: ".8em" }} />
        ) : (
          <VolumeUpIcon sx={{ width: ".8em", height: ".8em" }} />
        )}
      </button>
      {item.postTypeId === 1 && (
        <LockedContentScreenContainer>
          <LockedContent>
            <LockedButtonContainer>
              <LockedButton>
                <DollarCaseIcon />
                Unlock
              </LockedButton>
            </LockedButtonContainer>
            <Text>1 Video (0.33) for Rs.XX</Text>
          </LockedContent>
        </LockedContentScreenContainer>
      )}
    </Container>
  );
};

export default VideoPost;
