import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider, styled } from "@mui/material";
import { useInView } from 'react-intersection-observer';
import { typography, colors } from '../../utils/styles';
import { DropDownIcon } from '../../assets/SvgIcons';
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  position: relative;
`;
const TimeDisplayContainer = styled(Box)`
  display: flex;
  width: 94px;
  height: 32px;
  padding: 4px 8px;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid ${colors.gray[80]};
  background: ${colors.white};
  justify-content: space-evenly;
`;
const DropdownContainer = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const TimeDisplay = styled(Box)`
  color: ${colors.title};
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const TimeSelectorAndButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${colors.gray[300]};
  border-radius: 5px;
  background: ${colors.white};
  flex-shrink: 0;
  position: absolute;
  top: 38px;
  z-index: 999;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;
const ButtonsContainer = styled(Box)`
  border-radius: 0px 0px 4px 4px;
  background: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px 8px 8px;
`;
const CancelButton = styled(Box)`
  color: ${colors.gray[80]};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
  display: flex;
  height: 32px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
`;
const OkayButton = styled(Box)`
  display: flex;
  height: 32px;
  width: 52px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.violet[100]};
  background: ${colors.violet[100]};
  color: ${colors.white};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const TimeSelectorContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 8px 8px 0 8px;
  gap: 4px
`;
const ScrollContainer = styled(Box)`
  width: 50%;
  height: 200px;
  overflow-y: scroll;
`;
const HoursScroll = styled(Box)`
  text-align: center;
`;
const HourOption = styled(Box)<{ selected: boolean }>`
  justify-content: center;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  background: ${({ selected }) => (selected ? colors.purple[50] : "transparent")};
  color: ${({ selected }) => (selected ? colors.black[200] : colors.gray[80])};
  border-radius: 3px;
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${({ selected }) => (selected ? typography.font_weight.bold : typography.font_weight.regular)};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const PeriodSelect = styled(Box)`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const PeriodOption = styled(Box)<{ selected: boolean }>`
  justify-content: center;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  background: ${({ selected }) => (selected ? colors.purple[50] : "transparent")};
  color: ${({ selected }) => (selected ? colors.black[200] : colors.gray[80])};
  border-radius: 3px;
  margin-bottom: 5px;
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${({ selected }) => (selected ? typography.font_weight.bold : typography.font_weight.regular)};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
interface TimePickerInputProps {
  value: string;
  handleChangeTime?: (time: string) => void;
  handleChangeSlotTime?: (time: string, index: number, slotIndex: number) => void;
  index?: number;
  slotIndex?: number,
}
const TimePickerInput: React.FC<TimePickerInputProps> = ({ value, handleChangeTime, handleChangeSlotTime, index, slotIndex }) => {
  const [selectedTime, setSelectedTime] = useState<number | null>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const { ref: dropdownRef, inView } = useInView();
  const [displayTime, setDisplayTime] = useState<string | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const menuIconRef = useRef<HTMLDivElement | null>(null);
  const hours = Array.from({ length: 12 }, (_, index) => index + 1);
  const extendedHours = [...hours, ...hours, ...hours]; 
  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const scrollTop = scrollContainerRef.current.scrollTop;
      const itemHeight = 40; 
      const middleIndex = hours.length;
      const itemsInView = Math.round(scrollTop / itemHeight);
      const actualHour = extendedHours[itemsInView % hours.length];
      let newPeriod = selectedPeriod;
      if (actualHour === 12 && selectedTime === 1) {
        newPeriod = selectedPeriod === "AM" ? "PM" : "AM";
      } else if (actualHour === 1 && selectedTime === 12) {
        newPeriod = selectedPeriod === "AM" ? "PM" : "AM";
      }
      setSelectedPeriod(newPeriod);
      if (itemsInView < middleIndex || itemsInView > middleIndex * 2) {
        scrollContainerRef.current.scrollTop = middleIndex * itemHeight;
      }
    }
  };
  const handleSelectHour = (e: React.MouseEvent<HTMLDivElement>) => {
    let hour=e.currentTarget.textContent;
    if (!hour) return;
    const newDisplayTime = `${hour || 0}:00 ${selectedPeriod || 'AM'}`; // Fallback to 0 and 'AM'
    setSelectedTime(Number(hour));
    setDisplayTime(newDisplayTime);
    if (typeof handleChangeSlotTime === "function" && index !== undefined && slotIndex !== undefined) {
      handleChangeSlotTime(newDisplayTime, index, slotIndex); 
    } else if (typeof handleChangeTime === "function") {
      handleChangeTime(newDisplayTime);
    }
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  const handleSelectMeridian=(meridian: string)=>{
    const newDisplayTime = `${selectedTime || 0}:00 ${meridian || 'AM'}`; // Fallback to 0 and 'AM'
    setSelectedPeriod(meridian);
    setDisplayTime(newDisplayTime);
    if (typeof handleChangeSlotTime === "function" && index !== undefined && slotIndex !== undefined) {
      handleChangeSlotTime(newDisplayTime, index, slotIndex); 
    } else if (typeof handleChangeTime === "function") {
      handleChangeTime(newDisplayTime);
    }
    
    setIsDropdownOpen(false);
  }
  const handleOnClickOkayButton=()=>{
    setIsDropdownOpen(false);
    if(!displayTime) return;
    if (typeof handleChangeSlotTime === "function" && index !== undefined && slotIndex !== undefined) {
      handleChangeSlotTime(displayTime, index, slotIndex); 
    } else if (typeof handleChangeTime === "function") {
      handleChangeTime(displayTime);
    }
  }
  useEffect(() => {
    if (value) {
      const [timePart, period] = value.split(" ");
      const hour = Number(timePart.split(":")[0]);
      const displayTimeStr = `${hour || 0}:00 ${period || 'AM'}`; // Fallback to 0 and 'AM'
      setSelectedTime(hour);
      setSelectedPeriod(period);
      setDisplayTime(displayTimeStr);
    } else {
      setSelectedTime(null);
      setSelectedPeriod(null);
    }
  }, [value]);

  useEffect(() => {
    // Close dropdown when it goes out of view
    if (!inView) {
      closeDropdown();
    }
  }, [inView]);
  useEffect(() => {
    if (scrollContainerRef.current) {
      const middleIndex = hours.length;
      scrollContainerRef.current.scrollTop = middleIndex * 40; 
    }
  }, [scrollContainerRef]);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        menuIconRef.current &&
        !menuIconRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    }
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <Container>
      <TimeDisplayContainer>
        <TimeDisplay>{displayTime}</TimeDisplay>
        <DropdownContainer onClick={toggleDropdown} ref={menuIconRef}>
          <DropDownIcon/>
        </DropdownContainer>
      </TimeDisplayContainer>

      {isDropdownOpen && (
        <TimeSelectorAndButtonsContainer ref={modalRef}>
        <TimeSelectorContainer ref={dropdownRef}>
          <ScrollContainer ref={scrollContainerRef} onScroll={handleScroll}>
            <HoursScroll>
              {extendedHours.map((hour, index) => (
                <HourOption
                  key={index}
                  selected={selectedTime === hour}
                  onClick={handleSelectHour}
                >
                  {hour}
                </HourOption>
              ))}
            </HoursScroll>
          </ScrollContainer>
          <PeriodSelect>
            {['AM', 'PM'].map((period) => (
              <PeriodOption
                key={period}
                selected={selectedPeriod === period}
                onClick={() => handleSelectMeridian(period)}
              >
                {period}
              </PeriodOption>
            ))}
          </PeriodSelect>
        </TimeSelectorContainer>
        <Divider sx={{width: '100%', margin: '8px 0'}}/>
        <ButtonsContainer>
          <CancelButton onClick={closeDropdown}>
            Cancel
          </CancelButton>
          <OkayButton onClick={handleOnClickOkayButton}>
            Okay
          </OkayButton>
        </ButtonsContainer>
        </TimeSelectorAndButtonsContainer>
      )}
    </Container>
  );
};

export default TimePickerInput;
