import { createSlice  } from "@reduxjs/toolkit";
import { ProfileState, UserFeedType } from "../types";

interface CreatorProfileState {
  profile: ProfileState;
  post: UserFeedType[];
  loading: boolean;
  error: any;
}

const initialCreatorState: CreatorProfileState = {
  profile: {
    id: "",
    userId: "",
    email: "",
    phoneNumber: "",
    displayName: "",
    handleName: "",
    description: "",
    contentTarget: [],
    referralCode: "",
    coins: 0,
    creatorType: "",
    businessName: "",
    individualName: "",
    followersCount: 0,
    followingCount: 0,
    subscriptionCount: 0,
    subscriberCount: 0,
    isInstagramVerified: false,
    isEmailVerified: false,
    isRemoved: false,
    isActive: false,
    isReported: false,
    isBlocked: false,
    profilePhotoUrl: "",
    coverPhotoUrl: "",
    preferredContentCategory: [],
    gender: "",
  },
  post:[],
  loading: false,
  error: null,
};

const creatorProfileSlice = createSlice({
  name: "creatorProfile",
  initialState: initialCreatorState,
  reducers: {
    setCreator(state, action) {
      state.profile = action.payload;
    },
    updateCreator(state, action) {
      state.profile = action.payload;
    },
    clearCreator(state) {
      return initialCreatorState;
    },
    setPostsOfCreator(state, action){
      state.post = action.payload;
    },
    removePostById(state, action){
      const postIndex = state.post.findIndex(postItem => postItem.id === action.payload);
      if (postIndex!== -1) {
        state.post.splice(postIndex, 1);
      }
    }
  },
});

export const { setCreator, updateCreator, clearCreator, setPostsOfCreator, removePostById} =
  creatorProfileSlice.actions;
export const creatorProfileReducer = creatorProfileSlice.reducer;
