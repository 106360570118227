import React, { useEffect, useState, useRef } from "react";
import "./Comments.css";
import { Backdrop, Box, Button, Stack, Typography, styled } from "@mui/material";
import { colors, typography } from "../../../utils/styles";
import CommentsScreen from "./CommentsScreen";
import { TextInput } from "../../Common/TextInput";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { setAddComment, setDeleteComment } from "../../../redux/userFeedSlice";
import feedsServices from "../../../api/feeds/feedsServices";
import userFeedsService from "../../../api/feeds/userFeedsService";
import { severityType } from "../../../types";

const InputLabelContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

const CounterText=styled(Typography)`
color: #616161;
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: 135%; 
letter-spacing: 0.32px;
`

type commentType = {
  displayName: string;
  createdAt: string;
  profilePhotoUrl: string | null;
  text: string;
  userId: string;
  handleName: string | null;
  commentId: string | undefined;
};

interface SlidingComponentProps {
  onClose: () => void;
  postId: string;
  creatorId:string;
  userRole:string;
  openSnackBar:(message:string, severity: severityType)=> void;
}

const Comments = React.forwardRef((props: SlidingComponentProps, ref) => {
  const [isComments, setIsComments] = useState(true);
  const [commentDescription, setCommentDescription] = useState("");
  const [commentData, setCommentData] = useState<commentType[]>([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const profileUser = useSelector((state: RootState) => state.user);
  const creatorProfile = useSelector((state: RootState) => state.creatorProfile.profile);
  const profileData = profileUser.id !== "" ? profileUser : creatorProfile;
  const dispatch = useDispatch<AppDispatch>();
  const observerRef = useRef<HTMLDivElement>(null);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    const truncatedValue =value.length > 500 ? value.substring(0, 500) : value;
      setCommentDescription(truncatedValue);
  };
  const handleSubmit = async () => {
    try {
      if (selectedComment) {
        const selectedIndex = commentData.findIndex(
          (item) => item.text === selectedComment["text"]
        );
        commentData[selectedIndex].text = commentDescription;
        setCommentData([...commentData]);
        setCommentDescription("");
        setSelectedComment(null);
        const body = { text: commentDescription };
        const commentId = commentData[selectedIndex].commentId;
        if (commentId) {
          try {
            props.userRole === 'creator' ? 
            await feedsServices.updateComment(commentId, body)
           : 
           await userFeedsService.updateComment(commentId, body);

           props.openSnackBar("Comment updated successfully", "success");

          } catch (error) {
            console.error("Failed to update comment:", error);
            commentData[selectedIndex].text = selectedComment["text"];
            setCommentData([...commentData]);
            props.openSnackBar("Failed to update comment", "error");
          }
        }
      } else {
        const data = {
          userId: 'userId' in profileData ? profileData.userId : profileData.id,
          createdAt: Date.now().toString(),
          profilePhotoUrl: profileData?.profilePhotoUrl,
          displayName: profileData?.displayName,
          text: commentDescription,
          handleName: profileData?.handleName,
          commentId: "",
        };

        setCommentData([...commentData, data]);
        setCommentDescription("");
        dispatch(setAddComment({ postId: props.postId }));
        const body = {
          postId: props.postId,
          text: commentDescription,
          creatorId: props.creatorId
        };
        try {
          props.userRole === 'creator' ? 
          await feedsServices.addComment(body)
         : 
         await userFeedsService.addComment(body);
          props.openSnackBar("Comment added successfully", "success");
    
        } catch (error) {
          console.error("Failed to add comment:", error);
          setCommentData(commentData.filter(item => item !== data));
          props.openSnackBar("Failed to add comment", "error");
        }
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  const onEdit = (comment: any) => {
    setSelectedComment(comment);
    setCommentDescription(comment.text);
  };

  const onDelete = async (index: number) => {
    try {
      const commentId = commentData.at(index)?.commentId;
      const comments = [...commentData];
      comments.splice(index, 1);
      setCommentData([...comments]);
      setCommentDescription("");
      dispatch(setDeleteComment({ postId: props.postId }));
      if (commentId) {
        try {
          props.userRole === 'creator' ? 
          await feedsServices.deleteComment(commentId)
         : 
         await userFeedsService.deleteComment(commentId);
          props.openSnackBar("Comment deleted successfully", "success");
        } catch (error) {
          console.error("Failed to delete comment:", error);
          props.openSnackBar("Failed to delete comment", "error");
        }
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  const fetchComments = async (pageNumber: number) => {
    setLoading(true);
    try {
      const response = props.userRole === 'creator' ? 
      await feedsServices.getAllComments(
        props.postId,
        pageNumber.toString(),
        "3"
      )
     : 
     await userFeedsService.getAllComments(
      props.postId,
      pageNumber.toString(),
      "3"
    )
      if (Array.isArray(response.data.data.data)) {
        const newComments = response.data.data.data;
        setCommentData((prevComments) => {
          const uniqueCommentsMap = new Map(
            [...prevComments, ...newComments].map((comment) => [comment.commentId, comment])
          );
          return Array.from(uniqueCommentsMap.values());
        });
  
        setTotalPages(response.data.data.pagination.totalPages);
      }
    } catch (error) {
      console.error("Failed to fetch comments:", error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchComments(page);
  }, [page]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading && page < totalPages) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [loading, page, totalPages]);

  return (
    <Backdrop open style={{ zIndex: 99999 }} onClick={() => props.onClose()}>
      <Box
        className="sliding-component"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {commentData.length ? (
          <Box
            mb="4px"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            {commentData.map((item: any, index: any) => (
              <Box mb="4px" key={item.commentId}>
                <CommentsScreen
                  commentData={item}
                  onEdit={onEdit}
                  onDelete={() => onDelete(index)}
                  profileUserId={
                    "userId" in profileData ? profileData.userId : profileData.id
                  }
                />
              </Box>
            ))}
            <div
              ref={observerRef}
              style={{ height: "20px", backgroundColor: "transparent" }}
            ></div>
            {loading && <Typography>Loading more comments...</Typography>}
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="20px"
            mt="120px"
            mb="120px"
          >
            <img src="/Images/submission.svg" width="200px" height="150px" />
            <Typography
              fontSize={typography.font_size["xl"]}
              fontWeight={typography.font_weight["bold"]}
            >
              No Comments
            </Typography>
            <Typography
              fontSize={typography.font_size["sm"]}
              color={colors.gray["80"]}
              textAlign="center"
            >
              Be the first one to interact
            </Typography>
          </Box>
        )}

        <Stack mt="10px">
        <InputLabelContainer>
        <TextInput
            name="comment"
            placeholder="Type a comment"
            value={commentDescription}
            onChange={handleChange}
          />
            <CounterText
              sx={{ marginLeft: "8px" }}
            >{`${commentDescription?.length}/500`}</CounterText>
          </InputLabelContainer>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="end"
            gap="10px"
          >
            <Button style={{ height: "32px" }} onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              variant={commentDescription ? "primary" : "disabled"}
              style={{ height: "32px" }}
              onClick={handleSubmit}
            >
              {selectedComment ? "Save" : "Post"}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Backdrop>
  );
});

export { Comments };
