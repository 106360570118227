export function getPlanValidityMessage(expiryDate: string) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const expiry = new Date(expiryDate);
    expiry.setHours(0, 0, 0, 0);
    const timeDifference = expiry.getTime() - currentDate.getTime();
    const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    if (daysLeft > 1 ) {
      return `(expires in ${daysLeft} days)`;
    } else if (daysLeft === 1) {
      return "(expires tomorrow)";
    } else if (daysLeft === 0) {
      return "(expires today)";
    }
  }