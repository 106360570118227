import { ApiService } from '../ApiClient';
import {creatorAxiosInstance} from '../axiosInstance';
import "../interceptors/creatorAuthInterceptors";


class CreatorProfileService extends ApiService {
  constructor() {
    super(creatorAxiosInstance);
  }
  getCreatorProfile() {
    return this.axios.get(`/creator/profile`);
  }
  updateCreatorProfile(profile: any){
    return this.axios.put(`creator/profile`, profile);
  }

  getAnotherCreatorsProfile(creatorId:string){
    return this.axios.get(`creator/profile/${creatorId}`);
  }

  getFreePostList(creatorId:string, page:string, pageSize: string){
    return this.axios.get(`content/post?creatorId=${creatorId}&postAccessType=FREE&page=${page}&pageSize=${pageSize}`);
  }

  getLockedPostList(creatorId:string, page:string, pageSize: string){
    return this.axios.get(`content/post?creatorId=${creatorId}&postAccessType=PAID&page=${page}&pageSize=${pageSize}`);
  }

  getPurchasedPostList(creatorId:string, page:string, pageSize: string){
    return this.axios.get(`content/gallery?page=${page}&pageSize=${pageSize}&creatorId=${creatorId}&view=LIST`);
  }

  getCreatorsSubscription(creatorId:string){
    return this.axios.get(`subscription?creatorId=${creatorId}`);
  }
  getCreatorsOnDemandRates(creatorId:string){
    return this.axios.get(`subscription/on-demand-rate?creatorId=${creatorId}`);
  }

  addFollower(userId:string){
    return this.axios.post(`creator/following/${userId}`);
  }

  removeFollower(userId:string){
    return this.axios.delete(`creator/following/${userId}`);
  }

  getAllPostsOfACreator(creatorId:string, page:string, pageSize: string){
    return this.axios.get(`content/post?creatorId=${creatorId}&postAccessType=ALL&page=${page}&pageSize=${pageSize}`);
  }
 


 createSubscription(subscription: any) {

      return this.axios.post('subscription', JSON.stringify(subscription));

 
  }
getCreatorSubscriptions(creatorId: string) {

        return this.axios.get(`subscription?creatorId=${creatorId}`);
   
  }

 updateSubscription(id: string, subscription: any) {

     return this.axios.put(`subscription/${id}`, subscription);
   
  }
deleteSubscription(id: string) {

      return this.axios.delete(`subscription/${id}`);
   
  }

 getOnDemandRate(creatorId:string) {

     return this.axios.get(`subscription/on-demand-rate?creatorId=${creatorId}`);
  
  }

createUpDateOnDemandRate(subscription: any) {
      return this.axios.put('subscription/on-demand-rate', JSON.stringify(subscription));
  }
 deleteOnDemandRate(id: string, key?: string, value?: string) {
      if(key && value){
        return this.axios.delete(`subscription/on-demand-rate/${id}`, {
          params: {
            [key]: value,
          },
        });
      }
      else{
        return this.axios.delete(`subscription/on-demand-rate/${id}`)
      }
  }

  getCreatorsFollowersList(page:string, pageSize: string){
    return this.axios.get(`creator/following/followers?page=${page}&pageSize=${pageSize}`);
  }

  getCreatorsFollowingList(page:string, pageSize: string){
    return this.axios.get(`creator/following/followings?page=${page}&pageSize=${pageSize}`);
  }

  getCreatorsPostStats(creatorId:string){
    return this.axios.get(`content/post/stats?creatorId=${creatorId}`);
  }
  generateReferralCode(){
    return this.axios.post(`user/referral`);
  }
  getEarnedReferralCoins(){
    return this.axios.get(`payment/referral`);
  }
}

export default new CreatorProfileService();