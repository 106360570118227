
import { ApiService } from '../ApiClient';
import {userAxiosInstance} from '../axiosInstance';
import '../interceptors/userAuthInterceptors';

class ProfileService extends ApiService {
  constructor() {
    super(userAxiosInstance);
  }

  getProfile(){
    return this.axios.get(`/user/profile`);
  }

  getUserProfile(userId: string) {
    return this.axios.get(`/user/profile/${userId}`);
  }

  updateUserProfile(userId: string, data: any) {
    return this.axios.put(`/user/profile/${userId}`, data);
  }
  
  generateReferralCode(){
    return this.axios.post(`user/referral`);
  }
  getEarnedReferralCoins(){
    return this.axios.get(`payment/referral`);
  }

}

export default new ProfileService();
