import { ApiService } from '../ApiClient';
import { userAxiosInstance } from "../axiosInstance";
import "../interceptors/userAuthInterceptors"


class UsersSettingsApi extends ApiService {
    constructor() {
        super(userAxiosInstance);
      }
  getFaqList() {
    return this.axios.get(`/user/settings/faqs`);
  }
  deleteAccount(body:any){
    return this.axios.patch(`/user/settings/delete`, JSON.stringify(body));
  }
  deactivateAccount(body: any){
    return this.axios.patch(`/user/settings/deactivate`, JSON.stringify(body));
  }
  updateNotificationSettings(body:any){
    return this.axios.patch(`/user/settings/notification`, JSON.stringify(body));
  }
  getNotificationSettings(){
    return this.axios.get(`/user/settings/notification`);
  }
}

export default new UsersSettingsApi();