import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import { set_Cover_Photo, setView } from "../../redux/imageUploadSlice";
import styled from "@emotion/styled";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useToast from "../../hooks/useToast";
import { useEffect } from "react";
const PageHeader = styled(Stack)`
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  padding-inline:12px;
`
const AddCoverPhoto = () => {
    const dispatch = useDispatch();
    const { ToastMessage, handleOpenToast } = useToast();
    const freePhotos = useSelector((state: RootState) => state.imagePost.freePhotos);
    const coverPhoto = useSelector((state: RootState) => state.imagePost.coverPhoto)
    const updateCoverPhoto = (idx: number) => {
        const coverPhoto = freePhotos[idx];
        dispatch(set_Cover_Photo(coverPhoto));
    }
    const onClickNext = () => {
        dispatch(setView('POST_DETAILS'));
    }
    const onBackBtnClick = () =>{
        dispatch(setView('FREE_PHOTOS'))
    }
    useEffect(() => {
        setTimeout(() => {
            handleOpenToast('You can select only one cover photo', 'warning')
        }, 2000)
    }, [])
    return (
        <Box>
            <ToastMessage />
            <PageHeader
                sx={{ background: "#DCD7F5", height: '56px', boxShadow: 'none' }}>
                <ArrowBackIosNewIcon onClick={onBackBtnClick}/>
                <Typography fontSize="16px" fontWeight={700}>
                    Select Cover Photos
                </Typography>
                <Typography fontSize="12px" fontWeight={700} onClick={onClickNext}>Next</Typography>
            </PageHeader>
            <Stack direction='row' gap="2px" flexWrap='wrap'>
                {freePhotos.map((e: any, idx: any) => {
                    return (
                        <Box position='relative' flex={`1 1 calc(33.33% - 2px)`} sx={{
                            aspectRatio: '1 / 1',
                            overflow: 'hidden'
                        }}>
                            <Checkbox
                                checked={coverPhoto === e}
                                color="success"
                                sx={{ position: 'absolute', right: 5, top: 5 }}
                                onChange={() => updateCoverPhoto(idx)}
                            />
                            <img src={e} key={e} style={{
                                width: '100%', 
                                height: '100%',
                                objectFit: 'cover', 
                            }} />
                        </Box>
                    )
                })}
            </Stack>
        </Box>
    )
}
export { AddCoverPhoto }