import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { colors, typography } from "../../utils/styles";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Carousel from "react-material-ui-carousel";
import { GiftIcon } from "../../assets/SvgIcons";
import "./SlidingComponent.css";
import { SlidingComponent } from "./SlidingComponent";
import { useNavigate } from "react-router";
import userApi from "../../api/user/userApi";
import UserFeed from "../Feeds/UserFeed";
import { UserFeedType } from "../../types";
import userGalleryService from "../../api/gallery/userGalleryService";
import creatorGalleryService from "../../api/gallery/creatorGalleryService";

const DetailedPurchasedContentView = ({userRole}:{userRole:string}) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [purchasedPosts, setPurchasedPosts] = useState<UserFeedType[]>([]);
  const slidingComponentRef = useRef<HTMLDivElement>(null);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const fetchGalleryListView = async() =>{
    try{
      const response = userRole === 'user' ? 
     await userGalleryService.fetchGalleryListView()
     :
     await creatorGalleryService.fetchGalleryListView()

     setPurchasedPosts(response.data.data.data)
    }catch(error){
      // replance with fallback
      console.log(error);
    }
  }

  useEffect(()=>{
   fetchGalleryListView()
  },[])

  return <Box>
    {
     purchasedPosts.length !== 0 && purchasedPosts.map((post,idx)=><UserFeed key={idx} post={post}/>)
    }
  </Box>

};

export { DetailedPurchasedContentView };

const Post = ({postData}:{postData:any}) =>{
  const {
        contents,
        commentsCount,
        contentDescription,
        likesCount
      } = postData.post;
  console.log(postData)
  return (
    <Box>
    <Box mx="16px" my="8px">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="row" gap="10px">
          <img src="/Icons/Avatar.svg" />
          <Box>
            <Typography
              fontSize={typography.font_size.base}
              fontWeight={typography.font_weight.semiBold}
            >
              Kylie Jenner Thomas
            </Typography>
            <Typography
              fontSize={typography.font_size.xs}
              color={colors.gray["80"]}
            >
              @Kyliejennerthomas
            </Typography>
          </Box>
        </Box>

        <img src="/Icons/three_dots_icon.svg" />
      </Box>
      <Stack my="8px">
        <Typography
          fontSize={typography.font_size.sm}
          color={colors.gray["80"]}
        >
          {contentDescription}
          <a
            style={{
              fontSize: typography.font_size.xs,
              fontWeight: typography.font_weight.bold,
              color: colors.brand_primary,
            }}
          >
            See more
          </a>
        </Typography>
      </Stack>
    </Box>
    <Carousel
      autoPlay={false}
      navButtonsAlwaysInvisible
      stopAutoPlayOnHover
      swipe={false}
    >
      {
        contents.map((e:any)=><img src={e.processedUrl} alt={e.key} />)
      }
      {/* <img src="/Images/postImage.svg" width="100%" />
      <img src="/Images/postImage.svg" width="100%" />
      <img src="/Images/postImage.svg" width="100%" /> */}
    </Carousel>

    <Stack
      mx="16px"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      mt="10px"
    >
      <Stack display="flex" flexDirection="row" gap="12px">
        <FavoriteBorderIcon />
        <img
          src="/Icons/comment_icon.svg"
          width="21px"
          height="21px"
          // onClick={toggleVisibility}
          style={{ cursor: "pointer" }}
        />
        <img src="/Icons/share_icon.svg" width="21px" height="21px" />
      </Stack>
      <Button
        variant="contained"
        sx={{
          background: colors.violet[100],
          color: colors.white,
          fontWeight: typography.font_weight.bold,
          fontSize: typography.font_size.xs,
          display: "flex",
          gap: ".3rem",
          alignItems: "center",
          padding: "8px 10px",
        }}
      >
        <GiftIcon />

        <Typography
          variant="h3"
          sx={{
            fontWeight: typography.font_weight.bold,
            color: colors.white,
            fontSize: typography.font_size.xs,
          }}
        >
          Tip Me
        </Typography>
      </Button>
    </Stack>
    <Stack mx="16px" display="flex" flexDirection="row" gap="6px">
      <Typography
        fontSize={typography.font_size.sm}
        fontWeight={typography.font_weight.bold}
      >
        {likesCount} Likes,
      </Typography>
      <Typography
        fontSize={typography.font_size.sm}
        fontWeight={typography.font_weight.bold}
      >
        {commentsCount} Comments
      </Typography>
    </Stack>
  
  </Box>
  )
}