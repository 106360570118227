import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  styled,
  Typography,
  Dialog,
} from "@mui/material";
import SubscriptionHeader from "../ProfileView/Creator/CreatorSubscriptions/SubscriptionHeader";
import { STATUS, Status } from "../../constants/statusConstants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import creatorPaymentServiceApi from "../../api/creator/paymentServiceApi";
import userPaymentServiceApi from "../../api/user/paymentServiceApi";
import { RootState } from "../../store/store";
import {
  resetBooking,
  setAssignedSlot,
  setSelectedSlot,
  setTimerEndTime,
} from "../../redux/slotBookingSlice";
import { formatDate } from "../../utils/formatDate";
import { colors } from "../../utils/styles";
import { Timer } from "../../components/BookSlot/Timer";
import { resetTimer } from "../../redux/timerSlice";
import { useSnackbar } from "../../context/SnackbarContext";
import userSlotBookingService from "../../api/booking/user/userSlotBookingService";
import creatorSlotBookingService from "../../api/booking/creator/creatorSlotBookingService";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

const DetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 94%;
  border: 1px solid ${colors.violet[100]};
  border-radius: 8px;
  padding: 16px 8px;
`;

const DetailItem = styled(Box)`
  display: flex;
  gap: 4px;
  justify-content: space-between;
  width: 100%;
`;

const DetailItemTitle = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

const DetailItemValue = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.violet[100]};
`;

const BookingDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    serviceType,
    service,
    minuteSlot,
    daySlot,
    selectedSlot,
    availableSlotTime,
    serviceValue,
    creatorDetails,
    susbscriptionDetails,
    lockedSlotId,
    timerEndTime,
  } = useSelector((state: RootState) => state.slotBooking);

  const hasBookingData =
    service &&
    minuteSlot &&
    daySlot &&
    selectedSlot &&
    creatorDetails.creatorName;
  const handleOnClickGoBackButton = async () => {
    try {
      if (timerEndTime !== "") {
        userRole === "user"
          ? await userSlotBookingService.unlockSlot(lockedSlotId)
          : await creatorSlotBookingService.unlockSlot(lockedSlotId);
        dispatch(setTimerEndTime(""));
        dispatch(resetTimer());
        dispatch(setAssignedSlot({}));
        dispatch(setSelectedSlot({ startTime: "", endTime: "" }));
      }
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  let totalCloutCoins = 0;
  if (serviceType === "onDemand") {
    totalCloutCoins = Number(minuteSlot) * Number(serviceValue);
  }
  const location = useLocation();
  const [isInsufficientBalance, setIsInsufficientBalance] = useState(false);
  const data = useSelector((state: RootState) => state.anotherCreator);
  const [profileData, setProfileData] = useState(data.profileData);
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const timeLeft = useSelector((state: RootState) => state.timer.timeLeft);
  const { openSnackBar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const onPayingForSubscription = async () => {
    setOpenDialog(true);
  };

  const handleConfirmation = async () => {
    try {
      const payload = {
        creatorId: creatorDetails.creatorId,
        date: daySlot.date,
        serviceType: ServiceType,
        startTime: availableSlotTime.startTime,
        endTime: availableSlotTime.endTime,
        userSubscriptionId: susbscriptionDetails.userSubscriptionId,
        planExpiryDate: susbscriptionDetails.expiryDate,
        lockedSlotId,
      };
      const { data: responseData } =
        userRole === "user"
          ? await userSlotBookingService.bookSlot(payload)
          : await creatorSlotBookingService.bookSlot(payload);
      if (responseData) {
        dispatch(resetBooking());
        openSnackBar("Booking confirmed successfully", "success");
        navigate(`/${userRole}/bookings`);
      }
    } catch (error) {
      console.log(error);
      openSnackBar("Error in booking slot", "error");
    }
  };

  useEffect(() => {
    if (!profileData?.id) {
      const b = localStorage.getItem("profileData");
      if (b) {
        setProfileData(JSON.parse(b));
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (userRole === "user") {
      userPaymentServiceApi.get_Wallet_Details().then((data) => {
        setIsInsufficientBalance(
          data.data.data.totalYc - data.data.data.lockedYc < totalCloutCoins
        );
      });
    } else if (userRole === "creator") {
      creatorPaymentServiceApi.get_Wallet_Details().then((data) => {
        setIsInsufficientBalance(
          data.data.data.totalYc - data.data.data.lockedYc < totalCloutCoins
        );
      });
    }
  }, [totalCloutCoins, userRole]);

  useEffect(() => {
    if (timeLeft === "00:00") {
      dispatch(resetTimer());
      navigate(`/${userRole}/time-expired`);
    }
  }, [timeLeft, dispatch, navigate, userRole]);

  let ServiceType = "";
  if (service === "Audio Call") {
    ServiceType = "AUDIOCALL";
  }
  if (service === "Video Call") {
    ServiceType = "VIDEOCALL";
  }
  if (service === "Direct Message") {
    ServiceType = "DMS";
  }

  const onDemandData = {
    minuteSlot,
    service,
    ServiceType,
  };

  return (
    <Container>
      <OnDemandConfirmationModal
        open={openDialog}
        setOpenDialog={setOpenDialog}
        amount={totalCloutCoins}
        userRole={userRole}
        onDemandData={onDemandData}
        profileData={profileData}
        openSnackBar={openSnackBar}
        lockedSlotId={lockedSlotId}
      />
      <SubscriptionHeader
        title="Booking Details"
        handleOnClickGoBackButton={handleOnClickGoBackButton}
      />

      {hasBookingData && (
        <>
          {timerEndTime && <Timer ttl={timerEndTime} />}
          <DetailsContainer>
            <DetailItem>
              <DetailItemTitle>Creator Name</DetailItemTitle>
              <DetailItemValue>{creatorDetails.creatorName}</DetailItemValue>
            </DetailItem>
            <DetailItem>
              <DetailItemTitle>Service</DetailItemTitle>
              <DetailItemValue>{service}</DetailItemValue>
            </DetailItem>
            <DetailItem>
              <DetailItemTitle>No. of Minutes</DetailItemTitle>
              <DetailItemValue>
                {minuteSlot} {minuteSlot && "min"}
              </DetailItemValue>
            </DetailItem>
            <DetailItem>
              <DetailItemTitle>Booking Date</DetailItemTitle>
              <DetailItemValue>
                {formatDate(daySlot.date)} {daySlot.name}
              </DetailItemValue>
            </DetailItem>
            <DetailItem>
              <DetailItemTitle>Slot Timing</DetailItemTitle>
              <DetailItemValue>
                {availableSlotTime.startTime}{" "}
                {availableSlotTime.startTime && "-"} {availableSlotTime.endTime}
              </DetailItemValue>
            </DetailItem>
            {serviceType === "onDemand" && (
              <DetailItem>
                <DetailItemTitle>No. of Clout Coins Charged</DetailItemTitle>
                <DetailItemValue>
                  {totalCloutCoins} {" CC"}
                </DetailItemValue>
              </DetailItem>
            )}
          </DetailsContainer>
          {serviceType === "subscription" && (
            <>
              <DetailItem
                sx={{
                  padding: "4px 16px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: "16px",
                    color: colors.gray[600],
                  }}
                >
                  Remaining minutes Left after this Slot <br /> in your
                  subscription plan for {service}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "22px",
                    color: colors.red[300],
                  }}
                >
                  {Number(serviceValue) - Number(minuteSlot)} min
                </Typography>
              </DetailItem>
              {timeLeft !== "00:00" && (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colors.violet[100],
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: 700,
                    position: "fixed",
                    bottom: "0",
                    borderRadius: "0",
                    "&:hover": {
                      backgroundColor: colors.violet[20],
                    },
                    width: "100%",
                    height: "56px",
                  }}
                  onClick={handleConfirmation}
                  disabled={!hasBookingData}
                >
                  Confirm
                </Button>
              )}
            </>
          )}
          {serviceType === "onDemand" && timeLeft !== "00:00" && (
            <Stack
              px="20px"
              py="4px"
              mt="auto"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              position="fixed"
              bottom="0"
              width="100%"
              boxShadow="0px -4px 8px 0px rgba(0, 0, 0, 0.08)"
            >
              <Box>
                <Typography fontSize="20px" fontWeight={700}>
                  {totalCloutCoins} CC
                </Typography>
                <Typography
                  fontSize="14px"
                  color={
                    isInsufficientBalance ? colors.red[300] : colors.black[200]
                  }
                >
                  {isInsufficientBalance
                    ? "Insufficient Balance"
                    : "Grand Total"}
                </Typography>
              </Box>
              {isInsufficientBalance ? (
                <Button
                  onClick={() =>
                    navigate(`/${userRole}/wallet/add-coins`, {
                      state: { from: "buy-subscription" },
                    })
                  }
                  sx={{ width: "140px" }}
                  variant="contained"
                >
                  Add Cc
                </Button>
              ) : (
                <Button
                  onClick={onPayingForSubscription}
                  sx={{ width: "140px" }}
                  variant="contained"
                >
                  Pay
                </Button>
              )}
            </Stack>
          )}
        </>
      )}
    </Container>
  );
};

const OnDemandConfirmationModal = ({
  open,
  setOpenDialog,
  amount,
  userRole,
  onDemandData,
  profileData,
  openSnackBar,
  lockedSlotId,
}: {
  open: boolean;
  setOpenDialog: any;
  amount: number;
  userRole: string;
  profileData: any;
  onDemandData: any;
  openSnackBar: any;
  lockedSlotId: string;
}) => {
  const navigate = useNavigate();
  const [isPaid, setIsPaid] = useState<Status>(STATUS.IDLE);
  const dispatch = useDispatch();
  const { daySlot, availableSlotTime, creatorDetails } = useSelector(
    (state: RootState) => state.slotBooking
  );

  const handleOnDemandChargePay = async () => {
    try {
      setIsPaid(STATUS.LOADING);

      const payload = {
        creatorId: creatorDetails.creatorId,
        date: daySlot.date,
        serviceType: onDemandData.ServiceType,
        startTime: availableSlotTime.startTime,
        endTime: availableSlotTime.endTime,
        amount,
        lockedSlotId,
      };
      const { data: responseData } =
        userRole === "user"
          ? await userSlotBookingService.bookSlot(payload)
          : await creatorSlotBookingService.bookSlot(payload);
      setIsPaid(STATUS.SUCCESS);
      dispatch(resetBooking());
      if (responseData.status === "ACCEPTED") {
        navigate(`/${userRole}/bookings?success=true`);
      } else {
        navigate(`/${userRole}/bookings?success=false`);
      }
      localStorage.removeItem("subscriptionPlanData");
      localStorage.removeItem("profileData");
    } catch (error) {
      setIsPaid(STATUS.ERROR);
      console.log(error);
      openSnackBar("Error in booking slot", "error");
    } finally {
      setIsPaid(STATUS.IDLE);
    }
  };
  useEffect(() => {
    setOpenDialog(open);
  }, [open, setOpenDialog]);

  return (
    <Dialog open={open} sx={{ borderRadius: "12px" }}>
      <Stack sx={{ height: "280px", width: "280px" }}>
        <Box
          sx={{ background: colors.gray[10] }}
          p="1rem"
          pt="1.5rem"
          height="118px"
        >
          <Box textAlign="center">
            <Typography fontWeight={700} fontSize="20px">
              Confirm Slot Booking
            </Typography>
            <Typography fontSize="14px">
              For {onDemandData.minuteSlot} min {onDemandData.service} with{" "}
              {profileData?.handleName ?? ""}
            </Typography>
          </Box>
        </Box>
        <Box py=".5rem" px="2rem" textAlign="center">
          <Typography lineHeight="16px" fontSize="12px" color={colors.gray[80]}>
            Click confirm to book your slot with creator. Once creator accepts
            your booking request you will receive confirmation .
          </Typography>
        </Box>
        <Stack
          pt="1.25rem"
          direction="row"
          px="2rem"
          justifyContent="space-between"
        >
          <Button
            sx={{ height: "48px", width: "100px" }}
            variant={isPaid === STATUS.LOADING ? "disabled" : "outlined"}
            onClick={() => setOpenDialog(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{ height: "48px", width: "100px" }}
            variant={isPaid === STATUS.LOADING ? "disabled" : "contained"}
            onClick={handleOnDemandChargePay}
          >
            {isPaid !== STATUS.LOADING ? (
              "Confirm"
            ) : (
              <CircularProgress size={24} sx={{ color: colors.white }} />
            )}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default BookingDetails;
