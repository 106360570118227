import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentStep,
  setCurrentStep,
  setCurrentTitle,
  setLoading,
} from "../../../redux/creatorRegistrationSlice";
import userApi from "../../../api/user/userApi";

export default function PanVerification(props: any) {
  const dispatch = useDispatch();
  const currentStep = useSelector(getCurrentStep);

  const [panName, setPanName] = useState("");
  const [panNumber, setPanNumber] = useState("");

  const [errors, setErrors] = useState<{
    panName?: string;
    panNumber?: string;
  }>({});

  const checkPanName = (name: string) => {
    const regex = /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/;
    const error = regex.test(name) ? "" : "Invalid name entered";
    return setErrors({ panName: error });
  };
  const checkPanNum = (number: string) => {
    const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const error = regex.test(number) ? "" : "PAN card number is wrong";
    return setErrors({ panNumber: error });
  };

  const handleNext = async () => {
    try {
      dispatch(setLoading(true));

      const payload = {
        pan_number: panNumber.trim(),
        pan_holder_name: panName,
      };
      await userApi.verifyPan(payload);

      props.next();
    } catch (error: any) {
      console.error("Error during step two:", error);
      setErrors({ ...errors, panNumber: error.message });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handlePanNumberChange = (e: any) => {
    const num = e.target.value;
    if (num.length <= 10) {
      setPanNumber(num.toUpperCase());
    }
  };

  return (
    <Box>
      <Box mx="16px">
        <InputLabel sx={{ paddingBottom: "4px", paddingTop: "15px" }}>
          Enter Name
        </InputLabel>
        <TextField
          variant="outlined"
          placeholder="Enter Name"
          fullWidth
          value={panName}
          onChange={(e) => setPanName(e.target.value)}
          onBlur={(e) => checkPanName(e.target.value)}
          error={!!errors.panName}
          helperText={errors.panName}
          InputProps={{
            style: { height: "48px" },
          }}
        />

        <InputLabel sx={{ paddingBottom: "4px", paddingTop: "15px" }}>
          Enter Pan Card
        </InputLabel>
        <TextField
          variant="outlined"
          placeholder="ABCDE 1234 F"
          fullWidth
          value={panNumber}
          onChange={handlePanNumberChange}
          error={!!errors.panNumber}
          helperText={errors.panNumber}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton edge="start">
                  <img src="/Images/pan.svg" />
                </IconButton>
              </InputAdornment>
            ),
            style: { height: "48px" },
          }}
          inputProps={{ maxLength: 10 }}
        />
      </Box>

      <Box position="absolute" bottom="0" width="100%">
        <Button
          type="submit"
          onClick={handleNext}
          variant={!panName || panNumber.length < 10 ? "disabled" : "primary"}
          fullWidth
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
