import { Box, styled, Typography } from "@mui/material";
import { useEffect, useRef } from "react";

const Container = styled(Box)({
  width: "100%",
  height: "100%",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const VideoContainer = styled(Box)(
  ({ fullScreen }: { fullScreen?: boolean }) => ({
    width: fullScreen ? "100%" : "300px",
    height: fullScreen ? "100%" : "300px",
    objectFit: "cover",
    position: "relative",
  })
);

const Stream = ({
  stream,
  muted,
  isOwner,
  fullScreen = false,
}: {
  stream: MediaStream;
  muted: boolean;
  isOwner: boolean;
  fullScreen?: boolean;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  return (
    <Container>
      <VideoContainer fullScreen={fullScreen}>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted={muted}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </VideoContainer>
      <Typography
        sx={{
          color: "white",
          position: "absolute",
          bottom: "10px",
          left: "10px",
          fontSize: "14px",
        }}
      >
        {isOwner ? "You" : ""}
      </Typography>
    </Container>
  );
};

export default Stream;
