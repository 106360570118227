import { useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../../components/Common/Loader";
import { Header } from "../../components/Creator/CreatePost/Header";
import { FileUpload } from "../../components/Creator/CreatePost/FileUpload";
import { CreatePostForm } from "../../components/Creator/CreatePost/CreatePostForm";
import FreePhotos from "../../components/Creator/CreatePost/FreePhotos";
import CoverPhotos from "../../components/Creator/CreatePost/CoverPhotos";
import { PreviewVideos } from "../../components/Creator/CreatePost/PreviewVideos";
import { PHOTO_STEPS, VIDEO_STEPS } from "../../utils/creatorContentTypes";

const CreatePost = () => {
  const files = useSelector((state: any) => state.postContent.files);
  const mode = useSelector((state: any) => state.postContent.mode);

  const currentStep = useSelector(
    (state: any) => state.postContent.currentContentStep
  );

  // const state = useSelector((state: any) => state);

  const [loading, setLoading] = useState<boolean>(false);

  if (!files.length) return null;

  return (
    <Box>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Box sx={{height:"100dvh",position:'relative'}}>
          <Header files={files} mode={mode} setLoading={setLoading} />
          {(currentStep === VIDEO_STEPS.VIDEO_UPLOADING ||
            currentStep === PHOTO_STEPS.PHOTO_UPLOADING) && <FileUpload />}

          {currentStep ===
            (PHOTO_STEPS.POST_DETAILS || VIDEO_STEPS.POST_DETAILS) && (
            <CreatePostForm />
          )}

          {currentStep === PHOTO_STEPS.FREE_PHOTOS && <FreePhotos />}

          {currentStep === PHOTO_STEPS.COVER_PHOTOS && <CoverPhotos />}

          {currentStep === VIDEO_STEPS.PREVIEW_VIDEOS && <PreviewVideos />}
        </Box>
      )}
    </Box>
  );
};
export { CreatePost };
