import { Box, Button, styled, Typography } from "@mui/material";
import SubscriptionHeader from "../ProfileView/Creator/CreatorSubscriptions/SubscriptionHeader";
import { useNavigate } from "react-router-dom";
import { resetBooking } from "../../redux/slotBookingSlice";
import { useDispatch } from "react-redux";
import { colors } from "../../utils/styles";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const PageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: 80%;
`;

const TimerEnd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnClickGoBackButton = () => {
    console.log("Go back button clicked");
    dispatch(resetBooking());
    navigate(-2);
  };

  return (
    <Container>
      <SubscriptionHeader
        title="Booking Details"
        handleOnClickGoBackButton={handleOnClickGoBackButton}
      />
      <PageContainer>
        <img src="/Images/timer_end.png" alt="Timer Ended" width="200px" />
        <div>
          <Typography
            sx={{
              fontSize: "32px",
              fontWeight: "700",
              color: colors.gray[80],
              textAlign: "center",
              fontFamily: "Poppins",
            }}
          >
            Oops!!
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "400",
              color: colors.gray[80],
              textAlign: "center",
              fontFamily: "Poppins",
            }}
          >
            Sorry, your time has run out.
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "400",
              color: colors.gray[80],
              textAlign: "center",
              fontFamily: "Poppins",
            }}
          >
            Don't worry. You can book again.
          </Typography>
        </div>
        <Button
          sx={{
            height: "48px",
            width: "200px",
            borderRadius: "16px",
            fontSize: "16px",
            fontWeight: "700",
            color: colors.white,
            padding: "4px 16px",
            letterSpacing: "1.5%",
            textAlign: "center",
          }}
          variant="contained"
          onClick={handleOnClickGoBackButton}
        >
          Book Again
        </Button>
      </PageContainer>
    </Container>
  );
};

export default TimerEnd;
