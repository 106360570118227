import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { PageTagComponent } from "../../components/Common/PageTagComponent";
import { Link } from "react-router-dom";
import { typography, colors } from "../../utils/styles";

const PolicyContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Header = styled(Box)`
  position: sticky;
  top: 0;
`;
const Section = styled(Box)``;
const SectionTitle = styled(Typography)`
  font-size: ${typography.font_size.lg}px;
  font-weight: ${typography.font_weight.semiBold};
  color: ${colors.violet[100]}; /* Assuming var(--Text-Brand-Primary, #7262c2) maps to violet[100] */
`;
const SectionContent = styled(Typography)`
  font-size: ${typography.font_size.sm}px;
  color: ${colors.black[200]}; /* Assuming var(--Text-Primary, #212121) maps to black[200] */
  line-height: ${typography.line_height.default}; /* 1.6 approximated as 135% */
`;
const Content = styled(Box)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;
const Items = styled("ul")``;
const Item = styled("li")`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
  margin-left: 1.5rem;
`;
const DecorationContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TermsAndConditionPage = () => {
  return (
    <PolicyContainer>
      <Header>
        <PageTagComponent>Terms Of Service</PageTagComponent>
      </Header>
      <Content>
        <Section>
          <SectionTitle>Clout Terms of Service</SectionTitle>
          <SectionContent>
            Effective as of October 01, 2024. <br />
            <br />
            Welcome, and thank you for your interest in the Clout Platform
            (“Clout”, “we” or “us”), our website at{" "}
            <Link to="www.tryclout.com">www.tryclout.com</Link> and associated
            mobile applications (“Website”), and any other services or resources
            that are accessed or enabled via the Website (collectively with the
            Website, the “Services”).
            <br />
            <br />
            PLEASE READ THESE PLATFORM TERMS OF SERVICE (“TERMS OF SERVICE”)
            CAREFULLY. While we’ve taken all effort to explain the provision of
            our Services to you in as simple and straightforward a manner as
            possible, there will inevitably be clauses that do contain a little
            bit of ‘legalease’. If you require any further explanation or
            clarification with regards to any of the following, please feel free
            to get in touch with us and we would be more than happy to provide
            further explanation and assistance.
            <br />
            <br />
            By agreeing to these Terms of Service, you acknowledge and consent
            that any disputes arising from or relating to your use of our
            Services will be resolved exclusively on an individual basis. You
            expressly waive any right to pursue legal action as part of a class,
            consolidated, or representative lawsuit. This means you cannot
            initiate or participate in any class action lawsuits, collective
            actions, or any similar group proceedings against Clout. This waiver
            is intended to limit potential litigation risks and ensure that all
            disputes are handled individually, providing both parties with a
            more efficient and streamlined resolution process.
            <br />
            <br />
            BY ACCESSING OR USING THE SERVICES IN ANY WAY, INCLUDING BY CLICKING
            ON AN “I ACCEPT” BUTTON, COMPLETING THE ACCOUNT REGISTRATION
            PROCESS, AND/OR BROWSING THE WEBSITE, YOU REPRESENT THAT: (1) YOU
            HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THESE TERMS OF
            SERVICE; (2) YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH
            Clout; AND (3) YOU HAVE THE AUTHORITY TO ENTER INTO THESE TERMS OF
            SERVICE PERSONALLY OR ON BEHALF OF THE ENTITY YOU HAVE NAMED AS THE
            USER, AND TO BIND THAT ENTITY TO THESE TERMS OF SERVICE. THE TERM
            “YOU” REFERS TO THE INDIVIDUAL OR LEGAL ENTITY, AS APPLICABLE,
            IDENTIFIED AS THE USER WHEN YOU REGISTERED FOR THE SERVICES. IF YOU
            DO NOT AGREE TO BE BOUND BY THESE TERMS OF SERVICE, YOU MAY NOT
            ACCESS OR USE ANY OF THE SERVICES.
            <br />
            <br />
            THE SERVICES INCLUDE A PLATFORM WHICH ALLOWS USERS TO OFFER TO SELL,
            SELL AND BUY TANGIBLE AND/OR DIGITAL GOODS, ASSETS, PRODUCTS,
            SERVICES AND BENEFITS. ALL ITEMS AVAILABLE THROUGH THE PLATFORM,
            WHETHER DIGITAL OR TANGIBLE, ARE FURNISHED BY OR ON BEHALF OF
            SELLERS IN CONNECTION WITH SUCH SALES. Clout IS NOT RESPONSIBLE FOR
            ANY GOODS OFFERED FOR SALE OR SOLD THROUGH THE SERVICES, UNLESS
            Clout IS IDENTIFIED AS THE SELLER AT THE POINT OF SALE. WE ARE NOT A
            BROKER, FINANCIAL INSTITUTION, OR CREDITOR. YOU AGREE THAT WE SHALL
            NOT BE A PARTY TO OR HAVE ANY RESPONSIBILITY OR LIABILITY FOR,
            ARISING OUT OF, RELATING TO, ASSOCIATED WITH OR RESULTING FROM ANY
            DISPUTES BETWEEN YOU AND ANY SELLER OF GOODS IN RESPECT OF THE USE,
            MISUSE, PROVISION OR FAILURE TO PROVIDE ANY GOODS.
            <br />
            <br />
            Your use of, and participation in, certain Services may be subject
            to additional terms (“Supplemental Terms”) and such Supplemental
            Terms will either be listed in these Terms of Service or will be
            presented to you for your acceptance when you sign up to use the
            supplemental Service. If these Terms of Service are inconsistent
            with the Supplemental Terms, the Supplemental Terms shall control
            with respect to such Service.
            <br />
            <br />
            PLEASE NOTE THAT THESE TERMS OF SERVICE ARE SUBJECT TO CHANGE BY US
            IN OUR SOLE DISCRETION AT ANY TIME. When changes are made, we will
            make a new copy of these Terms of Service available on or through
            the Services and any new Supplemental Terms will be made available
            from within, or through, the Website. We will also update the “Last
            Updated” date at the top of these Terms of Service. If we make any
            material changes, and you have registered with us to create an
            Account (as defined below), we may also send an e-mail to you at the
            last e-mail address you provided to us pursuant to these Terms of
            Service. Any changes to these Terms of Service will be effective
            immediately for new users of the Services and will be effective
            thirty (30) days after posting notice of such changes on the Website
            for existing Members (defined below). We may also require you to
            provide consent to the updated Terms of Service in a specified
            manner before further use of the Services is permitted. If you do
            not agree to any change(s) after receiving a notice of such
            change(s), you shall stop using the Services. Otherwise, your
            continued use of the Services constitutes your acceptance of such
            change(s). PLEASE REGULARLY CHECK THE SERVICES TO VIEW THE
            THEN-CURRENT TERMS OF SERVICE.
            <Items sx={{ listStyleType: "decimal" }}>
              <Item>
                WHO WE ARE.
                <br />
                The Services are owned and operated by Nexvantage Technologies
                pvt ltd, a company incorporated in the India. Our registered
                office is at 566/11, Row House No 11, Ganatra Complex, Market
                Yard, Gultekdi Pune 411037. You can contact us at
                <br />
                <Link to="support@clout.ai">support@clout.ai</Link>
              </Item>
              <Item>
                WHO CAN USE THE SERVICES.
                <br />
                No one under the age of 18 is allowed to create an Account or
                otherwise use the Services. By using the Services, you represent
                that you are:
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    over the age of 18 and agree to form a binding contract with
                    us;
                  </Item>
                  <Item>
                    not barred from using the Services under the applicable law
                    of the country where you are located; and
                  </Item>
                  <Item>
                    not someone who has previously had an Account disabled on
                    the Services for a violation of these Terms of Service, any
                    applicable law or any of our policies.
                  </Item>
                </Items>
              </Item>

              <Item>
                Notwithstanding the foregoing, if you are creating an account as
                a Creator and you are between the ages of 15 and 17, you may use
                the Services with Clout prior approval and with a parent or
                guardian’s verified written consent.
              </Item>
              <Item>
                SERVICES.
                <br />
                The Services consist of the Clout website and mobile
                applications, the Clout platform and any other products and
                services made available by Clout. Clout is a platform where
                users can access premium information, data, text, music, sound,
                photographs, graphics, video, messages, tags and/or other
                materials (collectively, “Content”) made available by Clout
                and/or by certain content creators (“Creator(s),” and such
                Content “Creator Content”), join exclusive members-only
                communities for those Creators (“Membership Communities”),
                purchase and sell digital and tangible merchandise and
                collectibles through Membership Communities or the Platform (as
                defined below), and interact with Creators and other Members.
              </Item>
              <Item>
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    License to use the Services.
                    <br />
                    The Services and the information and content available on or
                    through them are protected by copyright laws. Subject to the
                    terms of these Terms of Service, Clout grants you a limited
                    license to access and use the Services on the basis that
                    this license is:
                    <Items sx={{ listStyleType: "lower-roman" }}>
                      <Item>
                        not a commercial license – you can only access and use
                        the Services for your own personal, non-commercial use;
                      </Item>
                      <Item>
                        non-transferable – you cannot pass this right to someone
                        else;
                      </Item>
                      <Item>
                        non-exclusive – other people can access and use the
                        Services;
                      </Item>
                      <Item>
                        revocable – we have the right to remove your ability to
                        use the Services at any time; and
                      </Item>
                      <Item>
                        limited – the license does not extend beyond what has
                        just been described above.
                      </Item>
                    </Items>
                  </Item>

                  <Item>
                    Updates.
                    <br />
                    You understand that the Services are evolving. As a result,
                    we may require you to accept updates to any of the Services
                    that you have installed on your computer or mobile device.
                    You acknowledge and agree that we may update the Services
                    with or without notifying you. You may need to update
                    third-party software from time to time in order to use the
                    Services.
                  </Item>

                  <Item>
                    Certain Restrictions.
                    <br />
                    The rights granted to you in these Terms of Service are
                    subject to the following restrictions:
                    <Items sx={{ listStyleType: "lower-roman" }}>
                      <Item>
                        You cannot violate someone else’s rights by
                        impersonating them, posting their private or
                        confidential information, or otherwise infringing their
                        intellectual property rights, publicity rights or right
                        to privacy.{" "}
                      </Item>
                      <Item>You must follow our Community Guidelines. </Item>
                      <Item>
                        You may not post content belonging to another person,
                        unless you have obtained a license or permission from
                        such person in advance.{" "}
                      </Item>
                      <Item>
                        You cannot use the Services to carry out or enable any
                        unlawful, misleading or otherwise fraudulent activity.{" "}
                      </Item>
                      <Item>
                        You cannot use the Services to promote or offer
                        commercial activities and/or sales, such as contests,
                        sweepstakes, advertising or pyramid schemes without our
                        prior written consent.{" "}
                      </Item>
                      <Item>
                        You cannot use the Services to carry out any financial
                        activities subject to registration or licensing,
                        including, but not limited to, using the Services to
                        transact in securities or other similar transactions.{" "}
                      </Item>
                      <Item>
                        You cannot use the Services to harass, intimidate,
                        defame, or bully other users.{" "}
                      </Item>
                      <Item>
                        You cannot conduct yourself in a vulgar, offensive,
                        harassing or objectionable manner while using the
                        Services.{" "}
                      </Item>
                      <Item>
                        You cannot use the Services to generate unsolicited
                        advertisement or spam.{" "}
                      </Item>
                      <Item>
                        You cannot engage in any activity that would interfere
                        with or impair the delivery of our Services or its
                        intended operation.{" "}
                      </Item>
                      <Item>
                        You cannot upload or otherwise send or transmit any
                        material that contains viruses, ‘Trojan horses’, worms
                        or any other computer code, files or programs designed
                        to interrupt, destroy or limit the functionality of the
                        Services or any associated infrastructure.{" "}
                      </Item>
                      <Item>
                        You cannot reproduce, copy, transmit, store, sell or
                        otherwise distribute the Services.{" "}
                      </Item>
                      <Item>
                        You cannot alter, translate, create derivative works
                        from, or try to reverse engineer the Services or any
                        associated infrastructure, or otherwise attempt to
                        derive the source code of the software that enables or
                        underlies the Services.{" "}
                      </Item>
                      <Item>
                        You cannot purchase and sell, or attempt to license, any
                        account (including your individual account) or other
                        data obtained through the Services or in the course of
                        using our Services.{" "}
                      </Item>
                      <Item>
                        You cannot frame or use framing techniques to enclose
                        any of our trademarks, logos or other content (including
                        images, text, page layout or form).{" "}
                      </Item>
                      <Item>
                        You cannot use any manual or automated software, devices
                        or other processes (including, but not limited to,
                        spiders, robots, scrapers, crawlers, avatars, data
                        mining tools or the like) to ‘scrape’ or download data
                        from any web pages on the Services (except that we may
                        choose to grant the operators of public search engines
                        revocable permission to use spiders to copy materials
                        from the Services for the sole purpose of and solely to
                        the extent necessary for creating publicly available
                        searchable indices of the materials, but not caches or
                        archives of such materials).{" "}
                      </Item>
                      <Item>
                        You cannot alter, remove or destroy any copyright
                        notices or other proprietary markings contained on or in
                        the Services.{" "}
                      </Item>
                    </Items>
                  </Item>
                </Items>
              </Item>

              <Item>
                ACCOUNT REGISTRATION.
                <br />
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Items>
                    Registering Your Account. <br />
                    In order to access certain features of the Services, you may
                    be required to become a Member. For purposes of these Terms
                    of Service, a “Member” is a user who has registered an
                    account on the Services (“Account”).
                  </Items>
                  <Items>
                    Registration Data.
                    <br />
                    In registering an Account on the Services, you agree to: (a)
                    provide true, accurate, current and complete information
                    about yourself as prompted by the registration form (the
                    “Registration Data”); maintain and promptly update the
                    Registration Data to keep it true, accurate, current and
                    complete. You are responsible for all activities that occur
                    under your Account. You agree that you will monitor your
                    Account to restrict use by minors, and you will accept full
                    responsibility for any unauthorized use of the Services by
                    minors. You agree to notify us immediately of any
                    unauthorized use of your password or any other breach of
                    security. If you provide any information that is untrue,
                    inaccurate, not current or incomplete, or we have reasonable
                    grounds to suspect that any information you provide is
                    untrue, inaccurate, not current or incomplete, we have the
                    right to suspend or terminate your Account and refuse any
                    and all current or future use of the Services. You agree not
                    to create an Account using a false identity or information,
                    or on behalf of someone other than yourself. You agree that
                    you will not have more than one Account at any given time.
                    We reserve the right to remove or reclaim any usernames at
                    any time and for any reason, including, but not limited to,
                    claims by a third-party that a username violates the
                    third-party’s rights.
                  </Items>
                  <Items>
                    Requests for Additional Information.
                    <br />
                    Clout may from time to time require you to provide
                    additional information and documents at the request of any
                    competent authority, in order to help Clout comply with
                    applicable law, regulation, or policy, including laws
                    related to money laundering and funds obtained by criminal
                    means, or for counteracting financing of terrorism, or
                    otherwise in Clout’ sole discretion. Clout may also require
                    you to provide additional information and documents in cases
                    where it has reasons to believe that: (a) your Account is
                    being used for money laundering or for any other illegal
                    activity; (b) you have concealed or reported false
                    identification information and other details; and/or (c)
                    transactions effected via your Account were effected in
                    breach of these Terms of Service. In such cases, Clout may,
                    in its sole discretion, pause or cancel your transactions
                    until such requested additional information and documents
                    have been reviewed by Clout and accepted as satisfying the
                    requirements of applicable law, regulation, or policy. If
                    you do not provide complete and accurate information and
                    documents in response to any such request from Clout, Clout
                    may refuse to provide any product, service, and/or further
                    access to the Services to you.
                  </Items>
                </Items>
              </Item>

              <Item>
                Your Account.
                <br />
                Prohibition on Sharing Accounts: Users are strictly prohibited
                from sharing their accounts with others, including friends,
                family members, or any third parties. This policy is
                particularly important for minors who may access the platform
                under the supervision of a parent or guardian. Each user is
                responsible for maintaining the confidentiality of their account
                credentials and is solely accountable for all activities
                conducted through their account. By ensuring that accounts are
                not shared, we aim to protect the safety and integrity of our
                platform, as well as to promote responsible usage among all
                users.
                <br />
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    You are responsible for maintaining the confidentiality of
                    your password and account details, if any, and are fully
                    responsible for any and all activities that occur under your
                    password or account. You agree to (a) immediately notify
                    Clout of any unauthorized use of your password or account or
                    any other breach of security, and (b) ensure that you exit
                    from your account at the end of each session when accessing
                    the Services. Clout will not be liable for any loss or
                    damage arising from your failure to comply with this
                    paragraph. You agree that Clout has no responsibility or
                    liability for the deletion or failure to store any data or
                    other content maintained or uploaded by the Services.
                  </Item>
                  <Item>
                    Necessary Equipment and Software. <br />
                    You must provide all equipment and software necessary to
                    connect to the Services. You are solely responsible for any
                    fees, including Internet connection or mobile fees, that you
                    incur when accessing or using the Services.
                  </Item>
                  <Item>
                    Relationship of the Parties. <br />
                    You are an independent contractor and not a partner, joint
                    venturer, agent or employee of Clout, and you will not bind
                    or attempt to bind Clout to any contract. Neither these
                    Terms of Service nor your use of the Services shall create
                    an association, partnership, joint venture, or relationship
                    of principal and agent, master and servant, or employer and
                    employee, between you and Clout.
                  </Item>
                </Items>
              </Item>

              <Item>
                USING THE SERVICES.
                <br />
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    Platform.
                    <br />
                    The Services include a platform (the “Platform”) where
                    Creators can sell benefits (“Benefits”), including, but not
                    limited to, “Subscriptions” whose ownership is stored on
                    digital ledgers. Pricing and payment terms, including the
                    specific details with relation to the offering of any
                    Subscriptions, shall be set forth at the point of sale.
                    Certain portions of the Platform may be accessible only
                    through the Membership Community of a particular Creator.
                    Subscriptions and Benefits listed on the Platform are being
                    offered by the individuals seeking to sell Subscriptions and
                    Benefits (“Sellers”), and the actual contracts for sale are
                    between the Sellers and the individuals seeking to buy
                    Subscriptions and/or Benefits (as applicable) (“Buyers”).
                    While we may, in our discretion, help facilitate resolution
                    of disputes through various programs, we have no control
                    over the quality of Subscriptions offered on the Platform
                    nor the Benefits offered by the Sellers. We cannot control
                    or guarantee the truth or accuracy of a Member’s or
                    Creator’s Content, the ability of the Creator to deliver the
                    Benefits, the ability of Sellers to sell Subscriptions, the
                    ability of Buyers to pay for Subscriptions, or that a Buyer
                    and Seller will actually complete a transaction or deliver
                    or return any Subscriptions listed on the Platform or
                    deliver any of the Benefits they offer. While Clout may
                    provide pricing and guidance for Subscriptions sold by
                    Sellers on our Platform, such information is solely
                    informational. We do not have control over the quality,
                    timing, legality, failure to provide, or any aspect
                    whatsoever of any reviews provided by Members, Subscriptions
                    sold by Sellers, Benefits offered or sold by the Creators or
                    of the integrity, responsibility, or any actions of any
                    Members. Clout makes no representations about the
                    suitability, reliability, timeliness or accuracy in public,
                    private or offline interactions. When interacting with other
                    Members you should exercise caution and common sense to
                    protect your personal safety and property, just as you would
                    when interacting offline with other persons whom you don’t
                    know. Clout IS NOT RESPONSIBLE FOR THE CONDUCT, WHETHER
                    ONLINE OR OFFLINE, OF ANY USER OF THE SERVICES. Clout WILL
                    NOT BE LIABLE FOR ANY CLAIM, INJURY OR DAMAGE ARISING IN
                    CONNECTION WITH YOUR INTERACTIONS WITH OTHER USERS OF THE
                    SERVICES OR YOUR USE OF OR INABILITY TO USE ANY
                    Subscriptions PURCHASED THROUGH THE SERVICES.
                  </Item>
                  <Item>
                    Purchasing Subscriptions through the Platform.
                    <br />
                    You may from time to time have the opportunity to purchase
                    Subscriptions through the Platform. Subscriptions may be
                    redeemed for or used to ‘unlock’ various goods, rights, and
                    other privileges on or through the Services, including, but
                    not limited to, unique Creator Content not otherwise
                    available to Members. The terms applicable to the purchase
                    of each Membership will be displayed to you at the point of
                    sale, and such terms will be viewable at all times
                    thereafter by the then-current owner of the relevant
                    Membership. When you purchase a Membership through the
                    Platform, you agree to comply with any terms, including
                    licenses or payment rights, that are embedded within or
                    otherwise included with such Membership, and to make
                    commercially reasonable efforts to bind subsequent owners of
                    such Subscriptions to any such terms. Unless otherwise
                    expressly set forth at the point of sale for such
                    Membership, ownership of a Subscriptions does not convey any
                    right, title or interest in or to the Content, including any
                    characters, artwork, or other work of authorship, embodied
                    by or linked to such Membership (such Content, “Membership
                    IP”). Unless otherwise expressly set forth at the point of
                    sale for such Membership, when you purchase a Membership you
                    shall receive in connection with such Membership a limited,
                    non-exclusive, non-sublicensable, non-transferable license
                    to view and display the Membership IP for your personal,
                    non-commercial use solely in accordance with the
                    functionality of the Services for so long as you own such
                    Membership.
                  </Item>
                  <Item>
                    Storage of Your Subscriptions.
                    <br />
                    Clout will record ownership of your Subscriptions on its
                    internal ledger. You will be able to access your
                    Subscriptions through your Account. If you lose your
                    Account, you may lose access to your Subscriptions. While
                    your Subscriptions may be interoperable with other
                    compatible platforms, digital wallets or other services,
                    only Subscriptions supported by Clout that are stored on the
                    Platform will be accessible through the Platform.
                    Subscriptions that have been transferred out of your Account
                    to a third-party platform, digital wallet or other service
                    may no longer be compatible with the Platform, and Clout
                    disclaims all liability in connection with Subscriptions
                    that have been so transferred.
                  </Item>
                  <Item>
                    Subscriptions Communities. <br />
                    Members may from time to time be given access to Membership
                    Communities through the Platform. Each Membership Community
                    has unique access requirements. Members may be able to use
                    certain Subscriptions to gain access to a Membership
                    Community. Once you join a Membership Community, you may
                    have access to certain Creator Content and/or Third-Party
                    Websites and/or Third-Party Applications (as defined below)
                    through such Membership Community.
                  </Item>
                  <Item>
                    Selling Subscriptions through the Platform.
                    <br />
                    PLEASE READ CAREFULLY THIS IMPORTANT INFORMATION ABOUT
                    PROCEDURES FOR SELLING Subscriptions THROUGH THE PLATFORM
                    BEFORE USING THE PLATFORM TO SELL Subscriptions. To help the
                    government fight the funding of terrorism and money
                    laundering activities, applicable law requires all financial
                    institutions to obtain, verify, and record information that
                    identifies each person who has an account which has the
                    ability to list items for sale on the Platform. When you
                    engage in transactions on the Platform, we may ask for your
                    name, address, date of birth, and other information that
                    will allow us and/or our Payment Processor(s) (as defined
                    below) to identify you. We may also ask for a copy of your
                    driver’s license or other identifying documents. We reserve
                    the right to require you to provide additional information
                    and documents in accordance with Section 4.3 of these Terms
                    of Service. Failure to provide such information upon
                    request, as well as any other violation of these Terms of
                    Service, may result in your removal from the Services,
                    including a termination of your ability to sell through the
                    Platform. <br />
                    <p style={{ textDecoration: "underline" }}>
                      Could lead to account suspension Failure to comply with
                      Clout's terms, including but not limited to engaging in
                      unlawful activities, violating the platform's content
                      guidelines, or providing false information during
                      registration, could lead to the suspension or termination
                      of your account. Additionally, activities such as
                      fraudulent transactions, misuse of the platform, or
                      attempts to undermine Clout's security measures may also
                      result in account suspension.
                    </p>
                  </Item>
                  <Item>
                    Restrictions Specific to Subscriptions.
                    <br />
                    You shall not (and shall not permit any third-party to) take
                    any action or Make Available any Content on or through the
                    Services that engages in or knowingly facilitates any
                    ‘front-running’, ‘wash trading’, ‘pump and dump trading’,
                    ‘ramping’, ‘cornering’ or fraudulent, deceptive or
                    manipulative trading activities, including: (a) trading a
                    Membership at successively lower or higher prices for the
                    purpose of creating or inducing a false, misleading or
                    artificial appearance of activity in such Membership, unduly
                    or improperly influencing the market price for such
                    Membership trading on the Services or establishing a price
                    which does not reflect the true state of the market in such
                    Membership; (b) executing or causing the execution of any
                    transaction in a Membership which involves no material
                    change in the beneficial ownership thereof; (c) entering any
                    order for the purchase or sale of a Membership with the
                    knowledge that an order of substantially the same size, and
                    at substantially the same price, for the sale of such
                    Membership, has been or will be entered by or for the same
                    or different parties; or (d) participating in, facilitating,
                    assisting or knowingly transacting with any pool, syndicate
                    or joint account organized for the purpose of unfairly or
                    deceptively influencing the market price of an a Membership.
                  </Item>
                  <Item>
                    Taxes on Transactions Involving Subscriptions.
                    <br />
                    You are solely responsible for determining what, if any,
                    taxes apply to transactions involving your Subscriptions.
                    Neither Clout nor any other Clout entity is responsible for
                    determining the taxes that may apply to transactions
                    involving Subscriptions.
                  </Item>
                  <Item>
                    Types of Subscriptions.
                    <br />
                    Clout offers two types of Membership: Subscriptions; and (b)
                    Super Subscriptions (each as defined below, and each a
                    Membership). Each of these Subscriptions allows you to enjoy
                    access to exclusive Content and/or Membership Communities,
                    as described at the point of sale. You can purchase a
                    Membership through the Services. By purchasing a Membership,
                    you authorize us to charge the relevant fees to the payment
                    method with which you placed the order.
                    <Items sx={{ listStyleType: "lower-roman" }}>
                      Subscriptions.
                      <br />
                      You can choose to subscription to access, view, and
                      interact with exclusive Content and/or Membership
                      Communities and/or enjoy other benefits (each a
                      “Subscription”). Subscriptions will automatically be
                      renewed at the end of the relevant subscription period at
                      the then-current subscription fee, except if your payment
                      method payment is declined or if you have cancelled your
                      Subscription. You have the right to cancel your
                      Subscription at any time, and if you do so you will be
                      permitted to enjoy the benefits attached to the
                      Subscription until the end of your subscription period in
                      which you cancelled, after which no further payments will
                      be taken from your payment method in respect of the
                      Subscription (unless you choose to renew your Subscription
                      at a later stage) and you will no longer be able to
                      continue to enjoy the benefits attached to the
                      Subscription.
                    </Items>
                  </Item>
                  <Item>
                    Account Management.
                    <br />
                    Certain Creators may use third party companies, agencies or
                    individuals to manage their account for them (including but
                    not limited to the posting of content and answering of
                    messages sent to the account), meaning that when interacting
                    with a Creator you may be interacting with a third party
                    company, agency or individual. Clout is not responsible for
                    your interaction with, or the management of, a Creator
                    account, nor is Clout responsible for ensuring your
                    interactions are with the Creator themselves. Clout WILL NOT
                    BE LIABLE FOR ANY CLAIM, INJURY OR DAMAGE ARISING IN
                    CONNECTION WITH YOUR INTERACTIONS WITH A CRETOR OR A THIRD
                    PARTY MANAGEMENT COMPANY, AGENCY OR INDIVIDUAL MANAGING THE
                    CREATOR’S ACCOUNT ON THEIR BEHALF. By linking your account
                    with third-party services such as Google, Facebook, or
                    Apple, you acknowledge and agree to assume full
                    responsibility for adhering to the policies and terms set
                    forth by those third-party providers, which may differ from
                    those of Clout. Additionally, creators who utilize
                    third-party account management services remain personally
                    liable for all content and offers made under their accounts.
                    This means that regardless of any third-party services used,
                    creators are accountable for ensuring that their content
                    complies with applicable laws and regulations and adheres to
                    our Terms of Service.
                    <br />
                    <br />
                    <p style={{ textDecoration: "underlined" }}>
                      Account sharing is prohibited, especially in the case of
                      minors. Parents or guardians supervising minors must
                      ensure that each user maintains their own account to
                      comply with Clout's terms and ensure security. This helps
                      to prevent unauthorized use and ensures that accounts are
                      used responsibly
                    </p>
                  </Item>
                </Items>
              </Item>

              <Item>
                RESPONSIBILITY FOR CONTENT. <br />
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    Types of Content. You acknowledge that all Content shall be
                    the sole responsibility of the party from whom such Content
                    originated. This means that you, and not Clout, are entirely
                    responsible for all Content that you upload, post, sell,
                    e-mail, transmit or otherwise make available (“Make
                    Available”) through the Services (“Your Content”), and that
                    other Members of the Services, and not Clout, are similarly
                    responsible for all Content that they Make Available through
                    the Services (“User Content”). User Content includes, but is
                    not limited to, Creator Content.
                  </Item>
                  <Item>
                    No Obligation to Pre-Screen Content. You acknowledge that we
                    have no obligation to pre-screen Content (including, but not
                    limited to, User Content), although we reserve the right in
                    our sole discretion to monitor, pre-screen, refuse or remove
                    any Content. By entering into these Terms of Service, you
                    hereby provide your irrevocable consent to such monitoring.
                    You acknowledge and agree that you have no expectation of
                    privacy concerning the transmission of Your Content,
                    including, but not limited to, chat, text, or voice
                    communications. In the event that Clout pre-screens, refuses
                    or removes any Content, you acknowledge that Clout will do
                    so for Clout’ benefit, not yours. Without limiting the
                    foregoing, we shall have the right to remove any Content
                    that violates these Terms of Service or that we otherwise
                    determine in our sole discretion is objectionable.
                  </Item>
                  <Item>
                    Storage. Unless expressly agreed to by us in writing
                    elsewhere, we have no obligation to store any of Your
                    Content that you Make Available on the Services. Clout has
                    no responsibility or liability for: (a) the deletion or
                    accuracy of any Content, including Your Content; (b) the
                    failure to store, transmit or receive transmission of
                    Content; or (c) the security, privacy, storage, or
                    transmission of other communications originating with or
                    involving use of the Services. Certain Services may enable
                    you to specify the level at which such Services restrict
                    access to Your Content. You are solely responsible for
                    applying the appropriate level of access to Your Content. If
                    you do not choose, the system may default to its most
                    permissive setting. You agree that we retain the right to
                    create reasonable limits on our use and storage of the
                    Content, including Your Content, such as limits on file
                    size, storage space, processing capacity, and similar limits
                    as determined by us in our sole discretion.
                  </Item>
                  <Item>
                    Prohibition on Leaked Content. You shall not distribute,
                    post, spread, display, transmit, publish or otherwise
                    provide any Restricted Content to other persons (“Leak”),
                    including to any public or private medium, platform, feed or
                    community. “Restricted Content” means any Content (other
                    than Your Content, but including any Creator Content or
                    other User Content) that is provided, streamed, made
                    available or otherwise accessed on or through the Services
                    on a restricted basis rather than on “public” areas of the
                    Services, such as, for example, Content provided to
                    Membership Communities or only to persons with a Membership,
                    or Content provided on an exclusive basis or via a private
                    message or a private, Membership-only or subscription only
                    feed. Without limiting any other rights, remedies or
                    recourse of Clout (including without limitation the
                    suspension or termination of your Clout Account or the
                    collection of damages), if you violate the foregoing
                    prohibition on Leaks, Clout may impose a leaking fee on you
                    to compensate Clout for the damages caused by such Leaks
                    which may be collected through your Payment Method or
                    otherwise in the same manner as other fees payable by you
                    under this Terms of Service. Such leaking fee shall be
                    calculated as follows: for every individual Leak (one piece
                    of Content such as one image or one video) by you, you may
                    be charged a leaking fee of $50 per site, platform or medium
                    on which the leaked Content can be found, accessed or
                    viewed. We reserve the right to impose such leaking fee for
                    up to three “instances” for a given piece of Leaked Content,
                    as we assume any additional Leaks may not be by the original
                    leaker. An instance is defined as a specified site, platform
                    or medium where the leaked Content can be found, access or
                    viewed, as indicated in a Leak report submitted to Clout or
                    as otherwise discovered or reported to us. If you do not
                    immediately remove all Content which you Leaked in violation
                    of this paragraph from all sites, platforms and medium, we
                    reserve the right to charge you an additional Leak
                    remediation fee of $150. The foregoing leaking fee and Leak
                    remediation fee are intended and designed to be a reasonable
                    estimate of damages incurred by Clout from unauthorized
                    Leaks and not as a penalty for such Leaks, and therefore
                    constitute liquidated damages as compensation to Clout for
                    such damages.
                  </Item>
                </Items>
              </Item>

              <Item>
                OWNERSHIP.
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    Services.
                    <br />
                    Except with respect to Your Content and User Content, you
                    agree that Clout and our licensors own all rights, title and
                    interest in and to the Services (including but not limited
                    to, any computer code, themes, objects, characters,
                    character names, stories, dialogue, concepts, artwork,
                    animations, sounds, musical compositions, audiovisual
                    effects, methods of operation, documentation and Clout
                    software).
                  </Item>
                  <Item>
                    Trademarks.
                    <br />
                    Clout and all related graphics, logos, service marks and
                    trade names used on or in connection with the Services are
                    the trademarks of Clout and may not be used without
                    permission in connection with your, or any third-party,
                    products or services. Other trademarks, service marks and
                    trade names that may appear on or in the Services are the
                    property of their respective owners.
                  </Item>

                  <Item>
                    Your Content.
                    <br />
                    Clout does not claim ownership of Your Content. However,
                    when you post or publish Your Content on, in or through the
                    Services, you warrant that you own and/or have a
                    royalty-free, perpetual, irrevocable, worldwide,
                    non-exclusive right and license to use, display, share, and
                    monetize on your behalf Your Content worldwide on, in or
                    through the Services.
                  </Item>
                  <Item>
                    License to Your Content.
                    <br />
                    Subject to any applicable account settings that you select,
                    you grant Clout a fully paid, royalty-free, perpetual,
                    irrevocable, worldwide, royalty-free, non-exclusive and
                    fully sublicensable right and license to use, license,
                    distribute, reproduce, modify, adapt, publicly perform, and
                    publicly display Your Content for the purposes of operating
                    and providing the Services to you and to our other Members
                    and to improve the Services and develop new products and
                    services. Please remember that other Members may search for,
                    see, use, modify and reproduce any of Your Content that you
                    submit to any ‘public’ area of the Services. You agree that
                    you, not Clout, are responsible for all of Your Content that
                    you Make Available on or in the Services.
                  </Item>
                  <Item>
                    User Content.
                    <br />
                    You may only use any User Content, whether as embedded in or
                    otherwise comprising a Membership or otherwise available on
                    the Services, in accordance with the terms of these Terms of
                    Service and with the terms of any additional right or
                    license granted expressly by the creator of such User
                    Content and, in the case of Subscriptions, as set forth at
                    the point of sale and solely if as applicable to you as a
                    Buyer. Username. Notwithstanding anything contained herein
                    to the contrary, by submitting Your Content to any ‘public’
                    area of the Services, you hereby expressly permit us to
                    identify you by your username (which may be a pseudonym) as
                    the contributor of Your Content.
                  </Item>
                  <Item>
                    Feedback.
                    <br />
                    You agree that submission of any ideas, suggestions,
                    documents, and/or proposals to Clout through our suggestion,
                    feedback, wiki, forum or similar pages (“Feedback”) is at
                    your own risk and that we have no obligations (including,
                    but not limited to, obligations of confidentiality) with
                    respect to such Feedback. You warrant that you have all
                    rights necessary to submit the Feedback. You hereby grant to
                    Clout a fully paid, royalty-free, perpetual, irrevocable,
                    worldwide, non-exclusive, and fully sublicensable right and
                    license to use and commercially or non-commercially exploit
                    in any manner any and all Feedback.
                  </Item>
                </Items>
              </Item>

              <Item>
                INTERACTIONS WITH OTHER USERS.
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    User Responsibility.
                    <br />
                    You are solely responsible for your interactions with other
                    Members and any other parties with whom you interact;
                    provided, however, that we reserve the right, but have no
                    obligation, to intercede in such disputes. You agree that
                    Clout will not be responsible for any liability incurred as
                    the result of such interactions.
                  </Item>
                  <Item>
                    Content Provided by Other Users.
                    <br />
                    The Services may contain User Content provided by other
                    Members. Clout is not responsible for and does not control
                    User Content. Clout has no obligation to review or monitor,
                    and does not approve, endorse or make any representations or
                    warranties with respect to, User Content. You use all User
                    Content and interact with other Members at your own risk.
                  </Item>
                </Items>
              </Item>

              <Item>
                FEES AND PURCHASE TERMS.
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    Fees. <br />
                    Registering for the Services is free; however, we charge
                    certain fees for various transactions through your use of
                    the Services (“Fees”) as set forth at the point of sale. All
                    amounts are quoted in US dollars unless otherwise stated.
                  </Item>

                  <Item>
                    Payment. <br />
                    You agree to pay any Fees or charges incurred by your
                    Account in accordance with the fees, charges and billing
                    terms then-currently in effect. You may be required to
                    provide us or our Payment Processor(s) (as defined below)
                    with a valid credit card, digital wallet address, bank
                    account information or other payment provider account
                    (“Payment Method”) as a condition to accessing certain
                    Services. By providing us with your Payment Method details,
                    you agree that Clout is authorized to immediately invoice
                    your Account for all fees and charges due and payable to us
                    hereunder and that no additional notice or consent is
                    required. We reserve the right at any time to change our
                    fees, prices, and/or billing methods, either immediately
                    upon posting on the Services or by e-mail delivery to you.
                  </Item>

                  <Item>
                    Taxes.
                    <br />
                    Any payments required may not include any Sales Tax that may
                    be due in connection with the Services provided. If Clout
                    determines it has a legal obligation to collect a Sales Tax
                    from you, Clout will collect such Sales Tax in addition to
                    the other payments required. If any Services or products, or
                    payments for any Services or products, are subject to any
                    Sales Tax in any jurisdiction and you have not paid the
                    applicable Sales Tax to Clout, you will be responsible for
                    the payment of such Sales Tax and any related penalties or
                    interest to the relevant tax authority, and you will
                    indemnify Clout for any liability or expense Clout may incur
                    in connection with such Sales Taxes. For purposes of this
                    section, “Sales Tax” means any sales or use tax and any
                    other tax measured by sales proceeds that is the functional
                    equivalent of a sales tax where the applicable taxing
                    jurisdiction does not otherwise impose a sales or use tax.
                  </Item>
                </Items>
              </Item>

              <Item>
                RELEASE.
                <br />
                Clout expressly disclaims any liability that may arise between
                users of its Services. The Platform is only a venue for
                connecting Buyers with Sellers. Because Clout is not a party to
                the actual contracts between Buyers and Sellers, in the event
                that you have a dispute with one or more users, you release
                Clout, its parents, subsidiaries, affiliates, officers,
                employees, investors, agents, partners and licensors, but
                excluding any users (each a “Clout Party” and collectively the
                “Clout Parties”) from any and all claims, demands, or damages
                (actual or consequential) of every kind and nature, known and
                unknown, suspected and unsuspected, disclosed and undisclosed,
                arising out of or in any way connected with such disputes.
              </Item>

              <Item>
                INDEMNIFICATION.
                <br />
                You agree to indemnify and hold harmless Clout and the Clout
                Parties from any losses, costs, liabilities and expenses
                (including reasonable attorneys’ fees) relating to or arising
                out of any and all of the following: (a) Your Content; (b) your
                use of the Services in breach of these Terms of Service; (c)
                your violation of any rights of another party, including any
                Members; (d) your purchase or attempt to purchase Subscriptions
                on or through the Services; (e) your sale or attempt to sell
                Subscriptions on or through the Services; or (f) your violation
                of any applicable laws, rules or regulations. Clout reserves the
                right, at its own cost, to assume the exclusive defense and
                control of any matter otherwise subject to indemnification by
                you, in which event you will fully cooperate with Clout in
                asserting any available defenses. You agree that the provisions
                in this section will survive any termination of your Account,
                these Terms of Service and/or your access to the Services.
              </Item>

              <Item>
                DISCLAIMER OF WARRANTIES AND CONDITIONS. <br />
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    AS IS.
                    <br />
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE FULLEST
                    EXTENT PERMITTED BY APPLICABLE LAW, YOUR USE OF THE SERVICES
                    IS AT YOUR SOLE RISK, AND THE SERVICES ARE PROVIDED ON AN
                    ‘AS IS’ AND ‘AS AVAILABLE’ BASIS.
                  </Item>
                  <Items sx={{ listStyleType: "lower-roman" }}>
                    <Item>
                      Clout EXPRESSLY DISCLAIMS ALL WARRANTIES, REPRESENTATIONS
                      AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                      INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OR
                      CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                      PURPOSE AND NON-INFRINGEMENT ARISING FROM USE OF THE
                      SERVICES. WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR
                      ANY LOSSES YOU SUSTAIN AS A RESULT OF YOUR USE OF THE
                      SERVICES. WE TAKE NO RESPONSIBILITY FOR, AND WILL NOT BE
                      LIABLE TO YOU FOR, ANY LOSSES, DAMAGES OR CLAIMS ARISING
                      FROM YOUR USE OF THE SERVICES INCLUDING, BUT NOT LIMITED
                      TO,: (1) USER ERROR SUCH AS FORGOTTEN PASSWORDS,
                      INCORRECTLY CONSTRUCTED TRANSACTIONS OR MISTYPED
                      ADDRESSES; (2) SERVER FAILURE OR DATA LOSS; (3) CORRUPTED
                      DIGITAL WALLET OR Subscriptions; (4) UNAUTHORIZED ACCESS
                      TO APPLICATIONS; OR (5) ANY UNAUTHORIZED THIRD-PARTY
                      ACTIVITIES, INCLUDING, BUT NOT LIMITED TO, THE USE OF
                      VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK
                      AGAINST THE SERVICE.{" "}
                    </Item>
                    <Item>
                      Clout MAKES NO WARRANTY, REPRESENTATION OR CONDITION THAT:
                      (1) THE SERVICES WILL MEET YOUR REQUIREMENTS; (2) YOUR USE
                      OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
                      ERROR-FREE; OR (3) THE RESULTS THAT MAY BE OBTAINED FROM
                      USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE.
                    </Item>

                    <Item>
                      ANY CONTENT DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH
                      THE SERVICES IS ACCESSED AT YOUR OWN RISK, AND YOU SHALL
                      BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY,
                      INCLUDING, BUT NOT LIMITED TO, YOUR COMPUTER SYSTEM AND
                      ANY DEVICE YOU USE TO ACCESS THE SERVICES, OR ANY OTHER
                      LOSS THAT RESULTS FROM ACCESSING SUCH CONTENT.
                    </Item>
                    <Item>
                      THE SERVICES MAY BE SUBJECT TO DELAYS, CANCELLATIONS AND
                      OTHER DISRUPTIONS. Clout MAKES NO WARRANTY, REPRESENTATION
                      OR CONDITION WITH RESPECT TO SERVICES, INCLUDING, BUT NOT
                      LIMITED TO, THE QUALITY, EFFECTIVENESS, REPUTATION AND
                      OTHER CHARACTERISTICS OF THE SERVICES.
                    </Item>
                    <Item>
                      OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM
                      Clout OR THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT
                      EXPRESSLY MADE HEREIN.
                    </Item>
                    <Item>
                      FROM TIME TO TIME, Clout MAY OFFER NEW ‘BETA’ FEATURES OR
                      TOOLS WITH WHICH ITS USERS MAY EXPERIMENT. SUCH FEATURES
                      OR TOOLS ARE OFFERED SOLELY FOR EXPERIMENTAL PURPOSES AND
                      WITHOUT ANY WARRANTY OF ANY KIND, AND MAY BE MODIFIED OR
                      DISCONTINUED WITHOUT NOTICE AT Clout’ SOLE DISCRETION.
                    </Item>
                    <Item>
                      Clout DOES NOT GUARANTEE THAT ANY MEMBERSHIP OR OTHER
                      CONTENT WILL BE AVAILABLE AT ALL TIMES, IN ALL LOCATIONS
                      OR AT ANY GIVEN TIME, OR THAT Clout WILL CONTINUE TO OFFER
                      A PARTICULAR SERVICE OR MEMBERSHIP FOR ANY PARTICULAR
                      LENGTH OF TIME. Clout DOES NOT GUARANTEE THAT
                      Subscriptions WILL BE INTEROPERABLE WITH OR ACCESSIBLE
                      THROUGH ANY THIRD-PARTY SERVICE PROVIDERS.
                    </Item>
                  </Items>

                  <Item>
                    NO LIABILITY FOR CONDUCT OF OTHER USERS. YOU ARE SOLELY
                    RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS
                    WITH OTHER USERS OF THE SERVICES. YOU UNDERSTAND THAT Clout
                    DOES NOT MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS
                    OF THE SERVICES. Clout MAKES NO WARRANTY THAT THE SERVICES
                    OR Subscriptions PROVIDED BY THIRD-PARTIES WILL MEET YOUR
                    REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE OR
                    ERROR-FREE BASIS. Clout MAKES NO WARRANTY REGARDING THE
                    ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR
                    RELIABILITY OF ANY USER CONTENT OBTAINED THROUGH THE
                    SERVICES.
                  </Item>
                </Items>
              </Item>

              <Item>
                EXCLUSION AND LIMITATION OF LIABILITY. <br />
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    EXCLUSION OF CERTAIN DAMAGES. YOU UNDERSTAND AND AGREE THAT,
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                    EVENT SHALL Clout OR ITS AFFILIATES BE LIABLE FOR ANY LOSS
                    OF PROFITS, REVENUE OR DATA, INDIRECT, INCIDENTAL, SPECIAL,
                    OR CONSEQUENTIAL DAMAGES, OR DAMAGES OR COSTS DUE TO LOSS OF
                    PRODUCTION OR USE, BUSINESS INTERRUPTION, OR PROCUREMENT OF
                    SUBSTITUTE GOODS OR SERVICES, IN EACH CASE WHETHER OR NOT
                    Clout HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
                    ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF SERVICE
                    OR YOUR USE OF THE SERVICES. THE FOREGOING LIMITATION OF
                    LIABILITY SHALL NOT APPLY TO LIABILITY FOR: (1) DEATH OR
                    PERSONAL INJURY CAUSED BY NEGLIGENCE; (2) FRAUD OR
                    FRAUDULENT MISREPRESENTATION; OR (3) ANY OTHER LIABILITY
                    WHICH MAY NOT BE EXCLUDED PURSUANT TO APPLICABLE LAW.
                  </Item>
                  <Item>
                    CAP ON LIABILITY.
                    <br />
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, Clout AND
                    ITS AFFILIATES WILL NOT BE LIABLE TO YOU FOR MORE THAN THE
                    GREATER OF: (1) THE TOTAL AMOUNT PAID TO Clout BY YOU DURING
                    THE ONE-MONTH PERIOD PRIOR TO THE ACT, OMISSION OR
                    OCCURRENCE GIVING RISE TO SUCH LIABILITY; OR (2) $100. THE
                    FOREGOING CAP ON LIABILITY SHALL NOT APPLY TO LIABILITY FOR:
                    (A) DEATH OR PERSONAL INJURY CAUSED BY NEGLIGENCE; (B) FRAUD
                    OR FRAUDULENT MISREPRESENTATION; OR (C) ANY OTHER LIABILITY
                    WHICH MAY NOT BE EXCLUDED PURSUANT TO APPLICABLE LAW.
                  </Item>

                  <Item>
                    USER CONTENT.
                    <br />
                    EXCEPT FOR Clout’ OBLIGATIONS TO PROTECT YOUR PERSONAL DATA
                    AS SET FORTH IN Clout’ PRIVACY POLICY, Clout AND ITS
                    AFFILIATES ASSUME NO RESPONSIBILITY FOR THE TIMELINESS,
                    DELETION, MIS-DELIVERY OR FAILURE TO STORE ANY CONTENT
                    (INCLUDING, BUT NOT LIMITED TO, YOUR CONTENT, USER CONTENT
                    OR Subscriptions), USER COMMUNICATIONS OR PERSONALIZATION
                    SETTINGS.
                  </Item>

                  <Item>
                    EXCLUSION OF DAMAGES.
                    <br />
                    CERTAIN JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                    LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU,
                    SOME OR ALL OF THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT
                    APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
                  </Item>
                </Items>
              </Item>

              <Item>
                PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT. It is
                Clout’ policy to terminate membership privileges of any Member
                who repeatedly infringes copyright upon prompt notification to
                Clout by the copyright owner or the copyright owner’s legal
                agent. Without limiting the foregoing, if you believe that your
                work has been copied and posted on the Services in a way that
                constitutes copyright infringement.
              </Item>

              <Item>
                MONITORING AND ENFORCEMENT. <br />
                Clout reserves the right to: (a) remove or refuse to post any of
                Your Content for any or no reason in our sole discretion; (b)
                take any action with respect to any of Your Content that we deem
                necessary or appropriate in our sole discretion, including if we
                believe that such Content violates these Terms of Service,
                infringes any intellectual property right or other right of any
                person or entity, threatens the personal safety of users of the
                Services or the public, or could create liability for Clout; (c)
                disclose your identity or other information about you to any
                third-party who claims that material posted by you violates
                their rights, including their intellectual property rights or
                their right to privacy; (d) take appropriate legal action,
                including, but not limited to, referral to law enforcement, for
                any illegal or unauthorized use of the Services; and/or (e)
                terminate or suspend your access to all or part of the Services
                for any or no reason, including, but not limited to, any
                violation of these Terms of Service.
              </Item>

              <Item>
                MISCELLANEOUS. <br />
                All items are non-refundable. All payments must be at least $3,
                and there are no maximum payment thresholds. We accept payments
                in US dollars.
              </Item>

              <Item>
                TERM AND TERMINATION.
                <br />
                All items are non-refundable. All payments must be at least $3,
                and there are no maximum payment thresholds. We accept payments
                in US dollars.
                <Items>
                  <Item>
                    Term.
                    <br />
                    These Terms of Service commence on the date when you accept
                    them (as described in the preamble above) and remain in full
                    force and effect while you use the Services, unless
                    terminated earlier in accordance with these Terms of
                    Service.
                  </Item>

                  <Item>
                    Termination by You.
                    <br />
                    If you want to terminate your use of the Services, you may
                    do so by: (a) notifying us at any time; and/or (b) closing
                    your Account for all of the Services that you use, provided,
                    however, that notwithstanding any such termination and for
                    the avoidance of doubt, the terms of these Terms of Service
                    shall continue to apply with respect to any Membership or
                    other digital asset obtained by you through the Platform.
                  </Item>

                  <Item>
                    Termination by Clout.
                    <br />
                    Clout reserves the right to terminate your access to some or
                    all of the Services, including by closing your Account,
                    without notice to you in the event we reasonably believe
                    that you are in breach of any part of these Terms of
                    Service.
                  </Item>

                  <Item>
                    Termination by You.
                    <br />
                    If you want to terminate your use of the Services, you may
                    do so by: (a) notifying us at any time; and/or (b) closing
                    your Account for all of the Services that you use, provided,
                    however, that notwithstanding any such termination and for
                    the avoidance of doubt, the terms of these Terms of Service
                    shall continue to apply with respect to any Membership or
                    other digital asset obtained by you through the Platform.
                  </Item>

                  <Item>
                    Effect of Termination..
                    <br />
                    Termination of any Services includes removal of access to
                    such Services and barring of further use of the Services.
                    Termination of all Services also includes deletion of your
                    Account and all related information, files and Content
                    associated with or inside your Account (or any part
                    thereof), including Your Content. Upon termination of any
                    Service, your right to use such Service will automatically
                    terminate immediately. You understand that any termination
                    of Services may involve deletion of Your Content associated
                    therewith from our live databases. Clout will not have any
                    liability whatsoever to you for any suspension or
                    termination, including for deletion of Your Content. All
                    provisions of these Terms of Service which by their nature
                    should survive, shall survive termination of Services,
                    including, but not limited to, ownership provisions,
                    warranty disclaimers, and limitation of liability.
                  </Item>

                  <Item>
                    No Subsequent Registration.
                    <br />
                    If your registration(s) with, or ability to access, the
                    Services or any other Clout community, is discontinued by us
                    due to your violation of any portion of these Terms of
                    Service or for conduct otherwise inappropriate for the
                    community, then you agree that you shall not attempt to
                    re-register with or access the Services or any Clout
                    community through use of a different member name or
                    otherwise, and you acknowledge that you will not be entitled
                    to receive a refund for fees related to those Services to
                    which your access has been terminated. In the event that you
                    violate the immediately preceding sentence, Clout reserves
                    the right, in its sole discretion, to immediately take any
                    or all of the actions set forth herein without any notice or
                    warning to you.
                  </Item>
                </Items>
              </Item>

              <Item>
                INTERNATIONAL USERS.
                <br />
                The Services can be accessed from countries around the world and
                may contain references to Services and Content that are not
                available in your country. These references do not imply that
                Clout intends to announce such Services or Content in your
                country. The Services are controlled and offered by Clout from
                its facilities in the India. Clout provides no warranties that
                the Services are appropriate or available for use in other
                locations. Those who access or use the Services from other
                countries do so at their own volition and are responsible for
                compliance with local law.
              </Item>

              <Item>
                THIRD-PARTY SERVICES.
                <br />
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    Third-Party Payment Processing.
                    <br />
                    Payment processing for the Services will be performed by
                    Clout’ third-party payment processors (each, a “Payment
                    Processor”). Your use of the payment processing facility
                    provided by the Payment Processor is subject to the Payment
                    Processor’s own terms and conditions, as may be modified by
                    the Payment Processor from time to time. As a condition of
                    using the Payment Processor’s payment processing facility,
                    you must provide accurate and complete information, and you
                    authorize us to share this information with the Payment
                    Processor and to charge your payment method for all amounts
                    that may become due under these Terms of Service. All bank,
                    credit card or other payment information is sent directly to
                    and stored with the Payment Processor using its security
                    protocols. Clout does not store your payment information on
                    its systems and shall not have any responsibility for the
                    safety or security of that information.
                  </Item>

                  <Item>
                    Third-Party Websites, Applications and Ads. The Services may
                    contain links to third-party websites (“Third-Party
                    Websites”), applications (“Third-Party Applications”) and
                    advertisements for third parties (“Third-Party Ads”). When
                    you click on a link to a Third-Party Website, Third-Party
                    Application or Third-Party Ad, we will not warn you that you
                    have left the Services and are subject to the terms and
                    conditions (including privacy policies) of another website
                    or service. Such Third-Party Websites, Third-Party
                    Applications and Third-Party Ads are not under the control
                    of Clout. Clout is not responsible for any Third-Party
                    Websites, Third-Party Applications or Third-Party Ads. Clout
                    provides these Third-Party Websites, Third-Party
                    Applications and Third-Party Ads only as a convenience and
                    does not review, approve, monitor or endorse any Third-Party
                    Websites, Third-Party Applications or Third-Party Ads.
                  </Item>
                </Items>
              </Item>

              <Item>
                GENERAL PROVISIONS.
                <br />
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    Electronic Communications.
                    <br />
                    The communications between you and Clout may take place via
                    electronic means, whether you visit the Services or send
                    Clout e-mails, or whether Clout posts notices on the
                    Services or communicates with you via e-mail. For
                    contractual purposes, you: (a) consent to receive
                    communications from us in an electronic form; and (b) agree
                    that all terms and conditions, agreements, notices,
                    disclosures and other communications that we provide to you
                    electronically satisfy any legal requirement that such
                    communications would satisfy if it were to be in writing.
                  </Item>

                  <Item>
                    Assignment.
                    <br />
                    These Terms of Service, and your rights and obligations
                    hereunder, may not be assigned, subcontracted, delegated or
                    otherwise transferred by you without our prior written
                    consent, and any attempted assignment, subcontract,
                    delegation or transfer in violation of the foregoing will be
                    null and void.
                  </Item>

                  <Item>
                    Force Majeure.
                    <br />
                    Clout shall not be liable for any delay or failure to
                    perform resulting from causes outside its reasonable
                    control, including, but not limited to, acts of God, war,
                    terrorism, riots, embargos, acts of civil or military
                    authorities, fire, floods, pandemics, epidemics, accidents,
                    strikes or shortages of transportation facilities, fuel,
                    energy, labor or materials.
                  </Item>

                  <Item>
                    Questions, Complaints, Claims.
                    <br />
                    If you have any questions, complaints or claims with respect
                    to the Services, please follow the instructions in our
                    Complaints Policy. We will do our best to address your
                    concerns. If you feel that your concerns have been addressed
                    incompletely, we invite you to let us know for further
                    investigation by{" "}
                    <Link to="support@clout.ai.">support@clout.ai.</Link>
                  </Item>
                  <Item>
                    Exclusive Venue.
                    <br />
                    Both you and we agree that all claims and disputes arising
                    out of or relating to these Terms of Service will be to the
                    non-exclusive jurisdiction of the State of New York.
                  </Item>
                  <Item>
                    Governing Law.
                    <br />
                    The Terms of Service and any action related thereto will be
                    governed and interpreted by and under the laws of the State
                    of New York. You acknowledge that the Platform is controlled
                    in and originates from the India and that only persons
                    located in the India are currently permitted to access the
                    Platform as Users. We make no claims that the Platform or
                    any of the Content is accessible or appropriate outside of
                    India. Access to the Platform may not be legal by certain
                    persons or in certain countries. If you choose to access the
                    Platform from outside India, you do so on your own
                    initiative, at your own risk, and you are responsible for
                    compliance with applicable local laws.
                  </Item>
                  <Item>
                    Choice of Language.
                    <br />
                    It is the express wish of the parties that these Terms of
                    Service and all related documents have been drawn up in
                    English.
                  </Item>
                  <Item>
                    Questions, Complaints, Claims.
                    <br />
                    If you have any questions, complaints or claims with respect
                    to the Services, please follow the instructions in our
                    Complaints Policy. We will do our best to address your
                    concerns. If you feel that your concerns have been addressed
                    incompletely, we invite you to let us know for further
                    investigation by{" "}
                    <Link to="support@clout.ai.">support@clout.ai.</Link>
                  </Item>
                  <Item>
                    Notice.
                    <br />
                    Where Clout requires that you provide an e-mail address, you
                    are responsible for providing us with your most current
                    e-mail address. In the event that the last e-mail address
                    you provided to us is not valid, or for any reason is not
                    capable of delivering to you any notices required/ permitted
                    by these Terms of Service, our dispatch of the e-mail
                    containing such notice will nonetheless constitute effective
                    notice. You may give notice to us at{" "}
                    <Link to="support@clout.ai.">support@clout.ai.</Link>
                  </Item>
                  <Item>
                    Waiver.
                    <br />
                    Any waiver or failure to enforce any provision of these
                    Terms of Service on one occasion will not be deemed a waiver
                    of any other provision or of such provision on any other
                    occasion.
                  </Item>
                  <Item>
                    Severability.
                    <br />
                    If any portion of these Terms of Service is held invalid or
                    unenforceable, that portion shall be construed in a manner
                    to reflect, as nearly as possible, the original intention of
                    the parties, and the remaining portions shall remain in full
                    force and effect.
                  </Item>

                  <Item>
                    Third-Party Rights.
                    <br />
                    These Terms of Services are between you and us, and no other
                    person shall have any rights to enforce any of its terms.
                  </Item>

                  <Item>
                    Entire Agreement.
                    <br />
                    These Terms of Service, together with any Supplemental
                    Terms, are the final, complete and exclusive agreement of
                    the parties with respect to the subject matter hereof and
                    supersede all prior discussions between the parties with
                    respect to such subject matter.
                  </Item>
                </Items>
              </Item>
            </Items>
            <br />
            <br />
            <br />
            <DecorationContainer>◈ ◈ ◈</DecorationContainer>
          </SectionContent>
        </Section>
        <br />
        <br />
        <br />
        <Section>
          <SectionTitle>CREATOR TERMS OF SERVICE</SectionTitle>
          <br />
          <SectionContent>
            Effective as of October 01, 2024. <br />
            <br />
            Welcome, and thank you for your interest in the Clout Platform
            (“Clout”, “we” or “us”) and becoming a Creator on our Platform.{" "}
            <br />
            PLEASE READ THESE CREATOR TERMS OF SERVICE (“CREATOR TERMS OF
            SERVICE”) CAREFULLY. <br />
            While we’ve taken all efforts to explain your position and
            obligations as a Creator on our Services to you in as simple and
            straightforward a manner as possible, there will inevitably be
            clauses that do contain a little bit of ‘legalease’. If you require
            any further explanation or clarification with regards to any of the
            following, please feel free to get in touch with us and we would be
            more than happy to provide further explanation and assistance.{" "}
            <br />
            THESE CREATOR TERMS OF SERVICE ARE ADDITIONAL TERMS WHICH APPLY TO
            YOU IF YOU USE OUR SERVICES AS A CREATOR. NOTWITHSTANDING BEING
            BOUND BY THESE TERMS, YOU WILL CONTINUE TO BE SUBJECT TO THE
            PLATFORM TERMS OF SERVICE. IN THE EVENT OF ANY INCONSISTENCY BETWEEN
            THE PLATFORM TERMS OF SERVICE AND THESE CREATOR TERMS OF SERVICE,
            THESE CREATOR TERMS OF SERVICE WILL PREVAIL TO THE EXTENT OF SUCH
            INCONSISTENCY. <br />
            PLEASE NOTE THAT THESE CREATOR TERMS OF SERVICE ARE SUBJECT TO
            CHANGE BY US IN OUR SOLE DISCRETION AT ANY TIME. When changes are
            made, we will make a new copy of these Creator Terms of Service
            available on or through the Services. We will also update the “Last
            Updated” date at the top of these Creator Terms of Service. If we
            make any material changes, and you have registered with us to create
            a Creator account (“Creator Account”), we may also send an e-mail to
            you at the last e-mail address you provided to us. Any changes to
            these Creator Terms of Service will be effective immediately for new
            Creators on the Services and will be effective thirty (30) days
            after posting notice of such changes on the Website for existing
            Creators. We may also require you to provide consent to the updated
            Creator Terms of Service in a specified manner before further use of
            the Services as a Creator is permitted. If you do not agree to any
            change(s) after receiving a notice of such change(s), you shall stop
            using the Services as a Creator. Otherwise, your continued use of
            the Services as a Creator constitutes your acceptance of such
            change(s). PLEASE REGULARLY CHECK THE SERVICES TO VIEW THE
            THEN-CURRENT CREATOR TERMS OF SERVICE. <br />
            Unless defined in these Creator Terms of Service, the defined terms
            used in these Creator Terms of Service are as defined in the
            Platform Terms of Service. <br />
            <br />
            <Items sx={{ listStyleType: "decimal" }}>
              <Item>
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    Using our Services as a Creator When registering with us or
                    using the Services as a Creator, you agree and acknowledge
                    that you are responsible for:
                    <Items sx={{ listStyleType: "lower-roman" }}>
                      <Item>any and all activity on your Creator Account;</Item>

                      <Item>
                        any interaction from your Creator Account with any other
                        users of our Services;
                      </Item>
                      <Item>
                        the Creator Content uploaded, posted, published or
                        displayed using your Creator Account; and
                      </Item>
                      <Item>
                        any offers and/or Subscription Benefits you provide,
                        and/or products you sell using your Creator Account.
                      </Item>
                    </Items>
                  </Item>
                  <Item>
                    Offers and Subscription Benefits
                    <br />
                    When providing certain sales, offers, Subscription Benefits,
                    or other services to other users of our Services (whether
                    these are one-off benefits or recurring benefits (e.g. a
                    monthly recurring offering)), you acknowledge and agree that
                    you are solely responsible for providing and honoring those
                    sales, offers and Subscription Benefits (e.g. should you
                    offer monthly video call sessions as part of your
                    subscription, you are solely responsible for providing those
                    monthly video call sessions). You warrant that you will
                    honor the offers and Subscription Benefits you offer via the
                    Services, and acknowledge that Clout is not responsible for
                    ensuring you deliver and/or honor the offers, sales and/or
                    Subscription Benefits made available by you.
                  </Item>

                  <Item>
                    Binding Terms
                    <br />
                    When using our Services you acknowledge that you shall
                    comply in full with these Creator Terms of Service, our
                    Platform Terms of Service and all other Supplemental Terms
                    which may apply to your use of the Services as a Creator.
                    Every Creator is bound by these Creator Terms of Service. If
                    you have an agent, agency, management company or other
                    third-party assisting you with the operations of your
                    Creator Account (or which operate it on your behalf), this
                    does not affect your personal responsibility.
                  </Item>
                </Items>
              </Item>

              <Item>
                CREATOR CONTENT
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    Creator Content Any Creator Content uploaded, published,
                    displayed or posted to the Platform by you shall comply with
                    the Terms of Service and Creator Terms of Service. Clout
                    does not claim ownership of your Creator Content, however,
                    when you post or publish your Creator Content on, in or
                    through the Services, you warrant that you own and/or have a
                    royalty-free, perpetual, irrevocable, worldwide,
                    non-exclusive right and license to use, display, share and
                    exploit your Creator Content worldwide on, in or through the
                    Services. You warrant that your Creator Content is of
                    satisfactory quality, taking into account any description of
                    your Creator Content, the price and all other circumstances
                    including any statement or representation which you make
                    about the nature of your Creator Content on your account and
                    is as described by you.
                  </Item>

                  <Item>
                    No endorsements
                    <br />
                    We are not responsible for and do not endorse any aspect of
                    any Creator Content posted by you.
                  </Item>

                  <Item>
                    License to Creator Content.
                    <br />
                    Subject to any applicable account settings that you select,
                    you grant Clout a fully paid, royalty-free, perpetual,
                    irrevocable, worldwide, royalty-free, non-exclusive and
                    fully sublicensable right and license to use, license,
                    distribute, reproduce, modify, adapt, publicly perform, and
                    publicly display your Creator Content for the purposes of
                    operating and providing the Services to you and to our other
                    Members and to improve the Services and develop new products
                    and services. Please remember that other Members may search
                    for, see, use, modify and reproduce any of your Creator
                    Content that you submit to any ‘public’ area of the
                    Services. You agree that you, not Clout, are responsible for
                    all of the Creator Content that you make available on or in
                    the Services.
                  </Item>

                  <Item>
                    Liability and Indemnification.
                    <br />
                    You agree to indemnify and hold harmless Clout and the Clout
                    Parties from any losses, costs, liabilities and expenses
                    (including reasonable attorneys’ fees) relating to or
                    arising out or your Creator Content and any Subscription
                    Benefits you offer. Clout reserves the right, at its own
                    cost, to assume the exclusive defense and control of any
                    matter otherwise subject to indemnification by you, in which
                    event you will fully cooperate with Clout in asserting any
                    available defenses. You agree that the provisions in this
                    section will survive any termination of your Creator
                    Account, these Creator Terms of Service and/or your access
                    to the Services.
                  </Item>
                  <Item>
                    Publicity
                    <br />
                    You agree to indemnify and hold harmless Clout and the Clout
                    Parties from any losses, costs, liabilities and expenses
                    (including reasonable attorneys’ fees) relating to or
                    arising out or your Creator Content and any Subscription
                    Benefits you offer. Clout reserves the right, at its own
                    cost, to assume the exclusive defense and control of any
                    matter otherwise subject to indemnification by you, in which
                    event you will fully cooperate with Clout in asserting any
                    available defenses. You agree that the provisions in this
                    section will survive any termination of your Creator
                    Account, these Creator Terms of Service and/or your access
                    to the Services.
                  </Item>
                  <Item>
                    Usage Data
                    <br />
                    You hereby authorize Company and its third-party service
                    providers to collect and analyze User Content and other data
                    and information relating to the Service and related systems
                    and technologies and derive statistical and usage data
                    relating thereto (collectively, “Usage Data”). We may use
                    Usage Data for any purpose in accordance with applicable law
                    and our Privacy Policy. As a Creator, Usage Data may include
                    data and information relating your use and activity on
                    Clout, including your earnings, audience and viewership
                    metrics, and other similar analytics.
                  </Item>
                </Items>
              </Item>

              <Item>
                PAYMENTS TO SELLERS
                <Items sx={{ listStyleType: "lower-alpha" }}>
                  <Item>
                    Sale by a Seller In the event of a sale of a Membership or
                    other Creator Content or a Tip by you through the Platform
                    (a “Sale”), Clout shall pay you a percentage (%) of Clout’
                    Net Consideration in respect of the Sale (the “Sale
                    Proceeds”). For the purpose of this Section, “Net
                    Consideration” shall mean, with respect to any Sale, the
                    total consideration actually received by Clout in connection
                    with such Sale less any fees, taxes, and third-party
                    expenses (e.g. any ‘gas’ or other payment or transaction
                    processing fees).
                  </Item>

                  <Item>
                    Payment Terms When cashing out, you can either select to
                    receive your funds via bank transfer. We take a 20% fee of
                    all credit/debit Sales. By default, Clout shall pay Sale
                    Proceeds owed to you bi-weekly within thirty (30) days of
                    the end of each such bi-weekly period to your bank account
                    as identified in your Creator Account.
                  </Item>

                  <Item>
                    Withholding of payment Clout may withhold all or any part of
                    the Sale Proceeds due to you but not yet paid out if:
                    <Items sx={{ listStyleType: "lower-roman" }}>
                      <Item>
                        we reasonably believe that you have breached any part of
                        these Creator Terms of Service or any part of the
                        Platform Terms of Service;
                      </Item>
                      <Item>
                        you attempt or threaten to breach any part of these
                        Creator Terms of Service or any part of the Platform
                        Terms of Service in a way which we reasonably believe
                        could harm Clout or another Creator or Member;
                      </Item>

                      <Item>
                        we reasonably suspect that all or any part of the Sale
                        Proceeds result from unlawful or fraudulent activity,
                        either by you or by the Buyer;
                      </Item>

                      <Item>
                        we reasonably suspect that the relevant Creator Content
                        infringes any third-party intellectual property; or
                      </Item>

                      <Item>
                        we have received complaints from our Members that you do
                        not provide the Subscription Benefits associated with
                        the Access purchased by the Buyer,
                      </Item>
                    </Items>
                  </Item>
                </Items>
              </Item>

              <Item>
                for as long as is necessary for us to investigate the actual,
                threatened or suspected breach by you or the suspected unlawful
                activity (as applicable). We shall not have any responsibility
                to you if we withhold any of your Sale Proceeds where we have a
                right to do so under these Creator Terms of Service.
              </Item>
            </Items>
            <br />
            <br />
            <br />
            <DecorationContainer>◈ ◈ ◈ </DecorationContainer>
            <br />
            <br />
            <br />
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>MOBILE TERMS OF SERVICE </SectionTitle>
          <br />
          <SectionContent>
            Effective as of October 01, 2024.
            <br />
            <br />
            Clout Messaging
            <Items sx={{ listStyleType: "decimal" }}>
              <Item>
                As part of the Clout Messaging program, you can expect to
                receive messages as configured and detailed in the SMS
                Notification Center, including, but not limited to, messages for
                every direct message you receive and messages for every new post
                from creators you follow.
              </Item>
              <Item>
                You can cancel the SMS service at any time. Visit here to adjust
                your notification preferences. Alternatively, you may text
                “STOP” to the short code. If you send the SMS message “STOP” to
                us, we will send you an SMS message to confirm that you have
                been unsubscribed. After this, you will no longer receive SMS
                messages from us. If you want to join again, text “START” to the
                short code and we will start sending SMS messages to you again.
              </Item>
              <Item>
                If you are experiencing issues with the messaging program you
                can reply with the keyword HELP for more assistance, or you can
                get help directly at{" "}
                <Link to="support@clout.ai.">support@clout.ai.</Link>
              </Item>
              <Item>
                Carriers are not liable for delayed or undelivered messages
              </Item>
              <Item>
                As always, message and data rates may apply for any messages
                sent to you from us and to us from you. Message frequency
                varies. If you have any questions about your text plan or data
                plan, it is best to contact your wireless provider.
              </Item>
              <Item>
                If you have any questions regarding privacy, please read our
                privacy policy.
              </Item>
            </Items>
          </SectionContent>

          <br />
          <br />
          <br />

          <DecorationContainer>◈ ◈ ◈</DecorationContainer>

          <br />
          <br />
          <br />
        </Section>
      </Content>
    </PolicyContainer>
  );
};

export default TermsAndConditionPage;
