import { UserFeedType } from "../types";

export const getContentInfo = (post:UserFeedType) => {
    const { contents } = post;
    const photosCount = contents.filter(item => item.type !== 'VIDEO').length;
    const videosCount = contents.filter(item => item.type === 'VIDEO').length;
    if (!videosCount) {
      // adding 's' if there is more than one photo or video
      return `${photosCount} Photo${photosCount > 1 ? 's' : ''}`;
    }
    if (!photosCount) {
      // adding 's' if there is more than one photo or video
      return `${videosCount} Video${videosCount > 1 ? 's' : ''}`;
    }
    // adding 's' if there is more than one photo or video
    return `${videosCount} Video${videosCount > 1 ? 's' : ''} & ${photosCount} Photo${photosCount > 1 ? 's' : ''}`;
  };
  