import { Box, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SubscriptionHeader from "../ProfileView/Creator/CreatorSubscriptions/SubscriptionHeader";
import BookSlotDetails from "../../components/BookSlot/BookSlotDetails";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

const BookSlot = () => {
  const navigate = useNavigate();

  const handleOnClickGoBackButton = () => {
    navigate(-1);
  };

  return (
    <Container>
      <SubscriptionHeader
        title="Book Your Slot"
        handleOnClickGoBackButton={handleOnClickGoBackButton}
      />
      <BookSlotDetails />
    </Container>
  );
};

export default BookSlot;
