import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { typography, colors } from "../../utils/styles";
import SuccessLogo from "../Common/SuccessLogo";
import AuthApi from "../../api/auth/AuthApi";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {STATUS, Status} from "../../constants/statusConstants"
import useToast from "../../hooks/useToast";

const EmailVerification: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {ToastMessage,handleOpenToast} = useToast()
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const email = query.get('email');
  const userId = query.get('userId');
  const isEmailVerified = location?.state?.isEmailVerified ;
  const [onboardBtnState, setOnboardBtnState] = useState<Status>(STATUS.IDLE);

  const handleEmailVerification = async () =>{
    if(!token) return ;
    try {
      await AuthApi.verifyemail(token);
      setOnboardBtnState(STATUS.SUCCESS)
    } catch (error) {
      setOnboardBtnState(STATUS.ERROR)
      console.error('Error verifying email:', error);
    }
  }
  const handleOnboardYourself = () =>{
    navigate('/user/onboarding', {
      replace: true,
      state: { 
        email,
        userId
      }
    });
  }
  const handleResendVerificationEmail = async() =>{
    const {id} = location.state;
    try{
      await AuthApi.resendVerificationMail(id);
      handleOpenToast('Verification mail has been resent successfully','success');
    }
    catch(err){
      handleOpenToast('Error sending verification mail. Please try again.','error')
    }

  }
// simulating verification process
  useEffect(()=>{
    if(token){
      setOnboardBtnState(STATUS.LOADING)
      handleEmailVerification()
    }
  },[])

  useEffect(()=>{
    if(!isEmailVerified && !token){
      handleOpenToast('Resend to verify your mail','info')
    }
  },[])

    return (
    <Box display="flex" alignItems="center" justifyContent="center" mx="16px">
      <ToastMessage/>
      <Box
        marginTop="140px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mx="20px"
      >
        <Box>
          <SuccessLogo width="195px" height="150px" />
        </Box>
        <Typography
          fontSize={typography.font_size["base"]}
          fontWeight={typography.font_weight["bold"]}
          paddingY="16px"
          textAlign="center"
        >
          {
            onboardBtnState===STATUS.SUCCESS? 
            "Successfully Verified" 
              : 
            "Verification link sent to you email ID"   
            }
        </Typography>

        <Typography
          fontSize={typography.font_size["sm"]}
          textAlign="center"
          color={colors.gray["80"]}
          marginBottom="10px"
        >
          {onboardBtnState === STATUS.SUCCESS ? "Welcome to Yaara":'Please go to your registered mail ID and verify'}
        </Typography>

        <Button variant={onboardBtnState===STATUS.SUCCESS?'contained':'disabled'} 
          sx={{ height: "32px" }}
          onClick={handleOnboardYourself}
          >
          Onboard Yourself
        </Button>
        {!token && <>
        <Divider
          style={{
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          OR
        </Divider>

        <Box
          display="flex"
          flexDirection="row"
          fontSize={typography.font_size["sm"]}
        >
          <Typography color={colors.gray["75"]}>
            Did not receive email?{" "}
          </Typography>{" "}
          <Typography component='span' onClick={handleResendVerificationEmail} >Resend</Typography>
        </Box>
        </>}
      </Box>
    </Box>
  );

};
export default EmailVerification;
