import React from "react";
import { Profile } from "../pages/Profile";
import { Gallery } from "../pages/Gallery/indexs";
import { Subscription } from "../pages/Subscription";
import { Leaderboard } from "../pages/Leaderboard";
import { Wallet } from "../pages/Wallet";
import { ReferAndEarn } from "../pages/ReferAndEarn";
import { Bookings } from "../pages/Bookings";


interface SidebarRoute {
    displayText: string;
    displayIcon: string;
    path: string;
    page:React.ComponentType<any>,
    state:string
}

const SidebarRoutes: SidebarRoute[] = [
    {
        displayText: 'Profile',
        displayIcon: '/Icons/sidebarIcons/profile.svg',
        path: 'profile',
        page:Profile ,
        state:'profile'
    },
    {
        displayText: 'Subscription',
        displayIcon: '/Icons/sidebarIcons/subscription.svg',
        path: 'subscription',
        page:Subscription,
        state:'subscription'
    },
    {
        displayText: 'Bookings',
        displayIcon: '/Icons/sidebarIcons/calendarIcon.svg',
        path: 'bookings',
        page: Bookings,
        state:'bookings'
    },
    {
        displayText: 'LeaderBoard',
        displayIcon: '/Icons/sidebarIcons/leaderboard.svg',
        path: 'leaderboard',
        page:Leaderboard,
        state:'leaderboard' 
    },
    {
        displayText: 'Gallery',
        displayIcon: '/Icons/sidebarIcons/gallery.svg',
        path: 'gallery',
        page:Gallery,
        state:'gallery'
    },
    {
        displayText: 'Wallet',
        displayIcon: '/Icons/sidebarIcons/wallet.svg',
        path: 'wallet',
        page:Wallet,
        state:'wallet' 
    },
    {
        displayText: 'Refer & Earn',
        displayIcon: '/Icons/sidebarIcons/refer_and_earn.svg',
        path: 'refer&earn',
        page:ReferAndEarn,
        state:'refer & earn'
    }
];

export { SidebarRoutes };
