import { Box, Button, styled, Typography } from '@mui/material'
import React from 'react'
import { typography, colors } from '../../utils/styles';
import { RequestCloseModal, RequestOpenModal } from '../../types';

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContentContainer = styled(Box)`
  display: flex;
  width: 280px;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: ${colors.white};
`;
const Content = styled(Box)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
const Title = styled(Typography)`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.lg}px;
  font-style: normal;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.regular};
`;
const TitleContainer = styled(Typography)``;
const NoteContainer = styled(Box)``;
const Buttons = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const BottomButtonsContainer = styled(Box)`
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
const HorizontalDivider = styled(Box)`
  display: flex;
  height: 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 0.8px solid rgba(33, 33, 33, 0.05);
`;
const VerticalDivider = styled(Box)`
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 0.5px solid rgba(33, 33, 33, 0.1);
`;
const NoteText = styled(Typography)`
  color: ${colors.gray[80]};
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const NoteListItems = styled("ul")``;
const NoteListItem = styled("li")`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
  margin-left: 1.5rem;
`;
interface DeleteCreatorOnDemandRateProps{
    requestCloseModal: RequestCloseModal;
    requestOpenModal: RequestOpenModal;
    handleOnDelete: () => void;
}
const DeleteCreatorOnDemandRate:React.FC<DeleteCreatorOnDemandRateProps>= ({
    requestCloseModal,
    requestOpenModal,
    handleOnDelete
}) => {
    const handleCancel = () => {
        requestCloseModal();
    }
    const handleConfirmedforDelete = () => {
        requestCloseModal();
        handleOnDelete();
    }
  return (
        <Container>
            <ContentContainer>
            <Content>
            <TitleContainer>
                <Title>
                    Do you want to delete your subscription plan ?
                </Title>
            </TitleContainer>
            <NoteContainer>
                <NoteText>
                    Note the following:
                </NoteText>
                <NoteListItems>
                    <NoteListItem>
                        You must still continue providing services to your current subscribers till their valid period.
                    </NoteListItem>
                    <NoteListItem>
                        After removal no new subscribers would be able to avail your subscription plan.
                    </NoteListItem>
                    <NoteListItem>
                        After removal you may create new subscription.
                    </NoteListItem>
                </NoteListItems>
            </NoteContainer>
            </Content>
            <HorizontalDivider/>
            <BottomButtonsContainer>
                    <Buttons sx={{color: "#616161"}}
                    onClick={handleCancel}
                    >
                        Cancel
                    </Buttons>
                        <VerticalDivider/>
                    <Buttons sx={{color: "#B71C1C"}}
                        onClick={handleConfirmedforDelete}
                    >
                        Delete
                    </Buttons>
            </BottomButtonsContainer>
            </ContentContainer>
        </Container>
  )
}

export default DeleteCreatorOnDemandRate