import { Box, Button, Typography, styled } from "@mui/material";
import React from "react";
import { RequestCloseModal, RequestOpenModal } from "../../types";
import { typography, colors } from "../../utils/styles";

const UnfollowContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const Content = styled(Box)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
`;
const BottomButtonsContainer = styled(Box)`
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
const Title = styled(Typography)`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.lg}px;
  font-weight: ${typography.font_weight.semiBold};
  line-height: 125%;
`;
const Buttons = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  color: ${colors.gray[80]};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const HorizontalDivider = styled(Box)`
  display: flex;
  height: 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 0.8px solid rgba(33, 33, 33, 0.05);
`;
const VerticalDivider = styled(Box)`
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 0.5px solid rgba(33, 33, 33, 0.1);
`;
const UnfollowContent = styled(Box)`
  width: 280px;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: ${colors.white};
  box-shadow: 0px 24px 80px 0px rgba(0, 0, 0, 0.3);
`;
const SubtitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;
const SubtitlSubTextContainer = styled(Box)``;
const SubtitlSubTextNote = styled(Typography)`
  color: ${colors.gray[80]};
  font-size: ${typography.font_size.sm}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const OtherDetailsIems = styled("ul")`
  list-style-type: disc;
  padding-left: 28px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const OtherDetailItem = styled("li")``;
const OtherDetailItemText = styled(Typography)`
  color: ${colors.gray[80]};
  font-size: ${typography.font_size.sm}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
interface EditAvailabilityInfoProps {
  requestOpenModal: RequestOpenModal;
  requestCloseModal: RequestCloseModal;
  handleOnConfirm: () => void;
}
const EditAvailabilityInfo: React.FC<EditAvailabilityInfoProps> = ({
  requestOpenModal,
  requestCloseModal,
  handleOnConfirm,
}) => {
  const handleCancel = () => {
    requestCloseModal();
  };
  const handleConfirmDiscard = () => {
    requestCloseModal();
    handleOnConfirm();
  };

  return (
    <UnfollowContainer>
      <UnfollowContent>
        <Content>
          <Title>Do you want to edit your current availability ?</Title>
          <SubtitleContainer>
            <SubtitlSubTextContainer>
              <SubtitlSubTextNote>Note the following:</SubtitlSubTextNote>

              <OtherDetailsIems>
                <OtherDetailItem>
                  <OtherDetailItemText>
                    Your current week availability will be same
                  </OtherDetailItemText>
                </OtherDetailItem>
                <OtherDetailItem>
                  <OtherDetailItemText>
                    If you will change the time slot user will be able to do
                    bookings on new timing from next week.
                  </OtherDetailItemText>
                </OtherDetailItem>
              </OtherDetailsIems>
            </SubtitlSubTextContainer>
          </SubtitleContainer>
        </Content>
        <HorizontalDivider />
        <BottomButtonsContainer>
          <Buttons sx={{ color: "#616161" }} onClick={handleCancel}>
            Cancel
          </Buttons>
          <VerticalDivider />
          <Buttons sx={{ color: "#7262C2" }} onClick={handleConfirmDiscard}>
            Yes
          </Buttons>
        </BottomButtonsContainer>
      </UnfollowContent>
    </UnfollowContainer>
  );
};

export default EditAvailabilityInfo;
