import React, { useEffect } from "react";
import CreateSubscriptionHeader from "../CreateSubscriptionHeader";
import { useModal } from "../../../../context/ModalContext";
import DiscardSubscriptionModal from "../../../../modals/DiscardSubscriptionModal/DiscardSubscriptionModal";
import { Box, Button, Divider, styled, Typography } from "@mui/material";
import { colors, typography } from "../../../../utils/styles";
import {
  CancelRedIcon,
  ProcessingIcon,
  TickMarkIcon,
} from "../../../../assets/SvgIcons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import creatorProfileServiceApi from "../../../../api/creator/creatorProfileServiceApi";
import { setSubscriptions } from "../../../../redux/creatorSubscriptionSlice";

const Container = styled(Box)``;

const ContentContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding-bottom: 92px;
`;

const SubscriptionCardContainer = styled(Box)`
  gap: 5px;
  display: flex;
  flex-direction: column;
`;

const SubscriptionOfferingsTitle = styled(Typography)`
  color: ${colors.black[200]};
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const SubscriptionOfferingsContainer = styled(Box)`
  padding: 16px;
  border-radius: 16px;
  border: 1px solid ${colors.violet[100]};
  background: ${colors.white};
`;

const SubscriptionOfferingsContent = styled(Box)`
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 16px 0;
  border-bottom: 1px solid ${colors.violet[100]};
`;

const SubscriptionOfferingPricingText = styled(Typography)`
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;

const SubscriptionOfferingsText = styled(Typography)`
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;

const SubscriptionPlanOfferingsContent = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const PlanText = styled(Typography)`
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;

const SubscriptionOtherDetailsConatainer = styled(Box)`
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const OthetDeatilsTitle = styled(Typography)`
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;

const OtherDetailsIems = styled("ul")`
  list-style-type: disc;
  padding-left: 28px;
  margin: 0;
`;

const OtherDetailItem = styled("li")``;

const OtherDetailItemText = styled(Typography)`
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;

const CommitmentDivider = styled(Box)``;

const CommitmentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CommitmentTitle = styled(Typography)`
  color: ${colors.black[200]};
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const CommitmentInfoContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const CommitmentText = styled(Typography)`
  color: ${colors.gray[80]};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
  width: 58%;
`;

const CommitmentTime = styled(Typography)`
  color: ${colors.violet[100]};
  text-align: right;
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
`;

const AvailabilityContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const AvailabilityTitle = styled(Typography)`
  color: ${colors.black[200]};
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const AvailabilityTextContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const AvailabilityText = styled(Typography)`
  color: ${colors.gray[80]};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;

const Availability = styled(Typography)`
  color: ${colors.violet[100]};
  text-align: right;
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
`;

const ServiceFeasibilityContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const ServiceFeasibilityTitle = styled(Typography)`
  color: ${colors.black[200]};
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const ServiceFeasibilityTextContainer = styled(Box)`
  color: ${colors.gray[80]};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
  display: flex;
  justify-content: space-between;
`;

const ServiceFeasibilityText = styled(Typography)`
  color: ${colors.gray[80]};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
  width: 58%;
`;

const ServiceFeasibilityNumber = styled(Typography)`
  color: ${colors.violet[100]};
  text-align: right;
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
`;

const NoteContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const NoteText = styled(Typography)`
  color: ${colors.red[200]};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
`;

const NoteListItems = styled("ul")`
  list-style-type: disc;
  padding-left: 28px;
  margin: 0;
`;

const NoteListItem = styled("li")`
  color: ${colors.gray[80]};
  font-family: Poppins;
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;

const ProcessingContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  background: ${colors.orange[50]};
`;

const ProcessingIconContainer = styled(Box)``;

const ProcessingTextContainer = styled(Typography)``;

const ProcessingText = styled(Typography)`
  color: ${colors.black[200]};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;

const BottomButtonsContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 72px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  justify-content: space-around;
`;

const BackToEditButton = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 18px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  overflow: hidden;
  color: ${colors.violet[100]};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  max-width: 168px;
`;

const ConfirmButton = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 18px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  overflow: hidden;
  color: ${colors.white};
  text-align: center;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  border-radius: 8px;
  background: ${colors.violet[100]};
  max-width: 168px;
`;

const PlanDetails = () => {
  // Access modal functions for opening and closing modals
  const { requestOpenModal, requestCloseModal } = useModal();
  const {id}=useSelector((state: RootState)=> state.creatorProfile.profile);
  const dispatch=useDispatch<AppDispatch>()
  // Access the current subscription state from the Redux store
  const { subscriptions } = useSelector(
    (state: RootState) => state.creatorSubscription
  );
  // Use React Router's navigate function to programmatically navigate between routes
  const navigate = useNavigate();
  // Function to discard the subscription and navigate to the profile page
  const discardSubscription = () => {
    navigate("/creator/create-subscription-plan");
  };
  // Handle the click event for the "Go Back" button, opening a modal to confirm discarding the subscription
  const handleOnClickGoBackButton = () => {
    requestOpenModal({
      name: "Discard Subscription",
      component: (
        <DiscardSubscriptionModal
          requestOpenModal={requestOpenModal}
          requestCloseModal={requestCloseModal}
          onDiscardPlan={discardSubscription}
        />
      ),
    });
  };
  // Handle the click event for the "Back to Edit" button, preventing the default form submission and navigating to the plan creation page
  const handleOnClickBackToEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate("/creator/create-subscription-plan");
  };
  // Handle the click event for the "Confirm" button, preventing the default form submission and navigating to the profile page
  const handleOnClickConfirmButton = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    navigate("/creator/profile");
  };
  // Define the subscription offerings with their respective properties, including type, status, minutes, and icon
  const subscriptionOfferings = [
    {
      type: "Direct Message",
      enabled: subscriptions.directMessage,
      minutes: subscriptions.directMessageMinutes,
      icon: subscriptions.directMessage ? <TickMarkIcon /> : <CancelRedIcon />,
      label: subscriptions?.directMessageMinutes
        ? `${subscriptions.directMessageMinutes} min Direct message`
        : "Direct message",
    },
    {
      type: "Video Call",
      enabled: subscriptions.videoCall,
      minutes: subscriptions.videoCallMinutes,
      icon: subscriptions.videoCall ? <TickMarkIcon /> : <CancelRedIcon />,
      label: subscriptions?.videoCallMinutes
        ? `${subscriptions.videoCallMinutes} min Video call`
        : "Video call",
    },
    {
      type: "Audio Call",
      enabled: subscriptions.audioCall,
      minutes: subscriptions.audioCallMinutes,
      icon: subscriptions.audioCall ? <TickMarkIcon /> : <CancelRedIcon />,
      label: subscriptions?.audioCallMinutes
        ? `${subscriptions.audioCallMinutes} min Audio call`
        : "Audio call",
    },
  ];
  // Sort the subscription offerings based on whether the minutes are greater than 0
  // Offerings with more than 0 minutes are placed before those with 0 minutes
  const sortedOfferings = subscriptionOfferings.sort((a, b) =>
    b.minutes > 0 ? 1 : -1
  );
  const fetchSubscriptions = async (creatorId: string) => {
    try {
      const response = await creatorProfileServiceApi.getCreatorSubscriptions(creatorId);
      dispatch(setSubscriptions(response.data.data.subscription));
    } catch (error) {
      console.error("Failed to get subscriptions", error);
    } 
  };
  // Return the JSX for the PlanDetails component (this part would be below, omitted here)
  useEffect(() => {
    if (id && !subscriptions?.id) {
      fetchSubscriptions(id)
    }
  }, [dispatch, id]);

  return (
    <Container>
      <CreateSubscriptionHeader
        title="Subscription Plan Details"
        cancelTextTitle="cancel"
        cancelTextSubtitle="cancel"
        handleOnClickGoBackButton={handleOnClickGoBackButton}
      />

      <ContentContainer>
        <SubscriptionCardContainer>
          <SubscriptionOfferingsTitle>
            Your Subscription Plan
          </SubscriptionOfferingsTitle>
          <SubscriptionOfferingsContainer>
            <SubscriptionOfferingsContent>
              <SubscriptionOfferingPricingText>
                CC {subscriptions?.amountInYC} / Month
              </SubscriptionOfferingPricingText>
              <SubscriptionOfferingsText>
                Subscription Offerings -
              </SubscriptionOfferingsText>
              {sortedOfferings.map((offering, index) => (
                <SubscriptionPlanOfferingsContent key={index}>
                  {offering.icon}
                  <PlanText
                    sx={{
                      textDecoration: offering.enabled
                        ? "none"
                        : "line-through",
                    }}
                  >
                    {offering.label}
                  </PlanText>
                </SubscriptionPlanOfferingsContent>
              ))}
            </SubscriptionOfferingsContent>

            <SubscriptionOtherDetailsConatainer>
              <OthetDeatilsTitle>Others Details:</OthetDeatilsTitle>

              <OtherDetailsIems>
                <OtherDetailItem>
                  <OtherDetailItemText>
                    10% default discount for subscribed users who want to buy
                    the post.
                  </OtherDetailItemText>
                </OtherDetailItem>

                <OtherDetailItem>
                  <OtherDetailItemText>
                    Free post will be provided to whoever buys this monthly
                    subscription.
                  </OtherDetailItemText>
                </OtherDetailItem>

                <OtherDetailItem>
                  <OtherDetailItemText>
                    1 month will start from the day user has bought this plan.
                  </OtherDetailItemText>
                </OtherDetailItem>
              </OtherDetailsIems>
            </SubscriptionOtherDetailsConatainer>
          </SubscriptionOfferingsContainer>
        </SubscriptionCardContainer>

        <CommitmentContainer>
          <CommitmentTitle>Your Time Commitment</CommitmentTitle>
          <CommitmentInfoContainer>
            <CommitmentText>
              Your Combined Subscription Time offered per Subscriber
            </CommitmentText>

            <CommitmentTime>
              {subscriptions.audioCallMinutes +
                subscriptions.directMessageMinutes +
                subscriptions.videoCallMinutes}{" "}
              Minutes
            </CommitmentTime>
          </CommitmentInfoContainer>

          <CommitmentDivider>
            <Divider sx={{ my: "2" }} />
          </CommitmentDivider>
        </CommitmentContainer>

        <AvailabilityContainer>
          <AvailabilityTitle>Your Monthly Availability</AvailabilityTitle>
          <AvailabilityTextContainer>
            <AvailabilityText>Weekly availability</AvailabilityText>

            <Availability>Wed.Sat</Availability>
          </AvailabilityTextContainer>

          <AvailabilityTextContainer>
            <AvailabilityText>Daily availability</AvailabilityText>

            <Availability>8 hour</Availability>
          </AvailabilityTextContainer>

          <AvailabilityTextContainer>
            <AvailabilityText>Monthly availability</AvailabilityText>

            <Availability>64 hours</Availability>
          </AvailabilityTextContainer>

          <CommitmentDivider>
            <Divider sx={{ my: "2" }} />
          </CommitmentDivider>
        </AvailabilityContainer>

        <ServiceFeasibilityContainer>
          <ServiceFeasibilityTitle>
            Your service feasibility
          </ServiceFeasibilityTitle>
          <ServiceFeasibilityTextContainer>
            <ServiceFeasibilityText>
              You would be able to Serve a maximum of
            </ServiceFeasibilityText>

            <ServiceFeasibilityNumber>27 Subscribers</ServiceFeasibilityNumber>
          </ServiceFeasibilityTextContainer>

          <CommitmentDivider>
            <Divider sx={{ my: "2" }} />
          </CommitmentDivider>
        </ServiceFeasibilityContainer>

        <NoteContainer>
          <NoteText>Note:</NoteText>

          <NoteListItems>
            <NoteListItem>
              This is considering that all your available slots are booked.
            </NoteListItem>
            <NoteListItem>
              If you are also doing on demand 1-1 Interactions, then number of
              people you would be able to offer the subscription service would
              go down further.
            </NoteListItem>
          </NoteListItems>
        </NoteContainer>
      </ContentContainer>

      <ProcessingContainer>
        <ProcessingIconContainer>
          <ProcessingIcon />
        </ProcessingIconContainer>

        <ProcessingTextContainer>
          <ProcessingText>
            If you wish to modify your availability, you may do that later from
            your profile.
          </ProcessingText>

          <ProcessingText>
            If you wish to modify your subscription plan, you can go back and
            edit.
          </ProcessingText>
        </ProcessingTextContainer>
      </ProcessingContainer>

      <BottomButtonsContainer>
        <BackToEditButton onClick={handleOnClickBackToEdit}>
          Back to edit
        </BackToEditButton>

        <ConfirmButton onClick={handleOnClickConfirmButton}>
          Confirm
        </ConfirmButton>
      </BottomButtonsContainer>
    </Container>
  );
};

export default PlanDetails;
