import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { VideoCarousel } from "./VideoCarousel";
import { ImageCarousel } from "./ImageCarousel";
import { PHOTO_STEPS, VIDEO_STEPS } from "../../../utils/creatorContentTypes";

const FileUpload = (props: any) => {
  const mode = useSelector((state: any) => state.postContent.mode);
  const currentStep = useSelector(
    (state: any) => state.postContent.currentContentStep
  );

  return (
    <Box>
      {mode === "video" && currentStep === VIDEO_STEPS.VIDEO_UPLOADING && (
        <VideoCarousel />
      )}

      {currentStep === PHOTO_STEPS.PHOTO_UPLOADING && <ImageCarousel />}
    </Box>
  );
};

export { FileUpload };
