import {userAxiosInstance} from "../axiosInstance";

userAxiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("creatorAccessToken") || localStorage.getItem("userAccessToken");
  if (token) {
    let parsedToken = JSON.parse(token);
    config.headers['Authorization'] = `Bearer ${parsedToken}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
