import { Box, Button, CircularProgress, InputLabel, Stack, Typography, } from "@mui/material";
import { useEffect, useState } from "react";
import { colors, typography } from "../../utils/styles";
import { InputFieldWithTextCount } from "../../components/Common/CharacterCountInput";
import { GenderSelectRadioInput } from "../../components/Common/GenderSelectRadioInput";
import { TextInput } from "../../components/Common/TextInput";
import { OptionalPhoneNumberInput } from "../../components/Common/OptionalPhoneNumberInput";
import { ChooseContent } from "../../components/Common/ChooseContent";
import { CoverPicture } from "../../components/Common/CoverPicture";
import { AboutMe } from "../../components/Common/AboutMe";
import { useLocation, useNavigate } from "react-router-dom";
import userApi from "../../api/user/userApi";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/appUserSlice";
import { STATUS, Status } from "../../constants/statusConstants";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import useHandleNameAvailability from "../../hooks/useHandleNameAvailability";
import useToast from "../../hooks/useToast";

const options = ['Educational', 'Fashion', 'Fitness', 'Wellness', 'Food', 'Cooking', 'Gaming', 'Beauty', 'Travel', 'Parenting', 'Lifestyle', 'Health', 'Technology', 'Gadget', 'DIY/Craft', 'Pet', 'Environment', 'Sustainability'];
const PageTag = styled("div")`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  background: ${colors.purple[50]};
`
const InputLabelComponent = (props:any) => {
  return <InputLabel sx={{ color: colors.black[200], fontSize: typography.font_size.sm, mb: '8px' }}>
    {props.children}
    {props.isMandatory && (
        <span style={{ color: "red", marginLeft: "4px" }}>*</span>)}
    </InputLabel>
}

type EditProfileState = {
  description: string,
  displayName: string,
  handleName: string,
  gender: string,
  phoneNumber?: string,
  preferredContentCategory: string[],
  profilePhotoUrl?: string,
  coverPhotoUrl?: string
}

const EditUserProfile = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ToastMessage, handleOpenToast } = useToast();
  const userInfo = location.state?.userInfo;
  const { isAvailable, handleNameChange } = useHandleNameAvailability();
  const { displayName, gender, phoneNumber, preferredContentCategory, email, handleName, coverPhotoUrl, profilePhotoUrl } = userInfo;
  const initialState = {
    description: userInfo?.description ?? "",
    displayName,
    handleName,
    gender,
    preferredContentCategory,
    phoneNumber: phoneNumber ?? "",
    coverPhotoUrl,
    profilePhotoUrl,
  }

  const [userProfileDetails, setUserProfileDetails] = useState<EditProfileState>(initialState);
  const [isProfileUpdated, setIsProfileUpdated] = useState<Status>(STATUS.IDLE);
  const [imageLoadingStatus, setImageLoadingStatus] = useState(false);

  const handleInputChange = (event: any) => {
    const { name, value } = event?.target;
    if (name === "handleName") {
      handleNameChange(value);
    };
    setUserProfileDetails((prev) => ({ ...prev, [name]: value }))
  }

  const isSaveBtnActive = !!userProfileDetails.description &&
    !!userProfileDetails.displayName &&
    !!(userProfileDetails.preferredContentCategory.length >= 3) &&
    !!(!userProfileDetails.phoneNumber || userProfileDetails.phoneNumber.length === 10);

  const handleSaveProfileDetails = async () => {
    setIsProfileUpdated(STATUS.LOADING);
    try {
      const response = await userApi.updateUserProfile(userProfileDetails);
      dispatch(setUser(response.data.user))
      setIsProfileUpdated(STATUS.SUCCESS)
      navigate(-1)
    } catch (error: any) {
      setIsProfileUpdated(STATUS.ERROR)
      if (error?.response) {
        // replace api error
        handleOpenToast(error.response?.data?.errors?.[0].message ?? "Something went wrong.",'error')
      }
      else handleOpenToast('Something went wrong.','error')
    }
  }
  const updateCoverPicture = (uri: string) => {
    setUserProfileDetails(prev => ({ ...prev, 'coverPhotoUrl': uri }))
  }
  const updateProfilePicture = (uri: string) => {
    setUserProfileDetails(prev => ({ ...prev, 'profilePhotoUrl': uri }))
  }

  return <Box sx={{ position: 'relative' }}>
    <ToastMessage />
    <PageTag>
      <Typography
        fontSize={typography.font_size.base}
        fontWeight={typography.font_weight.bold}
        color={colors.black[200]}
      >
        Edit Profile
      </Typography>
      <CloseIcon sx={{ position: "absolute", right: 10 }} onClick={() => navigate(-1)} />
    </PageTag>
    <CoverPicture
      pictureUrl={userProfileDetails.coverPhotoUrl}
      updateCoverPicture={updateCoverPicture} 
      setImageLoadingStatus={setImageLoadingStatus}
      />
    <AboutMe
      profilePic={userProfileDetails.profilePhotoUrl}
      displayName={displayName}
      handleName={handleName}
      updateProfilePicture={updateProfilePicture} 
      setImageLoadingStatus={setImageLoadingStatus}
      />
    <Stack direction='column' gap='32px' px="16px" pb={6}>
      <Box>
        <InputLabelComponent isMandatory={true}>Description</InputLabelComponent>
        <InputFieldWithTextCount
          name="description"
          placeholder="Write Description"
          characterLimit={200}
          minHeight={72}
          value={userProfileDetails.description}
          onChange={handleInputChange}
        />
      </Box>
      <Box>
        <InputLabelComponent isMandatory={true}>Enter Display Name</InputLabelComponent>
        <InputFieldWithTextCount
          name="displayName"
          placeholder="Enter display name"
          characterLimit={25}
          minHeight={48}
          maxHeight={48}
          value={userProfileDetails.displayName}
          onChange={handleInputChange}
        />
      </Box>
      <Box>
        <InputLabelComponent isMandatory={true}>How do you identify yourself?</InputLabelComponent>
        <GenderSelectRadioInput
          value={userProfileDetails.gender}
          onChange={handleInputChange}
        />
      </Box>
      <Box>
        <InputLabelComponent isMandatory={true}>Handle Name</InputLabelComponent>
        <TextInput
          name="handleName"
          placeholder="Enter your handle name"
          value={userProfileDetails?.handleName}
          onChange={handleInputChange}
          isAvailable={isAvailable}
          onKeyDown={(e: any) => {
            if (e.key === " ") {
              e.preventDefault()
            }
          }}
          helperText={userProfileDetails.handleName ? isAvailable ? "Available" : "Handle name unavailable" : ''}
        />
      </Box>
      <Box>
        <InputLabelComponent>Email</InputLabelComponent>
        <TextInput
          name="email"
          placeholder="Enter your mail"
          value={email}
          disabled
          onChange={() => { }}
        />
      </Box>
      <Box>
        <InputLabelComponent>
          Phone Number (optional)
        </InputLabelComponent>
        <OptionalPhoneNumberInput
          name="phoneNumber"
          onChange={handleInputChange}
          value={userProfileDetails.phoneNumber} />
      </Box>
      <Box>
        <InputLabelComponent isMandatory={true}>
          Interested content areas:
        </InputLabelComponent>
        <ChooseContent
          name="preferredContentCategory"
          options={options}
          selectAll={true}
          setUserDetails={setUserProfileDetails}
          initialValues={preferredContentCategory}
        />

      </Box>
    </Stack>
    {isProfileUpdated !== STATUS.LOADING ? <Button fullWidth
      variant={(isSaveBtnActive && !imageLoadingStatus) ? 'primary' : 'disabled'}
      sx={{ borderRadius: 0, position: 'sticky', bottom: 0 }}
      onClick={handleSaveProfileDetails}
    >
      Save
    </Button> : <Button fullWidth sx={{ borderRadius: 0, position: 'sticky', bottom: 0 }} variant="primary" startIcon={<CircularProgress size={26} sx={{ color: '#FFF' }} />} />}
  </Box>
}
export { EditUserProfile }