import { createSlice } from '@reduxjs/toolkit';
import { OndemandRateType } from '../types';


interface CreatorOnDemandRateState {
  onDemandRate: OndemandRateType;
  loading: boolean;
  error: any;
}

const initialOnDemandState: CreatorOnDemandRateState = {
    onDemandRate: {
    id: '',
    creatorId: '',
    audioCallYc: 0,
    videoCallYc: 0,
    directMessageYc: 0,
    createdAt: '',
    updatedAt: '',
      },
      loading: false,
      error: null,
};

const creatorOnDemandRateSlice = createSlice({
  name: 'creatorOnDemandRate',
  initialState: initialOnDemandState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setOnDemandRate(state, action) {
      if(action.payload.id){
        state.onDemandRate = action.payload;
      }
    },
    removeOnDemandRate(state) {
      state.onDemandRate = initialOnDemandState.onDemandRate;
    },
    setAudioCallRate(state, action) {
      if (state.onDemandRate) {
        state.onDemandRate.audioCallYc = action.payload;
      }
    },
    setVideoCallRate(state, action) {
      if (state.onDemandRate) {
        state.onDemandRate.videoCallYc = action.payload;
      }
    },
    setDirectMessageRate(state, action) {
      if (state.onDemandRate) {
        state.onDemandRate.directMessageYc = action.payload;
      }
    },
    resetAudioCallRate(state) {
      if (state.onDemandRate) {
        state.onDemandRate.audioCallYc = initialOnDemandState.onDemandRate.audioCallYc;
      }
    },
    resetVideoCallRate(state) {
      if (state.onDemandRate) {
        state.onDemandRate.videoCallYc = initialOnDemandState.onDemandRate.videoCallYc;
      }
    },
    resetDirectMessageRate(state) {
      if (state.onDemandRate) {
        state.onDemandRate.directMessageYc = initialOnDemandState.onDemandRate.directMessageYc;
      }
    },
  },
});




export const {
  setLoading,
  setError,
  setOnDemandRate,
  removeOnDemandRate,
  setAudioCallRate,
  setVideoCallRate,
  setDirectMessageRate,
  resetAudioCallRate,
  resetVideoCallRate,
  resetDirectMessageRate,
} = creatorOnDemandRateSlice.actions;

export const creatorOnDemandRateReducer= creatorOnDemandRateSlice.reducer;
