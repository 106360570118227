import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Stack, Tabs, Typography } from '@mui/material';
import { useState } from 'react';

const TransactionStatusTabs = ({value,handleChange}:{value:string,handleChange:any}) => {
 
    return <Box sx={{ width: '100%' }}>
            <Stack width="100%" direction='row' justifyContent='center'>
                <TabList
                    sx={{
                        width: "100%",
                        px:'1rem'
                    }}
                    onChange={handleChange}>
                    <Tab sx={{
                        width: "50%",
                        margin: 0, 
                        padding: '0 16px',
                    }} label="History" value="1" />
                    <Tab sx={{
                        width: "50%",
                        margin: 0, 
                        padding: '0 16px',
                    }}  label="Pending" value="2" />
                </TabList>
            </Stack>
    </Box>
}
export default TransactionStatusTabs