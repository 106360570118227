import { Box } from "@mui/material";
import React from "react";
import { colors, typography } from "../../../utils/styles";

interface cardProps {
  name?: string;
  onClick?: () => void;
  isSelected?: boolean;
}
const Card: React.FC<cardProps> = ({ name, isSelected, onClick }) => {
  const borderColor = isSelected ? colors.green["100"] : colors.gray["110"];
  const backgroundColor = isSelected ? colors.green["60"] : colors.white;

  return (
    <Box
      width="90px"
      border={`1px solid ${borderColor}`}
      fontSize={typography.font_size["xxs"]}
      textAlign="center"
      p="8px"
      borderRadius="8px"
      sx={{ backgroundColor }}
      onClick={onClick}
    >
      {name}
    </Box>
  );
};

export { Card };
