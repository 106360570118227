import React, { useEffect, useState, useCallback } from "react";
import CreatorFollowing from "../Following/Following";
import followingsApi from "../../../api/creator/socialConnectionsApi";
import { useParams } from "react-router-dom";

interface FollowerType {
  id: number;
  img: string;
  name: string;
  handle: string;
  type: string;
}

interface CreatorFollowingProps {
  isOwn: boolean;
}

const CreatorsFollowers: React.FC<CreatorFollowingProps> = ({ isOwn }) => {
  const [followerData, setFollowerData] = useState<FollowerType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { id } = useParams();

  // Function to fetch followers
  const fetchOwnFollowers = useCallback(() => {
    if (loading || !hasMore) return;

    setLoading(true);

    followingsApi
      .getOwnFollowers(page)
      .then(({ data }) => {
        const filteredData = data.data.map((item: any) => ({
          id: item.user.id,
          img: item.user.profilePhotoUrl,
          name: item.user.displayName,
          handle: item.user.handleName,
          type: item.user.creator ? "creator" : "user",
        }));

        if (filteredData.length === 0) {
          setHasMore(false);
          return;
        } else {
          setFollowerData((prevData) => {
            const newData = [...prevData, ...filteredData];
            setHasMore(data.pagination.totalItems > newData.length);
            return newData;
          });
          setPage((prevPage) => prevPage + 1);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setHasMore(false);
      });
  }, [page, loading, hasMore]);

  const fetchFollowers = useCallback(() => {
    if (loading || !hasMore) return;

    setLoading(true);

    followingsApi
      .getCreatorFollowers(id!, page)
      .then(({ data }) => {
        const filteredData = data.data.map((item: any) => ({
          id: item.user.creator?.id ?? item.user.id,
          img: item.user.profilePhotoUrl,
          name: item.user.displayName,
          handle: item.user.handleName,
          type: item.user.creator ? "creator" : "user",
        }));

        console.log("Data", data);
        console.log("Filtered Data", filteredData);

        if (filteredData.length === 0) {
          setHasMore(false);
          return;
        } else {
          setFollowerData((prevData) => {
            const newData = [...prevData, ...filteredData];
            setHasMore(data.pagination.totalItems > newData.length);
            return newData;
          });
          setPage((prevPage) => prevPage + 1);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setHasMore(false);
      });
  }, [page, loading, hasMore]);

  useEffect(() => {
    isOwn ? fetchOwnFollowers() : fetchFollowers();
  }, []);

  return (
    <CreatorFollowing
      text="Followers"
      data={followerData}
      fetchMoredata={isOwn ? fetchOwnFollowers : fetchFollowers}
      hasMore={hasMore}
      loading={loading}
    />
  );
};

export default CreatorsFollowers;
