import { Box, TextField } from "@mui/material";
import { useRef } from "react";

export function OTPInput(props: any) {
  const { otp, setOtp } = props;
  const OTPInputFieldStyle = {
    fontSize: "24px",
    fontWeight: 700,
    padding: ".5rem",
    width: "44px",
    height: "50px",
    marginLeft: "15px",
  };

  const inputRefs: any = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleOTPInputChange = (index: any, event: any) => {
    const { value } = event.target;
    if (isNaN(value) || value.length > 1) {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index: any, event: any) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs[index - 1].current.focus();
    }
    if (event.key === "Enter" && !otp.some((value: any) => value === "")) {
    }
  };

  return (
    <Box display="flex" gap="10px" mt={2}>
      {otp.map((digit: any, index: any) => (
        <TextField
          autoFocus={index === 0}
          key={index}
          inputRef={inputRefs[index]}
          value={digit}
          onChange={(event) => handleOTPInputChange(index, event)}
          onKeyDown={(event) => handleKeyDown(index, event)}
          variant="outlined"
          sx={{ textAlign: "center" }}
          inputProps={{
            maxLength: 1,
            style: {
              ...OTPInputFieldStyle,
            },
          }}
        />
      ))}
    </Box>
  );
}
