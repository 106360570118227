import { Box, Button, styled, Typography } from "@mui/material";

import React from "react";
import { typography } from "../../utils/styles";
import { RequestCloseModal, RequestOpenModal } from "../../types";

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled(Box)`
  display: flex;
  width: 280px;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: var(--Background-card, #fff);
`;
const Content = styled(Box)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const Title = styled(Typography)`
  color: var(--Text-Primary, #212121);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 22.5px */
`;

const TitleContainer = styled(Typography)``;

const NoteContainer = styled(Box)``;

const Buttons = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: 0.24px;
`;

const BottomButtonsContainer = styled(Box)`
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const HorizontalDivider = styled(Box)`
  display: flex;
  height: 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 0.8px solid rgba(33, 33, 33, 0.05);
`;

const VerticalDivider = styled(Box)`
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 0.5px solid rgba(33, 33, 33, 0.1);
`;

const DeleteContent = styled(Box)`
  width: 280px;
  flex-direction: column;
  align-items: center;

  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 24px 80px 0px rgba(0, 0, 0, 0.3);
`;

const NoteText = styled(Typography)`
  color: var(--Text-Processing, #c15700);

  /* Body/L-R */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
  letter-spacing: 0.21px;
`;

const NoteListItems = styled("ul")``;

const NoteListItem = styled("li")`
  color: var(--Text-Secodary, #616161);

  /* Body/L-R */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.21px;
  margin-left: 1.5rem;
`;

interface DeleteCreatorOnDemandRateProps {
  requestCloseModal: RequestCloseModal;
  requestOpenModal: RequestOpenModal;
  handleOnDelete: () => void;
}

const DeleteCreatorOnDemandRate: React.FC<DeleteCreatorOnDemandRateProps> = ({
  requestCloseModal,
  requestOpenModal,
  handleOnDelete,
}) => {
  const handleCancel = () => {
    requestCloseModal();
  };

  const handleConfirmedforDelete = () => {
    requestCloseModal();
    handleOnDelete();
  };
  return (
    <Container>
      <ContentContainer>
        <Content>
          <TitleContainer>
            <Title>
              Completely Remove On Demand services from your offerings ?
            </Title>
          </TitleContainer>

          <NoteContainer>
            <NoteText>Note</NoteText>

            <NoteListItems>
              <NoteListItem>
                You must still continue providing services to user who already
                booked slots and paid for same rate.
              </NoteListItem>
              <NoteListItem>
                After removal no new user would be able to avail your on demand
                1-1 plan.
              </NoteListItem>
              <NoteListItem>
                After removal you may set your rates again later.
              </NoteListItem>
            </NoteListItems>
          </NoteContainer>
        </Content>

        <HorizontalDivider />
        <BottomButtonsContainer>
          <Buttons sx={{ color: "#616161" }} onClick={handleCancel}>
            Cancel
          </Buttons>
          <VerticalDivider />
          <Buttons sx={{ color: "#B71C1C" }} onClick={handleConfirmedforDelete}>
            Remove
          </Buttons>
        </BottomButtonsContainer>
      </ContentContainer>
    </Container>
  );
};

export default DeleteCreatorOnDemandRate;
