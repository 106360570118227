import React from "react";
import { PageTagComponent } from "../Common/PageTagComponent";
import { Box, styled, Typography } from "@mui/material";
import { ForwardArrowIcon } from "../../assets/SvgIcons";
import { useLocation, useNavigate } from "react-router-dom";
import { colors, typography } from "../../utils/styles";

const Container = styled(Box)`
  width: 100%;
  overflow: hidden;
  position: relative;
`;
const Content = styled(Box)``;
const Header = styled(Box)``;
const ItemContainer = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--Border-Light, rgba(33, 33, 33, 0.05));
`;

const ItemText = styled(Typography)`
  color: ${colors.black[200]}
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const IconContainer = styled(Box)``;
const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;
const Subtext = styled(Typography)`
  color: ${colors.black[200]}
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.regular};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const AccountSetting = () => {
    const navigate=useNavigate();
    const location=useLocation();
    const userRole=location.pathname.includes("user") ? "user" : " creator";
  const handleOnClickDeactivateAccount = () => {
    navigate(`/${userRole}/settings/account/deactivate`);
  };

  return (
    <Container>
      <Header>
        <PageTagComponent>Account</PageTagComponent>
      </Header>
      <Content>
        <ItemContainer onClick={handleOnClickDeactivateAccount}>
          <TextContainer>
            <ItemText>Deactivate Account</ItemText>
            <Subtext>Find out how you can deactivate your account.</Subtext>
          </TextContainer>
          <IconContainer>
            <ForwardArrowIcon />
          </IconContainer>
        </ItemContainer>
      </Content>
    </Container>
  );
};

export default AccountSetting;
