import { Box } from "@mui/material";
import { useState } from "react";
import { PageTagComponent } from "../../../components/Common/PageTagComponent";
import { TabContext, TabPanel } from "@mui/lab";
import TransactionStatusTabs from "../../../components/Wallet/Transactions/TransactionStatusTab";
import TxnHistory from "../../../components/Wallet/Transactions/History";
import PendingTxn from "../../../components/Wallet/Transactions/Pending";

const CreatorTransactionPage = ()=>{
    const [value, setValue] = useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Box>
            <PageTagComponent>Transactions</PageTagComponent>
            <TabContext value={value}>
                <TransactionStatusTabs value={value} handleChange={handleChange} />
                    <TabPanel value="1">
                        <TxnHistory userRole="creator"/>
                    </TabPanel>
                    <TabPanel value="2">
                        <PendingTxn />
                    </TabPanel>
            </TabContext>
        </Box>
    )

}
export default CreatorTransactionPage