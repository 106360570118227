import { Box, Button, InputLabel, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { colors, typography } from "../../utils/styles";
import { TextInput } from "../../components/Common/TextInput";
import { GenderSelectRadioInput } from "../../components/Common/GenderSelectRadioInput";
import { OptionalPhoneNumberInput } from "../../components/Common/OptionalPhoneNumberInput";
import { ChooseContent } from "../../components/Common/ChooseContent";
import userApi from "../../api/user/userApi";
import { UserDisplayPicture } from "../../components/UserDisplayPicture";
import useHandleNameAvailability from "../../hooks/useHandleNameAvailability";
import { setUser } from "../../redux/appUserSlice";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { STATUS } from "../../constants/statusConstants";
import { Status } from "aws-sdk/clients/directconnect";
import { useDispatch } from "react-redux";

const options = [
  "Educational",
  "Fashion",
  "Fitness",
  "Wellness",
  "Food",
  "Cooking",
  "Gaming",
  "Beauty",
  "Travel",
  "Parenting",
  "Lifestyle",
  "Health",
  "Technology",
  "Gadget",
  "DIY/Craft",
  "Pet",
  "Environment",
  "Sustainability",
];

type userOnboardingDetails = {
  coverPicture?: string;
  profilePhotoUrl?: string;
  displayName: string;
  handleName: string;
  gender: string;
  phoneNumber?: string;
  preferredContentCategory: string[];
};

const initialUserDetails: userOnboardingDetails = {
  displayName: "",
  handleName: "",
  gender: "",
  preferredContentCategory: [],
  profilePhotoUrl: "",
};

const InputLabelComponent = (props: any) => {
  return (
    <InputLabel
      sx={{
        color: colors.black[200],
        fontSize: typography.font_size.sm,
        marginBottom: "8px",
      }}
    >
      {props.children}
      {props.isMandatory && (
        <span style={{ color: "red", marginLeft: "4px" }}>*</span>
      )}
    </InputLabel>
  );
};

const UserOnboardingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUserId = localStorage.getItem("currentUserId");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [userDetails, setUserDetails] =
    useState<userOnboardingDetails>(initialUserDetails);
  const { isAvailable, handleNameChange } = useHandleNameAvailability();
  const [isSubmitted, setIsSubmitted] = useState<Status>(STATUS.IDLE);
  const [fieldErrors, setFieldErrors] = useState<any>({});

  const userEmail: string | null = location.state?.email;
  const userId: string | null = location.state?.userId;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
    if (name === "handleName") {
      const lowercasedValue = value.toLowerCase();
      if (!/^[a-z0-9_]+$/.test(lowercasedValue)) {
        setFieldErrors((prev: any) => ({
          ...prev,
          handleName: "Invalid handle name can only contain a-z, 0-9, _",
        }));
      } else {
        handleNameChange(lowercasedValue);
        setUserDetails((prev) => ({ ...prev, handleName: lowercasedValue }));
        setFieldErrors((prev: any) => ({ ...prev, handleName: null }));
      }
    }
  };

  const handleUserOnboarding = async () => {
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      setIsSubmitted(STATUS.ERROR);
      return;
    }

    try {
      setIsSubmitted(STATUS.LOADING);
      if (phoneNumber.length === 10) {
        userDetails.phoneNumber = phoneNumber;
      }
      if (profilePicture) userDetails.profilePhotoUrl = profilePicture;
      const response = await userApi.onboarduser(userId, userDetails);
      const { data } = response;
      const { accessToken, user } = data;
      localStorage.setItem("accessToken", JSON.stringify(accessToken));
      dispatch(setUser(user));
      setIsSubmitted(STATUS.SUCCESS);
      navigate("/user/home");
    } catch (error) {
      setIsSubmitted(STATUS.ERROR);
      console.log(error);
    }
  };

  const validateFields = () => {
    const errors: any = {};
    const { displayName, handleName, preferredContentCategory, gender } =
      userDetails;

    if (!displayName) {
      errors.displayName = "Display Name is required";
    }
    if (!handleName) {
      errors.handleName = "Handle Name is required";
    } else if (!isAvailable) {
      errors.handleName = "Handle Name is unavailable";
    }
    if (preferredContentCategory.length < 3) {
      errors.preferredContentCategory = "Please select at least 3 categories";
    }
    if (!gender) {
      errors.gender = "Please select a gender";
    }
    if (phoneNumber && phoneNumber.length !== 10) {
      errors.phoneNumber = "Phone number must be 10 digits";
    }
    return errors;
  };

  const isSubmitButtonEnabled = () => {
    return true; // Submit button is enabled by default
  };

  if (!userEmail) {
    return <Navigate to="/login" />;
  }

  return (
    <Box m="auto" maxWidth="sm" p="16px 12px">
      <UserDisplayPicture setProfilePicture={setProfilePicture} />
      <Stack direction="column" gap="32px">
        <Box>
          <InputLabelComponent isMandatory={true}>
            Display Name
          </InputLabelComponent>
          <TextInput
            name="displayName"
            placeholder="Enter your display name"
            value={userDetails.displayName}
            onChange={handleChange}
          />
          {fieldErrors.displayName && (
            <Typography color="error">{fieldErrors.displayName}</Typography>
          )}
        </Box>
        <Box>
          <InputLabelComponent isMandatory={true}>
            Handle Name
          </InputLabelComponent>
          <TextInput
            name="handleName"
            placeholder="e.g., johndoe123, johndoe_123, john_doe_123"
            value={userDetails.handleName}
            onChange={handleChange}
            isAvailable={isAvailable}
            helperText={
              userDetails.handleName && !fieldErrors.handleName
                ? isAvailable
                  ? "Available"
                  : "Handle name unavailable"
                : ""
            }
          />
          {fieldErrors.handleName && (
            <Typography color="error">{fieldErrors.handleName}</Typography>
          )}
        </Box>
        <Box>
          <InputLabelComponent>Email</InputLabelComponent>
          <TextInput
            name="email"
            placeholder="abc@xyz.com"
            value={userEmail}
            disabled
            onChange={() => {}}
          />
        </Box>
        <Box>
          <InputLabelComponent isMandatory={true}>
            How do you identify yourself?
          </InputLabelComponent>
          <GenderSelectRadioInput
            onChange={handleChange}
            value={userDetails.gender}
          />
          {fieldErrors.gender && (
            <Typography color="error">{fieldErrors.gender}</Typography>
          )}
        </Box>
        <Box>
          <InputLabelComponent>Phone Number (optional)</InputLabelComponent>
          <OptionalPhoneNumberInput
            name="phoneNumber"
            value={phoneNumber}
            onChange={(e: any) => setPhoneNumber(e.target.value)}
          />
          {fieldErrors.phoneNumber && (
            <Typography color="error">{fieldErrors.phoneNumber}</Typography>
          )}
        </Box>
        <Box>
          <InputLabelComponent isMandatory={true}>
            What type of content would you like to see?
          </InputLabelComponent>
          <ChooseContent
            name="preferredContentCategory"
            options={options}
            setUserDetails={setUserDetails}
            selectAll={true}
          />
          {fieldErrors.preferredContentCategory && (
            <Typography color="error">
              {fieldErrors.preferredContentCategory}
            </Typography>
          )}
        </Box>
        <Stack textAlign="center" gap="8px" direction="column">
          <Button variant="contained" onClick={handleUserOnboarding}>
            {"Submit"}
          </Button>
          <Button variant="outlined" onClick={() => navigate("/login")}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export { UserOnboardingPage };
