import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { typography, colors } from "../../../utils/styles";
const NoBookingsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 265px;
  height: 281px;
  gap: 8px;
  width: 100%;
position: fixed;
top: 23%;
`;
const Image = styled("img")``;
const Title = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
`;
const Subtitle = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
interface NoBookingsProps{
    title: string;
    subtitle: string;
}
const NoBookings:React.FC<NoBookingsProps> = ({
title, 
subtitle
}) => {
  return (
    <NoBookingsContainer>
    <Image src="/Images/illustration_reading.png" alt="no subscription" />
    <Title>{title}</Title>

    <Subtitle>
        {subtitle}
    </Subtitle>
  </NoBookingsContainer>
  )
}

export default NoBookings;