import React, {useEffect} from "react";
import { Avatar, Box, Button, Typography, styled } from "@mui/material";
import {
  AudioCallIcon,
  TextMessageIcon,
  VideoCallCameraIcon,
} from "../../../assets/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { typography, colors } from "../../../utils/styles";
import { AMOUNT_UNIT } from "../../../constants/amountUnitConstant";
import { Link, useLocation, useParams } from "react-router-dom";
import { resetBooking } from "../../../redux/slotBookingSlice";
import usersCreatorProfileServiceApi from "../../../api/user/creatorServiceApi";
import creatorsCreatorProfileServiceApi from "../../../api/creator/creatorProfileServiceApi";
import { setAnotherCreatorProfile, setAnothetCreatorsOnDemandRates } from "../../../redux/creatorServiceSlice";

const Container = styled(Box)`
  display: flex;
  width: 340px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid var(--Border-Primary, #7262c2);
  background: var(--Background-default, #fff);
`;
const Header = styled(Box)`
  display: flex;
  width: 100%;
  height: 77px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px 16px 0px 0px;
  border-bottom: 1px solid var(--Border-Primary, #7262c2);
  background: var(--Background-brand-primary-Subtle, #dcd7f5);
`;
const HeaderContent = styled(Box)`
  display: flex;
  padding: 8px 0px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const HeaderUserName = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Primary, #212121);
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.21px;
`;
const HeaderHandleName = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Secodary, #616161);
  text-overflow: ellipsis;
  /* Body/M-R */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.18px;
`;
const SubscriptionViewCardContentContainer = styled(Box)`
  display: flex;
  padding: 0 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
`;
const SubscriptionViewCardContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
const ViewCardContentLeftContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;
const ViewCardContentLeftIcon = styled(Box)``;
const ViewCardContentLeftTextContainer = styled(Box)``;
const ViewCardContentLeftTitleText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const ViewCardContentLeftSubTitleText = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Primary, #212121);
  text-overflow: ellipsis;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.15px;
`;
const ViewCardContentRightContainer = styled(Box)``;
const AvailableText = styled(Typography)`
  color: var(--Text-Positive, #2e7d32);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 16.2px */
  letter-spacing: 0.18px;
`;
const NoServiceText = styled(Typography)`
  color: var(--Text-Secodary, #616161);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 16.2px */
  letter-spacing: 0.18px;
`;

const BookSlotButton = styled(Button)`
  display: flex;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Background-brand-primary, #7262c2);
  background: var(--Background-brand-primary, #7262c2);
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  letter-spacing: 0.18px;
  width: 100%;
  &:hover {
    background: var(--Background-brand-primary, #7262c2);
  }
`;

const BuyOnDemandCard = () => {
  const { id } = useParams();
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const { profileData, onDemandRate } = useSelector(
    (state: RootState) => state.anotherCreator
  );
  const dispatch = useDispatch<AppDispatch>();
  const fetchCeatorsProfileData = async (id:string) => {
    try {
      if (id) {
        if (userRole === "user") {
          const response =
            await usersCreatorProfileServiceApi.getAnotherCreatorsProfile(id);
          if (response.status === 200) {
            dispatch(setAnotherCreatorProfile(response.data.data.creator));
          }
        } else if (userRole === "creator") {
          const response =
            await creatorsCreatorProfileServiceApi.getAnotherCreatorsProfile(
              id
            );
          if (response.status === 200) {
            dispatch(setAnotherCreatorProfile(response.data.data.creator));
          }
        }
      }
    } catch (error) {
      console.error("Error fetching creators profile data", error);
    }
  };

  const fetchCreatorsOnDemandData = async (id:string) => {
    try {
      if (id) {
        if (userRole === "user") {
          const response =
            await usersCreatorProfileServiceApi.getCreatorsOnDemandRates(id);
          if (response.status === 200) {
            dispatch(
              setAnothetCreatorsOnDemandRates(response.data.data.onDemandRate)
            );
          }
        } else if (userRole === "creator") {
          const response =
            await creatorsCreatorProfileServiceApi.getCreatorsOnDemandRates(id);
          if (response.status === 200) {
            dispatch(
              setAnothetCreatorsOnDemandRates(response.data.data.onDemandRate)
            );
          }
        }
      }
    } catch (error) {
      console.error("Error fetching creators on demand rates", error);
    }
  };

  useEffect(() => {
    if(id && !onDemandRate.id){
      fetchCeatorsProfileData(id)
      fetchCreatorsOnDemandData(id)
    }
  }, [id, onDemandRate]);

  console.log(onDemandRate);
  console.log(profileData);
  return (
    <Container>
      <Header>
        <HeaderContent>
          <Box sx={{ position: "relative" }}>
            <Avatar
              sx={{ width: "40px", height: "40px" }}
              alt="user"
              src={profileData?.profilePhotoUrl}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <HeaderUserName>{profileData?.displayName}</HeaderUserName>
            <HeaderHandleName>@{profileData?.handleName}</HeaderHandleName>
          </Box>
        </HeaderContent>
      </Header>

      <SubscriptionViewCardContentContainer>
        <SubscriptionViewCardContent>
          <ViewCardContentLeftContainer>
            <ViewCardContentLeftIcon>
              <TextMessageIcon />
            </ViewCardContentLeftIcon>
            <ViewCardContentLeftTextContainer>
              <ViewCardContentLeftTitleText>
                Direct Message
              </ViewCardContentLeftTitleText>
              <ViewCardContentLeftSubTitleText>
                {onDemandRate?.directMessageYc
                  ? `${onDemandRate?.directMessageYc}${AMOUNT_UNIT.MAIN_UNIT} per 1 minute`
                  : "Not providing direct messages"}
              </ViewCardContentLeftSubTitleText>
            </ViewCardContentLeftTextContainer>
          </ViewCardContentLeftContainer>
          {onDemandRate?.directMessageYc ? (
            <ViewCardContentRightContainer>
              <AvailableText>Available</AvailableText>
            </ViewCardContentRightContainer>
          ) : (
            <ViewCardContentRightContainer>
              <NoServiceText>No Services</NoServiceText>
            </ViewCardContentRightContainer>
          )}
        </SubscriptionViewCardContent>
        <SubscriptionViewCardContent>
          <ViewCardContentLeftContainer>
            <ViewCardContentLeftIcon>
              <AudioCallIcon />
            </ViewCardContentLeftIcon>
            <ViewCardContentLeftTextContainer>
              <ViewCardContentLeftTitleText>
                Audio Call
              </ViewCardContentLeftTitleText>
              <ViewCardContentLeftSubTitleText>
                {onDemandRate?.audioCallYc
                  ? `${onDemandRate?.audioCallYc}${AMOUNT_UNIT.MAIN_UNIT} per 1 minute`
                  : "Not providing audio call"}
              </ViewCardContentLeftSubTitleText>
            </ViewCardContentLeftTextContainer>
          </ViewCardContentLeftContainer>
          {onDemandRate?.audioCallYc ? (
            <ViewCardContentRightContainer>
              <AvailableText>Available</AvailableText>
            </ViewCardContentRightContainer>
          ) : (
            <ViewCardContentRightContainer>
              <NoServiceText>No Services</NoServiceText>
            </ViewCardContentRightContainer>
          )}
        </SubscriptionViewCardContent>
        <SubscriptionViewCardContent>
          <ViewCardContentLeftContainer>
            <ViewCardContentLeftIcon>
              <VideoCallCameraIcon />
            </ViewCardContentLeftIcon>
            <ViewCardContentLeftTextContainer>
              <ViewCardContentLeftTitleText>
                Video Call
              </ViewCardContentLeftTitleText>
              <ViewCardContentLeftSubTitleText>
                {onDemandRate?.videoCallYc
                  ? `${onDemandRate?.videoCallYc}${AMOUNT_UNIT.MAIN_UNIT} per 1 minute`
                  : "Not providing video call"}
              </ViewCardContentLeftSubTitleText>
            </ViewCardContentLeftTextContainer>
          </ViewCardContentLeftContainer>
          {onDemandRate?.videoCallYc ? (
            <ViewCardContentRightContainer>
              <AvailableText>Available</AvailableText>
            </ViewCardContentRightContainer>
          ) : (
            <ViewCardContentRightContainer>
              <NoServiceText>No Services</NoServiceText>
            </ViewCardContentRightContainer>
          )}
        </SubscriptionViewCardContent>

        <BookSlotButton>
          <Link
            to={`/${userRole}/book-slot?serviceType=onDemand&id=${
              profileData?.userId
            }&creatorName=${profileData?.displayName}&audioCallMinutes=${
              onDemandRate?.audioCallYc
            }&videoCallMinutes=${
              onDemandRate?.videoCallYc
            }&directMessageMinutes=${
              onDemandRate?.directMessageYc
            }&isAudioCall=${onDemandRate?.audioCallYc !== null}&isVideoCall=${
              onDemandRate?.videoCallYc !== null
            }&isDirectMessage=${onDemandRate?.directMessageYc !== null}`}
            style={{ textDecoration: "none", width: "100%", color: "white" }}
            onClick={() => dispatch(resetBooking())}
          >
            Book Your Slot
          </Link>
        </BookSlotButton>
      </SubscriptionViewCardContentContainer>
    </Container>
  );
};

export default BuyOnDemandCard;
