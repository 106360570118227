import { Box, styled, Divider, Avatar, Stack, Typography } from "@mui/material";
import { PageTagComponent } from "../../components/Common/PageTagComponent";
import { colors, typography } from "../../utils/styles";
import { useLocation } from "react-router-dom";
import useToast from "../../hooks/useToast";
import { useEffect } from "react";

const SubscriptionPlanContainer = styled(Box)`
  border-radius: 8px;
  border: 1px solid ${colors.violet[100]};
`;
const AboutCreator = styled(Box)`
  border-bottom: 1px solid ${colors.violet[100]};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: ${colors.purple[50]};
  padding-inline: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
`;
const PlanDetails = styled(Box)`
  padding-block: 0.5rem;
`;
const OtherDetails = styled(Box)`
  padding-block: 0.5rem;
`;
const DisplayName = styled(Typography)`
  font-size: ${typography.font_size.base};
  font-weight: ${typography.font_weight.bolder};
`;
const HandleName = styled(Typography)`
  font-size: ${typography.font_size.sm};
  font-weight: ${typography.font_weight.regular};
`;

const PlanActivatedPage = () => {
  const location = useLocation();
  const { planData, handleName, displayName } = location.state;
  const { ToastMessage, handleOpenToast } = useToast();
  useEffect(() => {
    handleOpenToast("Your subscription plan is active now.", "success");
  }, []);
  return (
    <Box>
      <ToastMessage />
      <PageTagComponent to="/user/home">
        Subscription Plan Activated
      </PageTagComponent>
      <Box px="1rem" pt="1rem">
        <SubscriptionPlanContainer>
          <AboutCreator>
            <Avatar />
            <Stack>
              <DisplayName>{handleName}</DisplayName>
              <HandleName>{displayName}</HandleName>
            </Stack>
          </AboutCreator>
          <Box px="1rem">
            <PlanDetails>
              <Typography
                gutterBottom
                fontSize={typography.font_size.sm}
                fontWeight={typography.font_weight.bolder}
              >
                CC {planData.amountInYC} / Month
              </Typography>
              <Box>
                <Typography fontSize={typography.font_size.xs}>
                  Subscription Offerings -
                </Typography>
                <Box>
                  <Typography fontSize={typography.font_size.sm}>
                    {planData.directMessage ? planData.directMessageMinutes : 0}{" "}
                    Text Messages Characters
                  </Typography>
                  <Typography fontSize={typography.font_size.sm}>
                    {planData.videoCall ? planData.videoCallMinutes : 0} min
                    video call
                  </Typography>
                  <Typography fontSize={typography.font_size.sm}>
                    {planData.audoCall ? planData.audioCallMinutes : 0} min
                    audio call
                  </Typography>
                </Box>
              </Box>
            </PlanDetails>
            <Divider sx={{ border: `1px solid ${colors.purple[50]}` }} />
            <OtherDetails>
              <Typography
                gutterBottom
                fontSize={typography.font_size.sm}
                fontWeight={typography.font_weight.bolder}
              >
                Other Details
              </Typography>
              <Box component="ul" sx={{ pl: "1rem" }}>
                <Typography textOverflow="ellipsis" component={"li"}>
                  {" "}
                  10% default discount for subscribed users who want to buy the
                  post.
                </Typography>
                <Typography component={"li"}>
                  Free post will be provided to whoever buys this monthly
                  subscription.
                </Typography>
                <Typography component={"li"}>
                  1 month will start from the day user has bought this plan.
                </Typography>
              </Box>
            </OtherDetails>
          </Box>
        </SubscriptionPlanContainer>
      </Box>
    </Box>
  );
};

export { PlanActivatedPage };
