import ApiClient from "../ApiClient";

class UserApi {
  private client = ApiClient.getInstance();
  private token: string | null = null;

  constructor() {
    this.setToken();
  }

  public setToken() {
    const strToken = localStorage.getItem("accessToken");

    if (strToken) {
      this.token = JSON.parse(strToken);
    }
  }

  private filterUndefinedFields(obj: any): any {
    const filteredObj: any = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== undefined && obj[key] !== null && obj[key] !== "") {
        filteredObj[key] = obj[key];
      }
    });
    return filteredObj;
  }

  public async getCreatorOnboardingStep() {
    ApiClient.setHeaders({ Authorization: `Bearer ${this.token}` });
    try {
      const response = await this.client.get(`user/become-creator/next-stage`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  public async checkHandleAvailability(input: string) {
    try {
      const response = await this.client.get(`user/handle/${input}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async onboarduser(userId: string | null, userDetails: any) {
    const filteredUserDetails = this.filterUndefinedFields(userDetails);
    try {
      const response = await this.client.put(`user/onboard/${userId}`, {
        ...filteredUserDetails,
        gender: userDetails.gender.toUpperCase(),
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async getUserProfile() {
    ApiClient.setHeaders({ Authorization: `Bearer ${this.token}` });
    try {
      const response = await this.client.get(`user/profile`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  public async updateUserProfile(userProfileDetails: object) {
    const filteredUserDetails = this.filterUndefinedFields(userProfileDetails);
    this.setToken();
    ApiClient.setHeaders({ Authorization: `Bearer ${this.token}` });
    try {
      const response = await this.client.put(
        `user/profile`,
        filteredUserDetails
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async becomeCreatorStepOne(userOnboardingData: object) {
    const filteredUserOnboardingData =
      this.filterUndefinedFields(userOnboardingData);
    console.log(userOnboardingData)
    ApiClient.setHeaders({ Authorization: `Bearer ${this.token}` });
    try {
      const response = await this.client.post("user/become-creator?step=1", {
        ...filteredUserOnboardingData,
        gender: filteredUserOnboardingData.gender.toUpperCase(),
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async onboardingStepTwoAsIndividualType(payload: {
    creatorType: string;
    individualName: string;
  }) {
    try {
      ApiClient.setHeaders({ Authorization: `Bearer ${this.token}` });

      const response = await this.client.post(
        "user/become-creator?step=2",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async onboardingStepTwoAsBusinessType(payload: {
    creatorType: string;
    businessName: string;
  }) {
    try {
      ApiClient.setHeaders({ Authorization: `Bearer ${this.token}` });

      const response = await this.client.post(
        "user/become-creator?step=2",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async sendAadharOtp(payload: { aadhar_number: string }) {
    try {
      ApiClient.setHeaders({ Authorization: `Bearer ${this.token}` });

      const response = await this.client.post(
        "user/document/send-aadhar-otp",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async verifyAadharOtp(payload: {
    request_id: string;
    otp: string;
    aadhar_number: string;
  }) {
    try {
      ApiClient.setHeaders({ Authorization: `Bearer ${this.token}` });

      const response = await this.client.post(
        "user/document/verify-aadhar-otp",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async verifyPan(payload: {
    pan_number: string;
    pan_holder_name: string;
  }) {
    try {
      ApiClient.setHeaders({ Authorization: `Bearer ${this.token}` });

      const response = await this.client.post(
        "user/document/verify-pan",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async verifyGstNum(payload: { gst_number: string }) {
    try {
      ApiClient.setHeaders({ Authorization: `Bearer ${this.token}` });

      const response = await this.client.post(
        "user/document/verify-gst",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async onboardingStepThree() {
    try {
      ApiClient.setHeaders({ Authorization: `Bearer ${this.token}` });

      const response = await this.client.post("user/become-creator?step=3");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async getWalletTopUpOptions(){
    try{
      const response = await this.client.get('payment/add-money-config');
    }catch(error){
      throw error;
    }
  }

 
}

export default new UserApi();
