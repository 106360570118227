import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import { Sidebar } from "./Sidebar";

const Navbar = ({userRole}:{userRole:string}) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
  
    const handleDrawerOpen = () => {
      setSidebarOpen(true);
    };
  
    const handleDrawerClose = () => {
      setSidebarOpen(false);
    };
  
    return (
      <>
      
        <AppBar position="sticky" sx={{width:"100%",background:'#DCD7F5',boxShadow:'none', top: "0", left: "0"}}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" 
              onClick={handleDrawerOpen}
            >
              <img src="/Icons/hamburger_icon.svg"/>
            </IconButton>
            <img src = '/Icons/yaara_logo.svg' alt="logo" style={{width:"22px",height:"20px",flexGrow:1}} />
            <IconButton edge="end">
              <img src ="/Icons/notification_bell_icon.svg" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Sidebar userRole={userRole} open={sidebarOpen} onClose={handleDrawerClose} />
      </>
    );
  };

  export {Navbar}