import React, { useState } from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { colors, typography } from "../../../utils/styles";
import { BackButton } from "../../../assets/SvgIcons";
import CreatorPostGridView from "./CreatorPostGridView";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import UserFeed from "../../Feeds/UserFeed";
import { setUserFeeds } from "../../../redux/userFeedSlice";
import { UserFeedType } from "../../../types";
import { useNavigate } from "react-router-dom";

const Container = styled(Box)`
  width: 100%;
`;
const PostsContent = styled(Box)`
  display: grid;
  gap: 3px;
  // grid-template-columns: repeat(auto-fit, minmax(122px, 1fr));
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  justify-content: space-between;
`;

const PostPageMainContainer=styled(Box)`
  
`;

const PostPageContainer = styled(Box)``;
const Header = styled(Box)`
  position: sticky;
  top: 50px;
  z-index: 2;
  display: flex;
  padding: 16px;
  background: white;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  z-index: 12;
`;

const HeaderContent = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HeaderTitleText = styled(Typography)`
  color: #212121;
  font-size: 16px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: 0.24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  flex: 1 0 0;
`;

const NoPostScreenContainer=styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoPostScreen=styled(Typography)`
color: ${colors.gray[80]};
text-align: center;
font-size: ${typography.font_size.base}px;
font-style: normal;
font-weight: ${typography.font_weight.bold};
line-height: ${typography.line_height.default}; 
letter-spacing: ${typography.letter_spacing.default}; 
margin-top: 26px;
`
const PostPageContent = styled(Box)``;
const CreatorPostsTab = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate=useNavigate();
  const { post } = useSelector((state: RootState) => state.creatorProfile);
  const [pageView, setPageView] = useState(false);
  const handleOnClickGoBackButton = () => {
    setPageView(false);
  };
  const handlePostClick = () => {
    setPageView(true);
    dispatch(setUserFeeds(post));
  };

  const handleOnClickEarnings=(post:UserFeedType)=>{
    navigate("/creator/post-earnings", {
      state:{
        post: post
      }
    })
  }

  return (
    <Container>
      <PostsContent>
        {!pageView &&
          post?.map((item, index) => (
            <CreatorPostGridView
              item={item}
              key={index}
              handlePostClick={handlePostClick}
            />
          ))}
    </PostsContent>
    <PostPageMainContainer>
        {pageView && (
          <PostPageContainer>
            <Header>
              <HeaderContent>
                <Button
                  onClick={handleOnClickGoBackButton}
                  sx={{ minWidth: "0", padding: "0" }}
                >
                  <BackButton />
                </Button>
                <HeaderTitleText>Posts</HeaderTitleText>
              </HeaderContent>
            </Header>

            <PostPageContent>
              {post?.map((item, index) => (
                <UserFeed key={index} post={item} handleOnClickEarnings={handleOnClickEarnings} isMyPost={true}/>
              ))}
            </PostPageContent>
          </PostPageContainer>
        )}

        {
              post?.length === 0 ? (
                <NoPostScreenContainer>
                <NoPostScreen>
                    Not uploaded any post yet
                </NoPostScreen>
              </NoPostScreenContainer>
              ): null
            }
      </PostPageMainContainer>
    </Container>
  );
};

export default React.memo(CreatorPostsTab);
