import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { RequestCloseModal, RequestOpenModal, severityType } from "../../types";
import DynamicStyledInput from "../../components/StyledComponent/DynamicStyledInput";
import { colors, typography } from "../../utils/styles";

const Container = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Content = styled(Box)`
  display: flex;
  width: 310px;
  flex-direction: column;
  border-radius: 12px;
  background: var(--Background-card, ${colors.white});
  align-items: center;
  height: 465px;
`;

const ContentUpperContainer = styled(Box)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const Title = styled(Typography)`
  color: var(--Text-Primary, ${colors.black[200]});
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.xl}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;

const HandleNameText = styled(Typography)`
  color: var(--Text-Secondary, ${colors.gray[80]});
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const CheckboxContainer = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const CheckboxLabel = styled(Typography)`
  color: var(--Text-Secondary, ${colors.gray[80]});
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
  flex: 1 0 0;
`;

const NewCheckbox = styled(Checkbox)`
  padding: 4px 0;
  &.Mui-checked {
    color: ${colors.green[100]};
  }
  &[data-testid="CheckBoxOutlineBlankIcon"] {
    border: 1px solid ${colors.red[100]};
  }
`;

const OtherReasonContainer = styled(Box)`
  display: flex;
  height: 123px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
`;

const Text = styled(Typography)`
  color: var(--Text-Primary, ${colors.black[200]});
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const CounterText = styled(Typography)`
  color: ${colors.gray[80]};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;

const InputLabelContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
`;

const ButtonsContainer = styled(Box)`
  display: flex;
  padding: 0px 16px 8px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const CancelButton = styled(Button)`
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  width: 135px;
  color: var(--Text-Brand-Primary, ${colors.violet[100]});
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;

const ReportButton = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  width: 135px;
  border-radius: 8px;
  background: var(--Background-brand-primary, ${colors.violet[100]});
  overflow: hidden;
  color: var(--Text-Inverted, ${colors.white});
  text-align: center;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;

const NoPostScreen = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  margin-top: 26px;
`;

const ReportTextArray = [
  "This content is offensive or violates “Passes” Terms of Service",
  "This content contains stolen material (DMCA)",
  "This content is spam",
  "Report abuse",
];

interface ReportPostProps {
  requestOpenModal: RequestOpenModal;
  requestCloseModal: RequestCloseModal;
  openSnackBar: (message: string, severity: severityType) => void;
}

type ReportType = {
  reportTextArray: string[];
  otherReason: string;
};

const initialState = {
  reportTextArray: [],
  otherReason: "",
};

const ReportPost: React.FC<ReportPostProps> = ({
  requestOpenModal,
  requestCloseModal,
  openSnackBar,
}) => {
  const [reportState, setReportState] = useState<ReportType>(initialState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const truncatedValue = value.length > 50 ? value.substring(0, 50) : value;
    setReportState((prevState) => ({
      ...prevState,
      otherReason: truncatedValue,
    }));
  };

  const handleOnClickReport = () => {
    console.log("this is the reportState", reportState);
    requestCloseModal();
    openSnackBar("Creator successfully reported", "success");
  };

  const handleCheckBoxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    if (checked) {
      setReportState((prevState) => ({
        ...prevState,
        reportTextArray: [...prevState.reportTextArray, value],
      }));
    } else {
      setReportState((prevState) => ({
        ...prevState,
        reportTextArray: prevState.reportTextArray?.filter(
          (item: string) => item !== value
        ),
      }));
    }
  };

  return (
    <Container>
      <Content>
        <ContentUpperContainer>
          <TitleContainer>
            <Title>Report Post</Title>
            <HandleNameText>@creator-123</HandleNameText>
          </TitleContainer>

          <ContentContainer>
            {ReportTextArray.map((text, index) => (
              <CheckboxContainer key={index}>
                <NewCheckbox value={text} onChange={handleCheckBoxClick} />
                <CheckboxLabel>{text}</CheckboxLabel>
              </CheckboxContainer>
            ))}
          </ContentContainer>

          <OtherReasonContainer>
            <Text>Other</Text>

            <InputLabelContainer>
              <DynamicStyledInput
                name="comment"
                placeholder="Why are you leaving?"
                value={reportState?.otherReason}
                onChange={handleChange}
                height="72px"
                width="100%"
                padding="12px"
                border="1px solid #9E9E9E"
                fullWidth
              />
              <CounterText
                sx={{ marginLeft: "8px" }}
              >{`${reportState.otherReason?.length}/50`}</CounterText>
            </InputLabelContainer>
          </OtherReasonContainer>
        </ContentUpperContainer>

        <ButtonsContainer>
          <CancelButton onClick={requestCloseModal}>Cancel</CancelButton>

          <ReportButton onClick={handleOnClickReport}>Report</ReportButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};

export default ReportPost;
