import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { SidebarRoutes } from "../routes/sidebarRoutes";
import { selectCurrentPage, setPage } from "../redux/activePageSlice";
import { colors } from "../utils/styles";

const LogoSection = () => {
  return (
    <Stack direction="row" alignItems="center" gap={3} mb="40px">
      <img
        src="/Icons/yaara_logo.svg"
        style={{ height: "24px", width: "24px" }}
      />
      <Typography fontSize="20px" fontWeight={700}>
        Yaara
      </Typography>
    </Stack>
  );
};

const Sidebar = ({
  open,
  onClose,
  userRole,
}: {
  open: boolean;
  onClose: () => void;
  userRole: String;
}) => {
  const dispatch = useDispatch();
  const currentPage = useSelector(selectCurrentPage);
  const {
    user: { isCreator },
  } = useSelector((state: any) => state);

  const navigate = useNavigate();
  const handleBecomeACreatorOnclick = (event: any) => {
    navigate("/register-for-creator");
    dispatch(setPage(""));
    onClose();
  };
  const handlePageChange = (page: string) => {
    dispatch(setPage(page));
    onClose();
  };

  const handleLogout = () => {
    localStorage.removeItem(
      userRole === "USER" ? "accessToken" : "creatorAccessToken"
    );
    navigate("/login");
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        py: "32px",
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: "50%",
          py: "32px",
          pl: "24px",
          position: "absolute",
        },
      }}
    >
      <LogoSection />
      <List sx={{ width: "100%" }}>
        {SidebarRoutes.map((route) => (
          <Link
            to={route.path}
            style={{ textDecoration: "none", color: colors.black[200] }}
          >
            <ListItem
              // component = {Link}
              key={route.displayText}
              sx={{ display: "flex", gap: "8px", mb: "16px", pl: 0 }}
              onClick={() => handlePageChange(route.displayText)}
            >
              <img src={route.displayIcon} alt={route.displayText} />
              <ListItemText
                primary={route.displayText}
                disableTypography
                sx={{
                  fontSize: "14px",
                  fontWeight:
                    currentPage.toLowerCase() ===
                    route.displayText.toLowerCase()
                      ? 700
                      : 400,
                }}
              />
            </ListItem>
          </Link>
        ))}
        <ListItem sx={{ pl: 0 }}>
          {userRole === "USER" && !isCreator && (
            <Button
              variant="primary"
              sx={{ fontWeight: 400, fontSize: "14px" }}
              onClick={handleBecomeACreatorOnclick}
            >
              Become a Creator
            </Button>
          )}
        </ListItem>
      </List>
      <ListItemButton
        sx={{ mt: "auto", display: "flex", flexGrow: 0, gap: "8px", pl: 0 }}
      >
        <img src="/Icons/logout_icon.svg" />
        <ListItemText primary={"Logout"} onClick={handleLogout} />
      </ListItemButton>
    </Drawer>
  );
};
export { Sidebar };
