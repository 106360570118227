import { ApiService } from '../ApiClient';
import { userAxiosInstance } from "../axiosInstance";
import "../interceptors/userAuthInterceptors"


class UsersLeaderboardServiceApi extends ApiService {
    constructor() {
        super(userAxiosInstance);
      }
  getTopFollowedCreators() {
    return this.axios.get(`/creator/leaderboard/follower`);
  }
  getTopEarningCreators(){
    return this.axios.get(`/creator/leaderboard/earning`);
  }
}

export default new UsersLeaderboardServiceApi();