import { Box, Button, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubscriptionSuccessCard from "../Subscription/SubscriptionSuccessCard";
import { OndemandRateType, SubscriptionType } from "../../../types";
import OnDemandPlanCard from "../OnDemandRate/OnDemandPlanCard";
import { colors, typography } from "../../../utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import bookingServiceApi from "../../../api/creator/bookingServiceApi";
import { setWeeklyAvailability } from "../../../redux/bookingSlice";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
const ServicesContainer = styled(Box)`
  width: 100%;
  display: flex;
  padding: 0px 16px;
  gap: 16px;
`;
const ServicesButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  align-self: stretch;
  width: 100%;
`;
const OnDemandButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const OnDemandTitle = styled(Typography)`
  // color: ${colors.brand_primary};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bolder};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const OnDemandButton = styled(Button)`
  display: flex;
  width: 106px;
  height: 32px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bolder};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
  cursor: pointer;
  &:hover {
    background: inherit;
  }
`;
const SubscriptionTitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
`;
const SubscriptionTitle = styled(Typography)`
  color: ${colors.brand_primary};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bolder};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const SubscriptionSubtitle = styled(Typography)`
overflow: hidden;
color: var(--Text-Negative, #B71C1C);
text-overflow: ellipsis;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 135%;
letter-spacing: 0.15px;
`;
const SubscriptionPlanButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const SubscriptionButton = styled(Button)`
  display: flex;
  width: 106px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.brand_primary};
  color: ${colors.white};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bolder};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
  &:hover {
    background: ${colors.violet[100]};
  }
`;
const SubscriptionCardContainer = styled(Box)`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const SubscriptionCardTitleContainer = styled(Box)``;

const SubscriptionCardTitle = styled(Typography)`
  color: ${colors.black[200]};
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bolder};
  line-height: ${typography.line_height.default};
`;
const OnDemandCardContainer = styled(Box)`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
const OnDemandTitleContainer = styled(Box)``;
const OnDemandTitleText = styled(Typography)`
  color: ${colors.black[200]};
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const ServicesContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

interface CreatorServicesProps {
  subscriptionPlanData: SubscriptionType;
  onDemandRate: OndemandRateType;
}
const CreatorServices: React.FC<CreatorServicesProps> = ({
  subscriptionPlanData,
  onDemandRate,
}) => {
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionType>();
  const [creatorOnDemandRate, setCreatorOnDemandrate] =
    useState<OndemandRateType>();
    const dispatch = useDispatch<AppDispatch>();
    const { weeklyAvailability, unavailability } = useSelector(
      (state: RootState) => state.bookings
    );
  const navigate = useNavigate();
  const handleOnSetDemandRateClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    navigate("/creator/set-on-demand-rates", {
      state: { data: creatorOnDemandRate },
    });
  };
  const handleOnCreatePlanClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigate("/creator/create-subscription-plan");
  };

  const getAvailabilityStatus=()=>{
    let isActive=weeklyAvailability?.length===0 || weeklyAvailability.every(slots=>!slots.checked) ? false : true;
    return isActive;
  }
  const checkForOnDemandRates = () => {
    let isAvailabilitySet=getAvailabilityStatus();
    if(!isAvailabilitySet) return true;
    let val =
      onDemandRate?.audioCallYc ||
      onDemandRate?.directMessageYc ||
      onDemandRate?.videoCallYc;
    let values = val ? true : false;
    return values;
  };
  const checkForSubscriptionData = () => {
    let isAvailabilitySet=getAvailabilityStatus();
    if(!isAvailabilitySet) return true;
    let val = subscriptionPlanData?.amountInYC;
    let values = val ? true : false;
    return values;
  };
  const fetchWeeklyAvailability = async () => {
    try{
    const response= await bookingServiceApi.getWeeklyAvailability();
    dispatch(setWeeklyAvailability(response.data.data));
    }
    catch(error){
      console.error("Error fetching weekly availability", error);
    }
  }
  const handleOnClickSetAvailability=()=>{
    navigate("/creator/bookings/set-availability")
  }
  useEffect(() => {
    if(weeklyAvailability?.every(it=>!it.checked)){
      fetchWeeklyAvailability();
    }
  }, [dispatch])
  useEffect(() => {
    if (subscriptionPlanData) {
      setSubscriptionData(subscriptionPlanData);
    }
  }, [subscriptionPlanData]);
  useEffect(() => {
    if (onDemandRate) {
      setCreatorOnDemandrate(onDemandRate);
    }
  }, [onDemandRate]);
  

  return (
    <Container>
      <ServicesContainer>
        <ServicesButtonsContainer>
          {(weeklyAvailability?.length===0 || weeklyAvailability.every(slots=>!slots.checked)) &&(
        <SubscriptionPlanButtonContainer>
          <SubscriptionTitleContainer>
              <SubscriptionTitle
                sx={{
                  color: colors.brand_primary
                }}
              >
                Set Availability
              </SubscriptionTitle>

              <SubscriptionSubtitle>
                Set your availability for bookings 
              </SubscriptionSubtitle>

            </SubscriptionTitleContainer>
            <SubscriptionButton
              onClick={handleOnClickSetAvailability}
              sx={{
                color: colors.gray[100],
                backgroundColor: colors.brand_primary,
              }}
            >
              {" "}
              Set Now
            </SubscriptionButton>
          </SubscriptionPlanButtonContainer>
          )}
          <OnDemandButtonContainer>
            <OnDemandTitle
              sx={{
                color: !checkForOnDemandRates()
                  ? colors.brand_primary
                  : colors.gray[90],
              }}
            >
              On Demand 1-1 Services
            </OnDemandTitle>
            <OnDemandButton
              onClick={handleOnSetDemandRateClick}
              disabled={checkForOnDemandRates()}
              sx={{
                color: !checkForOnDemandRates()
                  ? colors.gray[100]
                  : colors.gray[90],
                backgroundColor: !checkForOnDemandRates()
                  ? colors.brand_primary
                  : "rgba(230, 230, 230, 1)",
              }}
            >
              {" "}
              Set Rates
            </OnDemandButton>
          </OnDemandButtonContainer>
          <SubscriptionPlanButtonContainer>
            <SubscriptionTitleContainer>
              <SubscriptionTitle
                sx={{
                  color: !checkForSubscriptionData()
                    ? colors.brand_primary
                    : colors.gray[90],
                }}
              >
                Subscription Plan
              </SubscriptionTitle>
            </SubscriptionTitleContainer>
            <SubscriptionButton
              disabled={checkForSubscriptionData()}
              sx={{
                color: !checkForSubscriptionData()
                  ? colors.gray[100]
                  : colors.gray[90],
                backgroundColor: !checkForSubscriptionData()
                  ? colors.brand_primary
                  : "rgba(230, 230, 230, 1)",
              }}
              onClick={handleOnCreatePlanClick}
            >
              Create Plan
            </SubscriptionButton>
          </SubscriptionPlanButtonContainer>
        </ServicesButtonsContainer>
      </ServicesContainer>

      <ServicesContent>
        {checkForSubscriptionData() && getAvailabilityStatus() ? (
          <SubscriptionCardContainer>
            <SubscriptionCardTitleContainer>
              <SubscriptionCardTitle>Subscription Plan:</SubscriptionCardTitle>
            </SubscriptionCardTitleContainer>
            <SubscriptionSuccessCard items={subscriptionData} />
          </SubscriptionCardContainer>
        ) : null}

        {checkForOnDemandRates() && getAvailabilityStatus() ? (
          <OnDemandCardContainer>
            <OnDemandTitleContainer>
              <OnDemandTitleText>Your on demand 1-1 plan</OnDemandTitleText>
            </OnDemandTitleContainer>

            <OnDemandPlanCard items={creatorOnDemandRate} />
          </OnDemandCardContainer>
        ) : null}
      </ServicesContent>
    </Container>
  );
};

export default CreatorServices;
