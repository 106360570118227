import React, { useState } from "react";
import SubscriptionInputCard from "./SubscriptionInputForm";
import styled from "@emotion/styled";
import { Box, Divider, Typography } from "@mui/material";
import Snackbar from "../../Common/Snackbar";
import DynamicStyledInput from "../../StyledComponent/DynamicStyledInput";
import { AMOUNT_UNIT } from "../../../constants/amountUnitConstant";
import { colors, typography } from "../../../utils/styles";
import { SubscriptionType } from "../../../types";

const Container = styled(Box)`
  display: flex;
  padding: 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;
const DividerContainer = styled(Box)`
  width: 100%;
`;
const SubscriptionRateContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const SubscriptionRateTextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const SubscriptionRateText = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const SubscriptionRateSubText = styled(Typography)`
  color: ${colors.gray[80]};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const SubscriptionRateInputContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const SubscriptionRateAmountUnit = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
`;
const initialDmMessage = "Set dm time";
const initialVideoCallMessage = "Set audio time";
const initialAudioCallMessage = "Set video time";
interface CreateSubscriptionFormProps {
  subscriptionData: SubscriptionType;
  handleDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  snackbarOpen: boolean;
  snackbarMessage: string;
  handleAudioCallValueSet: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleVideoCallValueSet: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleDmValueSet: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleDeleteDirectMessage: () => void;
  handleDeleteAudioCall: () => void;
  handleDeleteVideoCall: () => void;
  handleCloseSnackbar: () => void;
}
const CreateSubscriptionForm: React.FC<CreateSubscriptionFormProps> = ({
  subscriptionData,
  handleDataChange,
  snackbarOpen,
  snackbarMessage,
  handleAudioCallValueSet,
  handleVideoCallValueSet,
  handleDmValueSet,
  handleDeleteDirectMessage,
  handleDeleteAudioCall,
  handleDeleteVideoCall,
  handleCloseSnackbar,
}) => {
  return (
    <Container>
      <SubscriptionInputCard
        title="Direct Message"
        subtitle={initialDmMessage}
        infoText="Trending time which other creators offering for DM:"
        minimumText="Minimum Time"
        maximumText="Maximum Time"
        minimumTime="30min"
        maximumTime="240min"
        inputInfoText="No. of minutes you want to offer"
        inputFieldUnitText="minute"
        name="directMessageMinutes"
        value={subscriptionData?.directMessageMinutes}
        onValueChange={handleDataChange}
        onValueSet={handleDmValueSet}
        onDelete={handleDeleteDirectMessage}
      />

      <SubscriptionInputCard
        title="Audio call"
        subtitle={initialAudioCallMessage}
        infoText="Trending time which other creators offering for Audio call:"
        minimumText="Minimum Time"
        maximumText="Maximum Time"
        minimumTime="30 min"
        maximumTime="240 min"
        inputInfoText="No. of minutes you want to offer"
        inputFieldUnitText="minute"
        name="audioCallMinutes"
        value={subscriptionData?.audioCallMinutes}
        onValueChange={handleDataChange}
        onValueSet={handleAudioCallValueSet}
        onDelete={handleDeleteAudioCall}
      />
      <SubscriptionInputCard
        title="Video call"
        subtitle={initialVideoCallMessage}
        infoText="Trending time which other creators offering for Video call:"
        minimumText="Minimum Time"
        maximumText="Maximum Time"
        minimumTime="30 min"
        maximumTime="240 min"
        inputInfoText="No. of minutes you want to offer"
        inputFieldUnitText="minute"
        name="videoCallMinutes"
        value={subscriptionData?.videoCallMinutes}
        onValueChange={handleDataChange}
        onValueSet={handleVideoCallValueSet}
        onDelete={handleDeleteVideoCall}
      />
      <DividerContainer>
        <Divider sx={{ my: 2 }} />
      </DividerContainer>
      <SubscriptionRateContainer>
        <SubscriptionRateTextContainer>
          <SubscriptionRateText>Set Subscription Rate</SubscriptionRateText>
          <SubscriptionRateSubText>
            Set your monthly subscription rate
          </SubscriptionRateSubText>
        </SubscriptionRateTextContainer>
        <SubscriptionRateInputContainer>
          <DynamicStyledInput
            padding="6px 12px"
            borderRadius="8px"
            height="31px"
            border={`2px solid  ${colors.violet[100]}`}
            background="#fff"
            value={subscriptionData.amountInYC}
            name="amountInYC"
            width="74px"
            onChange={handleDataChange}
          />
          <SubscriptionRateAmountUnit>
            {AMOUNT_UNIT.MAIN_UNIT}
          </SubscriptionRateAmountUnit>
        </SubscriptionRateInputContainer>
      </SubscriptionRateContainer>
      <Snackbar
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
      />
    </Container>
  );
};

export default CreateSubscriptionForm;
