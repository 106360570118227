import React from "react";

import { Box, Button, Typography } from "@mui/material";

import { typography, colors } from "../../utils/styles";
import { useNavigate } from "react-router-dom";

const ResetPasswordSuccess: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box mx="16px" my="24px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="16px"
        >
          <img src="/Icons/yaara_logo.svg" style={{ marginTop: "250px" }} />
          <Typography
            fontSize={typography.font_size["2xl"]}
            fontWeight={typography.font_weight["bold"]}
          >
            Password Changed
          </Typography>

          <Typography
            fontSize={typography.font_size["base"]}
            fontWeight={typography.font_weight["regular"]}
            color={colors.black["200"]}
            textAlign="center"
          >
            Your password has been changed successfully.
          </Typography>
        </Box>
      </Box>
      <Button
        variant="primary"
        fullWidth
        sx={{ position: "absolute", bottom: 0 }}
        onClick={()=>navigate('/login')}
      >
        Back To Login
      </Button>
    </Box>
  );
};

export default ResetPasswordSuccess;
