import { Box, styled, Avatar, Typography, Stack, Popover, Divider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { colors, typography } from "../../../utils/styles";
import { DeleteNewIcon, EditIcon, MenuIcon } from "../../../assets/SvgIcons";

const ActivityContainer = styled(Box)`
    background: green;
    width: 8px;
    height: 8px;
    der-radius: 0.5rem;
    border-radius: 12px;
    position: absolute;
    left: 76%;
    bottom: 5%;
    border: 1px solid white;
`;
const CommentText= styled(Typography)`
color: #212121;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 13.5px */
letter-spacing: 0.15px;
display: inline;
`;

const EditModalContainer=styled(Box)`
  display: flex;
  height: 80px;
  width: 158px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: var(--Background-card, #FFF);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: 24px;
  right: 0;
  z-index: 12;
`;
const MenuIconContainer = styled(Box)`
    position: relative;
    top: 4px;
`;

const EditContent=styled(Box)`
display: flex;
padding: 8px;
align-items: center;
gap: 8px;
align-self: stretch;
`;

const EditText=styled(Typography)` 
display: flex;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;
color: var(--Text-Primary, #212121);

/* Body/XL-R */
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 21.6px */
letter-spacing: 0.24px;
align-self: stretch;
`;

export default function CommentsScreen(props: any) {
  const { commentData, onEdit, onDelete } = props;
  const [viewText, setViewText] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const menuIconRef = useRef<HTMLDivElement | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleViewText = () => {
    setViewText((prev) => !prev);
  };
  const handleOpenEditDeleteModal = () => {
    setOpenModal((prev)=> !prev);
  };

  const handleOnEditButtonClick=(e:React.MouseEvent<HTMLDivElement>)=>{
    e.preventDefault();
    setOpenModal((prev)=> !prev);
    onEdit(commentData);
  }

  const handleOnDeleteButtonClick =(e:React.MouseEvent<HTMLDivElement>)=>{
    e.preventDefault();
    setOpenModal((prev)=> !prev);
    onDelete();
  }
  
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        menuIconRef.current &&
        !menuIconRef.current.contains(event.target as Node)
      ) {
        setOpenModal(false);
      }
    }

    if (openModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openModal]);

  return (
    <Box display="flex" gap="11px">
      <Box sx={{ position: "relative", width: "34px", height: "34px" }}>
        <Avatar
          sx={{ width: "34px", height: "34px" }}
          alt="user"
          src={commentData?.profilePhotoUrl}
        />
        <ActivityContainer />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          marginTop= "4px"
          position="relative"
        >
          <Stack>
            <Typography
              variant="body2"
              sx={{
                fontWeight: typography.font_weight.semiBold,
                color: colors.black[200],
              }}
            >
              {commentData?.displayName}
            </Typography>
          </Stack>
          {commentData.userId===props.profileUserId && (
            <>
               <MenuIconContainer ref={menuIconRef} onClick={handleOpenEditDeleteModal}>
            <MenuIcon />
          </MenuIconContainer>

          {openModal ?
            (
              <EditModalContainer ref={modalRef}>
                <EditContent onClick={handleOnEditButtonClick}>
                    <EditIcon
                      color={"#212121"}
                      width={"24"}
                      height={"24"}
                    />
                    <EditText>Edit</EditText>
                </EditContent>

                <Divider sx={{
                    width: "68%",
                    alignSelf: "self-end"
                }}/>


                <EditContent onClick={handleOnDeleteButtonClick}>
                    <DeleteNewIcon
                      color={"#212121"}
                    />
                    <EditText>Delete</EditText>
                </EditContent>
              </EditModalContainer>
            )
            : null
          }
            </>
          )} 
        </Stack>

  <Typography
      sx={{
        color: "#212121",
        fontSize: "10px",
        width: "100%",
        marginTop: "2px",
        display: "block",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "135%",
        letterSpacing: "0.15px",
        wordWrap: "break-word"
      }}
    >
      {commentData.text.length > 130 ? (
        <>
          <CommentText>
            {viewText ? commentData.text : `${commentData.text.substring(0, 131)}...`}
          </CommentText>
          <Typography
            component="span"
            sx={{
              fontSize: "10px",
              fontWeight: "bold",
              color: colors.brand_primary,
              cursor: "pointer",
              display: "inline",
              marginLeft: "5px",
            }}
            onClick={handleViewText}
          >
            {viewText ? "Read less" : "Read more"}
          </Typography>
        </>
      ) : (
        <CommentText>
          {commentData.text}
        </CommentText>
      )}
    </Typography>
      </Box>
    </Box>
  );
}