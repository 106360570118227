import { Box, InputLabel, Stack, TextField, Typography } from "@mui/material"
import { PageTagComponent } from "../../components/Common/PageTagComponent"
import { useEffect } from "react"
import { TextInput } from "../../components/Common/TextInput"

const addedAcc = [
    {
        bankName:''
    }
]

const KYC = () =>{
    useEffect(()=>{
        // fetch bank details if added
    },[])
    return (
        <Box>
            <PageTagComponent>KYC Verification</PageTagComponent>
            <Box pl='16px' sx={{display:'flex',flexDirection:'column',gap:'16px'}}>
            <Stack direction='row' gap={1.25}>
                    <p>TP</p>
                <Stack flexGrow={1} pb="8px" direction='row' justifyContent='space-between' alignItems='center' borderBottom='1px solid gray'>
                    <Box>
                        <Typography fontSize='14px' fontWeight={700}>PAN Card Details</Typography>
                        <Typography>1234577</Typography>
                    </Box>
                <Typography pr="16px" color='#2E7D32' fontSize='12px' fontWeight={700}>Verified</Typography>
                </Stack>
            </Stack>
            <Stack direction='row' gap={1.25}>
                    <p>TP</p>
                <Stack flexGrow={1} pb="8px" direction='row' justifyContent='space-between' alignItems='center' borderBottom='1px solid gray'>
                    <Box>
                        <Typography fontSize='14px' fontWeight={700}>PAN Card Details</Typography>
                        <Typography  >1234577</Typography>
                    </Box>
                <Typography pr="16px" color='#2E7D32' fontSize='12px' fontWeight={700}>Verified</Typography>
                </Stack>
            </Stack>
            </Box>
        </Box>
    )
    // <Box>
    //    <PageTagComponent>Add Bank Details</PageTagComponent>
    //    <Box p="16px" sx={{background:'rgba(227, 242, 253, 1)'}}>
    //     Enter Bank Details for User
    //    </Box>
    //    <Box sx={{display:'flex',flexDirection:'column', gap:'16px'}} pt="8px" px="16px">
    //     <Box>
    //         <InputLabel>Account Number</InputLabel>
    //         <TextField fullWidth/>
    //     </Box>
    //     <Box>
    //         <InputLabel>Retype Account Number</InputLabel>
    //         <TextField fullWidth/>
    //     </Box>
    //     <Box>
    //         <InputLabel>IFSC Number</InputLabel>
    //         <TextField fullWidth/>
    //     </Box>
    //    </Box>
    // </Box>
}
export {KYC}