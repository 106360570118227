import React, { SyntheticEvent, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { IconButton } from "@mui/material";
import { CloseIcon } from "../assets/SvgIcons";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={4} ref={ref} variant="filled" {...props} />;
});

const variantColors = {
  error: '#B71C1C',
  success: '#2E7D32',
  warning: '#C15700',
  info: '#212121', 
};


const useToast = (props?:any) => {
  const position = props ? props : {vertical:'bottom',horizontal:"center"}
  const [toastData, setToastData] = useState({
    open: false,
    message: "",
    severity: "info" as 'info' | 'error' | 'warning' | 'success',
  });

  const handleOpenToast = (message: string, severity: 'success' | 'error' | 'warning' | 'info' = 'info') => {
    setToastData({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseToast = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastData({
      ...toastData,
      open: false,
    });
  };

  const ToastMessage = ({
    autoHideDuration = 4000,
  }:{
    open?:any,
    message?:string,
    autoHideDuration?:number
  }) => {
    return (
      <Snackbar
        open={toastData.open}
        autoHideDuration={autoHideDuration}
        onClose={handleCloseToast}
        anchorOrigin={position}
      >
         <Alert 
          onClose={handleCloseToast} 
          severity={toastData.severity} 
          sx={{
            width: '100%',
            py:0,
            backgroundColor: variantColors[toastData.severity],
            display: 'flex',  
            alignItems: 'center',  
            justifyContent: 'center',  
            }}
         >
          {toastData.message}
        </Alert>
      </Snackbar>
    )
  };

  return {
    toastData,
    handleOpenToast,
    handleCloseToast,
    ToastMessage,
  };
};

export default useToast;