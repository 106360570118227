import ApiClient from "../ApiClient";

class SearchApi {
  private client = ApiClient.getInstance();
  private token: string | null = null;

  constructor() {
    this.setToken();
    this.addInterceptor();
  }
  private setToken() {
    const strToken =
      localStorage.getItem("creatorAccessToken") ||
      localStorage.getItem("accessToken");

    if (strToken) {
      this.token = JSON.parse(strToken);
    }
  }
  private addInterceptor() {
    this.client.interceptors.request.use(
      (config) => {
        if (this.token) {
          config.headers["Authorization"] = `Bearer ${this.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  public async search(query: string, page: number) {
    this.addInterceptor();
    try {
      const response = await this.client.get(
        `creator?name=${query}&page=${page}&pageSize=10`
      );
      return response.data;
    } catch (error) {
      console.error("Failed to search:", error);
      throw error;
    }
  }
}

export default new SearchApi();
