import React from "react";
import { PageTagComponent } from "../Common/PageTagComponent";
import { Box, Typography, Button, styled } from "@mui/material";
import { useModal } from "../../context/ModalContext";
import DeactivateAccountModal from "../../modals/DeactivateAccount/DeactivateAccountModal";
import { typography, colors } from "../../utils/styles";
import DeleteAccountModal from "../../modals/DeactivateAccount/DeleteAccountModal";

const Container = styled(Box)`
  width: 100%;
  overflow: hidden;
  position: relative;
`;
const Content = styled(Box)`
  padding-left: 16px;
`;
const Header = styled(Box)``;
const ItemContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 16px 0px 0px 0px;
  align-items: flex-start;
  border-bottom: 1px solid ${colors.gray[20]};
`;
const ItemText = styled(Typography)`
  color: ${colors.black[200]};
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const IconContainer = styled(Box)`
  padding-right: 16px;
`;
const TextContainer = styled(Box)`
  display: flex;
  padding: 0px 16px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;
const TextItems = styled("ul")``;
const TextItem = styled("li")`
  margin-left: 1.5rem;
  color: ${colors.black[200]};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const DeactivateButton = styled(Button)`
  display: flex;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.violet[100]};
  background: ${colors.violet[100]};
  color: ${colors.white};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const DeactivateAccount = () => {
  const { requestOpenModal, requestCloseModal } = useModal();
  const handleOnClickDeactivateAccount = () => {
    requestOpenModal({
      name: "Deactivate Account",
      component: (
        <DeactivateAccountModal
          requestOpenModal={requestOpenModal}
          requestCloseModal={requestCloseModal}
        />
      ),
    });
  };
  const handleOnClickDeleteAccount = () => {
    requestOpenModal({
      name: "Delete Account",
      component: (
        <DeleteAccountModal
          requestOpenModal={requestOpenModal}
          requestCloseModal={requestCloseModal}
        />
      ),
    });
  };

  return (
    <Container>
      <Header>
        <PageTagComponent>Deactivate Account</PageTagComponent>
      </Header>
      <Content>
        <ItemContainer>
          <TextContainer>
            <ItemText>Deactivating your account will:</ItemText>
            <TextItems>
              <TextItem>Disable receiving or sending payments.</TextItem>
              <TextItem>Hide your profile.</TextItem>
              <TextItem> Disable receiving or sending messages.</TextItem>
              <TextItem>
                Logging back in will automatically reactivate.
              </TextItem>
              <TextItem>
                All of your expiring subscriptions will automatically cancel.
              </TextItem>
            </TextItems>
          </TextContainer>
          <IconContainer>
            <DeactivateButton
              variant="contained"
              onClick={handleOnClickDeactivateAccount}
            >
              Deactivate
            </DeactivateButton>
          </IconContainer>
        </ItemContainer>

        <ItemContainer onClick={handleOnClickDeactivateAccount}>
          <TextContainer>
            <ItemText>Deleting your account will:</ItemText>
            <TextItems>
              <TextItem>Deactivate your account.</TextItem>
              <TextItem>
                Remove all personal information within 30 days.
              </TextItem>
              <TextItem> Disable receiving or sending messages.</TextItem>
              <TextItem>
                Changes are permanent (logging back in will not reactivate)
              </TextItem>
            </TextItems>
          </TextContainer>
          <IconContainer>
            <DeactivateButton
              variant="contained"
              onClick={handleOnClickDeleteAccount}
            >
              Delete
            </DeactivateButton>
          </IconContainer>
        </ItemContainer>
      </Content>
    </Container>
  );
};

export default DeactivateAccount;
