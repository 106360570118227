import React, { createContext, useState, useContext, ReactNode } from "react";
import CustomSnackbar from "../components/Common/Snackbar";


type severityType = "info" | "success" | "warning" | "error";

interface SnackbarContextProps {
  openSnackBar: (message: string, severity: severityType) => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(
  undefined
);

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<severityType>("info");

  const openSnackBar = (message: string, severity: severityType) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackBar }}>
      {children}
      <CustomSnackbar
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        severity={snackbarSeverity}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
    const context = useContext(SnackbarContext);
    if (!context) {
      throw new Error("useSnackbar must be used within a SnackbarProvider");
    }
    return context;
  };
