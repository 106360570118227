// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { AddCoverPhoto } from './AddCoverPhoto';
import { AddFreePhotos } from './AddFreePhotos';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import VideoCropper from './VideoCropper';
import { set_Video_Post_View, set_No_Of_Videos } from '../../redux/videoUploadSlice';
import { AddPostDescription } from './PostDescription';

const VideoInput = () => {
  const dispatch = useDispatch();
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [croppedVideos, setCroppedVideos] = useState([]);

  const currentView = useSelector((state: RootState) => state.videoPost.view);

  const onCropComplete = (croppedImages) => {
    setCroppedImages(croppedImages);
  };
  
  const component = {
    NONE:null,
    CAROUSEL_VIEW:<VideoCropper />,
    POST_DETAILS:<AddPostDescription />
  }

  const onSelectFiles = (event) => {
    const totalVideos = event.target.files.length;
    // no of selected pics
    dispatch(set_No_Of_Videos(totalVideos));
    if (event.target.files && totalVideos > 0) {
      const fileArray = Array.from(event.target.files).map(file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
          reader.readAsDataURL(file);
        });
      });

      Promise.all(fileArray)
        .then(results => {
          setSelectedImages(results)
          dispatch(setView('CAROUSEL_VIEW'))
          dispatch(set_Original_Selected_Images(results))
         
        })
        .catch(error => console.error('Error reading files: ', error));
    }
  };

  return (
    <div>
      {component[currentView]}
    </div>
  );
};

export default VideoInput;
 