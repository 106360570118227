import React from 'react'
import CreatorSetAvailability from '../../components/Creator/CreatorBookings/CreatorSetAvailability'


const SetAvailability = () => {
  return (
        <CreatorSetAvailability/>
  )
}

export default SetAvailability