import React, { useEffect, useState } from "react";
import { Box, Typography, Tab, Tabs, styled } from "@mui/material";
import { colors, typography } from "../../../utils/styles";
import CreatorServices from "./CreatorServicesTab";
import CreatorPostsTab from "./CreatorPostsTab";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import creatorApi from "../../../api/creator/creatorApi";

import {
  setLoading,
  setError,
  setSubscriptions,
} from "../../../redux/creatorSubscriptionSlice";
import { SubscriptionType } from "../../../types";
import { setOnDemandRate } from "../../../redux/creatorOnDemand";
import creatorProfileServiceApi from "../../../api/creator/creatorProfileServiceApi";
import { setPostsOfCreator } from "../../../redux/appCreatorSlice";

const initialState: SubscriptionType = {
  id: "",
  amountInYC: 0,
  audioCall: false,
  videoCall: false,
  directMessage: false,
  audioCallMinutes: 0,
  videoCallMinutes: 0,
  directMessageMinutes: 0,
  creatorId: "",
  version: 1,
  discount: null,
  isRecent: false,
  isDeleted: false,
  deletedAt: null,
  createdAt: "",
  updatedAt: "",
};
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  position: relative;
`;

const TabContainer = styled(Box)`
  border-bottom: 2px solid ${colors.gray[300]};
  padding: 0px 8px;
`;

const TabsItems = styled(Tabs)({
  "& .MuiTabs-indicator": {
    height: "8px",
    borderRadius: "12px",
    bottom: "-5px",
    backgroundColor: colors.brand_primary, // Assuming you want to match the indicator color
  },
});

const TabItem = styled(Tab)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
`;
const TabMainContainer = styled(Box)`
  display: flex;
  position: sticky;
  top: 0;
  align-items: flex-start;
  align-self: stretch;
  background: ${colors.white};
  z-index: 12;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
`;
const LabelContainer = styled(Box)``;
const LabelText = styled(Typography)`
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bolder};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;

const ContentContainer = styled(Box)`
  width: 100%;
`;

const CreatorPostTabContainer=styled(Box)`
  display: flex;
  width: 100%;
`

const contentTabs = {
  YOUR_SERVICE: "Your Service",
  YOUR_POST: "Your Posts",
};

interface CreatorProfileTabsProps {
  profileId: string;
}

const CreatorProfileTabs: React.FC<CreatorProfileTabsProps> = ({
  profileId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [creatorId, setCreatorId] = useState<string>();
  const [value, setValue] = useState(contentTabs.YOUR_SERVICE);
  const [activeTab, setActiveTab] = useState(contentTabs.YOUR_SERVICE);
  const { subscriptions} = useSelector(
    (state: RootState) => state.creatorSubscription
  );
  const { onDemandRate } = useSelector(
    (state: RootState) => state.creatorOnDemandRate
  );
  const [subscriptionPlanData, setSubscriptionPlanData] =
    useState<SubscriptionType>(initialState);
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    setValue(newValue);
    setTimeout(() => {
      setActiveTab(newValue);
    }, 300);
  };

  const fetchSubscriptions = async (creatorId: string) => {
    dispatch(setLoading(true));
    try {
      const response = await creatorProfileServiceApi.getCreatorSubscriptions(creatorId);
      dispatch(setSubscriptions(response.data.data.subscription));
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const fetchCreatorOnDemandRate = async (creatorId: string) => {
    dispatch(setLoading(true));
    try {
      const response = await creatorProfileServiceApi.getOnDemandRate(creatorId);
      dispatch(setOnDemandRate(response.data.data.onDemandRate));
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const fetchCreatorPosts = async (creatorId: string) => {
    dispatch(setLoading(true));
    try {
      const response = await creatorProfileServiceApi.getAllPostsOfACreator(
        creatorId,
        "1",
        "30"
      );
      dispatch(setPostsOfCreator(response.data.data.data));
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (profileId) {
      setCreatorId(profileId);
    }
  }, [profileId]);
  useEffect(() => {
    if (creatorId) {
      fetchSubscriptions(creatorId);
      fetchCreatorOnDemandRate(creatorId);
      fetchCreatorPosts(creatorId);
    }
  }, [dispatch, creatorId]);
  useEffect(() => {
    if (
      subscriptions &&
      !Array.isArray(subscriptions) &&
      (subscriptions as SubscriptionType)
    ) {
      const subsResponse = subscriptions as SubscriptionType;
      const subscriptionData = subsResponse;
      const newData: SubscriptionType = {
        ...initialState,
        id: subscriptionData?.id ?? "",
        amountInYC: subscriptionData?.amountInYC ?? 0,
        audioCall: subscriptionData?.audioCall ?? false,
        videoCall: subscriptionData?.videoCall ?? false,
        directMessage: subscriptionData?.directMessage ?? false,
        audioCallMinutes: subscriptionData?.audioCallMinutes ?? 0,
        videoCallMinutes: subscriptionData?.videoCallMinutes ?? 0,
        directMessageMinutes: subscriptionData?.directMessageMinutes ?? 0,
      };

      setSubscriptionPlanData(newData);
    }
  }, [subscriptions]);
  const ServicesLabel = () => {
    return (
      <LabelContainer>
        <LabelText
          variant="body2"
          color="textSecondary"
          sx={{
            color: value === contentTabs.YOUR_SERVICE ? colors.violet[100] : "",
          }}
        >
          {contentTabs.YOUR_SERVICE}
        </LabelText>
      </LabelContainer>
    );
  };

  const PostsLabel = () => {
    return (
      <LabelContainer>
        <LabelText
          variant="body2"
          color="textSecondary"
          sx={{
            color: value === contentTabs.YOUR_POST ? colors.violet[100] : "",
          }}
        >
          {contentTabs.YOUR_POST}
        </LabelText>
      </LabelContainer>
    );
  };

  return (
    <Container>
      <TabMainContainer>
        <TabContainer sx={{ width: "100%" }}>
          <TabsItems
            value={value}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
          >
            <TabItem
              value={contentTabs.YOUR_SERVICE}
              label={<ServicesLabel />}
            />
            <TabItem value={contentTabs.YOUR_POST} label={<PostsLabel />} />
          </TabsItems>
        </TabContainer>
      </TabMainContainer>
      <ContentContainer>
        {activeTab === contentTabs.YOUR_SERVICE && (
          <CreatorServices
            subscriptionPlanData={subscriptionPlanData}
            onDemandRate={onDemandRate}
          />
        )}

        {activeTab === contentTabs.YOUR_POST && 
        <CreatorPostTabContainer>
          <CreatorPostsTab />
        </CreatorPostTabContainer>
        }
      </ContentContainer>
    </Container>
  );
};

export default CreatorProfileTabs;
