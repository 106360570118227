import {userAxiosInstance} from './axiosInstance';
import axios, { AxiosInstance, AxiosError, InternalAxiosRequestConfig, AxiosHeaders } from 'axios';
let baseURL = 'https://api.dev.yarra.social/api/v1/'
class ApiClient {
    private static instance: AxiosInstance;
    private static additionalHeaders: AxiosHeaders = new AxiosHeaders();

    private constructor() {}

    public static getInstance(): AxiosInstance {
        if (!ApiClient.instance) {
            ApiClient.instance = axios.create({
                baseURL,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            ApiClient.instance.interceptors.request.use(
                (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
                    // Merge default headers with additional headers
                    const headers = new AxiosHeaders(config.headers);
                    Object.entries(ApiClient.additionalHeaders.toJSON()).forEach(([key, value]) => {
                        headers.set(key, value as string);
                    });
                    config.headers = headers;
                    return config;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );

            ApiClient.instance.interceptors.response.use(
                response => response,
                (error: AxiosError) => {
                    return Promise.reject(ApiClient.handleError(error));
                }
            );
        }
        return ApiClient.instance;
    }

    private static handleError(error: AxiosError) {
        if (error.response) {
            // Server responded with a status other than 200 range
            console.error('Error response:', error.response.data);
            return {
                status: error.response.status,
                 message: (error.response.data as { message?: string }).message || 'An error occurred'
            };
        } else if (error.request) {
            // Request was made but no response received
            console.error('Error request:', error.request);
            return {
                status: 0,
                message: 'No response received from server'
            };
        } else {
            // Something happened in setting up the request
            console.error('Error message:', error.message);
            return {
                status: 0,
                message: error.message
            };
        }
    }

    public static setHeaders(headers: Record<string, string>) {
        Object.entries(headers).forEach(([key, value]) => {
            ApiClient.additionalHeaders.set(key, value);
        });
    }

    public static removeHeader(headerKey: string) {
        ApiClient.additionalHeaders.delete(headerKey);
    }

    public static clearHeaders() {
        ApiClient.additionalHeaders = new AxiosHeaders();
    }
}

export default ApiClient;



export class ApiService {
  protected axios : AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axios = axiosInstance;
  }
}

