import React, { useEffect, useState, useRef } from 'react';
import { Avatar, Box, Typography, Button, styled, TextField, InputAdornment, Toolbar } from '@mui/material';
import { AudioCallIcon, TextMessageIcon, VideoCallCameraIcon, VideoCallIcon } from '../../assets/SvgIcons';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import socketService from '../../services/socketService';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import user from '../../api/messaging/usermsgService';
import { colors } from '../../utils/styles';
import { PageTagComponentWithAvatar } from './MessageHeaderBar';
import usermsgService from '../../api/messaging/usermsgService';
import creatormsgService from '../../api/messaging/creatormsgService';

// Styled Components
const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 100dvh;
  position: relative;
`;

const MessageHeader = styled(Box)`
  width: 100%;
  position: fixed;
  top: 0;
`;

const HeaderContent = styled(Box)`
display: flex;
align-items: center;
gap: 8px;
margin-left: 56px;
`;
const NameContainer = styled(Box)`
  flex:1;
  display: flex;
  flex-direction: column;
  gap: 4px;

`;

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 0 16px;
  overflow-y: auto;
  flex-grow: 1;
`;
const NameText = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Primary, #212121);
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;`
;

const MessageBox = styled(Box)<{ isMyMessage: boolean }>`
  display: flex;
  max-width: 280px;
  padding: 12px 8px;
  flex-direction: column;
  align-items: ${({ isMyMessage }) => (isMyMessage ? 'flex-end' : 'flex-start')};
  border-radius: ${({ isMyMessage }) => (isMyMessage ? '16px 16px 0 16px' : '0 16px 16px 16px')};
  border: 1px solid var(--Border-Strong, rgba(33, 33, 33, 0.20));
  background: ${({ isMyMessage }) => (isMyMessage ? colors.purple[50] : '#FFF')};
  gap: 8px;
  align-self: ${({ isMyMessage }) => (isMyMessage ? 'flex-end' : 'flex-start')};
`;

const MessageText = styled(Typography)`
  color: var(--Text-Primary, #212121);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 135%;
`;

const TimeText = styled(Typography)`
  color: var(--Text-Secondary, #616161);
  font-family: Poppins;
  font-size: 8px;
  text-align: right;
  line-height: 135%;
`;

// Main Component
const ReadMessage = () => {
  const { toUser } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  
  const userId = useSelector((state: RootState) => state.user.id);
  const creatorId = useSelector((state: RootState) => state.creatorProfile.profile.id);
  const id = userId || creatorId;
  
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState<any[]>([]);
  const [nextCursor, setNextCursor] = useState('')
  const chatEndRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const [loading, setLoading] = useState<any>('init');
  const cursorRef = useRef(''); 
  const scrollThreshold = 10;

  const loadMoreChats = async(cursor:string) =>{
    setLoading(true)
    location.pathname.includes('user')?
    usermsgService.fetchChatHistory(toUser ?? "",cursorRef.current).then((data) => {
        const actualData = data.data.data.data;
        setNextCursor(data.data.data.pagination.nextCursor);
        cursorRef.current = data.data.data.pagination.nextCursor
        console.log('updated cursor', data.data.data.pagination.nextCursor);
        
        const processedData = actualData.reverse().map((dayData: any) => {
          const date = Object.keys(dayData)[0];
          const messages = dayData[date].reverse().map((chat: any) => ({
            message: chat.message,
            timeStamp: chat.timeStamp,
            isMyMessage: id === chat.fromUser
          }));
          return { [date]: messages };
        });
        setChatHistory((prev) => {
            const updatedHistory = [...prev];
      
            processedData.forEach((newDayData:any) => {
              const newDate = Object.keys(newDayData)[0];
              const newMessages = newDayData[newDate];
      
              const existingDateIndex = updatedHistory.findIndex(
                (dayData) => Object.keys(dayData)[0] === newDate
              );
      
              if (existingDateIndex !== -1) {
                // If the date already exists, merge the messages
                updatedHistory[existingDateIndex][newDate] = [
                  ...newMessages,
                  ...updatedHistory[existingDateIndex][newDate],
                ];
              } else {
                // If the date doesn't exist, add a new entry at the beginning
                updatedHistory.unshift({ [newDate]: newMessages });
              }
            });
      
            return updatedHistory;
          });
          setLoading(false);
      }):
      creatormsgService.fetchChatHistory(toUser ?? "",cursorRef.current).then((data) => {
        const actualData = data.data.data.data;
        setNextCursor(data.data.data.pagination.nextCursor);
        cursorRef.current = data.data.data.pagination.nextCursor
        console.log('updated cursor', data.data.data.pagination.nextCursor);
        
        const processedData = actualData.reverse().map((dayData: any) => {
          const date = Object.keys(dayData)[0];
          const messages = dayData[date].reverse().map((chat: any) => ({
            message: chat.message,
            timeStamp: chat.timeStamp,
            isMyMessage: id === chat.fromUser
          }));
          return { [date]: messages };
        });
        setChatHistory((prev) => {
            const updatedHistory = [...prev];
      
            processedData.forEach((newDayData:any) => {
              const newDate = Object.keys(newDayData)[0];
              const newMessages = newDayData[newDate];
      
              const existingDateIndex = updatedHistory.findIndex(
                (dayData) => Object.keys(dayData)[0] === newDate
              );
      
              if (existingDateIndex !== -1) {
                // If the date already exists, merge the messages
                updatedHistory[existingDateIndex][newDate] = [
                  ...newMessages,
                  ...updatedHistory[existingDateIndex][newDate],
                ];
              } else {
                // If the date doesn't exist, add a new entry at the beginning
                updatedHistory.unshift({ [newDate]: newMessages });
              }
            });
      
            return updatedHistory;
          });
          setLoading(false);
      });
  }

  const handleScroll = () => {
    if (containerRef.current) {
        const { scrollTop } = containerRef.current;
    //    console.log(scrollTop , scrollThreshold)
        if (scrollTop < scrollThreshold) {
            console.log('load more')
            loadMoreChats(nextCursor); // Load more when scrolled near the top
            // alert('will scroll')
        }
    }
};
useEffect(() => {
    const container = containerRef.current;
    if (container) {
        setTimeout(()=>{
            console.log('added listener')
            container.addEventListener('scroll', handleScroll);
        },1000)
    }

    return () => {
        if (container) {
            container.removeEventListener('scroll', handleScroll);
        }
    };
}, []);

// initializing socket connection
  useEffect(() => {
    socketService.connect({ userId: id });

    socketService.onMessage('receive_message', (data: any) => {
      const newMessage = {
        message: data,
        isMyMessage: false,
        timeStamp: new Date()
      };
      updateChatHistory(newMessage);
    });

    return () => {
      socketService.disconnectWithPayload({ userId: id });
    };
  }, [id]);

//   fetching initial chat history 
  useEffect(() => {
    if (toUser) {
      location.pathname.includes('user')?
      usermsgService.fetchChatHistory(toUser,nextCursor).then((data) => {
        const actualData = data.data.data.data;
        const cursor = data.data.data.pagination.nextCursor;
        setNextCursor(cursor);
        cursorRef.current = cursor;
        console.log("setting next cursor", cursor);
        
        const processedData = actualData.reverse().map((dayData: any) => {
          const date = Object.keys(dayData)[0];
          const messages = dayData[date].reverse().map((chat: any) => ({
            message: chat.message,
            timeStamp: chat.timeStamp,
            isMyMessage: id === chat.fromUser
          }));
          return { [date]: messages };
        });
        setChatHistory(processedData);
      }) :
      creatormsgService.fetchChatHistory(toUser,nextCursor).then((data) => {
        const actualData = data.data.data.data;
        const cursor = data.data.data.pagination.nextCursor;
        setNextCursor(cursor);
        cursorRef.current = cursor;
        console.log("setting next cursor", cursor);
        
        const processedData = actualData.reverse().map((dayData: any) => {
          const date = Object.keys(dayData)[0];
          const messages = dayData[date].reverse().map((chat: any) => ({
            message: chat.message,
            timeStamp: chat.timeStamp,
            isMyMessage: id === chat.fromUser
          }));
          return { [date]: messages };
        });
        setChatHistory(processedData);
      });
    }
  }, [toUser, id]);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);


  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const updateChatHistory = (newMessage: any) => {
    const formattedDate = moment(newMessage.timeStamp).format('YYYY-MM-DD');
    setChatHistory((prev: any) => {
      const dateExists = prev.find((dayData: any) => Object.keys(dayData)[0] === formattedDate);
      if (dateExists) {
        return prev.map((dayData: any) => {
          const date = Object.keys(dayData)[0];
          if (date === formattedDate) {
            return { [date]: [...dayData[date], newMessage] };
          }
          return dayData;
        });
      } else {
        return [...prev, { [formattedDate]: [newMessage] }];
      }
    });
  };

  const sendMessage = () => {
    if (message.trim()) {
      const messagePayload = { message: message.trim(), fromUser: id, toUser };
      socketService.emitMessage('send_message', messagePayload);
      const newMessage = {
        message: messagePayload.message,
        isMyMessage: true,
        timeStamp: new Date()
      };
      updateChatHistory(newMessage);
      setMessage('');
      scrollToBottom();
    }
  };

  const handleJoinCall = () => {
    navigate('/join-audio-call');
  };

  return (
    <Container>
      <MessageHeader>
      <PageTagComponentWithAvatar>
            <HeaderContent>
                <Avatar src={location.state.chatData.profilePhotoUrl} sx={{ width: 32, height: 32 }} />
                <NameContainer>
                    <NameText>{location.state.chatData.displayName}</NameText>
                </NameContainer>
            </HeaderContent>
        </PageTagComponentWithAvatar>
      </MessageHeader>
      <Toolbar/>
      {loading && <p>loading...</p>}
      {/* <div ref={containerRef} style={{width:"100%"}}> */}
      <ContentContainer ref={containerRef}>
        {chatHistory.map((dayData, index) => {
          const date = Object.keys(dayData)[0];
          const messages = dayData[date];
          return (
            <React.Fragment key={index}>
              <Typography>{date}</Typography>
              {messages.map((msg: any, idx: number) => (
                <MessageBox key={idx} isMyMessage={msg.isMyMessage}>
                  <MessageText>{msg.message}</MessageText>
                  <TimeText>{moment(msg.timeStamp).format('HH:mm')}</TimeText>
                </MessageBox>
              ))}
            </React.Fragment>
          );
        })}
        <div ref={chatEndRef} />
      </ContentContainer>
      {/* </div> */}
      <Box mt='auto' width="100%" alignSelf='end' sx={{ background: '#fff' }}>
            <TextField
            variant="outlined"
                sx={{
                    '& .MuiInputBase-root': {  // Target the InputBase root
                        border: '1px solid #c6c6c6', // Set the border style
                        borderRadius: 0,     // Optional: Adjust border radius
                        // Other styles as needed
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                        borderTop:'1px solid #dedede !important',
                        borderRadius:0,
                        // Removes default outline for outlined variant
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid green !important', // Change border color when focused
                      },
                }}
                multiline
                fullWidth
                placeholder="Type your message..."
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <img
                                onClick={sendMessage}
                                width={40} height={40} src="/Icons/send_message_icon.svg" />
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    </Container>
  );
};

export default ReadMessage;
