import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvailabilityType, CheckBookingType, PendingBookingType, SlotsDataType, TimeSlotType } from '../types';
import dayjs from "dayjs";

interface BookingState {
    weeklyAvailability: SlotsDataType[];
    confirmedBookings:CheckBookingType[];
    pendingBookings:PendingBookingType[];
    historyBookings:CheckBookingType[];
    unavailability: boolean;
  }
  
  const initialSlotsData: BookingState= {
    weeklyAvailability:[
      {
      day: 0,
      checked: false,
      slots: [],
    },
    {
      day: 1,
      checked: false,
      slots: [],
    },
    {
      day: 2,
      checked: false,
      slots: [],
    },
    {
      day: 3,
      checked: false,
      slots: [],
    },
    {
      day: 4,
      checked: false,
      slots: [],
    },
    {
      day: 5,
      checked: false,
      slots: [],
    },
    {
      day: 6,
      checked: false,
      slots: [],
    },
  ],
  confirmedBookings:[],
  pendingBookings:[],
  historyBookings:[],
  unavailability: false
  }


const bookingSlice = createSlice({
  name: 'bookings',
  initialState: initialSlotsData,
  reducers: {
    setWeeklyAvailability(state, action) {
      if (action.payload.length) {
        state.weeklyAvailability = state.weeklyAvailability.map((dayItem) => {
          const matchedDay = action.payload.find((payloadItem: SlotsDataType) => payloadItem.day === dayItem.day);
          if (matchedDay) {
            const sortedSlots = matchedDay.slots.sort((a:TimeSlotType, b:TimeSlotType) => {
              const timeA = dayjs(`1970-01-01 ${a.from}`, 'YYYY-MM-DD hh:mm A');
              const timeB = dayjs(`1970-01-01 ${b.from}`, 'YYYY-MM-DD hh:mm A');
              return timeA.isBefore(timeB) ? -1 : 1;
            });
            return {
              ...dayItem,
              checked: true,
              slots: sortedSlots,
            };
          } else {
            return {
              ...dayItem,
              checked: false,
              slots: [],
            };
          }
        });
      }
    },
    updateWeeklyAvailability(state, action) {
        state.weeklyAvailability= action.payload
    },
    setUnAvailability(state, action){
        state.unavailability=action.payload
    },
    setConfirmedBookings(state, action){
      state.confirmedBookings= action.payload
    },
    setPendingBookings(state, action){
      state.pendingBookings= action.payload
    },
    setHistoryBookings(state, action){
      state.historyBookings= action.payload
    },
    acceptRescheduling(state, action) {
      let itemIndex = state.pendingBookings.findIndex(item => item.bookingId === action.payload);
      if (itemIndex !== -1) {
        let item = state.pendingBookings[itemIndex];
        state.pendingBookings.splice(itemIndex, 1);
        state.confirmedBookings.push(item);
      }
    },
    rejectRescheduling(state, action) {
      let itemIndex = state.pendingBookings.findIndex(item => item.bookingId === action.payload);
      if (itemIndex !== -1) {
        let item = state.pendingBookings[itemIndex];
        state.pendingBookings.splice(itemIndex, 1);
        state.historyBookings.push(item);
      }
    },
    cancelBooking(state, action){
        let itemIndex = state.confirmedBookings.findIndex(item => item.bookingId === action.payload);
        if (itemIndex !== -1) {
          let item = state.confirmedBookings[itemIndex];
          state.confirmedBookings.splice(itemIndex, 1);
          state.historyBookings.push(item);
        }
      },

    
  },
});



export const { setWeeklyAvailability, updateWeeklyAvailability, setUnAvailability, setConfirmedBookings, setPendingBookings, setHistoryBookings, acceptRescheduling, rejectRescheduling, cancelBooking} = bookingSlice.actions;

export const bookingReducer = bookingSlice.reducer;
