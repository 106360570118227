export function formatLongDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  export const formatDate = (date: string): string => {
    const formatDate = new Date(date);
    const formattedDate = formatDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    });
    return formattedDate;
  };

  export const formatRegularDate = (date: string): string => {
    const formatDate = new Date(date);
    const formattedDate = formatDate.toLocaleDateString("default", {
      day: "2-digit",
      month: "short",
      year: "numeric"
    });
    return formattedDate;
  };