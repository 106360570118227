import { createSlice } from '@reduxjs/toolkit';
import { SubscriptionType } from '../types';


interface CreatorSubscriptionState {
  subscriptions: SubscriptionType;
  loading: boolean;
  error: any;
}

const initialSubscriptionState: CreatorSubscriptionState = {
  subscriptions: {
    id: '',
    amountInYC: 0,
    audioCall: false,
    videoCall: false,
    directMessage: false,
    audioCallMinutes: 0,
    videoCallMinutes: 0,
    directMessageMinutes: 0,
    creatorId: '',
    version: 0,
    discount: null,
    isRecent: false,
    isDeleted: false,
    deletedAt: null,
    createdAt: '',
    updatedAt: '',
  },
  loading: false,
  error: null,
};

const creatorSubscriptionSlice = createSlice({
  name: 'creatorSubscription',
  initialState: initialSubscriptionState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setSubscriptions(state, action) {
      if(action.payload.id){
        state.subscriptions = action.payload;
      }
    },
    updateSubscription(state, action) {
      state.subscriptions = action.payload
    },
    removeSubscription(state) {
      state.subscriptions = initialSubscriptionState.subscriptions
    },
  },
});


export const {
  setLoading,
  setError,
  setSubscriptions,
  updateSubscription,
  removeSubscription,
} = creatorSubscriptionSlice.actions;

export default creatorSubscriptionSlice.reducer;
