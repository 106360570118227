import { Box } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';
import { Navbar } from '../components/Navbar';
import { BottomNav } from '../components/BottomNavigation';
import CreatorsPosts from '../pages/ProfileView/Creator/CreatorsPosts/CreatorsPosts';
import AppModals from '../components/AppModals';
import { useModal } from '../context/ModalContext';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import userApi from '../api/user/userApi';
import { useEffect } from 'react';
import { setUser } from '../redux/appUserSlice';
import { setCreator } from '../redux/appCreatorSlice';
import creatorProfileServiceApi from '../api/creator/creatorProfileServiceApi';


const UserLayout:React.FC= ({}) => {
  const dispatch = useDispatch();
  let accessToken = '';
  const { activeAppModals,  requestCloseModal } = useModal();
  const strAccessToken = localStorage.getItem('accessToken');
  const userId = useSelector((state: RootState) => state.user.id);
  if(strAccessToken) accessToken = JSON.parse(strAccessToken);
  const updateUserProfile = () =>{
    if(!userId){
      userApi.getUserProfile().then(res=>{
        dispatch(setUser(res.data.user));
      }).catch(err=>alert('something went wrong'));
    }
    else{
      console.log(userId+'27')
    }
  }
  useEffect(()=>{
    updateUserProfile()
  },[])
  if(!accessToken) return <Navigate to="/"/>
  return (
    <Box
      m="auto"
      maxWidth="sm"
      height="100dvh"
      display="flex"
      flexDirection="column"
      overflow="hidden"
    >
      <Navbar userRole='USER'/>

      {activeAppModals.length>=1 &&  
      activeAppModals.map((modal, index) => (
        modal.component && (
        <AppModals
          key={index}
          name={modal.name}
          component={modal.component}
          close={requestCloseModal}
        >
          {modal.component}
        </AppModals>
        )
      ))}
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        position="relative"
        minHeight="92dvh"
        sx={{
            overflowY:"auto",
            overflowX: "hidden"
        }}
        
      >
        <Box
          sx={{
            backgroundColor: '#fff',
            flexGrow: 1,
            overflow: 'auto'
          }}
        >
          <Outlet/>
        </Box>
        <Box sx={{ position: 'sticky', bottom: 0, width: '100%' }}>
          <BottomNav userRole='USER'/>
        </Box>
      </Box>
    </Box>
  );
};

const CreatorLayout:React.FC= ({}) => {
  const dispatch = useDispatch<AppDispatch>();
  const strAccessToken = localStorage.getItem('creatorAccessToken');
  let accessToken = '';
  const userId = useSelector((state: RootState) => state.creatorProfile.profile?.id ?? "");
  if(strAccessToken) accessToken = JSON.parse(strAccessToken);
  const { activeAppModals,  requestCloseModal } = useModal();
  const updateCreatorProfile = () =>{
    if(!userId){
      creatorProfileServiceApi.getCreatorProfile().then(response=>{
        dispatch(setCreator(response.data.data.creator));
      }).catch(err=>alert('something went wrong'));
    }
    else{
    }
  }
  useEffect(()=>{
    updateCreatorProfile()
  },[])

  if(!accessToken) return <Navigate to="/"/>

  return (
    <Box
      m="auto"
      maxWidth="sm"
      height="100dvh"
      display="flex"
      flexDirection="column"
      overflow="hidden"
    >
      <Navbar userRole='CREATOR'/>

      {activeAppModals.length>=1 &&  
      activeAppModals.map((modal, index) => (
        modal.component && (
        <AppModals
          key={index}
          name={modal.name}
          component={modal.component}
          close={requestCloseModal}
        >
          {modal.component}
        </AppModals>
        )
      ))}
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        position="relative"
        minHeight="92dvh"
        sx={{
          overflowY: "auto",
          overflowX: "hidden"
        }}

      >
        <Box
          sx={{
            backgroundColor: '#fff',
            flexGrow: 1,
            overflow: 'auto'
          }}
        >
          <Outlet />
          {/* <CreatorsPosts/> */}
          
        </Box>
        <Box sx={{ position: 'sticky', bottom: 0, width: '100%' }}>
          <BottomNav userRole='CREATOR'/>
        </Box>
      </Box>
    </Box>
  );
};
export {UserLayout,CreatorLayout}



