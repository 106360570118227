import React, { useCallback, useEffect, useState } from "react";
import UserFollowing from "../Following/Following";
import followingsApi from "../../../api/creator/socialConnectionsApi";
import subscriptionServiceApi from "../../../api/user/subscriptionServiceApi";

interface SubscriptionType {
  id: number;
  img: string;
  name: string;
  handle: string;
  type: string;
}

const SubscribersData = [
  {
    id: 1,
    img: "Images/avatar_image_1.jpg",
    name: "May heaven",
    handle: "@Mayheaven",
    type: "creator",
  },

  {
    id: 2,
    img: "Images/avatar_image_2.jpg",
    name: "Mishra Jee",
    handle: "@mishrajee",
    type: "user",
  },

  {
    id: 3,
    img: "Images/avatar_image_3.jpg",
    name: "Shreyas Thakur",
    handle: "@thakurshree",
    type: "user",
  },

  {
    id: 4,
    img: "Images/avatar_image_6.jpg",
    name: "Bahubali Singh",
    handle: "@bahubalisingh",
    type: "user",
  },

  {
    id: 5,
    img: "Images/avatar_image_4.jpg",
    name: "Swami Subbu",
    handle: "@subbuswami",
    type: "user",
  },

  {
    id: 6,
    img: "Images/avatar_image_5.jpg",
    name: "Mr. Virat",
    handle: "@mrvirat",
    type: "creator",
  },
  {
    id: 7,
    img: "Images/avatar_image_6.jpg",
    name: "Bahubali Singh",
    handle: "@bahubalisingh",
    type: "creator",
  },
  {
    id: 1,
    img: "Images/avatar_image_1.jpg",
    name: "May heaven",
    handle: "@Mayheaven",
    type: "creator",
  },

  {
    id: 2,
    img: "Images/avatar_image_2.jpg",
    name: "Mishra Jee",
    handle: "@mishrajee",
    type: "creator",
  },

  {
    id: 3,
    img: "Images/avatar_image_3.jpg",
    name: "Shreyas Thakur",
    handle: "@thakurshree",
    type: "creator",
  },

  {
    id: 4,
    img: "Images/avatar_image_6.jpg",
    name: "Bahubali Singh",
    handle: "@bahubalisingh",
    type: "user",
  },

  {
    id: 5,
    img: "Images/avatar_image_4.jpg",
    name: "Swami Subbu",
    handle: "@subbuswami",
    type: "user",
  },

  {
    id: 6,
    img: "Images/avatar_image_5.jpg",
    name: "Mr. Virat",
    handle: "@mrvirat",
    type: "user",
  },
  {
    id: 7,
    img: "Images/avatar_image_6.jpg",
    name: "Bahubali Singh",
    handle: "@bahubalisingh",
    type: "creator",
  },
];

const UserSubscription: React.FC = () => {
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionType[]>(
    []
  );
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const fetchSubscriptions = useCallback(() => {
    if (loading || !hasMore) return;

    setLoading(true);

    subscriptionServiceApi
      .getSubscribedCreators(page.toString(), "10")
      .then(({ data }) => {
        const filteredData = data.data.data.map((item: any) => ({
          id: item.id,
          img: item.profilePhotoUrl,
          name: item.displayName,
          handle: item.handleName,
          type: "creator",
        }));

        if (filteredData.length === 0) {
          setHasMore(false);
        } else {
          setSubscriptionData((prevData) => {
            const newData = [...prevData, ...filteredData];
            setHasMore(data.data.pagination.totalItems > newData.length);
            return newData;
          });

          setPage((prevPage) => prevPage + 1);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setHasMore(false);
      });
  }, [page, loading, hasMore]);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return (
    <UserFollowing
      text="Subscriptions"
      data={subscriptionData}
      fetchMoredata={fetchSubscriptions}
      hasMore={hasMore}
      loading={loading}
    />
  );
};

export default UserSubscription;
