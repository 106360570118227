import { Box, Button, Typography, styled } from "@mui/material";
import React from "react";
import { RequestCloseModal, RequestOpenModal } from "../../types";


const UnfollowContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Content = styled(Box)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
`;

const BottomButtonsContainer = styled(Box)`
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const Title = styled(Typography)`
  color: var(--Text-Primary, #212121);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  letter-spacing: 0.3px;
`;

const Subtitle = styled(Typography)`
  color: var(--Text-Secodary, #616161);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.21px;
`;

const Buttons = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  color: var(--Text-Secodary, #616161);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  letter-spacing: 0.24px;
`;

const HorizontalDivider = styled(Box)`
  display: flex;
  height: 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 0.8px solid rgba(33, 33, 33, 0.05);
`;

const VerticalDivider = styled(Box)`
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 0.5px solid rgba(33, 33, 33, 0.1);
`;

const UnfollowContent = styled(Box)`
  width: 280px;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 24px 80px 0px rgba(0, 0, 0, 0.3);
`;


const SubtitleContainer=styled(Box)`
    display:flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    
`;

const SubtitleMainText=styled(Typography)`
color: var(--Text-Secodary, #616161);

/* Body/L-R */
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 18.9px */
letter-spacing: 0.21px;
`;

const SubtitlSubTextContainer=styled(Box)`

`;

const SubtitlSubTextNote=styled(Typography)`
color: var(--Text-Processing, #C15700);

/* Body/L-R */
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%;
letter-spacing: 0.21px;
`;


const SubtitlSubText=styled(Typography)`
color: var(--Text-Secodary, #616161);

/* Body/L-R */
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 18.9px */
letter-spacing: 0.21px;
`;

interface DiscardSubscriptionModalProps {
  requestOpenModal: RequestOpenModal;
  requestCloseModal: RequestCloseModal;
  onDiscardPlan:() => void;
}


// Functional component for the Discard Subscription Modal
const DiscardSubscriptionModal: React.FC<DiscardSubscriptionModalProps> = ({
  requestOpenModal,
  requestCloseModal,
  onDiscardPlan,
}) => {
  
  // Handler for the Cancel button, closes the modal without discarding
  const handleCancel = () => {
    requestCloseModal();
  };

  // Handler for the Yes button, closes the modal and triggers the discard action
  const handleConfirmDiscard = () => {
    requestCloseModal();
    onDiscardPlan();
  };


  return (
    <UnfollowContainer>
      <UnfollowContent>
        <Content>
          <Title>
          Discard subscription creation process ?
          </Title>
          <SubtitleContainer>
            <SubtitleMainText>
                    Are you sure you want to cancel this Subscription?
            </SubtitleMainText>
          
            <SubtitlSubTextContainer>
                <SubtitlSubTextNote>
                    Note:
                    </SubtitlSubTextNote>
                    
                    <SubtitlSubText>
                    All your progress would be lost. You can still come back to create a new plan later.
                    </SubtitlSubText>
            </SubtitlSubTextContainer>
          </SubtitleContainer>
        </Content>
        <HorizontalDivider />
        <BottomButtonsContainer>
          <Buttons sx={{ color: "#616161" }} onClick={handleCancel}>
            Cancel
          </Buttons>
          <VerticalDivider />
          <Buttons sx={{ color: "#B71C1C" }} onClick={handleConfirmDiscard}>
            Yes
          </Buttons>
        </BottomButtonsContainer>
      </UnfollowContent>
    </UnfollowContainer>
  );
};

export default DiscardSubscriptionModal;
