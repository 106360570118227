import { Box, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors} from "../../../../utils/styles";
import { useNavigate, useParams } from "react-router-dom";
import PostHeader from "./PostHeader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { UserFeedType } from "../../../../types";
import UserFeed from "../../../../components/Feeds/UserFeed";
import { setUserFeeds } from "../../../../redux/userFeedSlice";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  background-color: ${colors.background_primary};
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-bottom: 5rem;
`;

const CreatorPostContentContainer = styled(Box)``;

const CreatorsPosts: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();
  const { lockedPostList, freePostList, purchasedPostList } = useSelector(
    (state: RootState) => state.anotherCreator
  );
  const { userFeeds } = useSelector((state: RootState) => state.userFeeds);
  const [title, setTitle] = useState<string>("");
  const navigate = useNavigate();
  const handleOnClickGoBackButton = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (id === undefined) {
      return;
    }
    const postIdNumber = parseInt(id, 10);
    if (postIdNumber === 1) {
      setTitle("Locked Content");
      dispatch(setUserFeeds(lockedPostList));
    } else if (postIdNumber === 2) {
      setTitle("Purchased Content");
      dispatch(setUserFeeds(purchasedPostList));
    } else {
      setTitle("Free Content");
      dispatch(setUserFeeds(freePostList));
    }
  }, [id, dispatch]);

  return (
    <Container>
      <PostHeader
        title={title ? title : ""}
        handleOnClickGoBackButton={handleOnClickGoBackButton}
      />
      <CreatorPostContentContainer>
        {userFeeds &&
          userFeeds?.map((item, index) => <UserFeed key={index} post={item} />)}
      </CreatorPostContentContainer>
    </Container>
  );
};

export default CreatorsPosts;
