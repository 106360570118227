import styled from "@emotion/styled";
import { InputBase } from "@mui/material";

const DynamicStyledInput = styled(({ ...otherProps }) => (
  <InputBase {...otherProps} />
))(
  ({
    theme,
    error = false,
    width,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    height,
    display,
    padding,
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    border,
    borderTop,
    borderRight,
    borderBottom,
    borderLeft,
    color,
    backgroundColor,
    borderRadius,
    fontSize,
    fontWeight,
    fontFamily,
    lineHeight,
    letterSpacing,
    position,
    borderColor,
    labelBorder,
    labelBorderColor,
    labelPadding,
    labelGap,
    labelDisplay,
    fontStyle,
    placeholderColor,
    placeholderFontSize,
    placeholderFontWeight

  }) => ({
    'label + &': {
      marginTop: marginTop || '8px',
      padding: "0 !important",
      
    },
    '& .MuiInputBase-root': {
      height: height || "48px",
      // width: width || 100,
      
     
    },

    '& .MuiInputBase-input': {
      height: height || "48px",
      width: width || "100%",
      borderRadius:  borderRadius || 8,
      position: position || 'relative',
      border: border || '1px solid',
      // borderColor: borderColor || '',
      fontSize: fontSize || 16,
      padding: padding || '0px',
      margin: margin || 0,
      fontFamily: fontFamily || 'Poppins',
      fontWeight: fontWeight || 400,
      fontStyle: fontStyle || 'normal',
      lineHeight: lineHeight || 1.5,
      letterSpacing: letterSpacing || "0.18px",
      color: color || '#212121',
      borderColor: error ? 'red' : borderColor || '',


      '&:focus': {
        boxShadow:`${error ? 'red' : '#7262C2'}  0 0 0 1.5px`,
        borderColor: error ? 'red' : '#7262C2',
        outline: 'none',
      },

       '::placeholder': {
      color: placeholderColor || "#9E9E9E", // Default placeholder color
      opacity: 1, // Ensures the placeholder text is fully opaque
      fontSize: placeholderFontSize ? placeholderFontSize : fontSize ||  16,
      fontWeight: placeholderFontWeight ? placeholderFontWeight : fontWeight || 400,
      fontFamily: fontFamily || 'Poppins',
    },
    },

    '::placeholder': {
       color: placeholderColor || "#9E9E9E", // Default placeholder color
      opacity: 1, // Ensures the placeholder text is fully opaque
      fontSize: placeholderFontSize ? placeholderFontSize : fontSize ||  16,
      fontWeight: placeholderFontWeight ? placeholderFontWeight : fontWeight || 400,
      fontFamily: fontFamily || 'Poppins',
    },
  })
);

export default DynamicStyledInput;