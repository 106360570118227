import { set_Compressed_Images } from "../../redux/imageUploadSlice";

export const compressImage = async (file) => {
  const MAX_WIDTH = 800; 
  const MAX_HEIGHT = 800;
  const img = new Image();
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      const originalImageBase64 = event.target.result; // Get original image as base64
      img.src = originalImageBase64;
      
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        let newWidth = width;
        let newHeight = height;
        
        // Resize the image if it's larger than the max dimensions
        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
          if (width > height) {
            newWidth = MAX_WIDTH;
            newHeight = Math.floor((height / width) * MAX_WIDTH);
          } else {
            newHeight = MAX_HEIGHT;
            newWidth = Math.floor((width / height) * MAX_HEIGHT);
          }
        }

        // Create a canvas to draw the resized image
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = newWidth;
        canvas.height = newHeight;

        // Draw the image to canvas
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Compress the image by converting it back to a base64 image with reduced quality
        const compressedImageBase64 = canvas.toDataURL('image/jpeg', .8); // Adjust quality as needed (0.6 for 60% quality)

        // Resolve both original and compressed image as base64 data
        resolve({
          original: originalImageBase64, // Original image in base64
          compressed: compressedImageBase64 // Compressed image in base64
        });
      };
    };

    reader.onerror = (error) => reject(error);

    // Start reading the image file as a DataURL
    reader.readAsDataURL(file);
  });
};

export const applyFilter = () =>{
  
}

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') 
    image.src = url
  })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export default async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  const ctx = canvas.getContext('2d')

  ctx.fillStyle = '#ffffff'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  )

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file))
    }, 'image/jpeg')
  })
}
 

export const applyFilterToCanvas = async (filterType,canvasRef,imageSrc,currentIndex,images) => {
  try {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      switch (filterType) {
        case 'grayscale':
          for (let i = 0; i < data.length; i += 4) {
            const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
            data[i] = avg; data[i + 1] = avg; data[i + 2] = avg;
          }
          break;
        case 'sepia':
          for (let i = 0; i < data.length; i += 4) {
            const r = data[i], g = data[i + 1], b = data[i + 2];
            data[i] = r * 0.393 + g * 0.769 + b * 0.189;
            data[i + 1] = r * 0.349 + g * 0.686 + b * 0.168;
            data[i + 2] = r * 0.272 + g * 0.534 + b * 0.131;
          }
          break;
        case 'vintage':
          for (let i = 0; i < data.length; i += 4) {
            const r = data[i], g = data[i + 1];
            data[i] = r * 0.9 + g * 0.5; data[i + 1] = g * 0.7; data[i + 2] = data[i + 2] * 0.4;
          }
          break;
        case 'saturation':
          const saturationFactor = 1.5;
          for (let i = 0; i < data.length; i += 4) {
            const gray = 0.2989 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2];
            data[i] = gray + (data[i] - gray) * saturationFactor;
            data[i + 1] = gray + (data[i + 1] - gray) * saturationFactor;
            data[i + 2] = gray + (data[i + 2] - gray) * saturationFactor;
          }
          break;
        case 'invert':
          for (let i = 0; i < data.length; i += 4) {
            data[i] = 255 - data[i];
            data[i + 1] = 255 - data[i + 1];
            data[i + 2] = 255 - data[i + 2];
          }
          break;
      }

      ctx.putImageData(imageData, 0, 0);

      const updatedImages = [...images];
      updatedImages[currentIndex] = canvas.toDataURL();
      return updatedImages
    };
  } catch (error) {
    console.error('Error applying filter:', error);
  }
};