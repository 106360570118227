import { Box, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors, typography } from "../../../utils/styles";

const SubscriptionViewCardContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid ${colors.violet[100]};
  background: ${colors.white};
`;

const SubscriptionViewCardTitleContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ViewCardTitle = styled(Box)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const SubscriptionViewCardContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const SubscriptionViewCardContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ViewCardContentLeftContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const ViewCardContentLeftIcon = styled(Box)``;

const ViewCardContentLeftTextContainer = styled(Box)``;

const ViewCardContentLeftTitleText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const ViewCardContentLeftSubTitleText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;

const ViewCardContentRightContainer = styled(Box)``;

const SuccessText = styled(Typography)`
  color: ${colors.green[100]};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

type items = {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  isActive: boolean;
};

interface OnDemandRateSuccessCardProps {
  items: items[];
}

const OnDemandRateSuccessCard: React.FC<OnDemandRateSuccessCardProps> = ({
  items,
}) => {
  const [data, setData] = useState<items[]>([]);

  useEffect(() => {
    if (items) {
      setData(items);
    }
  }, [items]);

  return (
    <>
      {data?.length >= 1 && (
        <SubscriptionViewCardContainer>
          <SubscriptionViewCardTitleContainer>
            <ViewCardTitle>On Demand Services</ViewCardTitle>
          </SubscriptionViewCardTitleContainer>
          <SubscriptionViewCardContentContainer>
            {data?.map(
              (item) =>
                item.isActive && (
                  <SubscriptionViewCardContent>
                    <ViewCardContentLeftContainer>
                      <ViewCardContentLeftIcon>
                        {item.icon}
                      </ViewCardContentLeftIcon>

                      <ViewCardContentLeftTextContainer>
                        <ViewCardContentLeftTitleText>
                          {item.title}
                        </ViewCardContentLeftTitleText>

                        <ViewCardContentLeftSubTitleText>
                          {item.subtitle}
                        </ViewCardContentLeftSubTitleText>
                      </ViewCardContentLeftTextContainer>
                    </ViewCardContentLeftContainer>

                    <ViewCardContentRightContainer>
                      <SuccessText>Rate is set</SuccessText>
                    </ViewCardContentRightContainer>
                  </SubscriptionViewCardContent>
                )
            )}
          </SubscriptionViewCardContentContainer>
        </SubscriptionViewCardContainer>
      )}
    </>
  );
};

export default OnDemandRateSuccessCard;
