import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { colors, typography } from "../../../utils/styles";
import {
  setCurrentContentStep,
  setFiles,
} from "../../../redux/creatorContentSlice";
import { PHOTO_STEPS, VIDEO_STEPS } from "../../../utils/creatorContentTypes";
import { trimVideo } from "../../../services/trimVideo";

const Header = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentStep = useSelector(
    (state: any) => state.postContent.currentContentStep
  );
  const { makeItFree } = useSelector((state: any) => state.postContent);

  const { files, mode, setLoading } = props;

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleNavigate = async () => {
    if (mode === "photo") {
      if (currentStep === PHOTO_STEPS.PHOTO_UPLOADING) {
        if (files.length === 1) {
          const filesArr = files.map((file: any) => {
            return {
              ...file,
              isCoverPhoto: true,
            };
          });

          dispatch(setFiles(filesArr));

          return dispatch(setCurrentContentStep(PHOTO_STEPS.POST_DETAILS));
        } else return dispatch(setCurrentContentStep(PHOTO_STEPS.FREE_PHOTOS));
      }

      if (currentStep === PHOTO_STEPS.FREE_PHOTOS) {
        dispatch(setCurrentContentStep(PHOTO_STEPS.COVER_PHOTOS));
      }
      if (currentStep === PHOTO_STEPS.COVER_PHOTOS) {
        dispatch(setCurrentContentStep(PHOTO_STEPS.POST_DETAILS));
      }
      if (currentStep === PHOTO_STEPS.POST_DETAILS) {
        dispatch(setCurrentContentStep(PHOTO_STEPS.POST_AMOUNT));
      }
    }

    if (mode === "video") {
      if (currentStep === VIDEO_STEPS.VIDEO_UPLOADING) {
        setLoading(true);
        const filesArr = await Promise.all(
          files.map(async (file: any) => {
            const trimmedUrl = file.selectedFree
              ? await trimVideo(file.url, file.freeSeconds)
              : "";

            return {
              ...file,
              trimmedUrl,
            };
          })
        );

        setLoading(false);
        dispatch(setFiles(filesArr));
        dispatch(setCurrentContentStep(VIDEO_STEPS.PREVIEW_VIDEOS));
      }
      if (currentStep === VIDEO_STEPS.PREVIEW_VIDEOS) {
        dispatch(setCurrentContentStep(VIDEO_STEPS.POST_DETAILS));
      }
      if (currentStep === VIDEO_STEPS.POST_DETAILS) {
        dispatch(setCurrentContentStep(VIDEO_STEPS.POST_AMOUNT));
      }
    }
  };

  const handleBack = () => {
    if (mode === "photo") {
      if (currentStep === PHOTO_STEPS.FREE_PHOTOS) {
        dispatch(setCurrentContentStep(PHOTO_STEPS.PHOTO_UPLOADING));
      }
      if (currentStep === PHOTO_STEPS.COVER_PHOTOS) {
        dispatch(setCurrentContentStep(PHOTO_STEPS.FREE_PHOTOS));
      }
      if (currentStep === PHOTO_STEPS.POST_DETAILS) {
        if (files.length === 1) {
          dispatch(setCurrentContentStep(PHOTO_STEPS.PHOTO_UPLOADING));
        } else dispatch(setCurrentContentStep(PHOTO_STEPS.COVER_PHOTOS));
      }
      if (currentStep === PHOTO_STEPS.POST_AMOUNT) {
        dispatch(setCurrentContentStep(PHOTO_STEPS.POST_DETAILS));
      }
    }

    if (mode === "video") {
      if (currentStep === VIDEO_STEPS.VIDEO_UPLOADING) {
        dispatch(setCurrentContentStep(VIDEO_STEPS.PREVIEW_VIDEOS));
      }
      if (currentStep === VIDEO_STEPS.PREVIEW_VIDEOS) {
        dispatch(setCurrentContentStep(VIDEO_STEPS.VIDEO_UPLOADING));
      }
      if (currentStep === VIDEO_STEPS.POST_DETAILS) {
        dispatch(setCurrentContentStep(VIDEO_STEPS.PREVIEW_VIDEOS));
      }
      if (currentStep === VIDEO_STEPS.POST_AMOUNT) {
        dispatch(setCurrentContentStep(VIDEO_STEPS.POST_DETAILS));
      }
    }
  };

  const hideNextButton =
    (currentStep === PHOTO_STEPS.POST_DETAILS && makeItFree) ||
    currentStep === PHOTO_STEPS.POST_DETAILS;

  const isNewPost =
    currentStep === PHOTO_STEPS.PHOTO_UPLOADING ||
    currentStep === VIDEO_STEPS.VIDEO_UPLOADING ||
    currentStep === PHOTO_STEPS.POST_DETAILS;

  return (
    <AppBar
      position="static"
      sx={{ width: "100%", background: "#DCD7F5", boxShadow: "none" }}
    >
      <Toolbar>
        {currentStep === PHOTO_STEPS.PHOTO_UPLOADING ||
        currentStep === VIDEO_STEPS.VIDEO_UPLOADING ? (
          <IconButton
            edge="start"
            aria-label="menu"
            onClick={() => navigate("/")}
          >
            <img src="Icons/close_icon.svg" />
          </IconButton>
        ) : (
          <IconButton edge="start" aria-label="menu" onClick={handleBack}>
            <img src="/Icons/back_icon.svg" />
          </IconButton>
        )}
        <Typography
          variant="h5"
          textAlign="center"
          fontWeight={typography.font_weight["bold"]}
          color={colors.black["200"]}
          style={{ flexGrow: 1 }}
        >
          {isNewPost && "New post"}
          {currentStep === PHOTO_STEPS.FREE_PHOTOS && "Free Photos"}
          {currentStep === PHOTO_STEPS.COVER_PHOTOS && "Cover Photos"}
          {currentStep === VIDEO_STEPS.PREVIEW_VIDEOS && "Preview"}
        </Typography>
        {!hideNextButton && (
          <Typography
            variant="h5"
            textAlign="end"
            fontWeight={typography.font_weight["bold"]}
            color={colors.black["200"]}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleNavigate();
            }}
          >
            Next
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
};

export { Header };
