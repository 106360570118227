
import { ApiService } from '../ApiClient';
import {userAxiosInstance} from '../axiosInstance';
import '../interceptors/userAuthInterceptors';

class SubscriptionService extends ApiService {
  constructor() {
    super(userAxiosInstance);
  }

  payForSubscription(subscriptionDetails:object){
    return this.axios.post('payment/yc-to-yc/pay-to-creator',subscriptionDetails)
  }

 getAllSubscription(page:string, pageSize:string) {
      return this.axios.get(`subscription/user-subscription?page=${page ?? "1"}&pageSize=${pageSize ?? "10"}`);
  }
  getActiveSubscription(page:string, pageSize:string) {
    return this.axios.get(`subscription/user-subscription?page=${page ?? "1"}&pageSize=${pageSize ?? "10"}&status=ACTIVE`);
  
  }
  getHistorySubscription(page:string, pageSize:string) {
    return this.axios.get(`subscription/user-subscription?page=${page ?? "1"}&pageSize=${pageSize ?? "10"}&status=HISTORY`);

  }
  getSubscribedCreators(page:string, pageSize:string) {
    return this.axios.get(`subscription/user-subscription/user?page=${page ?? "1"}&pageSize=${pageSize ?? "10"}`);

  }


}

export default new SubscriptionService();
