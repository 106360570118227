import { ApiService } from '../ApiClient';
import {userAxiosInstance} from '../axiosInstance';
import '../interceptors/userAuthInterceptors';

class UserMessaging extends ApiService {
   
    constructor() {
      super(userAxiosInstance)
    }
    fetchChat(){
        return this.axios.get('creator-services/chat?page=1&pageSize=20')
      }
    
    fetchChatHistory(userId:string,cursor:any){
    return this.axios.get(`creator-services/message?pageSize=20&toUserId=${userId}&cursor=${cursor??null}`)
    }

  }
  export default new UserMessaging();
  