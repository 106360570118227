import React, { useState } from "react";

import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-material-ui-carousel";
import ImageFilters from "../../../pages/CreatePost/ImageFilters";
import { setFiles } from "../../../redux/creatorContentSlice";
import cloneDeep from "lodash/cloneDeep";
import { CropImage } from "./CropImage";

const ImageCarousel = () => {
  const dispatch = useDispatch();

  const files = useSelector((state: any) => state.postContent.files);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const onCropImage = (image: any) => {
    let filesCopy = cloneDeep(files);
    filesCopy[selectedIndex].croppedUrl = image;
    dispatch(setFiles(filesCopy));
  };

  const onChangeFilters = (type: any, url: any) => {
    let filesCopy = cloneDeep(files);

    filesCopy[selectedIndex].url = url;
    filesCopy[selectedIndex].type = type;
    dispatch(setFiles(filesCopy));
  };

  return (
    <Box>
      <Carousel
        autoPlay={false}
        onChange={(newIndex: any) => {
          setSelectedIndex(newIndex);
        }}
        navButtonsAlwaysInvisible
        indicators={files.length > 1}
        stopAutoPlayOnHover
        swipe={false}
      >
        {files.map((item: any, i: any) => (
          <Box sx={{ width: "100%", height: "400px" }} key={i}>
            <CropImage url={item.url} onCropImage={onCropImage} index={i} />
          </Box>
        ))}
      </Carousel>

      <Box display="flex" gap="20px" overflow="scroll" pt="30px">
        <ImageFilters
          file={files[selectedIndex]}
          onClickFilter={({ type, url }: any) => onChangeFilters(type, url)}
        />
      </Box>
    </Box>
  );
};

export { ImageCarousel };
