function parseTime(time: string): [number, number] {
    const [timePart, meridian] = time.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);
    if (meridian === "PM" && hours !== 12) {
      hours += 12;
    } else if (meridian === "AM" && hours === 12) {
      hours = 0;
    }
    return [hours, minutes];
  }
  function formatDateForGivenDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "numeric" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }
export function isCurrentTimeInRange(
    startTime: string,
    endTime: string,
    date: string
  ): boolean {
    const today = new Date();
    const currentDateString = today.toLocaleDateString("en-GB"); // Format as "DD-MM-YYYY"
    const [day, month, year] = formatDateForGivenDate(date).split(" ").map(Number);
    const startDateTime = new Date(
      year,
      month - 1,
      day,
      ...parseTime(startTime)
    );
    const endDateTime = new Date(year, month - 1, day, ...parseTime(endTime));
    const currentTime = new Date();
    const value =
      currentDateString ===
        new Date(year, month - 1, day).toLocaleDateString("en-GB") &&
      currentTime >= startDateTime &&
      currentTime <= endDateTime;
    return value;
  }