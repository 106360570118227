import React from 'react';
import CreateSubscription from '../../components/Creator/Subscription/CreateSubscription';


const CreatorSubscription = () => {
  return (
    <CreateSubscription
        headerTitleText="Create Subscription Plan"
        title="Set monthly subscription plan"
        subtitle="Set your total time offerings for at least 1 or more services below followed by your Monthly charges"
        tooltipText="create a package for your monthly subscription that you want to offer to your subscribers."
        cancelTextTitle="Discard On Demand 1-1?"
        cancelTextSubtitle="Are you sure you want to discard this on demand 1-1 rate?"
        />
  )
}

export default CreatorSubscription;

