import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SlotBookingState {
  serviceType: string;
  serviceValue: string;
  creatorDetails: {
    creatorName: string;
    creatorId: string;
  };
  susbscriptionDetails: {
    userSubscriptionId: string;
    expiryDate: string;
  };
  service: string;
  minuteSlot: string;
  daySlot: {
    index: number;
    name: string;
    date: string;
  };
  selectedSlot: {
    startTime: string;
    endTime: string;
  };
  availableSlotTime: {
    startTime: string;
    endTime: string;
  };
  lockedSlotId: string;
  bookedSlotId: string;
  assignedSlot: any;
  minuteData: any;
  timerEndTime: string;
}

const initialState: SlotBookingState = {
  serviceType: "",
  serviceValue: "",
  creatorDetails: {
    creatorName: "",
    creatorId: "",
  },
  susbscriptionDetails: {
    userSubscriptionId: "",
    expiryDate: "",
  },
  service: "",
  minuteSlot: "",
  daySlot: {
    index: 0,
    name: "",
    date: "",
  },
  selectedSlot: {
    startTime: "",
    endTime: "",
  },
  availableSlotTime: {
    startTime: "",
    endTime: "",
  },
  lockedSlotId: "",
  bookedSlotId: "",
  minuteData: {},
  assignedSlot: {},
  timerEndTime: "",
};

const slotBookingSlice = createSlice({
  name: "slotBooking",
  initialState,
  reducers: {
    setCreatorDetails: (
      state,
      action: PayloadAction<{ creatorName: string; creatorId: string }>
    ) => {
      state.creatorDetails = action.payload;
    },
    setServiceType: (state, action: PayloadAction<string>) => {
      state.serviceType = action.payload;
    },
    setServiceValue: (state, action: PayloadAction<string>) => {
      state.serviceValue = action.payload;
    },
    setService: (state, action: PayloadAction<string>) => {
      state.service = action.payload;
      state.minuteSlot = "";
      state.daySlot = { index: 0, name: "", date: "" };
      state.selectedSlot = { startTime: "", endTime: "" };
      state.availableSlotTime = { startTime: "", endTime: "" };
      state.assignedSlot = {};
    },
    setMinuteSlot: (state, action: PayloadAction<string>) => {
      state.minuteSlot = action.payload;
      state.daySlot = { index: 0, name: "", date: "" };
      state.selectedSlot = { startTime: "", endTime: "" };
      state.availableSlotTime = { startTime: "", endTime: "" };
      state.assignedSlot = {};
    },
    setDaySlot: (
      state,
      action: PayloadAction<{ index: number; name: string; date: string }>
    ) => {
      state.daySlot = action.payload;
      state.selectedSlot = { startTime: "", endTime: "" };
      state.availableSlotTime = { startTime: "", endTime: "" };
      state.assignedSlot = {};
    },
    setSelectedSlot: (
      state,
      action: PayloadAction<{ startTime: string; endTime: string }>
    ) => {
      state.selectedSlot = action.payload;
      state.availableSlotTime = { startTime: "", endTime: "" };
      state.assignedSlot = {};
    },
    setAvailableSlotTime: (
      state,
      action: PayloadAction<{ startTime: string; endTime: string }>
    ) => {
      state.availableSlotTime = action.payload;
    },
    setSubscriptionDetails: (
      state,
      action: PayloadAction<{
        userSubscriptionId: string;
        expiryDate: string;
      }>
    ) => {
      state.susbscriptionDetails = action.payload;
    },
    setLockedSlotId: (state, action: PayloadAction<string>) => {
      state.lockedSlotId = action.payload;
    },
    setBookedSlotId: (state, action: PayloadAction<string>) => {
      state.bookedSlotId = action.payload;
    },
    setMinuteData: (state, action: PayloadAction<any>) => {
      state.minuteData = action.payload;
    },
    setAssignedSlot: (state, action: PayloadAction<any>) => {
      state.assignedSlot = action.payload;
      state.availableSlotTime = { startTime: "", endTime: "" };
    },
    setTimerEndTime: (state, action: PayloadAction<string>) => {
      state.timerEndTime = action.payload;
    },
    resetBooking: (state) => {
      const { susbscriptionDetails } = state;
      return { ...initialState, susbscriptionDetails };
    },
  },
});

export const {
  setCreatorDetails,
  setServiceType,
  setService,
  setServiceValue,
  setMinuteSlot,
  setDaySlot,
  setSelectedSlot,
  setAvailableSlotTime,
  setSubscriptionDetails,
  setLockedSlotId,
  setBookedSlotId,
  setMinuteData,
  setAssignedSlot,
  setTimerEndTime,
  resetBooking,
} = slotBookingSlice.actions;

export const slotBookingReducer = slotBookingSlice.reducer;
