const loadImage = async (videoFile: string): Promise<HTMLVideoElement> => {
  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.src = videoFile;
    video.autoplay = true;
    video.controls = true;

    video.addEventListener("loadedmetadata", function () {
      resolve(video);
    });
  });
};

export const trimVideo = (videoFile: any, end: number) => {
  return new Promise(async (resolve) => {
    const video: HTMLVideoElement = await loadImage(videoFile);

    if (!video) return;

    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    if (!canvas) return;

    const start = 0;

    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    video.currentTime = start;

    await new Promise((resolveIn) => {
      video.onseeked = () => {
        resolveIn(true);
      };
    });

    const stream = canvas.captureStream();
    const recorder = new MediaRecorder(stream);
    const chunks: any = [];

    recorder.ondataavailable = (e) => chunks.push(e.data);
    recorder.start();

    video.play();

    const trimEnd = end;
    let currentTime = start;

    const drawFrame = () => {
      if (currentTime >= trimEnd) {
        video.pause();
        recorder.stop();
        return;
      }
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      currentTime = video.currentTime;
      requestAnimationFrame(drawFrame);
    };

    drawFrame();

    recorder.onstop = () => {
      const blob = new Blob(chunks, { type: "video/mp4" });
      const url = URL.createObjectURL(blob);
      resolve(url);
      return;
    };
  });
};
