import React, { useEffect, useState } from "react";
import { PageTagComponent } from "../../Common/PageTagComponent";
import {
  Box,
  Button,
  styled,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  AddSlotsIcon,
  RemoveSlotsIcon,
} from "../../../assets/SvgIcons";
import TimePickerInput from "../../Common/TimePickerInput";
import { SlotsDataType, TimeSlotType } from "../../../types";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../context/ModalContext";
import DiscardModal from "../../../modals/DiscardModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { setWeeklyAvailability } from "../../../redux/bookingSlice";
import bookingServiceApi from "../../../api/creator/bookingServiceApi";
import dayjs from "dayjs";
import { typography, colors } from "../../../utils/styles";
import { useSnackbar } from "../../../context/SnackbarContext";
const Container = styled(Box)`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const SetAvailabilityHeader = styled(Box)``;
const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  overflow: auto;
`;
const ImageAndTextContainer = styled(Box)`
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  justify-content: center;
  padding: 0px 17px;
`;
const Text = styled(Box)`
  color: var(--Text-Secondary, ${colors.gray[80]});
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 23px;
  width: 100%;
  padding: 0px 17px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 78vh;
`;

const TimeSlotContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
  justify-content: center;
  flex: 1;
  position: relative;
`;
const AddSlotButton = styled(Button)`
  padding: 0;
  min-width: 0;
`;
const ShowTimeSlotsContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 6px;
}
`;
const BottomContainer = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
const SetAvailabilityButton = styled(Button)<{
  backgroundColor: string;
  textColor: string;
}>`
  display: flex;
  height: 56px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  text-align: center;
  width: 100%;
  font-size: ${typography.font_size.base}px;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  border-radius: 0;
  &:hover {
    background-color: ${(props) => props.backgroundColor};
  }
`;

const TimeSlotInputContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
const RemoveSlotButtonContainer = styled(Box)``;
const RemoveSlotButton = styled(Button)`
  color: ${colors.red[100]};
  font-size: ${typography.font_size.xs}px;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
  padding: 0;
  min-width: 0;
  margin-right: 8px;
`;

const CheckboxContainer = styled(Checkbox)`
  &.Mui-checked {
    color: green;
  }
  ,
  &[data-testid="CheckBoxOutlineBlankIcon"] {
    border: 1px solid red;
  }
`;

const SlotContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;

const CheckBoxAndLabelContainer = styled(FormControlLabel)`
  min-width: 22%;
  flex: 1;
`;

const UnavailableText = styled(Typography)``;

const ApplyToAllButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -18px;
  right: 0;
`;

const ApplyToAllTextButton = styled(Typography)`
  color:  ${colors.green[300]};
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
  padding-right: 33px;
`;
interface CreatorSetAvailabilityProps {}
const weekDayNumbers: any = {
  0: "MON",
  1: "TUE",
  2: "WED",
  3: "THUR",
  4: "FRI",
  5: "SAT",
  6: "SUN",
};
const initialTimeSlotValue: TimeSlotType = {
  from: "00:00 AM",
  to: "11:00 PM",
};
const initialSlotsData: SlotsDataType[] = [
  {
    day: 0,
    checked: false,
    slots: [],
  },
  {
    day: 1,
    checked: false,
    slots: [],
  },
  {
    day: 2,
    checked: false,
    slots: [],
  },
  {
    day: 3,
    checked: false,
    slots: [],
  },
  {
    day: 4,
    checked: false,
    slots: [],
  },
  {
    day: 5,
    checked: false,
    slots: [],
  },
  {
    day: 6,
    checked: false,
    slots: [],
  },
];
const CreatorSetAvailability: React.FC<CreatorSetAvailabilityProps> = ({}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { weeklyAvailability } = useSelector(
    (state: RootState) => state.bookings
  );
  const [slotsData, setSlotsData] = useState<SlotsDataType[]>(initialSlotsData);
  const [openApplyToAll, setOpenApplyToAll] = useState<boolean>(false);
  const [indexForApplyToAll, setIndexForApplyToAll] = useState<number>();
  const { openSnackBar } = useSnackbar();
  const navigate = useNavigate();
  const { requestOpenModal, requestCloseModal } = useModal();
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setSlotsData((prevSlotsData) =>
      prevSlotsData.map((slot, i) =>
        i === index
          ? !slot.checked
            ? {
                ...slot,
                checked: !slot.checked,
                slots: slot.slots.length
                  ? [...slot.slots]
                  : [initialTimeSlotValue],
              }
            : { ...slot, checked: !slot.checked }
          : slot
      )
    );
  };
  const checkForActiveAddSlotButton = (index: number) => {
    const slotItem =slotsData[index];
    const flag=slotItem?.slots?.some(
        (slot) =>
          slot.to === initialTimeSlotValue.to &&
          slot.from === initialTimeSlotValue.from
      )
    return flag;
  };
  const handleOnClickAddTimeSlotIcon = (index: number) => {
    const item = slotsData[index];
    const alreadyAdded = item.slots.some(
      (slot) =>
        slot.to === initialTimeSlotValue.to &&
        slot.from === initialTimeSlotValue.from
    );
    if (alreadyAdded) {
      openSnackBar("slot already added change values and try again to add a new slot", "info");
      return;
    }
    setSlotsData((prevSlotsData) =>
      prevSlotsData.map((slot, i) =>
        i === index
          ? {
              ...slot,
              slots: [...slot.slots, initialTimeSlotValue],
            }
          : slot
      )
    );
  };
  const handleOnClickRemoveTimeSlotIcon = (
    e: React.MouseEvent<HTMLButtonElement>,
    slotIndex: number,
    index: number
  ) => {
    let newSlotsData = [...slotsData];
    let newSlots = [...(newSlotsData[index]?.slots || [])];
    newSlots.splice(slotIndex, 1);
    newSlotsData[index] = {
      ...newSlotsData[index],
      slots: newSlots,
    };
    setSlotsData(newSlotsData);
  };
  
  const handleChangeTimeSlotFromTime = (
    time: string,
    index: number,
    slotIndex: number
  ) => {
    const updatedTimeSlots = slotsData.map((slot, i) =>
      i === index
        ? {
            ...slot,
            slots: slot.slots.map(
              (s, sIndex) => (sIndex === slotIndex ? { ...s, from: time } : s) // Update the 'to' time for the specific slotIndex
            ),
          }
        : slot
    );
    if (compareTimes(time, updatedTimeSlots[index]?.slots[slotIndex]?.to)) {
      setSlotsData([...updatedTimeSlots]);
    }

    setIndexForApplyToAll(index);
    setOpenApplyToAll(true);
  };
  const handleChangeTimeSlotToTime = (
    time: string,
    index: number,
    slotIndex: number
  ) => {
    const updatedTimeSlots = slotsData.map((slot, i) =>
      i === index
        ? {
            ...slot,
            slots: slot.slots.map(
              (s, sIndex) => (sIndex === slotIndex ? { ...s, to: time } : s) // Update the 'to' time for the specific slotIndex
            ),
          }
        : slot
    );
    if (compareTimes(updatedTimeSlots[index]?.slots[slotIndex]?.from, time)) {
      setSlotsData([...updatedTimeSlots]);
    }
    setIndexForApplyToAll(index);
    setOpenApplyToAll(true);
  };
  const compareTimes = (from: string, to: string) => {
    const fromDateTime = dayjs(from, "hh:mm A");
    const toDateTime = dayjs(to, "hh:mm A");
    if (fromDateTime.isAfter(toDateTime) || toDateTime.isBefore(fromDateTime)) {
      openSnackBar("The to time must be greater than the from time", "warning");
      return false;
    }
    return true;
  };
  const handleOnClickSetAvailabilityButton = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    try {
      e.preventDefault();
      if (checkForOverlappingSlots(slotsData)) {
        openSnackBar(
          "you have overlapping slots. please change your overlapping slots",
          "warning"
        );
        return;
      }
      let availabilityBodyData: SlotsDataType[] = [];
      slotsData.forEach((slot) => {
        if (slot.checked) {
          availabilityBodyData.push({ slots: slot.slots, day: slot.day });
        }
      });
      availabilityBodyData=removeDuplicateSlots(availabilityBodyData)
      if (availabilityBodyData.length) {
        dispatch(setWeeklyAvailability(availabilityBodyData));
        await bookingServiceApi.addWeeklyAvailability(availabilityBodyData);
        openSnackBar("Your availability is set successfully. Click weekly availability to view and edit", "success")
        navigate("/creator/bookings");
      }
    } catch (err) {
      console.error("Failed to set weekly availability", err);
      openSnackBar("Error in setting availability", "error");
      navigate("/creator/bookings");
    }
  };
  const handleConfirmDiscard = () => {
    setSlotsData(initialSlotsData);
    requestCloseModal();
    navigate(-1);
  };
  const handleCheckSetAvailabilityStatus = () => {
    const status = slotsData?.some((slot) => slot.checked);
    return status;
  };
  const handleOnClickBackButton = () => {
    if (!handleCheckSetAvailabilityStatus()) {
      navigate(-1);
    } else {
      requestOpenModal({
        name: "Discard Modal",
        component: (
          <DiscardModal
            requestOpenModal={requestOpenModal}
            requestCloseModal={requestCloseModal}
            title="You want to Discard set availability process?"
            subtitle="All your progress would be lost. You can still come back to and set your availability."
            handleConfirmDiscard={handleConfirmDiscard}
          />
        ),
      });
    }
  };
  const handleOnClickApplyToAll = (
    e: React.MouseEvent<HTMLSpanElement>,
    appliedSlots: TimeSlotType[],
    index: number
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const newSlotsData = slotsData.map((slot, i) =>
      i !== index
        ? {
            ...slot,
            checked: true,
            slots: appliedSlots,
          }
        : slot
    );
    setSlotsData(newSlotsData);
    openSnackBar("Slots applied to all successfully.", "success");
  };
  const removeDuplicateSlots = (slotsData:SlotsDataType[]) => {
    return slotsData.map((slot) => {
      const uniqueSlots = slot.slots.filter(
        (slotItem, index, self) =>
          index ===
          self.findIndex(
            (s) => s.from === slotItem.from && s.to === slotItem.to
          )
      );
      return { ...slot, slots: uniqueSlots };
    });
  };
  const checkForOverlappingSlots = (slotsData: SlotsDataType[]) => {
    let hasOverlappingSlot = false;
    for (const daySlot of slotsData) {
      const { slots, day } = daySlot;
      for (let i = 0; i < slots.length; i++) {
        const slotA = slots[i];
        const fromA = dayjs(`1970-01-01 ${slotA.from}`, "YYYY-MM-DD hh:mm A");
        const toA = dayjs(`1970-01-01 ${slotA.to}`, "YYYY-MM-DD hh:mm A");

        for (let j = i + 1; j < slots.length; j++) {
          const slotB = slots[j];
          const fromB = dayjs(`1970-01-01 ${slotB.from}`, "YYYY-MM-DD hh:mm A");
          const toB = dayjs(`1970-01-01 ${slotB.to}`, "YYYY-MM-DD hh:mm A");
          if (fromA.isBefore(toB) && fromB.isBefore(toA)) {
            hasOverlappingSlot = true;
            return hasOverlappingSlot;
          }
        }
      }
    }
    return hasOverlappingSlot;
  };
  useEffect(() => {
    if (weeklyAvailability) {
      let slotsDataState: SlotsDataType[] = [];
      weeklyAvailability?.slice().sort((a, b) => a.day - b.day)?.forEach((slot) => {
        slotsDataState.push(slot);
      });
      setSlotsData(removeDuplicateSlots([...slotsDataState]));
    }
  }, [weeklyAvailability]);

  const fetchWeeklyAvailability = async () => {
    try{
    const response= await bookingServiceApi.getWeeklyAvailability();
    dispatch(setWeeklyAvailability(response.data.data));
    }
    catch(error){
      console.error("Error fetching weekly availability", error);
    }
  }

  useEffect(() => {
    if(weeklyAvailability?.every(it=>!it.checked)){
      fetchWeeklyAvailability();
    }
  }, [dispatch])
  return (
    <Container>
      <SetAvailabilityHeader>
        <PageTagComponent handleBack={handleOnClickBackButton}>
          Set Availability
        </PageTagComponent>
      </SetAvailabilityHeader>
      <Content>
        <ImageAndTextContainer>
          <Text>
            Set your calendar, so your followers can see your availability to
            book slot.
          </Text>
        </ImageAndTextContainer>

        <ContentContainer>
          {slotsData?.map((item, index) => {
            const overlappingMessage = checkForOverlappingSlots([item]);
            return (
              <SlotContainer key={index}>
                <CheckBoxAndLabelContainer
                  label={weekDayNumbers[item?.day]}
                  control={
                    <CheckboxContainer
                      checked={item.checked}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleCheckboxChange(e, index)
                      } 
                    />
                  }
                />
                <TimeSlotContainer>
                  {!item.checked ? (
                    <UnavailableText>Unavailable</UnavailableText> // Show Unavailable text if unchecked
                  ) : (
                    <>
                      {item.slots?.map((slot, slotIndex) => (
                        <ShowTimeSlotsContainer key={slotIndex}>
                          {" "}
                          <TimeSlotInputContainer>
                            <TimePickerInput
                              handleChangeSlotTime={
                                handleChangeTimeSlotFromTime
                              }
                              value={slot?.from}
                              index={index}
                              slotIndex={slotIndex}
                            />
                            <TimePickerInput
                              handleChangeSlotTime={handleChangeTimeSlotToTime} // Update 'to' time
                              value={slot?.to}
                              index={index}
                              slotIndex={slotIndex}
                            />
                          </TimeSlotInputContainer>
                          <RemoveSlotButtonContainer>
                            {slotIndex === 0 ? (
                              <AddSlotButton
                                onClick={() =>
                                  handleOnClickAddTimeSlotIcon(index)
                                }
                                disabled={checkForActiveAddSlotButton(index)}
                              >
                                <AddSlotsIcon
                                  fillColor="white"
                                  color={
                                    checkForActiveAddSlotButton(index)
                                      ? "#9E9E9E"
                                      : "#212121"
                                  }
                                />
                              </AddSlotButton>
                            ) : (
                              <RemoveSlotButton
                                onClick={
                                  (e: React.MouseEvent<HTMLButtonElement>) =>
                                    handleOnClickRemoveTimeSlotIcon(
                                      e,
                                      slotIndex,
                                      index
                                    )
                                }
                              >
                                <RemoveSlotsIcon />
                              </RemoveSlotButton>
                            )}
                          </RemoveSlotButtonContainer>
                        </ShowTimeSlotsContainer>
                      ))}
                      {item.checked &&
                        openApplyToAll &&
                        !overlappingMessage &&
                        indexForApplyToAll === index && (
                          <ApplyToAllButtonContainer>
                            <ApplyToAllTextButton
                              onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
                                handleOnClickApplyToAll(e, item.slots, index)
                              }
                            >
                              Apply To All
                            </ApplyToAllTextButton>
                          </ApplyToAllButtonContainer>
                        )}
                      {overlappingMessage && (
                        <Typography
                          sx={{
                            color: "red",
                            paddingRight: "33px",
                            animation: "error 0.1s linear 0s 3",
                            "@keyframes error": {
                              "0%": { transform: "translateY(0)" },
                              "25%": { transform: "translateY(-3px)" },
                              "50%": { transform: "translateY(3px)" },
                              "75%": { transform: "translateY(-3px)" },
                              "100%": { transform: "translateY(0)" },
                            },
                          }}
                        >
                          Overlapping slot
                        </Typography>
                      )}
                    </>
                  )}
                </TimeSlotContainer>
              </SlotContainer>
            );
          })}
        </ContentContainer>
        <BottomContainer>
          <SetAvailabilityButton
            disabled={!handleCheckSetAvailabilityStatus()}
            backgroundColor={
              !handleCheckSetAvailabilityStatus()
                ? "#E6E6E6"
                : colors.violet[100]
            }
            textColor={
              !handleCheckSetAvailabilityStatus()
                ? colors.gray[90]
                : colors.gray[100]
            }
            onClick={handleOnClickSetAvailabilityButton}
          >
            Set Availability
          </SetAvailabilityButton>
        </BottomContainer>
      </Content>
    </Container>
  );
};

export default CreatorSetAvailability;
