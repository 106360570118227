// @ts-nocheck
import './styles.css';
import React, { useState, useCallback, useRef, useEffect} from 'react';
import Cropper from 'react-easy-crop';
import { Button, Slider, Box, Typography, Stack } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { AddPostDescription } from './AddPostDescription';
import { useDispatch, useSelector } from 'react-redux';
import { set_Compressed_Images, set_Cropped_Images, setView } from '../../redux/imageUploadSlice';
import { RootState } from '../../store/store';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import getCroppedImg from './imageProcessingUtil';

const filterOptions = [
  'Sepia', 'Invert', 'grayscale', 'Vintage', 'Brighteness','Saturation',"None"
]

const minZoom = 1;
const PageHeader = styled(Stack)`
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  padding-inline:12px;
  `
// Custom Previous Arrow using MUI ChevronLeftIcon
const customPrevArrow = (onClickHandler, hasPrev) => (
  <button
    type="button"
    onClick={onClickHandler}
    className="custom-arrow custom-prev-arrow"
    disabled={!hasPrev}
  >
    <ChevronLeftIcon />
  </button>
);

// Custom Next Arrow using MUI ChevronRightIcon
const customNextArrow = (onClickHandler, hasNext) => (
  <button
    type="button"
    onClick={onClickHandler}
    className="custom-arrow custom-next-arrow"
    disabled={!hasNext}
  >
    <ChevronRightIcon />
  </button>
);

const CropperComponent = ({ onCropComplete }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const images = useSelector((state: RootState) => state.imagePost.compressedImages);
  const ref_images = useSelector((state: RootState) => state.imagePost.referenceImages);
  const real_images = useSelector((state: RootState) => state.imagePost.originalImages);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [cropData, setCropData] = useState(
    images.map(() => ({ 
      crop: { x: 0, y: 0 }, 
      zoom: minZoom, 
      croppedAreaPixels: null 
    }))
  );

  const canvasRef = useRef(null);

  const onBackBtnClick = () => {
    navigate(-1, { replace: true });
  };

  const onCropChange = useCallback((crop) => {
    setCropData((prevData) => {
      const updatedData = [...prevData];
      updatedData[currentIndex].crop = crop;
      return updatedData;
    });
  }, [currentIndex]);

  const onZoomChange = useCallback((newZoom) => {
    setCropData((prevData) => {
      const updatedData = [...prevData];
      updatedData[currentIndex].zoom = newZoom;
      return updatedData;
    });
  }, [currentIndex]);

  const onCropCompleteHandler = useCallback((croppedArea, croppedAreaPixels) => {
    setCropData((prevData) => {
      const updatedData = [...prevData];
      if (!croppedAreaPixels || (croppedAreaPixels.width === 0 && croppedAreaPixels.height === 0)) {
        croppedAreaPixels = {
          x: 0,
          y: 0,
          width: real_images[currentIndex].naturalWidth,
          height: real_images[currentIndex].naturalHeight,
        };
      }
  
      updatedData[currentIndex].croppedAreaPixels = croppedAreaPixels;
      return updatedData;
    });
  }, [currentIndex]);

  const handleSaveAll = async () => {
    const updatedCroppedImages = [];
    for (let i = 0; i < images.length; i++) {
      const croppedAreaPixels = cropData[i].croppedAreaPixels ||
         { 
          x: 0,
          y: 0,
          width: real_images[i].naturalWidth || 800,
          height: real_images[i].naturalHeight || 800,
        };
      console.log(croppedAreaPixels)
      const croppedImage = await getCroppedImg(images[i], croppedAreaPixels);
      updatedCroppedImages.push(croppedImage);
    }

    dispatch(set_Cropped_Images(updatedCroppedImages));
    dispatch(setView(images.length > 1 ? 'FREE_PHOTOS' : 'POST_DETAILS'));
    // onCropComplete(updatedCroppedImages);
  };

  const applyFilterToCanvas = async (filterType) => {
   
    try {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = ref_images[currentIndex];
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        switch (filterType) {
          case 'grayscale':
            for (let i = 0; i < data.length; i += 4) {
              const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
              data[i] = avg; data[i + 1] = avg; data[i + 2] = avg;
            }
            break;
          case 'sepia':
            for (let i = 0; i < data.length; i += 4) {
              const r = data[i], g = data[i + 1], b = data[i + 2];
              data[i] = r * 0.393 + g * 0.769 + b * 0.189;
              data[i + 1] = r * 0.349 + g * 0.686 + b * 0.168;
              data[i + 2] = r * 0.272 + g * 0.534 + b * 0.131;
            }
            break;
          case 'vintage':
            for (let i = 0; i < data.length; i += 4) {
              const r = data[i], g = data[i + 1];
              data[i] = r * 0.9 + g * 0.5; data[i + 1] = g * 0.7; data[i + 2] = data[i + 2] * 0.4;
            }
            break;
          case 'saturation':
            const saturationFactor = 1.5;
            for (let i = 0; i < data.length; i += 4) {
              const gray = 0.2989 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2];
              data[i] = gray + (data[i] - gray) * saturationFactor;
              data[i + 1] = gray + (data[i + 1] - gray) * saturationFactor;
              data[i + 2] = gray + (data[i + 2] - gray) * saturationFactor;
            }
            break;
          case 'invert':
            for (let i = 0; i < data.length; i += 4) {
              data[i] = 255 - data[i];
              data[i + 1] = 255 - data[i + 1];
              data[i + 2] = 255 - data[i + 2];
            }
            break;
        }

        ctx.putImageData(imageData, 0, 0);

        const updatedImages = [...images];
        updatedImages[currentIndex] = canvas.toDataURL();
        dispatch(set_Compressed_Images(updatedImages));
      };
    } catch (error) {
      console.error('Error applying filter:', error);
    }
    finally{
      setIsLoading(false)
    }
  };

  return (
    <Box>
      <canvas ref={canvasRef} style={{display:'none'}}/>
      <PageHeader
        sx={{background: "#DCD7F5",height:'56px',boxShadow:'none'}}>
        <CloseIcon onClick={onBackBtnClick}/>
        <Typography fontSize="16px" fontWeight={700}>
          New Post
        </Typography>
        <Typography fontSize="12px" fontWeight={700} onClick={handleSaveAll}>Next</Typography>
      </PageHeader>
      <Box >
        <Carousel
          showThumbs={false}
          selectedItem={currentIndex}
          onChange={(index) => setCurrentIndex(index)}
          showArrows={true}
          infiniteLoop={true}
          className="carousel-container"
          showIndicators={false}
          swipeable={false}
          renderArrowNext={customNextArrow}
          renderArrowPrev={customPrevArrow}
        >
          {images.map((src, idx) => (
            <div className="crop-container" key={idx}>
              <Cropper
                minZoom={minZoom}
                image={src}
                crop={cropData[idx].crop}
                zoom={cropData[idx].zoom}
                aspect={1}
                restrictPosition={false}
                onCropChange={onCropChange}
                onCropComplete={onCropCompleteHandler}
                onZoomChange={onZoomChange}
                cropSize={{ height: 360, width: 360 }}
              />
              {/* {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                zIndex:1000
              }}
            >
              Processing..
            </div>
          )} */}
            </div>
          ))}
        </Carousel>
      </Box>

      {/* <div className="controls">
        <Slider
          value={cropData[currentIndex].zoom}
          min={minZoom}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e, zoom) => onZoomChange(Number(zoom))}
        />
      </div> */}
      <Typography px={3} mt={4} color="#212121" fontWeight={500}>Choose Filter</Typography>
      <Stack px={3} mt={1} direction='row' gap={1.5}   sx={{overflow:'auto'}}>
       {filterOptions.map((filter:any)=>{
        return  <Box  sx={{
            height:'80px',
            width:'80px',
            position:'relative',
            background:`url(${ref_images[currentIndex]})`,
            backgroundPosition:'center',
            backgroundSize:'cover',
            flexShrink: 0,
            }}
            onClick={()=>applyFilterToCanvas(filter.toLowerCase())}
            >
          <Typography width='100%'
            sx={{position:'absolute',bottom:0,color:'#fff',background:"#c6c6c6",fontSize:'12px',textAlign:'center'}}
            fullWidth 
            >{filter}</Typography>
        </Box>
       })}
      </Stack>
    </Box>
  );
}

export default CropperComponent