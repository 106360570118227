import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  Collapse,
  Card,
  CardContent,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DeleteNewIcon,
  EditIcon,
  ForwardIcon,
  TickMarkIcon,
} from "../../../assets/SvgIcons";
import DynamicStyledInput from "../../StyledComponent/DynamicStyledInput";
import { useModal } from "../../../context/ModalContext";
import DeleteSubscription from "../../../modals/DeleteSubscription/DeleteSubscription";
import { colors, typography } from "../../../utils/styles";

const ExpandableCard = styled(Card)(({ theme }) => ({
  transition:
    theme?.transitions?.create(["height"], {
      duration: 0.3,
    }) || "height 0.3s",
  overflow: "hidden",
  display: "flex",
  padding: "8px !important",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
  boxShadow: "none",
  borderRadius: "8px",
  background: "rgba(220, 215, 245, 0.40)",
}));

const CardDataContent = styled(CardContent)`
  padding: 0 !important;
  width: 100%;
`;

const InitialContainer = styled(Box)`
  width: 100%;
`;

const CollapseContainer = styled(Collapse)``;

const InitialSetButton = styled(Button)`
  color: ${colors.violet[100]};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
  background: none;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InitialTitleContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
`;

const Subtitle = styled(Typography)`
  color: ${colors.gray[80]};
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;

const InitialButtonContainer = styled(Box)`
  box-shadow: none;
`;

const InfoContainer = styled(Box)``;

const InfoTitle = styled(Typography)`
  color: ${colors.gray[80]};
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;

const InfoTextAndValue = styled(Typography)`
  color: ${colors.gray[80]};
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;

const InputAndTextContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: center;
`;

const InputInfoTextTitle = styled(Typography)`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;

const SetButton = styled(Button)`
  display: flex;
  width: 42px;
  height: 32px;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
  border-radius: 8px;
  border: 1px solid ${colors.violet[100]};
  background: ${colors.violet[100]};
  min-width: 42px;
   &:hover {
    background: ${colors.violet[100]};
  }
`;

const IconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InitialIconsContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
`;

const audioCallDiscardMessage =
  "Are you sure you want to exit without saving Audio call rates?";
const videoCallDiscardMessage =
  "Are you sure you want to exit without saving Video call rates?";
const dmDiscardMessage =
  "Are you sure you want to exit without saving Direct Message rates?";

interface SubscriptionInputCardProps {
  title: string;
  subtitle: string;
  infoText: string;
  minimumText: string;
  maximumText: string;
  minimumTime: string;
  maximumTime: string;
  inputInfoText: string;
  inputFieldUnitText: string;
  name: string;
  value: number | null;
  onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValueSet: (e:React.MouseEvent<HTMLButtonElement>) => void;
  onDelete: () => void;
  stateValue: number | null;
}

const SubscriptionInputCard: React.FC<SubscriptionInputCardProps> = ({
  title,
  subtitle,
  infoText,
  minimumText,
  maximumText,
  minimumTime,
  maximumTime,
  inputInfoText,
  inputFieldUnitText,
  name,
  value,
  onValueChange,
  onValueSet,
  onDelete,
  stateValue
}) => {
  const { requestOpenModal, requestCloseModal } = useModal();
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSetClick = (e:React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onValueSet(e);
    setExpanded(false);
  };

  const deleteSubsCription = () => {
    onDelete();
  };

  const handleOnDeleteIconClick = () => {
    const substitle =
    name === "audioCallYc"
        ? audioCallDiscardMessage
        : name === "videoCallYc"
        ? videoCallDiscardMessage
        : dmDiscardMessage;
    requestOpenModal({
      name: "DeleteSubscription",
      component: (
        <DeleteSubscription
          requestOpenModal={requestOpenModal}
          requestCloseModal={requestCloseModal}
          deleteSubsCription={deleteSubsCription}
          title="Discard Changes?"
          subtitle={substitle}

        />
      ),
    });
  };

  return (
    <ExpandableCard>
      <CardDataContent>
        <InitialContainer
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InitialTitleContainer>
            <Title>
              {title}
              {stateValue ? (
                <IconContainer>
                  <TickMarkIcon color={`${colors.green[100]}`} />
                </IconContainer>
              ): null}
            </Title>
            <Subtitle>{value ? `Your charges - ${value} yc/min` : subtitle}</Subtitle>
          </InitialTitleContainer>
          <InitialButtonContainer>
            {!expanded ? (
             stateValue ? (
                <>
                  <InitialIconsContainer>
                    <IconButton
                      sx={{ padding: "0" }}
                      onClick={handleExpandClick}
                    >
                      <EditIcon color={`${colors.black[200]}`} />
                    </IconButton>
                    <IconButton
                      sx={{ padding: "0" }}
                      onClick={handleOnDeleteIconClick}
                    >
                      <DeleteNewIcon />
                    </IconButton>
                  </InitialIconsContainer>
                </>
              ) : (
                <InitialSetButton
                  endIcon={<ForwardIcon />}
                  onClick={handleExpandClick}
                >
                  set rate
                </InitialSetButton>
              )
            ) : (
              <IconButton onClick={handleExpandClick}>
                <ExpandMoreIcon />
              </IconButton>
            )}
          </InitialButtonContainer>
        </InitialContainer>

        <CollapseContainer in={expanded}>
          <InfoContainer mt={2}>
            <InfoTitle>{infoText}</InfoTitle>
            <InfoTextAndValue>
              {minimumText}: {minimumTime}
            </InfoTextAndValue>
            <InfoTextAndValue>
              {maximumText}: {maximumTime}
            </InfoTextAndValue>
          </InfoContainer>
          <Divider sx={{ my: 2 }} />
          <InputAndTextContainer>
            <InputInfoTextTitle>{inputInfoText}</InputInfoTextTitle>
            <DynamicStyledInput
              type="number"
              min={0}
              padding="6px 12px"
              borderRadius="8px"
              height="31px"
              border={`2px solid  ${colors.violet[100]}`}
              background="#fff"
              value={value ? value : null }
              name={name}
              width="96px"
              onChange={onValueChange}
              placeholder="0"
            />
            <SetButton onClick={handleSetClick}>Set</SetButton>
          </InputAndTextContainer>
        </CollapseContainer>
      </CardDataContent>
    </ExpandableCard>
  );
};

export default SubscriptionInputCard;
