import React, { useEffect } from "react";
import { ForwardArrowIcon } from "../../assets/SvgIcons";
import { Box, styled, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import {
  setInAppNotification,
  setNewPostNotification,
  setDirectMessagetNotification,
} from "../../redux/settingsSlice";
import { colors, typography } from "../../utils/styles";

const Container = styled(Box)`
  width: 100%;
  overflow: hidden;
  position: relative;
`;
const Items = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const ItemContainer = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--Border-Light, rgba(33, 33, 33, 0.05));
`;
const ItemText = styled(Typography)`
  color: ${colors.black[200]};
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const IconContainer = styled(Box)``;
const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const dispatch = useDispatch<AppDispatch>();
  let appCreatorProfile = useSelector(
    (state: RootState) => state.creatorProfile.profile
  );
  let appUserProfile = useSelector((state: RootState) => state.user);
  let profile: any;

  if (appCreatorProfile.id) {
    profile = appCreatorProfile;
  } else {
    profile = appUserProfile;
  }
  const handleOnClickNotification = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    navigate(`/${userRole}/settings/notification`);
  };
  const handleOnClickPrivacyPolicy = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    navigate(`/${userRole}/privacy-policy`);
  };
  const handleOnClickTermsAndConditions = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.preventDefault();
    navigate(`/${userRole}/terms-and-conditions`);
  };
  const handleOnClickFAQ = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    navigate(`/${userRole}/settings/FAQ`);
  };
  const handleOnClickAccount = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    navigate(`/${userRole}/settings/account`);
  };
  useEffect(() => {
    if (profile) {
      dispatch(setInAppNotification(profile?.inAppNotificationsOn));
      dispatch(setNewPostNotification(profile?.newPostNotificationsOn));
      dispatch(
        setDirectMessagetNotification(profile?.directMessageNotificationsOn)
      );
    }
  }, [profile]);

  return (
    <Container>
      <Items>
        <ItemContainer onClick={handleOnClickAccount}>
          <ItemText>Account</ItemText>
          <IconContainer>
            <ForwardArrowIcon />
          </IconContainer>
        </ItemContainer>

        <ItemContainer onClick={handleOnClickNotification}>
          <ItemText>Notification</ItemText>
          <IconContainer>
            <ForwardArrowIcon />
          </IconContainer>
        </ItemContainer>

        <ItemContainer onClick={handleOnClickPrivacyPolicy}>
          <ItemText>Privacy & Policy</ItemText>
          <IconContainer>
            <ForwardArrowIcon />
          </IconContainer>
        </ItemContainer>

        <ItemContainer onClick={handleOnClickTermsAndConditions}>
          <ItemText>Terms & Conditions</ItemText>
          <IconContainer>
            <ForwardArrowIcon />
          </IconContainer>
        </ItemContainer>

        <ItemContainer onClick={handleOnClickFAQ}>
          <ItemText>FAQ</ItemText>
          <IconContainer>
            <ForwardArrowIcon />
          </IconContainer>
        </ItemContainer>
      </Items>
    </Container>
  );
};

export default Settings;
