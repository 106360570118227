import React from 'react';

import { Route as ReactRoute} from 'react-router-dom';
import Wrapper from '../layout/Wrapper';
import { BottomNavRoute } from '../routes/bottomNavRoutes';
import { SidebarRoutes } from '../routes/sidebarRoutes';

interface Route {
    path: string;
    page: React.ComponentType<any>;
    state: string;
}

const generateRoute =(routes: Route[])=>{
    return routes.map((route, index) => {
        const PageComponent = route.page;
        return (
          <ReactRoute
            key={index}
            path={route.path}
            element={
              <Wrapper state={route.state}>
                <PageComponent />
              </Wrapper>
            }
          />
        );
      });
}

const sidebar_routes = generateRoute(SidebarRoutes);
const bottom_nav_routes = generateRoute(BottomNavRoute);

export {sidebar_routes,bottom_nav_routes}

