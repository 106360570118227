// @ts-nocheck
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { Carousel } from "react-responsive-carousel";
import Cropper, { MediaSize } from "react-easy-crop";
import CloseIcon from '@mui/icons-material/Close';
import "./styles.css"
import { Box, Button, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { AddPostDescription } from "./PostDescription";
import { set_Cropped_Area_Coordinates, set_Free_Seconds, set_Video_Post_View } from "../../redux/videoUploadSlice";

const minZoom = 1;
type CroppedAreaPixel = {
    width: number,
    height: number,
    x: number,
    y: number
}
const PageHeader = styled(Stack)`
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  padding-inline:12px;
  `

const VideoCropper = () => {
    const dsipatch = useDispatch();
    const videoUrls = useSelector((state: RootState) => state.videoPost.originalVideos);

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [videosState, setVideosState] = useState(videoUrls.map(() => ({
        crop: { x: 0, y: 0 },
        zoom: minZoom,
        croppedAreaPixels: { width: 0, height: 0, x: 0, y: 0 },
    })));
    const [mediaSizes, setMediaSizes] = useState<any>([]);
    const [pixelsArea, setPixelsArea] = useState([]);

    const [videoFreeSeconds,setVideoFreeSeconds] = useState(videoUrls.map(() => ({freeSeconds:0})));

    const getMediaSizeFromBlobUrl = async (blobUrl: string): Promise<{ width: number; height: number }> => {
        return await new Promise((resolve, reject) => {
            const video = document.createElement('video');

            video.src = blobUrl;
            video.onloadedmetadata = () => {
                const width = video.videoWidth;
                const height = video.videoHeight;
                resolve({ width, height });
            };

            video.onerror = (error) => {
                reject(error);
            };
        });
    };

    const onMediaLoaded = useCallback((media: any) => {
        console.log("Media loaded for video:", currentVideoIndex, media);
    }, [currentVideoIndex]);

    const handleCarouselChange = (index: number) => {
        setCurrentVideoIndex(index);
    };

    const onCropChange = useCallback((crop: any) => {
        setVideosState((prevData) => {
            const updatedData = [...prevData];
            updatedData[currentVideoIndex].crop = crop;
            return updatedData;
        });
    }, [currentVideoIndex]);

    const onZoomChange = useCallback((newZoom: number) => {
        setVideosState((prevData) => {
            const updatedData = [...prevData];
            updatedData[currentVideoIndex].zoom = newZoom;
            return updatedData;
        });
    }, [currentVideoIndex]);

    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: CroppedAreaPixel) => {

        const currentMediaSize = videoUrls?.[currentVideoIndex];

        if (!currentMediaSize) {
            console.log('Media size not available for the current video.');
            return;
        }

        let updatedCroppedAreaPixel: CroppedAreaPixel = croppedAreaPixels;
        if (croppedAreaPixels.y < 0) {
            updatedCroppedAreaPixel = { ...croppedAreaPixels };
            updatedCroppedAreaPixel.height = Math.min(
                updatedCroppedAreaPixel.height + (2 * croppedAreaPixels.y),
                currentMediaSize.height
            );
        }
        else if (croppedAreaPixels.x < 0) {
            updatedCroppedAreaPixel = { ...croppedAreaPixels };
            updatedCroppedAreaPixel.width = Math.min(
                updatedCroppedAreaPixel.width + (2 * croppedAreaPixels.x),
                currentMediaSize.width
            );
        }

        setVideosState((prevData) => {
            const updatedData = [...prevData];
            updatedData[currentVideoIndex].croppedAreaPixels = updatedCroppedAreaPixel;
            dsipatch(set_Cropped_Area_Coordinates({ index: currentVideoIndex,croppedAreaPixels:updatedCroppedAreaPixel }));
            return updatedData;
        });

    }, [currentVideoIndex, mediaSizes.length]);

    const handleOnClickNext = () =>{
        dsipatch(set_Video_Post_View('POST_DETAILS'))
    }

    useEffect(() => {
        dsipatch(set_Free_Seconds(videoFreeSeconds));
      }, [videoFreeSeconds, dsipatch]);
    
    useEffect(()=>{
        dsipatch(set_Cropped_Area_Coordinates(videoUrls.map(e=>(e.croppedAreaPixels))))
    },[])

    return (
        <Box>
            <PageHeader
                sx={{ background: "#DCD7F5", height: '56px', boxShadow: 'none' }}>
                <CloseIcon
                // onClick={onBackBtnClick}
                />
                <Typography fontSize="16px" fontWeight={700}>
                    New Post
                </Typography>
                <Typography fontSize="12px" fontWeight={700} onClick={handleOnClickNext} >Next</Typography>
            </PageHeader>
            <Box mx="auto" sx={{width:"100%"}}>
            {videoUrls.length > 0 && (
                <>
                    <Carousel
                        showThumbs={false}
                        showIndicators={false}
                        onChange={handleCarouselChange}
                        selectedItem={currentVideoIndex}
                        className="carousel-container"
                        swipeable={false}
                    >
                        {videoUrls.map((videoFile, index) => (
                            <div className="crop-container" key={index}>
                                <ExtCropperComponent
                                    videoUrl={videoFile.url}
                                    crop={videosState[index].crop}
                                    zoom={videosState[index].zoom}
                                    onCropChange={onCropChange}
                                    onZoomChange={onZoomChange}
                                    onCropComplete={onCropComplete}
                                    setPixelsArea={setPixelsArea}
                                />
                            </div>
                        ))}
                    </Carousel>
                    {/* <div className="zoom-controls">
                        <input
                            type="range"
                            value={videosState[currentVideoIndex].zoom}
                            min="1"
                            max="3"
                            step="0.1"
                            onChange={(e) => setVideosState((prev) =>
                                prev.map((video, index) =>
                                    index === currentVideoIndex
                                        ? { ...video, zoom: Number(e.target.value) }
                                        : video
                                )
                            )}

                        />
                    </div> */}
                </>
            )}
            <Stack direction='row' justifyContent='space-between' px="16px">

            <FormControlLabel control={<Checkbox 
                checked={videoFreeSeconds[currentVideoIndex].freeSeconds !== 0} />}
                label="Set Free Seconds" />
            <Stack direction='row' alignItems='center' gap={2}>
                <Button variant="outlined" onClick={(e)=>{
                e.stopPropagation();
                setVideoFreeSeconds((prev)=>{
                    const x = [...prev];
                    const currentVal = x[currentVideoIndex].freeSeconds;
                    if(currentVal === 0 ){
                        dsipatch(set_Free_Seconds(x));
                        return x
                    }
                    x[currentVideoIndex] = {
                        ...x[currentVideoIndex] , freeSeconds:currentVal - 1
                    };
                    dsipatch(set_Free_Seconds(x));
                    return x
                })
            }}>
                <RemoveIcon />
                </Button>
                
                <Typography>{videoFreeSeconds[currentVideoIndex].freeSeconds} Secs</Typography>
                <Button variant="outlined" onClick={(e)=>{
                   e.stopPropagation();
                  setVideoFreeSeconds((prev)=>{
                      let x = [...prev];
                      const currentVal = x[currentVideoIndex].freeSeconds;
                      if(currentVal >= videoUrls[currentVideoIndex].maxFreeSeconds ){
                        dsipatch(set_Free_Seconds(x));
                        return x
                    }
                      x[currentVideoIndex] = {
                        ...x[currentVideoIndex], 
                        freeSeconds: currentVal + 1
                      };
                      dsipatch(set_Free_Seconds(x));
                      return x
                  })
              }}>
                <AddIcon/>
                </Button>
            </Stack>
            </Stack>
            </Box>
        </Box>
    )
}

export default VideoCropper


interface CropperComponentProps {
    videoUrl: string;
    crop: { x: number; y: number };
    zoom: number;
    onCropChange: (crop: any) => void;
    onZoomChange: (zoom: number) => void;
    setPixelsArea: any;
    onCropComplete: (croppedArea: any, croppedAreaPixels: any) => void;
}

const ExtCropperComponent: React.FC<CropperComponentProps> = ({
    videoUrl,
    crop,
    zoom,
    onCropChange,
    onZoomChange,
    onCropComplete,
    setPixelsArea,
}) => {

    return (
        <Cropper
            video={videoUrl}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onZoomChange={onZoomChange}
            onCropChange={onCropChange}
            restrictPosition={false}
            showGrid={true}
            cropSize={{ width: 360, height: 360 }}
            onCropComplete={onCropComplete}
        />
    );
};
