import { useState } from "react";
import { Box, Stack } from "@mui/material";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import StepperComponent from "../components/StepperComponent";
import { colors } from "../utils/styles";
import ConformationModal from "../modals/ConformationModal/ConformationModal";
import { CreatorOnboardingPage } from "../pages/RegisterForCreator";

const CreatorOnboardingLayout = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const handleClicked = () => {
    setOpenModal(true);
  };
  const strAccessToken:any = localStorage.getItem('accessToken');
  let accessToken = '';
  if(strAccessToken){
  accessToken = JSON.parse(strAccessToken);
  }
  if(!accessToken) return <Navigate to ="/login" />
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: colors.purple[50], height: "40px", px: "20px" }}
      >
        <Box>{""}</Box>
        <img
          src="/Icons/yaara_logo.svg"
          style={{ width: "21px", height: "16px" }}
        />
        <CloseIcon onClick={handleClicked} sx={{ cursor: "pointer" }} />
      </Stack>
      <StepperComponent />
      <CreatorOnboardingPage />
      {openModal && (
        <ConformationModal openModal={openModal} setOpenModal={setOpenModal} />
      )}
    </Box>
  );
};
export { CreatorOnboardingLayout };




// const CreatorOnboardingLayout = () =>{
   
//     return <Box>
//             <Stack
//                 direction="row"
//                 justifyContent="space-between"
//                 alignItems="center"
//                 sx={{ backgroundColor: colors.purple[50], height: "40px", px: "20px" }}
//         >
//                 <Box>{""}</Box>
//                 <img
//                 src="/Icons/yaara_logo.svg"
//                 style={{ width: "21px", height: "16px" }}
//                 />
//                 <CloseIcon onClick={() => navigate("/user/home")} sx={{cursor:'pointer'}} />
//             </Stack>
//             <StepperComponent />
//             <CreatorOnboardingPage />
//     </Box>
// }
// export {CreatorOnboardingLayout}