import { ApiService } from '../ApiClient';
import {userAxiosInstance} from '../axiosInstance';
import '../interceptors/userAuthInterceptors';

class UserGalleryServiceApi extends ApiService {
   
    constructor() {
      super(userAxiosInstance)
    }
    fetchGallery(){
        return this.axios.get('content/gallery?page=1&pageSize=10&view=GRID')
      }
    
    fetchGalleryListView(){
    return this.axios.get('content/gallery?page=1&pageSize=10&view=LIST')
    }

  }
  export default new UserGalleryServiceApi();
  