import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TimerState {
  endTime: string;
  timeLeft: string;
}

const initialState: TimerState = {
  endTime: "",
  timeLeft: "",
};

const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    setEndTime: (state, action: PayloadAction<string>) => {
      state.endTime = action.payload;
      state.timeLeft = "";
    },
    setTimeLeft: (state, action: PayloadAction<string>) => {
      state.timeLeft = action.payload;
    },
    resetTimer: (state) => {
      state.endTime = "";
      state.timeLeft = "";
    },
  },
});

export const { setEndTime, setTimeLeft, resetTimer } = timerSlice.actions;

export const timerReducer = timerSlice.reducer;
