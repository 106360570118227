import { Box, Button, Typography, styled } from "@mui/material";
import React from "react";
import { BackButton } from "../../../../assets/SvgIcons";
import { typography } from "../../../../utils/styles";


const Header = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 12;
  display: flex;
  padding: 16px;
  background: #dcd7f5;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const HeaderContent = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HeaderTitleText = styled(Typography)`
  color: #212121;
  font-size: 16px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: 0.24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  flex: 1 0 0;
`;

interface CreatorPostHeaderProps {
  title: string;
  handleOnClickGoBackButton: () => void;
}

const PostHeader: React.FC<CreatorPostHeaderProps> = ({
  title,
  handleOnClickGoBackButton,
}) => {
  return (
    <Header>
      <HeaderContent>
        <Button
          onClick={handleOnClickGoBackButton}
          sx={{ minWidth: "0", padding: "0" }}
        >
          <BackButton />
        </Button>
        <HeaderTitleText>{title}</HeaderTitleText>
      </HeaderContent>
    </Header>
  );
};

export default PostHeader;
