
import { Box, Button, Typography, styled } from '@mui/material'
import React from 'react'
import { InitialOnDemandRate, RequestCloseModal, RequestOpenModal } from '../../types';
import { typography } from '../../utils/styles';


interface DeleteSubscriptionProps{
    requestOpenModal: RequestOpenModal;
    requestCloseModal: RequestCloseModal;
    deleteSubsCription?: ()=> void;
    deleteRateCard?: ()=> void;
    title: string;
    subtitle: string;
    onDemandData?: InitialOnDemandRate
}


const DeleteContainer=styled(Box)`
display: flex;
align-items: center;
justify-content: center;
height: 100%;
`


const Content=styled(Box)`
display: flex;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;

border-radius: 8px 8px 0px 0px;

`


const BottomButtonsContainer=styled(Box)`
display: flex;
height: 56px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
`

const Title=styled(Typography)`
color:  #212121;
font-size: 20px;
font-style: normal;
font-weight: ${typography.font_weight.bold};
line-height: ${typography.line_height.default};
letter-spacing: 0.3px;
align-self: stretch;
`

const Subtitle=styled(Typography)`
color: #616161;
font-size: 14px;
font-style: normal;
font-weight: ${typography.font_weight.regular};
line-height: ${typography.line_height.default};
letter-spacing: 0.21px;
align-self: stretch;
`

const Buttons=styled(Button)`
display: flex;
height: 48px;
padding: 4px 0px;
justify-content: center;
align-items: center;
gap: 4px;
flex: 1 0 0;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: ${typography.font_weight.bold};
line-height: ${typography.line_height.default};
letter-spacing: 0.24px;
`

const HorizontalDivider=styled(Box)`
display: flex;
height: 0px;
justify-content: center;
align-items: center;
align-self: stretch;
border: .8px solid rgba(33, 33, 33, 0.05)
`

const VerticalDivider=styled(Box)`
display: flex;
height: 56px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
border: .5px solid rgba(33, 33, 33, 0.1)

`

const DeleteContent=styled(Box)`
   width: 280px;
flex-direction: column;
align-items: center;

border-radius: 12px;
background: #FFF;
box-shadow: 0px 24px 80px 0px rgba(0, 0, 0, 0.30);
    
`

const DeleteSubscription:React.FC<DeleteSubscriptionProps>= ({
    requestOpenModal,
    requestCloseModal,
    deleteSubsCription,
    deleteRateCard,
    title,
    subtitle,
    onDemandData
}) => {

    const handleCancel=()=>{
        requestCloseModal();
    }


    const handleConfirmedforDelete=()=>{
        requestCloseModal();
        if(typeof deleteSubsCription == 'function'){
            deleteSubsCription();
            return
        }
        // else if(typeof deleteRateCard == 'function'){
        //     deleteRateCard(onDemandData);
        //     return;
        // }
        
    }


  return (
        <DeleteContainer>
            <DeleteContent>
            <Content>
                <Title>
                        {title}
                </Title>

                <Subtitle>
                    {subtitle}
                </Subtitle>
            </Content>
            <HorizontalDivider/>
            <BottomButtonsContainer>
                    <Buttons sx={{color: "#616161"}}
                    onClick={handleCancel}
                    >
                        Cancel
                    </Buttons>
                        <VerticalDivider/>
                    <Buttons sx={{color: "#7262C2"}}
                        onClick={handleConfirmedforDelete}
                    >
                        Yes
                    </Buttons>
            </BottomButtonsContainer>
            </DeleteContent>

        </DeleteContainer>

  )
}

export default DeleteSubscription