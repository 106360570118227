import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ChangeEventHandler, useEffect, useState } from "react";
import { colors, typography } from "../../utils/styles";

const genders = ["Male", "Female", "Others"]

const GenderSelectRadioInput = ({ value, onChange }: { 
        value: string, 
        onChange: ChangeEventHandler 
    }) => {
    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
        onChange(event);
    };

    useEffect(()=>{
        if(value){
            setSelectedValue(value[0] + value.slice(1).toLowerCase())
        }
    },[value])

    return (
        <RadioGroup
            name="gender"
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: "100%",
                marginTop: "16px"
            }}
            row
            value={selectedValue}
            onChange={handleChange}
        >
            {genders.map((option) => (
                <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio sx={{ display: 'none' }} />}
                    label={option}
                    sx={{
                        height: "24px",
                        width: "89px",
                        backgroundColor: selectedValue === option ? colors.green[50] : '#fff',
                        color: colors.black,
                        border: `1px solid ${selectedValue === option ? colors.green[200] : colors.gray[200]}`,
                        borderRadius: '8px',
                        padding: '2px 8px',
                        cursor: 'pointer',
                        marginInline: 0,
                        '& .MuiTypography-root': {
                            margin: 'auto',
                            fontSize: '10px',
                        },
                        '&:hover': {
                            backgroundColor: selectedValue === option ? 'none' : colors.green[50],
                        },
                    }}
                />
            ))}
        </RadioGroup>

    );
};
export { GenderSelectRadioInput }