import {
  Box,
  Button,
  Chip,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { colors, typography } from "../../utils/styles";

interface ChooseContentProps {
  options: string[];
  setUserDetails: (details: any) => void;
  selectAll?: boolean;
  maxSelection?: number;
  name: string;
  initialValues?: string[];
}

const ChooseContent = ({
  options,
  setUserDetails,
  selectAll,
  maxSelection = options.length,
  name,
  initialValues = [],
}: ChooseContentProps) => {
  const [contents, setContent] = useState<string[]>(initialValues);

  const handleChipClick = (content: string) => {
    if (contents.includes(content)) {
      setContent(contents.filter((item: string) => content !== item));
    } else if (contents.length < maxSelection) {
      setContent((prevContent) => [...prevContent, content]);
    }
  };

  const handleSelectAllContent = () => {
    setContent(options.slice(0, maxSelection));
  };

  useEffect(() => {
    setUserDetails((prev: any) => ({ ...prev, [name]: contents }));
  }, [contents.length]);

  return (
    <Box>
      <div>
        <Stack
          direction="row"
          justifyContent="flex-start"
          gap={1}
          flexWrap="wrap"
          mt="16px"
        >
          {options.map((option: any) => (
            <Chip
              key={option}
              label={option}
              clickable
              onClick={() => handleChipClick(option)}
              sx={{
                backgroundColor: contents.includes(option)
                  ? colors.green[50]
                  : colors.white,
                color: colors.black[200],
                fontSize: "10px",
                padding: "2px 0",
                height: "24px",
                width: "95px",
                border: "1px solid",
                borderColor: contents.includes(option)
                  ? colors.green[200]
                  : colors.gray[50],
                borderRadius: "8px",
                "&:hover": {
                  background: contents.includes(option)
                    ? colors.green[50]
                    : colors.white,
                },
              }}
            />
          ))}
        </Stack>
      </div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt="24px"
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <img
            src="/Icons/tooltip_info.svg"
            style={{ height: "12px", width: "12px" }}
            alt="info"
          />
          <Typography variant="body2">
            Select {maxSelection !== options.length ? "maximum" : "minimum"} 3
            categories
          </Typography>
        </Box>
        {selectAll && (
          <Button
            sx={{
              color: colors.violet[50],
              fontWeight: typography.font_weight.bold,
              fontSize: typography.font_size.xs,
            }}
            onClick={handleSelectAllContent}
          >
            Select All
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export { ChooseContent };
