import React, { useEffect, useState } from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import { useModal } from "../../context/ModalContext";
import { severityType, UserFeedType } from "../../types";
import { useLocation } from "react-router-dom";
import userSubscriptionServiceApi from "../../api/user/subscriptionServiceApi";
import creatorSubscriptionServiceApi from "../../api/creator/subscriptionServiceApi";
import TipInsufficientbalance from "../../modals/InsufficientCoins/TipInsufficientbalance";
import { typography, colors } from "../../utils/styles";
import creatorWalletServiceApi from "../../api/creator/creatorWalletServiceApi";
import paymentServiceApi from "../../api/user/paymentServiceApi";
import useToast from "../../hooks/useToast";

const Container = styled(Box)<{ visible: boolean }>`
  display: flex;
  padding: 0px 16px 16px 16px;
  flex-direction: column;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  gap: 22px;
  height: ${({ visible }) => (visible ? "128px" : "0px")};
  overflow: hidden;
  transition: height 0.5s ease-in-out;
`;

const ChipsContainer = styled(Box)`
  display: flex;
  gap: 8px;
`;

const SingleChipContainer = styled(Box)<{ selected: boolean }>`
  display: flex;
  width: 51.561px;
  height: 31.55px;
  padding: 4px 16px;
  align-items: center;
  flex-shrink: 0;
  border: ${({ selected }) =>
    selected ? `1px solid ${colors.green[100]}` : `1px solid ${colors.black[200]}1A`};
  background-color: ${({ selected }) =>
    selected ? `${colors.green[50]}` : `${colors.white}`};
  cursor: pointer;
  border-radius: 8px;
`;

const ChipText = styled(Typography)`
  font-size: ${typography.font_size.sm}px;
`;

const SendTipButton = styled(Button)`
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${colors.violet[100]};
  background: ${colors.violet[100]};
  color: ${colors.white};
  text-align: center;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  max-height: 33.553px;
  &:hover {
    background: ${colors.violet[100]};
  }
`;
interface SendTipProps {
  item: UserFeedType;
  handleOnClickTipMe:()=>void;
  openSnackBar:(message:string, severity: severityType)=> void;
}
const SendTip: React.FC<SendTipProps> = ({ item, handleOnClickTipMe, openSnackBar}) => {
  const [selectedValue, setSelectedValue] = useState<number>(100);
  const [fromUser, setFromUser] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const { requestOpenModal, requestCloseModal } = useModal();
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const [availableYC, setAvailableYC] = useState("");

  const getAndUpdateWalletDetails = () => {
    try {
    if (userRole === "user") {
      paymentServiceApi.get_Wallet_Details().then((data) => {
        setAvailableYC(data.data.data.totalYc);
        setFromUser(data.data.data.userId);
      });
    } else if (userRole === "creator") {
      creatorWalletServiceApi.get_Wallet_Details().then((data) => {
        setAvailableYC(data.data.data.totalYc);
        setFromUser(data.data.data.userId);
      });
    } else {
      setAvailableYC("0");
    }
  } catch (error) {
    console.log(error);
  }
  };
  const chipValues = [100, 200, 300, 500];
  const handleChipClick = (value: number) => {
    setSelectedValue(value);
  };

  const afterSendTip = (status: number) => {
    if (status === 200) {
      openSnackBar("You have successfully tipped the creator.", "success");
      setSelectedValue(100);
      setIsVisible(false);
      setTimeout(()=>{
        handleOnClickTipMe();
      }, 1000)
      
    }
  };

  const onPayingForSubscription = async () => {
    try {
      if (userRole === "user") {
        const resp = await userSubscriptionServiceApi.payForSubscription({
          toUser: item.creator.userId,
          fromUser,
          serviceType: "TIPS",
          serviceId: item.id,
          amount: selectedValue,
        });
        afterSendTip(resp.data.status);
      } else if (userRole === "creator") {
        const resp = await creatorSubscriptionServiceApi.payForSubscription({
          toUser: item.creator.userId,
          fromUser,
          serviceId: item.id,
          serviceType: "TIPS",
          amount: selectedValue,
        });
        afterSendTip(resp.data.status);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleOnClickSendTipYcButton = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (selectedValue <= parseInt(availableYC)) {
      onPayingForSubscription();
    } else {
      requestOpenModal({
        name: "Insufficient balance modal",
        component: (
          <TipInsufficientbalance
            requestOpenModal={requestOpenModal}
            requestCloseModal={requestCloseModal}
            item={item}
            availableYC={availableYC}
          />
        ),
      });
    }
  };

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    getAndUpdateWalletDetails();
  }, []);

  return (
    <Container visible={isVisible}>
      <ChipsContainer>
        {chipValues.map((value) => (
          <SingleChipContainer
            key={value}
            selected={value === selectedValue}
            onClick={() => handleChipClick(value)}
          >
            <ChipText>{value}</ChipText>
          </SingleChipContainer>
        ))}
      </ChipsContainer>
      <SendTipButton
        disabled={selectedValue === null}
        onClick={handleOnClickSendTipYcButton}
      >
        {selectedValue ? `Send tip ${selectedValue}CC` : "Select a tip amount"}
      </SendTipButton>
    </Container>
  );
};

export default SendTip;
