import React from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { bottom_nav_routes, sidebar_routes } from "./utils/generateRoute";
import { CreatePost } from "./pages/CreatePost";
import LogIn from "./pages/LogIn/LogIn";
import SignUp from "./pages/SignUp/SignUp";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import { UserOnboardingPage } from "./pages/UserOnboarding";
import { CreatorOnboardingPage } from "./pages/RegisterForCreator";
import { EditCreatorProfile } from "./pages/EditProfile/EditCreatorProfile";
import { EditUserProfile } from "./pages/EditProfile/EditUserProfile";
import { Box } from "@mui/material";
import { CreatorOnboardingLayout } from "./layout/UserOnboardingLayout";
import CreatorProfileForUsers from "./pages/ProfileView/Creator/Creator";
import UsersFollowingPage from "./pages/SocialConnections/UsersConnections/Following";
import FollowingPage from "./pages/SocialConnections/CreatorsConnections/Following";
import FollowersPage from "./pages/SocialConnections/CreatorsConnections/Followers";
import SubscribersPage from "./pages/SocialConnections/CreatorsConnections/Subscribers";
import SubscriptionPage from "./pages/SocialConnections/UsersConnections/Subscription";
import CreatorsPosts from "./pages/ProfileView/Creator/CreatorsPosts/CreatorsPosts";
import CreatorSubscriptions from "./pages/ProfileView/Creator/CreatorSubscriptions/CreatorSubscriptions";
import InAppModal from "./components/InAppModal";
import Verification from "./components/SignUp/Verification";
import CreatorOnDemandRate from "./pages/CreatorOnDemandRate/CreatorOnDemandRate";
import CreatorSubscription from "./pages/CreatorSubscription/CreatorSubscription";
import SubscriptionPlanDetails from "./pages/CreatorSubscription/SubscriptionPlanDetails";

import { CreatorLayout, UserLayout } from "./layout/HomeLayout";
import { CreatorsProtectedRoute } from "./layout/CreatorProtectedRoutes";
import { UsersProtectedRoute } from "./layout/UsersProtectedRoutes";
import { AddCoins } from "./pages/Wallet/AddCoins";
import { WithdrawYC } from "./pages/Wallet/WithdrawYC";
import { BuySubscription } from "./pages/BuySubscription/BuySubscription";
import { KYC } from "./pages/Wallet/KYC";
import { PlanActivatedPage } from "./pages/BuySubscription/PlanActivated";

import CreatorsOnDemandRates from "./pages/ProfileView/Creator/CreatorsOnDemandRates/CreatorsOnDemandRates";
import CreatorBuySubscription from "./pages/ProfileView/Creator/CreatorBuySubscription/CreatorBuySubscription";

import UserTransactionPage from "./pages/Wallet/Transactions/UserTransactionPage";
import CreatorTransactionPage from "./pages/Wallet/Transactions/CreatorTransactionPage";
import { DetailedPurchasedContentView } from "./components/Gallery/DetailedPurchasedContent";
import PostEarnings from "./pages/PostEarnings/PostEarnings";
import SetAvailability from "./pages/Bookings/SetAvailability";
import CheckBookings from "./pages/Bookings/CheckBookings";
import CheckAvailability from "./pages/Bookings/CheckAvailability";
import ImageInput from "./components/PostImageFeature";
import { AddPostDescription } from "./components/PostImageFeature/AddPostDescription";
import BookingDetails from "./pages/BookSlot/BookingDetails";
import BookSlot from "./pages/BookSlot/BookSlot";
import VideoCropper from "./components/PostVideoFeature/VideoCropper";
import VideoInput from "./components/PostVideoFeature";
import TimerEnd from "./pages/BookSlot/TimerEnd";
import RescheduleSlot from "./pages/RescheduleBooking/RescheduleSlot";
import RescheduleDetails from "./pages/RescheduleBooking/RescheduleDetails";
import AccountPage from "./pages/SettingsPage/AccountPage";
import DeactivateAccountPage from "./pages/SettingsPage/DeactivateAccountPage";
import NotificationSettingsPage from "./pages/SettingsPage/NotificationSettingsPage";
import FAQSettings from "./pages/SettingsPage/FAQSettings";
import PrivacyPolicyPage from "./pages/SettingsPage/PrivacyPolicyPage";
import TermsAndConditionPage from "./pages/SettingsPage/TermsAndConditionPage";
import VideoCallJoin from "./pages/CallService/VideoCallJoin";
import AudioCallJoin from "./pages/CallService/AudioCallJoin";
import Chat from "./components/Messages/ChatPage";

export default function App() {
  return (
    <Box maxWidth="sm" m="auto">
      <BrowserRouter>
        <InAppModal />
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/signup/:referralCodeStr?" element={<SignUp />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/users-following" element={<UsersFollowingPage />} />
          <Route
            path="/following/:id"
            element={<FollowingPage isOwn={false} />}
          />
          <Route
            path="/followers/:id"
            element={<FollowersPage isOwn={false} />}
          />

          <Route path="/user" element={<UserLayout />}>
            {sidebar_routes}
            {bottom_nav_routes}
            <Route
              path="creator-profile-view/:id"
              element={<CreatorProfileForUsers />}
            />
            <Route
              path="gallery/explore"
              element={<DetailedPurchasedContentView userRole="user" />}
            />
          </Route>
          <Route path="/creator" element={<CreatorLayout />}>
            {sidebar_routes}
            {bottom_nav_routes}
            <Route
              path="creator-profile-view/:id"
              element={<CreatorProfileForUsers />}
            />
            <Route
              path="gallery/explore"
              element={<DetailedPurchasedContentView userRole="creator" />}
            />
          </Route>

          <Route path="/user" element={<UsersProtectedRoute />}>
            <Route path="edit/profile" element={<EditUserProfile />} />
            <Route path="wallet/add-coins" element={<AddCoins />} />
            <Route
              path="wallet/transactions"
              element={<UserTransactionPage />}
            />
            <Route path="buy-subscription" element={<BuySubscription />} />
            <Route path="subscription-added" element={<PlanActivatedPage />} />

            <Route path="creator-post-page/:id" element={<CreatorsPosts />} />

            <Route path="users-subscription" element={<SubscriptionPage />} />
            <Route path="messages/read-message/:toUser" element={<Chat />} />
            <Route
              path="check-creators-subscription-plan/:id"
              element={<CreatorSubscriptions />}
            />
            <Route
              path="check-creators-on-demand-plan/:id"
              element={<CreatorsOnDemandRates />}
            />
            <Route path="book-slot" element={<BookSlot />} />
            <Route path="book-slot/details" element={<BookingDetails />} />
            <Route path="time-expired" element={<TimerEnd />} />
            <Route path="reschedule-booking" element={<RescheduleSlot />} />
            <Route
              path="reschedule-slot/details"
              element={<RescheduleDetails />}
            />
            <Route path="settings/account" element={<AccountPage />} />

            <Route
              path="settings/account/deactivate"
              element={<DeactivateAccountPage />}
            />
            <Route
              path="settings/notification"
              element={<NotificationSettingsPage />}
            />

            <Route path="settings/FAQ" element={<FAQSettings />} />

            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />

            <Route
              path="terms-and-conditions"
              element={<TermsAndConditionPage />}
            />
          </Route>

          <Route path="/user/onboarding" element={<UserOnboardingPage />} />

          <Route path="/creator" element={<CreatorsProtectedRoute />}>
            <Route path="edit/profile" element={<EditCreatorProfile />} />
            <Route path="wallet/add-coins" element={<AddCoins />} />
            <Route
              path="wallet/transactions"
              element={<CreatorTransactionPage />}
            />
            <Route path="wallet/withdraw" element={<WithdrawYC />} />
            <Route path="buy-subscription" element={<BuySubscription />} />
            <Route path="wallet/kyc" element={<KYC />} />
            <Route
              path="creators-following"
              element={<FollowingPage isOwn={true} />}
            />
            <Route
              path="creators-followers"
              element={<FollowersPage isOwn={true} />}
            />
            <Route path="creators-subscribers" element={<SubscribersPage />} />

            <Route path="post-earnings" element={<PostEarnings />} />

            <Route
              path="bookings/set-availability"
              element={<SetAvailability />}
            />

            <Route
              path="bookings/check-availability"
              element={<CheckAvailability />}
            />
            <Route path="bookings/check-bookings" element={<CheckBookings />} />
            <Route
              path="check-creators-subscription-plan/:id"
              element={<CreatorSubscriptions />}
            />
            <Route
              path="check-creators-on-demand-plan/:id"
              element={<CreatorsOnDemandRates />}
            />
            <Route path="book-slot" element={<BookSlot />} />
            <Route path="book-slot/details" element={<BookingDetails />} />

            <Route path="creator-post-page/:id" element={<CreatorsPosts />} />
            <Route path="time-expired" element={<TimerEnd />} />
            <Route
              path="set-on-demand-rates"
              element={<CreatorOnDemandRate />}
            />
            <Route
              path="create-subscription-plan"
              element={<CreatorSubscription />}
            />
            <Route
              path="subscription-plan-details"
              element={<SubscriptionPlanDetails />}
            />
            <Route path="reschedule-booking" element={<RescheduleSlot />} />
            <Route
              path="reschedule-slot/details"
              element={<RescheduleDetails />}
            />

            <Route path="settings/account" element={<AccountPage />} />

            <Route
              path="settings/account/deactivate"
              element={<DeactivateAccountPage />}
            />
            <Route
              path="settings/notification"
              element={<NotificationSettingsPage />}
            />

            <Route path="settings/FAQ" element={<FAQSettings />} />

            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />

            <Route
              path="terms-and-conditions"
              element={<TermsAndConditionPage />}
              />
          </Route>

          <Route path="/creator-post-page/:id" element={<CreatorsPosts />} />

          <Route
            path="/buy-creators-subscription-plan"
            element={<CreatorBuySubscription />}
          />

          <Route path="/newPost" element={<CreatePost />} />
          <Route path="/" element={<CreatorOnboardingLayout />}>
            <Route
              path="register-for-creator"
              element={<CreatorOnboardingPage />}
            />
          </Route>

          <Route path="/emailVerification" element={<Verification />} />

          {/* creator's protected route */}
          {/* user's protected route */}
          <Route path="/test" element={<ImageInput />} />
          <Route path="/test/update" element={<AddPostDescription />} />
          <Route path="/video" element={<VideoInput />} />
          <Route path="/call/video/join/:roomId" element={<VideoCallJoin />} />
          <Route path="/call/audio/join/:roomId" element={<AudioCallJoin />} />
        </Routes>
      </BrowserRouter>
    </Box>
  );
}
