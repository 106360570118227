import React, { useState, useEffect } from "react";
import {
  AudioCallIcon,
  CalendarIcon,
  ClockSvgIcon,
  CloutCoinIcon,
  TextMessageIcon,
  VideoCallCameraIcon,
} from "../../../../assets/SvgIcons";
import {
  ConfirmedBookingsItemsContainer,
  Container,
  MonthContainer,
  MonthTitle,
  ContentContainer,
  ImageContainer,
  ContentMainSection,
  TitleAndHandleName,
  Title,
  HandleName,
  IncomingOutgoingMessage,
  SecondLineContainer,
  TypeContainer,
  TypeText,
  DurationContainer,
  DurationText,
  PriceContainer,
  PriceIcon,
  PriceText,
  ThirdLineContainer,
  DateContainer,
  DateText,
  TimeContainer,
  TimeText,
  FourthLineContainer,
  ViewDetailsText,
  ConfirmedText,
  BookingDetailsContainer,
  BookingDetailsTitle,
  ChargesTextContainer,
  ChargesText,
  ChargesValue,
  ServiceTypeContainer,
  ServiceTypeText,
  ServiceTypeValue,
  RescheduleButtonContainer,
  RescheduleButton,
  JoinButtonContainer,
  JoinButton,
  BookingInfoContainer,
  Avatar,
  CancelBookingButton,
} from "./CheckBookingsStyles";
import { GroupedBookings } from "../../../../types";
import NoBookings from "../NoBookings";
import { groupByMonth } from "../../../../utils/groupByMonth";
import { isCurrentTimeInRange } from "../../../../utils/checkTimeInRange";
import { formatLongDate } from "../../../../utils/formatDate";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { useLocation, useNavigate, Link } from "react-router-dom";
import creatorBookingServiceApi from "../../../../api/creator/bookingServiceApi";
import userBookingServiceApi from "../../../../api/user/bookingServiceApi";
import {
  cancelBooking,
  setConfirmedBookings,
} from "../../../../redux/bookingSlice";
import moment from "moment";
import { useSnackbar } from "../../../../context/SnackbarContext";
import creatorCallServiceApi from "../../../../api/call/creatorCallService";
import userCallServiceApi from "../../../../api/call/userCallService";
interface ConfirmedBookingsProps {}
const ConfirmedBookings: React.FC<ConfirmedBookingsProps> = ({}) => {
  const { confirmedBookings } = useSelector(
    (state: RootState) => state.bookings
  );
  const groupedBookings: GroupedBookings = groupByMonth(confirmedBookings);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const userRole = location.pathname.includes("creator") ? "creator" : "user";
  const fetchConfimedBookings = async () => {
    try {
      let response =
        userRole === "creator"
          ? await creatorBookingServiceApi.getConfirmedBookings("1", "20")
          : await userBookingServiceApi.getConfirmedBookings("1", "20");
      let data = response.data.data.data;
      dispatch(setConfirmedBookings(data));
    } catch (err) {
      console.error("Error in getting confirmed bookings", err);
    }
  };
  useEffect(() => {
    fetchConfimedBookings();
  }, [dispatch]);
  return (
    <ConfirmedBookingsItemsContainer>
      {confirmedBookings?.length ? (
        <>
          {Object.keys(groupedBookings).map((monthYear) => (
            <div key={monthYear}>
              <MonthContainer>
                <MonthTitle>{monthYear}</MonthTitle>
              </MonthContainer>
              {groupedBookings[monthYear].map((booking, index) => (
                <ConfirmedBookingsItem key={index} item={booking} />
              ))}
            </div>
          ))}
        </>
      ) : (
        <NoBookings
          title="No Bookings Yet"
          subtitle="Start scheduling your booking."
        />
      )}
    </ConfirmedBookingsItemsContainer>
  );
};
interface ConfirmedBookingsItemProps {
  item: any;
}
const ConfirmedBookingsItem: React.FC<ConfirmedBookingsItemProps> = ({
  item,
}) => {
  const [openDetails, setOpenDetails] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const userRole = location.pathname.includes("creator") ? "creator" : "user";
  const { openSnackBar } = useSnackbar();
  const handleOnClickViewDetails = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setOpenDetails(!openDetails);
  };
  const { displayName, profilePhotoUrl } = useSelector((state: RootState) =>
    userRole === "creator" ? state.creatorProfile.profile : state.user
  );
  let ownUserId: string | undefined;
  let ownRole: string | undefined;

  useSelector((state: RootState) => {
    ownUserId =
      userRole === "creator"
        ? state.creatorProfile.profile.userId
        : state.user.id;
  });

  if (ownUserId === item?.userId) {
    ownRole = "user";
  } else {
    ownRole = "creator";
  }

  const handleOnClickRescheduleButton = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    try {
      const response =
        userRole === "creator"
          ? await creatorBookingServiceApi.checkCanRescheduleBooking(
              item?.bookingId
            )
          : await userBookingServiceApi.checkCanRescheduleBooking(
              item?.bookingId
            );
      if (response.data.message === "Reschedule allowed") {
        let creatorName = item?.displayName;
        let creatorId = item?.creatorId ?? item?.userId;
        let serviceType =
          item.type === "Subscription Plan" ? "subscription" : "onDemand";
        let service =
          item?.serviceType === "AUDIOCALL"
            ? "Audio Call"
            : item?.serviceType === "VIDEOCALL"
            ? "Video Call"
            : "Direct Message";
        let minuteSlot = parseInt(item?.slotLength);
        let previousEndTime = item?.endTime;
        let previousStartTime = item?.startTime;
        let previousDate = item?.date;
        let bookedSlotId = item?.bookingId;
        navigate(
          `/${userRole}/reschedule-booking?bookingId=${item?.bookingId}&serviceType=${serviceType}&service=${service}&minuteSlot=${minuteSlot}&creatorId=${creatorId}&creatorName=${creatorName}&previousStartTime=${previousStartTime}&previousEndTime=${previousEndTime}&previousDate=${previousDate}&bookedSlotId=${bookedSlotId}`
        );
      }
    } catch (err) {
      openSnackBar("Reschedule not allowed more than once", "warning");
      console.error("Error in can reschedule booking", err);
    }
  };
  const handleOnClickCancelBooking = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    try {
      e.preventDefault();
      const payload = {
        status: "cancelled",
      };
      setOpenDetails(false);
      openSnackBar("Booking cancelled successfully", "success");
      dispatch(cancelBooking(item?.bookingId));
      const response =
        userRole === "creator"
          ? await creatorBookingServiceApi.updateBookingShowRequestStatus(
              item?.bookingId,
              payload
            )
          : await userBookingServiceApi.updateBookingShowRequestStatus(
              item?.bookingId,
              payload
            );
    } catch (err) {
      console.error("Failed to update show request status", err);
    }
  };

  const handleOnClickJoinButton = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const routePath =
      item?.serviceType === "AUDIOCALL"
        ? "audio"
        : item?.serviceType === "VIDEOCALL"
        ? "video"
        : "text-message";

    if (routePath === "text-message") {
      return;
    } else {
      const callPayload = {
        roomId: item?.bookingId,
        creatorId: item?.creatorId,
        userId: item?.userId,
        callType: routePath === "audio" ? "AUDIO" : "VIDEO",
      };

      try {
        if (ownRole === "creator") {
          await creatorCallServiceApi.startCall(callPayload);
        } else {
          await userCallServiceApi.startCall(callPayload);
        }

        navigate(
          `/call/${routePath}/join/${item?.bookingId}?userName=${displayName}&profilePhotoUrl=${profilePhotoUrl}&endTime=${item?.endTime}&userRole=${ownRole}`
        );
      } catch (error) {
        console.error("Failed to start the call:", error);
      }
    }
  };

  const visitProfile = (e: any) => {
    e.preventDefault();
    if (!item?.profileId) return;
    navigate(`/${userRole}/creator-profile-view/${item?.profileId}`);
  };

  return (
    <Container>
      <ContentContainer>
        <ImageContainer>
          <Avatar
            onClick={visitProfile}
            sx={{ width: "24px", height: "24px" }}
            src={item?.profilePic}
          />
        </ImageContainer>
        <ContentMainSection>
          <TitleAndHandleName onClick={visitProfile}>
            <Title>{item?.displayName} - </Title>
            <HandleName>
              {" "}
              {item.handleName ? `@${item.handleName}` : ""}
            </HandleName>
            {item?.label === "outgoing" ? (
              <IncomingOutgoingMessage>Outgoing</IncomingOutgoingMessage>
            ) : null}
          </TitleAndHandleName>
          <SecondLineContainer>
            <TypeContainer>
              {item.serviceType === "AUDIOCALL" ? (
                <AudioCallIcon />
              ) : item.serviceType === "VIDEOCALL" ? (
                <VideoCallCameraIcon />
              ) : (
                <TextMessageIcon />
              )}
              <TypeText>
                {item?.serviceType === "AUDIOCALL"
                  ? "Audio call"
                  : item?.serviceType === "VIDEOCALL"
                  ? "Video call"
                  : "Text message"}
              </TypeText>
            </TypeContainer>
            <DurationContainer>
              <ClockSvgIcon />
              <DurationText>{parseInt(item?.slotLength)} min</DurationText>
            </DurationContainer>
            {item.type !== "Subscription Plan" ? (
              <PriceContainer>
                <PriceIcon>
                  <CloutCoinIcon />
                </PriceIcon>
                <PriceText>
                  {item?.label === "outgoing" ? null : "+"}
                  {item?.amountInYc}
                </PriceText>
              </PriceContainer>
            ) : null}
          </SecondLineContainer>
          <ThirdLineContainer>
            <DateContainer>
              <CalendarIcon />
              <DateText>{formatLongDate(item.date)}</DateText>
            </DateContainer>
            <TimeContainer>
              <TimeText>
                {" |   "}
                {item?.startTime} - {item?.endTime}
              </TimeText>
            </TimeContainer>
          </ThirdLineContainer>
          <FourthLineContainer>
            <ViewDetailsText onClick={handleOnClickViewDetails}>
            {openDetails ? "Less Details" : "View Details"}
            </ViewDetailsText>
            <ConfirmedText>Booking Confirmed</ConfirmedText>
          </FourthLineContainer>
        </ContentMainSection>
        <JoinButtonContainer>
          {isCurrentTimeInRange(item.startTime, item.endTime, item.date) ? (
            <JoinButton onClick={handleOnClickJoinButton}>Join</JoinButton>
          ) : null}
        </JoinButtonContainer>
        <BookingDetailsContainer open={openDetails}>
          <BookingInfoContainer>
            <BookingDetailsTitle>Booking Details</BookingDetailsTitle>
            {item.amountInYc ? (
              <ChargesTextContainer>
                <ChargesText>
                   {item?.serviceType === "AUDIOCALL"
                    ? "Audio call"
                    : item?.serviceType === "VIDEOCALL"
                    ? "Video call"
                    : "Message"} Charges (per min)</ChargesText>
                <ChargesValue>
                  {item.amountInYc
                    ? item?.amountInYc / parseInt(item?.slotLength)
                    : ""}{" "}
                  CC
                </ChargesValue>
              </ChargesTextContainer>
            ) : (
              <ChargesTextContainer>
                <ChargesText>Plan expiry date</ChargesText>
                <ChargesValue>
                  {moment
                    .utc(item.planExpiryDate)
                    .local()
                    .format("DD MMM YYYY h:mm a")}
                </ChargesValue>
              </ChargesTextContainer>
            )}
            <ServiceTypeContainer>
              <ServiceTypeText>Type of Service</ServiceTypeText>
              <ServiceTypeValue>{item?.type}</ServiceTypeValue>
            </ServiceTypeContainer>
          </BookingInfoContainer>
          <RescheduleButtonContainer>
            <RescheduleButton onClick={handleOnClickRescheduleButton}>
              Reschedule booking
            </RescheduleButton>
            <CancelBookingButton onClick={handleOnClickCancelBooking}>
              Cancel Booking
            </CancelBookingButton>
          </RescheduleButtonContainer>
        </BookingDetailsContainer>
      </ContentContainer>
    </Container>
  );
};
export default ConfirmedBookings;
