import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Typography, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { colors, typography } from "../../utils/styles";
import userApi from "../../api/user/userApi";
import { updateCreator, updateUser } from "../../redux/appUserSlice";
import { setLoading } from "../../redux/creatorRegistrationSlice";

const SubmittedApplication = (props: any) => {
  const navigate = useNavigate();
  const replaceTokenAndNavigate = () => {
    // set creator access token in Local from recent response
    // const creatorAccessToken =
    // localStorage.setItem()
    navigate("/creator/home");
  };

  return (
    <Box>
      {!props.isRejected ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="20px"
        >
          <img
            src="/Images/submitted.svg"
            width="200px"
            height="150px"
            style={{ marginTop: "40px" }}
          />
          <Typography
            fontSize={typography.font_size["xl"]}
            fontWeight={typography.font_weight["bold"]}
          >
            Application Approved !
          </Typography>
          <Typography
            fontSize={typography.font_size["sm"]}
            color={colors.gray["80"]}
            textAlign="center"
          >
            You application has been approved
          </Typography>
          <Box position="absolute" bottom="0" width="100%">
            <Button
              type="submit"
              onClick={replaceTokenAndNavigate}
              variant="primary"
              fullWidth
            >
              Go to Home Page
            </Button>
          </Box>
        </Box>
      ) : (
        <ApplicationRejected />
      )}
    </Box>
  );
};

const ApplicationRejected: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="center" gap="20px">
        <img
          src="/Images/applicationRejected.svg"
          width="200px"
          height="150px"
          style={{ marginTop: "40px" }}
        />
        <Typography
          fontSize={typography.font_size["xl"]}
          fontWeight={typography.font_weight["bold"]}
        >
          Application Rejected !
        </Typography>
        <Typography
          fontSize={typography.font_size["sm"]}
          color={colors.gray["80"]}
          textAlign="center"
        >
          Your Application has been rejected due <br /> to -aknsclsklkslvkv sd
          reason
        </Typography>
        <Button
          type="submit"
          onClick={() => navigate("/user/home")}
          variant="primary"
          sx={{ position: "absolute", bottom: "0px" }}
          fullWidth
        >
          Return Home
        </Button>
      </Box>
    </Box>
  );
};

const SubmitApplication: React.FC = () => {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isRejected, setIsRejected] = useState(false);

  const handleSubmit = async () => {
    try {
      dispatch(setLoading(true));

      const response = await userApi.onboardingStepThree();

      dispatch(updateUser(response.data.user));
      dispatch(updateCreator(response.data.creator));

      const strToken: string = localStorage.getItem("accessToken") || "";

      localStorage.setItem("creatorAccessToken", strToken);

      setIsSubmitted(true);
      setIsRejected(false);
    } catch (error) {
      setIsRejected(true);
      console.error("Error during step three:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Box>
      {isSubmitted ? (
        <SubmittedApplication isRejected={isRejected} />
      ) : (
        <Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="20px"
          >
            <img
              src="/Images/submission.svg"
              width="200px"
              height="150px"
              style={{ marginTop: "40px" }}
            />
            <Typography
              fontSize={typography.font_size["xl"]}
              fontWeight={typography.font_weight["bold"]}
            >
              Submit Application
            </Typography>
            <Box display="flex" flexDirection="row">
              <Checkbox color="success" onChange={() => setChecked(!checked)} />
              <Typography
                fontSize={typography.font_size["base"]}
                fontWeight={typography.font_weight["regular"]}
                color={colors.black["200"]}
                marginTop="6px"
              >
                By checking this box I agree to Yaara <br />
                <a href="/terms-and-conditions">Terms and Conditions</a>
              </Typography>
            </Box>
            <Typography
              fontSize={typography.font_size["sm"]}
              color={colors.gray["80"]}
              textAlign="center"
            >
              Your application is submitted for approval!
            </Typography>
          </Box>

          <Box position="absolute" bottom="0" width="100%">
            <Button
              type="submit"
              onClick={handleSubmit}
              variant={checked ? "primary" : "disabled"}
              fullWidth
            >
              Confirm and Continue
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SubmitApplication;
