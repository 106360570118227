import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
  styled,
} from "@mui/material";
import { FilterIcon, SearchIcon } from "../../../assets/SvgIcons";
import { useNavigate } from "react-router-dom";
import { colors, typography } from "../../../utils/styles";
import FollowingCompTopbar from "./FollowingTopbar";
import UsersFollowingFilters from "./FollowingFilters";
import { useIntersectionObserver } from "../../../hooks/useInteractionObserver";
import { useModal } from "../../../context/ModalContext";
import UnfollowCreator from "../../../modals/UnfollowCreator";
import { Link } from "react-router-dom";

interface StateType {
  id: number;
  img: string;
  name: string;
  handle: string;
  type: string;
}

interface CreatorFollowingProps {
  text?: string;
  data: StateType[];
  fetchMoredata?: () => void;
  hasMore?: boolean;
  loading?: boolean;
}

const Container = styled(Box)`
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SecondContent = styled(Box)`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  width: 100%;
  position: sticky;
  top: 56px;
  background: white;
  z-index: 1;
`;

const BottomContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftContainer = styled(Box)`
  display: flex;
  padding-right: 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

const RightConatainer = styled(Box)`
  display: flex;
`;

const FollowingButton = styled(Box)`
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  color: #7262c2;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: 0.18px;
  padding: 4px 16px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #7262c2;
`;

const InputAdornmentDiv = styled(InputAdornment)`
  & p {
    color: rgba(0, 0, 0, 0.54);
    margin-right: 8px;
    color: #212121;
    font-size: 14px;
    font-style: normal;
    font-weight: ${typography.font_weight.semiBold} !important;
    line-height: ${typography.line_height.default};
    letter-spacing: 0.21px;
  }
`;

const InputWithAdornMent = styled(OutlinedInput)`
  height: 48px;
  padding: 4px 12px;
  border-radius: 8px;
  width: 100%;
  margin-top: 8px;
  color: #212121;
  font-style: normal;
  margin-top: 0;
  color: #9e9e9e;
  font-size: 16px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  letter-spacing: 0.24px;
  display: flex;
  align-items: center;
  flex: 1 0 0;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
`;

const BottomContent = styled(Box)`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
`;

interface StateType {
  id: number;
  img: string;
  name: string;
  handle: string;
  type: string;
}

const filterCompData = [
  {
    id: 1,
    text: "Username",
  },
  {
    id: 1,
    text: "Started Following",
  },
];

const UserFollowing: React.FC<CreatorFollowingProps> = ({
  text,
  data,
  fetchMoredata,
  hasMore,
  loading,
}) => {
  const [stateData, setStateData] = useState<StateType[]>(data);
  const [searchData, setsearchData] = useState<String>("");
  const [openFilter, setOpenFilter] = useState<Boolean>(false);
  const navigate = useNavigate();
  const { requestOpenModal, requestCloseModal } = useModal();
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
  });

  useEffect(() => {
    if (isIntersecting && hasMore && !loading) {
      fetchMoredata!();
    }
  }, [isIntersecting]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;
    setsearchData(value);
    setTimeout(() => {
      const newData = data
        .filter(
          (item) =>
            item.name.toLowerCase().includes(value.toLowerCase()) ||
            item.handle.toLowerCase().includes(value.toLowerCase())
        )
        .sort();
      setStateData(newData);
    }, 1000);
  };

  const handleOnClickFilterIcon = () => {
    setOpenFilter((prev) => !prev);
  };

  const handleOnClickGoBackButton = () => {
    navigate(-1);
  };

  const unfollowTheCreator = (item: StateType) => {
    let newStateData = stateData.filter((element) => element.id !== item.id);
    setStateData(newStateData);
  };

  const handleOnClickUnfollow = (item: StateType) => {
    requestOpenModal({
      name: "Unfollow Creator Modal",
      component: (
        <UnfollowCreator
          requestOpenModal={requestOpenModal}
          requestCloseModal={requestCloseModal}
          title={"Are you sure you want to unfollow this creator?"}
          subtitle={
            "You won't be able to get any content updates from this creator."
          }
          unfollowTheCreator={unfollowTheCreator}
          item={item}
        />
      ),
    });
  };

  useEffect(() => {
    setStateData(data);
  }, [data]);

  return (
    <Container>
      <FollowingCompTopbar
        title={text ? text : "Following"}
        handleOnClickGoBackButton={handleOnClickGoBackButton}
      />

      <SecondContent>
        <FormControl variant="outlined" fullWidth>
          <InputWithAdornMent
            fullWidth
            type="text"
            id="outlined-adornment-weight"
            value={searchData}
            placeholder="Search"
            onChange={handleChange}
            startAdornment={
              <InputAdornmentDiv position="start">
                <SearchIcon />
              </InputAdornmentDiv>
            }
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </FormControl>

        <Button
          sx={{ minWidth: "0", padding: "0" }}
          onClick={handleOnClickFilterIcon}
        >
          <FilterIcon />
        </Button>

        {openFilter && (
          <UsersFollowingFilters
            handleOnClickFilterIcon={handleOnClickFilterIcon}
            data={filterCompData}
          />
        )}
      </SecondContent>

      <BottomContentContainer>
        {stateData.length > 0 ? (
          stateData.map((item, index) => (
            <BottomContent key={index}>
              <LeftContainer>
                <Box sx={{ position: "relative" }}>
                  <Avatar
                    sx={{ width: "40px", height: "40px" }}
                    alt="user"
                    src={item.img}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: typography.font_weight.semiBold,
                      color: colors.black[200],
                      letterSpacing: "0.21px",
                      fontFamily: "Poppins",
                    }}
                  >
                    <Link
                      to={
                        item.type === "creator"
                          ? `/user/creator-profile-view/${item.id}`
                          : `#`
                      }
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      {item.name}
                    </Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: typography.font_weight.regular,
                      color: colors.gray[700],
                      letterSpacing: "0.18px",
                      fontFamily: "Poppins",
                    }}
                  >
                    {item.handle}
                  </Typography>
                </Box>
              </LeftContainer>
              {item.type === "creator" && (
                <RightConatainer>
                  <FollowingButton onClick={() => handleOnClickUnfollow(item)}>
                    Following
                  </FollowingButton>
                </RightConatainer>
              )}
              {hasMore && <div ref={ref} style={{ height: "1rem" }} />}
            </BottomContent>
          ))
        ) : (!loading && stateData.length === 0) ||
          (!loading && data.length === 0) ? (
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            No creators found.
          </Typography>
        ) : null}
        {loading && <CircularProgress />}
      </BottomContentContainer>
    </Container>
  );
};

export default UserFollowing;
