import React, { useState, useEffect, useRef, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import Hls from "hls.js";
import {
  Box,
  styled,
  Button,
  Typography,
  Slider,
} from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { colors, typography } from "../../utils/styles";
import { DollarCaseIcon, FullScreenIcon } from "../../assets/SvgIcons";
import { useVideo } from "../../context/VideoContext";
import { PostContent, severityType, UserFeedType } from "../../types";
import { useModal } from "../../context/ModalContext";
import BuyPost from "../../modals/BuyPost/BuyPost";
import { getContentInfo } from "../../services/contentInfo";

const StyledSlider = styled(Slider)`
  padding: 0 !important;
  display: flex;
  .MuiSlider-thumb {
    background-color: #1db954;
    border: 2px solid #fff;
    transition: width 0.2s ease-in-out, height 0.2s ease-in-out,
      transform 0.2s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute; 
    top: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    &.paused {
      width: 9px;
      height: 9px;
    }
    &:hover,
    &:focus {
      width: 12px;
      height: 12px;
      transform: translate(-50%, -50%) scale(1.2); 
    }
  }
  .MuiSlider-track,
  .MuiSlider-rail {
    height: 4px;
    border-radius: 4px;
  }
  .MuiSlider-track {
    background-color: #2e7d32;
  }
  .MuiSlider-rail {
    background-color: #e0e0e0;
  }
  &:hover .MuiSlider-thumb {
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%) scale(1.2);
  }
  &:not(:hover) .MuiSlider-thumb {
    transition: width 1s ease, height 1s ease;
    width: 0;
    height: 0;
  }
`;
const Container = styled(Box)`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 2px;
  overflow: hidden;
`;
const LockedContentScreenContainer = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    ${colors.violet[100]} 0%,
    rgba(84, 71, 150, 0.38) 100%
  );
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const LockedContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;
const LockedButtonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
`;
const LockedButton = styled(Button)`
  display: flex;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid ${colors.violet[100]};
  background: ${colors.violet[100]};
  color: ${colors.white};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const PreviewAgainButton = styled(Button)`
  display: flex;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: none;
  color: ${colors.violet[100]};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
  &:hover {
    border: none;
  }
`;
const Text = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const ProgressBarContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;
const TimerContainer = styled(Box)`
  display: flex;
  align-items: center;
`;
const TimeDuration = styled(Typography)`
  color: ${colors.white};
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const VideoPropertiesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  opacity: 0.7;
  background: ${colors.black[200]};
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 48px;
  justify-content: flex-start;
  padding: 0;
  overflow: visible;
`;
const VideoPropertiesContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;
  padding-top: 24px;
  padding-left: 8px;
`;
const VideoLeftProperty = styled(Box)``;
const VideoRightProperty = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const NoPreview = styled(Typography)`
  color: ${colors.gray[500]};
  text-align: center;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
interface VideoPostTwoProps {
  contentItem: PostContent;
  post: UserFeedType;
  freePost: boolean;
  price: number | null | undefined;
  subscriptionPost: boolean | null | undefined;
  lockedPost: boolean;
  openSnackBar: (message: string, severity: severityType) => void;
  handleLoading?:()=> void;
}
const UserFeedVideoPost: React.FC<VideoPostTwoProps> = ({
  contentItem,
  post,
  freePost,
  lockedPost,
  openSnackBar,
  handleLoading
}) => {
  const { playVideo, mute, toggleMute } = useVideo();
  const [item, setItem] = useState<PostContent>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [manualPause, setManualPause] = useState(false);
  const [opacity, setOpacity] = useState(false);
  const [loading, setLoading] = useState(true);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const hls = useRef<Hls | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [previewPlay, setPreviewPlay] = useState<boolean>(false);
  const { ref, inView } = useInView({
    threshold: 0.85,
    triggerOnce: false,
  });
  const { requestOpenModal, requestCloseModal } = useModal();
  const handlePlayPause = useCallback(() => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video
          .play()
          .then(() => {
            setIsPlaying(true);
            setManualPause(false);
          })
          .catch((error) => {
            console.error("Play error:", error);
            setIsPlaying(false);
          });
      } else {
        video.pause();
        setIsPlaying(false);
        setManualPause(true);
      }
      setOpacity(true);
      setTimeout(() => {
        setOpacity(false);
      }, 2000);
    }
  }, []);
  const handleMuteIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    toggleMute();
  };
  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
      const progress =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(progress);
    }
  };
  const handleProgressChange = (_: Event, newValue: number | number[]) => {
    if (videoRef.current && typeof newValue === "number") {
      const time = (newValue / 100) * videoRef.current.duration;
      videoRef.current.currentTime = time;
      setProgress(newValue);
    }
  };
  const formatTime = (timeInSeconds: number): string => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };
  const handleOnClickUnlockButton = () => {
    requestOpenModal({
      name: "buy-post-modal",
      component: (
        <BuyPost
          requestOpenModal={requestOpenModal}
          requestCloseModal={requestCloseModal}
          post={post}
          openSnackBar={openSnackBar}
        />
      ),
    });
  };

  const handleLockScreenClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    return null;
  };
  const handlePreviewAgain = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const video = videoRef.current;
    if (video) {
      setPreviewPlay(true);
      playVideo(video);
      setDuration(video.duration);
      playVideo(video);
      video
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error("Play error:", error);
          setIsPlaying(false);
        });
    }
  };

  const handleFullScreenIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    return
  };

  useEffect(() => {
    if (contentItem) {
      setItem(contentItem);
    }
  }, [contentItem]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      if (Hls.isSupported()) {
        hls.current = new Hls();
        if (lockedPost) {
          hls.current.loadSource(item?.previewUrl ?? "");
        } else {
          hls.current.loadSource(item?.processedUrl ?? "");
        }

        hls.current.attachMedia(video);

        hls.current.on(Hls.Events.MANIFEST_PARSED, () => {});

        hls.current.on(Hls.Events.ERROR, (event, data) => {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                hls.current?.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                hls.current?.recoverMediaError();
                break;
              default:
                hls.current?.destroy();
                break;
            }
          }
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        if (lockedPost) {
          const videoSrc = item?.previewUrl ?? "";
          video.src = videoSrc;
          setDuration(video.duration);
       
        } else {
          const videoSrc = item?.processedUrl ?? "";
          video.src = videoSrc;
          setDuration(video.duration);
          
        }

        video.addEventListener("loadedmetadata", () => {
          setLoading(false);
          setDuration(video.duration);
        
        });
      }
    }

    return () => {
      hls.current?.destroy();
    };
  }, [item]);

  useEffect(() => {
    const video = videoRef.current;
    const handleVideoEnded = () => {
      if (lockedPost) {
        setPreviewPlay(false);
      }
    };
    if (video) {
      video.addEventListener("ended", handleVideoEnded);
    }
    return () => {
      if (video) {
        video.removeEventListener("ended", handleVideoEnded);
      }
    };
  }, []);
  
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.muted = mute;
      if (inView && !manualPause) {
        setTimeout(() => {
          if(item?.previewUrl || item?.processedUrl){
            setPreviewPlay(true)
            setDuration(video.duration);
            playVideo(video);
            video
              .play()
              .then(() => {
                setIsPlaying(true);
              })
              .catch((error) => {
                console.error("Play error:", error);
                setIsPlaying(false);
              });
          }
        }, 1000);
      } else if (!inView) {
        video.pause();
        setIsPlaying(false);
      }
    }
  }, [inView, playVideo, mute, manualPause]);

  return (
    <Container className="video_container" onClick={handlePlayPause} ref={ref}>
      <Box sx={{ position: "relative" }}>
        <video
          ref={videoRef}
          muted={mute}
          width="100%"
          height="100%"
          playsInline
          style={{
            display: lockedPost && !previewPlay ? "none" : "block",
            maxHeight: "72vh",
            objectFit: "contain",
            background:"black",
            aspectRatio: "1",
          }}
          preload="load"
          onTimeUpdate={handleTimeUpdate}
        />
        {videoRef?.current?.duration ? (
          <VideoPropertiesContainer>
            <ProgressBarContainer>
              <StyledSlider
                value={progress}
                onChange={handleProgressChange}
                aria-labelledby="video-progress-slider"
                sx={{ color: "#1db954" }}
              />
            </ProgressBarContainer>

            <VideoPropertiesContent>
              <VideoLeftProperty>
                <TimerContainer>
                  <TimeDuration>
                    {formatTime(currentTime)} / {formatTime(duration)}
                  </TimeDuration>
                </TimerContainer>
              </VideoLeftProperty>

              <VideoRightProperty>
                <button
                  onClick={handleMuteIconClick}
                  style={{ background: colors.gray[500] }}
                  className="speaker_icon_button"
                >
                  {mute ? (
                    <VolumeOffIcon sx={{ width: ".8em", height: ".8em" }} />
                  ) : (
                    <VolumeUpIcon sx={{ width: ".8em", height: ".8em" }} />
                  )}
                </button>

                <button
                  onClick={handleFullScreenIconClick}
                  className="speaker_icon_button"
                  style={{ background: colors.gray[500], marginBottom: "1px" }}
                >
                  <FullScreenIcon />
                </button>
              </VideoRightProperty>
            </VideoPropertiesContent>
          </VideoPropertiesContainer>
        ) : null}
      </Box>

      <button
        className="play_pause_button"
        style={{ opacity: opacity ? "1" : "0" }}
      >
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </button>

      {lockedPost && !previewPlay && (
        <LockedContentScreenContainer onClick={handleLockScreenClick}>
          <LockedContent>
            <LockedButtonContainer>
              <LockedButton
                onClick={handleOnClickUnlockButton}
                variant="contained"
                color="primary"
              >
                <DollarCaseIcon />
                Unlock
              </LockedButton>
              <Text>Buy {getContentInfo(post)} for CC {post?.price}</Text>
              {item?.previewUrl ? (
                <PreviewAgainButton onClick={handlePreviewAgain}>
                  Preview Again
                </PreviewAgainButton>
              ) : (
                <NoPreview>No Preview Available</NoPreview>
              )}
            </LockedButtonContainer>
          </LockedContent>
        </LockedContentScreenContainer>
      )}
    </Container>
  );
};

export default React.memo(UserFeedVideoPost);
