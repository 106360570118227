import { Box, Button, Divider, Stack, TextField, Typography } from "@mui/material"
import { typography } from "../../utils/styles"
import styled from "@emotion/styled"
import { PageTagComponent } from "../../components/Common/PageTagComponent"

const BorderedContainer = styled(Box)`
    border: .25px solid #7262C2;
    border-radius: 6px;
    width:100%;
    padding: 8px 16px
`

const WithdrawYC = () =>{
    return(
        <Box height='100dvh'>
            <PageTagComponent>Withdraw</PageTagComponent>
            <Box display='flex' flexDirection='column' alignItems='center' px="16px">
                <Box mb="20px">
                    <Typography textAlign='center' fontSize={typography.font_size.base} fontWeight={typography.font_weight.bold}>Current Balance :11k</Typography>
                    <Typography textAlign='center' fontSize={typography.font_size.xxs}>Corresponding Value :1100</Typography>
                </Box>
                <BorderedContainer>
                    <Box>
                        <Stack direction='row' justifyContent='space-between' py='8px'>
                            <Typography fontSize={typography.font_size.sm}>Locked Amount</Typography>
                            <Typography>YC 6000</Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='space-between' py='8px'>
                            <Typography fontSize={typography.font_size.sm}>Referral Bonus</Typography>
                            <Typography>YC 6000</Typography>
                        </Stack>
                    </Box>
                </BorderedContainer>
                <Divider sx={{width:'90%', my:'16px',color:'rgba(33, 33, 33, 0.1)'}}/>
                <BorderedContainer>
                <Stack direction='row' justifyContent='space-between' py='8px'>
                            <Typography fontSize={typography.font_size.sm}>Available for withdrawal</Typography>
                            <Typography>YC 6000</Typography>
                        </Stack>
                        <Stack direction='row' mt="24px" justifyContent='space-between'>
                            <Box>
                                <Typography fontSize={typography.font_size.sm} fontWeight={typography.font_weight.bold}>Enter Amount to Withdraw</Typography>
                                <Typography fontSize={typography.font_size.xxs}>Minimum of 10000YC can be withdrawan</Typography>
                            </Box>
                            <TextField sx={{width:'100px'}}/>
                        </Stack>
                </BorderedContainer>
            </Box>
            <Box maxWidth='sm' p="8px" display='flex' flexDirection={'row'} justifyContent='space-between' sx={{ position: 'fixed', bottom: 0, boxShadow: '0px -4px 8px 0px rgba(0, 0, 0, 0.08)', width: "100%" }}>
            <Stack direction='column'>
            <Typography fontWeight={typography.font_weight.bold} fontSize={typography.font_size.lg}>00</Typography>
            <Typography>Grand Total</Typography>
        </Stack>
                <Button variant="primary" sx={{ width: "175px" }}>Continue</Button>
            </Box>
        </Box>
    )
}   
export {WithdrawYC}