import React, {useEffect} from 'react';
import {
  Box,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Accordion,
  styled
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PageTagComponent } from '../Common/PageTagComponent';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { useLocation } from 'react-router-dom';
import creatorSettingsApi from '../../api/settings/creatorSettingsApi';
import userSettingsApi from '../../api/settings/userSettingsApi';
import { setfaqList } from '../../redux/settingsSlice';
import { typography, colors } from '../../utils/styles';

const Container = styled(Box)`
  width: 100%;
  height: 100%;
`;
const Header = styled(Box)``;
const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  padding-left: 16px;
`;
const ItemContainer = styled(Accordion)`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: none; 
  border: none;
  &::before {
    display: none; 
  };
  &.MuiAccordion-root:last-of-type {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  };
  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  border-bottom: 1px solid rgba(33, 33, 33, 0.20);
`;
const ItemSummary = styled(AccordionSummary)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-shadow: none;
  &.MuiAccordionSummary-content {
    margin: 0;
  }
`;
const ItemSummaryText = styled(Typography)`
  color: ${colors.violet[100]};
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;
const ItemDetails = styled(AccordionDetails)``;
const ItemDetailsText = styled(Typography)`
  color: ${colors.black[200]};
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.regular};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const CloutFAQ = () => {
  const dispatch=useDispatch<AppDispatch>();
    const location=useLocation();
    const userRole=location.pathname.includes("user") ? "user" : "creator";
    const {faqList}= useSelector((state:RootState)=>state.settings);
  const fetchFaqList=async()=>{
    try{
        let response= userRole==="creator" ? await creatorSettingsApi.getFaqList() : await userSettingsApi.getFaqList();
        console.log(response.data.data);
        dispatch(setfaqList(response.data.data));
    }
    catch(err){
        console.error("err");
    }
}
useEffect(()=>{
        if(faqList.length===0){
            fetchFaqList()
        }
}, [])
  return (
    <Container>
      <Header>
        <PageTagComponent>
          FAQ
        </PageTagComponent> 
      </Header>
      <Content>
        {faqList?.map((faq, index) => (
          <ItemContainer key={index}>
            <ItemSummary
              expandIcon={<ExpandMoreIcon color="primary" width="24px" height="24px" />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <ItemSummaryText>{faq.question}</ItemSummaryText>
            </ItemSummary>
            <ItemDetails>
              <ItemDetailsText>{faq.answer}</ItemDetailsText>
            </ItemDetails>
          </ItemContainer>
        ))}
      </Content>
    </Container>
  );
};

export default CloutFAQ;
