import React from "react";
import { Box } from "@mui/material";

export default function SuccessLogo(props: any) {
  return (
    <Box>
      <img
        src="/Images/Illustration_loving.svg"
        width={props.width || "150px"}
        height={props.height || "150px"}
      />
    </Box>
  );
}
