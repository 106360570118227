import { Box, Button, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DollarCaseIcon } from "../../assets/SvgIcons";
import { typography, colors } from "../../utils/styles";
import { PostContent, severityType, UserFeedType } from "../../types";
import BuyPost from "../../modals/BuyPost/BuyPost";
import { useModal } from "../../context/ModalContext";
import { getContentInfo } from "../../services/contentInfo";

const Container = styled(Box)`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 2px;
  overflow: hidden;
`;

const Image = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
`;

const LockedContentScreenContainer = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    ${colors.violet[100]} 0%,
    rgba(84, 71, 150, 0.38) 100%
  );
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LockedContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const LockedButtonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    gap: 18px;
`;

const LockedButton = styled(Button)`
  display: flex;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid ${colors.violet[100]};
  background: ${colors.violet[100]};
  color: ${colors.white};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;

const Text = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;

interface ImagePostProps {
  item: PostContent;
  post: UserFeedType;
  freePost: boolean;
  price: number | null | undefined;
  subscriptionPost: boolean | null | undefined;
  lockedPost: boolean;
  openSnackBar:(message:string, severity: severityType)=> void;
  handleLoading?:()=>void;
}

const UserFeedImagePost: React.FC<ImagePostProps> = ({
  item,
  post,
  freePost,
  price,
  subscriptionPost,
  lockedPost,
  openSnackBar,
  handleLoading
}) => {
  const [imagePost, setImagePost] = useState<PostContent>();
  const { requestOpenModal, requestCloseModal } = useModal();
  const imageRef = React.useRef<HTMLImageElement>(null);

  const handleOnClickUnlockButton = () => {
    requestOpenModal({
      name: "buy-post-modal",
      component: (
        <BuyPost
          requestOpenModal={requestOpenModal}
          requestCloseModal={requestCloseModal}
          post={post}
          openSnackBar={openSnackBar}
        />
      ),
    });
  };

  useEffect(() => {
    if (item) {
      setImagePost(item);
    }
  }, [item]);


  return (
    <Container>
      {imagePost && imagePost.processedUrl ? (
        <Image
          ref={imageRef}
          src={imagePost.processedUrl}
          alt="image"
          style={{ opacity: lockedPost ? "0" : "1" }}
        />
      ) : null}

      {lockedPost ? (
        <LockedContentScreenContainer>
          <LockedContent>
            <LockedButtonContainer>
              <LockedButton onClick={handleOnClickUnlockButton}>
                <DollarCaseIcon />
                Unlock
              </LockedButton>
            </LockedButtonContainer>
            <Text>Buy {getContentInfo(post)} for CC {post?.price}</Text>
          </LockedContent>
        </LockedContentScreenContainer>
      ) : null}
    </Container>
  );
};

export default UserFeedImagePost;
