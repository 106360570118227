import { ApiService } from '../ApiClient';
import {creatorAxiosInstance} from '../axiosInstance';
import "../interceptors/creatorAuthInterceptors";


class CreatorsLeaderboardServiceApi extends ApiService {
  constructor() {
    super(creatorAxiosInstance);
  }
  getTopFollowedCreators() {
    return this.axios.get(`/creator/leaderboard/follower`);
  }
  getTopEarningCreators(){
    return this.axios.get(`/creator/leaderboard/earning`);
  }
}

export default new CreatorsLeaderboardServiceApi();