import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import { typography } from "../../../utils/styles";
import Carousel from "react-material-ui-carousel";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { setFiles } from "../../../redux/creatorContentSlice";
import cloneDeep from "lodash/cloneDeep";
import { uploadContentFiles } from "../../../services/uploadToS3";
import { getFileName, getKeyForS3 } from "../../../utils/creatorContentPayload";

const PreviewVideos = () => {
  const dispatch = useDispatch();

  const imageRef = useRef<HTMLInputElement>(null);

  const files = useSelector((state: any) => state.postContent.files);
  const { id: userId } = useSelector((state: any) => state.user);

  const isSelected = files.some((file: any) => file.selectedFree === true);

  const uploadImageFileHandler = async (event: any) => {
    try {
      const input = event.target;

      if (input.files.length > 1) {
        alert("You can only upload a maximum of 1 image.");
        input.value = "";
        return;
      }

      const uploadedFile: any = await Promise.all(
        Object.values(input.files).filter((file: any) => {
          if (file.size > 5 * 1024 * 1024) {
            alert(
              `File ${file.name} is larger than 5MB and will not be uploaded.`
            );
            return false;
          }
          return true;
        })
      );

      const fileName = getFileName(uploadedFile[0]);

      const key = getKeyForS3("photo", userId, fileName);

      const s3Response = await uploadContentFiles(key, uploadedFile[0]);

      let filesCopy = cloneDeep(files);
      filesCopy[0].videoCoverPhoto = s3Response.url;
      dispatch(setFiles(filesCopy));
    } catch (e) {
      console.log("error while files uploading", e);
    }
  };

  return (
    <Box>
      {isSelected && (
        <Carousel
          autoPlay={false}
          navButtonsAlwaysInvisible
          stopAutoPlayOnHover
          swipe={false}
        >
          {files.map((item: any, i: any) => {
            return (
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: 0,
                  overflow: "hidden",
                  paddingTop: "100%",
                }}
              >
                <Box>
                  <video
                    width="100%"
                    height="100%"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit:
                        item.objectFitType === "none" ? "cover" : "contain",
                    }}
                    controls
                    src={item.trimmedUrl}
                  ></video>
                </Box>
              </Box>
            );
          })}
        </Carousel>
      )}

      <Box mx="16px" mt="24px">
        <input
          ref={imageRef}
          hidden
          id="accept-photo"
          accept=".jpg, .jpeg, .png, .gif, .svg,"
          type="file"
          onChange={(e) => uploadImageFileHandler(e)}
        />

        <Typography fontSize={typography.font_size["base"]} marginRight="30px">
          Choose Cover Photo
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          mt="8px"
        >
          <Box
            width="100px"
            height="100px"
            border="1px solid gray"
            borderRadius="12px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => imageRef.current?.click?.()}
            sx={{ cursor: "pointer" }}
          >
            <AddCircleOutlineOutlinedIcon />
          </Box>

          {files[0]?.videoCoverPhoto && (
            <Box>
              <img
                src={files[0]?.videoCoverPhoto}
                alt=""
                width="100px"
                height="100px"
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { PreviewVideos };
