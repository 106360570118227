import React, {useState} from 'react'
import { Box, styled, Typography, Tab, Tabs, } from "@mui/material";
import { PageTagComponent } from '../../../Common/PageTagComponent';
import { useLocation, useNavigate } from "react-router-dom";
import { typography, colors } from '../../../../utils/styles';
import ConfirmedBookings from './ConfirmedBookings';
import PendingBookings from './PendingBookings';
import HistoryBookings from './HistoryBookings';

const Container = styled(Box)<{isUser: boolean}>`
  position: relative;
  width: 100%;
  height: ${({ isUser })=>(isUser ? "100%" : "100vh")};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const SetAvailabilityHeader = styled(Box)``;
const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  overflow-y: auto;
  align-self: stretch;
  width: 100%;
`;
const TabContainer = styled(Box)`
  border-bottom: 2px solid ${colors.gray[300]};
  padding: 0px 8px;
`;
const TabsItems = styled(Tabs)({
  "& .MuiTabs-indicator": {
    height: "8px",
    borderRadius: "12px",
    bottom: "-5px",
    backgroundColor: colors.brand_primary,
  },
});
const TabItem = styled(Tab)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
`;
const TabMainContainer = styled(Box)`
  display: flex;
  position: sticky;
  top: 0;
  align-items: flex-start;
  align-self: stretch;
  background: ${colors.white};
  z-index: 12;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
`;
const LabelContainer = styled(Box)``;
const LabelText = styled(Typography)`
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;

const contentTabs = {
    CONFIRMED: "Confirmed",
    PENDING: "Pending",
    HISTORY: "History",
  };

const CheckCreatorBookings = () => {
    const [value, setValue] = useState(contentTabs.CONFIRMED);
  const [activeTab, setActiveTab] = useState(contentTabs.CONFIRMED);
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
    const navigate = useNavigate();
    const handleOnClickBackButton = () => {
          navigate(-1);
      };
      const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        event.preventDefault();
        setValue(newValue);
        setTimeout(() => {
          setActiveTab(newValue);
        }, 300);
      };
      const ConfirmedLabel = () => {
        return (
          <LabelContainer>
            <LabelText
              color="textSecondary"
              sx={{
                color: value === contentTabs.CONFIRMED ? colors.violet[100] : "",
              }}
            >
              {contentTabs.CONFIRMED}
            </LabelText>
          </LabelContainer>
        );
      };
      const PendingLabel = () => {
        return (
          <LabelContainer>
            <LabelText
              sx={{
                color: value === contentTabs.PENDING ? colors.violet[100] : "",
              }}
            >
              {contentTabs.PENDING}
            </LabelText>
          </LabelContainer>
        );
      };
      const HistoryLabel = () => {
        return (
          <LabelContainer>
            <LabelText
              sx={{
                color: value === contentTabs.HISTORY ? colors.violet[100] : "",
              }}
            >
              {contentTabs.HISTORY}
            </LabelText>
          </LabelContainer>
        );
      };
  return (
        <Container isUser={userRole==="creator" ? false : true}>
          {
            userRole==="creator" ? 
            <SetAvailabilityHeader>
            <PageTagComponent handleBack={handleOnClickBackButton}>
                Your Bookings
            </PageTagComponent>
          </SetAvailabilityHeader> : null
          }
      <TabMainContainer>
        <TabContainer sx={{ width: "100%" }}>
          <TabsItems
            value={value}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
          >
            <TabItem
              value={contentTabs.CONFIRMED}
              label={<ConfirmedLabel />}
            />
            <TabItem value={contentTabs.PENDING} label={<PendingLabel />} />
            <TabItem value={contentTabs.HISTORY} label={<HistoryLabel />} />
          </TabsItems>
        </TabContainer>
      </TabMainContainer>

          {
            activeTab===contentTabs.CONFIRMED ? (
              <Content>
                <ConfirmedBookings/>
              </Content>
            ):  activeTab===contentTabs.PENDING ? (
              <Content>
              <PendingBookings/>
            </Content>
            ): activeTab===contentTabs.HISTORY ? (
              <Content>
                <HistoryBookings/>
              </Content>
            ): null
          }
        </Container>
  )
}

export default CheckCreatorBookings