import React, { useState } from "react";

import {
  Box,
  TextField,
  Tooltip,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  InputAdornment,
  IconButton,
  InputLabel,
  Avatar,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "./Card";
import { useNavigate } from "react-router-dom";
import {
  clearPostContent,
  setMakeItFree,
  setOtherPreferredContent,
  setPostDescription,
  setPreferredContent,
  setPostAmount,
  setAddSubscription
} from "../../../redux/creatorContentSlice";
import { colors, typography } from "../../../utils/styles";
import { creatorContentPayload } from "../../../utils/creatorContentPayload";
import { setLoading } from "../../../redux/creatorContentSlice";
import creatorContentApi from "../../../api/creator/creatorContentApi";
import { STATUS,Status } from "../../../constants/statusConstants";
import useToast from "../../../hooks/useToast";

interface FormProps {
  files?: [] | undefined;
}

const PreferredContentType = [
  { label: "Fashion", value: "fashion" },
  { label: "Beauty", value: "beauty" },
  { label: "Environment", value: "environment" },
];

const postCategory = [
  { label: "Fashion", value: "fashion" },
  { label: "Beauty", value: "beauty" },
  { label: "Environment", value: "environment" },
  { label: "Educational", value: "educational" },
  { label: "Fitness", value: "fitness" },
  { label: "Wellness", value: "wellness" },
  { label: "Food", value: "food" },
  { label: "Cooking", value: "cooking" },
  { label: "Gaming", value: "gaming" },
  { label: "Travel", value: "travel" },
  { label: "Parenting", value: "parenting" },
  { label: "Lifestyle", value: "lifestyle" },
  { label: "Technology", value: "technology" },
  { label: "Gadget", value: "gadget" },
  { label: "DIY/Craft", value: "diy/craft" },
];

const PostForm: React.FC<FormProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isPosted, setIsPosted] = useState<Status>(STATUS.IDLE);
  const {ToastMessage,handleOpenToast} = useToast()

  const files = useSelector((state: any) => state.postContent.files);
  const {
    preferredContent,
    otherPreferredContent,
    postDescription,
    makeItFree,
    addSubscription,
    postAmount,
  } = useSelector((state: any) => state.postContent);


  const postContent = useSelector((state: any) => state.postContent);
  const userInfo = useSelector((state: any) => state.user);

  const updatePostCategories = (isSelected: boolean, item: any) => {
    if (isSelected) {
      dispatch(setPreferredContent(""));
    } else {
      dispatch(setPreferredContent(item.value));
    }
  };

  const handleDescriptionChange = (e: any) => {
    postDescription.length < 2000 &&
      dispatch(setPostDescription(e.target.value));
  };

  const handleSubmit = async () => {
    setIsPosted(STATUS.LOADING);
    try {
      dispatch(setLoading(true));
      let payload = await creatorContentPayload(postContent, userInfo.id);
      await creatorContentApi.uploadPost(payload);
      setIsPosted(STATUS.SUCCESS)
      navigate("/creator/profile");
      dispatch(clearPostContent());
    } catch (e) {
      setIsPosted(STATUS.ERROR)
      handleOpenToast('There was an error while posting.Please try again.','error')
    } finally {
      dispatch(setLoading(false));
    }
  };

  const coverPhoto =
    files[0]?.videoCoverPhoto ||
    files.filter((file: any) => file.isCoverPhoto)[0]?.croppedUrl ||
    files[0]?.croppedUrl ||
    files[0]?.url;

  return (
    <Stack direction='column' justifyContent='space-between' height="93.5%">
      <ToastMessage />
      <Box>
        <Box mx="16px" py="8px" display="flex" flexDirection="row">
          {!userInfo.profilePhotoUrl ? <Avatar sx={{height:"40px",width:"40px"}}/> :<Box>
            <img
              src={userInfo.profilePhotoUrl}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "100%",
              }}
            />
          </Box>}
          <Box>
            <TextField
              multiline
              placeholder="Add description"
              rows={4}
              variant="standard"
              sx={{
                width: "210px",
                ml: "8px",
                mr: "16px",

                "& .MuiInputBase-root": {
                  "&:not(.Mui-disabled):hover::before": {
                    borderColor: "white",
                  },
                  "&:before": {
                    borderBottom: "none",
                  },
                  "&:after": {
                    borderBottom: "none",
                  },
                },
              }}
              onChange={(e) => handleDescriptionChange(e)}
              value={postDescription}
            />
          </Box>
          <Box>
            <img src={coverPhoto} alt="" width="74px" height="74px" />
          </Box>
        </Box>

        <Box mx="16px" mb="8px">
          {postDescription.length}/2000
        </Box>

        <Box sx={{ borderBottom: `2px solid ${colors.gray["95"]}` }}></Box>

        <Box m="16px">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="10px"
          >
            <Typography
              fontSize={typography.font_size["sm"]}
              fontWeight={typography.font_weight["bold"]}
            >
              Choose this post categories
            </Typography>

            <Box sx={{ width: "20px" }}>
              <Tooltip
                title="The preferred categories are set from your profile. You can select from other as well."
                arrow
              >
                <img src="/Icons/tooltip_info.svg" width="16px" height="18px" />
              </Tooltip>
            </Box>
          </Box>

          <Box>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap='wrap'
              justifyContent='center'
              gap={2}
              mt="5px"
            >
              {postCategory.map((item: any, index: any) => {
                const isSelected = preferredContent === item.value;
                return (
                  <Box sx={{ cursor: "pointer" }}>
                    <Card
                      name={item.label}
                      onClick={() => updatePostCategories(isSelected, item)}
                      isSelected={isSelected}
                      key={index}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>

          {/* <Box
            display="flex"
            flexDirection="row"
            gap="6px 40px"
            flexWrap="wrap"
            marginTop="10px"
          >
            {OthersContentType.map((item: any, index: any) => {
              const isSelected = preferredContent === item.value;
              return (
                <Box sx={{ cursor: "pointer" }}>
                  <Card
                    name={item.label}
                    onClick={() => updatePostCategories(isSelected, item)}
                    isSelected={isSelected}
                    key={index}
                  />
                </Box>
              );
            })}
          </Box> */}

          <Box
            pt="10px"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography
              fontSize={typography.font_size["sm"]}
              my="16px"
              display="flex"
              alignItems="center"
              gap="4px"
              fontWeight={typography.font_weight["bold"]}
            >
              Set charges
              <Box width="20px" mt="4px">
                <img src="/Icons/tooltip_info.svg" width="16px" height="18px" />
              </Box>
            </Typography>
            <FormControlLabel
              control={<Checkbox checked={makeItFree} color="success" />}
              onChange={(event: any) =>
                dispatch(setMakeItFree(event.target.checked))
              }
              label="Make it free"
            />
          </Box>

          <Box>
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              placeholder="Yaara Coins"
              value={postAmount}
              onChange={(e) => dispatch(setPostAmount(e.target.value))}
              sx={{
                marginBottom: "10px",
                backgroundColor: makeItFree && colors.gray["120"],
              }}
              inputProps={{ minLength: 3, maxlength: 5 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <img src="/Icons/coin_icon.svg" />
                    </IconButton>
                  </InputAdornment>
                ),
                style: { height: "48px" },
              }}
              disabled={makeItFree}
            />
            <InputLabel
              sx={{ pb: "8px", fontSize: typography.font_size["xs"] }}
            >
              Min: CC 100 | Max: CC 10,000
            </InputLabel>

            <Box pt="10px">
              <FormControlLabel
                disabled={makeItFree}
                control={<Checkbox checked={addSubscription} color="success" />}
                onChange={(event: any) =>
                  dispatch(setAddSubscription(event.target.checked))
                }
                label="Add to Subscription Pack"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    
        {isPosted === STATUS.LOADING ? <Button 
          variant="disabled"
          sx={{alignSelf:'center',mt:'auto'}} 
          fullWidth
          >
          Uploading...
        </Button>:
        <Button
          sx={{alignSelf:'center',mt:'auto'}}
          onClick={handleSubmit}
          variant={(postDescription && preferredContent) ? "primary" : "disabled"}
          fullWidth
          disabled={!postDescription && !preferredContent}
        >
          Confirm and Post
        </Button>}
     
    </Stack>
  );
};

export { PostForm };
