import React, { useState, useEffect } from "react";
import {
  AudioCallIcon,
  CalendarIcon,
  ClockSvgIcon,
  CloutCoinIcon,
  TextMessageIcon,
  VideoCallCameraIcon,
} from "../../../../assets/SvgIcons";
import {
  ConfirmedBookingsItemsContainer,
  Container,
  MonthContainer,
  MonthTitle,
  ContentContainer,
  ImageContainer,
  ContentMainSection,
  TitleAndHandleName,
  Title,
  HandleName,
  IncomingOutgoingMessage,
  SecondLineContainer,
  TypeContainer,
  TypeText,
  DurationContainer,
  DurationText,
  PriceContainer,
  PriceIcon,
  PriceText,
  ThirdLineContainer,
  DateContainer,
  DateText,
  TimeContainer,
  TimeText,
  FourthLineContainer,
  ViewDetailsText,
  ConfirmedText,
  BookingDetailsContainer,
  BookingDetailsTitle,
  ChargesTextContainer,
  ChargesText,
  ChargesValue,
  ServiceTypeContainer,
  ServiceTypeText,
  ServiceTypeValue,
  JoinButtonContainer,
  JoinButton,
  BookingInfoContainer,
  Avatar,
} from "./CheckBookingsStyles";
import { colors } from "../../../../utils/styles";
import { GroupedBookings } from "../../../../types";
import { groupByMonth } from "../../../../utils/groupByMonth";
import { formatLongDate } from "../../../../utils/formatDate";
import { isCurrentTimeInRange } from "../../../../utils/checkTimeInRange";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { useLocation, useNavigate } from "react-router-dom";
import creatorBookingServiceApi from "../../../../api/creator/bookingServiceApi";
import userBookingServiceApi from "../../../../api/user/bookingServiceApi";
import { setHistoryBookings } from "../../../../redux/bookingSlice";
import moment from "moment";
import NoBookings from "../NoBookings";
interface HistoryBookingsProps {}
const HistoryBookings: React.FC<HistoryBookingsProps> = ({}) => {
  const { historyBookings } = useSelector((state: RootState) => state.bookings);
  const groupedBookings: GroupedBookings = groupByMonth(historyBookings);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const userRole = location.pathname.includes("creator") ? "creator" : "user";
  const fetchHistoryBookings = async () => {
    try {
      let response =
        userRole === "creator"
          ? await creatorBookingServiceApi.getHistoryBookings("1", "20")
          : await userBookingServiceApi.getHistoryBookings("1", "20");
      let data = response.data.data.data;
      dispatch(setHistoryBookings(data));
    } catch (err) {
      console.error("Error in getting history bookings", err);
    }
  };
  useEffect(() => {
    fetchHistoryBookings();
  }, [dispatch]);
  return (
    <ConfirmedBookingsItemsContainer>
      {historyBookings?.length ? (
        <>
          {Object.keys(groupedBookings).map((monthYear) => (
            <div key={monthYear}>
              <MonthContainer>
                <MonthTitle>{monthYear}</MonthTitle>
              </MonthContainer>
              {groupedBookings[monthYear].map((booking, index) => (
                <HistoryBookingsItem key={index} item={booking} />
              ))}
            </div>
          ))}
        </>
      ) : (
        <NoBookings
          title="No History Bookings Yet"
          subtitle="Start scheduling your booking."
        />
      )}
    </ConfirmedBookingsItemsContainer>
  );
};
interface HistoryBookingsItemProps {
  item: any;
}
const HistoryBookingsItem: React.FC<HistoryBookingsItemProps> = ({ item }) => {
  const [openDetails, setOpenDetails] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = location.pathname.includes("creator") ? "creator" : "user";
  const handleOnClickViewDetails = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setOpenDetails((prev) => !prev);
  };
  const visitProfile=(e:any)=>{
    e.preventDefault();
    if(!item?.profileId) return;
    navigate(`/${userRole}/creator-profile-view/${item?.profileId}`);
  }
  return (
    <Container>
      <ContentContainer>
        <ImageContainer>
          <Avatar
            onClick={visitProfile}
            sx={{ width: "24px", height: "24px" }}
            src={item.profilePic}
          />
        </ImageContainer>
        <ContentMainSection>
          <TitleAndHandleName onClick={visitProfile}>
            <Title sx={{ color: colors.gray[80] }}>
              {item?.displayName} -{" "}
            </Title>
            <HandleName sx={{ color: colors.gray[80] }}>
              {" "}
              {item.handleName ? `@${item.handleName}` : ""}
            </HandleName>
            {item?.label === "outgoing" ? (
              <IncomingOutgoingMessage>Outgoing</IncomingOutgoingMessage>
            ) : null}
          </TitleAndHandleName>
          <SecondLineContainer>
            <TypeContainer>
              {item.serviceType === "AUDIOCALL" ? (
                <AudioCallIcon />
              ) : item.serviceType === "VIDEOCALL" ? (
                <VideoCallCameraIcon />
              ) : (
                <TextMessageIcon />
              )}
              <TypeText sx={{ color: colors.gray[80] }}>
                {item?.serviceType === "AUDIOCALL"
                  ? "Audio call"
                  : item?.serviceType === "VIDEOCALL"
                  ? "Video call"
                  : "Text message"}
              </TypeText>
            </TypeContainer>
            <DurationContainer>
              <ClockSvgIcon />
              <DurationText sx={{ color: colors.gray[80] }}>
                {parseInt(item?.slotLength)} min
              </DurationText>
            </DurationContainer>
            {item.type !== "Subscription Plan" ? (
              <PriceContainer>
                <PriceIcon sx={{ color: colors.gray[80] }}>
                  <CloutCoinIcon />
                </PriceIcon>
                <PriceText sx={{ color: colors.gray[80] }}>
                  {item?.label === "outgoing" ? null : "+"}
                  {item?.amountInYc}
                </PriceText>
              </PriceContainer>
            ) : null}
          </SecondLineContainer>
          <ThirdLineContainer>
            <DateContainer>
              <CalendarIcon />
              <DateText>{formatLongDate(item.date)}</DateText>
            </DateContainer>
            <TimeContainer>
              <TimeText>
                {" |   "}
                {item?.startTime} - {item?.endTime}
              </TimeText>
            </TimeContainer>
          </ThirdLineContainer>
          <FourthLineContainer>
            <ViewDetailsText onClick={handleOnClickViewDetails}>
            {openDetails ? "Less Details" : "View Details"}
            </ViewDetailsText>
            <ConfirmedText sx={{ color: colors.gray[80] }}>
              Booking {(item.status).toLowerCase()}
            </ConfirmedText>
          </FourthLineContainer>
        </ContentMainSection>
        <JoinButtonContainer>
          {isCurrentTimeInRange(item.startTime, item.endTime, item.date) ? (
            <JoinButton>Join</JoinButton>
          ) : null}
        </JoinButtonContainer>
        <BookingDetailsContainer open={openDetails}>
          <BookingInfoContainer>
            <BookingDetailsTitle>Booking Details</BookingDetailsTitle>
            {item.amountInYc ? (
              <ChargesTextContainer>
                <ChargesText>{item?.serviceType === "AUDIOCALL"
                    ? "Audio call"
                    : item?.serviceType === "VIDEOCALL"
                    ? "Video call"
                    : "Message"} Charges (per min)</ChargesText>
                <ChargesValue>
                  {item.amountInYc
                    ? item?.amountInYc / parseInt(item?.slotLength)
                    : ""}{" "}
                  CC
                </ChargesValue>
              </ChargesTextContainer>
            ) : (
              <ChargesTextContainer>
                <ChargesText>Plan expiry date</ChargesText>
                <ChargesValue>
                  {moment
                    .utc(item?.planExpiryDate)
                    .local()
                    .format("DD MMM YYYY h:mm a")}
                </ChargesValue>
              </ChargesTextContainer>
            )}
            <ServiceTypeContainer>
              <ServiceTypeText>Type of Service</ServiceTypeText>
              <ServiceTypeValue>{item?.type}</ServiceTypeValue>
            </ServiceTypeContainer>
          </BookingInfoContainer>
        </BookingDetailsContainer>
      </ContentContainer>
    </Container>
  );
};

export default HistoryBookings;
