import styled from "@emotion/styled";
import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ProfileState, StateType, StateTypeRateCard } from "../../types";
import { colors, typography } from "../../utils/styles";
import { useModal } from "../../context/ModalContext";
import { Link, useNavigate } from "react-router-dom";
import { convertNumberToProfileCount } from "../../utils/ProfileUtilFunctions";
import { EditIcon } from "../../assets/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setError } from "../../redux/creatorSubscriptionSlice";
import { RootState, AppDispatch } from "../../store/store";
import creatorApi from "../../api/creator/creatorApi";
import { setCreator } from "../../redux/appCreatorSlice";
import CreatorProfileTabs from "../../components/Creator/CreatorProfileTabs/CreatorProfileTabs";
import creatorProfileServiceApi from "../../api/creator/creatorProfileServiceApi";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  height: 100vh;
  background-color: ${colors.background_primary};
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-bottom: 5rem;
`;

const ImageContainer = styled(Box)`
  position: relative;
`;

const Image = styled("img")`
  width: 100%;
  height: 181px;
  object-fit: cover;
`;

const BackgroundContainer = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.black[200]};
  opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileDescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ProfileDescHeader = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  bottom: 4px;
`;

const LeftContainer = styled(Box)`
  display: flex;
  padding-right: 1rem;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
`;

const ProfileAchievenmentsContainer = styled(Box)`
  display: flex;
  border-top: 1px solid ${colors.gray[20]};
  border-bottom: 1px solid ${colors.gray[20]};
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const ProfileAchievementsContent = styled(Box)`
  display: flex;
  padding: 6px 2px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ProfileAchievementsItem = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  text-decoration: none;
`;

const ActivityContainer = styled(Box)`
  background: green;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.5rem;
  position: absolute;
  left: 83%;
  bottom: 5px;
  border: 1px solid white;
`;

const ItemContent = styled(Typography)`
  color: ${colors.black[50]};
  text-align: center;
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;

const ShowcasingContentBox = styled(Box)`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  width: 100%;
`;

const ShowCasingItem = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  width: 89px;
  color: ${colors.black[200]};
  font-size: 10px;
  font-weight: 400;
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  background: #fff;
  height: 24px;
`;

const ShowcasingButtonsContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(89px, max-content));
  gap: 8px;
  width: 100%;
  justify-content: space-between;
`;

const SubscriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  position: relative;
  align-items: center;
  padding-bottom: 2rem;
`;

const UnclickableAchievmentsDivider = styled(Box)`
  opacity: 0.6;
  background: ${colors.violet[100]};
  width: 1px;
  height: 29px;
`;

const ProfileDescription = styled(Typography)`
  overflow: hidden;
  color: #616161;
  text-align: justify;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.18px;
  display: inline;
`;
const CreatorProfile: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { profile } = useSelector((state: RootState) => state.creatorProfile);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState<ProfileState>();
  const [viewText, setViewText] = useState<boolean>(false);
  const [likesCount, setLikesCount] = useState<number>(0);
  const [postsCount, setPostsCount] = useState<number>(0);

  const handleViewText = () => {
    setViewText((prev) => !prev);
  };

  const handleOnClickEditButton = () => {
    navigate("/creator/edit/profile", { state: { profile } });
  };

  const fetchCreatorStats = async (creatorId: string) => {
    try {
      const response = await creatorProfileServiceApi.getCreatorsPostStats(
        creatorId
      );
      let dataValues = response.data.data;
      setLikesCount(dataValues.likesCount);
      setPostsCount(dataValues.postCount);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCreatorProfile = async () => {
    dispatch(setLoading(true));
    try {
      const response = await creatorProfileServiceApi.getCreatorProfile();
      dispatch(setCreator(response.data.data.creator));
      if (response) {
        fetchCreatorStats(response.data.data.creator.id);
      }
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    fetchCreatorProfile();
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      setProfileData(profile);
    }
  }, [profile]);

  return (
    <Container>
      {!profileData?.coverPhotoUrl ? (
        <ImageContainer>
          <Image src="/Images/no_cover.png" />
          <BackgroundContainer></BackgroundContainer>
        </ImageContainer>
      ) : (
        <Image src={profileData?.coverPhotoUrl} />
      )}
      <ProfileDescriptionContainer>
        <ProfileDescHeader>
          <LeftContainer>
            <Box sx={{ position: "relative" }}>
              <Avatar
                sx={{ width: "3.5rem", height: "3.5rem" }}
                alt="user"
                src={
                  profileData?.profilePhotoUrl
                    ? profileData?.profilePhotoUrl
                    : ""
                }
              />

              <ActivityContainer />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: typography.font_weight.semiBold,
                  color: colors.black[200],
                  letterSpacing: ".02rem",
                }}
              >
                {profileData?.displayName}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: typography.font_weight.regular,
                  color: colors.gray[700],
                  letterSpacing: ".02rem",
                }}
              >
                {profileData?.handleName ? `@${profileData?.handleName}` : ""}
              </Typography>
            </Box>
          </LeftContainer>

          <Button
            onClick={handleOnClickEditButton}
            sx={{
              cursor: "pointer",
              color: "black",
              letterSpacing: ".02rem",
              display: "flex",
              minWidth: "24px",
              height: "24px",
              padding: "4.5px 0px 5.5px 0px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <EditIcon />
          </Button>
        </ProfileDescHeader>

        <Box sx={{ margin: ".5rem 0", padding: "0 1rem" }}>
          <Typography
            sx={{
              width: "100%",
              display: "block",
            }}
          >
            {profileData?.description &&
            profileData?.description.length > 130 ? (
              <>
                <ProfileDescription>
                  {viewText
                    ? profileData?.description
                    : `${profileData?.description.substring(0, 131)}...`}
                </ProfileDescription>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    color: colors.brand_primary,
                    cursor: "pointer",
                    display: "inline",
                    marginLeft: "5px",
                  }}
                  onClick={handleViewText}
                >
                  {viewText ? "See less" : "See more"}
                </Typography>
              </>
            ) : (
              <ProfileDescription>
                {profileData?.description}
              </ProfileDescription>
            )}
          </Typography>
        </Box>

        <ProfileAchievenmentsContainer>
          <ProfileAchievementsContent>
            <ProfileAchievementsItem to="#">
              <ItemContent variant="body1">
                {convertNumberToProfileCount(likesCount)}
              </ItemContent>
              <ItemContent variant="body1">Likes</ItemContent>
            </ProfileAchievementsItem>

            <ProfileAchievementsItem to="#">
              <ItemContent variant="body1">
                {convertNumberToProfileCount(postsCount)}
              </ItemContent>
              <ItemContent variant="body1">Posts</ItemContent>
            </ProfileAchievementsItem>

            <UnclickableAchievmentsDivider />

            <ProfileAchievementsItem to={`/creator/creators-following`}>
              <ItemContent variant="body1">
                {convertNumberToProfileCount(profileData?.followingCount ?? 0)}
              </ItemContent>
              <ItemContent variant="body1">Following</ItemContent>
            </ProfileAchievementsItem>

            <ProfileAchievementsItem to={`/creator/creators-followers`}>
              <ItemContent variant="body1">
                {convertNumberToProfileCount(profileData?.followersCount ?? 0)}
              </ItemContent>
              <ItemContent variant="body1">Followers</ItemContent>
            </ProfileAchievementsItem>

            <ProfileAchievementsItem to={`/creator/creators-subscribers`}>
              <ItemContent variant="body1">
                {convertNumberToProfileCount(profileData?.subscriberCount ?? 0)}
              </ItemContent>
              <ItemContent variant="body1">Subscribers</ItemContent>
            </ProfileAchievementsItem>
          </ProfileAchievementsContent>
        </ProfileAchievenmentsContainer>

        <ShowcasingContentBox>
          <Typography
            variant="body1"
            sx={{
              fontSize: typography.font_size.sm,
              fontWeight: typography.font_weight.regular,
              lineHeight: typography.line_height.default,
              letterSpacing: typography.letter_spacing.standard,
              color: colors.black[200],
              paddingBottom: "0.5rem",
              flexShrink: "0",
              width: "100%",
            }}
          >
            Showcasing Content:
          </Typography>

          <ShowcasingButtonsContainer>
            {profileData?.contentTarget?.map((profileDataItem, index) => (
              <ShowCasingItem key={index}>{profileDataItem}</ShowCasingItem>
            ))}
          </ShowcasingButtonsContainer>
        </ShowcasingContentBox>

        <ShowcasingContentBox>
          <Typography
            variant="body1"
            sx={{
              fontSize: typography.font_size.sm,
              fontWeight: typography.font_weight.regular,
              lineHeight: typography.line_height.default,
              letterSpacing: typography.letter_spacing.standard,
              color: colors.black[200],
              paddingBottom: "0.5rem",
              flexShrink: "0",
              width: "100%",
            }}
          >
            Content you choose to see:
          </Typography>
          <ShowcasingButtonsContainer>
            {profileData?.preferredContentCategory?.map(
              (contentItem, index) => (
                <ShowCasingItem key={index}>{contentItem}</ShowCasingItem>
              )
            )}
          </ShowcasingButtonsContainer>
        </ShowcasingContentBox>
      </ProfileDescriptionContainer>

      <CreatorProfileTabs profileId={profileData?.id ?? ""} />
    </Container>
  );
};

export default CreatorProfile;
