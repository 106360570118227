import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MainSubscription, Pagination, SubscriptionDataType } from "../types";

const initialState: SubscriptionDataType = {
  activeSubscriptions: {
    subscriptions: [
      {
        id: "",
        userId: "",
        creatorId: "",
        purchaseDate: "",
        expiryDate: "",
        subscriptionPlanId: "",
        subscriptionVersion: "",
        subscriptionDetails: {
          id: "",
          amountInYC: 0,
          audioCall: false,
          videoCall: false,
          directMessage: false,
          audioCallMinutes: 0,
          directMessageMinutes: 0,
          creatorId: "",
          version: "",
          discount: null,
          isRecent: false,
          isDeleted: false,
          deletedAt: null,
        },
        subscriptionConsumption:{
          audioCallMinutesLeft: 0,
          createdAt: "",
          directMessageMinutesLeft: 0,
          id : "",
          updatedAt: "",
          userSubscriptionId: "",
          videoCallMinutesLeft: 0
        },
        isActive: false,
        isCompleted: false,
        creator: {
          id: "",
          userId: "",
          displayName: "",
          handleName: "",
          subscriptionCount: 0,
          subscriberCount: 0,
          profilePhotoUrl: "",
        },
      },
    ],
    pagination: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
    },
  },
  historySubscriptions: {
    subscriptions: [
      {
        id: "",
        userId: "",
        creatorId: "",
        purchaseDate: "",
        expiryDate: "",
        subscriptionPlanId: "",
        subscriptionVersion: "",
        subscriptionDetails: {
          id: "",
          amountInYC: 0,
          audioCall: false,
          videoCall: false,
          directMessage: false,
          audioCallMinutes: 0,
          directMessageMinutes: 0,
          creatorId: "",
          version: "",
          discount: null,
          isRecent: false,
          isDeleted: false,
          deletedAt: null,
        },
        subscriptionConsumption:{
          audioCallMinutesLeft: 0,
          createdAt: "",
          directMessageMinutesLeft: 0,
          id : "",
          updatedAt: "",
          userSubscriptionId: "",
          videoCallMinutesLeft: 0
        },
        isActive: false,
        isCompleted: false,
        creator: {
          id: "",
          userId: "",
          displayName: "",
          handleName: "",
          subscriptionCount: 0,
          subscriberCount: 0,
          profilePhotoUrl: "",
        },
      },
    ],

    pagination: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
    },
  },
  loading: false,
};

const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState:initialState,
  reducers: {
    setActiveSubscriptions(state, action: PayloadAction<MainSubscription[]>) {
      state.activeSubscriptions.subscriptions = action.payload;
    },
    setHistorySubscriptions(state, action: PayloadAction<MainSubscription[]>) {
      state.historySubscriptions.subscriptions = action.payload;
    },
    setActivePagination(state, action: PayloadAction<Pagination>) {
      state.activeSubscriptions.pagination = action.payload;
    },
    setHistoryPagination(state, action: PayloadAction<Pagination>) {
      state.historySubscriptions.pagination = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const {
  setActiveSubscriptions,
  setHistorySubscriptions,
  setActivePagination,
  setHistoryPagination,
  setLoading,
} = subscriptionSlice.actions;

export const subscriptionsReducer = subscriptionSlice.reducer;
