import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography, Button, styled } from "@mui/material";
import { colors, typography } from "../../utils/styles";
import {
  ArrowForwardIcon,
  CancelRedIcon,
  TickMarkIcon,
} from "../../assets/SvgIcons";
import { MainSubscription } from "../../types";
import { useLocation, useNavigate } from "react-router-dom";
import { getPlanValidityMessage } from "../../services/planValidity";
import StyledTooltips from "../../utils/Tooltip";
import { Link } from "react-router-dom";
import { resetBooking, setSubscriptionDetails } from "../../redux/slotBookingSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
const Container = styled(Box)`
  display: flex;
  max-width: 340px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid ${colors.violet[100]};
  background: ${colors.white};
`;
const Header = styled(Box)`
  display: flex;
  width: 100%;
  height: 77px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px 16px 0px 0px;
  background: ${colors.purple[50]};
`;
const HeaderContent = styled(Box)`
  display: flex;
  padding: 8px 0px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
`;
const HeaderUserName = styled(Typography)`
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.semiBold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
  white-space: nowrap;
  width: 100%;
`;
const HeaderHandleName = styled(Typography)`
  width: 100%;
  overflow: hidden;
  color: ${colors.gray[80]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  white-space: nowrap;
`;
const SubscriptionOfferingsContainer = styled(Box)`
  padding: 0px 16px 16px 16px;
  width: 100%;
`;
const SubscriptionOfferingsContent = styled(Box)`
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 16px 0;
`;
const SubscriptionOfferingPricingText = styled(Typography)`
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const SubscriptionOfferingsText = styled(Typography)`
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const SubscriptionPlanOfferingsContent = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
const PlanText = styled(Typography)`
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const PlanValidity = styled(Typography)`
  overflow: hidden;
  color: ${colors.gray[80]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const PlanButtonContainer = styled(Box)`
  display: flex;
  width: 46%;
  justify-content: flex-end;
`;
const RenewButton = styled(Typography)`
  color: ${colors.violet[100]};
  text-align: center;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  cursor: pointer;
`;
const ArrowButton = styled(Typography)`
  display: flex;
  cursor: pointer;
`;
const PlanValidityInfoContainer = styled(Box)`
  display: flex;
  width: 46%;
  justify-content: flex-end;
`;
const PlanValidityInfoText = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-size: ${typography.font_size.xxs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const BuySubscriptionButton = styled(Box)`
  display: flex;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid var(--Background-brand-primary, #7262c2);
  background: var(--Background-brand-primary, #7262c2);
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  letter-spacing: 0.18px;
  width: 100%;
  margin: 12px 0 0 0;
`;
interface CommonSubscriptionCardProps {
  subscriptionPlanData: MainSubscription;
  flag: string;
}
const CommonSubscriptionCard: React.FC<CommonSubscriptionCardProps> = ({
  subscriptionPlanData,
  flag,
}) => {
  const [subscription, setSubscription] = useState<MainSubscription>();
  const dispatch=useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const formatCustomDateRange = (
    purchaseDateStr: string,
    expiryDateStr: string
  ) => {
    const purchaseDate = new Date(purchaseDateStr);
    const expiryDate = new Date(expiryDateStr);
    const formattedPurchaseDate = purchaseDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    const formattedExpiryDate = expiryDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    const purchaseDateParts = formattedPurchaseDate.split(" ");
    const expiryDateParts = formattedExpiryDate.split(" ");
    const formattedOutput = `from - ${purchaseDateParts[0]} ${purchaseDateParts[1]} to ${expiryDateParts[0]} ${expiryDateParts[1]} ${expiryDateParts[2]}`;
    return formattedOutput;
  };
  const handleOnRenewPlanClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    // use subscription if you want to send the state to buy-subscription page , it has creator details like id , userId which can help in getting the related data from api in the but subscrption page.
    navigate(`/${userRole}/buy-subscription`);
  };
  const handleOnClickBookSlot=()=>{
    if(!subscription?.subscriptionConsumption?.userSubscriptionId) return;
      dispatch(
        setSubscriptionDetails({
          userSubscriptionId: subscription?.subscriptionConsumption?.userSubscriptionId,
          expiryDate: subscription?.expiryDate,
        })
      );
    navigate(`/${userRole}/book-slot?serviceType=subscription&id=${subscription?.creator?.userId}&creatorName=${subscription?.creator?.displayName}&isAudioCall=${subscription?.subscriptionDetails?.audioCall}&isVideoCall=${subscription?.subscriptionDetails?.videoCall}&isDirectMessage=${subscription?.subscriptionDetails?.directMessage}&audioCallMinutes=${subscription?.subscriptionConsumption?.audioCallMinutesLeft}&videoCallMinutes=${subscription?.subscriptionConsumption?.videoCallMinutesLeft}&directMessageMinutes=${subscription?.subscriptionConsumption?.directMessageMinutesLeft}`);
  }
  useEffect(() => {
    if (subscriptionPlanData) {
      let data = subscriptionPlanData;
      setSubscription(data);
    }
  }, [subscriptionPlanData]);
  return (
    <Container
      sx={{
        border:
          flag === "activeSubscription"
            ? `1px solid ${colors.violet[100]}`
            : " 1px solid rgba(33, 33, 33, 0.80)",
      }}
    >
      <Header
        sx={{
          background:
            flag === "activeSubscription"
              ? colors.purple[50]
              : colors.gray[120],
        }}
      >
        <HeaderContent>
          <Box sx={{ position: "relative" }}>
            <Avatar
              sx={{ width: "40px", height: "40px" }}
              alt="user"
              src={subscription?.creator?.profilePhotoUrl ?? ""}
            />
          </Box>
          <Box
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <StyledTooltips text={subscription?.creator?.displayName ?? ""}>
              <HeaderUserName>
                {subscription?.creator?.displayName ?? ""}
              </HeaderUserName>
            </StyledTooltips>

            <StyledTooltips text={subscription?.creator?.handleName ?? ""}>
              <HeaderHandleName>
                {subscription?.creator?.handleName ? `@${subscription?.creator?.handleName}` : ""}
              </HeaderHandleName>
            </StyledTooltips>
          </Box>

          {flag === "subscriptionHistory" && (
            <PlanButtonContainer>
              <RenewButton onClick={handleOnRenewPlanClick}>
                {!subscription?.subscriptionDetails?.isDeleted ? (subscription?.subscriptionDetails?.isRecent
                  ? "Renew Plan"
                  : "Check Plan") : ""}
              </RenewButton>
              <ArrowButton onClick={handleOnRenewPlanClick}>
                <ArrowForwardIcon />
              </ArrowButton>
            </PlanButtonContainer>
          )}

          {flag === "activeSubscription" && (
            <PlanValidityInfoContainer>
              <PlanValidityInfoText>
                {getPlanValidityMessage(subscription?.expiryDate ?? "")}
              </PlanValidityInfoText>
            </PlanValidityInfoContainer>
          )}
        </HeaderContent>
      </Header>

      <SubscriptionOfferingsContainer>
        <SubscriptionOfferingsContent>
          <SubscriptionOfferingPricingText>
            CC {subscription?.subscriptionDetails?.amountInYC} / Month
          </SubscriptionOfferingPricingText>
          <SubscriptionOfferingsText>
            Subscription Offerings -
          </SubscriptionOfferingsText>

          <SubscriptionPlanOfferingsContent>
            {subscription?.subscriptionDetails?.directMessage ? (
              <TickMarkIcon
                color={
                  flag === "activeSubscription"
                    ? colors.violet[100]
                    : colors.gray[90]
                }
              />
            ) : (
              <CancelRedIcon
                color={
                  flag === "activeSubscription"
                    ? colors.red[300]
                    : colors.gray[90]
                }
              />
            )}
            <PlanText
              sx={{
                textDecoration: subscription?.subscriptionDetails?.directMessage
                  ? "none"
                  : "line-through",
              }}
            >
              {subscription?.subscriptionDetails?.directMessageMinutes
                ? `${subscription?.subscriptionConsumption?.directMessageMinutesLeft}/${subscription?.subscriptionDetails?.directMessageMinutes} min direct message`
                : "direct message"}
            </PlanText>
          </SubscriptionPlanOfferingsContent>

          <SubscriptionPlanOfferingsContent>
            {subscription?.subscriptionDetails?.videoCall ? (
              <TickMarkIcon
                color={
                  flag === "activeSubscription"
                    ? colors.violet[100]
                    : colors.gray[90]
                }
              />
            ) : (
              <CancelRedIcon
                color={
                  flag === "activeSubscription"
                    ? colors.red[300]
                    : colors.gray[90]
                }
              />
            )}
            <PlanText
              sx={{
                textDecoration: subscription?.subscriptionDetails?.videoCall
                  ? "none"
                  : "line-through",
              }}
            >
              {subscription?.subscriptionDetails?.videoCallMinutes
                ? `${subscription?.subscriptionConsumption?.videoCallMinutesLeft}/${subscription?.subscriptionDetails?.videoCallMinutes} min video call`
                : "video call"}
            </PlanText>
          </SubscriptionPlanOfferingsContent>

          <SubscriptionPlanOfferingsContent>
            {subscription?.subscriptionDetails?.audioCall ? (
              <TickMarkIcon
                color={
                  flag === "activeSubscription"
                    ? colors.violet[100]
                    : colors.gray[90]
                }
              />
            ) : (
              <CancelRedIcon
                color={
                  flag === "activeSubscription"
                    ? colors.red[300]
                    : colors.gray[90]
                }
              />
            )}
            <PlanText
              sx={{
                textDecoration: subscription?.subscriptionDetails?.audioCall
                  ? "none"
                  : "line-through",
              }}
            >
              {subscription?.subscriptionDetails?.audioCallMinutes
                ? `${subscription?.subscriptionConsumption?.audioCallMinutesLeft}/${subscription?.subscriptionDetails?.audioCallMinutes} min audio call`
                : "audio call"}
            </PlanText>
          </SubscriptionPlanOfferingsContent>
        </SubscriptionOfferingsContent>
        <PlanValidity>
          {formatCustomDateRange(
            subscription?.purchaseDate ?? "",
            subscription?.expiryDate ?? ""
          )}
        </PlanValidity>
        {flag === "activeSubscription" && (
            <BuySubscriptionButton>
            <Box
              style={{ textDecoration: "none", width: "100%", color: "white" }}
              onClick={handleOnClickBookSlot}
            >
              Book Your Slot
            </Box>
          </BuySubscriptionButton>
          )
        }
    </SubscriptionOfferingsContainer>
    </Container>
  );
};

export default CommonSubscriptionCard;
