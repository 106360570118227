import { createSlice } from "@reduxjs/toolkit";

let initialContentState = {
  mode: "photo",
  files: [],
  postDescription: "",
  preferredContent: "",
  otherPreferredContent: "",
  makeItFree: false,
  postAmount: "",
  addSubscription: false,
  loading: false,
  currentContentStep: "",
  coverPhoto: null,
};

export const creatorContentSlice = createSlice({
  name: "postContent",
  initialState: initialContentState,
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setPostDescription: (state, action) => {
      state.postDescription = action.payload;
    },
    setPreferredContent: (state, action) => {
      state.preferredContent = action.payload;
    },
    setOtherPreferredContent: (state, action) => {
      state.otherPreferredContent = action.payload;
    },
    setMakeItFree: (state, action) => {
      state.makeItFree = action.payload;
    },
    setPostAmount: (state, action) => {
      state.postAmount = action.payload;
    },
    setAddSubscription: (state, action) => {
      // alert(action.payload)
      state.addSubscription = action.payload;
    },
    clearPostContent: (state) => {
      return initialContentState;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
      return state;
    },
    setCurrentContentStep: (state, action) => {
      state.currentContentStep = action.payload;
      return state;
    },
  },
});

export const {
  setMode,
  setFiles,
  setPostDescription,
  setPreferredContent,
  setOtherPreferredContent,
  setMakeItFree,
  setPostAmount,
  setAddSubscription,
  clearPostContent,
  setCurrentContentStep,
  setLoading,
} = creatorContentSlice.actions;

export const selectCurrentMode = (state: any) => state.postContent.mode;
export const selectCurrentFiles = (state: any) => state.postContent.files;

export default creatorContentSlice.reducer;
