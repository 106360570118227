import { Box, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ImageStockIcon, VideoIconForPostGrid } from "../../../assets/SvgIcons";
import { UserFeedType } from "../../../types";

const Container = styled(Box)`
  // width: 122.613px;
  height: 123.162px;
  flex-shrink: 0;
  position: relative;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
`;

const Image = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const IconContainer = styled(Box)`
  display: flex;
  width: 15.898px;
  height: 16px;
  padding: 2.667px 2.65px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  right: 0;
`;

const Placeholder = styled(Box)`
  width: 100%;
  height: 100%;
  background: lightgray;
`;
interface ContentGridViewProps {
  item: UserFeedType;
  handlePostClick: () => void;
}
const CreatorPostGridView: React.FC<ContentGridViewProps> = ({ item, handlePostClick }) => {
  const [postItem, setPostItem] = useState<UserFeedType>();
  useEffect(() => {
    if (item) {
      setPostItem(item);
    }
  }, [item]);

  return (
    <Container
    onClick={handlePostClick}
     >
  {postItem ? (
    <Image
      src={postItem?.coverPhotoUrl}
      alt="imagePost"
      loading="lazy"
    />
  ) : (
    <Placeholder />
  )}
  <IconContainer>
    {postItem?.contentType==="VIDEO" ? (
      <VideoIconForPostGrid />
    ) : (
      <ImageStockIcon />
    )}
  </IconContainer>
</Container>
  );
};

export default React.memo(CreatorPostGridView);
