import { createTheme, Theme } from "@mui/material/styles";
import { colors } from "./utils/styles";

declare module "@mui/material/styles" {
  interface Theme {
    // Add custom theme properties if any
  }
  // Allow configuration using `createTheme`
  interface ThemeOptions {
    // Add custom theme properties if any
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    disabled: true;
    tertiary: true;
    default: true;
  }
}

export const muiTheme: Theme = createTheme({
  typography: {
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    h1: {},
    h2: {
      fontSize: "24px",
    },
    h3: {
      fontSize: "20px",
    },
    h4: {
      fontSize: "18px",
    },
    h5: {
      fontSize: "16px",
    },
    body1: {
      fontSize: "14px",
    },
    body2: {
      fontSize: "12px",
    },
    button: {
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 6,
  },
  palette: {
    primary: {
      main: "#7262C2",
    },
    secondary: {
      main: colors.purple[100],
    },
  },
  spacing: 6,
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "primary" },
          style: {
            height: "48px",
            textTransform: "none",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: "700",
            background: colors.violet[50],
            color: colors.white,
            "&:hover": {
              background: colors.violet[50],
            },
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            color: colors.gray["90"],
            "&:hover": {
              background: colors.white,
            },
          },
        },
        {
          props: { variant: "disabled" },
          style: {
            color: "#9E9E9E",
            height: "48px",
            textTransform: "none",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: "700",
            background: "#D5D5D5",
            "&:hover": {
              background: colors.violet[50],
            },
          },
        },
        {
          props: { variant: "tertiary" },
          style: {
            color: colors.violet["100"],
            "&:hover": {
              background: colors.white,
            },
            fontWeight: "700",
          },
        },
        {
          props: { variant: "default" },
          style: {
            color: colors.black["100"],
            "&:hover": {
              background: colors.white,
            },
            fontWeight: "700",
          },
        },
      ],
    },
  },
});
