import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import walletServiceApi from "../../../api/user/walletServiceApi";
import creatorWalletServiceApi from "../../../api/creator/creatorWalletServiceApi";
import useToast from "../../../hooks/useToast";
import moment from "moment";
import {
  AudioCallIcon,
  TextMessageIcon,
  VideoCallIcon,
} from "../../../assets/SvgIcons";

type ServiceType =
  | "TIPS"
  | "PAYMENT"
  | "SUBSCRIPTION"
  | "DMS"
  | "POST"
  | "AUDIOCALL"
  | "VIDEOCALL"
  | "DMS";

const serviceTypeIcon: Record<ServiceType, any> = {
  TIPS: "/Icons/serviceType/tips.svg",
  PAYMENT: "/Icons/serviceType/payment.svg",
  SUBSCRIPTION: "/Icons/serviceType/subscription.svg",
  DMS: "/Icons/serviceType/dm.svg",
  POST: "/Icons/serviceType/post.svg",
  AUDIOCALL: <AudioCallIcon color="#212121" width="24" height="24" />,
  VIDEOCALL: <VideoCallIcon color="#212121" width="24" height="24" />,
};

const TransactionsDetails = ({ data }: { data: any }) => {
  const makeDescription = () => {
    switch (data.serviceType.toUpperCase()) {
      case "TIPS":
        return `To - @${data?.toUser ?? "-"}`;
      case "PAYMENT":
        return `Paid - INR ${data.amountInRs}`;
      case "POST":
        return data.transactionType === "DEBIT"
          ? `Post brought from - @${data.toUser}`
          : `Purchased post - @${data.fromUser}`;
      case "SUBSCRIPTION":
        return `To - @${data?.toUser ?? "-"}`;
      case "DMS":
        return `To - @${data?.toUser ?? "-"}`;
      case "AUDIOCALL":
        return `To - @${data?.toUser ?? "-"}`;
      case "VIDEOCALL":
        return `To - @${data?.toUser ?? "-"}`;
      default:
        return "--";
    }
  };
  const getSign = () => {
    if (data.transactionType === "DEBIT") {
      return "-";
    } else {
      return "+";
    }
  };
  const iconSrc =
    serviceTypeIcon[data?.serviceType.toUpperCase() as ServiceType];
  return (
    <Stack direction="row" gap="18px" pt="16px">
      {typeof iconSrc === "string" ? (
        <img
          src={iconSrc}
          style={{ alignSelf: "flex-start" }}
          width="24px"
          height="24px"
        />
      ) : (
        iconSrc
      )}
      <Stack
        direction="row"
        pb="16px"
        justifyContent="space-between"
        flexGrow={1}
        borderBottom=".25px solid #9E9E9E"
      >
        <Box>
          <Typography fontSize="12px" fontWeight={700}>
            {/* {data?.serviceType ?? ""} */}
            {data?.message ?? ""}
          </Typography>
          <Typography gutterBottom fontWeight={400} fontSize="10px">
            {makeDescription()}
          </Typography>
          <Typography fontSize="8px" color="#9E9E9E">
            {moment.utc(data.createdAt).local().format("D MMMM YY, h:mma")}
          </Typography>
        </Box>
        <Typography fontSize="12px" color="#616161" pr="16px">
          {getSign()}CC {data.amountInYc ?? ""}
        </Typography>
      </Stack>
    </Stack>
  );
};

const TxnHistory = ({ userRole }: { userRole: string }) => {
  const { ToastMessage, handleOpenToast } = useToast();

  const [transactionData, setTransactionData] = useState([]);

  const fetchTransactionHistory = async () => {
    try {
      let response;
      if (userRole === "user") {
        response = await walletServiceApi.getHistory();
      } else {
        response = await creatorWalletServiceApi.getHistory();
      }
      setTransactionData(response.data.data.data);
    } catch (error) {
      handleOpenToast(
        "Something went wrong. Could not get history details.",
        "error"
      );
    }
  };
  useEffect(() => {
    fetchTransactionHistory();
  }, []);
  if (transactionData.length === 0) {
    return (
      <Typography mt="3rem" textAlign="center">
        No Transaction History
      </Typography>
    );
  }

  return (
    <Box>
      <ToastMessage />
      {transactionData &&
        transactionData?.map((txn: any) => {
          return <TransactionsDetails data={txn} />;
        })}
    </Box>
  );
};
export default TxnHistory;
