import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { typography } from "../../utils/styles";

export default function ConformationModal(props: any) {
  const navigate = useNavigate();

  return (
    <Box>
      <Box>
        <Dialog open={props.openModal}>
          <DialogContent>
            <Typography
              fontSize={typography.font_size["xl"]}
              fontWeight={typography.font_weight["bold"]}
            >
              Are you sure you want to cancel?
            </Typography>

            <Typography fontSize={typography.font_size["sm"]}>
              You might have to start over the process
            </Typography>
          </DialogContent>
          <DialogActions>
            <Box width="100%" display="flex" flexDirection="row">
              <Button
                variant="default"
                onClick={() => props.setOpenModal(false)}
                sx={{ width: "160px" }}
              >
                Cancel
              </Button>
              <Button
                variant="tertiary"
                onClick={() => navigate("user/home")}
                sx={{ width: "160px" }}
              >
                Yes
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
