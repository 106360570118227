import { Box, Divider, styled, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { colors, typography } from "../../../utils/styles";
import { DeleteNewIcon, EditIcon, GreenTick, MenuIcon } from "../../../assets/SvgIcons";
import { useNavigate } from "react-router-dom";
import { OndemandRateType } from "../../../types";
import {
  AudioCallIcon,
  TextMessageIcon,
  VideoCallCameraIcon,
} from "../../../assets/SvgIcons";
import { AMOUNT_UNIT } from "../../../constants/amountUnitConstant";
import { useDispatch } from "react-redux";
import { removeOnDemandRate } from "../../../redux/creatorOnDemand";
import creatorApi from "../../../api/creator/creatorApi";
import { useModal } from "../../../context/ModalContext";
import DeleteCreatorOnDemandRate from "../../../modals/CreatorServices/DeleteCreatorOnDemandRate";
import creatorProfileServiceApi from "../../../api/creator/creatorProfileServiceApi";

const SubscriptionViewCardContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid ${colors.violet[100]};
  background: ${colors.white};
`;

const SubscriptionViewCardTitleContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  position: relative;
`;

const ViewCardTitle = styled(Box)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const SubscriptionViewCardContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const SubscriptionViewCardContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ViewCardContentLeftContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const ViewCardContentLeftIcon = styled(Box)``;

const ViewCardContentLeftTextContainer = styled(Box)``;

const ViewCardContentLeftTitleText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const ViewCardContentLeftSubTitleText = styled(Typography)`
  overflow: hidden;
  color: var(--Text-Primary, #212121);
  text-overflow: ellipsis;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.15px;
`;

const ViewCardContentRightContainer = styled(Box)`
  
`;

const SuccessText = styled(Typography)`
  color: ${colors.violet[100]};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
  cursor: pointer;
`;

const EditModalContainer=styled(Box)`
  display: flex;
  height: 80px;
  width: 158px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: var(--Background-card, #FFF);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: 24px;
  right: 0;
`;

const EditContent=styled(Box)`
display: flex;
padding: 8px;
align-items: center;
gap: 8px;
align-self: stretch;
`;

const EditText=styled(Typography)` 
display: flex;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;
color: var(--Text-Primary, #212121);

/* Body/XL-R */
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 21.6px */
letter-spacing: 0.24px;
align-self: stretch;
`;



const MenuIconContainer = styled(Box)``;

interface OnDemandPlanCardProps {
  items: OndemandRateType | undefined;
}

const OnDemandPlanCard: React.FC<OnDemandPlanCardProps> = ({
  items,
}) => {
  const dispatch=useDispatch();
  const [data, setData] = useState<OndemandRateType>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const menuIconRef = useRef<HTMLDivElement | null>(null);

  const handleSetRate = (e:React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOpenModal((prev)=> !prev);
    navigate("/creator/set-on-demand-rates");
  };
  const {requestCloseModal, requestOpenModal} = useModal();

  const handleDeleteOnDemandrate=async()=>{
    try{
    setOpenModal((prev)=> !prev);
    dispatch(removeOnDemandRate());
    if(items?.id){
      const response=await creatorProfileServiceApi.deleteOnDemandRate(items?.id)
    }
  }
  catch(err){
    console.log("error", err)
  }
  }

  const handleOpenEditDeleteModal = () => {
    setOpenModal(!openModal);
  };

  const openDeleteModalOnDeleteButtonClick =(e:React.MouseEvent<HTMLDivElement>)=>{
    e.preventDefault();
    setOpenModal((prev)=> !prev);
    requestOpenModal({
      name: "Delete On Demand Rates Modal",
        component: <DeleteCreatorOnDemandRate
        handleOnDelete={handleDeleteOnDemandrate}
        requestCloseModal={requestCloseModal}
        requestOpenModal={requestOpenModal}
        />
    })
  }


  useEffect(() => {
    if (items) {
      setData(items);
    }
  }, [items]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        menuIconRef.current &&
        !menuIconRef.current.contains(event.target as Node)
      ) {
        setOpenModal(false);
      }
    }

    if (openModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openModal]);

  return (
    <>
      <SubscriptionViewCardContainer>
        <SubscriptionViewCardTitleContainer>
          <ViewCardTitle>On Demand Services</ViewCardTitle>
          <MenuIconContainer ref={menuIconRef} onClick={handleOpenEditDeleteModal}>
            <MenuIcon />
          </MenuIconContainer>

          {openModal ?
            (
              <EditModalContainer ref={modalRef}>
                <EditContent onClick={handleSetRate}>
                    <EditIcon
                      color={"#212121"}
                      width={"24"}
                      height={"24"}
                    />
                    <EditText>Edit</EditText>
                </EditContent>

                <Divider sx={{
                    width: "68%",
                    alignSelf: "self-end"
                }}/>


                <EditContent onClick={openDeleteModalOnDeleteButtonClick}>
                    <DeleteNewIcon
                      color={"#212121"}
                    />
                    <EditText>Delete</EditText>
                </EditContent>
              </EditModalContainer>
            )
            : null
          }
        </SubscriptionViewCardTitleContainer>

        
        <SubscriptionViewCardContentContainer>
          <SubscriptionViewCardContent>
            <ViewCardContentLeftContainer>
              <ViewCardContentLeftIcon>
                <TextMessageIcon />
              </ViewCardContentLeftIcon>
              <ViewCardContentLeftTextContainer>
                <ViewCardContentLeftTitleText>
                  Direct Message
                </ViewCardContentLeftTitleText>
                <ViewCardContentLeftSubTitleText>
                  {data?.directMessageYc
                    ? `${data?.directMessageYc}${AMOUNT_UNIT.MAIN_UNIT} per 1 minute`
                    : "No charges set yet."}
                </ViewCardContentLeftSubTitleText>
              </ViewCardContentLeftTextContainer>
            </ViewCardContentLeftContainer>
            {data?.directMessageYc ? (
              <ViewCardContentRightContainer>
                <GreenTick />
              </ViewCardContentRightContainer>
            ) : (
              <ViewCardContentRightContainer onClick={handleSetRate}>
                <SuccessText>Set Rate</SuccessText>
              </ViewCardContentRightContainer>
            )}
          </SubscriptionViewCardContent>
          <SubscriptionViewCardContent>
            <ViewCardContentLeftContainer>
              <ViewCardContentLeftIcon>
                <AudioCallIcon />
              </ViewCardContentLeftIcon>
              <ViewCardContentLeftTextContainer>
                <ViewCardContentLeftTitleText>
                  Audio Call
                </ViewCardContentLeftTitleText>
                <ViewCardContentLeftSubTitleText>
                  {data?.audioCallYc
                    ? `${data?.audioCallYc}${AMOUNT_UNIT.MAIN_UNIT} per 1 minute`
                    : "No charges set yet."}
                </ViewCardContentLeftSubTitleText>
              </ViewCardContentLeftTextContainer>
            </ViewCardContentLeftContainer>
            {data?.audioCallYc ? (
              <ViewCardContentRightContainer>
                <GreenTick />
              </ViewCardContentRightContainer>
            ) : (
              <ViewCardContentRightContainer onClick={handleSetRate}>
                <SuccessText>Set Rate</SuccessText>
              </ViewCardContentRightContainer>
            )}
          </SubscriptionViewCardContent>
          <SubscriptionViewCardContent>
            <ViewCardContentLeftContainer>
              <ViewCardContentLeftIcon>
                <VideoCallCameraIcon />
              </ViewCardContentLeftIcon>
              <ViewCardContentLeftTextContainer>
                <ViewCardContentLeftTitleText>
                  Video Call
                </ViewCardContentLeftTitleText>
                <ViewCardContentLeftSubTitleText>
                  {data?.videoCallYc
                    ? `${data?.videoCallYc}${AMOUNT_UNIT.MAIN_UNIT} per 1 minute`
                    : "No charges set yet."}
                </ViewCardContentLeftSubTitleText>
              </ViewCardContentLeftTextContainer>
            </ViewCardContentLeftContainer>
            {data?.videoCallYc ? (
              <ViewCardContentRightContainer>
                <GreenTick />
              </ViewCardContentRightContainer>
            ) : (
              <ViewCardContentRightContainer onClick={handleSetRate}>
                <SuccessText>Set Rate</SuccessText>
              </ViewCardContentRightContainer>
            )}
          </SubscriptionViewCardContent>
        </SubscriptionViewCardContentContainer>
      </SubscriptionViewCardContainer>
    </>
  );
};

export default OnDemandPlanCard;
