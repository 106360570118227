import { v4 as uuidv4 } from "uuid";

import { uploadContentFiles } from "../services/uploadToS3";
import { useSelector } from "react-redux";

// Step 1: Fetch the Blob from the Blob URL
async function fetchBlob(blobUrl: string) {
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  return blob;
}

// Step 2: Convert the Blob to a File object
function blobToFile(blob: any, fileName: string) {
  const file = new File([blob], fileName, { type: blob.type });
  return file;
}

// Usage example
async function convertBlobUrlToFile(blobUrl: string, fileName: string) {
  try {
    const blob = await fetchBlob(blobUrl);
    const file = blobToFile(blob, fileName);
    return file;
  } catch (error) {
    console.error("Error converting Blob URL to File:", error);
  }
}

export const getFileName = (file: any) => {
  const fileExtension = file.name.split(".").pop();
  const fileName = `${uuidv4()}.${fileExtension}`;

  return fileName;
};

export const getKeyForS3 = (mode: string, userId: string, fileName: string) => {
  let key = `raw/${userId}/${fileName}`;
//  changes asked by ayan
  // if (mode === "photo") {
  //   key = `photos/${key}`;
  // } else {
  //   key = `videos/${key}`;
  // }
  return fileName;
};

const creatorContentPayload = async (
  postContent: any,
  userId: string = "user_123"
) => {
  try {
    // debugger;
    const contentData = await Promise.all(
      postContent.files.map(async (file: any) => {
        const fileName = getFileName(file);
        const key = getKeyForS3(postContent.mode, userId, fileName);

        let fileOb;

        if (postContent.mode === "photo") {
          const fileURL = file.croppedUrl || file.url || file.actualUrl;
          fileOb = await convertBlobUrlToFile(fileURL, fileName);
        } else {
          const fileURL = file.url || file.actualUrl;
          fileOb = await convertBlobUrlToFile(fileURL, fileName);
        }

        const s3Response = await uploadContentFiles(key, fileOb);

        if (postContent.mode === "photo") {
          return {
            type: "PHOTO",
            bucket: s3Response.bucketName,
            key: s3Response.key,
          };
        } else {
          let s3trimmedFileResponse: any = {};

          if (file.freeSeconds && file.trimmedUrl) {
            const trimmedFileName = getFileName(file);
            const trimmedFileKey = getKeyForS3(
              postContent.mode,
              userId,
              trimmedFileName
            );

            const trimmedFileOb = await convertBlobUrlToFile(
              file.trimmedUrl,
              trimmedFileName
            );

            s3trimmedFileResponse = await uploadContentFiles(
              trimmedFileKey,
              trimmedFileOb
            );
          }

          return {
            type: "VIDEO",
            bucket: s3Response.bucketName,
            key: s3Response.key,
            previewBucket: s3trimmedFileResponse.bucketName,
            previewKey: s3trimmedFileResponse.key,
            freeSeconds: file.freeSeconds,
          };
        }
      })
    );

    const payload: any = {
      postData: {
        contentDescription: postContent.postDescription || " bb ",
        freePost: postContent.makeItFree,
        subscriptionPost: postContent.addSubscription,
        tag: postContent.preferredContent,
        contentType:postContent.mode === 'photo' ? "PHOTO" :'VIDEO'
      },
      contentData: contentData,
    };

    if (!postContent.makeItFree) {
      payload.postData.price = postContent.postAmount;
    }

    if (postContent.mode === "photo") {
      const coverPhotoFile = postContent.files.find(
        (file: any) => file.isCoverPhoto
      );

      if (!coverPhotoFile) {
        return payload
      }

      const coverPhotoFileName = getFileName(coverPhotoFile);
      const coverPhotoKey = getKeyForS3("photo", userId, coverPhotoFileName);

      const fileURL =
        coverPhotoFile.croppedUrl ||
        coverPhotoFile.url ||
        coverPhotoFile.actualUrl;

      const coverPhotoFileOb = await convertBlobUrlToFile(
        fileURL,
        coverPhotoFileName
      );

      const coverPhotS3Response = await uploadContentFiles(
        coverPhotoKey,
        coverPhotoFileOb
      );

      payload.postData.coverPhotoUrl = coverPhotS3Response.url;
    } else {
      payload.postData.coverPhotoUrl = postContent.files[0].videoCoverPhoto;
    }

    return payload;
  } catch (e) {
    console.log("Error", e);
  }
};

export { creatorContentPayload };
