import { createSlice } from "@reduxjs/toolkit";
import { UserFeedType } from "../types";
interface UserFeedState {
    userFeeds: UserFeedType[] | null;
  }

const initiaUserFeedState: UserFeedState = {
    userFeeds: [],
};

const userFeedSlice = createSlice({
  name: "userFeeds",
  initialState: initiaUserFeedState,
  reducers: {
    setUserFeeds(state, action) {
      state.userFeeds = action.payload;
    },
    setLikePost(state, action) {
        if (state.userFeeds) {
          const postIndex = state.userFeeds.findIndex(post => post.id === action.payload.postId);
          if (postIndex !== -1) {
            const post = state.userFeeds[postIndex];
            post.isLikedByMe = action.payload.isLiked;
            post.likesCount += action.payload.isLiked ? 1 : -1;
          }
        }
      },

  setAddComment(state, action) {
    if (state.userFeeds) {
      const postIndex = state.userFeeds.findIndex(post => post.id === action.payload.postId);
      if (postIndex !== -1) {
        const post = state.userFeeds[postIndex];
        post.commentsCount += 1
      }
    }
  },

  setDeleteComment(state, action) {
    if (state.userFeeds) {
      const postIndex = state.userFeeds.findIndex(post => post.id === action.payload.postId);
      if (postIndex !== -1) {
        const post = state.userFeeds[postIndex];
        post.isLikedByMe = action.payload.isLiked;
        if(post?.commentsCount>0){
          post.commentsCount += -1;
        }
        
      }
    }
  },

  setPostAfterBuyPost(state, action){
    if (state.userFeeds) {
      const postIndex = state.userFeeds.findIndex(post => post.id === action.payload?.post?.id);
      if (postIndex !== -1) {
          state.userFeeds.splice(postIndex, 1, action.payload.post);
      }
    }
  },

  setAddFollowingInUserFeed(state, action) {
    if (state.userFeeds) {
      state.userFeeds.forEach(post => {
        if (post.creatorId === action.payload?.creatorId) {
          post.creator.following = true;
        }
      });
    }
  },

  setRemoveFollowingInUserFeed(state, action) {
    if (state.userFeeds) {
      state.userFeeds.forEach(post => {
        if (post.creatorId === action.payload?.creatorId) {
          post.creator.following = false;
        }
      });
    }
  },

  addMoreFeedsToUserFeeds(state, action){
    if (state.userFeeds) {
      state.userFeeds.push(...action.payload);
    }
  },

  deleteFeedById(state, action){
    if (state.userFeeds) {
      const postIndex = state.userFeeds.findIndex(post => post.id === action.payload);
      if (postIndex !== -1) {
        state.userFeeds.splice(postIndex, 1);
    }
    }
  }

}
});

export const { setUserFeeds, setLikePost, setAddComment, setDeleteComment, setPostAfterBuyPost, setAddFollowingInUserFeed, setRemoveFollowingInUserFeed, addMoreFeedsToUserFeeds, deleteFeedById} =
    userFeedSlice.actions;

export const userFeedReducer = userFeedSlice.reducer;
