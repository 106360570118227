import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colors, typography } from "../../utils/styles";

const NoSubscriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 265px;
  height: 281px;
  gap: 8px;
`;

const Image = styled("img")``;

const Title = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
`;

const Subtitle = styled(Typography)`
  color: ${colors.gray[80]};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const ExploreButton = styled(Button)`
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  border-radius: 8px;
  border: 1px solid ${colors.violet[100]};
  background: ${colors.violet[100]};
`;

const NoSubscription = () => {
  const navigate = useNavigate();

  const handleOnClickExplore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate("/");
  };
  return (
    <NoSubscriptionContainer>
      <Image src="/Images/illustration_reading.png" alt="no subscription" />
      <Title>No Subscription Purchased</Title>

      <Subtitle>
        Explore Creator & purchase Subscription to access exclusive content
      </Subtitle>

      <ExploreButton onClick={handleOnClickExplore}>Explore</ExploreButton>
    </NoSubscriptionContainer>
  );
};

export default NoSubscription;
