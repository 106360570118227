import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateSubscriptionHeader from "./CreateSubscriptionHeader";
import CreateSubscriptionForm from "./CreateSubscriptionForm";
import { InfoSvg } from "../../../assets/SvgIcons";
import { Box, Button, styled, Typography } from "@mui/material";
import CustomTooltip from "../../../utils/CustomTooltip";
import { colors, typography } from "../../../utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { SubscriptionType } from "../../../types";
import { setSubscriptions } from "../../../redux/creatorSubscriptionSlice";
import creatorApi from "../../../api/creator/creatorApi";
import creatorProfileServiceApi from "../../../api/creator/creatorProfileServiceApi";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
`;

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  min-height: auto;
  max-height: 100vh;
  margin-bottom: 62px;
`;

const TitleContainer = styled(Box)`
  padding: 0 16px;
  position: sticky;
  top: 8vh;
  left: 0;
  z-index: 12;
  background: ${colors.white};
`;

const TitleAndTooltipContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Typography)`
  color: ${colors.black[200]};
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;

const SubtitleContainer = styled(Box)``;

const Subtitle = styled(Typography)`
  color: ${colors.gray[80]};
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
  align-self: stretch;
`;

const BottomButtonsContainer = styled(Box)`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
`;
const DoneButton = styled(Button)<{
  backgroundColor: string;
  textColor: string;
}>`
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
  display: flex;
  height: 56px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 0;
  width: 100%;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  &:hover {
    background-color: ${(props) => props.backgroundColor};
  }
`;

const SubscriptionSuccessCardContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 56px;
  left: 17px;
  padding: 12px 0;
  z-index: 12;
  background: ${colors.white};
`;

const CreateSubscriptionFormContainer = styled(Box)`
  overflow: visible;
  height: auto;
  min-height: 55vh;
  max-height: 145vh;
`;

interface CreateSubscriptionProps {
  headerTitleText: string;
  title: string;
  subtitle: string;
  tooltipText: string;
  cancelTextTitle: string;
  cancelTextSubtitle: string;
}

const initialSubscription = {
  id: "",
  amountInYC: 0,
  audioCall: false,
  videoCall: false,
  directMessage: false,
  audioCallMinutes: 0,
  videoCallMinutes: 0,
  directMessageMinutes: 0,
  creatorId: "",
  version: 0,
  discount: null,
};

const CreateSubscription: React.FC<CreateSubscriptionProps> = ({
  headerTitleText,
  title,
  subtitle,
  tooltipText,
  cancelTextTitle,
  cancelTextSubtitle,
}) => {
  const navigate = useNavigate();
  const handleOnClickGoBackButton = () => {
    navigate("/creator/profile");
  };

  const dispatch = useDispatch<AppDispatch>();
  const [subscriptionData, setSubscriptionData] =
    useState<SubscriptionType>(initialSubscription);
    const {id}=useSelector((state: RootState)=> state.creatorProfile.profile);

  const { subscriptions } = useSelector(
    (state: RootState) => state.creatorSubscription
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleOnClickDoneButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleOnClickCreatePlaneButton = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
  };

  const handleDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    setSubscriptionData((prev) => {
      return { ...prev, [name]: Number(value) };
    });
  };

  const handleDeleteVideoCall = async () => {
    setSubscriptionData((prev) => {
      return { ...prev, videoCallMinutes: 0, videoCall: false };
    });
  };

  const handleDeleteAudioCall = async () => {
    setSubscriptionData((prev) => {
      return { ...prev, audioCallMinutes: 0, audioCall: false };
    });
  };

  const handleDeleteDirectMessage = async () => {
    setSubscriptionData((prev) => {
      return { ...prev, directMessageMinutes: 0, directMessage: false };
    });
  };

  const handleAudioCallValueSet = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (subscriptionData?.audioCallMinutes) {
      openSnackBar("Audio call rates are set successfully.");
      setSubscriptionData((prev) => {
        return { ...prev, audioCall: true };
      });
    }
    return;
  };

  const handleVideoCallValueSet = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (subscriptionData?.videoCallMinutes) {
      openSnackBar("Video call rates are set successfully.");
      setSubscriptionData((prev) => {
        return { ...prev, videoCall: true };
      });
    }
    return;
  };

  const handleDmValueSet = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (subscriptionData?.directMessageMinutes) {
      openSnackBar("Direct Message rates are set successfully.");
      setSubscriptionData((prev) => {
        return { ...prev, directMessage: true };
      });
    }
    return;
  };

  const openSnackBar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSaveSubscription = async () => {
    try {
      dispatch(
        setSubscriptions({
          ...subscriptionData,
        })
      );
      let subscription = { ...subscriptionData };
      const { id, version, discount, creatorId, ...restData } = subscription;
      if (subscriptionData.id) {
        const response = await creatorProfileServiceApi.updateSubscription(
          subscriptionData.id,
          restData
        );
      } else {
        const response = await creatorProfileServiceApi.createSubscription(
          restData
        );
      }
      navigate("/creator/subscription-plan-details");
    } catch (err) {
      console.log("error", err);
    }
  };

  const checkForActiveButton = () => {
    const flag =
      subscriptionData.amountInYC &&
      ((subscriptionData.audioCallMinutes && subscriptionData.audioCall) ||
        (subscriptionData.directMessageMinutes &&
        subscriptionData.directMessage) ||
        (subscriptionData.videoCallMinutes && subscriptionData.videoCall));
    return flag ? true : false;
  };

  const fetchSubscriptions = async (creatorId: string) => {
    try {
      const response = await creatorProfileServiceApi.getCreatorSubscriptions(creatorId);
      dispatch(setSubscriptions(response.data.data.subscription));
    } catch (error) {
      console.error("Failed to get subscriptions", error);
    } 
  };


  useEffect(() => {
    if (subscriptions) {
      let data = {
        id: subscriptions.id ? subscriptions.id : "",
        amountInYC: subscriptions.amountInYC ? subscriptions.amountInYC : 0,
        audioCall: subscriptions.audioCall ? subscriptions.audioCall : false,
        videoCall: subscriptions.videoCall ? subscriptions.videoCall : false,
        directMessage: subscriptions.directMessage
          ? subscriptions.directMessage
          : false,
        audioCallMinutes: subscriptions.audioCallMinutes
          ? subscriptions.audioCallMinutes
          : 0,
        videoCallMinutes: subscriptions.videoCallMinutes
          ? subscriptions.videoCallMinutes
          : 0,
        directMessageMinutes: subscriptions.directMessageMinutes
          ? subscriptions.directMessageMinutes
          : 0,
        version: subscriptions.version ? subscriptions.version : 0,
        discount: subscriptions.discount ? subscriptions.discount : "",
        creatorId: subscriptions.creatorId ? subscriptions.creatorId : "",
      };
      setSubscriptionData(data);
    }
  }, [dispatch, subscriptions]);

  useEffect(() => {
    if (id) {
      fetchSubscriptions(id)
    }
  }, [dispatch, id]);


  console.log("creator id of creator", id);

  return (
    <Container>
      <CreateSubscriptionHeader
        title={headerTitleText}
        cancelTextTitle={cancelTextTitle}
        cancelTextSubtitle={cancelTextSubtitle}
        handleOnClickGoBackButton={handleOnClickGoBackButton}
      />
      <ContentContainer>
        <TitleContainer>
          <TitleAndTooltipContainer>
            <Title>{title}</Title>
            <CustomTooltip
              arrow={true}
              title={tooltipText}
              placement="bottom-start"
            >
              <Typography
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InfoSvg />
              </Typography>
            </CustomTooltip>
          </TitleAndTooltipContainer>
          <SubtitleContainer>
            <Subtitle>{subtitle}</Subtitle>
          </SubtitleContainer>
        </TitleContainer>
        <CreateSubscriptionFormContainer>
          <CreateSubscriptionForm
            subscriptionData={subscriptionData}
            handleDataChange={handleDataChange}
            snackbarOpen={snackbarOpen}
            snackbarMessage={snackbarMessage}
            handleAudioCallValueSet={handleAudioCallValueSet}
            handleVideoCallValueSet={handleVideoCallValueSet}
            handleDmValueSet={handleDmValueSet}
            handleDeleteDirectMessage={handleDeleteDirectMessage}
            handleDeleteAudioCall={handleDeleteAudioCall}
            handleDeleteVideoCall={handleDeleteVideoCall}
            handleCloseSnackbar={handleCloseSnackbar}
          />
        </CreateSubscriptionFormContainer>
      </ContentContainer>
      <BottomButtonsContainer>
        <DoneButton
          onClick={handleSaveSubscription}
          disabled={!checkForActiveButton()}
          backgroundColor={
            checkForActiveButton()
              ? colors.violet[100]
              : "rgba(230, 230, 230, 1)"
          }
          textColor={
            checkForActiveButton() ? colors.gray[100] : colors.gray[90]
          }
        >
          {subscriptionData.id ? "Update Plan" : "Create Plan"}
        </DoneButton>
      </BottomButtonsContainer>
    </Container>
  );
};

export default CreateSubscription;
