import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, styled, Typography } from "@mui/material";
import { colors } from "../../utils/styles";
import { RootState } from "../../store/store";
import { setTimeLeft } from "../../redux/timerSlice";

const TimeContainer = styled(Box)`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px 16px;
`;

interface TimerProps {
  ttl: string;
}

export const Timer: React.FC<TimerProps> = ({ ttl }) => {
  const dispatch = useDispatch();
  const { timeLeft } = useSelector((state: RootState) => state.timer);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +ttl - +new Date().getTime();
      if (difference > 0) {
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
        return `${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;
      }
      return "00:00";
    };

    const timer = setInterval(() => {
      dispatch(setTimeLeft(calculateTimeLeft()));
    }, 1000);

    return () => clearInterval(timer);
  }, [ttl, dispatch]);

  return (
    <>
      <TimeContainer>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            fontSize: "12px",
            fontFamily: "Poppins",
            color: colors.orange[300],
          }}
        >
          Confirm your slot in
        </Typography>
        <img src="/Icons/clockIcon.svg" alt="clock" />
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            fontSize: "12px",
            fontFamily: "Poppins",
            color: colors.orange[300],
          }}
        >
          {timeLeft}
        </Typography>
      </TimeContainer>
    </>
  );
};
