import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentStep: 0,
  title: "Become a Creator",
  loading: false,
};

export const becomeCreatorStep = createSlice({
  name: "becomeCreator",
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
      return state;
    },
    setCurrentTitle: (state, action) => {
      state.title = action.payload;
      return state;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
      return state;
    },
  },
});

export const { setCurrentStep, setCurrentTitle, setLoading } =
  becomeCreatorStep.actions;

export const getCurrentStep = (state: any) => state.becomeCreator.currentStep;
export const getCurrentTitle = (state: any) => state.becomeCreator.title;

export default becomeCreatorStep.reducer;
