import React from "react";
import { CreatorBookings } from "../../components/Creator/CreatorBookings/CreatorBookings";
import { useLocation } from "react-router-dom";
import CheckCreatorBookings from "../../components/Creator/CreatorBookings/CheckCreatorBookings/CheckCreatorBookings";

const Bookings = () => {
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  return (
    <>
      {userRole === "creator" ? <CreatorBookings /> : <CheckCreatorBookings />}
    </>
  );
};

export { Bookings };