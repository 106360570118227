
import { ApiService } from '../ApiClient';
import {userAxiosInstance} from '../axiosInstance';
import '../interceptors/userAuthInterceptors';

class WalletService extends ApiService {
  constructor() {
    super(userAxiosInstance);
  }

  getHistory(){
    return this.axios.get('payment/transaction-history/user?page=1&pageSize=15')
  }
}

export default new WalletService();
