import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import { RequestCloseModal, RequestOpenModal } from "../../types";
import DynamicStyledInput from "../../components/StyledComponent/DynamicStyledInput";
import { colors, typography } from "../../utils/styles";
import { useSnackbar } from "../../context/SnackbarContext";
import creatorSettingsApi from "../../api/settings/creatorSettingsApi";
import userSettingsApi from "../../api/settings/userSettingsApi";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { useLocation, useNavigate } from "react-router-dom";
const Container = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const Content = styled(Box)`
  display: flex;
  width: 310px;
  flex-direction: column;
  border-radius: 12px;
  background: var(--Background-card, ${colors.white});
  align-items: center;
`;
const ContentUpperContainer = styled(Box)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;
const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const Title = styled(Typography)`
  color: var(--Text-Primary, ${colors.black[200]});
  text-align: center;
  font-size: ${typography.font_size.xl}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const HandleNameText = styled(Typography)`
  color: var(--Text-Secondary, ${colors.gray[80]});
  text-align: center;
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
const CheckboxContainer = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const CheckboxLabel = styled(Typography)`
  color: var(--Text-Secondary, ${colors.gray[80]});
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
  flex: 1 0 0;
`;
const NewCheckbox = styled(Checkbox)`
  padding: 4px 0;
  &.Mui-checked {
    color: ${colors.green[100]};
  }
  &[data-testid="CheckBoxOutlineBlankIcon"] {
    border: 1px solid ${colors.red[100]};
  }
`;
const OtherReasonContainer = styled(Box)`
  display: flex;
  height: 123px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
`;
const Text = styled(Typography)`
  color: var(--Text-Primary, ${colors.black[200]});
  font-size: ${typography.font_size.sm}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;
const CounterText = styled(Typography)`
  color: ${colors.gray[80]};
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.regular};
`;
const InputLabelContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
`;
const ButtonsContainer = styled(Box)`
  display: flex;
  padding: 0px 16px 8px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
const CancelButton = styled(Button)`
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  width: 135px;
  color: var(--Text-Brand-Primary, ${colors.violet[100]});
  text-align: center;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const ReportButton = styled(Button)`
  display: flex;
  height: 48px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  width: 135px;
  border-radius: 8px;
  background: var(--Background-brand-primary, ${colors.violet[100]});
  overflow: hidden;
  color: var(--Text-Inverted, ${colors.white});
  text-align: center;
  text-overflow: ellipsis;
  font-size: ${typography.font_size.base}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.default};
`;
const ReasonsTextArray = [
  "Technical Issues",
  "Switching to another platform",
  "I don't like the content",
  "I need to save money",
  "Tick this for permissions",
  "Other (Please explain below)",
];
interface ReportPostProps {
  requestOpenModal: RequestOpenModal;
  requestCloseModal: RequestCloseModal;
}
type ReportType = {
  reasonsTextArray: string[];
  otherReason: string;
};
const initialState = {
  reasonsTextArray: [],
  otherReason: "",
};
const DeleteAccountModal: React.FC<ReportPostProps> = ({
  requestOpenModal,
  requestCloseModal,
}) => {
  const { openSnackBar } = useSnackbar();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const [deactivationState, setDeactivationState] =
    useState<ReportType>(initialState);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const truncatedValue = value.length > 50 ? value.substring(0, 50) : value;
    setDeactivationState((prevState) => ({
      ...prevState,
      otherReason: truncatedValue,
    }));
  };

  const handleLogout = () => {
    localStorage.removeItem(
      userRole === "user" ? "accessToken" : "creatorAccessToken"
    );
    navigate("/login");
  };
  const handleOnClickDeactivate = async () => {
    try {
      requestCloseModal();
      openSnackBar("Account deleted successfully", "success");
      const body = {
        reasons: [...deactivationState?.reasonsTextArray, deactivationState?.otherReason],
      };
      let response =
      userRole === "creator"
        ? await creatorSettingsApi.deleteAccount(body)
        : await userSettingsApi.deleteAccount(body);
      handleLogout();
    } catch (err) {
      console.error("err", err);
      openSnackBar("Error in deleting account", "error");
    }
  };
  const handleCheckBoxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    if (checked) {
      setDeactivationState((prevState) => ({
        ...prevState,
        reasonsTextArray: [...prevState.reasonsTextArray, value],
      }));
    } else {
      setDeactivationState((prevState) => ({
        ...prevState,
        reasonsTextArray: prevState.reasonsTextArray?.filter(
          (item: string) => item !== value
        ),
      }));
    }
  };
  return (
    <Container>
      <Content>
        <ContentUpperContainer>
          <img src="/Images/Illustration_loving.svg" alt="" />
          <TitleContainer>
            <Title>We are sad to see you go!</Title>
            <HandleNameText>Let us know why?</HandleNameText>
          </TitleContainer>
          <ContentContainer>
            {ReasonsTextArray?.map((text, index) => (
              <CheckboxContainer key={index}>
                <NewCheckbox value={text} onChange={handleCheckBoxClick} />
                <CheckboxLabel>{text}</CheckboxLabel>
              </CheckboxContainer>
            ))}
          </ContentContainer>
          <OtherReasonContainer>
            <Text>Other</Text>
            <InputLabelContainer>
              <DynamicStyledInput
                name="comment"
                placeholder="Why are you leaving?"
                value={deactivationState?.otherReason}
                onChange={handleChange}
                height="72px"
                width="100%"
                padding="12px"
                border="1px solid #9E9E9E"
                fullWidth
                disabled={
                  !deactivationState.reasonsTextArray.includes(
                    "Other (Please explain below)"
                  )
                }
                focussed={deactivationState.reasonsTextArray.includes(
                  "Other (Please explain below)"
                )}
              />
              <CounterText
                sx={{ marginLeft: "8px" }}
              >{`${deactivationState.otherReason?.length}/50`}</CounterText>
            </InputLabelContainer>
          </OtherReasonContainer>
        </ContentUpperContainer>
        <ButtonsContainer>
          <CancelButton onClick={requestCloseModal}>Cancel</CancelButton>
          <ReportButton onClick={handleOnClickDeactivate}>
            Delete 
          </ReportButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};

export default DeleteAccountModal;
