import React from 'react'
import { PageTagComponent } from '../Common/PageTagComponent'
import {Box, Typography, styled} from '@mui/material';
import ToogleButton from '../Common/ToggleButton';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { useLocation } from 'react-router-dom';
import creatorSettingsApi from '../../api/settings/creatorSettingsApi';
import userSettingsApi from '../../api/settings/userSettingsApi';
import { setNewPostNotification, setDirectMessagetNotification,  setInAppNotification} from '../../redux/settingsSlice';
import { typography, colors } from '../../utils/styles';
const Container = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
const Header = styled(Box)``;
const Items = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const ItemsContainer = styled(Box)`
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
`;
const TextContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;
const Title = styled(Typography)`
    color: ${colors.black[200]};
    font-family: Poppins;
    font-size: ${typography.font_size.sm}px;
    font-style: normal;
    font-weight: ${typography.font_weight.semiBold};
    line-height: ${typography.line_height.default};
    letter-spacing: ${typography.letter_spacing.standard};
`;
const Subtitle = styled(Typography)`
    color: ${colors.black[200]};
    font-family: Poppins;
    font-size: ${typography.font_size.xs}px;
    font-style: normal;
    font-weight: ${typography.font_weight.regular};
    line-height: ${typography.line_height.default};
    letter-spacing: ${typography.letter_spacing.normal};
`;
const IconContainer = styled(Box)``;

const NotificationSetting = () => {
    const dispatch=useDispatch<AppDispatch>();
    const location=useLocation();
    const userRole=location.pathname.includes("user") ? "user" : "creator";
    const {notifications}= useSelector((state:RootState)=>state.settings);
    const handleInAppNotifications=async(event: React.ChangeEvent<HTMLInputElement>)=>{
        try{
        event.preventDefault();
        let { checked }=event.target;
        dispatch(setInAppNotification(checked));
        const body={
            type:"IN_APP_NOTIFICATION", value: checked
        }
        userRole==="creator" ? await creatorSettingsApi.updateNotificationSettings(body) : await userSettingsApi.updateNotificationSettings(body);
    }catch(err){console.error(err)}
    };
    const handleDMNotifications=async(event: React.ChangeEvent<HTMLInputElement>)=>{
        try{
        event.preventDefault();
        let { checked }=event.target;
        dispatch(setDirectMessagetNotification(checked));
        const body={
            type:"DIRECT_MESSAGE_NOTIFICATION", value: checked
        }
        userRole==="creator" ? await creatorSettingsApi.updateNotificationSettings(body) : await userSettingsApi.updateNotificationSettings(body);
    }catch(err){console.error(err)}
    }
    const handlePostNotifications=async(event: React.ChangeEvent<HTMLInputElement>)=>{
        try{
        event.preventDefault();
        let { checked }=event.target;
        dispatch(setNewPostNotification(checked));
        const body={
            type:"NEW_POST_NOTIFICATION", value: checked,
        }
        userRole==="creator" ? await creatorSettingsApi.updateNotificationSettings(body) : await userSettingsApi.updateNotificationSettings(body);
    }catch(err){console.error(err)}
    }

  return (
       <Container>
            <Header>
                <PageTagComponent>
                    Notifications
                </PageTagComponent>
            </Header>
            <Items>
                <ItemsContainer>
                    <TextContainer>
                        <Title>
                            In App Notifications
                        </Title>
                        <Subtitle>
                            Get In app notifications to find out what’s going on when you’re not on Clout. You can turn them off anytime.
                        </Subtitle>
                    </TextContainer>
                    <IconContainer>
                        <ToogleButton handleOnChange={handleInAppNotifications} checked={notifications.IN_APP_NOTIFICATION}/>
                    </IconContainer>
                </ItemsContainer>
                <ItemsContainer>
                    <TextContainer>
                        <Title>
                        Direct Messages
                        </Title>
                        <Subtitle>
                        Receive a notification once every other day for any unread messages
                        </Subtitle>
                    </TextContainer>
                    <IconContainer>
                        <ToogleButton handleOnChange={handleDMNotifications} checked={notifications.DIRECT_MEASAGE_NOTIFICATION }/>
                    </IconContainer>
                </ItemsContainer>
                <ItemsContainer>
                    <TextContainer>
                        <Title>
                        New Posts
                        </Title>
                        <Subtitle>
                        Receive a notification for every new post from creators you follow
                        </Subtitle>
                    </TextContainer>

                    <IconContainer>
                        <ToogleButton handleOnChange={handlePostNotifications} checked={notifications.NEW_POST_NOTIFICATION}/>
                    </IconContainer>
                </ItemsContainer>
            </Items>
       </Container>
  )
}

export default NotificationSetting