import { ApiService } from "../ApiClient";
import { creatorAxiosInstance } from "../axiosInstance";
import "../interceptors/creatorAuthInterceptors";

class CreatorCallApi extends ApiService {
  constructor() {
    super(creatorAxiosInstance);
  }

  startCall(payload: any) {
    return this.axios
      .put("creator-services/call/start/creator", payload)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  endCall(payload: any) {
    return this.axios
      .put("creator-services/call/end/creator", payload)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}

export default new CreatorCallApi();
