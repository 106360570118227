export const getVideoDimensions = (file: File): Promise<{ width: number, height: number,duration:number }> => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      const fileUrl = URL.createObjectURL(file);
  
      video.src = fileUrl;
  
      video.onloadedmetadata = () => {
        resolve({
          width: video.videoWidth,
          height: video.videoHeight,
          duration: video.duration
        });
        URL.revokeObjectURL(fileUrl);  // Clean up the URL object
      };
  
      video.onerror = () => {
        reject(new Error('Failed to load video metadata'));
      };
    });
  };
  