import { BookingsType, GroupedBookings, CheckBookingType } from "../types";

export const groupByMonth = (data: CheckBookingType[]): GroupedBookings => {
  const groupedBookings = data.reduce<GroupedBookings>((acc, booking) => {
    const date = new Date(booking.date);
    const monthYear = date.toLocaleString("default", {
      month: "short",
      year: "numeric",
    });

    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(booking);
    return acc;
  }, {} as GroupedBookings);

  // Sort grouped bookings by month and year
  const sortedGroupedBookings = Object.keys(groupedBookings)
    .sort((a, b) => {
      const dateA = new Date(a); // Convert "Jan 2024" into Date
      const dateB = new Date(b);
      return dateA.getTime() - dateB.getTime();
    })
    .reduce<GroupedBookings>((acc, key) => {
      acc[key] = groupedBookings[key];
      return acc;
    }, {} as GroupedBookings);

  return sortedGroupedBookings;
};
