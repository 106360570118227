import { ApiService } from '../ApiClient';
import {creatorAxiosInstance} from '../axiosInstance';
import "../interceptors/creatorAuthInterceptors";

class PaymentService extends ApiService {
  constructor() {
    super(creatorAxiosInstance);
  }

  get_Wallet_Details(){
    return this.axios.get(`/payment/wallet`);
  }

  get_CC_Topup_Options() {
    return this.axios.get(`/payment/add-money-config`);
  }

  get_CC_Billing_Detail(ccid:string){
    return this.axios.get(`/payment/add-money-config/${ccid}`)
  }

  create_Order_And_SessionId(orderDetails:object){
    return this.axios.post('/payment/payment-gateway/create-order',orderDetails)
  }

  verify_Payment_Status(orderId:string){
    return this.axios.get(`payment/payment-history/status?orderId=${orderId}`)
  }
 
}

export default new PaymentService();