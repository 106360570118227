import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import { set_Cover_Photo, set_Free_Photos, setView } from "../../redux/imageUploadSlice";
import styled from "@emotion/styled";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useToast from "../../hooks/useToast";
const PageHeader = styled(Stack)`
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  padding-inline:12px;
`

const AddFreePhotos = () =>{
    const dispatch = useDispatch();
    const {ToastMessage,handleOpenToast} = useToast()
    const croppedImages = useSelector((state:RootState)=>state.imagePost.croppedImages);
    const noOfFreePhotos = Math.round(croppedImages.length * 0.3);
    const freePhotos:Array<string> = useSelector((state:RootState)=>state.imagePost.freePhotos)
    
    const updateFreePhotos = (photo:string)=>{
        let selectedFreePhotos = [...freePhotos];
        
        if(!selectedFreePhotos.includes(photo)){
            if(selectedFreePhotos.length === noOfFreePhotos){
                handleOpenToast(`You can only select ${noOfFreePhotos} preview photos`,'warning');
                return;
            }
            selectedFreePhotos.push(photo)
            dispatch(set_Free_Photos(selectedFreePhotos))
        }else{
            
            dispatch(set_Free_Photos(selectedFreePhotos.filter((photoStr:string)=>photoStr!==photo)))
        }
    }
    const onClickNext = () =>{
        dispatch(setView('COVER_PHOTOS'));
    }
    const onClickBackBtn = () =>{
        dispatch(setView('CAROUSEL_VIEW'));
    }
    return(
        <Box>
            <ToastMessage/>
            <PageHeader
        sx={{background: "#DCD7F5",height:'56px',boxShadow:'none'}}>
        <ArrowBackIosNewIcon onClick={onClickBackBtn}/>
        <Typography fontSize="16px" fontWeight={700}>
        Select Free Photos
        </Typography>
        <Typography fontSize="12px" fontWeight={700} onClick={onClickNext}>Next</Typography>
      </PageHeader>
           <Stack direction='row' gap="2px" flexWrap='wrap' mt="2px">
           {croppedImages.map((image:any,idx:any)=>{
                return (
                   <Box position='relative'  flex={`1 1 calc(33.33% - 2px)`}  sx={{ 
                    overflow: 'hidden', 
                  }}>
                    <Checkbox 
                        checked ={freePhotos.includes(image)}
                        color="success"
                        sx={{position:'absolute', right:0,top:0,color:'#fff'}}
                        onChange={()=>updateFreePhotos(image)}
                        />
                     <img 
                        src ={image} 
                        key={`image${idx}`} 
                        style={{
                            width: '100%', 
                            height: '100%', 
                            // objectFit: 'cover',
                          }}
                        alt={`image${idx}`}
                        />
                   </Box>
                )
            })}
           </Stack>
        </Box>
    )
}
export {AddFreePhotos}