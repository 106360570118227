import React, { useEffect, useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./post.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box, Button, Typography } from "@mui/material";
import { colors, typography } from "../../utils/styles";
import { CommentIcon, GiftIcon, ShareIcon } from "../../assets/SvgIcons";
import ImagePost from "./ImagePost/ImagePost";
import VideoPost from "./VideoPost/VideoPost";
 
interface DataProps {
  id: number;
  img: string;
  name: string;
  handle: string;
  title: string;
  description: string;
  likes: number;
  comments: boolean;
  totalComments: number;
  user_type: string;
  video: string;
  tips: string;
  userImg: string;
  liked: boolean;
  images: string[];
  postTypeId: number;
}
 
interface PostComponentProps {
  data: DataProps;
  isFollowed?: boolean;
}
 
const Post: React.FC<PostComponentProps> = ({ data, isFollowed }) => {
  const [item, setItem] = useState<DataProps>(data);
 
  const [viewText, setViewText] = useState<boolean>(false);
 
  const handleOnClickLikeButton = () => {
    if (item) {
      if (item.liked) {
        const declikeCount = item?.likes - 1;
        setItem({ ...item, ["liked"]: false, ["likes"]: declikeCount });
      } else {
        const likeCount = item?.likes + 1;
        setItem({ ...item, ["liked"]: true, ["likes"]: likeCount });
      }
    }
  };
 
  const handleViewText = () => {
    setViewText((prev) => !prev);
  };
 
  const handleOnClickShareButton = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check out this video!",
          text: "Watch this amazing video",
          url: window.location.href,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.log("Web Share API is not supported in your browser.");
    }
  };
 
  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);
 
  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
        padding: "0",
      }}
      key={item?.id}
    >
      <Box className="post_header" sx={{ padding: ".5rem 1rem" }}>
        <Box
          sx={{
            display: "flex",
            gap: ".5rem",
            alignItems: "center",
            flex: "1",
          }}
        >
          <ListItemAvatar sx={{ margin: "0", minWidth: "auto" }}>
            <Avatar
              sx={{ width: "2.8rem", height: "2.8rem" }}
              alt="user "
              src={item?.img}
            />
          </ListItemAvatar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: typography.font_weight.semiBold,
                color: colors.black[200],
                letterSpacing: ".02rem",
              }}
            >
              {item?.name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontWeight: typography.font_weight.regular,
                color: colors.gray[700],
                letterSpacing: ".02rem",
              }}
            >
              {item?.handle}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              background: colors.violet[50],
              padding: "8px 16px",
              borderRadius: "12px",
              border: "none",
              outline: "none",
              opacity: isFollowed ? "0" : "1",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: colors.white,
                fontWeight: typography.font_weight.bold,
                fontSize: typography.font_size.sm,
                letterSpacing: ".02rem",
              }}
            >
              Follow
            </Typography>
          </Button>
          <Button
            sx={{
              cursor: "pointer",
              color: "black",
              letterSpacing: ".02rem",
              display: "flex",
              minWidth: "24px",
              height: "24px",
              padding: "4.5px 0px 5.5px 0px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MoreVertIcon />
          </Button>
        </Box>
      </Box>
 
      <Box sx={{ margin: ".5rem 0", padding: "0 1rem" }}>
        <Typography
          sx={{
            color: "#616161",
            letterSpacing: "0.02rem",
            display: "ruby-text !important",
          }}
        >
          {item && item.description.length > 185 ? (
            <>
              {viewText
                ? item.description
                : `${item.description.substring(0, 186)}....`}
              <Typography
                variant="body1"
                sx={{
                  fontSize: typography.font_size.xs,
                  fontWeight: typography.font_weight.bold,
                  color: colors.brand_primary,
                  marginLeft: "1rem",
                }}
                onClick={handleViewText}
              >
                {viewText && item.description.length > 185
                  ? "See less"
                  : "See more"}
              </Typography>
            </>
          ) : (
            <>{item?.description}</>
          )}
        </Typography>
      </Box>
 
      {item && item.images.length ? (
        <ImagePost item={item} />
      ) : (
        <VideoPost key={item?.id} item={item} />
      )}
 
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: "0.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "6px 1rem",
            gap: "12px",
          }}
        >
          <Button
            sx={{
              padding: "0",
              width: "1rem",
              minWidth: "0",
              height: "1rem",
              minHeight: "0",
              cursor: "pointer",
              color: item?.liked ? "red" : "black",
            }}
            onClick={handleOnClickLikeButton}
          >
            {item?.liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </Button>
 
          <Button
            sx={{
              padding: "0",
              width: "1.3rem",
              minWidth: "0",
              height: "1rem",
              minHeight: "0",
              cursor: "pointer",
              color: "black",
            }}
          >
            <CommentIcon />
          </Button>
 
          <Button
            sx={{
              padding: "0",
              width: "1.3rem",
              minWidth: "0",
              height: "1rem",
              minHeight: "0",
              cursor: "pointer",
            }}
            onClick={handleOnClickShareButton}
          >
            <ShareIcon />
          </Button>
 
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: "1",
              justifyContent: "end",
              gap: "0.5rem",
            }}
          >
            <Typography variant="body2">Your Tips: Rs.{item?.tips}</Typography>
            <Button
              sx={{
                background: colors.violet[50],
                color: colors.white,
                fontWeight: typography.font_weight.bold,
                fontSize: typography.font_size.xs,
                display: "flex",
                gap: ".3rem",
                alignItems: "center",
                padding: "8px 10px",
              }}
            >
              <GiftIcon />
 
              <Typography
                variant="h3"
                sx={{
                  fontWeight: typography.font_weight.bold,
                  color: colors.white,
                  fontSize: typography.font_size.xs,
                  letterSpacing: ".02rem",
                  wordWrap: "break-word",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                Send Tip
              </Typography>
            </Button>
          </Box>
        </Box>
 
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "6px",
            padding: "0 1rem",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: typography.font_weight.bold,
              color: colors.black[200],
              fontSize: typography.font_size.xs + 1,
              letterSpacing: ".02rem",
            }}
          >
            {item?.likes} Likes
          </Typography>
 
          {item?.comments && (
            <Typography
              variant="body2"
              sx={{
                fontWeight: typography.font_weight.regular,
                color: colors.gray[700],
                fontSize: typography.font_size.sm,
                letterSpacing: ".02rem",
              }}
            >
              View all {item?.totalComments} comments
            </Typography>
          )}
 
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: ".5rem",
              padding: "6px 0",
            }}
          >
            <Avatar
              alt="user "
              src={item?.userImg}
              sx={{ width: "2rem", height: "2rem" }}
            />
            <Typography
              variant="body2"
              sx={{
                fontWeight: typography.font_weight.regular,
                color: colors.gray[700],
                fontSize: typography.font_size.sm,
                letterSpacing: ".02rem",
              }}
            >
              Add a comment...
            </Typography>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};
 
export default React.memo(Post);