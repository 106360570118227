import React, { useState, useEffect, useRef, useCallback } from "react";
import NoSubscription from "./NoSubscription";
import { Box, styled } from "@mui/material";
import CommonSubscriptionCard from "./CommonSubscriptionCard";
import { MainSubscription, Pagination } from "../../types";
import creatorSubscriptionServiceApi from "../../api/creator/subscriptionServiceApi";
import userSubscriptionServiceApi from "../../api/user/subscriptionServiceApi";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 0 16px;
  width: 100%;
`;

interface SubscriptionHistoryProps {
  historySubscriptions: {
    subscriptions: MainSubscription[];
    pagination: Pagination;
  };
  userRole: string;
}

const SubscriptionHistory: React.FC<SubscriptionHistoryProps> = ({
  historySubscriptions,
  userRole
}) => {
  const [subscriptionData, setSubscriptionData] = useState<MainSubscription[]>(
    []
  );

  const [page, setPage] = useState<number>(historySubscriptions.pagination.currentPage);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (historySubscriptions.subscriptions) {
      setSubscriptionData(historySubscriptions?.subscriptions);
    }
  }, [historySubscriptions?.pagination.currentPage]);

  const fetchMoreSubscriptions = useCallback(async () => {
    try {
      const pageSize = "2";
      const response = userRole === 'creator' ? 
      await creatorSubscriptionServiceApi.getHistorySubscription(
        page.toString(),
        pageSize
      )
     : 
     await userSubscriptionServiceApi.getHistorySubscription(
      page.toString(),
      pageSize
    );
    setSubscriptionData((prevData) => [
      ...prevData,
      ...response.data.data.subscriptions,
    ]);
    } catch (error) {
      console.error("Failed to fetch more subscriptions:", error);
    }
  }, [page]);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    });

    if (lastElementRef.current) {
      observer.current.observe(lastElementRef.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [lastElementRef, fetchMoreSubscriptions]);

  useEffect(() => {
    if (page > 1) {
      fetchMoreSubscriptions();
    }
  }, [page, fetchMoreSubscriptions]);

  return (
    <Container>
        {subscriptionData?.length ? (
            subscriptionData.map((item: MainSubscription, index: number) => {
              if (index === subscriptionData.length - 1) {
                return (
                  <div ref={lastElementRef} key={item.id} style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CommonSubscriptionCard
                      subscriptionPlanData={item}
                      flag="subscriptionHistory"
                    />
                  </div>
                );
              }
              return (
                <CommonSubscriptionCard
                  key={item.id}
                  subscriptionPlanData={item}
                  flag="subscriptionHistory"
                />
              );
            })
          ) : (
            <NoSubscription />
          )}
    </Container>
  );
};

export default SubscriptionHistory;
