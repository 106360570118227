import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Divider,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { typography, colors } from "../../utils/styles";

import { Visibility, VisibilityOff, Check, Close } from "@mui/icons-material";
import AuthApi from "../../api/auth/AuthApi";
import { STATUS, Status } from "../../constants/statusConstants";
import { Navigate, useNavigate } from "react-router-dom";
import { setUser } from "../../redux/appUserSlice";
import { useDispatch } from "react-redux";
import useToast from "../../hooks/useToast";
import { setCreator } from "../../redux/appCreatorSlice";
import { REGEX } from "../../utils/regex";

const LogIn: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ToastMessage, handleOpenToast } = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignedIn, setIsSignedIn] = useState<Status>(STATUS.IDLE);
  const [errors, setErrors] = useState<{
    email?: string;
    password?: string;
  }>({});
  const [showPassword, setShowPassword] = useState(false);
  const [redirectPath, setRedirectPath] = useState("");

  const [showPasswordHint, setShowPasswordHint] = useState(false);
  const [showEmailHint, setShowEmailHint] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const validateCredentials = () => {
    const newErrors: { email?: string; password?: string } = {};

    if (!REGEX.EMAIL.test(email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!REGEX.PASSWORD.test(password)) {
      newErrors.password = "Please enter a valid password.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const isValid = validateCredentials();
    if (!isValid) return;

    try {
      setIsSignedIn(STATUS.LOADING);
      const response = await AuthApi.login({ email, password });
      const { accessToken } = response.data;
      const {
        isCreator,
        isOnboardingDone,
        isEmailVerified,
        email: userEmail,
        id,
      } = response.data.user;
      if (!isEmailVerified) {
        navigate("/emailVerification", { state: { isEmailVerified, id } });
      } else if (isCreator) {
        localStorage.setItem("creatorAccessToken", JSON.stringify(accessToken));
        dispatch(setCreator(response.data.user));
        navigate("/creator/home");
      } else {
        if (isOnboardingDone) {
          localStorage.setItem("accessToken", JSON.stringify(accessToken));
          dispatch(setUser(response.data.user));
          navigate("/user/home");
        } else {
          navigate("/user/onboarding", {
            state: { email: userEmail, userId: id },
          });
        }
      }
    } catch (error: any) {
      setIsSignedIn(STATUS.ERROR);
      if (error?.response) {
        handleOpenToast(
          error.response?.data?.errors?.[0].message ?? "Something went wrong.",
          "error"
        );
      } else {
        handleOpenToast("Something went wrong.", "error");
      }
      const newErrors: { email?: string; password?: string } = {};

      error?.response?.data?.errors?.forEach((err: any) => {
        if (err.field === "email") {
          newErrors.email = err.message;
        }
        if (err.field === "password") {
          newErrors.password = err.message;
        }
      });

      setErrors(newErrors);
    } finally {
      setIsSignedIn(STATUS.IDLE);
    }
  };

  const tryLoggingIn = () => {
    let isUserTokenPresent = localStorage.getItem("accessToken");
    let isCreatorTokenPresent = localStorage.getItem("creatorAccessToken");
    if (isUserTokenPresent) {
      setRedirectPath("/user/home");
    } else if (isCreatorTokenPresent) {
      setRedirectPath("/creator/home");
    } else {
      console.log("no current user");
    }
  };

  useEffect(() => {
    tryLoggingIn();
  }, []);

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors((prev) => ({ ...prev, password: "" }));
    setPassword(e.target.value);
  };

  const passwordChecklist = [
    { label: "8-15 characters long", regex: /.{8,15}/ },
    { label: "At least 1 uppercase letter", regex: /[A-Z]/ },
    { label: "At least 1 digit", regex: /\d/ },
    { label: "At least 1 special character (@$!%*?&)", regex: /[@$!%*?&]/ },
  ];

  return (
    <Box mx="16px" my="24px">
      <ToastMessage />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap="10px"
      >
        <img
          src="/Icons/yaara_logo.svg"
          style={{ marginTop: "40px" }}
          alt="logo"
        />
        <Typography
          fontSize={typography.font_size["2xl"]}
          fontWeight={typography.font_weight["bold"]}
        >
          Sign In
        </Typography>

        <Typography
          fontSize={typography.font_size["base"]}
          fontWeight={typography.font_weight["regular"]}
          color={colors.black["200"]}
        >
          Welcome!!
        </Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <InputLabel sx={{ paddingBottom: "4px", paddingTop: "15px" }}>
          Email
        </InputLabel>
        <TextField
          variant="outlined"
          fullWidth
          sx={{ marginBottom: "10px" }}
          placeholder="Enter your email"
          value={email}
          onChange={(e) => {
            setErrors((prev) => ({ ...prev, email: "" }));
            setEmail(e.target.value);
          }}
          onBlur={() => setShowEmailHint(false)}
          onFocus={() => {
            setShowEmailHint(true);
            setErrors((prev) => ({ ...prev, email: "" }));
          }}
          error={!!errors.email}
          helperText={
            showEmailHint ? "abc@domain.com" : errors.email ? errors.email : ""
          }
          InputProps={{ style: { height: "48px" } }}
        />
        <InputLabel sx={{ py: "4px" }}>Password</InputLabel>

        <TextField
          type={showPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          placeholder="Enter your password"
          value={password}
          onChange={handlePasswordChange}
          error={!!errors.password}
          helperText={
            showPasswordHint ? "" : errors.password ? errors.password : ""
          }
          onBlur={() => setShowPasswordHint(false)}
          onFocus={() => {
            setShowPasswordHint(true);
            setErrors((prev) => ({ ...prev, password: "" }));
          }}
          sx={{ marginBottom: "15px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            style: { height: "48px" },
          }}
          inputProps={{ minLength: 6 }}
        />
        {showPasswordHint && (
          <List
            dense
            sx={{
              fontSize: typography.font_size.xs,
              padding: 0,
              marginBottom: "15px",
            }}
          >
            {passwordChecklist.map((item, index) => (
              <ListItem key={index} sx={{ padding: "2px 0" }}>
                <ListItemIcon sx={{ minWidth: "24px" }}>
                  {item.regex.test(password) ? (
                    <Check style={{ color: "green", fontSize: "16px" }} />
                  ) : (
                    <Close style={{ color: "red", fontSize: "16px" }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{ fontSize: typography.font_size.xs }}
                />
              </ListItem>
            ))}
          </List>
        )}

        <Button
          type="submit"
          variant="primary"
          fullWidth
          disabled={isSignedIn === STATUS.LOADING}
        >
          {isSignedIn === STATUS.LOADING ? (
            <>
              Logging In... <CircularProgress size={20} sx={{ ml: 1 }} />
            </>
          ) : (
            "Log In"
          )}
        </Button>
        <Box
          fontSize={typography.font_size["sm"]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginTop="32px"
        >
          <a href="/forgot-password">Forgot Password?</a>
        </Box>

        <Divider
          style={{
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          OR
        </Divider>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          gap="40px"
        >
          <Box
            display="flex"
            flexDirection="row"
            fontSize={typography.font_size["sm"]}
          >
            <Typography color={colors.gray["75"]}>
              Don't have an account? &nbsp;
            </Typography>
            <a href="/signup">Sign Up.</a>
          </Box>

          <Typography
            sx={{
              fontSize: typography.font_size["xxs"],
              color: colors.gray["75"],
            }}
          >
            By signing in, you are agreeing to our{" "}
            <a href="/terms-and-conditions">T&C</a> and{" "}
            <a href="/privacy-policy">Privacy Policy</a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LogIn;
