import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { colors, typography } from "../../utils/styles";
import { GenderSelectRadioInput } from "../../components/Common/GenderSelectRadioInput";
import { TextInput } from "../../components/Common/TextInput";
import { OptionalPhoneNumberInput } from "../../components/Common/OptionalPhoneNumberInput";
import { ChooseContent } from "../../components/Common/ChooseContent";
import { InputFieldWithTextCount } from "../../components/Common/CharacterCountInput";
import { useDispatch, useSelector } from "react-redux";
import { CoverPicture } from "../Common/CoverPicture";
import { AboutMe } from "../Common/AboutMe";
import { RootState } from "../../store/store";
import { useState } from "react";
import { setCreator } from "../../redux/appUserSlice";
import { STATUS, Status } from "../../constants/statusConstants";
import userApi from "../../api/user/userApi";
import Loader from "../Common/Loader";

const InputLabelComponent = (props: { children: string }) => {
  return (
    <InputLabel
      sx={{
        color: colors.black[200],
        fontSize: typography.font_size.sm,
        mb: "8px",
      }}
    >
      {props.children}
    </InputLabel>
  );
};

const options = [
  "Educational",
  "Fashion",
  "Fitness",
  "Wellness",
  "Food",
  "Cooking",
  "Gaming",
  "Beauty",
  "Travel",
  "Parenting",
  "Lifestyle",
  "Health",
  "Technology",
  "Gadget",
  "DIY/Craft",
  "Pet",
  "Environment",
  "Sustainability",
];

type OnboardingDetailsState = {
  description: string;
  displayName: string;
  contentTarget: string[];
  preferredContentCategory: string[];
  gender: string;
  phoneNumber: string;
  isInstagramVerified: boolean;
  coverPhotoUrl: string;
  profilePhotoUrl: string;
};

const CreatorOnboardingStep = (props: { getNextStage: () => void }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.user);

  const {
    displayName,
    gender,
    phoneNumber,
    preferredContentCategory,
    email,
    handleName,
  } = userInfo;

  const initialState = {
    description: userInfo?.description ?? "",
    displayName,
    gender,
    contentTarget: [],
    preferredContentCategory,
    phoneNumber: phoneNumber ?? "",
    isInstagramVerified: true,
    profilePhotoUrl: "",
    coverPhotoUrl: "",
  };

  const [userOnboardingData, setUserOnboardingData] =
    useState<OnboardingDetailsState>(initialState);
  const [isFirstStepCompleted, setIsFirstStepCompleted] = useState<Status>(
    STATUS.IDLE
  );
  const [imageLoadingStatus, setImageLoadingStatus] = useState(false);
  const [fieldValidationErrors, setFieldValidationErrors] = useState({
    description: null,
    displayName: null,
    gender: null,
    phoneNumber: null,
    contentTarget: null,
    preferredContentCategory: null,
    socialMediaUsername: null,
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "preferredContentCategory") {
      return;
    }
    setUserOnboardingData((prev) => ({ ...prev, [name]: value }));
    setFieldValidationErrors((prev) => ({ ...prev, [name]: null }));
  };

  const handleSubmit = async () => {
    let hasError = false;
    const validationErrors: any = {};

    if (!userOnboardingData.description) {
      validationErrors.description = "Description is required";
      hasError = true;
    }
    if (!userOnboardingData.displayName) {
      validationErrors.displayName = "Display Name is required";
      hasError = true;
    }
    if (!userOnboardingData.gender) {
      validationErrors.gender = "Gender is required";
      hasError = true;
    }
    if (userOnboardingData.contentTarget.length === 0) {
      validationErrors.contentTarget = "Content Target is required";
      hasError = true;
    }
    if (userOnboardingData.preferredContentCategory.length === 0) {
      validationErrors.preferredContentCategory =
        "Preferred Content Category is required";
      hasError = true;
    }
    // if (userOnboardingData.isInstagramVerified) {
    //   validationErrors.socialMediaUsername =
    //     "Social Media username is required";
    //   hasError = true;
    // }

    if (hasError) {
      setFieldValidationErrors(validationErrors);
      return;
    }

    setIsFirstStepCompleted(STATUS.LOADING);
    try {
      const response = await userApi.becomeCreatorStepOne(userOnboardingData);
      const { data } = response;
      if (data) {
        dispatch(setCreator(response?.data?.creator));
      }
      setIsFirstStepCompleted(STATUS.SUCCESS);
      props.getNextStage();
    } catch (error: any) {
      console.log(error);
      setIsFirstStepCompleted(STATUS.ERROR);
    }
  };

  const updateCoverPicture = (uri: string) => {
    setUserOnboardingData((prev) => ({ ...prev, coverPhotoUrl: uri }));
  };
  const updateProfilePicture = (uri: string) => {
    setUserOnboardingData((prev) => ({ ...prev, profilePhotoUrl: uri }));
  };

  return (
    <Box sx={{ position: "relative" }}>
      <CoverPicture
        pictureUrl={userOnboardingData.coverPhotoUrl ?? ""}
        updateCoverPicture={updateCoverPicture}
        setImageLoadingStatus={setImageLoadingStatus}
      />
      <AboutMe
        displayName={displayName}
        handleName={handleName}
        profilePic={userOnboardingData?.profilePhotoUrl ?? ""}
        setImageLoadingStatus={setImageLoadingStatus}
        updateProfilePicture={updateProfilePicture}
      />
      <Stack direction="column" gap="32px" px="16px">
        <Box>
          <InputLabelComponent>Description</InputLabelComponent>
          <InputFieldWithTextCount
            name="description"
            placeholder="Write Description"
            characterLimit={200}
            minHeight={72}
            value={userOnboardingData.description}
            onChange={handleInputChange}
          />
          {fieldValidationErrors.description && (
            <Typography color="error" sx={{ mt: "8px" }}>
              {fieldValidationErrors.description}
            </Typography>
          )}
        </Box>
        <Box>
          <InputLabelComponent>Enter Display Name</InputLabelComponent>
          <InputFieldWithTextCount
            name="displayName"
            placeholder="Enter display name"
            characterLimit={25}
            minHeight={48}
            maxHeight={48}
            value={userOnboardingData.displayName}
            onChange={handleInputChange}
          />
          {fieldValidationErrors.displayName && (
            <Typography color="error" sx={{ mt: "8px" }}>
              {fieldValidationErrors.displayName}
            </Typography>
          )}
        </Box>
        <Box>
          <InputLabelComponent>
            How do you identify yourself?
          </InputLabelComponent>
          <GenderSelectRadioInput
            value={userOnboardingData.gender.toUpperCase()}
            onChange={handleInputChange}
          />
          {fieldValidationErrors.gender && (
            <Typography color="error" sx={{ mt: "8px" }}>
              {fieldValidationErrors.gender}
            </Typography>
          )}
        </Box>
        <Box>
          <InputLabelComponent>Email</InputLabelComponent>
          <TextInput
            name="email"
            placeholder="Enter your email"
            value={email}
            disabled
            onChange={() => {}}
          />
        </Box>
        <Box>
          <InputLabelComponent>Phone Number (optional)</InputLabelComponent>
          <OptionalPhoneNumberInput
            name="phoneNumber"
            value={userOnboardingData.phoneNumber}
            onChange={handleInputChange}
          />
        </Box>
        <Box>
          <InputLabelComponent>
            What type of content would you like to showcase?
          </InputLabelComponent>
          <ChooseContent
            name="contentTarget"
            options={options}
            maxSelection={3}
            setUserDetails={setUserOnboardingData}
          />
          {fieldValidationErrors.contentTarget && (
            <Typography color="error" sx={{ mt: "8px" }}>
              {fieldValidationErrors.contentTarget}
            </Typography>
          )}
        </Box>
        <Box>
          <InputLabelComponent>
            What type of content would you like to see?
          </InputLabelComponent>
          <ChooseContent
            name="preferredContentCategory"
            options={options}
            initialValues={preferredContentCategory}
            setUserDetails={setUserOnboardingData}
          />
          {fieldValidationErrors.preferredContentCategory && (
            <Typography color="error" sx={{ mt: "8px" }}>
              {fieldValidationErrors.preferredContentCategory}
            </Typography>
          )}
        </Box>
        <Box>
          <InputLabelComponent>Social Media username</InputLabelComponent>
          <TextField
            fullWidth
            placeholder="Instagram Id here"
            sx={{
              "& .MuiInputBase-input": {
                height: "48px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Button sx={{ fontWeight: "bold", fontSize: "12px" }}>
                    Connect
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          {fieldValidationErrors.socialMediaUsername && (
            <Typography color="error" sx={{ mt: "8px" }}>
              {fieldValidationErrors.socialMediaUsername}
            </Typography>
          )}
        </Box>
      </Stack>
      <Box mt="3rem">
        <Button
          onClick={handleSubmit}
          disabled={
            isFirstStepCompleted === STATUS.LOADING || imageLoadingStatus
          }
          fullWidth
          sx={{
            backgroundColor: colors.brand_primary,
            color: colors.white,
            height: "40px",
            borderRadius: "0px",
            width: "100%",
            "&:hover": {
              backgroundColor: colors.brand_primary,
            },
          }}
        >
          {isFirstStepCompleted === STATUS.LOADING ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            "Submit"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export { CreatorOnboardingStep };
