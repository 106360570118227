import AWS from "aws-sdk";
import { v4 as uniqueId } from "uuid";
const aws_region = "ap-south-1";
const bucketName = process.env.REACT_APP_S3_BUCKET;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: aws_region,
    signatureVersion: "v4",
});
const s3 = new AWS.S3();

const uploadBlobToS3 = async (blobUrl: string, index: number) => {
    try {
        // Fetch the Blob data from the blob URL
        const response = await fetch(blobUrl);
        const blob = await response.blob();

        // Convert the blob to a File object
        const fileNamePrefix = uniqueId();
        const file = new File([blob], `video_${fileNamePrefix}.mp4`, { type: blob.type });

        //   Prepare S3 upload parameters
        const params = {
            Bucket: '',
            Key: `${file.name}`, // return this key
            Body: file,
            ContentType: file.type,
        };

        //   Upload the file to S3
        let uploadResult;
        if (bucketName) {
            params.Bucket = bucketName;
            uploadResult = await s3.upload(params).promise();
            console.log(`File ${file.name} uploaded successfully:`, uploadResult.Location);
            return {
                type: 'VIDEO',
                src: uploadResult.Location,
                key: params.Key,
                bucket: bucketName,
                // isCoverPhoto: blobUrl.toString() === coverPhoto.toString()
            };
        }
        else{
            throw new Error('Invalid Bucket')
        }
    } catch (error) {
        console.error('Error uploading file to S3:', error);
        throw error;
    }
};

export const convertVideosBlobToFileObject = async (videos: string[], coverPhoto?: string) => {

    try {
        const uploadPromises = videos.map((blobUrl, index) => uploadBlobToS3(blobUrl, index));
        const uploadResults = await Promise.all(uploadPromises);
        return uploadResults; // Return the array of uploaded file URLs
    } catch (error) {
        console.error('Error uploading blobs:', error);
    }

}