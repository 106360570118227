import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { colors, typography } from "../utils/styles";
import { useSelector } from "react-redux";
import { getCurrentStep, getCurrentTitle } from "../redux/creatorRegistrationSlice";

const steps = [
    "Onboarding Details",
    "Registration details",
    "Submit Application",
  ];

export default function StepperComponent(props: any) {
    const title = useSelector(getCurrentTitle);
    const step = useSelector(getCurrentStep)
    return (
      <Box
        sx={{
          width: "100%",
          height: "180px",
          backgroundColor: colors.purple[50],
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography
          color={colors.violet["100"]}
          fontWeight={typography.font_weight["bold"]}
          fontSize={typography.font_size["2xl"]}
          sx={{ textAlign: "center", marginTop: "34px" }}
        >
          {title}
        </Typography>
        <Stepper activeStep={step} alternativeLabel >
          {steps.map((label) => (
            <Step key={label} >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }