import { useState } from "react";

import { Box, Typography, Checkbox, ButtonGroup, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-material-ui-carousel";
import { setFiles } from "../../../redux/creatorContentSlice";
import cloneDeep from "lodash/cloneDeep";
import { VideoUploading } from "./VideoUploading";

const VideoCarousel = () => {
  const dispatch = useDispatch();

  const files = useSelector((state: any) => state.postContent.files);
  const mode = useSelector((state: any) => state.postContent.mode);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleSelectedFree = (e: any) => {
    let filesCopy = cloneDeep(files);
    filesCopy[selectedIndex].selectedFree = e.target.checked;
    dispatch(setFiles(filesCopy));
  };

  const handleDecrementFreeSeconds = () => {
    let filesCopy = cloneDeep(files);
    filesCopy[selectedIndex].freeSeconds = files[selectedIndex].freeSeconds - 1;
    dispatch(setFiles(filesCopy));
  };

  const handleIncrementFreeSeconds = () => {
    if (files[selectedIndex].selectedFree) {
      let filesCopy = cloneDeep(files);
      filesCopy[selectedIndex].freeSeconds =
        files[selectedIndex].freeSeconds + 1;
      dispatch(setFiles(filesCopy));
    }
  };

  return (
    <Box>
      <Carousel
        autoPlay={false}
        onChange={(newIndex: any) => {
          setSelectedIndex(newIndex);
        }}
        navButtonsAlwaysInvisible
        indicators={files.length > 1}
        stopAutoPlayOnHover
        swipe={false}
      >
        {files.map((item: any, i: any) => {
          return (
            <VideoUploading
              url={item.url}
              key={i}
              index={i}
              selectedIndex={selectedIndex}
            />
          );
        })}
      </Carousel>
      <Box
        mt="10px"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Checkbox
            checked={files[selectedIndex].selectedFree}
            color="success"
            onClick={(e: any) => handleSelectedFree(e)}
          />
          <Typography>Set free Seconds</Typography>
        </Box>
        <Box mr="20px">
          <ButtonGroup size="small" aria-label="small outlined button group">
            <Button
              disabled={files[selectedIndex].freeSeconds <= 0}
              onClick={() => handleDecrementFreeSeconds()}
            >
              -
            </Button>
            <Button disabled={files[selectedIndex].freeSeconds <= 0}>
              {files[selectedIndex].freeSeconds}sec
            </Button>
            <Button
              onClick={async () => handleIncrementFreeSeconds()}
              disabled={
                files[selectedIndex].freeSeconds >=
                  files[selectedIndex].maxLimit ||
                !files[selectedIndex].selectedFree
              }
            >
              +
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </Box>
  );
};

export { VideoCarousel };
