import React from "react";
import UserFollowing from "../../../components/User/Following/Following";

const followingData = [
  {
    id: 1,
    img: "Images/avatar_image_1.jpg",
    name: "May heaven",
    handle: "@Mayheaven",
    type: "creator",
  },

  {
    id: 2,
    img: "Images/avatar_image_2.jpg",
    name: "Mishra Jee",
    handle: "@mishrajee",
    type: "creator",
  },

  {
    id: 3,
    img: "Images/avatar_image_3.jpg",
    name: "Shreyas Thakur",
    handle: "@thakurshree",
    type: "creator",
  },

  {
    id: 4,
    img: "Images/avatar_image_6.jpg",
    name: "Bahubali Singh",
    handle: "@bahubalisingh",
    type: "creator",
  },

  {
    id: 5,
    img: "Images/avatar_image_4.jpg",
    name: "Swami Subbu",
    handle: "@subbuswami",
    type: "creator",
  },

  {
    id: 6,
    img: "Images/avatar_image_5.jpg",
    name: "Mr. Virat",
    handle: "@mrvirat",
    type: "creator",
  },
  {
    id: 7,
    img: "Images/avatar_image_6.jpg",
    name: "Bahubali Singh",
    handle: "@bahubalisingh",
    type: "creator",
  },
  {
    id: 1,
    img: "Images/avatar_image_1.jpg",
    name: "May heaven",
    handle: "@Mayheaven",
    type: "creator",
  },

  {
    id: 2,
    img: "Images/avatar_image_2.jpg",
    name: "Mishra Jee",
    handle: "@mishrajee",
    type: "creator",
  },

  {
    id: 3,
    img: "Images/avatar_image_3.jpg",
    name: "Shreyas Thakur",
    handle: "@thakurshree",
    type: "creator",
  },

  {
    id: 4,
    img: "Images/avatar_image_6.jpg",
    name: "Bahubali Singh",
    handle: "@bahubalisingh",
    type: "creator",
  },

  {
    id: 5,
    img: "Images/avatar_image_4.jpg",
    name: "Swami Subbu",
    handle: "@subbuswami",
    type: "creator",
  },

  {
    id: 6,
    img: "Images/avatar_image_5.jpg",
    name: "Mr. Virat",
    handle: "@mrvirat",
    type: "creator",
  },
  {
    id: 7,
    img: "Images/avatar_image_6.jpg",
    name: "Bahubali Singh",
    handle: "@bahubalisingh",
    type: "creator",
  },
];

const UsersFollowingPage: React.FC = () => {
  return (
    <>
      <UserFollowing text="Following" data={followingData} />
    </>
  );
};

export default UsersFollowingPage;
