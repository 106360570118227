import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { colors, typography } from '../../../utils/styles'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import bookingServiceApi from '../../../api/creator/bookingServiceApi';
import { setWeeklyAvailability } from '../../../redux/bookingSlice';
const BookingAction:any = ({title,description,link, label, isActive}:{title:string,description:string,link:string, label:string, isActive:boolean}) =>{
  return <Link to={link} style={{textDecoration:'none', color:"#212121"}}>
  <Stack borderBottom="1px solid rgba(33, 33, 33, 0.1)" pt="16px" pb="11px" pl="29px" pr="16px" direction='row' alignItems="center" justifyContent="space-between">
      <Stack>
          <Typography fontSize={typography.font_size.sm} fontWeight={typography.font_weight.semiBold}>{title}</Typography>
          <Typography fontSize={typography.font_size.xs} fontWeight={typography.font_weight.regular}
          color={label==="Availability" && !isActive ? colors.red[300] : colors.black[200] }
          >{description}</Typography>
      </Stack>
      <ArrowForwardIosRoundedIcon />
  </Stack></Link>
}
const Container=styled(Box)`
    width: 100%;
    height: 100%;
`;

const CreatorBookings = () => {
    const location = useLocation();
  const dispatch=useDispatch<AppDispatch>()
  const {weeklyAvailability}= useSelector((state:RootState) => state.bookings);
  const navigate= useNavigate();
  const fetchWeeklyAvailability = async () => {
    try{
    const response= await bookingServiceApi.getWeeklyAvailability();
    dispatch(setWeeklyAvailability(response.data.data));
    }
    catch(error){
      console.error("Error fetching weekly availability", error);
    }
  }
  const getAvailabilityStatus=()=>{
    let isActive=weeklyAvailability?.length===0 || weeklyAvailability.every(slots=>!slots.checked) ? false : true;
    return isActive;
  }
  const bookingOptions = [
    {
      title:weeklyAvailability?.length>0 && weeklyAvailability.some(slots=>slots.checked) ? 'Weekly Availability' :'Set Availability',
      description:weeklyAvailability?.length>0 && weeklyAvailability.some(slots=>slots.checked) ? 'Tap to edit your everyday availability': "set your availability , hence you will not receive any booking from users.",
      link:`/creator/bookings/${!getAvailabilityStatus() ? "set-availability" : "check-availability"}`,
      label:"Availability",
      isActive: weeklyAvailability?.length>0 && weeklyAvailability.some(slots=>slots.checked)
    },
    {
      title:'Check Bookings',
      description:'Tap to view your monthly bookings',
      link:'/creator/bookings/check-bookings',
      label:"CheckBookings",
      isActive: true
    }
  ]

  useEffect(()=>{
    fetchWeeklyAvailability();
  }, [dispatch])
  return (
      <Container>
      {bookingOptions.map((item,idx)=>{
        return <BookingAction key={item.title} 
            title={item.title} 
            description={item.description}
            link={item.link}
            label={item.label}
            isActive={item.isActive}
            />
      })}
      </Container>
  )
}

export { CreatorBookings };

// {weeklyAvailability?.length>0 && weeklyAvailability.some(slots=>slots.checked) &&