import { ApiService } from "../../ApiClient";
import { creatorAxiosInstance } from "../../axiosInstance";
import "../../interceptors/creatorAuthInterceptors";

class CreatorSlotBookingApi extends ApiService {
  constructor() {
    super(creatorAxiosInstance);
  }

  getSubscriptionConsumption(
    subscriptionId: string,
    subscriptionVersion: number
  ) {
    return this.axios
      .get(
        `subscription/user-subscription/consumption?subscriptionId=${subscriptionId}&subscriptionVersion=${subscriptionVersion}`
      )
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getAvailableMinuteSlot(payload: any) {
    return this.axios
      .post("creator/slots/available", JSON.stringify(payload))
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  assignSlot(payload: any) {
    return this.axios
      .post("creator/slots/assign", JSON.stringify(payload))
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  lockSlot(payload: any) {
    return this.axios
      .post("creator/slots/lock", JSON.stringify(payload))
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  bookSlot(payload: any) {
    return this.axios
      .post("creator/slots/book", JSON.stringify(payload))
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  unlockSlot(lockedSlotId: string) {
    return this.axios
      .delete(`creator/slots/lock/${lockedSlotId}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}

export default new CreatorSlotBookingApi();
