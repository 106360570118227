import React, { useState, useRef, useEffect } from "react";

import { Box, Typography } from "@mui/material";

const filters = ["none", "vivid", "grayscale", "sepia", "warm", "blur"];

const filterType: any = {
  sepia: "sepia(0.8)",
  grayscale: "grayscale(1)",
  vivid: "saturate(1.8)",
  warm: "sepia(0.2) brightness(1.1) contrast(1.1)",
  blur: "blur(2px)",
  none: "none",
};

const RenderImage = (props: any) => {
  const imgRef: any = useRef(null);
  const canvasRef = useRef(null);

  const [imageURL, setImageURL] = useState(null);

  const applyFilterAndRender = () => {
    const canvas: any = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    canvas.width = imgRef.current?.naturalWidth;
    canvas.height = imgRef.current?.naturalHeight;

    ctx.filter = filterType[props.type];
    ctx.drawImage(imgRef.current, 0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob: any) => {
      if (!blob) return;

      const url: any = URL.createObjectURL(blob);
      setImageURL(url);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      applyFilterAndRender();
    }, 500);
  }, [props.imageURL, props.type]);

  return (
    <Box
      onClick={() => {
        props.onClick({
          type: props.type,
          url: imageURL,
        });
      }}
    >
      <img ref={imgRef} src={props.imageURL} style={{ display: "none" }} />

      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>

      {imageURL && (
        <img src={imageURL} alt="Filtered Image" width={100} height={100} />
      )}
    </Box>
  );
};

const ImageFilters = (props: any) => {
  const imageURL = props.file.actualUrl;

  return (
    <>
      {filters.map((type, index) => {
        return (
          <Box>
            <Typography textAlign="center">{type}</Typography>
            <RenderImage
              key={index}
              type={type}
              imageURL={imageURL}
              onClick={props.onClickFilter}
            />
          </Box>
        );
      })}
    </>
  );
};

export default ImageFilters;
