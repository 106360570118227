import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setAssignedSlot,
  setAvailableSlotTime,
  setDaySlot,
  setLockedSlotId,
  setMinuteData,
  setSelectedSlot,
  setTimerEndTime,
} from "../../redux/slotBookingSlice";
import { useEffect, useState } from "react";
import { Box, Typography, styled, Button } from "@mui/material";
import { colors } from "../../utils/styles";
import { formatDate } from "../../utils/formatDate";
import { TickIcon } from "../../assets/SvgIcons";
import userSlotBookingService from "../../api/booking/user/userSlotBookingService";
import creatorSlotBookingService from "../../api/booking/creator/creatorSlotBookingService";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 4px 16px;
`;

const Heading = styled(Typography)<{ disabled?: boolean }>`
  font-weight: 600;
  color: ${(props) => (props.disabled ? "#616161" : "#212121")};
`;

const ChipContainer = styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  justify-content: flex-start;
  gap: 16px;
  max-width: 100%;
  padding-right: 16px; /* Adds space at the end for scrolling */

  /* Scrollbar styling for Webkit browsers */
  &::-webkit-scrollbar {
    height: 8px; /* Height of horizontal scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Make it rounded */
  }

  /* Optional: Hide scrollbar entirely */
  &::-webkit-scrollbar {
    display: none;
  }

  /* For other browsers */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
`;

const StyledChip = styled(Box)<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  min-width: 65px;
  min-height: 35px;
  color: ${(props) => (props.disabled ? colors.black[10] : colors.black[200])};
  font-size: 10px;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.disabled ? colors.black[10] : colors.black[200])};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.disabled ? colors.gray[105] : "#fff")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const SlotContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
`;

const SlotChip = styled(Box)<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  min-width: 156px;
  min-height: 35px;
  color: ${(props) => (props.disabled ? colors.black[10] : colors.black[200])};
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.disabled ? colors.black[10] : colors.black[200])};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.disabled ? colors.gray[105] : "#fff")};
  gap: 8px;
`;

const SlotText = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  font-family: Poppins;
  margin: 0;
`;

const ChipText = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  font-family: Poppins;
  margin: 0;
`;

const DetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 94%;
  border: 1px solid ${colors.violet[100]};
  border-radius: 8px;
  padding: 16px 8px;
`;

const DetailItem = styled(Box)`
  display: flex;
  gap: 4px;
  justify-content: space-between;
  width: 100%;
`;

const DetailItemTitle = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

const DetailItemValue = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.violet[100]};
`;

const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const RescheduleSlotDetails = ({
  data,
}: {
  data:
    | {
        previousDate: string;
        previousStartTime: string;
        previousEndTime: string;
      }
    | any;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    service,
    minuteSlot,
    daySlot,
    selectedSlot,
    availableSlotTime,
    creatorDetails,
    minuteData,
    assignedSlot,
  } = useSelector((state: RootState) => state.slotBooking);
  const [error, setError] = useState<string | null>(null);
  const userRole = location.pathname.includes("user") ? "user" : "creator";

  const getSlotData = async () => {
    const payload = {
      creatorId: creatorDetails.creatorId,
      slotLength: Number(minuteSlot),
    };

    try {
      const { data: responseData } =
        userRole === "user"
          ? await userSlotBookingService.getAvailableMinuteSlot(payload)
          : await creatorSlotBookingService.getAvailableMinuteSlot(payload);
      if (responseData?.length === 0) {
        setError("No available minute slots found.");
      } else {
        dispatch(setMinuteData(responseData));
        setError(null);
      }
    } catch (err: any) {
      setError("Failed to fetch available minute slots : " + err.message);
      console.error(err);
    }
  };

  const handleAssignSlot = async (timeSlot: {
    startTime: string;
    endTime: string;
  }) => {
    const payload = {
      creatorId: creatorDetails.creatorId,
      slotLength: minuteSlot,
      startTime: timeSlot.startTime,
      endTime: timeSlot.endTime,
      date: daySlot.date,
    };

    try {
      const { data: responseData } =
        userRole === "user"
          ? await userSlotBookingService.assignSlot(payload)
          : await creatorSlotBookingService.assignSlot(payload);
      dispatch(setAssignedSlot(responseData));
      dispatch(setLockedSlotId(responseData.lockedSlotId));
      setError(null);
    } catch (err: any) {
      setError("Failed to assign slot : " + err.message);
      console.error(err);
      dispatch(setAssignedSlot({ startTime: "", endTime: "" }));
    }
  };

  const handleContinue = async () => {
    try {
      const payload = {
        creatorId: creatorDetails.creatorId,
        startTime: selectedSlot.startTime,
        endTime: selectedSlot.endTime,
        date: daySlot.date,
      };

      const { data: responseData } =
        userRole === "user"
          ? await userSlotBookingService.lockSlot(payload)
          : await creatorSlotBookingService.lockSlot(payload);
      dispatch(setLockedSlotId(responseData.lockedSlotId));
      dispatch(setTimerEndTime(responseData.ttl));
      setError(null);
      navigate(
        `/${userRole}/reschedule-slot/details?previousStartTime=${data.previousStartTime}&previousEndTime=${data.previousEndTime}&previousDate=${data.previousDate}`
      );
    } catch (err: any) {
      setError("Failed to lock slot : " + err.message);
      console.error(err);
    }
  };
  useEffect(() => {
    if (creatorDetails.creatorId) {
      getSlotData();
    }
  }, [creatorDetails.creatorId]);

  return (
    <>
      <Container>
        {error && (
          <Typography
            variant="body2"
            color="error"
            sx={{ marginTop: 2, textAlign: "center" }}
          >
            {error}
          </Typography>
        )}
        {
          <DetailsContainer>
            <DetailItem>
              <DetailItemTitle sx={{ fontWeight: "bold" }}>
                Previous Booking Details
              </DetailItemTitle>
            </DetailItem>
            <DetailItem>
              <DetailItemTitle>Booking Date</DetailItemTitle>
              <DetailItemValue>{data.previousDate}</DetailItemValue>
            </DetailItem>
            <DetailItem>
              <DetailItemTitle>No. of minutes</DetailItemTitle>
              <DetailItemValue>{Number(minuteSlot)} Minutes</DetailItemValue>
            </DetailItem>
            <DetailItem>
              <DetailItemTitle>Slot Timing</DetailItemTitle>
              <DetailItemValue>
                {data.previousStartTime} {data.previousStartTime && "-"}{" "}
                {data.previousEndTime}
              </DetailItemValue>
            </DetailItem>
          </DetailsContainer>
        }
        {minuteSlot && minuteData && minuteData.length > 0 && (
          <>
            <Heading variant="h5" disabled={!minuteSlot}>
              Select Day
            </Heading>
            <ChipContainer>
              {minuteData?.map((day: any, index: number) => {
                if (day.slots.length > 0) {
                  return (
                    <StyledChip
                      key={day.date}
                      onClick={() => {
                        dispatch(
                          setDaySlot({
                            index,
                            name: weekdays[day.day],
                            date: day.date,
                          })
                        );
                      }}
                      disabled={!minuteSlot}
                      sx={{
                        opacity: minuteSlot ? 1 : 0.5,
                        borderColor:
                          daySlot.date === day.date
                            ? colors.violet[100]
                            : colors.black[200],
                        backgroundColor:
                          daySlot.date === day.date
                            ? colors.purple[50]
                            : colors.white,
                      }}
                    >
                      <ChipText>
                        {weekdays[day.day]}
                        <br />
                        {formatDate(day.date)}
                      </ChipText>
                    </StyledChip>
                  );
                } else return null;
              })}
            </ChipContainer>
          </>
        )}
      </Container>
      {service && minuteSlot && daySlot.name && (
        <>
          <hr
            style={{
              width: "100%",
              border: "none",
              borderTop: "1px solid #e0e0e0",
            }}
          />
          <Container>
            <Heading variant="h5">Select Slot</Heading>
            <SlotContainer>
              {minuteData[daySlot.index]?.slots
                ?.filter(
                  (slot: any, index: number, self: any[]) =>
                    index ===
                    self.findIndex((s) => s.startTime === slot.startTime)
                )
                .map((slot: any) => (
                  <SlotChip
                    key={slot.startTime}
                    onClick={() => {
                      dispatch(setSelectedSlot(slot));
                      handleAssignSlot({
                        startTime: slot.startTime,
                        endTime: slot.endTime,
                      });
                    }}
                    sx={{
                      borderColor:
                        selectedSlot === slot
                          ? colors.violet[100]
                          : colors.black[200],
                      color:
                        selectedSlot === slot
                          ? colors.violet[100]
                          : colors.black[200],
                      position: "relative",
                    }}
                  >
                    {selectedSlot === slot && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          zIndex: 10,
                          backgroundColor: colors.white,
                          border: `1px solid ${colors.white}`,
                        }}
                      >
                        <TickIcon />
                      </Box>
                    )}
                    <SlotText>{slot.startTime}</SlotText>
                    <SlotText>{" - "}</SlotText>
                    <SlotText>{slot.endTime}</SlotText>
                  </SlotChip>
                ))}
            </SlotContainer>
            {assignedSlot &&
              assignedSlot.startTime !== "" &&
              selectedSlot &&
              selectedSlot.startTime !== "" && (
                <>
                  <Heading variant="h5" disabled={!selectedSlot}>
                    Available Slot Time For Booking
                  </Heading>
                  <SlotChip
                    key={assignedSlot.startTime}
                    onClick={() =>
                      dispatch(
                        setAvailableSlotTime({
                          startTime: assignedSlot.startTime,
                          endTime: assignedSlot.endTime,
                        })
                      )
                    }
                    disabled={!selectedSlot}
                    sx={{
                      opacity: minuteSlot ? 1 : 0.5,
                      borderColor:
                        availableSlotTime.startTime === assignedSlot.startTime
                          ? colors.violet[100]
                          : colors.black[200],
                      backgroundColor:
                        availableSlotTime.startTime === assignedSlot.startTime
                          ? colors.purple[50]
                          : colors.white,
                      maxWidth: "80px",
                      marginBottom: "90px",
                    }}
                  >
                    <SlotText>{assignedSlot.startTime}</SlotText>
                    <SlotText>{" - "}</SlotText>
                    <SlotText>{assignedSlot.endTime}</SlotText>
                  </SlotChip>
                </>
              )}
          </Container>
        </>
      )}
      {service &&
        minuteSlot &&
        daySlot.name &&
        selectedSlot.startTime &&
        availableSlotTime.startTime && (
          <Button
            variant="contained"
            sx={{
              backgroundColor: colors.violet[100],
              color: colors.white,
              fontSize: "16px",
              fontWeight: 700,
              position: "fixed",
              bottom: "0",
              borderRadius: "0",
              "&:hover": {
                backgroundColor: colors.violet[20],
              },
              width: "100%",
              height: "56px",
            }}
            onClick={() => {
              if (
                service &&
                minuteSlot &&
                daySlot.name &&
                selectedSlot.startTime &&
                availableSlotTime.startTime
              ) {
                handleContinue();
              }
            }}
          >
            Continue
          </Button>
        )}
    </>
  );
};

export default RescheduleSlotDetails;
