import { Box } from "@mui/material";
import React, { useState } from "react";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { useDispatch, useSelector } from "react-redux";
import { setFiles } from "../../../redux/creatorContentSlice";
import cloneDeep from "lodash/cloneDeep";

const VideoUploading = (props: any) => {
  const files = useSelector((state: any) => state.postContent.files);
  const [objectFit, setObjectFit] = useState("none");
  const dispatch = useDispatch();

  const handleChange = async () => {
    if (objectFit === "none") {
      await setObjectFit("cover");
      let filesCopy = cloneDeep(files);
      filesCopy[props.selectedIndex].objectFitType = objectFit;
      dispatch(setFiles(filesCopy));
    } else {
      await setObjectFit("none");
      let filesCopy = cloneDeep(files);
      filesCopy[props.selectedIndex].objectFitType = objectFit;
      dispatch(setFiles(filesCopy));
    }
  };
  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: 0,
          overflow: "hidden",
          paddingTop: "100%",
        }}
      >
        <Box>
          <video
            width="100%"
            height="100%"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: objectFit === "none" ? "cover" : "contain",
            }}
            // controls
            autoPlay
            src={props.url}
          ></video>
        </Box>
      </Box>
      <CropFreeIcon
        style={{
          position: "absolute",
          bottom: "50px",
          left: "20px",
          cursor: "pointer",
          color: "red",
          width: "32px",
          height: "32px",
        }}
        onClick={() => handleChange()}
      />
    </Box>
  );
};

export { VideoUploading };
