import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setPage } from "../redux/activePageSlice";

interface WrapperProps {
    state: string;
    children: React.ReactNode;
}
const Wrapper: React.FC<WrapperProps> = ({ state, children })=>{
    
    const dispatch = useDispatch();
    useEffect(()=>{
        if(state){
            dispatch(setPage(state));
        }
    },[dispatch, state])
    return <>{children}</>
}
export default Wrapper