import { createSlice } from "@reduxjs/toolkit";

let initialContentState = {
  mode: "photo",
  view:'NONE',
  originalImages: [],
  compressedImages:[],
  referenceImages:[],
  croppedImages :[],
  noOfImages:"",
  currentContentStep: "",
  coverPhoto: '',
  freePhotos:[]
};

export const imagePostSlice = createSlice({
  name: "imagePost",
  initialState: initialContentState,
  reducers: {
   setView(state,action){
        state.view = action.payload
   },
   set_Original_Selected_Images(state,action){
    state.originalImages = action.payload
   },
   set_Compressed_Images(state,action){
    return {
      ...state, // Keep the existing state intact
      compressedImages: action.payload, // Update compressedImages with a new array
    };
   },
   set_Reference_Images(state,action){
    state.referenceImages = action.payload;
   },
   set_Cropped_Images(state,action){
    state.croppedImages = action.payload
   },
   set_Cover_Photo(state,action){
    state.coverPhoto = action.payload
   },
   set_Free_Photos(state,action){
    state.freePhotos = action.payload
   },
   set_No_Of_Images(state,action){
    state.noOfImages = action.payload;
   },
   clear_Image_Post_State(){
    return initialContentState
   }
  },
});

export const {
    setView,
    set_Cropped_Images,
    set_Original_Selected_Images,
    set_Compressed_Images,
    set_Reference_Images,
    set_Cover_Photo,
    set_Free_Photos,
    set_No_Of_Images,
    clear_Image_Post_State
} = imagePostSlice.actions;

// export const selectCurrentMode = (state: any) => state.postContent.mode;
// export const selectCurrentFiles = (state: any) => state.postContent.files;
export const currentView = (state:any) =>state.imagePost.view;

export const imagePostReducer = imagePostSlice.reducer;
