import { Box, Stack, Typography } from "@mui/material";
import { typography } from "../../utils/styles";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import paymentService from "../../api/user/paymentServiceApi";
import { convertNumber } from "../../utils/convertNumbers";
import useToast from "../../hooks/useToast";
import creatorWalletServiceApi from "../../api/creator/creatorWalletServiceApi";

const CCBreakUp = ({ walletData }: { walletData: any }) => {
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  return (
    <Stack
      direction="column"
      p="16px"
      gap={2}
      sx={{ border: "1px solid rgba(114, 98, 194, 1)", borderRadius: "4px" }}
    >
      {
        <Stack direction="row" justifyContent="space-between">
          <Typography>Locked Amount</Typography>
          <Typography>{walletData.lockedYc} CC</Typography>
        </Stack>
      }
      <Stack direction="row" justifyContent="space-between">
        <Typography>Referral Bonus</Typography>
        <Typography>{walletData.referralBonusYc} CC</Typography>
      </Stack>
      {userRole === "creator" && (
        <Stack direction="row" justifyContent="space-between">
          <Typography>Available for Withdrawal</Typography>
          <Typography>{walletData.withdrawableYc} CC</Typography>
        </Stack>
      )}
    </Stack>
  );
};

const WalletBalance: any = ({ balance }: { balance: string }) => {
  return (
    <Stack
      py="16px"
      direction="column"
      justifyContent="center"
      alignItems="center"
      borderBottom="1px solid rgba(33, 33, 33, 0.1)"
    >
      <Typography
        fontWeight={typography.font_weight.bold}
        fontSize={typography.font_size.xl}
      >
        Current Balance : {convertNumber(parseInt(balance))} CC
      </Typography>
    </Stack>
  );
};

const WalletAction: any = ({
  title,
  description,
  link,
}: {
  title: string;
  description: string;
  link: string;
}) => {
  return (
    <Link to={link} style={{ textDecoration: "none", color: "#212121" }}>
      <Stack
        borderBottom="1px solid rgba(33, 33, 33, 0.1)"
        pt="16px"
        pb="11px"
        pl="16px"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography
            fontSize={typography.font_size.base}
            fontWeight={typography.font_weight.bold}
          >
            {title}
          </Typography>
          <Typography fontSize={typography.font_size.sm}>
            {description}
          </Typography>
        </Stack>
        <ArrowForwardIosRoundedIcon />
      </Stack>
    </Link>
  );
};
const Wallet = () => {
  const location = useLocation();
  const { ToastMessage, handleOpenToast } = useToast();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const [availableYC, setAvailableYC] = useState("");
  const [walletData, setWalletData] = useState({});

  const getAandUpdateWalletDetails = () => {
    if (userRole === "user") {
      paymentService
        .get_Wallet_Details()
        .then((data) => {
          setAvailableYC(data.data.data.totalYc);
          setWalletData(data.data.data);
        })
        .catch((error) => {
          handleOpenToast(
            "Could not fetch wallet details. Please refresh the page.",
            "error"
          );
        });
    } else {
      creatorWalletServiceApi
        .get_Wallet_Details()
        .then((data) => {
          setAvailableYC(data.data.data.totalYc);
          setWalletData(data.data.data);
        })
        .catch((error) => {
          handleOpenToast(
            "Could not fetch wallet details. Please refresh the page.",
            "error"
          );
        });
    }
  };

  const walletOptions = [
    {
      title: "Add Clout Coins",
      description: "Tap to add Clout Coins to wallet",
      link: `/${userRole}/wallet/add-coins?availableYC=${availableYC}`,
    },
    {
      title: "Withdraw",
      description: "Tap to withdraw Clout Coins to wallet",
      link: "/creator/wallet/withdraw",
    },
    {
      title: "KYC Verification",
      description: "Tap to verify account details",
      link: "/creator/wallet/kyc",
    },
    {
      title: "Transaction History",
      description: "Tap to view your wallet history",
      link: `/${userRole}/wallet/transactions`,
    },
  ];

  useEffect(() => {
    getAandUpdateWalletDetails();
  }, []);
  return (
    <Box width="100%">
      <ToastMessage />
      <WalletBalance balance={availableYC} />
      <Box p="9px 16px">
        <CCBreakUp walletData={walletData} />
        {walletOptions.map((item, idx) => {
          if (userRole === "user" && (idx === 1 || idx === 2)) {
            return null;
          }
          return (
            <WalletAction
              key={item.title}
              title={item.title}
              description={item.description}
              link={item.link}
            />
          );
        })}
      </Box>
    </Box>
  );
};
export { Wallet };
