import React from "react";
import { Box, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SubscriptionHeader from "../CreatorSubscriptions/SubscriptionHeader";
import BuyOnDemandCard from "../../../../components/Creator/OnDemandPlan/BuyOnDemandCard";


const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items:center;
`;


const CreatorsOnDemandRates: React.FC = () => {
  const navigate = useNavigate();
  const handleOnClickGoBackButton=()=>{
    navigate(-1);
  }

  return (
    <Container>
      <SubscriptionHeader
        title="On Demand 1-1 Services"
        handleOnClickGoBackButton={handleOnClickGoBackButton}
      />
      <BuyOnDemandCard/>
    </Container>
  );
};

export default CreatorsOnDemandRates;
