import { Box } from "@mui/material";
import { useRef, useState } from "react";
import { uploadimage } from "../../services/uploadToS3";

export const CoverPicture: any = ({pictureUrl,updateCoverPicture,setImageLoadingStatus}:{pictureUrl:string,updateCoverPicture:any,setImageLoadingStatus: (loading: boolean) => void;}) => {
    const [backgroundImage, setBackgroundImage] = useState<string>(pictureUrl ? pictureUrl : "/Images/no_cover.png");
    const imageInputRef = useRef<HTMLInputElement>(null);
  
    const handleBoxClick = () => {
      if (imageInputRef.current) {
        imageInputRef.current.click();
      }
    };
  
    const handleImageChange = async(event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            setBackgroundImage(reader.result as string);
          }
        };
        reader.readAsDataURL(file);
        setImageLoadingStatus(true)
        const imgUrl = await uploadimage("cover_photos", file)
        .then((d) => {
          setBackgroundImage(d);
          updateCoverPicture(d)
          // setProfilePicture(d.url);
        })
        .catch((err) => console.error(err))
        .finally(()=>setImageLoadingStatus(false))
      }
    };
  //   const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setSelectedImage(reader.result as string);
  //     };
  //     reader.readAsDataURL(file);
  //     const imgUrl = await uploadimage("users/profilepicture", file)
  //       .then((d) => {
  //       //   setProfilePicture(d.url);
  //       })
  //       .catch((err) => console.error(err));
  //   }
  // };
  
    return (
      <Box
        height={181}
        width="100%"
        sx={{
          backgroundImage: `url('${backgroundImage}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '1px solid gray',
          cursor: 'pointer',
        }}
        onClick={handleBoxClick}
      >
        <img src="/Icons/plus_icon_circle.svg" alt="plus icon" style={{ width: '32px', height: '32px' }} />
        <input
          type="file"
          ref={imageInputRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleImageChange}
        />
      </Box>
    );
  };