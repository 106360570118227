// @ts-ignore
import { load } from "@cashfreepayments/cashfree-js";

class CashfreeService {
  private cashfree: any;

  constructor() {}

  async initializeSDK(mode: "sandbox" | "production") {
    this.cashfree = await load({
      mode: mode,
    });
  }

  async checkout(checkoutOptions: any) {
    if (!this.cashfree) {
      throw new Error("SDK not initialized. Call initializeSDK() first.");
    }
    return this.cashfree.checkout(checkoutOptions);
  }
}

export default new CashfreeService();
