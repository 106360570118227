import { Home } from "../pages/Home";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MessageIcon from "@mui/icons-material/Message";
import SettingsIcon from "@mui/icons-material/Settings";
import { SvgIconComponent } from "@mui/icons-material";
import { SearchPage } from "../pages/Search";
import Post from "../components/Posts/Post";
import { SettingsPage } from "../pages/SettingsPage/SettingsPage";
import Messages from "../pages/Messages/Messages";

interface BottomNavRoute {
  displayIcon: SvgIconComponent;
  path: string;
  text: string;
  page: React.ComponentType<any>;
  state: string;
  // userRole:string
}

const BottomNavRoute: BottomNavRoute[] = [
  {
    displayIcon: HomeIcon,
    path: "home",
    text: "home",
    page: Home,
    state: "home",
  },
  {
    displayIcon: SearchIcon,
    path: "search",
    text: "search",
    page: SearchPage,
    state: "search",
  },
  {
    displayIcon: AddBoxIcon,
    path: "post",
    text: "post",
    page: Post,
    state: "post",
  },
  {
    displayIcon: MessageIcon,
    path: "messages",
    text: "messages",
    page: Messages,
    state: "messages",
  },
  {
    displayIcon: SettingsIcon,
    path: "settings",
    text: "settings",
    page: SettingsPage,
    state: "settings",
  },
];
export { BottomNavRoute };
