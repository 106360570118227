import { Avatar, Backdrop, Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { colors } from "../../utils/styles"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { STATUS, Status } from "../../constants/statusConstants"
import creatorContentApi from "../../api/creator/creatorContentApi"
import { useNavigate } from "react-router-dom"
import useToast from "../../hooks/useToast"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import styled from "@emotion/styled"
import { convertVideosBlobToFileObject } from "./utils"
import { set_Video_Post_View } from "../../redux/videoUploadSlice"
const PageHeader = styled(Stack)`
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding-inline:12px;
  position:realtive;
`

type PostContentType = {
    contentDescription: string,
    freePost: boolean,
    subscriptionPost: boolean,
    tag: string,
    contentType: string,
    coverPhotoBucket?: string,
    coverPhotoKey?: string;
    price?: number
}

export const AddPostDescription = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const croppedAreaCoordinates = useSelector((state:RootState)=>state.videoPost.croppedAreaCoordinates);
    const freeSeconds:any = useSelector((state:RootState)=>state.videoPost.freeSeconds);
    const { ToastMessage, handleOpenToast } = useToast();
    const videos = useSelector((state: RootState) => state.videoPost.originalVideos);
    const noOfVideos = useSelector((state:RootState)=> state.videoPost.noOfVideos);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [description, setDescription] = useState('');
    const [isFreePost, setIsFreePost] = useState(false);
    const [isAddedToSubscription, setIsAddedToSubscription] = useState(false);
    const [postValue, setPostValue] = useState('');

    const [isUploadAndPostInProgress, setIsUploadAndPostInProgress] = useState<Status>(STATUS.IDLE);

    const postContentToPlatform = async (postData: any, contentData: any) => {
        const payload = {
            postData,
            contentData
        }
        return await creatorContentApi.uploadPost(payload);
    }
    const generateContentData = async (response: any) => {
        const contentData = await response.map((eachResponse: any,index:number) => {
            const { type, bucket, key } = eachResponse;
            return {
                type,
                bucket,
                key,
            }
        })
        return contentData
    }

    const confirmPost = async () => {

        try {
            setIsUploadAndPostInProgress(STATUS.LOADING);

            const response = await convertVideosBlobToFileObject(videos.map((e:any)=>e.url));

            const contentData = await generateContentData(response);
            let postData: PostContentType = {
                contentDescription: description,
                freePost: isFreePost,
                subscriptionPost: isAddedToSubscription,
                tag: selectedCategory,
                contentType: 'VIDEO'
            }
            if (postValue) {
                postData.price = parseInt(postValue)
            }

            const updatedContentData = contentData.map((data: any,index:number) => {
                const croppedAreaPixels:any = croppedAreaCoordinates[index];
                const availableFreeSeconds:any = freeSeconds[index]?.freeSeconds ;
                const contentPayload =  {
                    type: data.type,
                    key: data.key,
                    bucket: data.bucket,
                    offsetX:croppedAreaPixels.x ,
                    offsetY:croppedAreaPixels.y,
                    cropWidth:croppedAreaPixels.width,
                    cropHeight:croppedAreaPixels.height
                }
                if(availableFreeSeconds !== 0){
                    return{...contentPayload, freeSeconds:availableFreeSeconds};
                }
                return contentPayload
            })
            const uploadPostResponse = await postContentToPlatform(postData, updatedContentData);
            setIsUploadAndPostInProgress(STATUS.SUCCESS);
            // dispatch(clear_Image_Post_State())
            navigate('/creator/profile');
        } catch (err) {
            console.error(err)
            handleOpenToast('There was an error posting your post please try again', 'error');
            setIsUploadAndPostInProgress(STATUS.ERROR)
        }
    }

    const onBackBtnClick = () =>{
        dispatch(set_Video_Post_View('CAROUSEL_VIEW')) 
    }

    useEffect(() => {
        if (isFreePost) {
            setIsAddedToSubscription(false);
            setPostValue('')
        }
    }, [isFreePost])

    const updateCategory = (value: any) => {
        setSelectedCategory(value);
    }
    return (
        <Box sx={{ height: "100dvh", display: 'flex', flexDirection: 'column' }}>
            <ToastMessage />
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column' })}
                open={isUploadAndPostInProgress === STATUS.LOADING}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
                <Typography fontSize={16} mt={2}>Please wait..</Typography>
            </Backdrop>
            <PageHeader
                sx={{ background: "#DCD7F5", height: '56px', boxShadow: 'none' }}>
                <ArrowBackIosNewIcon onClick={onBackBtnClick} sx={{ position: 'absolute', left: 5 }} />
                <Typography fontSize="16px" fontWeight={700}>
                    About Post
                </Typography>
            </PageHeader>
            <Box overflow='hidden' flexGrow={1}>
                <Stack p="8px 16px" direction='row' gap={3} pb={3}>
                    <Stack direction='row' flexGrow={1} gap={1.5}>
                        <Avatar />
                        <TextField
                            value={description}
                            onChange={(e: any) => setDescription(e.target.value)}
                            multiline
                            fullWidth
                            placeholder="Add description"
                            rows={3}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    border: 'none',
                                    '&.Mui-focused': {
                                        outline: 'none',
                                    },
                                    paddingTop: 1
                                },
                            }}
                            sx={{ outline: 'none', border: 'none' }}
                        />
                    </Stack>
                    {/* <img src={coverPhoto} width="72px" height="72px" /> */}
                </Stack>
                <Divider />
                <Box p="8px 16px" mt={4}>
                    <Typography fontWeight={700}>Choose this post category</Typography>
                    <Stack direction='row' flexWrap='wrap' gap={1.5} mt={2} justifyContent='center'>
                        {postCategory.map((category: any) => {
                            return <CategoryType
                                key={category.label}
                                category={category}
                                selectedCategory={selectedCategory}
                                updateCategory={updateCategory}
                            />
                        })}
                    </Stack>
                </Box>
                <Box p="8px 16px" mt={1}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography fontWeight={700}>Set Charges</Typography>
                        <FormControlLabel
                            control={<Checkbox checked={isFreePost} color="success" />}
                            onChange={(event: any) => {
                                setIsFreePost(event.target.checked)
                            }
                            }
                            label="Make it free"
                        />
                    </Stack>
                    <TextField
                        value={postValue}
                        onChange={(e: any) => setPostValue(e.target.value)}
                        type="number"
                        variant="outlined"
                        fullWidth
                        placeholder="Yaara Coins"
                        helperText={!isFreePost && "Min: CC 100 | Max: CC. 10,000"}
                        sx={{
                            marginBottom: "10px",
                            backgroundColor: isFreePost ? colors.gray["120"] : '',
                        }}
                        inputProps={{ minLength: 3, maxlength: 5 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton>
                                        <img src="/Icons/coin_icon.svg" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: { height: "48px" },
                        }}
                        disabled={isFreePost}
                    />
                    <FormControlLabel
                        control={<Checkbox
                            color="success"
                            checked={isAddedToSubscription}
                            sx={{
                                accentColor: '#212121',
                                color: isFreePost ? colors.gray["120"] : undefined,
                                pointerEvents: isFreePost ? 'none' : 'auto'
                            }}
                        />}
                        onChange={(event: any) => {
                            if (isFreePost) return
                            setIsAddedToSubscription(event.target.checked)
                        }
                        }
                        label="Add to Subscription Pack"
                    />
                </Box>
            </Box>
            <Button
                onClick={confirmPost}
                sx={{ borderRadius: 0 }}
                variant={(!description || !postCategory) ?  'disabled':"primary" }
                fullWidth>
                Confirm and Post
            </Button>
        </Box>
    )
}

const CategoryType = ({ category, selectedCategory, updateCategory }
    : { category: any, selectedCategory: any, updateCategory: any }) => {
    return <Stack
        width="30%"
        textAlign='center'
        onClick={() => updateCategory(category.value)}
        sx={{
            border: `1px solid ${selectedCategory === category.value ? colors.green["100"] : colors.gray["110"]}`,
            background: selectedCategory === category.value ? colors.green["60"] : colors.white,
            borderRadius: '8px'
        }}>
        <span
            style={{
                fontSize: '10px',
                padding: '8px',

            }}>
            {category.label}
        </span>
    </Stack>
}

const postCategory = [
    { label: "Fashion", value: "fashion" },
    { label: "Beauty", value: "beauty" },
    { label: "Environment", value: "environment" },
    { label: "Educational", value: "educational" },
    { label: "Fitness", value: "fitness" },
    { label: "Wellness", value: "wellness" },
    { label: "Food", value: "food" },
    { label: "Cooking", value: "cooking" },
    { label: "Gaming", value: "gaming" },
    { label: "Travel", value: "travel" },
    { label: "Parenting", value: "parenting" },
    { label: "Lifestyle", value: "lifestyle" },
    { label: "Technology", value: "technology" },
    { label: "Gadget", value: "gadget" },
    { label: "DIY/Craft", value: "diy/craft" },
];