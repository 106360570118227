import { Box, Divider, styled, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { colors, typography } from "../../../utils/styles";
import { DeleteNewIcon, EditIcon, MenuIcon } from "../../../assets/SvgIcons";
import { useNavigate } from "react-router-dom";
import { SubscriptionType } from "../../../types";
import {
  AudioCallIcon,
  TextMessageIcon,
  VideoCallCameraIcon,
} from "../../../assets/SvgIcons";
import { AMOUNT_UNIT } from "../../../constants/amountUnitConstant";
import { removeSubscription } from "../../../redux/creatorSubscriptionSlice";
import { useDispatch } from "react-redux";
import creatorApi from "../../../api/creator/creatorApi";
import { useModal } from "../../../context/ModalContext";
import DeleteCreatorOnDemandRate from "../../../modals/CreatorServices/DeleteCreatorOnDemandRate";
import DeleteCreatorSubscription from "../../../modals/CreatorServices/DeleteCreatorSubscription";
import creatorProfileServiceApi from "../../../api/creator/creatorProfileServiceApi";

const SubscriptionViewCardContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid ${colors.violet[100]};
  background: ${colors.white};
`;

const SubscriptionViewCardTitleContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  position: relative;
`;

const ViewCardTitle = styled(Box)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bold};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const SubscriptionViewCardContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const SubscriptionViewCardContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ViewCardContentLeftContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const ViewCardContentLeftIcon = styled(Box)``;

const ViewCardContentLeftTextContainer = styled(Box)``;

const ViewCardContentLeftSubTitleText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
  overflow: hidden;
  color: ${colors.black[200]};
  text-overflow: ellipsis;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.regular};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.standard};
`;

const ViewCardContentRightContainer = styled(Box)``;

const SuccessText = styled(Typography)`
  color: ${colors.violet[100]};
  text-align: center;
  font-family: Poppins;
  font-size: ${typography.font_size.xs}px;
  font-style: normal;
  font-weight: ${typography.font_weight.bolder};
  line-height: ${typography.line_height.default};
  letter-spacing: ${typography.letter_spacing.normal};
`;

const EditModalContainer=styled(Box)`
  display: flex;
  height: 80px;
  width: 158px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: var(--Background-card, #FFF);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: 24px;
  right: 0;
`;

const EditContent=styled(Box)`
display: flex;
padding: 8px;
align-items: center;
gap: 8px;
align-self: stretch;
`;

const EditText=styled(Typography)` 
display: flex;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;
color: var(--Text-Primary, #212121);

/* Body/XL-R */
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 21.6px */
letter-spacing: 0.24px;
align-self: stretch;
`;
const MenuIconContainer = styled(Box)``;
interface SubscriptionSuccessCardProps {
  items: SubscriptionType | undefined;
}
const SubscriptionSuccessCard: React.FC<SubscriptionSuccessCardProps> = ({
  items,
}) => {
  const [data, setData] = useState<SubscriptionType>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const menuIconRef = useRef<HTMLDivElement | null>(null);
  const {requestCloseModal, requestOpenModal} = useModal();
  const handleOpenEditDeleteModal = () => {
    setOpenModal((prev)=> !prev);
  };

  const handleDeleteSubscriptionPlan=async()=>{
    try{
    dispatch(removeSubscription());
    if(data?.id){
      const response=await creatorProfileServiceApi.deleteSubscription(data?.id)
    }
  }
  catch(err){
    console.log("error", err)
  }
  }
  const openDeleteModalOnDeleteButtonClick =(e:React.MouseEvent<HTMLDivElement>)=>{
    e.preventDefault();
    setOpenModal((prev)=> !prev);
    requestOpenModal({
      name: "Delete On Demand Rates Modal",
        component: <DeleteCreatorSubscription
        handleOnDelete={handleDeleteSubscriptionPlan}
        requestCloseModal={requestCloseModal}
        requestOpenModal={requestOpenModal}
        />
    })
  }
  const handleOnEditButtonClick=()=>{
    setOpenModal((prev)=> !prev);
    navigate("/creator/create-subscription-plan")
  }
  useEffect(() => {
    if (items) {
      setData(items);
    }
  }, [items]);
  const displayItems = [
    {
      title: "Direct Message",
      icon: <TextMessageIcon />,
      minutes: data?.directMessageMinutes,
      label: data?.directMessageMinutes
        ? `${data.directMessageMinutes} min Direct message`
        : "Direct message",
      isActive: !data?.directMessage,
    },
    {
      title: "Audio Call",
      icon: <AudioCallIcon />,
      minutes: data?.audioCallMinutes,
      label: data?.audioCallMinutes
        ? `${data.audioCallMinutes} min Audio call`
        : "Audio call",
      isActive: !data?.audioCall,
    },
    {
      title: "Video Call",
      icon: <VideoCallCameraIcon />,
      minutes: data?.videoCallMinutes,
      label: data?.videoCallMinutes
        ? `${data.videoCallMinutes} min Video call`
        : "Video call",
      isActive: !data?.videoCall,
    },
  ].sort((a, b) => {
    // If one item has textDecoration: true and the other has textDecoration: false, prioritize the one with false
    if (a.isActive !== b.isActive) {
      return a.isActive ? 1 : -1;
    }
    // If both items have the same textDecoration value, sort by minutes in descending order
    return (b.minutes ?? 0) - (a.minutes ?? 0);
  });
  
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        menuIconRef.current &&
        !menuIconRef.current.contains(event.target as Node)
      ) {
        setOpenModal(false);
      }
    }

    if (openModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openModal]);
  return (
    <>
      <SubscriptionViewCardContainer>
        <SubscriptionViewCardTitleContainer>
          <ViewCardTitle>
            {`${AMOUNT_UNIT.MAIN_UNIT} ${data?.amountInYC} / month`}
          </ViewCardTitle>

          <MenuIconContainer ref={menuIconRef} onClick={handleOpenEditDeleteModal}>
            <MenuIcon />
          </MenuIconContainer>

          {openModal ?
            (
              <EditModalContainer ref={modalRef}>
                <EditContent onClick={handleOnEditButtonClick}>
                    <EditIcon
                      color={"#212121"}
                      width={"24"}
                      height={"24"}
                    />
                    <EditText>Edit</EditText>
                </EditContent>

                <Divider sx={{
                    width: "68%",
                    alignSelf: "self-end"
                }}/>


                <EditContent onClick={openDeleteModalOnDeleteButtonClick}>
                    <DeleteNewIcon
                      color={"#212121"}
                    />
                    <EditText>Delete</EditText>
                </EditContent>
              </EditModalContainer>
            )
            : null
          }
        </SubscriptionViewCardTitleContainer>

        <SubscriptionViewCardContentContainer>
          {displayItems.map((item, index) => (
            <SubscriptionViewCardContent key={index}>
              <ViewCardContentLeftContainer>
                <ViewCardContentLeftIcon>{item.icon}</ViewCardContentLeftIcon>
                <ViewCardContentLeftTextContainer>
                  <ViewCardContentLeftSubTitleText
                    sx={{
                      textDecoration: item.isActive ? "line-through" : "none",
                    }}
                  >
                    {item.label}
                  </ViewCardContentLeftSubTitleText>
                </ViewCardContentLeftTextContainer>
              </ViewCardContentLeftContainer>
            </SubscriptionViewCardContent>
          ))}
        </SubscriptionViewCardContentContainer>
      </SubscriptionViewCardContainer>
    </>
  );
};

export default SubscriptionSuccessCard;
