import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store/store";
import {
  setAnotherCreatorProfile,
  setAnothetCreatorsFreePostsList,
  setAnothetCreatorsLockedPostsList,
  setAnothetCreatorsOnDemandRates,
  setAnothetCreatorsPurchasedPostsList,
  setAnothetCreatorsSubscriptionData,
} from "../../../redux/creatorServiceSlice";
import usersCreatorProfileServiceApi from "../../../api/user/creatorServiceApi";
import creatorsCreatorProfileServiceApi from "../../../api/creator/creatorProfileServiceApi";
import { useEffect, useState } from "react";
interface CreatorsServicesProps {
  creatorId: string | undefined;
  setLikesCount: React.Dispatch<React.SetStateAction<number>>;
  setPostsCount: React.Dispatch<React.SetStateAction<number>>;
}
const CreatorsServices: React.FC<CreatorsServicesProps> = ({
  creatorId,
  setLikesCount,
  setPostsCount,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [id, setId] = useState<string>();

  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const fetchCeatorsProfileData = async () => {
    try {
      if (id) {
        if (userRole === "user") {
          const response =
            await usersCreatorProfileServiceApi.getAnotherCreatorsProfile(id);
          if (response.status === 200) {
            dispatch(setAnotherCreatorProfile(response.data.data.creator));
          }
        } else if (userRole === "creator") {
          const response =
            await creatorsCreatorProfileServiceApi.getAnotherCreatorsProfile(
              id
            );
          if (response.status === 200) {
            dispatch(setAnotherCreatorProfile(response.data.data.creator));
          }
        }
      }
    } catch (error) {
      console.error("Error fetching creators profile data", error);
    }
  };

  const fetchCreatorsFreePostList = async () => {
    try {
      if (id) {
        if (userRole === "user") {
          const response = await usersCreatorProfileServiceApi.getFreePostList(
            id,
            "1",
            "30"
          );
          if (response.status === 200) {
            dispatch(setAnothetCreatorsFreePostsList(response.data.data.data));
          }
        } else if (userRole === "creator") {
          const response =
            await creatorsCreatorProfileServiceApi.getFreePostList(
              id,
              "1",
              "30"
            );
          if (response.status === 200) {
            dispatch(setAnothetCreatorsFreePostsList(response.data.data.data));
          }
        }
      }
    } catch (error) {
      console.error("Error fetching free post list", error);
    }
  };

  const fetchCreatorsLockedPostList = async () => {
    try {
      if (id) {
        if (userRole === "user") {
          const response =
            await usersCreatorProfileServiceApi.getLockedPostList(
              id,
              "1",
              "30"
            );
          if (response.status === 200) {
            dispatch(
              setAnothetCreatorsLockedPostsList(response.data.data.data)
            );
          }
        } else if (userRole === "creator") {
          const response =
            await creatorsCreatorProfileServiceApi.getLockedPostList(
              id,
              "1",
              "30"
            );
          if (response.status === 200) {
            dispatch(
              setAnothetCreatorsLockedPostsList(response.data.data.data)
            );
          }
        }
      }
    } catch (error) {
      console.error("Error fetching locked post list", error);
    }
  };

  const fetchCreatorsPurchasesPostList = async () => {
    try {
      if (id) {
        if (userRole === "user") {
          const response =
            await usersCreatorProfileServiceApi.getPurchasedPostList(
              id,
              "1",
              "30"
            );
          if (response.status === 200) {
            dispatch(
              setAnothetCreatorsPurchasedPostsList(response.data.data.data)
            );
          }
        } else if (userRole === "creator") {
          const response =
            await creatorsCreatorProfileServiceApi.getPurchasedPostList(
              id,
              "1",
              "30"
            );
          if (response.status === 200) {
            dispatch(
              setAnothetCreatorsPurchasedPostsList(response.data.data.data)
            );
          }
        }
      }
    } catch (error) {
      console.error("Error fetching purchased post list", error);
    }
  };

  const fetchCreatorsSubscription = async () => {
    try {
      if (id) {
        if (userRole === "user") {
          const response =
            await usersCreatorProfileServiceApi.getCreatorsSubscription(id);
          if (response.status === 200) {
            dispatch(
              setAnothetCreatorsSubscriptionData(
                response.data.data.subscription
              )
            );
          }
        } else if (userRole === "creator") {
          const response =
            await creatorsCreatorProfileServiceApi.getCreatorsSubscription(id);
          if (response.status === 200) {
            dispatch(
              setAnothetCreatorsSubscriptionData(
                response.data.data.subscription
              )
            );
          }
        }
      }
    } catch (error) {
      console.error("Error fetching creators subscription", error);
    }
  };

  const fetchCreatorsOnDemandData = async () => {
    try {
      if (id) {
        if (userRole === "user") {
          const response =
            await usersCreatorProfileServiceApi.getCreatorsOnDemandRates(id);
          if (response.status === 200) {
            dispatch(
              setAnothetCreatorsOnDemandRates(response.data.data.onDemandRate)
            );
          }
        } else if (userRole === "creator") {
          const response =
            await creatorsCreatorProfileServiceApi.getCreatorsOnDemandRates(id);
          if (response.status === 200) {
            dispatch(
              setAnothetCreatorsOnDemandRates(response.data.data.onDemandRate)
            );
          }
        }
      }
    } catch (error) {
      console.error("Error fetching creators on demand rates", error);
    }
  };

  const fetchCreatorStats = async () => {
    try {
      if (id) {
        if (userRole === "user") {
          const response =
            await usersCreatorProfileServiceApi.getCreatorsPostStats(id);
          if (response.status === 200) {
            let dataValues = response.data.data;
            setLikesCount(dataValues.likesCount);
            setPostsCount(dataValues.postCount);
          }
        } else if (userRole === "creator") {
          const response =
            await creatorsCreatorProfileServiceApi.getCreatorsPostStats(id);
          if (response.status === 200) {
            let dataValues = response.data.data;
            setLikesCount(dataValues.likesCount);
            setPostsCount(dataValues.postCount);
          }
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    setId(creatorId);
  }, [creatorId]);

  useEffect(() => {
    fetchCeatorsProfileData();
    fetchCreatorStats();
    fetchCreatorsFreePostList();
    fetchCreatorsLockedPostList();
    fetchCreatorsPurchasesPostList();
    fetchCreatorsSubscription();
    fetchCreatorsOnDemandData();
  }, [id, dispatch]);

  return null;
};

export default CreatorsServices;
