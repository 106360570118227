import React from 'react'
import PlanDetails from '../../components/Creator/Subscription/PlanDetails/PlanDetails';
import { Box, styled } from '@mui/material';

const Container=styled(Box)`
    
`;


const SubscriptionPlanDetails = () => {
  return (
    <Container>

        <PlanDetails
        />
    </Container>
  )
}

export default SubscriptionPlanDetails;