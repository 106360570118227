import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';
import { Box, Button, styled } from '@mui/material';
import FavoriteIcon from "@mui/icons-material/Favorite";
import { CommentIcon, GiftIcon, ShareIcon } from '../../assets/SvgIcons';

interface MediaProps {
  loading?: boolean;
}

const PostSkeletonContainer=styled(Box)`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.8);
border: 1px solid #f2e9e9;
border-radius: 12px;
padding: 16px 0;
`
const Header=styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
`;

const HeaderLeftContainer=styled(Box)`
    width: 50%;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const NameContainer=styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;
`;

const HeaderRightContainer=styled(Box)`
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 40%;
    justify-content: end;
`;


const DescriptionContainer=styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3px;
    margin: .5rem 0;
    padding: 0 1rem;
`;

const Text=styled(Typography)`
    
`;

const PostContainer=styled(Box)`
    width: 100%;
`;

const BottomContainer=styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 16px;
    margin-top: 0.5rem;
`;

const BottomLeftContainer=styled(Box)`
    display: flex;
    align-items: center;
    gap: 8px;
`;


const BottomRightContainer=styled(Box)`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const SendTipButton=styled(Button)``
const FollowButton=styled(Button)`

`


function Media(props: MediaProps) {
  const { loading = false } = props;




  return (
        <PostSkeletonContainer
        >
        <Header>
            <HeaderLeftContainer>
                <Skeleton animation="wave" variant="circular" width={40} height={40} >
                    <Avatar />
                </Skeleton>
                    <NameContainer>
                        <Skeleton animation="wave" height={16} width="95%"><Text>.</Text></Skeleton>
                        <Skeleton animation="wave" height={14}  width="55%"><Text>.</Text></Skeleton>
                    </NameContainer>
            </HeaderLeftContainer>

            <HeaderRightContainer>

            <Skeleton animation="wave" height={30} width="50%" variant="rounded">
                <FollowButton>
                </FollowButton>
            </Skeleton>

            <Skeleton animation="wave" height={30} width="5%" variant="rounded" > 
                <IconButton>
                    <MoreVertIcon />
                </IconButton>
                </Skeleton>
            </HeaderRightContainer>
        </Header>

        <DescriptionContainer>
        <Skeleton animation="wave" height={12} variant="text" width="100%">
                <Text>.</Text>
            </Skeleton>
            <Skeleton animation="wave" height={12} variant="text" width="100%">
                <Text>.</Text>
            </Skeleton>
            <Skeleton animation="wave" height={12} variant="text" width="50%">
                <Text>.</Text>
            </Skeleton>
        </DescriptionContainer>

        <PostContainer>
            <Skeleton sx={{width: "100%",  aspectRatio: 1, height: "auto" }} animation="wave" variant="rectangular" />
        </PostContainer>

        <BottomContainer>
            <BottomLeftContainer>
            <Skeleton animation="wave" width={30} height={30} variant="circular">
                <FavoriteIcon /> 
            </Skeleton>
            <Skeleton animation="wave" width={30} height={30} variant="circular">
            <CommentIcon />
            </Skeleton>

            <Skeleton animation="wave" width={30} height={30} variant="circular">
            <ShareIcon />
            </Skeleton>
            </BottomLeftContainer>

            <BottomRightContainer>
                <Skeleton animation="wave" width={90} height={30} variant="rounded">
                <SendTipButton>
                    <GiftIcon />
                </SendTipButton>
                </Skeleton>
            </BottomRightContainer>
          
        </BottomContainer>
      </PostSkeletonContainer>
  );
}

export default function PostSkeleton() {
  return (
    <div>
      <Media loading />
    </div>
  );
}
