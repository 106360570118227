import { createSlice } from '@reduxjs/toolkit';
import { TopEarningCreatorType, TopFollowedCreatorType } from '../types';


interface LeaderBoardState{
    topFollowedCreators: TopFollowedCreatorType[];
    topEarningCreators: TopEarningCreatorType[];
}

const initialState: LeaderBoardState = {
  topFollowedCreators: [],
  topEarningCreators: [],
};

const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState: initialState,
  reducers: {
    setTopFollowedCreators(state, action) {
        const topCreators = action.payload?.sort((a:TopFollowedCreatorType, b:TopFollowedCreatorType) => b.followersCount - a.followersCount);
        state.topFollowedCreators = topCreators;
    },
    setTopEarningCreators(state, action) {
        const topCreators = action.payload?.sort((a:TopEarningCreatorType, b:TopEarningCreatorType) => b.earning - a.earning);
        state.topEarningCreators = topCreators;
    },
  }
});

export const {
    setTopFollowedCreators,
    setTopEarningCreators,
} = leaderboardSlice.actions;

export const leaderboardReducer = leaderboardSlice.reducer;