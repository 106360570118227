import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import CropFreeIcon from "@mui/icons-material/CropFree";

const CropImage = (props: any) => {
  const { url } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [objectFit, setObjectFit] = useState("none");

  const onCropComplete = useCallback(
    async (croppedArea: any, croppedAreaPixels: any) => {
      const response = await getCroppedImage(url, croppedAreaPixels, zoom);

      props.onCropImage(response);
    },
    [url, props.onCropImage]
  );

  const getCroppedImage = async (imageSrc: any, crop: any, zoom: any) => {
    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => (image.onload = resolve));

    const canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");

    const scale = image.naturalWidth / image.width;
    const scaledCrop = {
      width: crop.width * scale,
      height: crop.height * scale,
      x: crop.x * scale,
      y: crop.y * scale,
    };

    canvas.width = scaledCrop.width;
    canvas.height = scaledCrop.height;

    ctx.drawImage(
      image,
      scaledCrop.x,
      scaledCrop.y,
      scaledCrop.width,
      scaledCrop.height,
      0,
      0,
      scaledCrop.width,
      scaledCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob: any) => {
        if (!blob) return;
        const fileUrl = URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  };
  const handleChange = () => {
    if (objectFit === "none") {
      return setObjectFit("contain");
    } else {
      return setObjectFit("none");
    }
  };

  return (
    <>
      <Cropper
        image={url}
        crop={crop}
        zoom={zoom}
        aspect={1}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        style={{
          containerStyle: {
            position: "absolute",
          },
          mediaStyle: {
            height: objectFit === "none" ? "400px" : "100%",
            width: "100%",
            objectFit: objectFit === "none" ? "unset" : "contain",
          },
        }}
        onCropComplete={(croppedArea, croppedAreaPixels) =>
          onCropComplete(croppedArea, croppedAreaPixels)
        }
        objectFit="cover"
        showGrid={true}
      />

      <CropFreeIcon
        style={{
          position: "absolute",
          bottom: "20px",
          left: "20px",
          cursor: "pointer",
          color: "white",
          width: "32px",
          height: "32px",
        }}
        onClick={() => handleChange()}
      />
    </>
  );
};

export { CropImage };
