import {
  Box,
  Typography,
  Select,
  MenuItem,
  styled,
  SelectChangeEvent,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  setAssignedSlot,
  setAvailableSlotTime,
  setCreatorDetails,
  setDaySlot,
  setLockedSlotId,
  setMinuteData,
  setMinuteSlot,
  setSelectedSlot,
  setService,
  setServiceType,
  setServiceValue,
  setTimerEndTime,
} from "../../redux/slotBookingSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { TickIcon } from "../../assets/SvgIcons";
import { formatDate } from "../../utils/formatDate";
import { colors } from "../../utils/styles";
import userSlotBookingService from "../../api/booking/user/userSlotBookingService";
import creatorSlotBookingService from "../../api/booking/creator/creatorSlotBookingService";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 4px 16px;
`;

const Heading = styled(Typography)<{ disabled?: boolean }>`
  font-weight: 600;
  color: ${(props) => (props.disabled ? "#616161" : "#212121")};
`;

const ChipContainer = styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  justify-content: flex-start;
  gap: 16px;
  max-width: 100%;
  padding-right: 16px; /* Adds space at the end for scrolling */

  /* Scrollbar styling for Webkit browsers */
  &::-webkit-scrollbar {
    height: 8px; /* Height of horizontal scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Make it rounded */
  }

  /* Optional: Hide scrollbar entirely */
  &::-webkit-scrollbar {
    display: none;
  }

  /* For other browsers */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
`;

const StyledChip = styled(Box)<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  min-width: 65px;
  min-height: 35px;
  color: ${(props) => (props.disabled ? colors.black[10] : colors.black[200])};
  font-size: 10px;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.disabled ? colors.black[10] : colors.black[200])};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.disabled ? colors.gray[105] : "#fff")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const SlotContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
`;

const SlotChip = styled(Box)<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  min-width: 156px;
  min-height: 35px;
  color: ${(props) => (props.disabled ? colors.black[10] : colors.black[200])};
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.disabled ? colors.black[10] : colors.black[200])};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.disabled ? colors.gray[105] : "#fff")};
  gap: 8px;
`;

const SlotText = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  font-family: Poppins;
  margin: 0;
`;

const ChipText = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  font-family: Poppins;
  margin: 0;
`;

const CustomSelect = styled(Select)`
  .MuiSelect-select {
    display: flex;
    justify-content: space-between;
  }
`;

type Service = {
  value: string;
  label: string;
  duration: string;
};

const data = {
  slotTime: [5, 10, 15, 30, 45],
  weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
};

const BookSlotDetails = () => {
  const dispatch = useDispatch();
  const {
    service,
    minuteSlot,
    daySlot,
    selectedSlot,
    availableSlotTime,
    creatorDetails,
    minuteData,
    assignedSlot,
  } = useSelector((state: RootState) => state.slotBooking);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const [error, setError] = useState<string | null>(null);
  const creatorId = queryParams.get("id");
  const creatorName = queryParams.get("creatorName");
  const serviceType = queryParams.get("serviceType");
  const audioCallMinutes = queryParams.get("audioCallMinutes");
  const videoCallMinutes = queryParams.get("videoCallMinutes");
  const directMessageMinutes = queryParams.get("directMessageMinutes");
  const isAudioCall = queryParams.get("isAudioCall");
  const isVideoCall = queryParams.get("isVideoCall");
  const isDirectMessage = queryParams.get("isDirectMessage");

  const services: Service[] = [];
  if (isAudioCall === "true" && audioCallMinutes !== "0") {
    services.push({
      value: "Audio Call",
      label: "Audio Call",
      duration:
        audioCallMinutes +
        (serviceType === "subscription" ? " min" : " CC/min"),
    });
  }
  if (isVideoCall === "true" && videoCallMinutes !== "0") {
    services.push({
      value: "Video Call",
      label: "Video Call",
      duration:
        videoCallMinutes +
        (serviceType === "subscription" ? " min" : " CC/min"),
    });
  }
  if (isDirectMessage === "true" && directMessageMinutes !== "0") {
    services.push({
      value: "Direct Message",
      label: "Direct Message",
      duration:
        directMessageMinutes +
        (serviceType === "subscription" ? " min" : " CC/min"),
    });
  }
  const minuteLeft =
    service === "Audio Call"
      ? Number(audioCallMinutes)
      : service === "Video Call"
      ? Number(videoCallMinutes)
      : service === "Direct Message"
      ? Number(directMessageMinutes)
      : 0;
  const handleSelectMinuteSlot = async (minute: Number) => {
    const payload = {
      creatorId: creatorDetails.creatorId,
      slotLength: minute,
    };
    try {
      const { data: responseData } =
        userRole === "user"
          ? await userSlotBookingService.getAvailableMinuteSlot(payload)
          : await creatorSlotBookingService.getAvailableMinuteSlot(payload);
      if (responseData?.length === 0) {
        setError("No available minute slots found.");
      } else {
        dispatch(setMinuteData(responseData));
        setError(null); // Clear any previous errors
      }
    } catch (err: any) {
      setError("Failed to fetch available minute slots : " + err.message);
      console.error(err);
    }
  };
  const handleAssignSlot = async (timeSlot: {
    startTime: string;
    endTime: string;
  }) => {
    const payload = {
      creatorId: creatorDetails.creatorId,
      slotLength: Number(minuteSlot),
      startTime: timeSlot.startTime,
      endTime: timeSlot.endTime,
      date: daySlot.date,
    };

    try {
      const { data: responseData } =
        userRole === "user"
          ? await userSlotBookingService.assignSlot(payload)
          : await creatorSlotBookingService.assignSlot(payload);
      dispatch(setAssignedSlot(responseData));
      setError(null); // Clear any previous errors
    } catch (err: any) {
      setError("Failed to assign slot : " + err.message);
      console.error(err);
      dispatch(setAssignedSlot({ startTime: "", endTime: "" }));
    }
  };

  const handleContinue = async () => {
    try {
      const payload = {
        creatorId: creatorDetails.creatorId,
        startTime: selectedSlot.startTime,
        endTime: selectedSlot.endTime,
        date: daySlot.date,
      };

      const { data: responseData } =
        userRole === "user"
          ? await userSlotBookingService.lockSlot(payload)
          : await creatorSlotBookingService.lockSlot(payload);
      dispatch(setLockedSlotId(responseData.lockedSlotId));
      dispatch(setTimerEndTime(responseData.ttl));
      setError(null);
      navigate(`/${userRole}/book-slot/details`);
    } catch (err: any) {
      setError("Failed to lock slot : " + err.message);
      console.error(err);
    }
  };

  useEffect(() => {
    if (creatorId && creatorName && serviceType) {
      dispatch(setCreatorDetails({ creatorName, creatorId }));
      dispatch(setServiceType(serviceType!));
    }
  }, [creatorName, creatorId, serviceType, dispatch]);

  return (
    <>
      <Container>
        {error && (
          <Typography
            variant="body2"
            color="error"
            sx={{ marginTop: 2, textAlign: "center" }}
          >
            {error}
          </Typography>
        )}
        <Heading variant="h5">Select Service</Heading>
        <CustomSelect
          value={service}
          onChange={(event: SelectChangeEvent<unknown>) => {
            dispatch(setService(event.target.value as string));
            if (event.target.value === "Audio Call") {
              dispatch(setServiceValue(audioCallMinutes!));
            } else if (event.target.value === "Video Call") {
              dispatch(setServiceValue(videoCallMinutes!));
            } else if (event.target.value === "Direct Message") {
              dispatch(setServiceValue(directMessageMinutes!));
            }
          }}
          displayEmpty
          fullWidth
          IconComponent={ExpandMoreIcon}
          renderValue={(selected: unknown) => {
            if (!selected) {
              return (
                <Typography sx={{ color: "#aaa" }}>Select Service</Typography>
              );
            }

            const selectedService = services.find(
              (item) => item?.value! === service
            );

            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }}
                >
                  {selectedService?.label}
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    marginRight: "4px",
                  }}
                >
                  ({selectedService?.duration})
                </Typography>
              </Box>
            );
          }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <MenuItem value="" disabled>
            <em>Select Service</em>
          </MenuItem>

          {services.map((serviceItem) => (
            <MenuItem
              key={serviceItem.value}
              value={serviceItem.value}
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }}
                >
                  {serviceItem.label}
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }}
                >
                  ({serviceItem.duration})
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </CustomSelect>
        {service && (
          <>
            <Heading variant="h5" disabled={!service}>
              Select No. Of Minutes
            </Heading>
            <ChipContainer>
              {data.slotTime
                .filter((minute) => {
                  // Show 5 and 10 minute slots only when minuteLeft is less than 15
                  if (minute <= minuteLeft) {
                    // Only show 5 and 10 minute slots if minuteLeft is less than 15
                    if (minute === 5 || minute === 10) {
                      return minuteLeft < 15;
                    }
                    return true;
                  }
                  return false;
                })
                .map((minute) => (
                  <StyledChip
                    key={minute}
                    onClick={() => {
                      dispatch(setMinuteSlot(minute.toString()));
                      handleSelectMinuteSlot(minute);
                    }}
                    disabled={!service}
                    sx={{
                      opacity: service ? 1 : 0.5,
                      borderColor:
                        minuteSlot === minute.toString()
                          ? colors.violet[100]
                          : colors.black[200],
                      backgroundColor:
                        minuteSlot === minute.toString()
                          ? colors.purple[50]
                          : "#fff",
                    }}
                  >
                    <ChipText>{minute + " min"}</ChipText>
                  </StyledChip>
                ))}
            </ChipContainer>
          </>
        )}
        {minuteSlot && minuteData.length > 0 && (
          <>
            <Heading variant="h5" disabled={!minuteSlot}>
              Select Day
            </Heading>
            <ChipContainer>
              {minuteData.map((day: any, index: number) => {
                if (day.slots.length > 0) {
                  return (
                    <StyledChip
                      key={day.date}
                      onClick={() => {
                        dispatch(
                          setDaySlot({
                            index,
                            name: data.weekdays[day.day],
                            date: day.date,
                          })
                        );
                      }}
                      disabled={!minuteSlot}
                      sx={{
                        opacity: minuteSlot ? 1 : 0.5,
                        borderColor:
                          daySlot.date === day.date
                            ? colors.violet[100]
                            : colors.black[200],
                        backgroundColor:
                          daySlot.date === day.date
                            ? colors.purple[50]
                            : colors.white,
                      }}
                    >
                      <ChipText>
                        {data.weekdays[day.day]}
                        <br />
                        {formatDate(day.date)}
                      </ChipText>
                    </StyledChip>
                  );
                } else return null;
              })}
            </ChipContainer>
          </>
        )}
      </Container>
      {service && minuteSlot && daySlot.name && (
        <>
          <hr
            style={{
              width: "100%",
              border: "none",
              borderTop: "1px solid #e0e0e0",
            }}
          />
          <Container>
            <Heading variant="h5">Select Slot</Heading>
            <SlotContainer>
              {minuteData[daySlot.index]?.slots
                ?.filter(
                  (slot: any, index: number, self: any[]) =>
                    index ===
                    self.findIndex((s) => s.startTime === slot.startTime)
                )
                .map((slot: any) => (
                  <SlotChip
                    key={slot.startTime}
                    onClick={() => {
                      dispatch(setSelectedSlot(slot));
                      handleAssignSlot({
                        startTime: slot.startTime,
                        endTime: slot.endTime,
                      });
                    }}
                    sx={{
                      borderColor:
                        selectedSlot === slot
                          ? colors.violet[100]
                          : colors.black[200],
                      color:
                        selectedSlot === slot
                          ? colors.violet[100]
                          : colors.black[200],
                      position: "relative",
                    }}
                  >
                    {selectedSlot === slot && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          zIndex: 10,
                          backgroundColor: colors.white,
                          border: `1px solid ${colors.white}`,
                        }}
                      >
                        <TickIcon />
                      </Box>
                    )}
                    <SlotText>{slot.startTime}</SlotText>
                    <SlotText>{" - "}</SlotText>
                    <SlotText>{slot.endTime}</SlotText>
                  </SlotChip>
                ))}
            </SlotContainer>

            {assignedSlot &&
              assignedSlot.startTime !== "" &&
              selectedSlot &&
              selectedSlot.startTime !== "" && (
                <>
                  <Heading variant="h5" disabled={!selectedSlot}>
                    Available Slot Time For Booking
                  </Heading>
                  <SlotChip
                    key={assignedSlot.startTime}
                    onClick={() =>
                      dispatch(
                        setAvailableSlotTime({
                          startTime: assignedSlot.startTime,
                          endTime: assignedSlot.endTime,
                        })
                      )
                    }
                    disabled={!selectedSlot}
                    sx={{
                      opacity: minuteSlot ? 1 : 0.5,
                      borderColor:
                        availableSlotTime.startTime === assignedSlot.startTime
                          ? colors.violet[100]
                          : colors.black[200],
                      backgroundColor:
                        availableSlotTime.startTime === assignedSlot.startTime
                          ? colors.purple[50]
                          : colors.white,
                      maxWidth: "80px",
                      marginBottom: "90px",
                    }}
                  >
                    <SlotText>{assignedSlot.startTime}</SlotText>
                    <SlotText>{" - "}</SlotText>
                    <SlotText>{assignedSlot.endTime}</SlotText>
                  </SlotChip>
                </>
              )}
          </Container>
        </>
      )}
      {service &&
        minuteSlot &&
        daySlot.name &&
        selectedSlot.startTime &&
        availableSlotTime.startTime && (
          <Button
            variant="contained"
            sx={{
              backgroundColor: colors.violet[100],
              color: colors.white,
              fontSize: "16px",
              fontWeight: 700,
              position: "fixed",
              bottom: "0",
              borderRadius: "0",
              "&:hover": {
                backgroundColor: colors.violet[20],
              },
              width: "100%",
              height: "56px",
            }}
            onClick={() => {
              if (
                service &&
                minuteSlot &&
                daySlot.name &&
                selectedSlot.startTime &&
                availableSlotTime.startTime
              ) {
                handleContinue();
              }
            }}
          >
            Continue
          </Button>
        )}
    </>
  );
};

export default BookSlotDetails;
