export const colors = {
  status: {
    SUCCESSFUL: "#43A45C",
    FAILED: "#E88A8A",
    IN_PROGRESS: "#EFB515",
    PENDING: "#E88A8A",
    CANCELLED: "#E88A8A",
  },

  gray: {
    50: "#2121211f",
    100: "#f3f3f3",
    200: "#2121213d",
    300: "#d5d5d599",
    325: "#21212166",
    400: "#b5b5b5",
    500: "#21212140",
    600: "#808080",
    700: "#212121b3",
  },
  black: {
    50: "#343546",
    1000: "#212121",
  },
  violet: {
    50: "#4F30AB",
  },
  orange: {
    50: "#fff7ed",
    100: "#FB542B",
    200: "#544796",
  },
  pink: {
    50: "#fdf2f8",
    100: "#fce7f3",
  },
  purple: {
    100: "#A3278F",
  },
  brand_primary: "#544796",
  brand_secondary: "#41acaf",
  white: "#FFFFFF",
  disabled: "#B1B4B7",
  title: "#5C6178",
  background_primary: "#e5ecfd",
  background_secondary: "",
  background_tertiary: "",
  background_inactive: "",
  offwhite: "#f4f8fb",
};

export const typography = {
  font_size: {
    xxs: 10,
    xs: 12,
    sm: 14,
    base: 16,
    lg: 18,
    xl: 20,
    "2xl": 24,
  },
  font_weight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    bolder: 800,
  },
};
