import { Box, styled, Typography } from '@mui/material'
import React from 'react'

const Container=styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 83dvh;
`

const NoMessageImageContainer=styled(Box)`

`;
const NoMessageText=styled(Typography)`
color: var(--Text-Secodary, #616161);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%;
letter-spacing: 0.21px;
`;

const NoMessages = () => {


  return (
        <Container>
            <NoMessageImageContainer>
                <img src="/Images/Messages_Laying.png" alt="no-messages"/>
            </NoMessageImageContainer>
            <NoMessageText>
                    Follow creators to Connect!
            </NoMessageText>
        </Container>
  )
}

export default NoMessages