import { ApiService } from "../ApiClient";
import { userAxiosInstance } from "../axiosInstance";
import "../interceptors/userAuthInterceptors"

class UserBookingsApi extends ApiService {
  constructor() {
    super(userAxiosInstance);
  }
  getConfirmedBookings(page: string, pageSize: string) {
    return this.axios.get(
      `creator/bookings?status=Confirmed&page=${page}&pageSize=${pageSize}`
    );
  }
  getPendingBookings(page: string, pageSize: string) {
    return this.axios.get(
      `creator/bookings?status=Pending&page=${page}&pageSize=${pageSize}`
    );
  }
  getHistoryBookings(page: string, pageSize: string) {
    return this.axios.get(
      `creator/bookings?status=History&page=${page}&pageSize=${pageSize}`
    );
  }
  cancelBooking(bookingId: string) {
    return this.axios.get(`creator/bookings?status=History&page`);
  }
  checkCanRescheduleBooking(bookingId:string){
    let body={id:bookingId};
    return this.axios.post(`creator/slots/reschedule/available`, JSON.stringify(body));
  }
  updateBookingShowRequestStatus(bookingId: string, payload: any) {
    return this.axios.patch(
      `creator/bookings/${bookingId}`,
      JSON.stringify(payload)
    );
  }
}


export default new UserBookingsApi();
