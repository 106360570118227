import { ApiService } from "../../ApiClient";
import { creatorAxiosInstance } from "../../axiosInstance";
import "../../interceptors/creatorAuthInterceptors";

class CreatorSlotReschedulingApi extends ApiService {
  constructor() {
    super(creatorAxiosInstance);
  }

  getRescheduleSlot(payload: any) {
    return this.axios
      .put("creator/slots/book", JSON.stringify(payload))
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}

export default new CreatorSlotReschedulingApi();
