export const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

  return `${formattedMinutes}:${formattedSeconds}`;
};

export const convertTimeToDateTime = (timeStr: string): Date | null => {
  if (!timeStr) return null;

  try {
    const [time, period] = timeStr.split(" ");
    const [hours, minutes] = time.split(":").map(Number);
    const now = new Date();
    const result = new Date(now);

    let adjustedHours = hours;
    if (period.toUpperCase() === "PM" && hours !== 12) {
      adjustedHours += 12;
    } else if (period.toUpperCase() === "AM" && hours === 12) {
      adjustedHours = 0;
    }

    result.setHours(adjustedHours, minutes, 0, 0);

    if (result < now) {
      result.setDate(result.getDate() + 1);
    }

    return result;
  } catch (error) {
    console.error("Error converting time:", error);
    return null;
  }
};

export const calculateRemainingDuration = (endTime: string): number => {
  const endDateTime = convertTimeToDateTime(endTime);
  if (!endDateTime) return 0;

  const now = new Date();
  const diffInMinutes = Math.floor(
    (endDateTime.getTime() - now.getTime()) / (1000 * 60)
  );
  return Math.max(0, diffInMinutes);
};
