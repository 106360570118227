import styled from "@emotion/styled";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { typography } from "../../colors";
import { colors } from "../../utils/styles";
import { PageTagComponent } from "../../components/Common/PageTagComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import userApi from "../../api/user/userApi";
import paymentService from "../../api/user/paymentServiceApi";
import creatorPaymentService from "../../api/creator/paymentServiceApi";
import { STATUS, Status } from "../../constants/statusConstants";
import { useLocation } from "react-router-dom";
import CashfreeService from "../../services/CashfreeService";
import creatorWalletServiceApi from "../../api/creator/creatorWalletServiceApi";

const YCValue = styled(Typography)({
  fontSize: "12px",
});

const Discount = styled(Typography)({
  position: "absolute",
  bottom: "-6px",
  fontSize: "8px",
  fontWeight: 500,
  background: "#C15700",
  borderRadius: "12px",
  paddingInline: "4px",
  color: "#fff",
});

const Summary: any = ({ amountPayable }: { amountPayable: number }) => {
  return (
    <Stack direction="column">
      <Typography
        fontWeight={typography.font_weight.bold}
        fontSize={typography.font_size.lg}
      >
        &#8377; {amountPayable}
      </Typography>
      <Typography>Grand Total</Typography>
    </Stack>
  );
};

const BillingDetails: any = ({ data }: { data: any }) => {
  return (
    <Stack
      height="210px"
      mt="auto"
      mx="16px"
      p="9px 14px"
      border={`1px solid ${colors.purple[50]}`}
      borderRadius="16px"
    >
      <Typography
        fontWeight={typography.font_weight.bold}
        fontSize={typography.font_size.sm}
      >
        Billing Details
      </Typography>
      <Stack py="9px">
        <Stack px={1} direction="row" justifyContent="space-between">
          <Typography fontSize={typography.font_size.xs}>
            No of Clout Coins you get
          </Typography>
          <Typography fontSize={typography.font_size.xs}>
            CC {data.amountInYc}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          my="8px"
          py="10px"
          gap="10px"
          borderBottom="1px solid rgba(213, 213, 213, 0.35)"
          borderTop="1px solid rgba(213, 213, 213, 0.35)"
        >
          <Stack px={1} direction="row" justifyContent="space-between">
            <Box>
              <Typography fontSize={typography.font_size.xxs}>
                Item Cost
              </Typography>
              <Typography fontSize={typography.font_size.xxs}>
                (1₹ per 10 Clout Coins)
              </Typography>
            </Box>
            <Typography fontSize={typography.font_size.xxs}>
              &#8377; {data.amountInRs}
            </Typography>
          </Stack>
          {data.discountPercentage !== 0 && (
            <Stack px={1} direction="row" justifyContent="space-between">
              <Typography fontSize={typography.font_size.xxs}>
                Discount({data.discountPercentage}%)
              </Typography>
              <Typography fontSize={typography.font_size.xxs}>
                &#8377; {data.discountInRs}
              </Typography>
            </Stack>
          )}
          <Stack px={1} direction="row" justifyContent="space-between">
            <Typography fontSize={typography.font_size.xxs}>
              GST applicable(18%)
            </Typography>
            <Typography fontSize={typography.font_size.xxs}>
              &#8377; {data.gstAmount}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight={typography.font_size.sm}>
            Amount Payable
          </Typography>
          <Typography fontWeight={typography.font_size.sm}>
            &#8377; {data.totalAmount}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const AddCoins = () => {
  let cashfree: any;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const availableWalletCC = useSelector((state: RootState) => state.user.coins);
  const availableYC = searchParams.get("availableYC");
  const [coins, setCoins] = useState<number>(0);
  const [isFetched, setIsFetched] = useState<Status>(STATUS.IDLE);
  const [addCoinsData, setAddCoinsData] = useState([]);
  const [billingDetails, setBillingDetails] = useState({});
  const [amountPayable, setAmountPayable] = useState(0);
  const [orderDetails, setOrderDetails] = useState({});
  const [sessionId, setSessionId] = useState("");
  const [isCCAdded, setIsCCAdded] = useState<Status>(STATUS.IDLE);
  const [isBillingDetailsFetched, setIsBillingDetailsFetched] =
    useState<Status>(STATUS.IDLE);

  const handleCoins = (e: any) => {
    const isValid = isNaN(e.target.value);
    if (isValid) return;
    setCoins(e.target.value);
  };

  const getCoinsData = async () => {
    setIsFetched(STATUS.LOADING);
    try {
      const response =
        userRole === "user"
          ? await paymentService.get_CC_Topup_Options()
          : await creatorWalletServiceApi.get_CC_Topup_Options();
      setAddCoinsData(response.data.data);
      setIsFetched(STATUS.SUCCESS);
    } catch (error) {
      setIsFetched(STATUS.ERROR);
      // handleOpenToast('Could not fetch data. Please refresh page.')
    }
  };
  const getBillingDetails = async (option: any) => {
    setCoins(option.amountInYc);
    setIsBillingDetailsFetched(STATUS.LOADING);
    try {
      const response =
        userRole === "user"
          ? await paymentService.get_CC_Billing_Detail(option.id)
          : await creatorWalletServiceApi.get_CC_Billing_Detail(option.id);
      setBillingDetails(response.data.data);
      setAmountPayable(response.data.data.totalAmount);
      const {
        amountInRs,
        gstAmount,
        amountInYc,
        discountPercentage,
        totalAmount,
      } = option;
      setOrderDetails({
        amountInRs,
        gstAmount,
        amountInYc,
        discountPercentage,
        discountInRs: (totalAmount * discountPercentage) / 100,
        totalAmount,
      });
      setIsBillingDetailsFetched(STATUS.SUCCESS);
    } catch (error) {
      setIsBillingDetailsFetched(STATUS.ERROR);
      console.log(error);
    }
  };
  const createOrderAndGenerateSessionId = async () => {
    try {
      setIsCCAdded(STATUS.LOADING);
      const response =
        userRole === "user"
          ? await paymentService.create_Order_And_SessionId(orderDetails)
          : await creatorPaymentService.create_Order_And_SessionId(
              orderDetails
            );
      setSessionId(response.data.data.sessionId);
      const redirect_path = location.state?.from;
      let checkoutOptions = {
        paymentSessionId: response.data.data.sessionId,
        redirectTarget: "_self",
        returnUrl: `${
          process.env.REACT_APP_CASHFREE_BASE_RETURN_URL
        }/${userRole}/${
          redirect_path ? redirect_path : "wallet"
        }?myorder={order_id}`,
      };
      await CashfreeService.initializeSDK("sandbox");
      const cashfree_response = await CashfreeService.checkout(checkoutOptions);
      setIsCCAdded(STATUS.SUCCESS);
    } catch (error) {
      console.log(error);
      setIsCCAdded(STATUS.ERROR);
    }
  };
  // function that renders views based on api status of fetching coin/topup data
  const renderViews = () => {
    switch (isFetched) {
      case STATUS.SUCCESS:
        return (
          <>
            {addCoinsData.map((option: any, idx: number) => {
              return (
                <Stack
                  key={idx}
                  position="relative"
                  onClick={() => getBillingDetails(option)}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  height="48px"
                  borderRadius="8px"
                  flexBasis="calc(33.33% - 8px)"
                  sx={{
                    background: option.amountInYc === coins ? "#E8F5E9" : null,
                    border:
                      option.amountInYc === coins
                        ? "1px solid #2E7D32"
                        : "1px solid gray",
                  }}
                  textAlign="center"
                >
                  <YCValue>CC {option.amountInYc}</YCValue>
                  {option.discountPercentage !== 0 && (
                    <Discount>{option.discountPercentage}% OFF</Discount>
                  )}
                </Stack>
              );
            })}
          </>
        );
      case STATUS.LOADING:
        return <CircularProgress size={40} sx={{ mt: "9rem", mx: "auto" }} />;
      case STATUS.ERROR:
        return <Box textAlign="center">Something went wrong !</Box>;
      default:
        return null;
    }
  };

  const billingDetailsView = () => {
    switch (isBillingDetailsFetched) {
      case STATUS.SUCCESS:
        return <BillingDetails data={billingDetails} />;
      case STATUS.LOADING:
        return (
          <Stack
            height="210px"
            mt="auto"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={20} />
          </Stack>
        );
      case STATUS.ERROR:
        return (
          <Box height="210px" mt="auto" textAlign="center">
            Error getting billing details.
          </Box>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    getCoinsData();
  }, []);

  const orderId = searchParams.get("myorder");
  useEffect(() => {
    if (orderId) {
      paymentService
        .verify_Payment_Status(orderId)
        .then((d) => console.log(d))
        .catch((error) => {});
    }
  }, []);

  return (
    <Box height="90dvh" display="flex" flexDirection="column">
      <PageTagComponent>Add Coins</PageTagComponent>
      <Stack px="16px" direction="column" gap="16px">
        <Box textAlign="center">
          <Typography
            component={"span"}
            fontWeight={typography.font_weight.semiBold}
          >
            Current Balance : {availableYC}
          </Typography>
          &nbsp;
          <Typography component="span">CC</Typography>
        </Box>
        <Box>
          <InputLabel>Add Clout Coins</InputLabel>
          <TextField
            fullWidth
            value={coins}
            aria-readonly
            helperText={
              coins < 50 ? "Minimum CC should be more than 50 CC" : ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src="/Icons/coin_icon.svg" />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiInputBase-root": {
                height: "48px",
              },
            }}
          />
        </Box>
        <Stack direction="row" px="4px" flexWrap="wrap" gap={2}>
          {renderViews()}
        </Stack>
      </Stack>
      {coins >= 50 && billingDetailsView()}
      <Box
        maxWidth="sm"
        p="8px"
        display="flex"
        flexDirection={"row"}
        justifyContent="space-between"
        sx={{
          position: "fixed",
          bottom: 0,
          boxShadow: "0px -4px 8px 0px rgba(0, 0, 0, 0.08)",
          width: "100%",
        }}
      >
        <Summary amountPayable={amountPayable} />
        <Button
          endIcon={
            isCCAdded === STATUS.LOADING && <CircularProgress size={20} />
          }
          variant={
            coins >= 50 || isCCAdded !== STATUS.LOADING
              ? "contained"
              : "disabled"
          }
          onClick={createOrderAndGenerateSessionId}
          sx={{ width: "175px" }}
        >
          Pay
        </Button>
      </Box>
    </Box>
  );
};
export { AddCoins };
