import { useEffect, useState } from "react";
import { CancelRounded, Search as SearchIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import searchApi from "../../api/search/searchApi";
import { SearchFeed } from "./SearchFeed";
import { useIntersectionObserver } from "../../hooks/useInteractionObserver";

interface Creator {
  id: string;
  displayName: string;
  handleName: string;
  profilePhotoUrl: string;
}

const SearchPage = () => {
  const [search, setSearch] = useState<string>("");
  const [creators, setCreators] = useState<Creator[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const location = useLocation();
  const userRole = location.pathname.includes("user") ? "user" : "creator";
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
  });

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [debounceTimeout]);

  useEffect(() => {
    if (isIntersecting && !loading && hasMore) {
      fetchCreators();
    }
  }, [isIntersecting]);

  useEffect(() => {
    if (search.trim() === "") {
      setCreators([]);
      setPage(0);
      return;
    }

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      setPage(1);
      fetchCreators(true);
    }, 500);

    setDebounceTimeout(timeout);
  }, [search]);

  const fetchCreators = (reset: boolean = false) => {
    if (loading) return;

    setLoading(true);

    const nextPage = reset ? 1 : page + 1;
    searchApi
      .search(search, nextPage)
      .then(({ data }) => {
        const filteredCreators: Creator[] = data.data.map((item: any) => ({
          id: item.id,
          displayName: item.displayName,
          handleName: item.handleName,
          profilePhotoUrl: item.profilePhotoUrl,
        }));

        if (filteredCreators.length === 0 && nextPage === 1) {
          setCreators([]);
        } else {
          setCreators((prev) =>
            reset ? filteredCreators : [...prev, ...filteredCreators]
          );
          setHasMore(
            data.pagination.totalItems >
              (reset ? 0 : creators.length) + filteredCreators.length
          );
        }
        setPage(nextPage);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setCreators([]);
      });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setSearch("");
    setCreators([]);
    setPage(0);
    setHasMore(true);
  };

  const renderCreators = () => {
    return (
      <>
        {creators.map((creator) => (
          <Box
            key={creator.id}
            display="flex"
            alignItems="center"
            p={2}
            sx={{ width: "100%" }}
          >
            <Avatar
              src={creator.profilePhotoUrl}
              alt={creator.displayName}
              sx={{ marginRight: "10px" }}
            />
            <Box>
              <Typography variant="h6">
                <Link
                  to={`/${userRole}/creator-profile-view/${creator.id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {creator.displayName}
                </Link>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                @{creator.handleName}
              </Typography>
            </Box>
          </Box>
        ))}
        {hasMore && <div ref={ref} style={{ height: "1rem" }} />}
      </>
    );
  };

  const renderNoResults = () => (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyItems="space-around"
      sx={{ marginTop: "25%" }}
    >
      <Typography variant="h6" color="textSecondary" fontWeight="bold">
        No creator found
      </Typography>
      <img
        src="/Images/error.svg"
        alt="No Results"
        style={{ width: "350px", height: "300px", marginTop: "20px" }}
      />
      <Typography variant="body1" color="textSecondary">
        Check if you have entered the right spelling
      </Typography>
    </Box>
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          position: "sticky",
          top: "0",
          zIndex: 1,
          backgroundColor: "white",
        }}
      >
        <TextField
          variant="outlined"
          sx={{ marginInline: "10px", marginTop: "10px" }}
          placeholder="Search Creator"
          value={search}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: search && (
              <InputAdornment position="end">
                <CancelRounded
                  onClick={handleClearSearch}
                  style={{ cursor: "pointer" }}
                />
              </InputAdornment>
            ),
            style: { height: "48px", fontWeight: "bold" },
          }}
        />
      </Box>
      <Box mt={4} display="flex" flexDirection="column" alignItems="center">
        {search.trim() === "" ? (
          <SearchFeed />
        ) : loading ? (
          <Box display="flex" justifyContent="center" mt={2} p={2}>
            <CircularProgress />
          </Box>
        ) : creators.length > 0 ? (
          renderCreators()
        ) : (
          renderNoResults()
        )}
      </Box>
    </>
  );
};

export { SearchPage };
