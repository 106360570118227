import AWS from "aws-sdk";
import { v4 as uniqueId } from "uuid";
const aws_region = "ap-south-1";
const bucketName = process.env.REACT_APP_S3_BUCKET;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: aws_region,
    signatureVersion: "v4",
});
const s3 = new AWS.S3();

// Function to upload a single Blob to S3
const uploadBlobToS3 = async (blobUrl: string, index: number, coverPhoto: string) => {
    try {
        // Fetch the Blob data from the blob URL
        const response = await fetch(blobUrl);
        const blob = await response.blob();

        // Convert the blob to a File object
        const fileNamePrefix = uniqueId();
        const file = new File([blob], `image_${fileNamePrefix}.jpg`, { type: blob.type });

        //   Prepare S3 upload parameters
        const params = {
            Bucket: '',
            Key: `${file.name}`, // return this key
            Body: file,
            ContentType: file.type,
        };

        //   Upload the file to S3
        let uploadResult;
        if (bucketName) {
            params.Bucket = bucketName;
            uploadResult = await s3.upload(params).promise();
            console.log(`File ${file.name} uploaded successfully:`, uploadResult.Location);
            return {
                type: 'PHOTO',
                src: uploadResult.Location,
                key: params.Key,
                bucket: bucketName,
                isCoverPhoto: blobUrl.toString() === coverPhoto.toString()
            };
        }
        else{
            throw new Error('Invalid Bucket')
        }
    } catch (error) {
        console.error('Error uploading file to S3:', error);
        throw error;
    }
};

export const convertCroppedImagesBlobToFileObject = async (croppedImagesBlob: string[], coverPhoto: string) => {

    try {
        // Map over the blob URLs and upload each one using Promise.all
        const uploadPromises = croppedImagesBlob.map((blobUrl, index) => uploadBlobToS3(blobUrl, index, coverPhoto));

        // Wait for all uploads to complete
        const uploadResults = await Promise.all(uploadPromises);
        return uploadResults; // Return the array of uploaded file URLs
    } catch (error) {
        console.error('Error uploading blobs:', error);
    }

}