import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentStep,
  setCurrentStep,
  setCurrentTitle,
} from "../../redux/creatorRegistrationSlice";
import { Box } from "@mui/material";
import { CreatorOnboardingStep } from "../../components/RegisterForCreator/Onboarding";
import { RegistrationDetails } from "../../components/RegisterForCreator/Register";
import SubmitApplication from "../../components/RegisterForCreator/SubmitApplication";
import userApi from "../../api/user/userApi";
import Loader from "../../components/Common/Loader";
import creatorApi from "../../api/creator/creatorApi";

const CreatorOnboardingPage = () => {
  const dispatch = useDispatch();

  const currentStep = useSelector(getCurrentStep);
  const loading = useSelector((state: any) => state.becomeCreator.loading);
  const user = useSelector((state) => state);
  const creator = useSelector((state: any) => state.creator);

  const [verificationType, setVerificationType] = useState("");

  const verificationStatus = [
    "STEP_2",
    "AADHAR_VERIFICATION",
    "PAN_VERIFICATION",
    "GST_VERIFICATION",
  ];

  const fetchCreatorOnboardingStep = async () => {
    try {
      const res = await userApi.getCreatorOnboardingStep();

      const nextStage = res.data.nextStage;

      if ("STEP_1" === nextStage) {
        dispatch(setCurrentStep(0));
      } else if (verificationStatus.includes(nextStage)) {
        dispatch(setCurrentTitle("Register"));
        if ("AADHAR_VERIFICATION" === nextStage) {
          setVerificationType("aadhar");
        } else if ("PAN_VERIFICATION" === nextStage) {
          setVerificationType("pan");
        } else if ("GST_VERIFICATION" === nextStage) {
          setVerificationType("gst");
        } else {
          setVerificationType("");
        }

        dispatch(setCurrentStep(1));
      } else if ("STEP_3" === nextStage) {
        dispatch(setCurrentTitle("Submit Application"));
        dispatch(setCurrentStep(2));
      }
    } catch (e) {
      console.log("error while getting creator onboarding step", e);
    }
  };

  useEffect(() => {
    userApi.setToken();
    fetchCreatorOnboardingStep();
  }, []);

  return (
    <Box>
      <Loader loading={loading} />

      {currentStep === 0 && (
        <CreatorOnboardingStep getNextStage={fetchCreatorOnboardingStep} />
      )}
      {currentStep === 1 && (
        <RegistrationDetails
          verificationType={verificationType}
          setVerificationType={setVerificationType}
          getNextStage={fetchCreatorOnboardingStep}
        />
      )}
      {currentStep === 2 && <SubmitApplication />}
    </Box>
  );
};
export { CreatorOnboardingPage };
