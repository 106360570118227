import { ApiService } from "../../ApiClient";
import { userAxiosInstance } from "../../axiosInstance";
import "../../interceptors/creatorAuthInterceptors";

class UserSlotReschedulingApi extends ApiService {
  constructor() {
    super(userAxiosInstance);
  }

  getRescheduleSlot(payload: any) {
    return this.axios
      .put("creator/slots/book", JSON.stringify(payload))
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}

export default new UserSlotReschedulingApi();
